import { RootAction } from "../types";
import {
  GET_CLIENTS_SUCCESS,
  GET_COMMERCIAL_CYCLES_SUCCESS,
  GET_COMPLAINTS_SUCCESS,
  GET_CONTACTS_SUCCESS,
  GET_OPPORTUNITIES_SUCCESS,
  GET_PROSPECTS_SUCCESS,
  GET_TASKS_SUCCESS,
} from "./actions";
import { CrmState } from "./types";

const initialState: CrmState = {
  clients: [],
  prospects: [],
  contacts: [],
  opportunities: [],
  commercialCycles: [],
  complaints: [],
  tasks: [],
};

export function crmReducer(
  state: CrmState = initialState,
  action: RootAction
): CrmState {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
      };

    case GET_PROSPECTS_SUCCESS:
      return {
        ...state,
        prospects: action.prospects,
      };

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.contacts,
      };

    case GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        opportunities: action.opportunities,
      };

    case GET_COMMERCIAL_CYCLES_SUCCESS:
      return {
        ...state,
        commercialCycles: action.commercialCycles,
      };

    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.complaints,
      };

    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
      };

    default:
      return state;
  }
}
