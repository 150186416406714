import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Invoice, InvoicePayment } from "../../../../../../models/invoice";
import { Dispatch, RootState } from "../../../../../../store";
import { getSuppliersAction } from "../../../../../../store/Stock/actions";
import { getSuppliers } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text12, Text14, Text16, Text28 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getHTPriceFromTTC,
  getTTCPriceFromHT,
  toFixed2,
} from "../../../../../Reusable/Utils";

export interface InvoicePaymentsProps {
  invoice: Invoice;
  inputInvoicePayments: InvoicePayment[];
  setInputInvoicePayments: React.Dispatch<
    React.SetStateAction<InvoicePayment[]>
  >;
}

const _InvoicePayments: React.FC<InvoicePaymentsProps> = (props) => {
  const [paymentAddActive, setPaymentAddActive] = useState(false);

  const [paymentEditActive, setPaymentEditActive] = useState("");

  const [inputAmountHT, setInputAmountHT] = useState("");

  const [inputAmountHTError, setInputAmountHTError] = useState(false);

  const [inputAmountTTC, setInputAmountTTC] = useState("");

  const [inputPaymentMethod, setInputPaymentMethod] = useState("");

  const [inputPaymentDate, setInputPaymentDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputAmountTTCError, setInputAmountTTCError] = useState(false);

  const [htBase, setHtBase] = useState(false);

  const [addFailed, setAddFailed] = useState("");

  const minMax = props.invoice?.type === "Avoir" ? Math.min : Math.max;

  const resetValidation = () => {
    setAddFailed("");
    setInputAmountHTError(false);
  };

  const validateForm = () => {
    setInputAmountHTError(false);
    setInputAmountTTCError(false);
    let result = true;
    if (props.invoice.type === "Avoir" && Number(inputAmountTTC) > 0) {
      setAddFailed("Le montant doit être négatif");
      return false;
    }
    if (props.invoice.type !== "Avoir" && Number(inputAmountTTC) < 0) {
      setAddFailed("Le montant doit être positif");
      return false;
    }
    if (
      inputAmountHT === "" ||
      inputAmountTTC === "" ||
      isNaN(Number(inputAmountTTC))
    ) {
      setInputAmountHTError(true);
      setInputAmountTTCError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            let remainingHt =
              props.invoice.totalPriceHT -
              props.inputInvoicePayments.reduce((acc, invoicePayment) => {
                return acc + invoicePayment.amountHT;
              }, 0);
            let remainingTtc =
              props.invoice.totalPriceTTC -
              props.inputInvoicePayments.reduce((acc, invoicePayment) => {
                return acc + invoicePayment.amountTTC;
              }, 0);
            setPaymentAddActive(true);
            setPaymentEditActive("");
            setInputAmountHT(toFixed2(minMax(0, remainingHt)));
            setInputAmountTTC(toFixed2(minMax(0, remainingTtc)));
            setInputPaymentMethod("");
            setInputPaymentDate(dayjs());
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un paiement
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={paymentAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="amountHT"
                label="Montant HT"
                error={inputAmountHTError}
                value={inputAmountHT}
                onChange={(e) => {
                  if (
                    /^-?\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      !isNaN(Number(e.target.value)) &&
                      !isNaN(Number(props.invoice.globalTVA))
                    ) {
                      setInputAmountTTC(
                        toFixed2(
                          getTTCPriceFromHT(
                            Number(e.target.value),
                            props.invoice.globalTVA
                          )
                        )
                      );
                    }
                    setHtBase(true);
                    setInputAmountHT(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="amountTTC"
                label="Montant TTC"
                error={inputAmountTTCError}
                value={inputAmountTTC}
                onChange={(e) => {
                  if (
                    /^-?\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      !isNaN(Number(e.target.value)) &&
                      !isNaN(Number(props.invoice.globalTVA))
                    ) {
                      setInputAmountHT(
                        toFixed2(
                          getHTPriceFromTTC(
                            Number(e.target.value),
                            props.invoice.globalTVA
                          )
                        )
                      );
                    }
                    setHtBase(false);
                    setInputAmountTTC(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de paiement"
                  inputFormat="DD/MM/YYYY"
                  value={inputPaymentDate}
                  onChange={(value) => setInputPaymentDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Mode de paiement
                </InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputPaymentMethod}
                  label="Mode de paiement"
                  onChange={(e) => {
                    setInputPaymentMethod(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Espèces">Espèces</MenuItem>
                  <MenuItem value="Chèque">Chèque</MenuItem>
                  <MenuItem value="Virement bancaire">
                    Virement bancaire
                  </MenuItem>
                  <MenuItem value="Carte de crédit">Carte de crédit</MenuItem>
                  <MenuItem value="Carte de débit">Carte de débit</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Portefeuille numérique">
                    Portefeuille numérique
                  </MenuItem>
                  <MenuItem value="Prélèvement automatique">
                    Prélèvement automatique
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputInvoicePayments([
                  ...props.inputInvoicePayments,
                  {
                    id: uuid(),
                    amountHT: htBase
                      ? Number(inputAmountHT)
                      : getHTPriceFromTTC(
                          Number(inputAmountTTC),
                          props.invoice.globalTVA
                        ),
                    amountTTC: !htBase
                      ? Number(inputAmountTTC)
                      : getTTCPriceFromHT(
                          Number(inputAmountHT),
                          props.invoice.globalTVA
                        ),
                    paymentDate: inputPaymentDate,
                    paymentMethod: inputPaymentMethod,
                  },
                ]);
                setPaymentAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setPaymentAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputInvoicePayments.map((invoicePayment, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer alignItems="center" justifyContent={"space-between"}>
              <Text16 fontWeight="600">
                {invoicePayment.paymentDate
                  ? dayjs(invoicePayment.paymentDate).format("DD/MM/YYYY")
                  : ""}
              </Text16>
              <FlexContainer
                flexDirection={"column"}
                alignItems={"center"}
                margin={"0 0 0 48px"}
              >
                <FlexContainer>
                  <Text28
                    margin="4px 10px"
                    color={CustomTheme.palette.primary.main}
                  >
                    {toFixed2(Number(invoicePayment.amountHT)) + " €"}
                  </Text28>
                  <Text12 margin="8px 0 0 0">HT</Text12>
                </FlexContainer>
                <Text14 margin="4px 10px">
                  {invoicePayment.paymentMethod}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setPaymentAddActive(false);
                  setPaymentEditActive(invoicePayment.id);
                  setInputAmountHT(toFixed2(invoicePayment.amountHT));
                  setInputAmountTTC(
                    toFixed2(
                      getTTCPriceFromHT(
                        invoicePayment.amountHT,
                        props.invoice.globalTVA
                      )
                    )
                  );
                  setInputPaymentMethod(invoicePayment.paymentMethod);
                  setInputPaymentDate(invoicePayment.paymentDate);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputInvoicePayments];
                  newArr.splice(index, 1);
                  props.setInputInvoicePayments(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={paymentEditActive === invoicePayment.id}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="amountHT"
                    label="Montant HT"
                    error={inputAmountHTError}
                    value={inputAmountHT}
                    onChange={(e) => {
                      if (
                        /^-?\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          !isNaN(Number(e.target.value)) &&
                          !isNaN(Number(props.invoice.globalTVA))
                        ) {
                          setInputAmountTTC(
                            toFixed2(
                              getTTCPriceFromHT(
                                Number(e.target.value),
                                props.invoice.globalTVA
                              )
                            )
                          );
                        }
                        setInputAmountHT(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="amountTTC"
                    label="Montant TTC"
                    error={inputAmountTTCError}
                    value={inputAmountTTC}
                    onChange={(e) => {
                      if (
                        /^-?\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          !isNaN(Number(e.target.value)) &&
                          !isNaN(Number(props.invoice.globalTVA))
                        ) {
                          setInputAmountHT(
                            toFixed2(
                              getHTPriceFromTTC(
                                Number(e.target.value),
                                props.invoice.globalTVA
                              )
                            )
                          );
                        }
                        setInputAmountTTC(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date de paiement"
                      inputFormat="DD/MM/YYYY"
                      value={inputPaymentDate}
                      onChange={(value) => setInputPaymentDate(value!)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mode de paiement
                    </InputLabel>
                    <Select
                      required
                      fullWidth
                      value={inputPaymentMethod}
                      label="Mode de paiement"
                      onChange={(e) => {
                        setInputPaymentMethod(
                          typeof e.target.value === "string"
                            ? e.target.value
                            : ""
                        );
                      }}
                    >
                      <MenuItem value="Espèces">Espèces</MenuItem>
                      <MenuItem value="Chèque">Chèque</MenuItem>
                      <MenuItem value="Virement bancaire">
                        Virement bancaire
                      </MenuItem>
                      <MenuItem value="Carte de crédit">
                        Carte de crédit
                      </MenuItem>
                      <MenuItem value="Carte de débit">Carte de débit</MenuItem>
                      <MenuItem value="PayPal">PayPal</MenuItem>
                      <MenuItem value="Portefeuille numérique">
                        Portefeuille numérique
                      </MenuItem>
                      <MenuItem value="Prélèvement automatique">
                        Prélèvement automatique
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setPaymentAddActive(false);
                  resetValidation();
                  if (validateForm()) {
                    let tmpArray = [...props.inputInvoicePayments];
                    tmpArray[index] = {
                      id: invoicePayment.id,
                      amountHT: Number(inputAmountHT),
                      amountTTC: Number(inputAmountTTC),
                      paymentDate: inputPaymentDate,
                      paymentMethod: inputPaymentMethod,
                    };

                    props.setInputInvoicePayments(tmpArray);
                    setPaymentEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setPaymentEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const InvoicePayments = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_InvoicePayments);

export default _InvoicePayments;
