import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import { Complaint } from "../../../../../models/complaint";
import { CustomField } from "../../../../../models/customField";
import { UserParameter } from "../../../../../models/userParameters";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import {
  COMPLAINT_TABLE_ORDER,
  COMPLAINT_TABLE_ORDER_BY,
  COMPLAINT_TABLE_SEARCH_BY,
  COMPLAINT_TABLE_VIEW,
  getContactDescription,
  getEmployeeDescription,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";
import AddInteraction from "../../Interactions/AddInteraction";
import AddTask from "../../Tasks/AddTask";
import AddComplaint from "../AddComplaint";
import ComplaintComments from "../ComplaintComments";
import DeleteComplaint from "../DeleteComplaint";
import EditComplaint from "../EditComplaint";
import ExportComplaints from "../ExportComplaints";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface ComplaintTableProps {
  additionalCriterias?: string;
  actionsOnAdd?: (() => void)[];
  actionsOnEdit?: (() => void)[];
  actionsOnDelete?: (() => void)[];
  actionsOnGenerate?: (() => void)[];
  triggerGetTable?: boolean;
}

const ComplaintTable: React.FC<ComplaintTableProps> = ({
  additionalCriterias,
  actionsOnAdd,
  actionsOnEdit,
  actionsOnDelete,
  actionsOnGenerate,
  triggerGetTable,
}) => {
  const getComplaintsPage = () => {
    setTableLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    AxiosHttpClient.get<any>(
      "api/v1/weeventpro/complaints/page",
      {
        criteriaList:
          inputField +
          "=" +
          inputSearch +
          (additionalCriterias ? "," + additionalCriterias : ""),
        pageNumber: page - 1,
        pageSize: limit,
        sort: fieldsCorrespondanceMapForOrders.get(orderBy),
        order: order,
      },
      signal
    )
      .then((res) => {
        setComplaints(res.content);
        setTableLoading(false);
        setTotalElements(res.totalElements);
      })
      .catch((err) => {});
  };

  const previousController = useRef<AbortController>(null!);

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/complaint")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/complaint"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        COMPLAINT_TABLE_ORDER +
        "," +
        COMPLAINT_TABLE_ORDER_BY +
        "," +
        COMPLAINT_TABLE_SEARCH_BY +
        "," +
        COMPLAINT_TABLE_VIEW,
    }).then((res) => {
      setOrder(
        getUserParameterValueByName(COMPLAINT_TABLE_ORDER, res) === "asc"
          ? "asc"
          : "desc"
      );
      setOrderBy(getUserParameterValueByName(COMPLAINT_TABLE_ORDER_BY, res));
      setInputField(
        getUserParameterValueByName(COMPLAINT_TABLE_SEARCH_BY, res)
      );
      setInputView(getUserParameterValueByName(COMPLAINT_TABLE_VIEW, res));
    });
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [inputSearch, setInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Complaint | null>(
    null
  );

  const [popupUpCreateTask, setPopupUpCreateTask] = useState(false);

  const [popupUpCreateTaskComplaint, setPopupUpCreateTaskComplaint] = useState<
    Complaint | undefined
  >(undefined);

  const [popupUpCreateInteraction, setPopupUpCreateInteraction] =
    useState(false);

  const [
    popupUpCreateInteractionComplaint,
    setPopupUpCreateInteractionComplaint,
  ] = useState<Complaint | undefined>(undefined);

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [popupCommentsActive, setPopupCommentsActive] =
    useState<Complaint | null>(null);

  const [inputView, setInputView] = useState("");

  const [selectedComplaint, setSelectedComplaint] = useState<
    Complaint | undefined
  >(undefined);

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorElTable, setAnchorElTable] = React.useState<null | HTMLElement>(
    null
  );

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  const [inputField, setInputField] = useState("");

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);

  const [totalElements, setTotalElements] = useState(0);

  const [complaints, setComplaints] = useState<Complaint[]>([]);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getComplaintsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, page, limit, orderBy, order, triggerGetTable]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, limit, orderBy, order]);

  useEffect(() => {
    setInputSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  const setOrderParameters = (orderValue: string, orderByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: COMPLAINT_TABLE_ORDER,
        parameterValue: orderValue,
      },
      {
        parameterName: COMPLAINT_TABLE_ORDER_BY,
        parameterValue: orderByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrderParameters(isAsc ? "desc" : "asc", cellId);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "Date de réclamation",
      "Numéro de réclamation",
      "Titre",
      "Client",
      "Catégorie",
      "Priorité",
      "Statut",
    ],
  };

  const getViewById = (id: string) => {
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : defaultView;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  let fieldsCorrespondanceMapForOrders: Map<string, string> = new Map([
    ["Numéro de réclamation", "complaintNumber"],
    ["Titre", "title"],
    ["Date de réclamation", "complaintDate"],
    ["Date de résolution prévue", "expectedResolutionDate"],
    ["Date de résolution", "resolutionDate"],
    ["Client", "client"],
    ["Tags", "tags"],
    ["Catégorie", "category"],
    ["Priorité", "priority"],
    ["Canal de réclamation", "complaintChannel"],
    ["Statut", "status"],
    ["Description", "description"],
  ]);

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["Numéro de réclamation", true],
      ["Titre", true],
      ["Date de réclamation", true],
      ["Date de résolution prévue", true],
      ["Date de résolution", true],
      ["Client", true],
      ["Responsables", false],
      ["Tags", true],
      ["Catégorie", true],
      ["Priorité", true],
      ["Canal de réclamation", true],
      ["Statut", true],
      ["Description", true],
      ["Ventes associées", false],
      ["Factures associées", false],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (
    complaint: Complaint,
    customFieldId: string
  ) => {
    if (complaint.customFields !== null) {
      for (var customFieldValue of complaint.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, complaint: Complaint) => {
    let headValuesMap: Map<string, string> = new Map([
      ["Numéro de réclamation", complaint.complaintNumber],
      ["Titre", complaint.title],
      [
        "Date de réclamation",
        complaint.complaintDate
          ? dayjs(complaint.complaintDate).format("DD/MM/YYYY")
          : "",
      ],
      [
        "Date de résolution prévue",
        complaint.expectedResolutionDate
          ? dayjs(complaint.expectedResolutionDate).format("DD/MM/YYYY")
          : "",
      ],
      [
        "Date de résolution",
        complaint.resolutionDate
          ? dayjs(complaint.resolutionDate).format("DD/MM/YYYY")
          : "",
      ],
      ["Client", getContactDescription(complaint.client)],
      [
        "Responsables",
        complaint.responsibles
          .map((responsible) => getEmployeeDescription(responsible))
          .join(", "),
      ],
      ["Tags", complaint.tags.join(", ")],
      ["Catégorie", complaint.category],
      ["Priorité", complaint.priority],
      ["Canal de réclamation", complaint.complaintChannel],
      ["Description", complaint.description],
      [
        "Ventes associées",
        complaint.associatedSales.map((sale) => sale.saleNumber).join(", "),
      ],
      [
        "Factures associées",
        complaint.associatedInvoices
          .map((invoice) => invoice.invoiceNumber)
          .join(", "),
      ],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(
        customField.name,
        getCustomFieldValues(complaint, customField.id)
      );
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, complaint: Complaint) => {
    let headValuesMap: Map<string, React.JSX.Element> = new Map([
      [
        "Statut",
        <FlexContainer justifyContent="center">
          <FlexContainer
            justifyContent="center"
            backgroundColor={getStatusBackgroundColor(complaint.status)}
            color="white"
            padding="4px 8px"
            $borderRadius="50px"
          >
            {complaint.status}
          </FlexContainer>
        </FlexContainer>,
      ],
      [
        "Commentaires",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupCommentsActive(complaint);
            }}
            size="large"
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        </FlexContainer>,
      ],
      [
        "Actions",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupEditActive(complaint);
            }}
            size="large"
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupDeleteActive([complaint.id]);
            }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAnchorElTable(e.currentTarget);
              setSelectedComplaint(complaint);
            }}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
        </FlexContainer>,
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "Abandonnée") return CustomTheme.palette.error.dark;
    if (status === "Résolue") return CustomTheme.palette.success.light;
    if (status === "En cours") return CustomTheme.palette.primary.dark;
    if (status === "En attente") return "#DD9933";
  };

  const getValueCells = (headValue: string, complaint: Complaint) => {
    let simpleValue = getSimpleCellValue(headValue, complaint);
    let complexValue = getComplexCellValue(headValue, complaint);
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  useEffect(() => {
    getClientsAction("");
    getCustomFields();
    getViews();
    getDishesAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Menu
        id="invoice-menu"
        anchorEl={anchorElTable}
        open={Boolean(anchorElTable)}
        onClose={(e) => setAnchorElTable(null)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            marginX: "20px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorElTable(null);
            setPopupUpCreateTask(true);
            setPopupUpCreateTaskComplaint(selectedComplaint);
          }}
        >
          <FlexContainer alignItems="center">
            <AssignmentTwoToneIcon
              sx={{ mr: "8px" }}
              fontSize="small"
              color="primary"
            />
            Créer une tâche
          </FlexContainer>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorElTable(null);
            setPopupUpCreateInteraction(true);
            setPopupUpCreateInteractionComplaint(selectedComplaint);
          }}
        >
          <FlexContainer alignItems="center">
            <QuestionAnswerTwoToneIcon
              sx={{ mr: "8px" }}
              fontSize="small"
              color="primary"
            />
            Créer une intéraction
          </FlexContainer>
        </MenuItem>
      </Menu>
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="8px 10px 8px 8px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem disabled={true}>
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "customization,complaints",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                setPopupDeleteActive([...selected]);
                setSelected([]);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          inputField={inputField}
          setInputField={setInputField}
          setInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui sx={{ position: "relative" }}>
        {tableLoading && (
          <FlexContainer
            sx={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 57.297px)",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              top: "57.297px",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: "2",
              opacity: "0.7",
            }}
          >
            <CircularProgress size={80} />
          </FlexContainer>
        )}
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === complaints.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = complaints.map(
                        (complaint) => complaint.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading && complaints.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "150px", borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            )}
            {!tableLoading && complaints.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "100px", borderBottom: "none" }}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune réclamation retrouvée
                  </Text14>
                </TableCell>
              </TableRow>
            )}
            {complaints.map((item) => (
              <TableRow
                key={item.id}
                onClick={(e) => {
                  navigate(`/feature/complaints/${item.id}`);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={selected.indexOf(item.id) > -1}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newArr = [...selected];
                      const index = selected.indexOf(item.id);
                      if (index > -1) {
                        newArr.splice(index, 1);
                        setSelected(newArr);
                      } else {
                        setSelected([...selected, item.id]);
                      }
                    }}
                  />
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell key={index}>
                    {getValueCells(headCell.label, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer sx={{ marginTop: "16px" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalElements}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={(dataKey) => {
            setPage(1);
            setLimit(dataKey);
          }}
        />
      </BlockContainer>
      {popupAddActive && (
        <AddComplaint
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          actionsOnAdd={[...(actionsOnAdd || []), getComplaintsPage]}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteComplaint
          popupActive={popupDeleteActive}
          setPopupActive={setPopupDeleteActive}
          actionsOnDelete={[...(actionsOnDelete || []), getComplaintsPage]}
        />
      )}
      {popupEditActive !== null && (
        <EditComplaint
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          actionsOnEdit={[...(actionsOnEdit || []), getComplaintsPage]}
        />
      )}
      {popupExportActive && (
        <ExportComplaints
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
      {popupUpCreateTask && (
        <AddTask
          popupActive={popupUpCreateTask}
          setPopupActive={setPopupUpCreateTask}
          actionsOnAdd={[...(actionsOnAdd || []), getComplaintsPage]}
          associatedComplaint={popupUpCreateTaskComplaint}
        />
      )}
      {popupUpCreateInteraction && (
        <AddInteraction
          popupActive={popupUpCreateInteraction}
          setPopupActive={setPopupUpCreateInteraction}
          actionsOnAdd={[...(actionsOnAdd || []), getComplaintsPage]}
          associatedComplaint={popupUpCreateInteractionComplaint}
        />
      )}
      {popupCommentsActive !== null && (
        <ComplaintComments
          popupActive={popupCommentsActive}
          setPopupActive={setPopupCommentsActive}
        />
      )}
    </Paper>
  );
};

export default ComplaintTable;
