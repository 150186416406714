import { createFilterOptions } from "@mui/core";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Contact } from "../../../../../models/contact";
import { EmailTemplate } from "../../../../../models/emailTemplate";
import { Employee } from "../../../../../models/employee";
import { File } from "../../../../../models/file";
import { Interaction } from "../../../../../models/interaction";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getContactsAction } from "../../../../../store/Crm/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getContacts, getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  getEmployeeDescription,
  trimLongString,
} from "../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface SendReportProps {
  popupActive: Interaction | null;
  employees: Employee[];
  contacts: Contact[];
  setPopupActive: React.Dispatch<React.SetStateAction<Interaction | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getContactsAction: typeof getContactsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _SendReport: React.FC<SendReportProps> = ({
  popupActive,
  employees,
  contacts,
  getEmployeesAction,
  getContactsAction,
  setPopupActive,
}) => {
  const sendInvoice = () => {
    AxiosHttpClient.post<EmailTemplate[]>(
      "api/v1/weeventpro/interactions/sendReport",
      {
        to: inputToEmails.map((inputToEmails) =>
          typeof inputToEmails === "string"
            ? inputToEmails
            : inputToEmails.email
        ),
        cc: inputCCEmails.map((inputCCEmail) =>
          typeof inputCCEmail === "string" ? inputCCEmail : inputCCEmail.email
        ),
        subject: inputSubject,
        content: inputContent,
        files: inputFiles,
      }
    )
      .then((res) => {
        setPopupActive(null);
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  const [user, setUser] = useState<Employee | null>(null);

  const [inputSubject, setInputSubject] = useState(
    "Compte rendu de " +
      (popupActive!.type === "Appel téléphonique" ? "l'appel" : "la réunion") +
      " du " +
      dayjs(popupActive?.startDateTime).format("DD/MM/YYYY")
  );

  const [inputSubjectError, setInputSubjectError] = useState(false);

  const [inputContent, setInputContent] = useState(
    "Je tiens à vous remercier pour votre participation à " +
      (popupActive!.type === "Appel téléphonique" ? "l'appel" : "la réunion") +
      " qui s'est tenue le " +
      dayjs(popupActive?.startDateTime).format("DD/MM/YYYY") +
      ". Vous trouverez ci-dessous un résumé des points abordés:\n\n" +
      popupActive!.report +
      "\n\nCordialement\n" +
      getEmployeeDescription(user)
  );

  const [inputContentError, setInputContentError] = useState(false);

  const [inputCCEmails, setInputCCEmails] = useState<
    (Employee | Contact | string)[]
  >([]);

  const [inputCCEmailsText, setInputCCEmailsText] = useState("");

  const [inputToEmails, setInputToEmails] = useState<
    (Contact | Employee | string)[]
  >([]);

  const [inputToEmailsText, setInputToEmailsText] = useState("");

  const [inputToError, setInputToError] = useState(false);

  const [inputFiles, setInputFiles] = useState<File[]>([]);

  const [sendFailed, setSendFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const filterEmployeesContactsEmailsOptions = createFilterOptions<
    Employee | Contact
  >({
    stringify: (option) => option.email,
  });

  const validateForm = () => {
    setInputToError(false);
    setInputSubjectError(false);
    setInputContentError(false);
    let result = true;
    if (inputToEmails.length === 0) {
      setInputToError(true);
      result = false;
    }
    if (inputSubject === "") {
      setInputSubjectError(true);
      result = false;
    }
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) {
      setSendFailed("Champs manquants");
    }
    return result;
  };

  let removeDocument = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  useEffect(() => {
    if (user) {
      setInputContent(
        "Bonjour,\n\nJe tiens à vous remercier pour votre participation à " +
          (popupActive!.type === "Appel téléphonique"
            ? "l'appel"
            : "la réunion") +
          " qui s'est tenue le " +
          dayjs(popupActive?.startDateTime).format("DD/MM/YYYY") +
          ". Vous trouverez ci-dessous un résumé des points abordés:\n\n" +
          popupActive!.report +
          "\n\nCordialement\n" +
          getEmployeeDescription(user)
      );
      setInputCCEmails([user]);
      setInputToEmails(
        [
          ...(popupActive!.fromContact ? [popupActive!.fromContact] : []),
          ...popupActive!.toContacts,
          ...popupActive!.contactsParticipants,
          ...(popupActive!.fromEmployee ? [popupActive!.fromEmployee] : []),
          ...popupActive!.toEmployees,
          ...popupActive!.employeesParticipants,
        ].filter((to) => typeof to === "string" || to.id !== user.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getEmployeesAction("");
    getContactsAction("");
    getUserInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Envoyer par email"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {sendFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setSendFailed("");
              }}
            >
              {sendFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete
                id="to"
                filterOptions={filterEmployeesContactsEmailsOptions}
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                getOptionLabel={(option) => {
                  return typeof option === "string" ? option : option.email;
                }}
                options={[...employees, ...contacts]}
                value={inputToEmails}
                onChange={(event, newValue) => {
                  setInputToEmails(newValue!);
                }}
                inputValue={inputToEmailsText}
                onInputChange={(event, newInputValue) => {
                  setInputToEmailsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={inputToError}
                    {...params}
                    variant="outlined"
                    name="to"
                    label="À"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete
                id="cc"
                filterOptions={filterEmployeesContactsEmailsOptions}
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                filterSelectedOptions
                getOptionLabel={(option) => {
                  return typeof option === "string" ? option : option.email;
                }}
                options={[...employees, ...contacts]}
                value={inputCCEmails}
                onChange={(event, newValue) => {
                  setInputCCEmails(newValue!);
                }}
                inputValue={inputCCEmailsText}
                onInputChange={(event, newInputValue) => {
                  setInputCCEmailsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="cc"
                    label="CC"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="subject"
                label="Objet"
                error={inputSubjectError}
                value={inputSubject}
                onChange={(e) => setInputSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              {inputFiles.length > 0 && (
                <Grid
                  container
                  border="2px dashed rgba(0,0,0,0.1)"
                  margin={"0 0 8px 0"}
                >
                  {inputFiles!.map((justification, index) => (
                    <Grid>
                      <FlexContainer
                        elevation={3}
                        margin="8px"
                        height="40px"
                        justifyContent="space-between"
                        alignItems="center"
                        $borderRadius="16px"
                      >
                        <FlexContainer margin="0 0 0 8px" alignItems="center">
                          <AttachFileRoundedIcon
                            fontSize="medium"
                            color="primary"
                          />
                          <Text14
                            cursor="pointer"
                            margin="0 4px"
                            color={CustomTheme.palette.secondary.main}
                            textDecorationLine="underline"
                            onClick={(e) => {
                              fetch(justification.url)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute(
                                    "download",
                                    justification.name
                                  );
                                  document.body.appendChild(link);
                                  link.click();
                                });
                            }}
                          >
                            {trimLongString(justification.name)}
                          </Text14>
                        </FlexContainer>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            removeDocument(index);
                          }}
                          size="medium"
                          style={{
                            margin: "0 8px",
                            padding: "4px",
                            zIndex: "1",
                          }}
                        >
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </FlexContainer>
                    </Grid>
                  ))}
                </Grid>
              )}
              <FlexContainer>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="content"
                  label="Corps du message"
                  multiline
                  minRows={5}
                  error={inputContentError}
                  value={inputContent}
                  onChange={(e) => {
                    setInputContent(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <label htmlFor="contained-button-file">
                          <Input
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => {
                              filesPreviewHandler(e.target.files);
                            }}
                          />
                          <IconButton component="span">
                            <AttachFileRoundedIcon fontSize="medium" />
                          </IconButton>
                        </label>
                      </>
                    ),
                  }}
                />
              </FlexContainer>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) sendInvoice();
            else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Envoyer
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const SendReport = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
    contacts: getContacts(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getContactsAction: getContactsAction,
      },
      dispatch
    )
)(_SendReport);

export default SendReport;
