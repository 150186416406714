import { Alert, Divider, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Employee } from "../../../../models/employee";
import { AxiosHttpClient } from "../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Text20 } from "../../../../styles/Text";

export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = (props) => {
  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setInputFirstName(res.firstName);
        setInputLastName(res.lastName);
        setInputPhoneNumber(res.phoneNumber);
        setInputEmail(res.email);
      }
    );
  };

  const updatePersonalInformations = () => {
    AxiosHttpClient.put("api/v1/weeventpro/employees/personalInformations", {
      firstName: inputFirstName,
      lastName: inputLastName,
      phoneNumber: inputPhoneNumber,
      email: inputEmail,
    })
      .then(() => {
        getUserInfos();
        setIsSaveInformationsLoading(false);
      })
      .catch((err) => {
        setEditPersonalInformationsFailed(err.cause);
        setIsSaveInformationsLoading(false);
      });
  };

  const updatePassword = () => {
    AxiosHttpClient.put("api/v1/weeventpro/user/changePassword", {
      oldPassword: inputActualPassword,
      newPassword: inputNewPassword,
    })
      .then(() => {
        getUserInfos();
        setIsUpdatePasswordLoading(false);
        setInputActualPassword("");
        setInputNewPassword("");
        setInputNewPasswordConfirmation("");
      })
      .catch((err) => {
        setEditPasswordFailed(err.cause);
        setIsUpdatePasswordLoading(false);
      });
  };

  const validatePersonalInformationsForm = () => {
    setInputFirstNameError(false);
    setInputLastNameError(false);
    let result = true;
    if (inputFirstName === "") {
      setInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      setInputLastNameError(true);
      result = false;
    }
    if (!result) {
      setEditPersonalInformationsFailed("Champs manquants");
    }
    return result;
  };

  const validatePasswordForm = () => {
    setInputNewPasswordError(false);
    setInputNewPasswordConfirmationError(false);
    let result = true;
    if (inputNewPassword !== inputNewPasswordConfirmation) {
      setInputNewPasswordError(true);
      setInputNewPasswordConfirmationError(true);
      setEditPersonalInformationsFailed(
        "Les mots de passe ne sont pas identiques"
      );
      return false;
    }
    if (!result) {
      setEditPersonalInformationsFailed("Champs manquants");
    }
    return result;
  };

  const [inputFirstName, setInputFirstName] = useState("");

  const [inputFirstNameError, setInputFirstNameError] = useState(false);

  const [inputLastName, setInputLastName] = useState("");

  const [inputLastNameError, setInputLastNameError] = useState(false);

  const [inputPhoneNumber, setInputPhoneNumber] = useState("");

  const [inputEmail, setInputEmail] = useState("");

  const [inputActualPassword, setInputActualPassword] = useState("");

  const [inputNewPassword, setInputNewPassword] = useState("");

  const [inputNewPasswordError, setInputNewPasswordError] = useState(false);

  const [inputNewPasswordConfirmation, setInputNewPasswordConfirmation] =
    useState("");

  const [
    inputNewPasswordConfirmationError,
    setInputNewPasswordConfirmationError,
  ] = useState(false);

  const [isSaveInformationsLoading, setIsSaveInformationsLoading] =
    useState(false);

  const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false);

  const [editPersonalInformationsFailed, setEditPersonalInformationsFailed] =
    useState("");

  const [editPasswordFailed, setEditPasswordFailed] = useState("");

  useEffect(() => {
    getUserInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "8px",
          md: "32px",
        },
      }}
    >
      <FlexContainer>
        <Text20>Mon profil</Text20>
      </FlexContainer>
      <FlexContainer
        flexDirection={"column"}
        margin="16px 0"
        backgroundColor="white"
        sx={{
          padding: {
            xs: "0",
            sm: "32px 16px",
          },
        }}
        elevation={3}
        $borderRadius="4px"
      >
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Informations personnelles
          </Text20>
        </BlockContainer>
        {editPersonalInformationsFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditPersonalInformationsFailed("");
              }}
            >
              {editPersonalInformationsFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
          sx={{ maxWidth: "1200px" }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="firstName"
              label="Prénom"
              error={inputFirstNameError}
              value={inputFirstName}
              onChange={(e) => {
                setInputFirstName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="lastName"
              label="Nom"
              error={inputLastNameError}
              value={inputLastName}
              onChange={(e) => {
                setInputLastName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="phoneNumber"
              label="N° de téléphone personnel"
              value={inputPhoneNumber}
              onChange={(e) => {
                setInputPhoneNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="email"
              label="E-mail"
              value={inputEmail}
              onChange={(e) => {
                setInputEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
            alignItems={"center"}
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isSaveInformationsLoading}
                loading={isSaveInformationsLoading}
                onClick={() => {
                  setIsSaveInformationsLoading(true);
                  if (validatePersonalInformationsForm())
                    updatePersonalInformations();
                  else setIsSaveInformationsLoading(false);
                }}
              >
                Enregistrer
              </ButtonMui>
            </FlexContainer>
          </Grid>
        </Grid>
        <Divider sx={{ mt: "32px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Changer mon mot de passe
          </Text20>
        </BlockContainer>
        {editPasswordFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditPasswordFailed("");
              }}
            >
              {editPasswordFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
          sx={{ maxWidth: "1200px" }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="actualPassword"
              label="Mot de passe actuel"
              type="password"
              autoComplete="new-password"
              value={inputActualPassword}
              onChange={(e) => {
                setInputActualPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="actualPassword"
              label="Nouveau mot de passe"
              type="password"
              autoComplete="new-password"
              error={inputNewPasswordError}
              value={inputNewPassword}
              onChange={(e) => {
                setInputNewPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="actualPassword"
              label="Confimer le nouveau mot de passe"
              type="password"
              autoComplete="new-password"
              error={inputNewPasswordConfirmationError}
              value={inputNewPasswordConfirmation}
              onChange={(e) => {
                setInputNewPasswordConfirmation(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
            alignItems={"center"}
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isUpdatePasswordLoading}
                loading={isUpdatePasswordLoading}
                onClick={() => {
                  setIsUpdatePasswordLoading(true);
                  if (validatePasswordForm()) updatePassword();
                  else setIsUpdatePasswordLoading(false);
                }}
              >
                Enregistrer
              </ButtonMui>
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
    </BlockContainer>
  );
};

export default Profile;
