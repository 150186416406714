import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { DishService } from "../../services/DishService";
import { RootAction } from "../types";
import {
  GET_DISHES,
  GetDishesActionFailure,
  getDishesActionFailure,
  GetDishesActionSuccess,
  getDishesActionSuccess,
} from "./actions";

export const dishesEpic: Epic<
  RootAction,
  GetDishesActionSuccess | GetDishesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_DISHES),
    switchMap((action) =>
      from(
        DishService.getDishes({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getDishesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getDishesActionFailure()]))
        )
      )
    )
  );

export const dishEpic = combineEpics(dishesEpic);
