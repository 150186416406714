import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useState } from "react";
import { Comment } from "../../../../../../models/comment";
import { Complaint } from "../../../../../../models/complaint";
import { File } from "../../../../../../models/file";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../../styles/Utils";
import { trimLongString } from "../../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

const Input = styled("input")({
  display: "none",
});

export interface EditCommentProps {
  complaint: Complaint | null;
  popupActive: Comment | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Comment | null>>;
  actionsOnEdit?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const EditComment: React.FC<EditCommentProps> = ({
  complaint,
  popupActive,
  setPopupActive,
  actionsOnEdit,
}) => {
  /* TODO */
  const editComment = () => {
    if (complaint) {
      AxiosHttpClient.put("api/v1/weeventpro/complaints", {
        id: complaint.id,
        complaintNumber: complaint.complaintNumber,
        title: complaint.title,
        clientId: complaint.client?.id,
        responsiblesIds: complaint.responsibles.map(
          (responsible) => responsible.id
        ),
        complaintDate: complaint.complaintDate
          ? dayjs(complaint.complaintDate)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : null,
        expectedResolutionDate: complaint.expectedResolutionDate
          ? dayjs(complaint.expectedResolutionDate)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : null,
        resolutionDate: complaint.resolutionDate
          ? dayjs(complaint.resolutionDate)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : null,
        tags: complaint.tags,
        category: complaint.category,
        priority: complaint.priority,
        complaintChannel: complaint.complaintChannel,
        status: complaint.status,
        description: complaint.description,
        associatedSalesIds: complaint.associatedSales.map((sale) => sale.id),
        associatedInvoicesIds: complaint.associatedInvoices.map(
          (invoice) => invoice.id
        ),
        comments: [
          ...complaint.comments.map((comment) =>
            comment.id === popupActive?.id
              ? {
                  id: popupActive.id,
                  content: inputContent,
                  commentWriterId: popupActive.commentWriter?.id,
                  files: inputFiles,
                  creationDateTime: popupActive.creationDateTime,
                }
              : {
                  id: comment.id,
                  content: comment.content,
                  commentWriterId: comment.commentWriter?.id,
                  files: comment.files,
                  creationDateTime: comment.creationDateTime,
                }
          ),
        ],
        customFields: complaint.customFields,
      })
        .then(() => {
          setPopupActive(null);
          actionsOnEdit?.forEach((action) => action());
        })
        .catch((err) => {
          setEditFailed(err.cause);
          setIsLoading(false);
          scroll.scrollToTop({
            duration: 500,
            smooth: true,
            containerId: "editComplaintCommentDialogContentId",
          });
        });
    }
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  const validateForm = () => {
    setInputContentError(false);
    let result = true;
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) setEditFailed("Champs manquants");
    return result;
  };

  const [inputContent, setInputContent] = useState(popupActive!.content);

  const [inputContentError, setInputContentError] = useState(false);

  const [inputFiles, setInputFiles] = useState<File[]>(popupActive!.files);

  const [editFailed, setEditFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  let removeDocument = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un commentaire"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editOpportunityCommentDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              {inputFiles.length > 0 && (
                <Grid
                  container
                  border="2px dashed rgba(0,0,0,0.1)"
                  margin={"0 0 8px 0"}
                >
                  {inputFiles!.map((justification, index) => (
                    <Grid>
                      <FlexContainer
                        elevation={3}
                        margin="8px"
                        height="40px"
                        justifyContent="space-between"
                        alignItems="center"
                        $borderRadius="16px"
                      >
                        <FlexContainer margin="0 0 0 8px" alignItems="center">
                          <AttachFileRoundedIcon
                            fontSize="medium"
                            color="primary"
                          />
                          <Text14
                            cursor="pointer"
                            margin="0 4px"
                            color={CustomTheme.palette.secondary.main}
                            textDecorationLine="underline"
                            onClick={(e) => {
                              fetch(justification.url)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute(
                                    "download",
                                    justification.name
                                  );
                                  document.body.appendChild(link);
                                  link.click();
                                });
                            }}
                          >
                            {trimLongString(justification.name)}
                          </Text14>
                        </FlexContainer>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            removeDocument(index);
                          }}
                          size="medium"
                          style={{
                            margin: "0 8px",
                            padding: "4px",
                            zIndex: "1",
                          }}
                        >
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </FlexContainer>
                    </Grid>
                  ))}
                </Grid>
              )}

              <TextField
                fullWidth
                variant="outlined"
                name="comment"
                label="Commentaire"
                multiline
                minRows={4}
                error={inputContentError}
                value={inputContent}
                onChange={(e) => {
                  setInputContent(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <label htmlFor="editCommentFiles">
                        <Input
                          id="editCommentFiles"
                          multiple
                          type="file"
                          onChange={(e) => {
                            filesPreviewHandler(e.target.files);
                          }}
                        />
                        <IconButton component="span">
                          <AttachFileRoundedIcon fontSize="medium" />
                        </IconButton>
                      </label>
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FlexContainer>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editComment();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditComment;
