import {
  ClickAwayListener,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";

export interface AutocompleteNotClickableProps<T> {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  label: string;
  options: T[];
  getOptionLabel: (object: T) => React.JSX.Element;
  inputValue: string;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  InputProps?: Partial<OutlinedInputProps> | undefined;
}

const AutocompleteNotClickable = <T extends unknown>(
  props: AutocompleteNotClickableProps<T>
) => {
  const [focused, setFocused] = useState(false);

  let suggestionsListComponent;

  if (props.inputValue) {
    if (props.options.length && focused) {
      console.log(focused);

      suggestionsListComponent = (
        <BlockContainer
          id="autocompletePaper"
          elevation={6}
          sx={{
            maxHeight: "375px",
            overflow: "auto",
            position: "absolute",
            top: "56px",
            background: "white",
            zIndex: "2",
            width: "100%",
          }}
        >
          {props.options.map((option, index) => (
            <FlexContainer
              padding="6px 16px"
              alignItems="center"
              minHeight="100px"
              key={index}
              sx={{
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  cursor: "pointer",
                },
              }}
            >
              {props.getOptionLabel(option)}
            </FlexContainer>
          ))}
        </BlockContainer>
      );
    } else {
      console.log(focused);
      suggestionsListComponent = <Fragment></Fragment>;
    }
  }

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <BlockContainer position="relative">
          <TextField
            id={props.id}
            variant="outlined"
            fullWidth
            name={props.name}
            label={props.label}
            onFocus={(e) => setFocused(true)}
            onChange={props.onChange}
            value={props.inputValue}
            autoComplete="new-password"
            InputProps={props.InputProps}
          />
          {suggestionsListComponent}
        </BlockContainer>
      </ClickAwayListener>
    </Fragment>
  );
};

export default AutocompleteNotClickable;
