import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Ingredient } from "../../../../../../models/ingredients";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getUnitDescription } from "../../../../../Reusable/Utils";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MapFieldsProps {
  ingredients: Ingredient[];
  fetchedValues: string[][];
  outputErrors: string[];
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapFields: React.FC<MapFieldsProps> = (props) => {
  let fields = [
    "Image",
    "Nom",
    "Catégorie",
    "Quantité",
    "Prix",
    "Allergies",
    "Quantité minimale",
    "Quantité idéale",
    "Suggestion par évènement",
    "À vendre",
    "Durée d'expiration",
    "Unités d'achat",
    "Unités de stockage",
    "Unités de production",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
  ];

  const getValueCells = (headValue: string, ingredient: Ingredient) => {
    let headValuesMap: Map<string, string | React.JSX.Element> = new Map([
      ["Nom", ingredient.name],
      ["Catégorie", ingredient.category],
      ["Allergies", ingredient.allergies.join(", ")],
      [
        "Quantité minimale",
        ingredient.minimalQuantity + " " + ingredient.globalStorageUnit?.name,
      ],
      [
        "Quantité idéale",
        ingredient.idealQuantity + " " + ingredient.globalStorageUnit?.name,
      ],
      [
        "Suggestion par évènement",
        ingredient.suggestionPerEvent ? "Oui" : "Non",
      ],
      ["À vendre", ingredient.ingredientForSale ? "Oui" : "Non"],
      [
        "Durée d'expiration",
        ingredient.averageExpiryDelay + " " + ingredient.averageExpiryDelayUnit,
      ],
      [
        "Unités d'achat",
        ingredient.purchaseUnits
          .map((purchaseUnit) => getUnitDescription(purchaseUnit))
          .join(", "),
      ],
      [
        "Unités de stockage",
        ingredient?.storageUnits
          .map((storageUnit) => getUnitDescription(storageUnit))
          .join(", "),
      ],
      [
        "Unités de production",
        ingredient.productionUnits
          .map((productionUnit) => getUnitDescription(productionUnit))
          .join(", "),
      ],
      [
        "Énergie (Kj)",
        ingredient.energyKj + (ingredient.energyKj === null ? "" : " Kj"),
      ],
      [
        "Énergie (Kcal)",
        ingredient.energyKcal + (ingredient.energyKcal === null ? "" : " Kcal"),
      ],
      [
        "Protéines",
        ingredient.proteins + (ingredient.proteins === null ? "" : " g"),
      ],
      [
        "Glucides",
        ingredient.carbohydrates +
          (ingredient.carbohydrates === null ? "" : " g"),
      ],
      ["Lipides", ingredient.lipids + (ingredient.lipids === null ? "" : " g")],
      ["Sucres", ingredient.sugar + (ingredient.sugar === null ? "" : " g")],
      [
        "Acides gras saturés",
        ingredient.saturatedFattyAcids +
          (ingredient.saturatedFattyAcids === null ? "" : " g"),
      ],
      ["Sel", ingredient.salt + (ingredient.salt === null ? "" : " g")],
      [
        "Calcium",
        ingredient.calcium + (ingredient.calcium === null ? "" : " g"),
      ],
      [
        "Vitamine D",
        ingredient.vitaminD + (ingredient.vitaminD === null ? "" : " g"),
      ],
      [
        "Fibres alimentaires",
        ingredient.dietaryFiber +
          (ingredient.dietaryFiber === null ? "" : " g"),
      ],
      [
        "Cholestérol",
        ingredient.cholesterol + (ingredient.cholesterol === null ? "" : " g"),
      ],
    ]);
    let headComplexValuesMap = new Map([
      [
        "Image",
        <BlockContainer
          width="100px"
          height="100px"
          border="rgba(0,0,0,0.1) solid 1px"
          justifyContent="center"
          position="relative"
          textAlign="center"
          backgroundColor="white"
          margin="auto"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            alt=""
            src={ingredient?.imageUrl}
          />
        </BlockContainer>,
      ],
    ]);
    return headValuesMap.get(headValue) !== undefined
      ? headValuesMap.get(headValue)
      : headComplexValuesMap.get(headValue);
  };

  const classes = useStyles();

  return (
    <BlockContainer>
      <TableContainerMui $maxHeight="45vh">
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map((header, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.ingredients.map((ingredient, index) => (
              <TableRow key={index}>
                {fields.map((field, index2) => (
                  <TableCell key={index2}>
                    {getValueCells(field, ingredient)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <BlockContainer
        margin="16px"
        border="1px solid rgba(0,0,0,0.2)"
        $borderRadius="4px"
        $maxHeight="15vh"
        overflow="auto"
      >
        {props.outputErrors.map((outputError, index) => (
          <Text14 color={CustomTheme.palette.secondary.main} key={index}>
            {outputError}
          </Text14>
        ))}
        {props.outputErrors.length === 0 && (
          <Text14>Fichier valide! Vous pouvez importer vos données</Text14>
        )}
      </BlockContainer>
    </BlockContainer>
  );
};

export default MapFields;
