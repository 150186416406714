import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { SupplierOrderService } from "../../services/SupplierOrderService";
import { RootAction } from "../types";
import {
  GET_SUPPLIER_ORDERS,
  GET_SUPPLIER_ORDERS_ARCHIVE,
  GetSupplierOrdersActionFailure,
  getSupplierOrdersActionFailure,
  GetSupplierOrdersActionSuccess,
  getSupplierOrdersActionSuccess,
  GetSupplierOrdersArchiveActionFailure,
  getSupplierOrdersArchiveActionFailure,
  GetSupplierOrdersArchiveActionSuccess,
  getSupplierOrdersArchiveActionSuccess,
} from "./actions";

export const supplierOrderListEpic: Epic<
  RootAction,
  GetSupplierOrdersActionSuccess | GetSupplierOrdersActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SUPPLIER_ORDERS),
    switchMap((action) =>
      from(
        SupplierOrderService.getSupplierOrders({ input: action.input })
      ).pipe(
        mergeMap((res) => {
          return [getSupplierOrdersActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getSupplierOrdersActionFailure()]))
        )
      )
    )
  );

export const supplierOrderArchiveListEpic: Epic<
  RootAction,
  GetSupplierOrdersArchiveActionSuccess | GetSupplierOrdersArchiveActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SUPPLIER_ORDERS_ARCHIVE),
    switchMap((action) =>
      from(
        SupplierOrderService.getSupplierOrdersArchive({ input: action.input })
      ).pipe(
        mergeMap((res) => {
          return [getSupplierOrdersArchiveActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(
            mergeMap(() => [getSupplierOrdersArchiveActionFailure()])
          )
        )
      )
    )
  );

export const supplierOrderEpic = combineEpics<any>(
  supplierOrderListEpic,
  supplierOrderArchiveListEpic
);
