import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { SaleService } from "../../services/SaleService";
import { RootAction } from "../types";
import {
  GET_SALES,
  GetSalesActionFailure,
  getSalesActionFailure,
  GetSalesActionSuccess,
  getSalesActionSuccess,
} from "./actions";

export const salesEpic: Epic<
  RootAction,
  GetSalesActionSuccess | GetSalesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SALES),
    switchMap((action) =>
      from(
        SaleService.getSales({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getSalesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getSalesActionFailure()]))
        )
      )
    )
  );

export const saleEpic = combineEpics(salesEpic);
