import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { RouteService } from "../../services/RouteService";
import { RootAction } from "../types";
import {
  GET_ROUTES,
  GetRoutesActionFailure,
  getRoutesActionFailure,
  GetRoutesActionSuccess,
  getRoutesActionSuccess,
} from "./actions";

export const routesEpic: Epic<
  RootAction,
  GetRoutesActionSuccess | GetRoutesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_ROUTES),
    switchMap((action) =>
      from(
        RouteService.getRoutes({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getRoutesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getRoutesActionFailure()]))
        )
      )
    )
  );

export const routeEpic = combineEpics(routesEpic);
