import { RootAction } from "../types";
import { GET_DISHES_SUCCESS } from "./actions";
import { DishState } from "./types";

const initialState: DishState = {
  dishes: [],
};

export function dishReducer(
  state: DishState = initialState,
  action: RootAction
): DishState {
  switch (action.type) {
    case GET_DISHES_SUCCESS:
      return {
        dishes: action.dishes,
      };

    default:
      return state;
  }
}
