import { Document } from "../models/document";
import { Employee } from "../models/employee";
import { InputSearch } from "../models/inputSearch";
import { Vacation } from "../models/vacation";
import { AxiosHttpClient } from "./AxiosHttpService";

export class RhService {
  static getEmployees = (input: any) => {
    return AxiosHttpClient.get<Employee[]>(
      "api/v1/weeventpro/employees",
      input
    );
  };

  static getVacations = (input: any) => {
    return AxiosHttpClient.post<Vacation[]>(
      "api/v1/weeventpro/vacations",
      input
    );
  };

  static getDocuments = (input: InputSearch) => {
    return AxiosHttpClient.post<Document[]>(
      "api/v1/weeventpro/rh/documents/get",
      input
    );
  };
}
