import CloseIcon from "@mui/icons-material/Close";
import { ApexOptions } from "apexcharts";
import { AnimateSharedLayout, motion } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { BaseMetric } from "../../../../../../models/metric";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { SpanContainer } from "../../../../../../styles/SpanContainer";
import { Text12, Text14, Text16, Text24 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../../styles/Utils";
import { commify } from "../../../../../Reusable/Utils";
import "./index.css";

interface MotionDivProps {
  children: ReactNode;
  className: string;
  layoutId: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const MotionDiv: React.FC<MotionDivProps> = ({ children, ...props }) => {
  return <motion.div {...props}>{children}</motion.div>;
};

// parent Card

export interface BaseMetricItemProps {
  metric: BaseMetric;
  startDate: string;
  endDate: string;
}

const BaseMetricItem: React.FC<BaseMetricItemProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AnimateSharedLayout>
      {expanded ? (
        <ExpandedCard
          metric={props.metric}
          startDate={props.startDate}
          endDate={props.endDate}
          setExpanded={() => setExpanded(false)}
        />
      ) : (
        <CompactCard
          metric={props.metric}
          setExpanded={() => setExpanded(true)}
        />
      )}
    </AnimateSharedLayout>
  );
};

export default BaseMetricItem;

// Compact Card

export interface CompactCardProps {
  metric: BaseMetric;
  setExpanded: () => void;
}

const CompactCard: React.FC<CompactCardProps> = (props) => {
  return (
    <MotionDiv
      className="CompactCard"
      layoutId="expandableCard"
      onClick={props.setExpanded}
    >
      <FlexContainer
        $borderRadius="4px"
        overflow="hidden"
        flexDirection="column"
        padding="16px"
        backgroundColor="white"
        elevation={3}
      >
        <FlexContainer
          alignSelf="flex-end"
          $borderRadius="6px"
          backgroundColor={CustomTheme.palette.secondary.main}
        >
          <Text12 color="white">Annuel</Text12>
        </FlexContainer>
        <FlexContainer margin="0 0 8px 0">
          <Text16 fontWeight="bold">{props.metric.name}</Text16>
        </FlexContainer>
        <FlexContainer margin="0 0 8px 0">
          <Text24>
            {commify(props.metric.value) + " " + props.metric.unit}
          </Text24>
        </FlexContainer>
        <FlexContainer>
          <Text14>
            <SpanContainer
              color="rgb(76, 175, 80)"
              backgroundColor="rgba(76, 175, 80, 0.1)"
              padding="2px"
              $borderRadius="3px"
            >
              +12%
            </SpanContainer>{" "}
            depuis une semaine
          </Text14>
        </FlexContainer>
      </FlexContainer>
    </MotionDiv>
  );
};

// Expanded Card

export interface ExpandedCardProps {
  metric: BaseMetric;
  startDate: string;
  endDate: string;
  setExpanded: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ExpandedCard: React.FC<ExpandedCardProps> = (props) => {
  const [seriesReturned, setSeriesReturned] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const getMetricValues = () => {
    AxiosHttpClient.get<number[]>(
      "api/v1/weeventpro/statistics/metrics/getDetails",
      {
        metricName: props.metric.name,
        startDate: props.startDate,
        endDate: props.endDate,
      }
    )
      .then((response) => {
        setSeriesReturned(response);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMetricValues();
    let categoriesTmp: string[] = [];
    for (
      var d = new Date(props.startDate);
      d <= new Date(props.endDate);
      d.setDate(d.getDate() + 1)
    ) {
      categoriesTmp.push(new Date(d).toString());
    }
    setCategories([...categoriesTmp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: ApexOptions = {
    chart: {
      type: "area",
      height: "auto",

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
    },

    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["white"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      show: true,
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
  };

  const series = [
    {
      name: "Sales",
      data: seriesReturned,
    },
  ];

  return (
    <MotionDiv
      className="ExpandedCard"
      style={{
        background: CustomTheme.palette.primary.main,
      }}
      layoutId="expandableCard"
    >
      <div style={{ alignSelf: "flex-end", cursor: "pointer", color: "white" }}>
        <ButtonMui
          color="secondary"
          $backgroundColorHover={ThemeCustom.colors.opaquePink}
          onClick={props.setExpanded}
        >
          <CloseIcon />
        </ButtonMui>
      </div>
      <span>Title</span>
      <div className="chartContainer">
        <Chart options={data} series={series} type="area" />
      </div>
      <span>Last 24 hours</span>
    </MotionDiv>
  );
};
