import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import {
  Ingredient,
  PurchaseOption,
  StorageRecord,
} from "../../../../../models/ingredients";
import { AdditionalFee } from "../../../../../models/invoice";
import { Material } from "../../../../../models/materials";
import { StorageArea } from "../../../../../models/storageArea";
import {
  PurchasedItem,
  SupplierOrder,
} from "../../../../../models/supplierOrder";
import { Supplier } from "../../../../../models/suppliers";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getIngredientsAction,
  getMaterialsAction,
  getStorageAreasAction,
  getSuppliersAction,
} from "../../../../../store/Stock/actions";
import {
  getIngredients,
  getMaterials,
  getStorageAreas,
  getSuppliers,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  calculateFutureDate,
  convertUnitToUnit,
  getHTPriceFromTTC,
  getIngredientMaterialByPurchaseOptionId,
  getPurchaseOptionByPurchaseOptionId,
  getTTCPriceFromHT,
  toFixed2,
} from "../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface EditSupplierOrderProps {
  popupActive: SupplierOrder | null;
  ingredients: Ingredient[];
  materials: Material[];
  storageAreas: StorageArea[];
  suppliers: Supplier[];
  setPopupActive: React.Dispatch<React.SetStateAction<SupplierOrder | null>>;
  getIngredientsAction: typeof getIngredientsAction;
  getMaterialsAction: typeof getMaterialsAction;
  getStorageAreasAction: typeof getStorageAreasAction;
  getSuppliersAction: typeof getSuppliersAction;
  actionsOnEdit?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));
const _EditSupplierOrder: React.FC<EditSupplierOrderProps> = ({
  popupActive,
  storageAreas,
  ingredients,
  materials,
  suppliers,
  setPopupActive,
  getIngredientsAction,
  getMaterialsAction,
  getStorageAreasAction,
  getSuppliersAction,
  actionsOnEdit,
}) => {
  /* TODO */
  const editSupplierOrder = () => {
    let totalHt = getTotalHt();
    let totalTTC = getTotalTTC();
    AxiosHttpClient.put("api/v1/weeventpro/supplierOrders", {
      id: popupActive!.id,
      description: inputDescription,
      supplierOrderInternalNumber: inputSupplierOrderInternalNumber,
      supplierOrderNumber: inputSupplierOrderNumber,
      orderDate: dayjs(inputOrderDate)
        ? dayjs(inputOrderDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      deliveryDate: dayjs(inputDeliveryDate)
        ? dayjs(inputDeliveryDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      supplierId: inputSupplier?.id,
      comment: inputComments,
      purchasedItems: inputPurchasedItems,
      additionalFees: inputAdditionalFees,
      totalPriceHT: toFixed2(totalHt),
      totalPriceTVA: toFixed2(totalTTC - totalHt),
      totalPriceTTC: toFixed2(totalTTC),
      globalTVA: toFixed2((totalTTC / totalHt - 1) * 100),
      status: inputStatus,
      addedToStore: popupActive!.addedToStore,
      storageRecords: inputStorageRecords.map((storageRecord) => {
        return {
          id: storageRecord.id,
          storageAreaId: storageRecord.storageArea.id,
          productId: storageRecord.product.id,
          quantity: storageRecord.quantity,
          storageUnitId: storageRecord.storageUnit?.id,
          expirationDate: storageRecord.expirationDate,
        };
      }),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnEdit?.forEach((action) => action());
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editSupplierOrderDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/supplierOrder"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateAddPurchasedItemForm = () => {
    setInputSupplierOrderOptionNameError(false);
    setInputSupplierOrderOptionQuantityError(false);
    setAddPurchaseOptionItemFailed("");
    let result = true;
    if (inputPurchaseOption === null) {
      setInputSupplierOrderOptionNameError(true);
      result = false;
    }
    if (inputPurchaseOptionQuantity === "") {
      setInputSupplierOrderOptionQuantityError(true);
      result = false;
    }
    if (!result) {
      setAddPurchaseOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditSoldItemForm = () => {
    setInputPurchaseOptionQuantityEditError(false);
    setEditPurchaseOptionItemFailed("");
    let result = true;
    if (inputPurchaseOptionQuantityEdit === "") {
      setInputPurchaseOptionQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditPurchaseOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputDescriptionError(false);
    setInputSupplierOrderInternalNumberError(false);
    setInputOrderDateError(false);
    setInputSupplierError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (inputSupplierOrderInternalNumber === "") {
      setInputSupplierOrderInternalNumberError(true);
      result = false;
    }
    if (inputSupplier === null) {
      setInputSupplierError(true);
      result = false;
    }
    if (inputOrderDate === null) {
      setInputOrderDateError(true);
      result = false;
    }
    if (inputPurchasedItems.length === 0) {
      setEditFailed(
        "Vous n'avez aucun produit vendu pour cette commande. Veuillez en ajouter!"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addSupplierOrderDialogContentId",
      });
      return false;
    }
    if (
      inputSupplier &&
      inputSupplier.minimumOrder &&
      inputPurchasedItems.reduce((total, item) => {
        return total + item.priceHT * (1 + item.tva / 100) * item.quantity;
      }, 0) < Number(inputSupplier.minimumOrder)
    ) {
      setEditFailed(
        "La commande minimum chez ce fournisseur est de " +
          inputSupplier.minimumOrder +
          " €"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addSupplierOrderDialogContentId",
      });
      return false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editSupplierOrderDialogContentId",
      });
    }
    return result;
  };

  const validateAddAdditionalFeeForm = () => {
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeePriceTTCError(false);
    setInputAdditionalFeeTvaError(false);
    setAddAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHT) *
            (1 + Number(inputAdditionalFeeTva) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTC)
    ) {
      setAddAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeeDescription === "") {
      setInputAdditionalFeeDescriptionError(true);
      result = false;
    }
    if (inputAdditionalFeePriceHT === "") {
      setInputAdditionalFeePriceHTError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTC === "") {
      setInputAdditionalFeePriceTTCError(true);
      result = false;
    }
    if (inputAdditionalFeeTva === "") {
      setInputAdditionalFeeTvaError(true);
      result = false;
    }
    if (!result) {
      setAddAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditAdditionalFeeForm = () => {
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setEditAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHTEdit) *
            (1 + Number(inputAdditionalFeeTvaEdit) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTCEdit)
    ) {
      setEditAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeePriceHTEdit === "") {
      setInputAdditionalFeePriceHTEditError(true);
      result = false;
    }
    if (inputAdditionalFeeTvaEdit === "") {
      setInputAdditionalFeeTvaEditError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTCEdit === "") {
      setInputAdditionalFeePriceTTCEditError(true);
      result = false;
    }
    if (!result) {
      setEditAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const resetForm = () => {
    setAddPurchaseOptionItemFailed("");
    setInputPurchaseOption(null);
    setInputPurchaseOptionName("");
    setInputPurchaseOptionQuantity("");
    setInputSupplierOrderOptionNameError(false);
    setInputSupplierOrderOptionQuantityError(false);
  };

  const resetEditForm = () => {
    setEditPurchaseOptionItemFailed("");
    setInputPurchaseOptionQuantityEdit("");
    setInputPurchaseOptionQuantityEditError(false);
  };

  const resetAdditionalFeeForm = () => {
    setAddAdditionalFeeItemFailed("");
    setInputAdditionalFeeDescription("");
    setInputAdditionalFeePriceHT("");
    setInputAdditionalFeeTva("");
    setInputAdditionalFeePriceTTC("");
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeeTvaError(false);
    setInputAdditionalFeePriceTTCError(false);
  };

  const resetAdditionalFeeEditForm = () => {
    setEditAdditionalFeeItemFailed("");
    setInputAdditionalFeePriceHTEdit("");
    setInputAdditionalFeeTvaEdit("");
    setInputAdditionalFeePriceTTCEdit("");
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
  };

  const [inputDescription, setInputDescription] = useState(
    popupActive!.description
  );

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [
    inputSupplierOrderInternalNumber,
    setInputSupplierOrderInternalNumber,
  ] = useState(popupActive!.supplierOrderInternalNumber);

  const [inputSupplierOrderNumber, setInputSupplierOrderNumber] = useState(
    popupActive!.supplierOrderNumber
  );

  const [
    inputSupplierOrderInternalNumberError,
    setInputSupplierOrderInternalNumberError,
  ] = useState(false);

  const [inputComments, setInputComments] = useState(popupActive!.comment);

  const [inputSupplier, setInputSupplier] = useState<Supplier | null>(null);

  const [inputSupplierError, setInputSupplierError] = useState(false);

  const [inputOrderDate, setInputOrderDate] = useState<Dayjs | null>(
    popupActive!.orderDate
  );

  const [inputDeliveryDate, setInputDeliveryDate] = useState<Dayjs | null>(
    popupActive!.deliveryDate
  );

  const [inputOrderDateError, setInputOrderDateError] = useState(false);

  const [inputPurchasedItems, setInputPurchasedItems] = useState<
    PurchasedItem[]
  >(popupActive!.purchasedItems);

  const [purchaseOptionsTmp, setPurchaseOptionsTmp] = useState<
    PurchaseOption[]
  >([]);

  const [inputPurchaseOptionName, setInputPurchaseOptionName] = useState("");

  const [inputPurchaseOption, setInputPurchaseOption] =
    React.useState<PurchaseOption | null>(null);

  const [inputPurchaseOptionQuantity, setInputPurchaseOptionQuantity] =
    useState("");

  const [inputPurchaseOptionQuantityEdit, setInputPurchaseOptionQuantityEdit] =
    useState("");

  const [inputPurchaseOptionNameError, setInputSupplierOrderOptionNameError] =
    useState(false);

  const [
    inputPurchaseOptionQuantityError,
    setInputSupplierOrderOptionQuantityError,
  ] = useState(false);

  const [inputStatus, setInputStatus] = useState(popupActive!.status);

  const [addPurchaseOptionItemFailed, setAddPurchaseOptionItemFailed] =
    useState("");

  const [
    inputPurchaseOptionQuantityEditError,
    setInputPurchaseOptionQuantityEditError,
  ] = useState(false);

  const [editPurchaseOptionItemFailed, setEditPurchaseOptionItemFailed] =
    useState("");

  const [inputAdditionalFees, setInputAdditionalFees] = useState<
    AdditionalFee[]
  >(popupActive!.additionalFees);

  const [inputAdditionalFeeDescription, setInputAdditionalFeeDescription] =
    useState("");

  const [inputAdditionalFeePriceHT, setInputAdditionalFeePriceHT] =
    useState("");

  const [inputAdditionalFeeTva, setInputAdditionalFeeTva] = useState("");

  const [inputAdditionalFeePriceTTC, setInputAdditionalFeePriceTTC] =
    useState("");

  const [inputAdditionalFeePriceHTEdit, setInputAdditionalFeePriceHTEdit] =
    useState("");

  const [inputAdditionalFeeTvaEdit, setInputAdditionalFeeTvaEdit] =
    useState("");

  const [inputAdditionalFeePriceTTCEdit, setInputAdditionalFeePriceTTCEdit] =
    useState("");

  const [
    inputAdditionalFeeDescriptionError,
    setInputAdditionalFeeDescriptionError,
  ] = useState(false);

  const [inputAdditionalFeeTvaError, setInputAdditionalFeeTvaError] =
    useState(false);

  const [inputAdditionalFeePriceTTCError, setInputAdditionalFeePriceTTCError] =
    useState(false);

  const [inputAdditionalFeePriceHTError, setInputAdditionalFeePriceHTError] =
    useState(false);

  const [addAdditionalFeeItemFailed, setAddAdditionalFeeItemFailed] =
    useState("");

  const [
    inputAdditionalFeePriceHTEditError,
    setInputAdditionalFeePriceHTEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceTTCEditError,
    setInputAdditionalFeePriceTTCEditError,
  ] = useState(false);

  const [inputAdditionalFeeTvaEditError, setInputAdditionalFeeTvaEditError] =
    useState(false);

  const [editAdditionalFeeItemFailed, setEditAdditionalFeeItemFailed] =
    useState("");

  const [additionalFeeItemEditActive, setAdditionalFeeItemEditActive] =
    useState("");

  const [inputStorageRecords, setInputStorageRecords] = useState<
    StorageRecord[]
  >([]);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [purchaseItemEditActive, setPurchaseItemEditActive] = useState("");

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getTotalHt = () => {
    return (
      inputPurchasedItems
        .map((soldItem) => Number(soldItem.quantity) * Number(soldItem.priceHT))
        .reduce((total, currentValue) => (total = total + currentValue), 0) +
      inputAdditionalFees.reduce((acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      }, 0)
    );
  };

  const getTotalTTC = () => {
    return (
      inputPurchasedItems
        .map(
          (soldItem) =>
            (1 + Number(soldItem.tva) * 0.01) *
            Number(soldItem.quantity) *
            Number(soldItem.priceHT)
        )
        .reduce((total, currentValue) => (total = total + currentValue), 0) +
      inputAdditionalFees.reduce((acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      }, 0)
    );
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  let removePurchaseOptionItem = (index: number) => {
    let newArr = [...inputPurchasedItems];
    newArr.splice(index, 1);
    setInputPurchasedItems(newArr);
  };

  let removeAdditionalFeeItem = (index: number) => {
    let newArr = [...inputAdditionalFees];
    newArr.splice(index, 1);
    setInputAdditionalFees(newArr);
  };

  const getStorageUnitsFromPurchaseOptionId = (purchaseOptionId: string) => {
    return getIngredientMaterialByPurchaseOptionId(
      purchaseOptionId,
      ingredients,
      materials
    )?.storageUnits;
  };

  const filterPurchaseOptionOptions = (
    options: PurchaseOption[],
    { inputValue }
  ) => {
    if (inputValue === null || inputValue === undefined) return [];
    const searchTokens = inputValue.toLowerCase().split(" ");
    return options
      .filter((option) =>
        searchTokens.every((token) => option.name.toLowerCase().includes(token))
      )
      .slice(0, 20);
  };

  useEffect(() => {
    getIngredientsAction("");
    getMaterialsAction();
    getStorageAreasAction();
    getSuppliersAction("");
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let purchaseOptions: PurchaseOption[] = [];

    for (var ingredient of ingredients) {
      ingredient.purchaseOptions.forEach((purchaseOption) =>
        purchaseOptions.push(purchaseOption)
      );
    }

    for (var material of materials) {
      material.purchaseOptions.forEach((purchaseOption) =>
        purchaseOptions.push(purchaseOption)
      );
    }

    if (purchaseOptions[0] !== undefined) {
      lastPurchaseOptions.current = purchaseOptions;
    }

    // Utilisez lastSupplierOrderOptions.current comme valeur pour setSupplierOrderOptionsTmp
    setPurchaseOptionsTmp(lastPurchaseOptions.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, materials]);

  // À l'extérieur du composant, définissez une référence pour stocker la dernière valeur de supplierOrderOptions
  const lastPurchaseOptions = useRef<PurchaseOption[]>([]);

  useEffect(() => {
    let generatedDescription =
      "Commande N° " +
      inputSupplierOrderInternalNumber +
      " Le " +
      dayjs(inputOrderDate).format("DD/MM/YYYY") +
      " chez " +
      (inputSupplier ? inputSupplier.name : "?");

    setInputDescription(generatedDescription);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOrderDate, inputSupplierOrderInternalNumber, inputSupplier]);

  useEffect(() => {
    setInputStorageRecords((prevStorageRecords) => {
      return inputPurchasedItems
        .map((purchasedItem) => {
          const ingredientMaterial = getIngredientMaterialByPurchaseOptionId(
            purchasedItem.purchaseOptionId,
            ingredients,
            materials
          );

          const storageUnits = getStorageUnitsFromPurchaseOptionId(
            purchasedItem.purchaseOptionId
          );

          const purchaseOption = getPurchaseOptionByPurchaseOptionId(
            purchasedItem.purchaseOptionId,
            ingredients,
            materials
          );

          if (!purchaseOption) return null;

          // Vérifier si l'item existe déjà dans inputStorageRecords
          const existingRecord = prevStorageRecords.find(
            (record) => record.product.id === purchasedItem.purchaseOptionId
          );

          // Calculer la nouvelle quantité
          const newQuantity =
            storageUnits && storageUnits.length > 0
              ? convertUnitToUnit(
                  purchaseOption.purchaseUnit!,
                  storageUnits[0]
                ) * purchasedItem.quantity
              : purchasedItem.quantity;

          if (existingRecord) {
            // Mettre à jour la quantité de l'enregistrement existant
            return { ...existingRecord, quantity: newQuantity };
          } else {
            // Créer un nouvel enregistrement si non existant
            return {
              id: uuid(),
              storageArea: storageAreas[0],
              product: purchaseOption,
              quantity: newQuantity,
              storageUnit:
                storageUnits && storageUnits.length > 0
                  ? storageUnits[0]
                  : null,
              expirationDate: calculateFutureDate(
                ingredientMaterial?.averageExpiryDelay,
                ingredientMaterial?.averageExpiryDelayUnit
              ),
            };
          }
        })
        .filter(Boolean) as StorageRecord[]; // Filtrer les valeurs nulles
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPurchasedItems, storageAreas, ingredients, materials]);

  useEffect(() => {
    setInputSupplier(
      suppliers.find((supplier) => supplier.id === popupActive!.supplier.id) ||
        null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier une commande"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addSupplierOrderDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="supplierOrderNumber"
                label="Numéro de commande interne"
                error={inputSupplierOrderInternalNumberError}
                value={inputSupplierOrderInternalNumber}
                onChange={(e) => {
                  setInputSupplierOrderInternalNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="supplierOrderNumber"
                label="Numéro de commande fournisseur"
                value={inputSupplierOrderNumber}
                onChange={(e) => {
                  setInputSupplierOrderNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de commande"
                  inputFormat="DD/MM/YYYY"
                  value={inputOrderDate}
                  onChange={(value) => setInputOrderDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputOrderDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de livraison"
                  inputFormat="DD/MM/YYYY"
                  value={inputDeliveryDate}
                  onChange={(value) => setInputDeliveryDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputSupplierError}
              >
                <InputLabel id="supplierLabelId">Fournisseur</InputLabel>
                <Select
                  labelId="supplierLabelId"
                  id="supplierSelect"
                  value={(inputSupplier || "") as any}
                  label="Fournisseur"
                  onChange={(e) => setInputSupplier(e.target.value)}
                >
                  {suppliers.length === 0 && (
                    <MenuItem disabled sx={{ color: "red" }}>
                      Aucun fournisseur créé!
                    </MenuItem>
                  )}
                  {suppliers.map((supplier, index) => (
                    <MenuItem value={supplier as any} key={index}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="comments"
                label="Notes/Commentaires"
                multiline
                rows={4}
                value={inputComments}
                onChange={(e) => {
                  setInputComments(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Produits achetés
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Sélectionnez les items achetés avec leurs quantités respectives:
            </Text14>
          </Grid>
          {addPurchaseOptionItemFailed !== "" && (
            <Grid item xs={1} sm={1} md={2}>
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addPurchaseOptionItemFailed}</Alert>
              </BlockContainer>
            </Grid>
          )}

          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="supplierOrderOptions"
              filterOptions={filterPurchaseOptionOptions}
              options={purchaseOptionsTmp}
              getOptionLabel={(option) => option.name}
              value={inputPurchaseOption}
              onChange={(event, newValue) => {
                setInputPurchaseOption(newValue);
              }}
              inputValue={inputPurchaseOptionName}
              onInputChange={(event, newInputValue) => {
                setInputPurchaseOptionName(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                    maxWidth="90px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <Text14 textAlign="left">{option.name}</Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="PurchaseOption"
                  label="Produit"
                  error={inputPurchaseOptionNameError}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="ingredientQuantity"
              label="Quantité"
              error={inputPurchaseOptionQuantityError}
              value={inputPurchaseOptionQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputPurchaseOptionQuantity(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddPurchasedItemForm()) {
                    resetForm();
                    setInputPurchasedItems([
                      ...inputPurchasedItems,
                      {
                        id: uuid(),
                        name: inputPurchaseOption!.name,
                        priceHT: inputPurchaseOption!.priceHT,
                        tva: inputPurchaseOption!.tva,
                        quantity: Number(inputPurchaseOptionQuantity),
                        internalCode: inputPurchaseOption!.productCode,
                        imageUrl: inputPurchaseOption!.imageUrl,
                        parentId: inputPurchaseOption!.parentId,
                        purchaseOptionId: inputPurchaseOption!.id,
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="product">Produit</TableCell>
                    <TableCell key="code">Code</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="pu">P.U</TableCell>
                    <TableCell key="pht">Prix H.T</TableCell>
                    <TableCell key="tva">TVA</TableCell>
                    <TableCell key="pttc">Prix TTC</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputPurchasedItems.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={9}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Veuillez ajouter les produits souhaités dans cette
                            commande!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputPurchasedItems.map((purchasedItem, index) => (
                    <React.Fragment key={"fragment" + purchasedItem.id}>
                      <TableRow key={purchasedItem.id}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={purchasedItem.imageUrl}
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>{purchasedItem.name}</TableCell>
                        <TableCell>{purchasedItem.internalCode}</TableCell>
                        <TableCell>{purchasedItem.quantity}</TableCell>
                        <TableCell>{purchasedItem.priceHT + " €"}</TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(purchasedItem.quantity) *
                              Number(purchasedItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>{purchasedItem.tva}</TableCell>
                        <TableCell>
                          {toFixed2(
                            (1 + Number(purchasedItem.tva) * 0.01) *
                              Number(purchasedItem.quantity) *
                              Number(purchasedItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputPurchaseOptionQuantityEdit(
                                  toFixed2(purchasedItem.quantity)
                                );
                                setPurchaseItemEditActive(purchasedItem.id);
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removePurchaseOptionItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + purchasedItem.id}
                        sx={{
                          display:
                            purchaseItemEditActive === purchasedItem.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={9}>
                          <Collapse
                            orientation="vertical"
                            in={purchaseItemEditActive === purchasedItem.id}
                          >
                            {purchaseItemEditActive === purchasedItem.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editPurchaseOptionItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editPurchaseOptionItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={2}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="supplierOrderOptionQuantity"
                                    label="Quantité"
                                    error={inputPurchaseOptionQuantityEditError}
                                    value={inputPurchaseOptionQuantityEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        setInputPurchaseOptionQuantityEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditSoldItemForm()) {
                                          resetEditForm();
                                          let tmpArray = [
                                            ...inputPurchasedItems,
                                          ];
                                          tmpArray[index] = {
                                            id: purchasedItem.id,
                                            name: purchasedItem.name,
                                            priceHT: purchasedItem.priceHT,
                                            tva: purchasedItem.tva,
                                            quantity: Number(
                                              inputPurchaseOptionQuantityEdit
                                            ),
                                            internalCode:
                                              purchasedItem.internalCode,
                                            imageUrl: purchasedItem.imageUrl,
                                            parentId: purchasedItem!.parentId,
                                            purchaseOptionId:
                                              purchasedItem!.purchaseOptionId,
                                          };
                                          setInputPurchasedItems(tmpArray);
                                          setPurchaseItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetEditForm();
                                        setPurchaseItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Frais supplémentaires
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Veuillez ajouter des frais supplémentaires pour cette commande
              s'il en existe:
            </Text14>
          </Grid>
          {addAdditionalFeeItemFailed !== "" && (
            <Grid item xs={1} sm={1} md={2}>
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addAdditionalFeeItemFailed}</Alert>
              </BlockContainer>
            </Grid>
          )}
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeeDescription"
              label="Description"
              error={inputAdditionalFeeDescriptionError}
              value={inputAdditionalFeeDescription}
              onChange={(e) => {
                setInputAdditionalFeeDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeeTva"
              label="TVA"
              error={inputAdditionalFeeTvaError}
              value={inputAdditionalFeeTva}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputAdditionalFeePriceHT))
                  ) {
                    setInputAdditionalFeePriceTTC(
                      toFixed2(
                        getTTCPriceFromHT(
                          Number(inputAdditionalFeePriceHT),
                          Number(e.target.value)
                        )
                      )
                    );
                  }
                  setInputAdditionalFeeTva(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeePriceHT"
              label="Prix HT"
              error={inputAdditionalFeePriceHTError}
              value={inputAdditionalFeePriceHT}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputAdditionalFeeTva))
                  ) {
                    setInputAdditionalFeePriceTTC(
                      toFixed2(
                        getTTCPriceFromHT(
                          Number(e.target.value),
                          Number(inputAdditionalFeeTva)
                        )
                      )
                    );
                  }
                  setInputAdditionalFeePriceHT(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeePriceTTC"
              label="Prix TTC"
              error={inputAdditionalFeePriceTTCError}
              value={inputAdditionalFeePriceTTC}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputAdditionalFeeTva))
                  ) {
                    setInputAdditionalFeePriceHT(
                      toFixed2(
                        getHTPriceFromTTC(
                          Number(e.target.value),
                          Number(inputAdditionalFeeTva)
                        )
                      )
                    );
                  }
                  setInputAdditionalFeePriceTTC(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddAdditionalFeeForm()) {
                    resetAdditionalFeeForm();
                    setInputAdditionalFees([
                      ...inputAdditionalFees,
                      {
                        id: uuid(),
                        description: inputAdditionalFeeDescription,
                        priceHT: Number(inputAdditionalFeePriceHT),
                        tva: Number(inputAdditionalFeeTva),
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="additionalFeeDescription">
                      Description
                    </TableCell>
                    <TableCell key="inputAdditionalFeePriceHT">
                      Prix HT
                    </TableCell>
                    <TableCell key="inputAdditionalFeeTva">TVA</TableCell>
                    <TableCell key="inputAdditionalFeePriceTTC">
                      Prix TTC
                    </TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputAdditionalFees.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={5}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Aucun frais supplémentaire ajouté!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputAdditionalFees.map((additionalFee, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>{additionalFee.description}</TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.priceHT)) + " €"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.tva)) + "%"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(additionalFee.priceHT) *
                              (1 + Number(additionalFee.tva) / 100)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputAdditionalFeePriceHTEdit(
                                  toFixed2(additionalFee.priceHT)
                                );
                                setInputAdditionalFeeTvaEdit(
                                  toFixed2(additionalFee.tva)
                                );
                                setInputAdditionalFeePriceTTCEdit(
                                  toFixed2(
                                    Number(additionalFee.priceHT) *
                                      (1 + Number(additionalFee.tva) / 100)
                                  )
                                );
                                setAdditionalFeeItemEditActive(
                                  additionalFee.id
                                );
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeAdditionalFeeItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + index}
                        sx={{
                          display:
                            additionalFeeItemEditActive === additionalFee.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={5}>
                          <Collapse
                            orientation="vertical"
                            in={
                              additionalFeeItemEditActive === additionalFee.id
                            }
                          >
                            {additionalFeeItemEditActive ===
                              additionalFee.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editAdditionalFeeItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editAdditionalFeeItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeeTva"
                                    label="TVA"
                                    error={inputAdditionalFeeTvaEditError}
                                    value={inputAdditionalFeeTvaEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(
                                              inputAdditionalFeePriceHTEdit
                                            )
                                          )
                                        ) {
                                          setInputAdditionalFeePriceTTCEdit(
                                            toFixed2(
                                              getTTCPriceFromHT(
                                                Number(
                                                  inputAdditionalFeePriceHTEdit
                                                ),
                                                Number(e.target.value)
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeeTvaEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceHT"
                                    label="Prix HT"
                                    error={inputAdditionalFeePriceHTEditError}
                                    value={inputAdditionalFeePriceHTEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(inputAdditionalFeeTvaEdit)
                                          )
                                        ) {
                                          setInputAdditionalFeePriceTTCEdit(
                                            toFixed2(
                                              getTTCPriceFromHT(
                                                Number(e.target.value),
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeePriceHTEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceTTC"
                                    label="Prix TTC"
                                    error={inputAdditionalFeePriceTTCEditError}
                                    value={inputAdditionalFeePriceTTCEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(inputAdditionalFeeTvaEdit)
                                          )
                                        ) {
                                          setInputAdditionalFeePriceHTEdit(
                                            toFixed2(
                                              getHTPriceFromTTC(
                                                Number(e.target.value),
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeePriceTTCEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditAdditionalFeeForm()) {
                                          resetAdditionalFeeEditForm();
                                          let tmpArray = [
                                            ...inputAdditionalFees,
                                          ];
                                          tmpArray[index] = {
                                            id: additionalFee.id,
                                            description:
                                              additionalFee.description,
                                            priceHT: Number(
                                              inputAdditionalFeePriceHTEdit
                                            ),
                                            tva: Number(
                                              inputAdditionalFeeTvaEdit
                                            ),
                                          };
                                          setInputAdditionalFees(tmpArray);
                                          setAdditionalFeeItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetAdditionalFeeEditForm();
                                        setAdditionalFeeItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Tarification
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14 display="inline-block" width="80px">
              Prix HT:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {toFixed2(getTotalHt()) + " €"}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <Text14 display="inline-block" width="80px">
              Prix TTC:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {toFixed2(getTotalTTC()) + " €"}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Statut</InputLabel>
              <Select
                required
                fullWidth
                value={inputStatus}
                label="Statut"
                onChange={(e) => {
                  setInputStatus(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="Annulée">Annulée</MenuItem>
                <MenuItem value="Commandée">Commandée</MenuItem>
                <MenuItem value="Reçue">Reçue</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!popupActive?.addedToStore && inputStatus === "Reçue" && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Ajout au stock
              </Text20>
            </BlockContainer>
          </>
        )}

        {!popupActive?.addedToStore &&
          inputStatus === "Reçue" &&
          inputStorageRecords.map((storageRecord, index) => (
            <>
              <FlexContainer
                border={"1px dashed rgba(0,0,0,0.8)"}
                borderRadius={"8px"}
                marginBottom={"16px"}
                padding={"16px"}
              >
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="purchaseItemName"
                      label="Produit"
                      disabled
                      value={storageRecord.product.name}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-label">
                        Espace de stockage
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        value={storageRecord.storageArea.id}
                        label="Espace de stockage"
                        onChange={(e) => {
                          const updatedRecords = [...inputStorageRecords];
                          updatedRecords[index] = {
                            ...storageRecord,
                            storageArea: storageAreas.find(
                              (area) => area.id === e.target.value
                            )!,
                          };
                          setInputStorageRecords(updatedRecords);
                        }}
                      >
                        {storageAreas.map((storageArea, index) => (
                          <MenuItem value={storageArea.id} key={index}>
                            {storageArea.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="storageUnit">
                        Unité de stockage
                      </InputLabel>
                      <Select
                        labelId="storageUnit"
                        id="storageUnit"
                        required
                        value={storageRecord.storageUnit?.id || ""}
                        label="Unité de stockage"
                        onChange={(e) => {
                          const storageUnitFound =
                            getStorageUnitsFromPurchaseOptionId(
                              storageRecord.product.id
                            )?.find((unit) => unit.id === e.target.value);
                          const updatedRecords = [...inputStorageRecords];
                          updatedRecords[index] = {
                            ...storageRecord,
                            storageUnit: storageUnitFound || null,
                            quantity:
                              storageRecord.storageUnit && storageUnitFound
                                ? convertUnitToUnit(
                                    storageRecord.storageUnit,
                                    storageUnitFound
                                  ) * storageRecord.quantity
                                : storageRecord.quantity,
                          };
                          setInputStorageRecords(updatedRecords);
                        }}
                      >
                        {(
                          getStorageUnitsFromPurchaseOptionId(
                            storageRecord.product.id
                          ) || []
                        ).map((storageUnit, index) => (
                          <MenuItem value={storageUnit.id} key={index}>
                            {storageUnit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {getIngredientMaterialByPurchaseOptionId(
                    storageRecord.product.id,
                    ingredients,
                    materials
                  )?.handleExpiry && (
                    <Grid item xs={1} sm={1} md={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="Date de péremption"
                          inputFormat="DD/MM/YYYY"
                          value={storageRecord.expirationDate}
                          onChange={(value) => {
                            const updatedRecords = [...inputStorageRecords];
                            updatedRecords[index] = {
                              ...storageRecord,
                              expirationDate: value,
                            };
                            setInputStorageRecords(updatedRecords);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
                </Grid>
              </FlexContainer>
            </>
          ))}

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editSupplierOrder();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditSupplierOrder = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
    materials: getMaterials(state),
    storageAreas: getStorageAreas(state),
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getMaterialsAction: getMaterialsAction,
        getStorageAreasAction: getStorageAreasAction,
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_EditSupplierOrder);

export default EditSupplierOrder;
