import { Quote } from "../../models/quote";
import { Template } from "../../models/template";

export const GET_QUOTES = "GET_QUOTES" as const;
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS" as const;
export const GET_QUOTES_FAILURE = "GET_QUOTES_FAILURE" as const;

export const GET_QUOTE_TEMPLATES = "GET_QUOTE_TEMPLATES" as const;
export const GET_QUOTE_TEMPLATES_SUCCESS =
  "GET_QUOTE_TEMPLATES_SUCCESS" as const;
export const GET_QUOTE_TEMPLATES_FAILURE =
  "GET_QUOTE_TEMPLATES_FAILURE" as const;

export const getQuotesAction = (input: string) => ({
  type: GET_QUOTES,
  input: input,
});
export type GetQuotesAction = ReturnType<typeof getQuotesAction>;

export const getQuotesActionSuccess = (quotes: Quote[]) => ({
  type: GET_QUOTES_SUCCESS,
  quotes: quotes,
});
export type GetQuotesActionSuccess = ReturnType<typeof getQuotesActionSuccess>;

export const getQuotesActionFailure = () => ({
  type: GET_QUOTES_FAILURE,
});
export type GetQuotesActionFailure = ReturnType<typeof getQuotesActionFailure>;

export const getQuoteTemplatesAction = () => ({
  type: GET_QUOTE_TEMPLATES,
});
export type GetQuoteTemplatesAction = ReturnType<
  typeof getQuoteTemplatesAction
>;

export const getQuoteTemplatesActionSuccess = (templates: Template[]) => ({
  type: GET_QUOTE_TEMPLATES_SUCCESS,
  templates: templates,
});
export type GetQuoteTemplatesActionSuccess = ReturnType<
  typeof getQuoteTemplatesActionSuccess
>;

export const getQuoteTemplatesActionFailure = () => ({
  type: GET_QUOTE_TEMPLATES_FAILURE,
});
export type GetQuoteTemplatesActionFailure = ReturnType<
  typeof getQuoteTemplatesActionFailure
>;
