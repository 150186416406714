import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { InteractionService } from "../../services/InteractionService";
import { RootAction } from "../types";
import {
  GET_INTERACTIONS,
  GetInteractionsActionFailure,
  getInteractionsActionFailure,
  GetInteractionsActionSuccess,
  getInteractionsActionSuccess,
} from "./actions";

export const interactionListEpic: Epic<
  RootAction,
  GetInteractionsActionSuccess | GetInteractionsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INTERACTIONS),
    switchMap((action) =>
      from(
        InteractionService.getInteractions({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getInteractionsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getInteractionsActionFailure()]))
        )
      )
    )
  );

export const interactionEpic = combineEpics<any>(interactionListEpic);
