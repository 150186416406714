import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import { CustomField } from "../../../../../models/customField";
import { SupplierOrder } from "../../../../../models/supplierOrder";
import { UserParameter } from "../../../../../models/userParameters";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import {
  SUPPLIER_ORDER_TABLE_ORDER,
  SUPPLIER_ORDER_TABLE_ORDER_BY,
  SUPPLIER_ORDER_TABLE_SEARCH_BY,
  SUPPLIER_ORDER_TABLE_VIEW,
  getExpectedDeliveryDate,
  getUserParameterValueByName,
  toFixed2,
  trimLongString,
} from "../../../../Reusable/Utils";
import AddSupplierOrder from "../AddSupplierOrder";
import DeleteSupplierOrder from "../DeleteSupplierOrder";
import EditSupplierOrder from "../EditSupplierOrder";
import ExportSupplierOrders from "../ExportSupplierOrders";
import SupplierOrderProducts from "../SupplierOrderProducts";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface SupplierOrderTableProps {
  additionalCriterias?: string;
  actionsOnAdd?: (() => void)[];
  actionsOnEdit?: (() => void)[];
  actionsOnDelete?: (() => void)[];
  triggerGetTable?: boolean;
}

const SupplierOrderTable: React.FC<SupplierOrderTableProps> = ({
  additionalCriterias,
  actionsOnAdd,
  actionsOnEdit,
  actionsOnDelete,
  triggerGetTable,
}) => {
  const getSupplierOrdersPage = () => {
    setTableLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    AxiosHttpClient.get<any>(
      "api/v1/weeventpro/supplierOrders/page",
      {
        criteriaList:
          inputField +
          "=" +
          inputSearch +
          (additionalCriterias ? "," + additionalCriterias : ""),
        pageNumber: page - 1,
        pageSize: limit,
        sort: fieldsCorrespondanceMapForOrders.get(orderBy),
        order: order,
      },
      signal
    )
      .then((res) => {
        setSupplierOrders(res.content);
        setTableLoading(false);
        setTotalElements(res.totalElements);
      })
      .catch((err) => {});
  };

  const previousController = useRef<AbortController>(null!);

  const getViews = () => {
    AxiosHttpClient.get<View[]>(
      "api/v1/weeventpro/parameters/views/supplierOrder"
    )
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/supplierOrder"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        SUPPLIER_ORDER_TABLE_ORDER +
        "," +
        SUPPLIER_ORDER_TABLE_ORDER_BY +
        "," +
        SUPPLIER_ORDER_TABLE_SEARCH_BY +
        "," +
        SUPPLIER_ORDER_TABLE_VIEW,
    }).then((res) => {
      setOrder(
        getUserParameterValueByName(SUPPLIER_ORDER_TABLE_ORDER, res) === "asc"
          ? "asc"
          : "desc"
      );
      setOrderBy(
        getUserParameterValueByName(SUPPLIER_ORDER_TABLE_ORDER_BY, res)
      );
      setInputField(
        getUserParameterValueByName(SUPPLIER_ORDER_TABLE_SEARCH_BY, res)
      );
      setInputView(getUserParameterValueByName(SUPPLIER_ORDER_TABLE_VIEW, res));
    });
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [inputSearch, setInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<SupplierOrder | null>(
    null
  );

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [popupListProductsActive, setPopupListProductsActive] =
    useState<SupplierOrder | null>(null);

  const [inputView, setInputView] = useState("");

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorElTable, setAnchorElTable] = React.useState<null | HTMLElement>(
    null
  );

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  const [inputField, setInputField] = useState("");

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);

  const [totalElements, setTotalElements] = useState(0);

  const [supplierOrders, setSupplierOrders] = useState<SupplierOrder[]>([]);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getSupplierOrdersPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, page, limit, orderBy, order, triggerGetTable]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, limit, orderBy, order]);

  useEffect(() => {
    setInputSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  const setOrderParameters = (orderValue: string, orderByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: SUPPLIER_ORDER_TABLE_ORDER,
        parameterValue: orderValue,
      },
      {
        parameterName: SUPPLIER_ORDER_TABLE_ORDER_BY,
        parameterValue: orderByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrderParameters(isAsc ? "desc" : "asc", cellId);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "N° de commande",
      "Date de commande",
      "Date de livraison",
      "Fournisseur",
      "Prix total TTC",
      "Produits",
      "Statut",
    ],
  };

  const getViewById = (id: string) => {
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : defaultView;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  let fieldsCorrespondanceMapForOrders: Map<string, string> = new Map([
    ["N° de commande interne", "supplierOrderInternalNumber"],
    ["N° de commande", "supplierOrderNumber"],
    ["Date de commande", "orderDate"],
    ["Date de livraison", "deliveryDate"],
    ["Fournisseur", "supplierName"],
    ["Description", "description"],
    ["Commentaire", "comment"],
    ["Prix total TTC", "totalPriceTtc"],
    ["Prix total HT", "totalPriceHt"],
    ["Prix total TVA", "totalPriceTva"],
    ["TVA globale", "globalTva"],
    ["Statut", "status"],
  ]);

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["N° de commande interne", true],
      ["N° de commande", true],
      ["Date de commande", true],
      ["Date de livraison", true],
      ["Fournisseur", false],
      ["Description", true],
      ["Commentaire", true],
      ["Prix total HT", true],
      ["Prix total TVA", true],
      ["Prix total TTC", true],
      ["TVA globale", true],
      ["Statut", true],
      ["Produits", false],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (
    supplierOrder: SupplierOrder,
    customFieldId: string
  ) => {
    if (supplierOrder.customFields !== null) {
      for (var customFieldValue of supplierOrder.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (
    headValue: string,
    supplierOrder: SupplierOrder
  ) => {
    let expectedDeliveryDate = getExpectedDeliveryDate(
      supplierOrder.supplier,
      supplierOrder.orderDate || dayjs()
    );
    let headValuesMap: Map<string, string> = new Map([
      ["N° de commande", supplierOrder.supplierOrderNumber],
      ["N° de commande interne", supplierOrder.supplierOrderInternalNumber],
      [
        "Date de commande",
        supplierOrder.orderDate
          ? dayjs(supplierOrder.orderDate).format("DD/MM/YYYY")
          : "",
      ],
      [
        "Date de livraison",
        supplierOrder.deliveryDate
          ? supplierOrder.status !== "Reçue"
            ? expectedDeliveryDate
              ? "Prévue le " + expectedDeliveryDate
              : ""
            : dayjs(supplierOrder.deliveryDate).format("DD/MM/YYYY")
          : "",
      ],
      ["Fournisseur", supplierOrder.supplier.name],
      ["Description", trimLongString(supplierOrder.description)],
      ["Commentaire", supplierOrder.comment],
      ["Prix total HT", toFixed2(supplierOrder.totalPriceHT) + " €"],
      ["Prix total TVA", toFixed2(supplierOrder.totalPriceTVA) + " €"],
      ["Prix total TTC", toFixed2(supplierOrder.totalPriceTTC) + " €"],
      ["TVA globale", supplierOrder.globalTVA + "%"],
      [
        "Frais supplémentaires",
        supplierOrder.additionalFees
          .map(
            (additionalFee) =>
              additionalFee.description +
              " (" +
              toFixed2(Number(additionalFee.priceHT)) +
              " € HT)"
          )
          .join(", "),
      ],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(
        customField.name,
        getCustomFieldValues(supplierOrder, customField.id)
      );
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (
    headValue: string,
    supplierOrder: SupplierOrder
  ) => {
    let headValuesMap: Map<string, React.JSX.Element> = new Map([
      [
        "Statut",
        <FlexContainer justifyContent="center">
          <FlexContainer
            justifyContent="center"
            backgroundColor={getStatusBackgroundColor(supplierOrder.status)}
            color="white"
            padding="4px 8px"
            $borderRadius="50px"
          >
            {supplierOrder.status}
          </FlexContainer>
        </FlexContainer>,
      ],
      [
        "Produits",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupListProductsActive(supplierOrder);
            }}
            size="large"
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        </FlexContainer>,
      ],
      [
        "Actions",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupEditActive(supplierOrder);
            }}
            size="large"
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupDeleteActive([supplierOrder.id]);
            }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAnchorElTable(e.currentTarget);
              //setSelectedSupplierOrderForInvoice(supplierOrder);
            }}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
        </FlexContainer>,
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "Annulée") return CustomTheme.palette.error.main;
    if (status === "Reçue") return CustomTheme.palette.success.light;
    if (status === "Commandée") return CustomTheme.palette.primary.main;
  };

  const getValueCells = (headValue: string, supplierOrder: SupplierOrder) => {
    let simpleValue = getSimpleCellValue(headValue, supplierOrder);
    let complexValue = getComplexCellValue(headValue, supplierOrder);
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  useEffect(() => {
    getClientsAction("");
    getCustomFields();
    getViews();
    getDishesAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Menu
        id="invoice-menu"
        anchorEl={anchorElTable}
        open={Boolean(anchorElTable)}
        onClose={(e) => setAnchorElTable(null)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            marginX: "20px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorElTable(null);
            //setPopupAddToStore(selectedSupplierOrderForInvoice);
            //setPopupAddToStoreActive(true);
          }}
        >
          <FlexContainer alignItems="center">
            <PublishTwoToneIcon
              sx={{ mr: "8px" }}
              fontSize="small"
              color="primary"
            />
            Ajouter au stock
          </FlexContainer>
        </MenuItem>
      </Menu>
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="8px 10px 8px 8px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem disabled={true}>
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "customization,supplierOrders",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                setPopupDeleteActive([...selected]);
                setSelected([]);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          inputField={inputField}
          setInputField={setInputField}
          setInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui sx={{ position: "relative" }}>
        {tableLoading && (
          <FlexContainer
            sx={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 57.297px)",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              top: "57.297px",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: "2",
              opacity: "0.7",
            }}
          >
            <CircularProgress size={80} />
          </FlexContainer>
        )}
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 &&
                    selected.length === supplierOrders.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = supplierOrders.map(
                        (supplierOrder) => supplierOrder.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading && supplierOrders.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "150px", borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            )}
            {!tableLoading && supplierOrders.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "100px", borderBottom: "none" }}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune commande retrouvée
                  </Text14>
                </TableCell>
              </TableRow>
            )}
            {supplierOrders.map((item) => (
              <TableRow
                key={item.id}
                onClick={(e) => {
                  //navigate(`/feature/supplierOrders/${item.id}`);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={selected.indexOf(item.id) > -1}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newArr = [...selected];
                      const index = selected.indexOf(item.id);
                      if (index > -1) {
                        newArr.splice(index, 1);
                        setSelected(newArr);
                      } else {
                        setSelected([...selected, item.id]);
                      }
                    }}
                  />
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell key={index}>
                    {getValueCells(headCell.label, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer sx={{ marginTop: "16px" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalElements}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={(dataKey) => {
            setPage(1);
            setLimit(dataKey);
          }}
        />
      </BlockContainer>
      {popupAddActive && (
        <AddSupplierOrder
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          actionsOnAdd={[...(actionsOnAdd || []), getSupplierOrdersPage]}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteSupplierOrder
          popupActive={popupDeleteActive}
          setPopupActive={setPopupDeleteActive}
          actionsOnDelete={[...(actionsOnDelete || []), getSupplierOrdersPage]}
        />
      )}
      {popupEditActive !== null && (
        <EditSupplierOrder
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          actionsOnEdit={[...(actionsOnEdit || []), getSupplierOrdersPage]}
        />
      )}
      {popupExportActive && (
        <ExportSupplierOrders
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
      {popupListProductsActive !== null && (
        <SupplierOrderProducts
          popupActive={popupListProductsActive}
          setPopupActive={setPopupListProductsActive}
        />
      )}
    </Paper>
  );
};

export default SupplierOrderTable;
