import WarehouseIcon from "@mui/icons-material/Warehouse";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import StorageAreaTable from "./StorageAreaTable";

export interface StorageAreaProps {}

const StorageAreas: React.FC<StorageAreaProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Espaces de stockage"
        subTitle="Liste des espaces de stockage"
        icon={<WarehouseIcon fontSize="large" color="primary" />}
      />
      <StorageAreaTable />
    </BlockContainer>
  );
};

export default StorageAreas;
