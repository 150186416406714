import dayjs, { Dayjs } from "dayjs";
import { CartItem } from "../../../models/cartItem";
import { Contact } from "../../../models/contact";
import { Dish } from "../../../models/dish";
import { Employee } from "../../../models/employee";
import { Ingredient, Unit } from "../../../models/ingredients";
import { Material } from "../../../models/materials";
import { Sale, SoldItem } from "../../../models/sale";
import { Service } from "../../../models/service";
import { Supplier } from "../../../models/suppliers";
import { UserParameter } from "../../../models/userParameters";

export const CC_EMAILS = "CC_EMAILS";
export const CC_SUPPLIER_ORDER_EMAILS = "CC_SUPPLIER_ORDER_EMAILS";
export const CC_SUPPLIER_ORDER_PHONE_NUMBERS =
  "CC_SUPPLIER_ORDER_PHONE_NUMBERS";
export const GLOBAL_TVA = "GLOBAL_TVA";
export const DISH_TAGS = "DISH_TAGS";
export const SERVICE_TAGS = "SERVICE_TAGS";
export const OPPORTUNITY_TAGS = "OPPORTUNITY_TAGS";
export const COMPLAINT_TAGS = "COMPLAINT_TAGS";
export const TASK_TAGS = "TASK_TAGS";
export const INGREDIENT_INCREMENT = "INGREDIENT_INCREMENT";
export const INGREDIENT_NUMBER_OF_DIGITS = "INGREDIENT_NUMBER_OF_DIGITS";
export const INGREDIENT_FORMAT = "INGREDIENT_FORMAT";
export const MATERIAL_INCREMENT = "MATERIAL_INCREMENT";
export const MATERIAL_NUMBER_OF_DIGITS = "MATERIAL_NUMBER_OF_DIGITS";
export const MATERIAL_FORMAT = "MATERIAL_FORMAT";
export const STORAGE_AREA_INCREMENT = "STORAGE_AREA_INCREMENT";
export const STORAGE_AREA_NUMBER_OF_DIGITS = "STORAGE_AREA_NUMBER_OF_DIGITS";
export const STORAGE_AREA_FORMAT = "STORAGE_AREA_FORMAT";
export const SUPPLIER_INCREMENT = "SUPPLIER_INCREMENT";
export const SUPPLIER_NUMBER_OF_DIGITS = "SUPPLIER_NUMBER_OF_DIGITS";
export const SUPPLIER_FORMAT = "SUPPLIER_FORMAT";
export const SUPPLIER_ORDER_INCREMENT = "SUPPLIER_ORDER_INCREMENT";
export const SUPPLIER_ORDER_NUMBER_OF_DIGITS =
  "SUPPLIER_ORDER_NUMBER_OF_DIGITS";
export const SUPPLIER_ORDER_FORMAT = "SUPPLIER_ORDER_FORMAT";
export const SALE_INCREMENT = "SALE_INCREMENT";
export const SALE_NUMBER_OF_DIGITS = "SALE_NUMBER_OF_DIGITS";
export const SALE_FORMAT = "SALE_FORMAT";
export const DISH_INCREMENT = "DISH_INCREMENT";
export const DISH_NUMBER_OF_DIGITS = "DISH_NUMBER_OF_DIGITS";
export const DISH_FORMAT = "DISH_FORMAT";
export const SERVICE_INCREMENT = "SERVICE_INCREMENT";
export const SERVICE_NUMBER_OF_DIGITS = "SERVICE_NUMBER_OF_DIGITS";
export const SERVICE_FORMAT = "SERVICE_FORMAT";
export const UPFRONT_INVOICE_NUMBER_OF_DIGITS =
  "UPFRONT_INVOICE_NUMBER_OF_DIGITS";
export const UPFRONT_INVOICE_FORMAT = "UPFRONT_INVOICE_FORMAT";
export const CREDIT_NOTE_NUMBER_OF_DIGITS = "CREDIT_NOTE_NUMBER_OF_DIGITS";
export const CREDIT_NOTE_FORMAT = "CREDIT_NOTE_FORMAT";
export const INVOICE_FORMAT = "INVOICE_FORMAT";
export const INVOICE_NUMBER_OF_DIGITS = "INVOICE_NUMBER_OF_DIGITS";
export const INVOICE_INCREMENT = "INVOICE_INCREMENT";
export const QUOTE_FORMAT = "QUOTE_FORMAT";
export const QUOTE_NUMBER_OF_DIGITS = "QUOTE_NUMBER_OF_DIGITS";
export const QUOTE_INCREMENT = "QUOTE_INCREMENT";
export const CONTACT_INCREMENT = "CONTACT_INCREMENT";
export const CONTACT_NUMBER_OF_DIGITS = "CONTACT_NUMBER_OF_DIGITS";
export const CONTACT_FORMAT = "CONTACT_FORMAT";
export const OPPORTUNITY_INCREMENT = "OPPORTUNITY_INCREMENT";
export const OPPORTUNITY_NUMBER_OF_DIGITS = "OPPORTUNITY_NUMBER_OF_DIGITS";
export const OPPORTUNITY_FORMAT = "OPPORTUNITY_FORMAT";
export const COMPLAINT_INCREMENT = "COMPLAINT_INCREMENT";
export const COMPLAINT_NUMBER_OF_DIGITS = "COMPLAINT_NUMBER_OF_DIGITS";
export const COMPLAINT_FORMAT = "COMPLAINT_FORMAT";
export const TASK_INCREMENT = "TASK_INCREMENT";
export const TASK_NUMBER_OF_DIGITS = "TASK_NUMBER_OF_DIGITS";
export const TASK_FORMAT = "TASK_FORMAT";
export const INTERACTION_INCREMENT = "INTERACTION_INCREMENT";
export const INTERACTION_NUMBER_OF_DIGITS = "INTERACTION_NUMBER_OF_DIGITS";
export const INTERACTION_FORMAT = "INTERACTION_FORMAT";
export const EMPLOYEE_INCREMENT = "EMPLOYEE_INCREMENT";
export const EMPLOYEE_NUMBER_OF_DIGITS = "EMPLOYEE_NUMBER_OF_DIGITS";
export const EMPLOYEE_FORMAT = "EMPLOYEE_FORMAT";
export const VACATION_INCREMENT = "VACATION_INCREMENT";
export const VACATION_NUMBER_OF_DIGITS = "VACATION_NUMBER_OF_DIGITS";
export const VACATION_FORMAT = "VACATION_FORMAT";

export const INGREDIENT_TABLE_VIEW = "INGREDIENT_TABLE_VIEW";
export const INGREDIENT_TABLE_ORDER = "INGREDIENT_TABLE_ORDER";
export const INGREDIENT_TABLE_ORDER_BY = "INGREDIENT_TABLE_ORDER_BY";
export const INGREDIENT_TABLE_SEARCH_BY = "INGREDIENT_TABLE_SEARCH_BY";
export const MATERIAL_TABLE_VIEW = "MATERIAL_TABLE_VIEW";
export const MATERIAL_TABLE_ORDER = "MATERIAL_TABLE_ORDER";
export const MATERIAL_TABLE_ORDER_BY = "MATERIAL_TABLE_ORDER_BY";
export const MATERIAL_TABLE_SEARCH_BY = "MATERIAL_TABLE_SEARCH_BY";
export const STORAGE_AREA_TABLE_VIEW = "STORAGE_AREA_TABLE_VIEW";
export const STORAGE_AREA_TABLE_ORDER = "STORAGE_AREA_TABLE_ORDER";
export const STORAGE_AREA_TABLE_ORDER_BY = "STORAGE_AREA_TABLE_ORDER_BY";
export const STORAGE_AREA_TABLE_SEARCH_BY = "STORAGE_AREA_TABLE_SEARCH_BY";
export const SUPPLIER_TABLE_VIEW = "SUPPLIER_TABLE_VIEW";
export const SUPPLIER_TABLE_ORDER = "SUPPLIER_TABLE_ORDER";
export const SUPPLIER_TABLE_ORDER_BY = "SUPPLIER_TABLE_ORDER_BY";
export const SUPPLIER_TABLE_SEARCH_BY = "SUPPLIER_TABLE_SEARCH_BY";
export const SUPPLIER_ORDER_TABLE_VIEW = "SUPPLIER_ORDER_TABLE_VIEW";
export const SUPPLIER_ORDER_TABLE_ORDER = "SUPPLIER_ORDER_TABLE_ORDER";
export const SUPPLIER_ORDER_TABLE_ORDER_BY = "SUPPLIER_ORDER_TABLE_ORDER_BY";
export const SUPPLIER_ORDER_TABLE_SEARCH_BY = "SUPPLIER_ORDER_TABLE_SEARCH_BY";
export const SALE_TABLE_VIEW = "SALE_TABLE_VIEW";
export const SALE_TABLE_ORDER = "SALE_TABLE_ORDER";
export const SALE_TABLE_ORDER_BY = "SALE_TABLE_ORDER_BY";
export const SALE_TABLE_SEARCH_BY = "SALE_TABLE_SEARCH_BY";
export const ROUTE_TABLE_VIEW = "ROUTE_TABLE_VIEW";
export const ROUTE_TABLE_ORDER = "ROUTE_TABLE_ORDER";
export const ROUTE_TABLE_ORDER_BY = "ROUTE_TABLE_ORDER_BY";
export const ROUTE_TABLE_SEARCH_BY = "ROUTE_TABLE_SEARCH_BY";
export const DISH_TABLE_VIEW = "DISH_TABLE_VIEW";
export const DISH_TABLE_ORDER = "DISH_TABLE_ORDER";
export const DISH_TABLE_ORDER_BY = "DISH_TABLE_ORDER_BY";
export const DISH_TABLE_SEARCH_BY = "DISH_TABLE_SEARCH_BY";
export const SERVICE_TABLE_VIEW = "SERVICE_TABLE_VIEW";
export const SERVICE_TABLE_ORDER = "SERVICE_TABLE_ORDER";
export const SERVICE_TABLE_ORDER_BY = "SERVICE_TABLE_ORDER_BY";
export const SERVICE_TABLE_SEARCH_BY = "SERVICE_TABLE_SEARCH_BY";
export const QUOTE_TABLE_VIEW = "QUOTE_TABLE_VIEW";
export const QUOTE_TABLE_ORDER = "QUOTE_TABLE_ORDER";
export const QUOTE_TABLE_ORDER_BY = "QUOTE_TABLE_ORDER_BY";
export const QUOTE_TABLE_SEARCH_BY = "QUOTE_TABLE_SEARCH_BY";
export const INVOICE_TABLE_VIEW = "INVOICE_TABLE_VIEW";
export const INVOICE_TABLE_ORDER = "INVOICE_TABLE_ORDER";
export const INVOICE_TABLE_ORDER_BY = "INVOICE_TABLE_ORDER_BY";
export const INVOICE_TABLE_SEARCH_BY = "INVOICE_TABLE_SEARCH_BY";
export const CLIENT_TABLE_VIEW = "CLIENT_TABLE_VIEW";
export const CLIENT_TABLE_ORDER = "CLIENT_TABLE_ORDER";
export const CLIENT_TABLE_ORDER_BY = "CLIENT_TABLE_ORDER_BY";
export const CLIENT_TABLE_SEARCH_BY = "CLIENT_TABLE_SEARCH_BY";
export const OPPORTUNITY_TABLE_VIEW = "OPPORTUNITY_TABLE_VIEW";
export const OPPORTUNITY_TABLE_ORDER = "OPPORTUNITY_TABLE_ORDER";
export const OPPORTUNITY_TABLE_ORDER_BY = "OPPORTUNITY_TABLE_ORDER_BY";
export const OPPORTUNITY_TABLE_SEARCH_BY = "OPPORTUNITY_TABLE_SEARCH_BY";
export const COMMERCIAL_CYCLE_TABLE_VIEW = "COMMERCIAL_CYCLE_TABLE_VIEW";
export const COMMERCIAL_CYCLE_TABLE_ORDER = "COMMERCIAL_CYCLE_TABLE_ORDER";
export const COMMERCIAL_CYCLE_TABLE_ORDER_BY =
  "COMMERCIAL_CYCLE_TABLE_ORDER_BY";
export const COMMERCIAL_CYCLE_TABLE_SEARCH_BY =
  "COMMERCIAL_CYCLE_TABLE_SEARCH_BY";
export const COMPLAINT_TABLE_VIEW = "COMPLAINT_TABLE_VIEW";
export const COMPLAINT_TABLE_ORDER = "COMPLAINT_TABLE_ORDER";
export const COMPLAINT_TABLE_ORDER_BY = "COMPLAINT_TABLE_ORDER_BY";
export const COMPLAINT_TABLE_SEARCH_BY = "COMPLAINT_TABLE_SEARCH_BY";
export const TASK_TABLE_VIEW = "TASK_TABLE_VIEW";
export const TASK_TABLE_ORDER = "TASK_TABLE_ORDER";
export const TASK_TABLE_ORDER_BY = "TASK_TABLE_ORDER_BY";
export const TASK_TABLE_SEARCH_BY = "TASK_TABLE_SEARCH_BY";
export const INTERACTION_TABLE_VIEW = "INTERACTION_TABLE_VIEW";
export const INTERACTION_TABLE_ORDER = "INTERACTION_TABLE_ORDER";
export const INTERACTION_TABLE_ORDER_BY = "INTERACTION_TABLE_ORDER_BY";
export const INTERACTION_TABLE_SEARCH_BY = "INTERACTION_TABLE_SEARCH_BY";
export const EMPLOYEE_TABLE_VIEW = "EMPLOYEE_TABLE_VIEW";
export const EMPLOYEE_TABLE_ORDER = "EMPLOYEE_TABLE_ORDER";
export const EMPLOYEE_TABLE_ORDER_BY = "EMPLOYEE_TABLE_ORDER_BY";
export const EMPLOYEE_TABLE_SEARCH_BY = "EMPLOYEE_TABLE_SEARCH_BY";
export const VACATION_TABLE_VIEW = "VACATION_TABLE_VIEW";
export const VACATION_TABLE_ORDER = "VACATION_TABLE_ORDER";
export const VACATION_TABLE_ORDER_BY = "VACATION_TABLE_ORDER_BY";
export const VACATION_TABLE_SEARCH_BY = "VACATION_TABLE_SEARCH_BY";

export const mergeDateTime = (
  inputDate: Dayjs | null,
  inputTime: Dayjs | null
) => {
  if (!inputDate) return null;
  if (inputTime)
    return dayjs(inputDate)
      .set("hour", dayjs(inputTime).hour())
      .set("minute", dayjs(inputTime).minute())
      .set("second", dayjs(inputTime).second())
      .set("millisecond", dayjs(inputTime).millisecond());
  return inputDate;
};

export const calculateWorkingDays = (
  startDate: Dayjs | null,
  endDate: Dayjs | null
) => {
  if (!startDate || !endDate) return 0;

  let workingDays = 0;
  let currentDate = dayjs(startDate).startOf("day");

  while (
    currentDate.isBefore(dayjs(endDate)) ||
    currentDate.isSame(dayjs(endDate))
  ) {
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      workingDays++;
    }
    currentDate = currentDate.add(1, "day");
  }

  return workingDays;
};

export const isBeforeToday = (date: Dayjs) => {
  return dayjs(date).isBefore(dayjs().startOf("day"));
};

export const commify = (n: number) => {
  var parts = toFixed2(n).toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, " ") + (decimalPart ? "." + decimalPart : "")
  );
};

export const getCurrentDate = () => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
};

export const getDateAYearAgo = () => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear() - 1;

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
};

export const getStringFromDate = (date: Date) => {
  if (date === null || date === undefined) return null;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}-${month < 10 ? `0${month}` : `${month}`}-${year}`;
};

export const getISODateFromString = (dateString: string) => {
  let date = new Date(dateString);
  return date.toLocaleDateString();
};

export const getTimeFromDate = (dateString: string) => {
  let date = new Date(dateString);

  let minutes = date.getHours();
  let seconds = date.getSeconds();

  return `${minutes < 10 ? `0${minutes}` : `${minutes}`}:${
    seconds < 10 ? `0${seconds}` : `${seconds}`
  }`;
};

export const parseToDate = (dateStr) => {
  const parts = dateStr.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

export const calculateAge = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};

export const getSoldItemsFromSales = (sales: Sale[]) => {
  const soldItemsMap: Map<string, SoldItem> = new Map();

  // Parcourir chaque vente et combiner les SoldItems en utilisant leur saleOptionId comme clé
  sales.forEach((sale) => {
    sale.soldItems.forEach((soldItem) => {
      const existingSoldItem = soldItemsMap.get(soldItem.name);

      if (existingSoldItem) {
        // Si l'élément existe déjà, ajouter la quantité à l'élément existant
        existingSoldItem.quantity =
          existingSoldItem.quantity + soldItem.quantity;
      } else {
        // Sinon, ajouter le nouvel élément à la carte
        soldItemsMap.set(soldItem.name, { ...soldItem });
      }
    });
  });

  // Convertir la carte en tableau de SoldItems
  const combinedSoldItems: SoldItem[] = Array.from(soldItemsMap.values());

  return combinedSoldItems;
};

export const getIngredientBySaleOptionId = (
  id: string,
  ingredients: Ingredient[]
) => {
  for (var ingredient of ingredients) {
    for (var saleOption of ingredient.saleOptions) {
      if (saleOption.id === id) {
        return ingredient;
      }
    }
  }
  return null;
};

export const getMaterialBySaleOptionId = (
  id: string,
  materials: Material[]
) => {
  for (var material of materials) {
    for (var saleOption of material.saleOptions) {
      if (saleOption.id === id) {
        return material;
      }
    }
  }
  return null;
};

export const getDishBySaleOptionId = (id: string, dishes: Dish[]) => {
  for (var dish of dishes) {
    for (var saleOption of dish.saleOptions) {
      if (saleOption.id === id) {
        return dish;
      }
    }
  }
  return null;
};

export const getServiceBySaleOptionId = (id: string, services: Service[]) => {
  for (var service of services) {
    for (var saleOption of service.saleOptions) {
      if (saleOption.id === id) {
        return service;
      }
    }
  }
  return null;
};

export const getItemCode = (item: Ingredient | Material | Dish | Service) => {
  if ("internalCode" in item) {
    return item.internalCode;
  } else if ("ingredientCode" in item) {
    return item.ingredientCode;
  } else if ("materialCode" in item) {
    return item.materialCode;
  }
  return "";
};

export const getIngredientByPurchaseOptionId = (
  id: string,
  ingredients: Ingredient[]
) => {
  for (var ingredient of ingredients) {
    for (var purchaseOption of ingredient.purchaseOptions) {
      if (purchaseOption.id === id) {
        return ingredient;
      }
    }
  }
  return null;
};

export const getIngredientMaterialByPurchaseOptionId = (
  id: string,
  ingredients: Ingredient[],
  materials: Material[]
) => {
  for (var ingredient of ingredients) {
    for (var purchaseOption of ingredient.purchaseOptions) {
      if (purchaseOption.id === id) {
        return ingredient;
      }
    }
  }
  for (var material of materials) {
    for (var materialPurchaseOption of material.purchaseOptions) {
      if (materialPurchaseOption.id === id) {
        return material;
      }
    }
  }
  return null;
};

export const getPurchaseOptionByPurchaseOptionId = (
  id: string,
  ingredients: Ingredient[],
  materials: Material[]
) => {
  for (var ingredient of ingredients) {
    for (var purchaseOption of ingredient.purchaseOptions) {
      if (purchaseOption.id === id) {
        return purchaseOption;
      }
    }
  }
  for (var material of materials) {
    for (var materialPurchaseOption of material.purchaseOptions) {
      if (materialPurchaseOption.id === id) {
        return materialPurchaseOption;
      }
    }
  }
  return null;
};

export const calculateFutureDate = (
  inputAverageExpiryDelay,
  inputAverageExpiryDelayUnit
) => {
  let futureDate = dayjs(); // La date du jour

  if (inputAverageExpiryDelay === "" || inputAverageExpiryDelayUnit === "")
    return futureDate;

  switch (inputAverageExpiryDelayUnit) {
    case "Jours":
      futureDate = futureDate.add(inputAverageExpiryDelay, "day");
      break;
    case "Mois":
      futureDate = futureDate.add(inputAverageExpiryDelay, "month");
      break;
    case "Années":
      futureDate = futureDate.add(inputAverageExpiryDelay, "year");
      break;
    default:
      console.warn("Unité non reconnue");
  }

  return futureDate;
};

export const isContactType = (
  participant: Contact | Employee | undefined | null
) => {
  if (participant) {
    return "contactCode" in participant || "companyName" in participant
      ? true
      : false;
  }
  return false;
};

export const getParticipantDescription = (
  participant: Contact | Employee | undefined | null
) => {
  if (participant) {
    if (isContactType(participant))
      return getContactDescription(participant as Contact);
    return getEmployeeDescription(participant as Employee);
  }
  return "";
};

export const getParticipantInitials = (
  participant: Contact | Employee | undefined | null
) => {
  if (participant) {
    if (isContactType(participant))
      return getContactInitials(participant as Contact);
    return getEmployeeInitials(participant as Employee);
  }
  return "";
};

export const getContactDescription = (client: Contact | undefined | null) => {
  if (client) {
    if (client.type === "Particulier")
      return client.title + ". " + client.lastName + " " + client.firstName;
    else return client.companyName;
  }
  return "";
};

export const getContactInitials = (client: Contact | undefined | null) => {
  if (client) {
    if (client.type === "Particulier")
      return client.lastName.charAt(0) + client.firstName.charAt(0);
    else return client.companyName.charAt(0);
  }
  return "";
};

export const getEmployeeDescription = (
  employee: Employee | undefined | null
) => {
  if (employee) {
    return employee.lastName + " " + employee.firstName;
  }
  return "";
};

export const getEmployeeInitials = (employee: Employee | undefined | null) => {
  if (employee) {
    return employee.lastName.charAt(0) + employee.firstName.charAt(0);
  }
  return "";
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  const splitName = name.split(" ");
  const filteredName = splitName.filter(
    (word) => !["Mr.", "Mme."].includes(word)
  );

  let initial = "";
  if (filteredName.length > 1) {
    initial = `${filteredName[0][0]}${filteredName[1][0]}`;
  } else if (filteredName.length === 1) {
    initial = filteredName[0][0];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initial,
  };
};

export const imageUrlValid = (imageUrl: string | undefined | null) => {
  if (imageUrl) {
    return imageUrl ===
      "https://" +
        process.env.REACT_APP_BUCKET_NAME! +
        ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
      ? false
      : true;
  }
  return false;
};

export const getIngredientByName = (
  name: string,
  ingredients: Ingredient[]
) => {
  for (var ingredient of ingredients) {
    if (ingredient.name === name) {
      return ingredient;
    }
  }
  return null;
};

export const getIngredientNameById = (
  id: string,
  ingredients: Ingredient[]
) => {
  for (var ingredient of ingredients) {
    if (ingredient.id === id) {
      return ingredient.name;
    }
  }
  return "";
};

export const getIngredientById = (id: string, ingredients: Ingredient[]) => {
  for (var ingredient of ingredients) {
    if (ingredient.id === id) {
      return ingredient;
    }
  }
  return null;
};

export const getUnitNameById = (
  id: string,
  units: Unit[]
): string | undefined => {
  const unit = units.find((unit) => unit.id === id);
  return unit ? unit.name : undefined;
};

export const getUnitByName = (
  name: string,
  units: Unit[]
): Unit | undefined => {
  return units.find((unit) => unit.name.toLowerCase() === name.toLowerCase());
};

export const getUnitById = (id: string, units: Unit[]): Unit | null => {
  return units.find((unit) => unit.id === id) ?? null;
};

export const baseUnits = ["Kg", "g", "mg", "L", "mL", "Unité"];

export const isBaseUnit = (unit: Unit): boolean => {
  return baseUnits.includes(unit.name);
};

export const getUnitDescription = (unit: Unit): string => {
  if (unit.correspondanceUnit) {
    return `${unit.name} (${unit.correspondanceValue} ${unit.correspondanceUnit.name})`;
  }
  if (!isBaseUnit(unit))
    return `${unit.name} (${unit.correspondanceValue} ${unit.baseUnit})`;
  return unit.name;
};

export const isParentOf = (parentUnit: Unit, childUnit: Unit): boolean => {
  if (!parentUnit || !childUnit) return false;
  if (parentUnit.correspondanceUnit) {
    if (parentUnit.correspondanceUnit.id === childUnit.id) {
      return true;
    }
    return isParentOf(parentUnit.correspondanceUnit, childUnit);
  }
  return false;
};

export const getIngredientQuantity = (ingredient: Ingredient) => {
  let quantity = 0;
  if (ingredient?.storageRecords !== undefined) {
    for (var storageRecord of ingredient?.storageRecords!) {
      if (storageRecord.storageUnit && ingredient.globalStorageUnit) {
        quantity +=
          storageRecord.quantity *
          convertUnitToUnit(
            storageRecord.storageUnit,
            ingredient.globalStorageUnit
          );
      }
    }
  }
  return quantity;
};

export const getMaterialQuantity = (material: Material) => {
  let quantity = 0;
  if (material?.storageRecords !== undefined) {
    for (var storageRecord of material?.storageRecords!) {
      if (storageRecord.storageUnit && material.globalStorageUnit) {
        quantity +=
          storageRecord.quantity *
          convertUnitToUnit(
            storageRecord.storageUnit,
            material.globalStorageUnit
          );
      }
    }
  }
  return quantity;
};

export const convertUnitToUnit = (unit1: Unit, unit2: Unit): number => {
  // Vérifie si les deux unités sont basées sur la masse
  if (unitIsKgBased(unit1) && unitIsKgBased(unit2)) {
    const valueInMg = convertUnitToMg(unit1); // Convertit unit1 en mg
    const targetValueInMg = convertUnitToMg(unit2); // Convertit unit2 en mg
    return valueInMg / targetValueInMg; // Convertit unit1 en unit2
  }
  // Vérifie si les deux unités sont basées sur le volume
  else if (unitIsLBased(unit1) && unitIsLBased(unit2)) {
    const valueInMl = convertUnitToML(unit1); // Convertit unit1 en mL
    const targetValueInMl = convertUnitToML(unit2); // Convertit unit2 en mL
    return valueInMl / targetValueInMl; // Convertit unit1 en unit2
  }
  // Vérifie si les deux unités sont des unités simples
  else if (unit1.baseUnit === "Unité" && unit2.baseUnit === "Unité") {
    return unit1.correspondanceValue / unit2.correspondanceValue;
  } else return 1;
};

export const unitIsKgBased = (unit: Unit): boolean => {
  if (
    unit.baseUnit === "Kg" ||
    unit.baseUnit === "g" ||
    unit.baseUnit === "mg"
  ) {
    return true;
  }
  return false;
};

export const unitIsLBased = (unit: Unit): boolean => {
  if (unit.baseUnit === "L" || unit.baseUnit === "mL") {
    return true;
  }
  return false;
};

export const convertUnitToMg = (unit: Unit): number => {
  if (unit.correspondanceUnit) {
    const convertedValue = convertUnitToMg(unit.correspondanceUnit);
    return unit.correspondanceValue * convertedValue;
  } else if (unit.baseUnit === "mg") {
    return unit.correspondanceValue;
  } else if (unit.baseUnit === "g") {
    return unit.correspondanceValue * 1000;
  } else if (unit.baseUnit === "Kg") {
    return unit.correspondanceValue * 1000000;
  } else return 1;
};

export const convertUnitToML = (unit: Unit): number => {
  if (unit.correspondanceUnit) {
    const convertedValue = convertUnitToML(unit.correspondanceUnit);
    return unit.correspondanceValue * convertedValue;
  } else if (unit.baseUnit === "mL") {
    return unit.correspondanceValue;
  } else if (unit.baseUnit === "L") {
    return unit.correspondanceValue * 1000;
  } else return 1;
};

export const getHTPriceFromTTC = (priceTTC: number, tva: number): number => {
  return priceTTC / (1 + tva / 100);
};

export const getTTCPriceFromHT = (priceHT: number, tva: number): number => {
  return priceHT * (1 + tva / 100);
};

export const getHTPriceFromTTC2 = (priceTTC: number, tva: number): string => {
  return toFixed2(priceTTC / (1 + tva / 100));
};

export const getTTCPriceFromHT2 = (priceHT: number, tva: number): string => {
  return toFixed2(priceHT * (1 + tva / 100));
};

export const getUserParameterValueByName = (
  parameterName: string,
  userParameters: UserParameter[]
) => {
  for (var userParameter of userParameters) {
    if (userParameter.parameterName === parameterName) {
      return userParameter.parameterValue;
    }
  }
  return "";
};

export const isDateValid = (dayjsObject: Dayjs | null) => {
  if (!dayjsObject) return false;
  return dayjsObject instanceof dayjs && dayjsObject.isValid();
};

export const toFixed2 = (num: number | null | undefined) => {
  if (
    num === null ||
    num === undefined ||
    typeof num !== "number" ||
    isNaN(num)
  ) {
    return "";
  }
  if (Math.abs(num) < 0.01) {
    return "0.00";
  }
  return (+(Math.round(+(num + 0.0001 + "e" + 2)) + "e" + -2)).toFixed(2);
};

export const toFixed2Number = (num: number | null | undefined) => {
  if (
    num === null ||
    num === undefined ||
    typeof num !== "number" ||
    isNaN(num)
  ) {
    return 0;
  }
  if (Math.abs(num) < 0.01) {
    return 0;
  }
  return Number(
    (+(Math.round(+(num + 0.0001 + "e" + 2)) + "e" + -2)).toFixed(2)
  );
};

export const trimLongString = (str: string | null | undefined) => {
  if (str) {
    return str.length > 50 ? str.substring(0, 48) + "..." : str;
  }
  return "";
};

export const getRelativeTime = (date) => {
  const now = dayjs();
  const diff = now.diff(date, "second");

  if (diff < 60) {
    return "il y a quelques secondes";
  } else if (diff < 60 * 60) {
    const minutes = Math.floor(diff / 60);
    return `il y a ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else if (diff < 60 * 60 * 24) {
    const hours = Math.floor(diff / (60 * 60));
    return `il y a ${hours} ${hours === 1 ? "heure" : "heures"}`;
  } else if (diff < 60 * 60 * 24 * 30) {
    const days = Math.floor(diff / (60 * 60 * 24));
    return `il y a ${days} ${days === 1 ? "jour" : "jours"}`;
  } else if (diff < 60 * 60 * 24 * 30 * 12) {
    const months = Math.floor(diff / (60 * 60 * 24 * 30));
    return `il y a ${months} ${months === 1 ? "mois" : "mois"}`;
  } else {
    const years = Math.floor(diff / (60 * 60 * 24 * 30 * 12));
    return `il y a ${years} ${years === 1 ? "an" : "ans"}`;
  }
};

export const dateDifference = (
  startDate: Dayjs | null,
  endDate: Dayjs | null
): string => {
  if (!startDate || !endDate) return "";

  const adjustedStartDate = dayjs(startDate).startOf("second");
  const adjustedEndDate = dayjs(endDate).startOf("second");

  const duration = adjustedEndDate.diff(adjustedStartDate, "second"); // La différence en secondes

  const years = Math.floor(duration / (3600 * 24 * 365));
  const months = Math.floor((duration % (3600 * 24 * 365)) / (3600 * 24 * 30));
  const days = Math.floor(
    ((duration % (3600 * 24 * 365)) % (3600 * 24 * 30)) / (3600 * 24)
  );
  const hours = Math.floor(
    ((duration % (3600 * 24 * 365)) % (3600 * 24)) / 3600
  );
  const minutes = Math.floor(((duration % (3600 * 24 * 365)) % 3600) / 60);
  const seconds = Math.floor((duration % (3600 * 24 * 365)) % 60);

  const formatValue = (value: number, unit: string): string => {
    return `${value} ${unit}${value !== 1 ? "s" : ""}`;
  };

  const formattedYears = years > 0 ? formatValue(years, "année") : "";
  const formattedMonths = months > 0 ? formatValue(months, "mois") : "";
  const formattedDays = days > 0 ? formatValue(days, "jour") : "";
  const formattedHours = hours > 0 ? formatValue(hours, "heure") : "";
  const formattedMinutes = minutes > 0 ? formatValue(minutes, "minute") : "";
  const formattedSeconds = seconds > 0 ? formatValue(seconds, "seconde") : "";

  const formattedParts = [
    formattedYears,
    formattedMonths,
    formattedDays,
    formattedHours,
    formattedMinutes,
    formattedSeconds,
  ].filter(Boolean);

  return formattedParts.join(" ");
};

export const getItemObject = (item: CartItem) => {
  return item.purchaseOption
    ? item.purchaseOption
    : item.materialPurchaseOption!;
};

export function getExpectedDeliveryDate(
  supplier: Supplier,
  startDate?: Dayjs
): string | null {
  const { leadTime, openWeekDays } = supplier;
  const dayNames = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  // Filtrer les jours ouverts valides
  const validOpenWeekDays = openWeekDays.filter((day) =>
    dayNames.includes(day)
  );

  if (validOpenWeekDays.length === 0) {
    return null;
  }

  let deliveryDaysCount = 0;
  let deliveryDate = dayjs(startDate) || dayjs(); // Utilise startDate s'il est fourni, sinon la date actuelle

  while (deliveryDaysCount < leadTime) {
    // Incrémenter la date d'un jour
    deliveryDate = deliveryDate.add(1, "day");

    // Obtenir le nom du jour en français
    const dayName = dayNames[deliveryDate.day()];

    // Vérifier si c'est un jour ouvert
    if (validOpenWeekDays.includes(dayName)) {
      deliveryDaysCount++;
    }
  }

  // Formater la date en "DD/MM/YYYY"
  return deliveryDate.format("DD/MM/YYYY");
}

export const blogPosts = [
  {
    title:
      "Maximiser l’efficacité : Comment Nos Solutions ERP/CRM Peuvent Transformer Votre Entreprise",
    description:
      "Découvrez comment nos solutions ERP/CRM peuvent améliorer la gestion des processus, renforcer la relation client et optimiser la productivité.",
    image: "erp-crm-article.jpg",
    date: "20 mars 2025",
    content: `
  <p>Dans un environnement commercial en constante évolution, l’efficacité et l’adaptabilité sont plus essentielles que jamais. Les entreprises recherchent des outils capables d’optimiser leurs opérations, d’améliorer la gestion de la relation client et de stimuler leur croissance. C’est là qu’interviennent les solutions de planification des ressources d’entreprise (ERP) et de gestion de la relation client (CRM). Chez WeEvent, nous proposons des systèmes ERP/CRM complets conçus pour répondre aux besoins spécifiques de chaque entreprise, quelle que soit sa taille ou son secteur d’activité.</p>
  
  <p style="font-size:24px;font-weight:bold;">Comprendre les systèmes ERP et CRM</p>
  <p>Les ERP et CRM remplissent des fonctions distinctes mais complémentaires au sein d’une organisation.</p>
  
  <p><strong>Les ERP</strong> centralisent la gestion des processus internes d’une entreprise en intégrant des fonctions clés comme la finance, les ressources humaines, la production et la distribution au sein d’une seule plateforme. Grâce à cette approche unifiée, les entreprises peuvent mieux gérer leurs ressources, améliorer la précision des données et prendre des décisions stratégiques éclairées.</p>
  
  <p><strong>Les CRM</strong>, quant à eux, sont dédiés à la gestion des interactions avec les clients et prospects. Ils permettent de mieux comprendre les comportements des clients, de suivre les opportunités de vente et d’optimiser la satisfaction client grâce à une approche personnalisée.</p>
  
  <p>L’association de ces deux solutions crée une synergie qui améliore l’efficacité opérationnelle et favorise une rentabilité accrue.</p>
  
  <p style="font-size:24px;font-weight:bold;">Pourquoi Choisir Nos Solutions ERP/CRM ?</p>
  
  <p><strong>✅ Intégration Transparente</strong><br>
  Notre logiciel ERP/CRM s’intègre facilement à vos systèmes existants. Inutile de modifier toute votre infrastructure informatique : notre plateforme modulable s’adapte à vos besoins, garantissant une transition fluide et sans perturbations.</p>
  
  <p><strong>📊 Accès aux Données en Temps Réel</strong><br>
  L’accès rapide à l’information est un atout majeur en entreprise. Nos solutions fournissent des données en temps réel, vous permettant de prendre des décisions éclairées. Suivez vos ventes, surveillez vos niveaux de stock et analysez les performances de votre entreprise en un coup d'œil grâce à nos tableaux de bord intuitifs.</p>
  
  <p><strong>🤝 Collaboration Optimisée</strong><br>
  La collaboration est essentielle à la réussite d’une entreprise. Avec notre ERP/CRM, vos équipes peuvent facilement partager des informations et travailler ensemble, quel que soit leur emplacement. Cela favorise la transparence, la réactivité et l’efficacité au sein de votre organisation.</p>
  
  <p><strong>🎯 Une Approche Axée sur le Client</strong><br>
  Notre solution CRM est pensée pour optimiser l’expérience client. Grâce à des analyses avancées, vous pouvez mieux comprendre les attentes et préférences de vos clients. Cela vous permet d’adapter vos campagnes marketing et vos services pour renforcer la fidélisation et accroître vos ventes.</p>
  
  <p><strong>📈 Une Solution Évolutive</strong><br>
  Votre entreprise grandit ? Nos solutions ERP/CRM grandissent avec vous ! Ajoutez de nouvelles fonctionnalités et utilisateurs au fil de votre développement, sans avoir à investir dans un nouvel outil.</p>
  
  <p style="font-size:24px;font-weight:bold;">Conclusion</p>
  <p>L’adoption d’un ERP/CRM peut transformer votre façon de gérer votre entreprise, en vous offrant plus d’efficacité, une meilleure gestion client et des insights précieux pour affiner votre stratégie. Chez [Nom de votre entreprise], nous vous accompagnons pour simplifier vos opérations avec une solution sur mesure, adaptée à vos besoins.</p>
  
  <p>🚀 <strong>Explorez dès aujourd’hui les possibilités offertes par notre ERP/CRM !</strong> Visitez notre site [insérer le lien du site web] et découvrez comment nous pouvons vous aider à optimiser votre activité et atteindre vos objectifs.</p>`,
  },
  {
    title:
      "Stratégies et Meilleures Pratiques pour Utiliser un CRM de Manière Efficace",
    description:
      "Découvrez des stratégies et des bonnes pratiques pour utiliser un CRM efficacement afin d'améliorer la conversion des prospects et renforcer la fidélisation des clients.",
    image: "crm-strategies-article.png",
    date: "13 mars 2025",
    content: `
      <p>Dans le paysage concurrentiel des affaires aujourd'hui, disposer d'un système de gestion de la relation client (CRM) robuste est essentiel pour améliorer la conversion des prospects et renforcer la fidélisation des clients. Pour les utilisateurs d'ERP/CRM qui cherchent à maximiser le potentiel de leur logiciel CRM, la mise en œuvre de stratégies efficaces peut faire toute la différence. Voici quelques meilleures pratiques pour vous aider à tirer le meilleur parti de votre CRM afin d'atteindre vos objectifs commerciaux.</p>
      
      <p style="font-size:24px;font-weight:bold;">1. Comprendre Votre Public</p>
      <p>Avant de vous plonger dans les aspects techniques du CRM, prenez le temps de comprendre les besoins, préférences et comportements de votre public. Utilisez le CRM pour segmenter votre base de données clients en différentes catégories en fonction de critères tels que la démographie, les habitudes d'achat et les niveaux d'engagement. En recueillant et analysant ces données, il devient possible de personnaliser votre approche, ce qui permet de mieux cibler votre communication et d'augmenter vos taux de conversion.</p>
      
      <p style="font-size:24px;font-weight:bold;">2. Simplifier le Processus de Saisie des Données</p>
      <p>L'un des plus grands obstacles à l'adoption du CRM est le temps passé à saisir des données. Automatisez la collecte des données chaque fois que possible pour réduire les saisies manuelles. Utilisez les intégrations avec d'autres outils (comme les plateformes de marketing par e-mail) pour synchroniser les informations de manière fluide. En simplifiant la saisie des données, votre équipe de vente pourra se concentrer davantage sur la construction de relations avec les prospects plutôt que d'être accablée par des tâches administratives, favorisant ainsi une expérience client plus engageante.</p>
      
      <p style="font-size:24px;font-weight:bold;">3. Favoriser la Collaboration Équipe</p>
      <p>Un CRM fonctionne mieux lorsqu'il est adopté par l'ensemble de l'équipe. Encouragez vos équipes de vente, marketing et service client à collaborer en utilisant la plateforme. Les mises à jour régulières et les informations partagées permettent de rationaliser les efforts et d'augmenter la productivité. Une équipe bien informée peut répondre plus rapidement et efficacement aux demandes des clients, ce qui entraîne une amélioration des taux de conversion et de la fidélité des clients.</p>
      
      <p style="font-size:24px;font-weight:bold;">4. Prioriser le Lead Scoring</p>
      <p>Mettez en place un système de scoring des prospects pour prioriser ceux-ci en fonction de leur niveau d'engagement et de leur probabilité de conversion. En attribuant des scores selon des critères prédéfinis, comme les visites de site web, les ouvertures de mails et les interactions sur les réseaux sociaux, vos équipes de vente pourront se concentrer sur les prospects les plus susceptibles de se transformer en clients. Cette approche ciblée peut entraîner des taux de conversion plus élevés et une efficacité accrue.</p>
      
      <p style="font-size:24px;font-weight:bold;">5. Utiliser l'Automatisation pour les Relances</p>
      <p>La communication de suivi est cruciale pour faire avancer les prospects dans l'entonnoir de vente. Utilisez les fonctionnalités d'automatisation de votre CRM pour créer des rappels de suivi et des messages personnalisés qui peuvent être envoyés automatiquement. Cela garantit un engagement rapide avec les prospects, réduisant ainsi les chances de les perdre au profit de la concurrence en raison de l'inaction.</p>
      
      <p style="font-size:24px;font-weight:bold;">6. Suivre et Analyser les Performances</p>
      <p>Surveillez régulièrement et analysez les métriques de votre CRM pour comprendre quelles stratégies fonctionnent et celles qui nécessitent des améliorations. Les indicateurs clés de performance (KPI), tels que les taux de conversion, le coût d'acquisition client et la valeur à vie du client, fourniront des informations sur l'efficacité de votre CRM. Utilisez ces données pour affiner vos techniques de vente et améliorer la satisfaction globale de vos clients.</p>
      
      <p style="font-size:24px;font-weight:bold;">7. Solliciter des Retours pour une Amélioration Continue</p>
      <p>Demandez activement des retours à vos prospects et clients concernant leur expérience avec votre marque et vos interactions CRM. Utilisez des sondages ou des communications directes pour recueillir des informations. Comprendre les perspectives de vos clients vous permettra d'ajuster vos stratégies CRM, renforçant ainsi les relations et la fidélité.</p>
      
      <p style="font-size:24px;font-weight:bold;">Conclusion</p>
      <p>Utiliser un CRM de manière efficace est un processus continu d'apprentissage et d'adaptation. En appliquant ces stratégies et meilleures pratiques, les utilisateurs d'ERP/CRM peuvent améliorer de manière significative leurs taux de conversion des prospects et la fidélité de leurs clients. Un CRM bien utilisé n'est pas seulement un outil ; il fait partie intégrante d'une stratégie commerciale réussie qui vous permet de mieux comprendre, engager et satisfaire vos clients. Adoptez ces pratiques et regardez vos conversions et la fidélité de vos clients se renforcer au fil du temps.</p>
    `,
  },
  {
    title:
      "Maximiser le succès de l'ERP : Conseils essentiels pour une adoption interne efficace",
    description:
      "Découvrez comment maximiser l'efficacité de votre ERP en formant vos équipes de manière optimale pour garantir une adoption réussie au sein de l'entreprise.",
    image: "training-article.jpg",
    date: "13 mars 2025",
    content: `
      <p>La mise en place d'un système de planification des ressources d'entreprise (ERP) peut transformer le fonctionnement de votre entreprise, en rationalisant les processus et en augmentant l'efficacité. Cependant, installer simplement le logiciel ne suffit pas. Il est crucial de veiller à ce que vos employés soient correctement formés pour utiliser le système, afin d'obtenir le meilleur retour sur investissement. Voici des conseils pratiques pour réussir la formation et l'adoption interne de votre système ERP.</p>
      
      <p style="font-size:24px;font-weight:bold;">1. Définir des objectifs clairs</p>
      <p>Avant de commencer toute formation, il est essentiel de définir des objectifs clairs. Réfléchissez à ce que vous voulez accomplir avec le système ERP et à la manière dont vos employés l'utiliseront au quotidien. Identifiez les fonctionnalités clés qui sont les plus pertinentes pour les différents groupes d'utilisateurs au sein de votre organisation. En ayant des objectifs définis, vous pouvez adapter vos sessions de formation aux besoins spécifiques des différents départements ou rôles.</p>
      
      <p style="font-size:24px;font-weight:bold;">2. Impliquer les parties prenantes clés dès le début</p>
      <p>Pour favoriser un sentiment de responsabilité et d'engagement, impliquez les parties prenantes clés dans le processus de mise en œuvre de l'ERP dès le début. Cela pourrait inclure les responsables de département et les chefs d'équipe qui comprennent les besoins uniques de leurs équipes. En sollicitant leurs avis, vous obtenez non seulement des informations précieuses sur les besoins de formation, mais vous renforcez également le soutien au sein de l'organisation, facilitant ainsi l'adoption du nouveau système par l'ensemble de l'équipe.</p>
      
      <p style="font-size:24px;font-weight:bold;">3. Développer un plan de formation complet</p>
      <p>Un plan de formation bien structuré est essentiel pour aider les employés à naviguer dans le nouveau système ERP avec confiance. Divisez la formation en segments gérables, en vous concentrant sur différents aspects tels que la navigation, la saisie des données, les rapports et l'intégration avec d'autres logiciels. Proposer un mélange de méthodes de formation—telles que des ateliers pratiques, des modules en ligne et des sessions de coaching individuelles—permet de répondre aux différentes préférences d'apprentissage et de renforcer la compréhension grâce à l'expérience pratique.</p>
      
      <p style="font-size:24px;font-weight:bold;">4. Créer des ressources conviviales pour les utilisateurs</p>
      <p>Complétez vos sessions de formation avec des ressources conviviales que les employés pourront consulter ultérieurement. Cela pourrait inclure des tutoriels vidéo, des fiches de révision et des questions fréquemment posées (FAQ). Fournir un accès facile à ces documents permettra au personnel de revoir les concepts à leur propre rythme et d'aider à renforcer leur apprentissage au fil du temps. Pensez à mettre en place une base de connaissances interne ou une page intranet dédiée aux ressources ERP.</p>
      
      <p style="font-size:24px;font-weight:bold;">5. Encourager une culture d'apprentissage continu</p>
      <p>Comprendre que l'adoption d'un nouveau système ERP est un processus continu est essentiel pour le succès à long terme. Encouragez une culture d'apprentissage continu où les employés se sentent à l'aise de poser des questions et de demander de l'aide lorsqu'ils rencontrent des difficultés. Des cours de remise à niveau ou des sessions de formation avancée programmées régulièrement peuvent maintenir les compétences aiguisées et garantir que le personnel reste informé des nouvelles fonctionnalités ou améliorations.</p>
      
      <p style="font-size:24px;font-weight:bold;">6. Recueillir des retours et ajuster en conséquence</p>
      <p>Après les premières sessions de formation, il est important de recueillir les retours des employés concernant leur expérience. Des enquêtes ou des discussions informelles peuvent fournir des informations sur les aspects de la formation qui ont été efficaces et ceux qui pourraient être améliorés. Soyez prêt à ajuster votre programme de formation en fonction de ces retours afin de garantir qu'il reste pertinent et efficace.</p>
      
      <p style="font-size:24px;font-weight:bold;">7. Célébrer les succès et reconnaître la progression</p>
      <p>Enfin, célébrez les réussites à mesure que votre équipe devient compétente dans l'utilisation du système ERP. Reconnaître la progression des employés motive non seulement les individus, mais renforce également l'importance du système ERP dans l'atteinte des objectifs commerciaux globaux. Mettre en avant des histoires de réussite peut inspirer les autres et créer un environnement positif autour de la transition.</p>
      
      <p style="font-size:24px;font-weight:bold;">Conclusion</p>
      <p>En conclusion, la formation efficace et l'adoption interne de votre système ERP sont un processus multifacette qui nécessite une planification réfléchie et des efforts continus. En vous concentrant sur des objectifs clairs, en impliquant les parties prenantes, en développant des plans de formation complets et en favorisant une culture d'apprentissage, vous pouvez maximiser le potentiel de votre nouveau système ERP et orienter votre organisation vers un succès accru.</p>
    `,
  },
  {
    title:
      "Erreurs courantes à éviter lors de l'implémentation d'un système ERP",
    description:
      "Découvrez les erreurs les plus fréquentes lors de l'implémentation d'un ERP et comment les éviter pour assurer le succès de votre projet.",
    image: "erp-implementation-errors.png",
    date: "13 mars 2025",
    content: `
      <p>Implémenter un système de planification des ressources d'entreprise (ERP) peut être une étape transformative pour les entreprises cherchant à rationaliser leurs opérations, améliorer l'efficacité et renforcer la visibilité au sein de différents départements. Cependant, de nombreuses organisations rencontrent des défis pendant le déploiement, ce qui peut conduire à l'échec du projet. Pour garantir le succès de l'implémentation de votre ERP, il est essentiel de comprendre et d'éviter les erreurs les plus courantes qui peuvent freiner l'avancement.</p>
  
      <p style="font-size:24px;font-weight:bold;">1. Mauvaise formation des équipes</p>
      <p>L'un des aspects les plus critiques d'une implémentation ERP réussie est de s'assurer que votre équipe est correctement préparée. Une erreur fréquente consiste à sous-estimer l'importance de la formation. De nombreuses entreprises supposent que leurs employés s'adapteront rapidement au nouveau système, mais cela peut entraîner de la confusion et de la frustration. Pour éviter ce piège, investissez du temps et des ressources dans des sessions de formation complètes adaptées aux différents rôles des utilisateurs au sein de votre organisation. Encouragez la pratique pratique pour aider les utilisateurs à se sentir plus à l'aise avec le logiciel, et envisagez un support continu au fur et à mesure que l'équipe commence à utiliser régulièrement le système.</p>
  
      <p style="font-size:24px;font-weight:bold;">2. Migration des données incorrecte</p>
      <p>La migration des données est une étape cruciale qui consiste à transférer les données existantes dans le nouveau système ERP. Une erreur importante se produit souvent lorsque les entreprises ne nettoient pas soigneusement leurs données avant la migration. Cela peut entraîner le transfert de données inexactes ou non pertinentes, ce qui compromet l'intégrité du nouveau système. Pour réduire ce risque, effectuez un audit de vos données existantes et éliminez les doublons ou erreurs avant la migration. Créez un plan de cartographie détaillé pour garantir que les données sont transférées correctement et dans un format que le nouvel ERP peut utiliser efficacement.</p>
  
      <p style="font-size:24px;font-weight:bold;">3. Choisir un ERP non adapté</p>
      <p>Une autre erreur courante dans le déploiement d’un ERP est de choisir un système qui ne correspond pas aux besoins spécifiques de l’entreprise. De nombreuses organisations prennent des décisions basées uniquement sur les fonctionnalités ou le prix, sans tenir compte de la manière dont le logiciel s'intègre dans leurs processus et infrastructures existants. Pour éviter cette erreur, réalisez une évaluation approfondie des besoins avant de sélectionner une solution ERP. Impliquez les parties prenantes de différents départements pour recueillir des informations sur leurs besoins et défis. Il est également avantageux d'explorer plusieurs fournisseurs et de profiter des versions de démonstration pour vous assurer que le logiciel répond aux besoins uniques de votre organisation.</p>
  
      <p style="font-size:24px;font-weight:bold;">4. Gestion de projet insuffisante</p>
      <p>Implémenter un système ERP est un projet complexe qui nécessite des compétences solides en gestion de projet et en leadership. Une erreur fréquente est de ne pas nommer un responsable de projet dédié ou une équipe pour superviser le processus d'implémentation. Sans une stratégie claire et des responsabilités définies, les projets peuvent rapidement dévier, entraînant des retards et des dépassements de budget. Désignez un chef de projet expérimenté dans les implémentations ERP, capable de coordonner les équipes, de gérer les délais et de tenir les parties prenantes informées.</p>
  
      <p style="font-size:24px;font-weight:bold;">5. Négliger l'évaluation post-implémentation</p>
      <p>Une fois le système ERP mis en production, le processus n'est pas terminé. De nombreuses entreprises négligent de réaliser une évaluation post-implémentation, ce qui est une occasion manquée d'amélioration continue. Effectuez des évaluations régulières pour analyser le bon fonctionnement du système ERP, recueillir des retours des utilisateurs et identifier les axes d'optimisation. Cet engagement continu pour l'amélioration peut aider à maximiser les avantages du système ERP et garantir qu'il continue à répondre aux besoins évolutifs de l'organisation.</p>
  
      <p>L'implémentation d'un ERP ne doit pas être une tâche décourageante. En restant attentif à ces erreurs courantes et en prenant des mesures proactives, les entreprises peuvent favoriser une transition plus fluide et exploiter pleinement le potentiel de leur nouveau système. Avec une planification soignée, une formation et une évaluation continues, l'implémentation d'un ERP peut entraîner d'importantes améliorations opérationnelles et un succès à long terme.</p>
    `,
  },
];
