import { CommercialCycle } from "../../models/commercialCycle";
import { Complaint } from "../../models/complaint";
import { Contact } from "../../models/contact";
import { Opportunity } from "../../models/opportunity";
import { Task } from "../../models/task";

export const GET_CLIENTS = "GET_CLIENTS" as const;
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS" as const;
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE" as const;

export const GET_PROSPECTS = "GET_PROSPECTS" as const;
export const GET_PROSPECTS_SUCCESS = "GET_PROSPECTS_SUCCESS" as const;
export const GET_PROSPECTS_FAILURE = "GET_PROSPECTS_FAILURE" as const;

export const GET_CONTACTS = "GET_CONTACTS" as const;
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS" as const;
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE" as const;

export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES" as const;
export const GET_OPPORTUNITIES_SUCCESS = "GET_OPPORTUNITIES_SUCCESS" as const;
export const GET_OPPORTUNITIES_FAILURE = "GET_OPPORTUNITIES_FAILURE" as const;

export const GET_COMMERCIAL_CYCLES = "GET_COMMERCIAL_CYCLES" as const;
export const GET_COMMERCIAL_CYCLES_SUCCESS =
  "GET_COMMERCIAL_CYCLES_SUCCESS" as const;
export const GET_COMMERCIAL_CYCLES_FAILURE =
  "GET_COMMERCIAL_CYCLES_FAILURE" as const;

export const GET_COMPLAINTS = "GET_COMPLAINTS" as const;
export const GET_COMPLAINTS_SUCCESS = "GET_COMPLAINTS_SUCCESS" as const;
export const GET_COMPLAINTS_FAILURE = "GET_COMPLAINTS_FAILURE" as const;

export const GET_TASKS = "GET_TASKS" as const;
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS" as const;
export const GET_TASKS_FAILURE = "GET_TASKS_FAILURE" as const;

export const getClientsAction = (criteriaList?: string) => ({
  type: GET_CLIENTS,
  criteriaList: criteriaList,
});
export type GetClientsAction = ReturnType<typeof getClientsAction>;

export const getClientsActionSuccess = (clients: Contact[]) => ({
  type: GET_CLIENTS_SUCCESS,
  clients: clients,
});
export type GetClientsActionSuccess = ReturnType<
  typeof getClientsActionSuccess
>;

export const getClientsActionFailure = () => ({
  type: GET_CLIENTS_FAILURE,
});
export type GetClientsActionFailure = ReturnType<
  typeof getClientsActionFailure
>;

export const getProspectsAction = (criteriaList?: string) => ({
  type: GET_PROSPECTS,
  criteriaList: criteriaList,
});
export type GetProspectsAction = ReturnType<typeof getProspectsAction>;

export const getProspectsActionSuccess = (prospects: Contact[]) => ({
  type: GET_PROSPECTS_SUCCESS,
  prospects: prospects,
});
export type GetProspectsActionSuccess = ReturnType<
  typeof getProspectsActionSuccess
>;

export const getProspectsActionFailure = () => ({
  type: GET_PROSPECTS_FAILURE,
});
export type GetProspectsActionFailure = ReturnType<
  typeof getProspectsActionFailure
>;

export const getContactsAction = (criteriaList?: string) => ({
  type: GET_CONTACTS,
  criteriaList: criteriaList,
});
export type GetContactsAction = ReturnType<typeof getContactsAction>;

export const getContactsActionSuccess = (contacts: Contact[]) => ({
  type: GET_CONTACTS_SUCCESS,
  contacts: contacts,
});
export type GetContactsActionSuccess = ReturnType<
  typeof getContactsActionSuccess
>;

export const getContactsActionFailure = () => ({
  type: GET_CONTACTS_FAILURE,
});
export type GetContactsActionFailure = ReturnType<
  typeof getContactsActionFailure
>;

export const getOpportunitiesAction = (criteriaList?: string) => ({
  type: GET_OPPORTUNITIES,
  criteriaList: criteriaList,
});
export type GetOpportunitiesAction = ReturnType<typeof getOpportunitiesAction>;

export const getOpportunitiesActionSuccess = (
  opportunities: Opportunity[]
) => ({
  type: GET_OPPORTUNITIES_SUCCESS,
  opportunities: opportunities,
});
export type GetOpportunitiesActionSuccess = ReturnType<
  typeof getOpportunitiesActionSuccess
>;

export const getOpportunitiesActionFailure = () => ({
  type: GET_OPPORTUNITIES_FAILURE,
});
export type GetOpportunitiesActionFailure = ReturnType<
  typeof getOpportunitiesActionFailure
>;

export const getCommercialCyclesAction = (criteriaList?: string) => ({
  type: GET_COMMERCIAL_CYCLES,
  criteriaList: criteriaList,
});
export type GetCommercialCyclesAction = ReturnType<
  typeof getCommercialCyclesAction
>;

export const getCommercialCyclesActionSuccess = (
  commercialCycles: CommercialCycle[]
) => ({
  type: GET_COMMERCIAL_CYCLES_SUCCESS,
  commercialCycles: commercialCycles,
});
export type GetCommercialCyclesActionSuccess = ReturnType<
  typeof getCommercialCyclesActionSuccess
>;

export const getCommercialCyclesActionFailure = () => ({
  type: GET_COMMERCIAL_CYCLES_FAILURE,
});
export type GetCommercialCyclesActionFailure = ReturnType<
  typeof getCommercialCyclesActionFailure
>;

export const getComplaintsAction = (criteriaList?: string) => ({
  type: GET_COMPLAINTS,
  criteriaList: criteriaList,
});
export type GetComplaintsAction = ReturnType<typeof getComplaintsAction>;

export const getComplaintsActionSuccess = (complaints: Complaint[]) => ({
  type: GET_COMPLAINTS_SUCCESS,
  complaints: complaints,
});
export type GetComplaintsActionSuccess = ReturnType<
  typeof getComplaintsActionSuccess
>;

export const getComplaintsActionFailure = () => ({
  type: GET_COMPLAINTS_FAILURE,
});
export type GetComplaintsActionFailure = ReturnType<
  typeof getComplaintsActionFailure
>;

export const getTasksAction = (criteriaList?: string) => ({
  type: GET_TASKS,
  criteriaList: criteriaList,
});
export type GetTasksAction = ReturnType<typeof getTasksAction>;

export const getTasksActionSuccess = (tasks: Task[]) => ({
  type: GET_TASKS_SUCCESS,
  tasks: tasks,
});
export type GetTasksActionSuccess = ReturnType<typeof getTasksActionSuccess>;

export const getTasksActionFailure = () => ({
  type: GET_TASKS_FAILURE,
});
export type GetTasksActionFailure = ReturnType<typeof getTasksActionFailure>;
