import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dish } from "../../../../../../models/dish";
import { Ingredient } from "../../../../../../models/ingredients";
import { Dispatch, RootState } from "../../../../../../store";
import { getIngredientsAction } from "../../../../../../store/Stock/actions";
import { getIngredients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getUnitDescription } from "../../../../../Reusable/Utils";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MapFieldsProps {
  dishes: Dish[];
  ingredients: Ingredient[];
  fetchedValues: string[][];
  outputErrors: string[];
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
}

const _MapFields: React.FC<MapFieldsProps> = (props) => {
  let fields = [
    "Image",
    "Nom",
    "Catégorie",
    "Quantité",
    "Prix",
    "Allergies",
    "Quantité minimale",
    "Quantité idéale",
    "Suggestion par évènement",
    "À vendre",
    "Durée d'expiration",
    "Unités d'achat",
    "Unités de stockage",
    "Unités de production",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
  ];

  const getValueCells = (headValue: string, dish: Dish) => {
    let headValuesMap: Map<string, string | React.JSX.Element> = new Map([
      ["Nom", dish.name],
      ["Catégorie", dish.category],
      ["Tags", dish.tags.join(", ")],
      ["Code interne", dish.internalCode],
      ["En vente", dish.forSale ? "Oui" : "Non"],
      ["Stocké", dish.stored ? "Oui" : "Non"],
      ["Temps de préparation", dish.preparationTime.toString()],
      ["Instructions", dish.instructions],
      ["Chefs", dish.cooks.join(", ")],
      ["Quantité produite", dish.productionQuantity.toString()],
      [
        "Unité de production",
        dish.productionUnit ? getUnitDescription(dish.productionUnit) : "",
      ],
      ["Temps de cuisson", dish.cookingTime.toString()],
      [
        "Nomenclature",
        dish.nomenclature
          .map(
            (nomenclatureItem) =>
              nomenclatureItem.ingredient.name +
              "(" +
              nomenclatureItem.quantity +
              " " +
              nomenclatureItem.unit.name +
              ")"
          )
          .join(", "),
      ],
      [
        "Suppléments",
        dish.extraOptions
          .map(
            (extraOption) =>
              extraOption.ingredient.name +
              "(" +
              extraOption.quantity +
              " " +
              extraOption.unit.name +
              ")"
          )
          .join(", "),
      ],
      ["Température de cuisson", dish.cookingTemperature.toString()],
      [
        "Options de vente",
        dish.saleOptions
          .map((saleOption) => saleOption.name + "(" + saleOption.priceHT + ")")
          .join(", "),
      ],
      ["Énergie (Kj)", dish.energyKj + (dish.energyKj === null ? "" : " Kj")],
      [
        "Énergie (Kcal)",
        dish.energyKcal + (dish.energyKcal === null ? "" : " Kcal"),
      ],
      ["Protéines", dish.proteins + (dish.proteins === null ? "" : " g")],
      [
        "Glucides",
        dish.carbohydrates + (dish.carbohydrates === null ? "" : " g"),
      ],
      ["Lipides", dish.lipids + (dish.lipids === null ? "" : " g")],
      ["Sucres", dish.sugar + (dish.sugar === null ? "" : " g")],
      [
        "Acides gras saturés",
        dish.saturatedFattyAcids +
          (dish.saturatedFattyAcids === null ? "" : " g"),
      ],
      ["Sel", dish.salt + (dish.salt === null ? "" : " g")],
      ["Calcium", dish.calcium + (dish.calcium === null ? "" : " g")],
      ["Vitamine D", dish.vitaminD + (dish.vitaminD === null ? "" : " g")],
      [
        "Fibres alimentaires",
        dish.dietaryFiber + (dish.dietaryFiber === null ? "" : " g"),
      ],
      [
        "Cholestérol",
        dish.cholesterol + (dish.cholesterol === null ? "" : " g"),
      ],
    ]);
    let headComplexValuesMap = new Map([
      [
        "Image",
        <BlockContainer
          width="100px"
          height="100px"
          border="rgba(0,0,0,0.1) solid 1px"
          justifyContent="center"
          position="relative"
          textAlign="center"
          backgroundColor="white"
          margin="auto"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            alt=""
            src={dish?.imageUrl}
          />
        </BlockContainer>,
      ],
    ]);
    return headValuesMap.get(headValue) !== undefined
      ? headValuesMap.get(headValue)
      : headComplexValuesMap.get(headValue);
  };

  const classes = useStyles();

  return (
    <BlockContainer>
      <TableContainerMui $maxHeight="45vh">
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map((header, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dishes.map((dish, index) => (
              <TableRow key={index}>
                {fields.map((field, index2) => (
                  <TableCell key={index2}>
                    {getValueCells(field, dish)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <BlockContainer
        margin="16px"
        border="1px solid rgba(0,0,0,0.2)"
        $borderRadius="4px"
        $maxHeight="15vh"
        overflow="auto"
      >
        {props.outputErrors.map((outputError, index) => (
          <Text14 color={CustomTheme.palette.secondary.main} key={index}>
            {outputError}
          </Text14>
        ))}
        {props.outputErrors.length === 0 && (
          <Text14>Fichier valide! Vous pouvez importer vos données</Text14>
        )}
      </BlockContainer>
    </BlockContainer>
  );
};

export const MapFields = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_MapFields);

export default MapFields;
