import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import GroupIcon from "@mui/icons-material/Group";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text16, Text24, Text36 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface FeaturesSectionProps {}

const FeaturesSection: React.FC<FeaturesSectionProps> = () => {
  const navigate = useNavigate();
  const features = [
    {
      icon: <WarehouseTwoToneIcon sx={{ fontSize: 60, color: "#A52626" }} />,
      title: "Stock",
      description:
        "Suivez et gérez vos stocks en temps réel pour éviter les ruptures et optimiser vos ressources.",
      bgColor: "#CB2929",
    },
    {
      icon: <ShoppingCartIcon sx={{ fontSize: 50, color: "#9B6717" }} />,
      title: "Ventes",
      description:
        "Centralisez vos commandes, automatisez la facturation et boostez vos performances commerciales.",
      bgColor: "#BF7C16",
    },
    {
      icon: <LocalShippingIcon sx={{ fontSize: 50, color: "#107B75" }} />,
      title: "Livraisons",
      description:
        "Planifiez et optimisez vos tournées pour des livraisons rapides et sans erreur.",
      bgColor: "#14A89F",
    },
    {
      icon: <LibraryBooksIcon sx={{ fontSize: 50, color: "#C231DA" }} />,
      title: "Catalogue",
      description:
        "Gérez et mettez à jour vos produits et services en toute simplicité.",
      bgColor: "#9314A8",
    },
    {
      icon: <RequestQuoteIcon sx={{ fontSize: 50, color: "#72A6E0" }} />,
      title: "Devis",
      description: "Créez et envoyez vos devis en un clic avec suivi intégré.",
      bgColor: "#A6C9F2",
    },
    {
      icon: <ReceiptIcon sx={{ fontSize: 50, color: "#D1A126" }} />,
      title: "Factures",
      description: "Générez, envoyez et suivez vos factures sans effort.",
      bgColor: "#EBC66D",
    },
    {
      icon: <GroupIcon sx={{ fontSize: 50, color: "#43A047" }} />,
      title: "CRM",
      description:
        "Pilotez vos clients, prospects et opportunités pour maximiser vos ventes.",
      bgColor: "#66BB6A",
    },
    {
      icon: <BadgeTwoToneIcon sx={{ fontSize: 50, color: "#E57373" }} />,
      title: "RH",
      description:
        "Gérez employés, congés et tâches pour une meilleure organisation.",
      bgColor: "#EF9A9A",
    },
  ];

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      sx={{
        padding: { xs: "16px", sm: "40px 146px" },
        marginBottom: { xs: "16px", sm: "64px" },
      }}
    >
      {/* Titre */}
      <FlexContainer margin="40px 0 64px 0">
        <Text36 fontWeight="600">Fonctionnalités</Text36>
      </FlexContainer>

      {/* Grid des fonctionnalités */}
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <FlexContainer
              backgroundColor="white"
              elevation={3}
              elevationHover={6}
              $cursorHover="pointer"
              sx={{
                borderRadius: "12px",
                padding: "32px",
                height: "100%",
                flexDirection: "column", // Arrange les enfants verticalement
                justifyContent: "space-between", // Pousse l'icône vers le bas
              }}
              onClick={() => navigate("/features")}
            >
              <FlexContainer
                flexDirection="column"
                alignItems="center"
                flexGrow={1}
              >
                {/* Icône dans un cercle */}
                <FlexContainer
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: `${feature.bgColor}20`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "16px",
                    width: { xs: "80px", sm: "120px" },
                    height: { xs: "80px", sm: "120px" },
                  }}
                >
                  {feature.icon}
                </FlexContainer>
                {/* Titre */}
                <Text24 fontWeight="500">{feature.title}</Text24>
                {/* Description */}
                <Text16
                  lineHeight="25px"
                  color="rgba(0,0,0,0.7)"
                  textAlign="center"
                >
                  {feature.description}
                </Text16>
              </FlexContainer>

              {/* Icône en bas */}
              <FlexContainer justifyContent="center" marginTop="auto">
                <ArrowCircleRightOutlinedIcon
                  sx={{
                    fontSize: 50,
                    color: CustomTheme.palette.secondary.main,
                    marginTop: "16px",
                  }}
                />
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
    </FlexContainer>
  );
};

export default FeaturesSection;
