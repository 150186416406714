import { RootAction } from "../types";
import { GET_NOTIFICATIONS_SUCCESS } from "./actions";
import { NotificationState } from "./types";

const initialNotificationState: NotificationState = {
  notifications: [],
};

export function notificationReducer(
  state: NotificationState = initialNotificationState,
  action: RootAction
): NotificationState {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        notifications: action.notifications,
      };

    default:
      return state;
  }
}
