import { createFilterOptions } from "@mui/core";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Complaint } from "../../../../../models/complaint";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee, EmployeeDocument } from "../../../../../models/employee";
import { Route } from "../../../../../models/route";
import { Sale } from "../../../../../models/sale";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getClientsAction,
  getComplaintsAction,
} from "../../../../../store/Crm/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getRoutesAction } from "../../../../../store/Route/actions";
import { getSalesAction } from "../../../../../store/Sale/actions";
import {
  getClients,
  getComplaints,
  getEmployees,
  getRoutes,
  getSales,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getEmployeeDescription, toFixed2 } from "../../../../Reusable/Utils";
import { Documents } from "../AddEmployee/Documents";
dayjs.extend(utc);
dayjs.extend(timezone);

const Input = styled("input")({
  display: "none",
});

export interface EditEmployeeProps {
  popupActive: Employee | null;
  sales: Sale[];
  routes: Route[];
  complaints: Complaint[];
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<Employee | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getSalesAction: typeof getSalesAction;
  getRoutesAction: typeof getRoutesAction;
  getComplaintsAction: typeof getComplaintsAction;
  actionsOnEdit?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _EditEmployee: React.FC<EditEmployeeProps> = ({
  popupActive,
  employees,
  setPopupActive,
  getEmployeesAction,
  actionsOnEdit,
}) => {
  /* TODO */
  const editEmployee = () => {
    AxiosHttpClient.put("api/v1/weeventpro/employees", {
      id: popupActive!.id,
      imageUrl: inputImageUrl,
      title: inputTitle,
      firstName: inputFirstName,
      lastName: inputLastName,
      dateOfBirth: inputDateOfBirth
        ? dayjs(inputDateOfBirth).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      phoneNumber: inputPhoneNumber,
      email: inputEmail,
      address: inputAddress,
      languages: inputLanguages,
      employeeNumber: inputEmployeeNumber,
      position: inputPosition,
      supervisorId: inputSupervisor ? inputSupervisor.id : null,
      hireDate: inputHireDate
        ? dayjs(inputHireDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      contractType: inputContractType,
      contratEndDate: inputContratEndDate
        ? dayjs(inputContratEndDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      workStatus: inputWorkStatus,
      percentage: inputPercentage,
      weeklyHours: inputWeeklyHours,
      effectiveHours: inputEffectiveHours,
      baseSalary: inputBaseSalary,
      workSchedule: inputWorkSchedule,
      socialSecurityNumber: inputSocialSecurityNumber,
      taxIdNumber: inputTaxIdNumber,
      cpAnnualNumber: inputCpAnnualNumber,
      cpBalance: inputCpBalance,
      rttAnnualNumber: inputRttAnnualNumber,
      rttBalance: inputRttBalance,
      documents: inputDocuments.map((document) => {
        return {
          ...document,
          expirationDate: document.expirationDate
            ? dayjs(document.expirationDate)
                .tz("Europe/Paris")
                .format("YYYY-MM-DD")
            : null,
        };
      }),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnEdit?.forEach((action) => action());
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editEmployeeDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/employee"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputFirstNameError(false);
    setInputLastNameError(false);
    let result = true;
    if (inputFirstName === "") {
      setInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      setInputLastNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editEmployeeDialogContentId",
      });
    }
    return result;
  };

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    popupActive!.imageUrl
  );

  const [clientImageHovered, setClientImageHovered] = useState(false);

  const [inputTitle, setInputTitle] = useState(popupActive!.title);

  const [inputFirstName, setInputFirstName] = useState(popupActive!.firstName);

  const [inputFirstNameError, setInputFirstNameError] = useState(false);

  const [inputLastName, setInputLastName] = useState(popupActive!.lastName);

  const [inputLastNameError, setInputLastNameError] = useState(false);

  const [inputDateOfBirth, setInputDateOfBirth] = useState<Dayjs | null>(
    popupActive!.dateOfBirth
  );

  const [inputPhoneNumber, setInputPhoneNumber] = useState(
    popupActive!.phoneNumber
  );

  const [inputEmail, setInputEmail] = useState(popupActive!.email);

  const [inputAddress, setInputAddress] = useState(popupActive!.address);

  const [inputLanguages, setInputLanguages] = useState<string[]>(
    popupActive!.languages
  );

  const [inputEmployeeNumber, setInputEmployeeNumber] = useState(
    popupActive!.employeeNumber
  );

  const [inputPosition, setInputPosition] = useState(popupActive!.position);

  const [inputSupervisor, setInputSupervisor] = useState<Employee | null>(
    popupActive!.supervisor
  );

  const [inputHireDate, setInputHireDate] = useState<Dayjs | null>(
    popupActive!.hireDate
  );

  const [inputContractType, setInputContractType] = useState(
    popupActive!.contractType
  );

  const [inputContratEndDate, setInputContratEndDate] = useState<Dayjs | null>(
    popupActive!.contratEndDate
  );

  const [inputWorkStatus, setInputWorkStatus] = useState(
    popupActive!.workStatus
  );

  const [inputPercentage, setInputPercentage] = useState(
    popupActive!.percentage?.toString() || ""
  );

  const [inputWeeklyHours, setInputWeeklyHours] = useState(
    popupActive!.weeklyHours?.toString() || ""
  );

  const [inputEffectiveHours, setInputEffectiveHours] = useState(
    popupActive!.effectiveHours?.toString() || ""
  );

  const [inputBaseSalary, setInputBaseSalary] = useState(
    toFixed2(popupActive!.baseSalary)
  );

  const [inputWorkSchedule, setInputWorkSchedule] = useState(
    popupActive!.workSchedule
  );

  const [inputSocialSecurityNumber, setInputSocialSecurityNumber] = useState(
    popupActive!.socialSecurityNumber
  );

  const [inputTaxIdNumber, setInputTaxIdNumber] = useState(
    popupActive!.taxIdNumber
  );

  const [inputCpAnnualNumber, setInputCpAnnualNumber] = useState(
    popupActive!.cpAnnualNumber?.toString() || ""
  );

  const [inputCpBalance, setInputCpBalance] = useState(
    popupActive!.cpBalance?.toString() || ""
  );

  const [inputRttAnnualNumber, setInputRttAnnualNumber] = useState(
    popupActive!.rttAnnualNumber?.toString() || ""
  );

  const [inputRttBalance, setInputRttBalance] = useState(
    popupActive!.rttBalance?.toString() || ""
  );

  const [inputDocuments, setInputDocuments] = useState<EmployeeDocument[]>(
    popupActive!.documents
  );

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterEmployeesOptions = createFilterOptions<Employee>({
    stringify: (option) => getEmployeeDescription(option),
    limit: 20,
  });

  useEffect(() => {
    getEmployeesAction("");
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientsAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un employé"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editEmployeeDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <BlockContainer
              width="150px"
              height="150px"
              border="rgba(0,0,0,0.1) solid 1px"
              justifyContent="center"
              position="relative"
              textAlign="center"
              onMouseEnter={() => setClientImageHovered(true)}
              onMouseLeave={() => setClientImageHovered(false)}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                }}
                alt=""
                src={inputImageUrl}
              />
              {clientImageHovered && (
                <FlexContainer
                  position="absolute"
                  top="0"
                  left="0"
                  width="150px"
                  height="150px"
                  backgroundColor="rgba(0,0,0,0.5)"
                  alignItems="center"
                  justifyContent="center"
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => {
                        imagePreviewHandler(e.target.files![0]);
                      }}
                    />
                    <IconButton
                      color="secondary"
                      component="span"
                      onClick={() => {}}
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <AddAPhotoIcon
                        sx={{ color: "white", fontSize: "32px" }}
                      />
                    </IconButton>
                  </label>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setInputImageUrl(
                        "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                      )
                    }
                    size="large"
                    style={{ padding: "4px", zIndex: "1" }}
                  >
                    <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                  </IconButton>
                </FlexContainer>
              )}
            </BlockContainer>
          </Grid>
        </Grid>
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations personnelles{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Civilité
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputTitle}
                  onChange={(e) => setInputTitle(e.target.value)}
                  row
                >
                  <FormControlLabel value="Mr" control={<Radio />} label="Mr" />
                  <FormControlLabel
                    value="Mme"
                    control={<Radio />}
                    label="Mme"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="firstName"
                label="Prénom"
                error={inputFirstNameError}
                value={inputFirstName}
                onChange={(e) => {
                  setInputFirstName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="lastName"
                label="Nom"
                error={inputLastNameError}
                value={inputLastName}
                onChange={(e) => {
                  setInputLastName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="DD/MM/YYYY"
                  value={inputDateOfBirth}
                  onChange={(value) => setInputDateOfBirth(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="phoneNumber"
                label="N° de téléphone personnel"
                value={inputPhoneNumber}
                onChange={(e) => {
                  setInputPhoneNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                label="E-mail"
                value={inputEmail}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="languages"
                noOptionsText={"Pas de suggestions"}
                fullWidth
                multiple
                disableClearable
                disableCloseOnSelect
                freeSolo
                options={[
                  "Français",
                  "Anglais",
                  "Espagnol",
                  "Allemand",
                  "Italien",
                  "Chinois",
                  "Arabe",
                  "Portugais",
                  "Russe",
                  "Japonais",
                  "Coréen",
                  "Hindi",
                  "Bengali",
                  "Néerlandais",
                  "Turc",
                  "Suédois",
                ]}
                value={inputLanguages}
                onChange={(event, newValue) => {
                  setInputLanguages(newValue!);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="languages"
                    label="Langues parlées"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                label="Adresse"
                value={inputAddress}
                onChange={(e) => setInputAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Informations professionnelles
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="employeeId"
              label="Identifiant d'employé"
              value={inputEmployeeNumber}
              onChange={(e) => {
                setInputEmployeeNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="position"
              label="Poste"
              value={inputPosition}
              onChange={(e) => {
                setInputPosition(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="responsibles"
              filterOptions={filterEmployeesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              getOptionLabel={(option) => {
                return option.firstName + " " + option.lastName;
              }}
              options={employees}
              value={inputSupervisor}
              onChange={(event, newValue) => {
                setInputSupervisor(newValue!);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer flex="4">
                    <Text14 textAlign="left">
                      {option.firstName + " " + option.lastName}
                    </Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="supervisor"
                  label="Superviseur"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date d'embauche"
                inputFormat="DD/MM/YYYY"
                value={inputHireDate}
                onChange={(value) => setInputHireDate(value!)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Type de contrat
              </InputLabel>
              <Select
                required
                fullWidth
                value={inputContractType}
                label="Priorité"
                onChange={(e) => {
                  setInputContractType(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="CDI">CDI</MenuItem>
                <MenuItem value="CDD">CDD</MenuItem>
                <MenuItem value="Stage">Stage</MenuItem>
                <MenuItem value="Intérim">Intérim</MenuItem>
                <MenuItem value="Apprentissage">Apprentissage</MenuItem>
                <MenuItem value="Freelance">Freelance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date de fin de contrat"
                inputFormat="DD/MM/YYYY"
                value={inputContratEndDate}
                onChange={(value) => setInputContratEndDate(value!)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Statut</InputLabel>
              <Select
                required
                fullWidth
                value={inputWorkStatus}
                label="Statut"
                onChange={(e) => {
                  setInputWorkStatus(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="Temps plein">Temps plein</MenuItem>
                <MenuItem value="Temps partiel">Temps partiel</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="percentage"
              label="Pourcentage"
              value={inputPercentage}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputWeeklyHours))
                  ) {
                    setInputEffectiveHours(
                      toFixed2(
                        Number(inputWeeklyHours) * Number(e.target.value) * 0.01
                      )
                    );
                  }
                  setInputPercentage(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="weeklyHours"
              label="Nombre d'heure hebdomadaires"
              value={inputWeeklyHours}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputPercentage))
                  ) {
                    setInputEffectiveHours(
                      toFixed2(
                        Number(e.target.value) * Number(inputPercentage) * 0.01
                      )
                    );
                  }
                  setInputWeeklyHours(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Heure(s)</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="effectiveHours"
              label="Nombre d'heures effectif"
              value={inputEffectiveHours}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (
                    !isNaN(Number(e.target.value)) &&
                    !isNaN(Number(inputPercentage))
                  ) {
                    setInputWeeklyHours(
                      toFixed2(
                        Number(e.target.value) /
                          (Number(inputPercentage) * 0.01)
                      )
                    );
                  }
                  setInputEffectiveHours(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Heure(s)</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="baseSalary"
              label="Salaire de base"
              value={inputBaseSalary}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputBaseSalary(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Régime de travail
              </InputLabel>
              <Select
                required
                fullWidth
                value={inputWorkSchedule}
                label="Régime de travail"
                onChange={(e) => {
                  setInputWorkSchedule(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="Horaire">Horaire</MenuItem>
                <MenuItem value="Journalier">Journalier</MenuItem>
                <MenuItem value="Mensuel">Mensuel</MenuItem>
                <MenuItem value="Annuel">Annuel</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="socialSecurityNumber"
              label="Numéro de sécurité sociale"
              value={inputSocialSecurityNumber}
              onChange={(e) => {
                setInputSocialSecurityNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="taxIdNumber"
              label="Numéro d'identification fiscale"
              value={inputTaxIdNumber}
              onChange={(e) => {
                setInputTaxIdNumber(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Documents administratifs
          </Text20>
        </BlockContainer>

        <Documents
          inputDocuments={inputDocuments}
          setInputDocuments={setInputDocuments}
        />

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Congés
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cpAnnualNumber"
              label="Nombre de CP annuel"
              value={inputCpAnnualNumber}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCpAnnualNumber(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Jours</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cpBalance"
              label="Solde CP"
              value={inputCpBalance}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCpBalance(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Jours</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="rttAnnualNumber"
              label="Nombre de RTT annuel"
              value={inputRttAnnualNumber}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputRttAnnualNumber(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Jours</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="rttBalance"
              label="Solde RTT"
              value={inputRttBalance}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputRttBalance(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Jours</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editEmployee();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditEmployee = connect(
  (state: RootState) => ({
    clients: getClients(state),
    employees: getEmployees(state),
    sales: getSales(state),
    routes: getRoutes(state),
    complaints: getComplaints(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
        getEmployeesAction: getEmployeesAction,
        getSalesAction: getSalesAction,
        getRoutesAction: getRoutesAction,
        getComplaintsAction: getComplaintsAction,
      },
      dispatch
    )
)(_EditEmployee);

export default EditEmployee;
