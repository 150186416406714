import { CommercialCycle } from "../models/commercialCycle";
import { Complaint } from "../models/complaint";
import { Contact } from "../models/contact";
import { Opportunity } from "../models/opportunity";
import { Task } from "../models/task";
import { AxiosHttpClient } from "./AxiosHttpService";

export class CrmService {
  static getContacts = (input: any) => {
    return AxiosHttpClient.get<Contact[]>("api/v1/weeventpro/contacts", input);
  };

  static getOpportunities = (input: any) => {
    return AxiosHttpClient.get<Opportunity[]>(
      "api/v1/weeventpro/opportunities",
      input
    );
  };

  static getCommercialCycles = (input: any) => {
    return AxiosHttpClient.get<CommercialCycle[]>(
      "api/v1/weeventpro/commercialCycles",
      input
    );
  };

  static getComplaints = (input: any) => {
    return AxiosHttpClient.get<Complaint[]>(
      "api/v1/weeventpro/complaints",
      input
    );
  };

  static getTasks = (input: any) => {
    return AxiosHttpClient.get<Task[]>("api/v1/weeventpro/tasks", input);
  };
}
