import { Notification } from "../../models/notification";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS" as const;
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS" as const;
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE" as const;

export const getNotificationsAction = (page: number) => ({
  type: GET_NOTIFICATIONS,
  page: page,
});
export type GetNotificationsAction = ReturnType<typeof getNotificationsAction>;

export const getNotificationsActionSuccess = (
  notifications: Notification[]
) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  notifications: notifications,
});
export type GetNotificationsActionSuccess = ReturnType<
  typeof getNotificationsActionSuccess
>;

export const getNotificationsActionFailure = () => ({
  type: GET_NOTIFICATIONS_FAILURE,
});
export type GetNotificationsActionFailure = ReturnType<
  typeof getNotificationsActionFailure
>;
