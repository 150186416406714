import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import SupplierTable from "./SupplierTable";

export interface SupplierProps {}

const Supplier: React.FC<SupplierProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Fournisseurs"
        subTitle="Liste des fournisseurs"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <SupplierTable />
    </BlockContainer>
  );
};

export default Supplier;
