import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from "styled-components";

interface IHeadingStyled {
  $maxHeight?: string;
  height?: string;
  margin?: string;
  BackgroundColorHover?: string;
}

export const AccordionMui = styled(Accordion)`
  background: rgb(0, 0, 0, 0);
  box-shadow: none;
  text-decoration: none;
  width: 100%;
`;

export const AccordionSummaryMui = styled(AccordionSummary)<IHeadingStyled>`
  &:hover {
    background: ${(props) =>
      props.BackgroundColorHover
        ? props.BackgroundColorHover
        : "rgb(255,0,0,0.1)"};
  }
  &&& {
    height: ${(props) => (props.height ? props.height : "44px")};
    min-height: 44px;
    max-height: ${(props) => (props.$maxHeight ? props.$maxHeight : "44px")};
  }
`;

export const AccordionDetailsMui = styled(AccordionDetails)`
  padding: 0 0 0 0;
  display: block;
`;
