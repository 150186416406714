import { AxiosHttpClient } from "./AxiosHttpService";

export class NotificationService {
  static getNotifications = (page: number) => {
    return AxiosHttpClient.get<any>("api/v1/weeventpro/notifications/page", {
      criteriaList: "",
      pageNumber: page - 1,
      pageSize: 20,
      sort: "creationDateTime",
      order: "desc",
    });
  };
}
