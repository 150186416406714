import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { View } from "../../../../../../models/view";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { CustomTheme } from "../../../../../../styles/Theme";
import { SUPPLIER_TABLE_VIEW } from "../../../../../Reusable/Utils";

export interface ViewInputProps {
  inputView: string;
  views: View[];
  displayedTool: string | null;
  setInputView: React.Dispatch<React.SetStateAction<string>>;
}

const ViewInput: React.FC<ViewInputProps> = (props) => {
  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const setViewParameter = (viewValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: SUPPLIER_TABLE_VIEW,
        parameterValue: viewValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
  };

  return (
    <FormControl
      size={matches ? "medium" : "small"}
      sx={{
        margin: "8px 16px 8px 0",
        flex: "1",
        maxWidth: { sm: "20%" },
        width: { xs: "100%" },
        display: {
          xs: props.displayedTool === "view" ? "inline-flex" : "none",
          sm: "inline-flex",
        },
      }}
    >
      <InputLabel id="demo-simple-select-label">Vue</InputLabel>
      <Select
        required
        fullWidth
        value={props.inputView}
        label="Vue"
        onChange={(e) => {
          setViewParameter(
            typeof e.target.value === "string" ? e.target.value : ""
          );
          props.setInputView(
            typeof e.target.value === "string" ? e.target.value : ""
          );
        }}
      >
        <MenuItem value="defaultView">Vue par défaut</MenuItem>
        {props.views.map((view, index) => (
          <MenuItem value={view.id} key={index}>
            {view.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ViewInput;
