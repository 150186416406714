import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { Opportunity } from "../../../../../../models/opportunity";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import {
  getContactDescription,
  getEmployeeDescription,
  toFixed2,
} from "../../../../../Reusable/Utils";

export interface InformationsProps {
  opportunity: Opportunity;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "850px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const Informations: React.FC<InformationsProps> = ({ opportunity }) => {
  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  const classes = useStyles();

  return (
    <FlexContainer padding="16px">
      <BlockContainer width="100%">
        <Text16 fontWeight="500">Informations générales</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">N° de l'opportunité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{opportunity.opportunityNumber}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Titre</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.title && opportunity.title !== ""
                ? opportunity.title
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de début</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.startDate
                ? dayjs(opportunity.startDate).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de fin</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.endDate
                ? dayjs(opportunity.endDate).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        {/* Client/Prospect */}
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Client/Prospect</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{getContactDescription(opportunity.contact)}</Text14>
          </FlexContainer>
        </FlexContainer>

        {/* Responsables */}
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Responsables</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.responsibles
                ? opportunity.responsibles
                    .map((responsible) => getEmployeeDescription(responsible))
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Tags</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.tags && opportunity.tags.length > 0
                ? opportunity.tags.join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Source</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.source && opportunity.source !== ""
                ? opportunity.source
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Montant HT</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{toFixed2(opportunity.amountHT) + " €"}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Probabilité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{opportunity.probability + "%"}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Priorité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.priority && opportunity.priority !== ""
                ? opportunity.priority
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Description</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.description && opportunity.description !== ""
                ? opportunity.description
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Progression de l'opportunité</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Cycle Commercial</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.commercialCycle
                ? opportunity.commercialCycle.name
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Étape</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.commercialCycleStep
                ? opportunity.commercialCycleStep.name
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Statut</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.status && opportunity.status !== ""
                ? opportunity.status
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Raison Perte</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {opportunity.lossReason && opportunity.lossReason !== ""
                ? opportunity.lossReason
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Produits/Services associés </Text16>

        <FlexContainer marginTop={"32px"}>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell key="image">Image</TableCell>
                      <TableCell key="product">Produit</TableCell>
                      <TableCell key="code">Code</TableCell>
                      <TableCell key="quantity">Quantité</TableCell>
                      <TableCell key="pu">P.U</TableCell>
                      <TableCell key="pht">Prix H.T</TableCell>
                      <TableCell key="tva">TVA</TableCell>
                      <TableCell key="pttc">Prix TTC</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {opportunity!.soldItems &&
                      opportunity!.soldItems.length === 0 && (
                        <TableRow key={"Empty"}>
                          <TableCell
                            colSpan={8}
                            sx={{ height: "100px", borderBottom: "none" }}
                          >
                            <Text14 color="gray" textAlign="center">
                              Aucun produit/service retrouvée
                            </Text14>
                          </TableCell>
                        </TableRow>
                      )}
                    {opportunity!.soldItems.map((soldItem, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell>
                            <BlockContainer
                              sx={{
                                width: {
                                  xs: "60px",
                                  sm: "60px",
                                },
                                height: {
                                  xs: "60px",
                                  sm: "60px",
                                },
                              }}
                              border="rgba(0,0,0,0.1) solid 1px"
                              justifyContent="center"
                              position="relative"
                              textAlign="center"
                              backgroundColor="white"
                              margin="auto"
                            >
                              <img
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                }}
                                alt=""
                                src={soldItem.imageUrl}
                              />
                            </BlockContainer>
                          </TableCell>
                          <TableCell>{soldItem.name}</TableCell>
                          <TableCell>{soldItem.internalCode}</TableCell>
                          <TableCell>{soldItem.quantity}</TableCell>
                          <TableCell>{soldItem.priceHT + " €"}</TableCell>
                          <TableCell>
                            {toFixed2(
                              Number(soldItem.quantity) *
                                Number(soldItem.priceHT)
                            ) + " €"}
                          </TableCell>
                          <TableCell>{soldItem.tva}</TableCell>
                          <TableCell>
                            {toFixed2(
                              (1 + Number(soldItem.tva) * 0.01) *
                                Number(soldItem.quantity) *
                                Number(soldItem.priceHT)
                            ) + " €"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </FlexContainer>
      </BlockContainer>
    </FlexContainer>
  );
};

export default Informations;
