import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import EmployeeTable from "./EmployeeTable";

export interface EmployeeProps {}

const Employee: React.FC<EmployeeProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Employés"
        subTitle="Liste des employés"
        icon={<BadgeTwoToneIcon fontSize="large" color="primary" />}
      />
      <EmployeeTable />
    </BlockContainer>
  );
};

export default Employee;
