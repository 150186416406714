import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { Supplier } from "../../../../../models/suppliers";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees, getSuppliers } from "../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ThemeCustom } from "../../../../../styles/Utils";
import MapFields from "./MapFields";
import UploadFile from "./UploadFile";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const mockCategories = [
  "Légumes",
  "Fruits",
  "Produits laitiers",
  "Condiments",
  "Pâtes",
  "Autre",
];

export interface ImportSuppliersProps {
  inputSearch: string;
  popupActive: boolean;
  suppliers: Supplier[];
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getSuppliersAction: typeof getSuppliersAction;
  getEmployeesAction: typeof getEmployeesAction;
}

const _ImportSuppliers: React.FC<ImportSuppliersProps> = (props) => {
  const addSuppliers = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/suppliers/multiple",
      constructedSuppliers
    )
      .then(() => {
        props.getSuppliersAction(props.inputSearch);
        props.setPopupActive(false);
      })
      .catch((err) => {});
  };

  let emailIsValid = (email: string) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const getConstructedSuppliers = () => {
    let errors: string[] = [];
    let suppliers: Supplier[] = [];

    for (let i = 1; i < fetchedValues.length; i++) {
      let customFieldValues: CustomFieldValue[] = [];

      for (var customField of customFields) {
        if (getValue(customField.name, i) !== "") {
          customFieldValues.push({
            id: uuid(),
            customFieldId: customField.id,
            values: getValue(customField.name, i).split(","),
          });
        }
      }
      if (
        props.suppliers
          .map((supplier) => supplier.name)
          .includes(getValue("Nom", i))
      )
        errors.push("Le fournisseur " + getValue("Nom", i) + " existe déjà");

      getValue("Jours de livraison", i)
        .split(",")
        .map((dayOfTheWeek) => {
          if (
            ![
              "Lundi",
              "Mardi",
              "Mercredi",
              "Jeudi",
              "Vendredi",
              "Samedi",
              "Dimanche",
            ].includes(dayOfTheWeek.replace(/^[ \t]+|[ \t]+$/g, "")) &&
            dayOfTheWeek.replace(/^[ \t]+|[ \t]+$/g, "") !== ""
          )
            errors.push(
              dayOfTheWeek.replace(/^[ \t]+|[ \t]+$/g, "") +
                " n'est pas un jour de semaine (Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi, Dimanche)"
            );
          return null;
        });

      if (
        getValue("Catégorie", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, "") !== "" &&
        !mockCategories.includes(
          getValue("Catégorie", i)
            .replace(/\d+\.?\d*/g, "")
            .replace(/^[ \t]+|[ \t]+$/g, "")
        )
      )
        errors.push(
          "La catégorie " +
            getValue("Catégorie", i)
              .replace(/\d+\.?\d*/g, "")
              .replace(/^[ \t]+|[ \t]+$/g, "") +
            " n'est pas reconnue."
        );

      if (
        !emailIsValid(
          getValue("Email", i)
            .replace(",", ".")
            .replace(/^[ \t]+|[ \t]+$/g, "")
        )
      )
        errors.push(
          "Le format de l'email est incorrect pour: " +
            getValue("Email", i).replace(",", ".")
        );

      getValue("Employés en charge", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(",")
        .forEach((employeeFullName) => {
          if (
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
            errors.push("Employé inconnu: " + employeeFullName);
        });

      getValue("Superviseurs", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(",")
        .forEach((employeeFullName) => {
          if (
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
            errors.push("Employé inconnu: " + employeeFullName);
        });

      suppliers.push({
        id: uuid(),
        imageUrl:
          "https://" +
          process.env.REACT_APP_BUCKET_NAME! +
          ".s3.eu-west-3.amazonaws.com/DefaultImages/default-supplier.png",
        name: getValue("Nom", i),
        supplierNumber: getValue("N° fournisseur", i),
        category: getValue("Catégorie", i),
        email: getValue("Email", i),
        phoneNumber: getValue("N° de téléphone", i),
        minimumOrder: Number(
          getValue("Commande minimum", i)
            .replace(",", ".")
            .replace(/[^\d.-]/g, "")
            .replace(/^[ \t]+|[ \t]+$/g, "")
        ),
        inCharge: getValue("Employés en charge", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(",")
          .filter((employeeFullName) =>
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
          .map(
            (employeeFullName) =>
              props.employees.find(
                (employee) =>
                  employee.firstName + " " + employee.lastName ===
                    employeeFullName ||
                  employee.lastName + " " + employee.firstName ===
                    employeeFullName
              )!
          ),
        responsibles: getValue("Superviseurs", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(",")
          .filter((employeeFullName) =>
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
          .map(
            (employeeFullName) =>
              props.employees.find(
                (employee) =>
                  employee.firstName + " " + employee.lastName ===
                    employeeFullName ||
                  employee.lastName + " " + employee.firstName ===
                    employeeFullName
              )!
          ),
        leadTime: Number(
          getValue("Lead time", i)
            .replace(",", ".")
            .replace(/[^\d.-]/g, "")
            .replace(/^[ \t]+|[ \t]+$/g, "")
        ),
        openWeekDays: getValue("Jours de livraison", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(","),
        customFields: customFieldValues,
      });
    }
    setOutputErrors(errors);
    setConstructedSuppliers(suppliers);
  };

  const readImportFile = () => {
    const data = new FormData();
    data.append("importFile", inputTemplate);

    AxiosHttpClient.put<string[][]>(
      "api/v1/weeventpro/suppliers/readImportFile",
      data
    )
      .then((res) => {
        setFetchedValues(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/supplier"
    ).then((response) => {
      setCustomFields(response);
    });
  };

  const getValue = (fieldName: string, i: number) => {
    let headers = fetchedValues[0];
    let indexOfField = headers.indexOf(fieldName);
    return indexOfField === -1 ? "" : fetchedValues[i][indexOfField];
  };

  const validateFirstStep = () => {
    if (inputTemplate === null) {
      setFailMessage("Veuillez sélectionner un fichier!");
      return false;
    }
    return true;
  };

  const validateSecondStep = () => {
    if (outputErrors.length > 0) {
      setFailMessage(
        "Fichier non valide. Veuillez corriger les erreurs spécifiées ci-dessous et recommencer!"
      );
      return false;
    }
    return true;
  };

  const [constructedSuppliers, setConstructedSuppliers] = useState<Supplier[]>(
    []
  );

  const [inputTemplate, setInputTemplate] = useState<any>(null);

  const [fetchedValues, setFetchedValues] = useState<string[][]>([[]]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [step, setStep] = useState(0);

  const [importDataFromDB, setImportDataFromDB] = useState(true);

  const [outputErrors, setOutputErrors] = useState<string[]>([]);

  const [failMessage, setFailMessage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    getCustomFields();
    getEmployeesAction("");
  }, []);

  useEffect(() => {
    getConstructedSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedValues]);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Importer des ingrédients"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui dividers>
        {failMessage !== "" && (
          <BlockContainer margin="4px">
            <Alert
              severity="error"
              onClose={() => {
                setFailMessage("");
              }}
            >
              {failMessage}
            </Alert>
          </BlockContainer>
        )}
        {step === 0 && (
          <UploadFile
            inputTemplate={inputTemplate}
            importDataFromDB={importDataFromDB}
            setInputTemplate={setInputTemplate}
            setImportDataFromDB={setImportDataFromDB}
          />
        )}
        {step === 1 && (
          <MapFields
            suppliers={constructedSuppliers}
            fetchedValues={fetchedValues}
            outputErrors={outputErrors}
            popupActive={props.popupActive}
            setPopupActive={props.setPopupActive}
          />
        )}
      </DialogContentMui>
      <DialogActions>
        {step === 1 && (
          <ButtonMui
            onClick={() => {
              setStep(step - 1);
              setFailMessage("");
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Précédent
          </ButtonMui>
        )}
        {step === 1 ? (
          <ButtonMui
            onClick={() => {
              if (validateSecondStep()) addSuppliers();
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Importer
          </ButtonMui>
        ) : (
          <ButtonMui
            onClick={() => {
              if (validateFirstStep()) {
                setFailMessage("");
                readImportFile();
                setStep(step + 1);
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Suivant
          </ButtonMui>
        )}
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const ImportSuppliers = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_ImportSuppliers);

export default ImportSuppliers;
