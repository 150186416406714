import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Alert, Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { AxiosHttpClient } from "../../../services/AxiosHttpService";
import { BlockContainer } from "../../../styles/BlockContainer";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text14, Text24, Text36 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";
import Footer from "../Footer";
import Header from "../Header";

export interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  const sendMail = () => {
    AxiosHttpClient.postForNonAuth("api/v1/weeventpro/general/email", {
      email: email,
      message: message,
    })
      .then(() => {
        setEmail("");
        setMessage("");
        setIsLoading(false);
        setEmailError(false);
        setMessageError(false);
        setSendFailed("");
        setSendSucceeded(true);
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const validateForm = () => {
    setEmailError(false);
    setMessageError(false);
    let result = true;
    if (email === "") {
      setEmailError(true);
      result = false;
    }
    if (message === "") {
      setMessageError(true);
      result = false;
    }
    if (!result) {
      setSendFailed("Champs manquants");
    }
    return result;
  };

  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");

  const [sendFailed, setSendFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const [messageError, setMessageError] = useState(false);

  const [sendSucceeded, setSendSucceeded] = useState(false);

  return (
    <BlockContainer position="relative" overflow="hidden">
      <Header />
      <FlexContainer width={"100%"} justifyContent="center"></FlexContainer>
      <FlexContainer sx={{ padding: { xs: "32px", sm: "80px 128px" } }}>
        <Grid container spacing={4}>
          {/* Bloc gauche - Coordonnées de l'entreprise */}
          <Grid item xs={12} md={6}>
            <BlockContainer sx={{ padding: "0 16px" }}>
              <Box
                component="img"
                src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/contact-image.png`}
                alt="Logiciel ERP"
                sx={{
                  width: "60%",
                  borderRadius: "16px",
                  mb: "8px",
                }}
              />
              <FlexContainer marginBottom="16px">
                <Text36 fontWeight="600" margin="4px 10px">
                  Contactez-nous
                </Text36>
              </FlexContainer>
              <FlexContainer flexDirection="column">
                <FlexContainer alignItems="center">
                  <PhoneIcon color="secondary" />
                  <Text14 margin="0 10px">+33 6 81 11 96 90</Text14>
                </FlexContainer>
                <FlexContainer alignItems="center">
                  <EmailIcon color="secondary" />
                  <Text14 margin="0 10px">contact@weevent.com</Text14>
                </FlexContainer>
              </FlexContainer>
            </BlockContainer>
          </Grid>

          {/* Bloc droit - Formulaire de contact */}
          <Grid item xs={12} md={6}>
            <FlexContainer
              elevation={3}
              flexDirection="column"
              backgroundColor="white"
              alignItems={"center"}
              sx={{
                padding: "32px",
                borderRadius: "12px",
                textAlign: "center",
              }}
            >
              <img
                src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/Logo.svg`}
                alt="Mon SVG"
                width={92.8}
                height={62.4}
                style={{ marginBottom: "8px" }}
              />
              <FlexContainer marginBottom="16px" justifyContent={"center"}>
                <Text24 fontWeight="600">Nous contacter</Text24>
              </FlexContainer>
              {sendSucceeded && (
                <BlockContainer margin="8px">
                  <Alert
                    severity="success"
                    onClose={() => {
                      setSendSucceeded(false);
                    }}
                  >
                    Votre message a bien été envoyé
                  </Alert>
                </BlockContainer>
              )}
              {sendFailed !== "" && (
                <BlockContainer margin="8px">
                  <Alert
                    severity="error"
                    onClose={() => {
                      setSendFailed("");
                    }}
                  >
                    {sendFailed}
                  </Alert>
                </BlockContainer>
              )}

              {/* Champs du formulaire */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Votre email"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "6px",
                  marginBottom: "16px",
                }}
                error={emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Votre message"
                multiline
                minRows={3}
                maxRows={7}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "6px",
                  marginBottom: "16px",
                }}
                error={messageError}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              {/* Bouton Envoyer */}
              <ButtonMui
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: CustomTheme.palette.warning.main,
                  color: "white",
                  padding: "12px",
                  borderRadius: "6px",
                  "&:hover": {
                    backgroundColor: CustomTheme.palette.warning.dark,
                  },
                }}
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  if (validateForm()) sendMail();
                  else setIsLoading(false);
                }}
              >
                Envoyer
              </ButtonMui>

              {/* Texte aligné à droite */}
              <FlexContainer justifyContent="flex-end" marginTop="8px">
                <Text14 color="rgba(255,255,255,0.7)">
                  ou{" "}
                  <span style={{ fontWeight: "600" }}>
                    Essayer gratuitement
                  </span>
                </Text14>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
      <Footer />
    </BlockContainer>
  );
};

export default Contact;
