import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Opportunity } from "../../../../../../models/opportunity";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../styles/ButtonMui";

export interface DeleteCommentProps {
  opportunity: Opportunity | null;
  popupActive: string[];
  setPopupActive: React.Dispatch<React.SetStateAction<string[]>>;
  actionsOnDelete?: (() => void)[];
}

const DeleteComment: React.FC<DeleteCommentProps> = (props) => {
  const deleteOpportunityComment = () => {
    if (props.opportunity) {
      AxiosHttpClient.put("api/v1/weeventpro/opportunities", {
        id: props.opportunity.id,
        title: props.opportunity.title,
        opportunityNumber: props.opportunity.opportunityNumber,
        startDate: props.opportunity.startDate
          ? dayjs(props.opportunity.startDate)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : null,
        endDate: props.opportunity.endDate
          ? dayjs(props.opportunity.endDate)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : null,
        contactId: props.opportunity.contact?.id,
        responsiblesIds: props.opportunity.responsibles.map(
          (responsible) => responsible.id
        ),
        tags: props.opportunity.tags,
        source: props.opportunity.source,
        amountHT: props.opportunity.amountHT,
        amountFrequency: props.opportunity.amountFrequency,
        probability: props.opportunity.probability,
        priority: props.opportunity.priority,
        description: props.opportunity.description,
        soldItems: props.opportunity.soldItems,
        commercialCycleId: props.opportunity.commercialCycle?.id,
        commercialCycleStepId: props.opportunity.commercialCycleStep?.id,
        status: props.opportunity.status,
        lossReason: props.opportunity.lossReason,
        comments: [
          ...props.opportunity.comments
            .filter((comment) => !props.popupActive.includes(comment.id))
            .map((comment) => {
              return {
                id: comment.id,
                content: comment.content,
                commentWriterId: comment.commentWriter?.id,
                files: comment.files,
                creationDateTime: comment.creationDateTime,
              };
            }),
        ],
        customFields: props.opportunity.customFields,
      })
        .then(() => {
          props.setPopupActive([]);
          props.actionsOnDelete?.forEach((action) => action());
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={props.popupActive.length !== 0}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive([]);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer un commentaire?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          {props.popupActive.length === 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer ce commentaire
            </React.Fragment>
          )}
          {props.popupActive.length > 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer les commentaires sélectionnés?
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            deleteOpportunityComment();
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive([])}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteComment;
