import { Quote } from "../models/quote";
import { Template } from "../models/template";
import { AxiosHttpClient } from "./AxiosHttpService";

export class QuoteService {
  static getQuotes = (input: any) => {
    return AxiosHttpClient.get<Quote[]>("api/v1/weeventpro/quotes", input);
  };

  static getQuoteTemplates = () => {
    return AxiosHttpClient.get<Template[]>("api/v1/weeventpro/quote/template");
  };
}
