import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import SubjectTwoToneIcon from "@mui/icons-material/SubjectTwoTone";
import VideoCameraFrontTwoToneIcon from "@mui/icons-material/VideoCameraFrontTwoTone";
import { Box, IconButton, Paper, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { bindActionCreators } from "redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Comment } from "../../../../../models/comment";
import { Interaction } from "../../../../../models/interaction";
import { Task } from "../../../../../models/task";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getTasksAction } from "../../../../../store/Crm/actions";
import { getInteractionsAction } from "../../../../../store/Interaction/actions";
import { getInteractions, getTasks } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import DeleteInteraction from "../../Interactions/DeleteInteraction";
import EditInteraction from "../../Interactions/EditInteraction";
import InteractionTable from "../../Interactions/InteractionTable";
import DeleteTask from "../DeleteTask";
import EditTask from "../EditTask";
import DeleteComment from "./DeleteComment";
import EditComment from "./EditComment";
import Informations from "./Informations";
import Timeline from "./Timeline";
import "./style.css";

export interface TaskDetailsProps {}

const _TaskDetails: React.FC<TaskDetailsProps> = () => {
  const [task, setTask] = useState<Task | null>(null);

  const [nextActions, setNextActions] = useState<Interaction[]>([]);

  const [allActions, setAllActions] = useState<(Interaction | Comment)[]>([]);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Task | null>(null);

  const [popupDeleteInteractionActive, setPopupDeleteInteractionActive] =
    useState<string[]>([]);

  const [popupEditInteractionActive, setPopupEditInteractionActive] =
    useState<Interaction | null>(null);

  const [popupDeleteTaskActive, setPopupDeleteTaskActive] = useState<string[]>(
    []
  );

  const [popupEditTaskActive, setPopupEditTaskActive] = useState<Task | null>(
    null
  );

  const [popupDeleteCommentActive, setPopupDeleteCommentActive] = useState<
    string[]
  >([]);

  const [popupEditCommentActive, setPopupEditCommentActive] =
    useState<Comment | null>(null);

  const navigate = useNavigate();

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getTaskDetails = () => {
    AxiosHttpClient.get<Task>("api/v1/weeventpro/tasks/" + params.taskId).then(
      (response) => {
        setTask(response);
      }
    );
  };

  const fetchInteractionById = (action: Interaction) => {
    AxiosHttpClient.get<Interaction>(
      "api/v1/weeventpro/interactions/" + action.id
    )
      .then((response) => {
        setPopupEditInteractionActive(response);
      })
      .catch((error) => {});
  };

  const getActionComponents = (action: Interaction) => {
    let icon: React.JSX.Element | null = <SubjectTwoToneIcon />;
    let actionType: string = "";
    if (action.type === "Appel téléphonique") {
      icon = <LocalPhoneTwoToneIcon color="primary" />;
      actionType = "Appel téléphonique -";
    } else if (action.type === "SMS") {
      icon = <SmsTwoToneIcon color="primary" />;
      actionType = "SMS -";
    } else if (action.type === "E-mail") {
      icon = <EmailTwoToneIcon color="primary" />;
      actionType = "E-mail -";
    } else if (action.type === "Réunion en personne") {
      icon = <GroupsTwoToneIcon color="primary" />;
      actionType = "Réunion en personne -";
    } else if (action.type === "Réunion virtuelle") {
      icon = <VideoCameraFrontTwoToneIcon color="primary" />;
      actionType = "Réunion virtuelle -";
    } else {
      icon = <LiveHelpTwoToneIcon color="primary" />;
      actionType = "Interaction -";
    }

    return (
      <FlexContainer margin={"16px 0 0 0"}>
        <FlexContainer
          width={"40px"}
          height={"40px"}
          backgroundColor={CustomTheme.palette.background.default}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {icon}
        </FlexContainer>
        <FlexContainer flexDirection={"column"}>
          <Text16 margin="0 16px" fontWeight="600" lineHeight="20px">
            {action.title}
          </Text16>
          <FlexContainer>
            <Text14
              color="rgba(0,0,0,0.5)"
              margin="0 0 0 16px"
              lineHeight="20px"
            >
              {actionType}
            </Text14>
            <Text14
              color={CustomTheme.palette.secondary.main}
              margin="0 16px 0 4px"
              lineHeight="20px"
              textDecorationLine="underline"
              cursor="pointer"
              onClick={(e) => {
                navigate(`/feature/interactions/${action.id}`);
              }}
            >
              {action.interactionNumber}
            </Text14>
          </FlexContainer>
          <Text14
            color="rgba(0,0,0,0.7)"
            margin="0 16px"
            lineHeight="20px"
            fontWeight="600"
          >
            {action.startDateTime
              ? dayjs(action.startDateTime).format("DD/MM/YYYY [à] HH:mm")
              : ""}
          </Text14>
        </FlexContainer>
        <FlexContainer
          justifyContent="center"
          marginLeft={"auto"}
          padding={"0 8px 0 0"}
        >
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fetchInteractionById(action);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupDeleteInteractionActive([action!.id]);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>
      </FlexContainer>
    );
  };

  const params = useParams();

  useEffect(() => {
    if (!popupEditActive && popupDeleteActive.length === 0) getTaskDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupEditActive, popupDeleteActive, params]);

  useEffect(() => {
    if (task) {
      const today = dayjs().startOf("day");
      const combinedListAsc: Interaction[] = [...task.associatedInteractions];
      const combinedListDesc: (Interaction | Comment)[] = [
        ...task.associatedInteractions,
        ...task.comments,
      ];

      const sortedListAsc = combinedListAsc.sort((a, b) => {
        return a.startDateTime && b.startDateTime
          ? dayjs(a.startDateTime).isBefore(dayjs(b.startDateTime))
            ? -1
            : 1
          : 0;
      });

      const sortedListDesc = combinedListDesc.sort((a, b) => {
        const aDateTime =
          "startDateTime" in a
            ? a.startDateTime
            : "creationDateTime" in a
            ? a.creationDateTime
            : null;
        const bDateTime =
          "startDateTime" in b
            ? b.startDateTime
            : "creationDateTime" in b
            ? b.creationDateTime
            : null;

        return aDateTime && bDateTime
          ? dayjs(aDateTime).isBefore(dayjs(bDateTime))
            ? 1
            : -1
          : 0;
      });

      const filteredList = sortedListAsc.filter((action) => {
        return (
          (action.startDateTime &&
            dayjs(action.startDateTime).isSame(today, "day")) ||
          dayjs(action.startDateTime).isAfter(today)
        );
      });

      setNextActions(filteredList);
      setAllActions(sortedListDesc);
    }
  }, [task]);

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/tasks">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>Tâches</Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer flexDirection="column">
              <Text20>{task?.title}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {task?.taskNumber}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(task);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  if (task) setPopupDeleteActive([task.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
            width: "33%",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <FlexContainer flexDirection="column" flex="1">
              <FlexContainer>
                <Text20>Activités à venir</Text20>
              </FlexContainer>
              {nextActions.length === 0 && (
                <FlexContainer
                  maxHeight={"180px"}
                  flexDirection={"column"}
                  overflowY={"auto"}
                  flex={"1"}
                  justifyContent={"center"}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune activité retrouvée
                  </Text14>
                </FlexContainer>
              )}
              <FlexContainer
                maxHeight={"180px"}
                flexDirection={"column"}
                overflowY={"auto"}
              >
                {nextActions.map((action) => getActionComponents(action))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && task && (
        <TabsDesktop
          tabs={["Infos", "Intéractions", "Fil d'actualités"]}
          components={[
            <Informations task={task!} />,
            <InteractionTable
              additionalCriterias={"associatedTasks_id=" + task.id}
              actionsOnAdd={[getTaskDetails]}
              actionsOnEdit={[getTaskDetails]}
              actionsOnGenerate={[getTaskDetails]}
            />,
            <Timeline
              task={task}
              allActions={allActions}
              setPopupEditCommentActive={setPopupEditCommentActive}
              setPopupEditInteractionActive={setPopupEditInteractionActive}
              setPopupDeleteCommentActive={setPopupDeleteCommentActive}
              setPopupDeleteInteractionActive={setPopupDeleteInteractionActive}
              actionsOnAdd={[getTaskDetails]}
            />,
          ]}
        />
      )}
      {!matches && task && (
        <TabsMobile
          tabs={["Infos", "Intéractions", "Fil d'actualités"]}
          components={[
            <Informations task={task!} />,
            <InteractionTable
              additionalCriterias={"associatedTasks_id=" + task.id}
              actionsOnAdd={[getTaskDetails]}
              actionsOnEdit={[getTaskDetails]}
              actionsOnGenerate={[getTaskDetails]}
            />,
            <Timeline
              task={task}
              allActions={allActions}
              setPopupEditCommentActive={setPopupEditCommentActive}
              setPopupEditInteractionActive={setPopupEditInteractionActive}
              setPopupDeleteCommentActive={setPopupDeleteCommentActive}
              setPopupDeleteInteractionActive={setPopupDeleteInteractionActive}
              actionsOnAdd={[getTaskDetails]}
            />,
          ]}
        />
      )}
      <DeleteTask
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
        redirect
      />
      <DeleteInteraction
        popupActive={popupDeleteInteractionActive}
        setPopupActive={setPopupDeleteInteractionActive}
        actionsOnDelete={[getTaskDetails]}
      />
      <DeleteTask
        popupActive={popupDeleteTaskActive}
        setPopupActive={setPopupDeleteTaskActive}
        actionsOnDelete={[getTaskDetails]}
      />
      <DeleteComment
        task={task}
        popupActive={popupDeleteCommentActive}
        setPopupActive={setPopupDeleteCommentActive}
        actionsOnDelete={[getTaskDetails]}
      />
      {popupEditActive !== null && (
        <EditTask
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          actionsOnEdit={[getTaskDetails]}
        />
      )}
      {popupEditInteractionActive !== null && (
        <EditInteraction
          popupActive={popupEditInteractionActive}
          setPopupActive={setPopupEditInteractionActive}
          actionsOnEdit={[getTaskDetails]}
        />
      )}
      {popupEditTaskActive !== null && (
        <EditTask
          popupActive={popupEditTaskActive}
          setPopupActive={setPopupEditTaskActive}
          actionsOnEdit={[getTaskDetails]}
        />
      )}
      {popupEditCommentActive !== null && (
        <EditComment
          task={task}
          popupActive={popupEditCommentActive}
          setPopupActive={setPopupEditCommentActive}
          actionsOnEdit={[getTaskDetails]}
        />
      )}
    </BlockContainer>
  );
};

export const TaskDetails = connect(
  (state: RootState) => ({
    tasks: getTasks(state),
    interactions: getInteractions(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getTasksAction: getTasksAction,
        getInteractionsAction: getInteractionsAction,
      },
      dispatch
    )
)(_TaskDetails);

export default _TaskDetails;
