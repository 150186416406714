import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { CartService } from "../../services/CartService";
import { RootAction } from "../types";
import {
  GET_CART_ITEMS,
  GetCartItemsActionFailure,
  getCartItemsActionFailure,
  GetCartItemsActionSuccess,
  getCartItemsActionSuccess,
} from "./actions";

export const cartItemsEpic: Epic<
  RootAction,
  GetCartItemsActionSuccess | GetCartItemsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CART_ITEMS),
    switchMap((action) =>
      from(CartService.getCartItems()).pipe(
        mergeMap((res) => {
          return [getCartItemsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getCartItemsActionFailure()]))
        )
      )
    )
  );

export const cartEpic = combineEpics(cartItemsEpic);
