import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import SubjectTwoToneIcon from "@mui/icons-material/SubjectTwoTone";
import VideoCameraFrontTwoToneIcon from "@mui/icons-material/VideoCameraFrontTwoTone";
import { Box, IconButton, Paper, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { bindActionCreators } from "redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Contact } from "../../../../../models/contact";
import { Interaction } from "../../../../../models/interaction";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getInteractionsAction } from "../../../../../store/Interaction/actions";
import { getInteractions } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { getContactDescription } from "../../../../Reusable/Utils";
import DeleteInteraction from "../../Interactions/DeleteInteraction";
import EditInteraction from "../../Interactions/EditInteraction";
import InteractionTable from "../../Interactions/InteractionTable";
import OpportunityTable from "../../Opportunities/OpportunityTable";
import DeleteProspect from "../DeleteProspect";
import EditProspect from "../EditProspect";
import { Informations } from "./Informations";
import "./style.css";

export interface ProspectDetailsProps {
  nextProspectInteractions: Interaction[];
  getInteractionsAction: typeof getInteractionsAction;
}

const _ProspectDetails: React.FC<ProspectDetailsProps> = ({
  nextProspectInteractions,
  getInteractionsAction,
}) => {
  const [prospect, setProspect] = useState<Contact | null>(null);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Contact | null>(null);

  const [popupDeleteInteractionActive, setPopupDeleteInteractionActive] =
    useState<string[]>([]);

  const [popupEditInteractionActive, setPopupEditInteractionActive] =
    useState<Interaction | null>(null);

  const triggerGetTables = false;

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const navigate = useNavigate();

  const fetchInteractionById = (action: Interaction) => {
    AxiosHttpClient.get<Interaction>(
      "api/v1/weeventpro/interactions/" + action.id
    )
      .then((response) => {
        setPopupEditInteractionActive(response);
      })
      .catch((error) => {});
  };

  const getActionComponents = (action: Interaction) => {
    let icon: React.JSX.Element | null = <SubjectTwoToneIcon />;
    let actionType: string = "";
    if (action.type === "Appel téléphonique") {
      icon = <LocalPhoneTwoToneIcon color="primary" />;
      actionType = "Appel téléphonique -";
    } else if (action.type === "SMS") {
      icon = <SmsTwoToneIcon color="primary" />;
      actionType = "SMS -";
    } else if (action.type === "E-mail") {
      icon = <EmailTwoToneIcon color="primary" />;
      actionType = "E-mail -";
    } else if (action.type === "Réunion en personne") {
      icon = <GroupsTwoToneIcon color="primary" />;
      actionType = "Réunion en personne -";
    } else if (action.type === "Réunion virtuelle") {
      icon = <VideoCameraFrontTwoToneIcon color="primary" />;
      actionType = "Réunion virtuelle -";
    } else {
      icon = <LiveHelpTwoToneIcon color="primary" />;
      actionType = "Interaction -";
    }

    return (
      <FlexContainer margin={"16px 0 0 0"}>
        <FlexContainer
          width={"40px"}
          height={"40px"}
          backgroundColor={CustomTheme.palette.background.default}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {icon}
        </FlexContainer>
        <FlexContainer flexDirection={"column"}>
          <Text16 margin="0 16px" fontWeight="600" lineHeight="20px">
            {action.title}
          </Text16>
          <FlexContainer>
            <Text14
              color="rgba(0,0,0,0.5)"
              margin="0 0 0 16px"
              lineHeight="20px"
            >
              {actionType}
            </Text14>
            <Text14
              color={CustomTheme.palette.secondary.main}
              margin="0 16px 0 4px"
              lineHeight="20px"
              textDecorationLine="underline"
              cursor="pointer"
              onClick={(e) => {
                navigate(`/feature/interactions/${action.id}`);
              }}
            >
              {action.interactionNumber}
            </Text14>
          </FlexContainer>
          <Text14
            color="rgba(0,0,0,0.7)"
            margin="0 16px"
            lineHeight="20px"
            fontWeight="600"
          >
            {action.startDateTime
              ? dayjs(action.startDateTime).format("DD/MM/YYYY [à] HH:mm")
              : ""}
          </Text14>
        </FlexContainer>
        <FlexContainer
          justifyContent="center"
          marginLeft={"auto"}
          padding={"0 8px 0 0"}
        >
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fetchInteractionById(action);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupDeleteInteractionActive([action!.id]);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>
      </FlexContainer>
    );
  };

  const getProspectDetails = () => {
    AxiosHttpClient.get<Contact>(
      "api/v1/weeventpro/contacts/" + params.prospectId
    ).then((response) => {
      setProspect(response);
    });
  };

  useEffect(() => {
    if (!popupEditActive && popupDeleteActive.length === 0)
      getProspectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupEditActive, popupDeleteActive]);

  useEffect(() => {
    if (prospect) {
      getInteractionsAction(
        "contactsIds=" +
          prospect?.id +
          ",startDateTime=" +
          dayjs().format("DD/MM/YYYY") +
          "-undefined"
      );
    }
  }, [getInteractionsAction, prospect]);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/prospects">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>
              Prospects
            </Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={prospect?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{getContactDescription(prospect)}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {prospect?.contactCode}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(prospect);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier le prospect
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  if (prospect) setPopupDeleteActive([prospect.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer le prospect
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
            width: "33%",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <FlexContainer flexDirection="column" flex="1">
              <FlexContainer>
                <Text20>Activités à venir</Text20>
              </FlexContainer>
              {nextProspectInteractions.length === 0 && (
                <FlexContainer
                  maxHeight={"180px"}
                  flexDirection={"column"}
                  overflowY={"auto"}
                  flex={"1"}
                  justifyContent={"center"}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune activité retrouvée
                  </Text14>
                </FlexContainer>
              )}
              <FlexContainer
                maxHeight={"180px"}
                flexDirection={"column"}
                overflowY={"auto"}
              >
                {nextProspectInteractions.map((action) =>
                  getActionComponents(action)
                )}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && prospect && (
        <TabsDesktop
          tabs={["Informations", "Opportunités", "Interactions"]}
          components={[
            <Informations prospect={prospect!} />,
            <OpportunityTable
              additionalCriterias={"contact_id=" + prospect.id}
              actionsOnAdd={[getProspectDetails]}
              actionsOnEdit={[getProspectDetails]}
              actionsOnGenerate={[getProspectDetails]}
              triggerGetTable={triggerGetTables}
            />,
            <InteractionTable
              additionalCriterias={"contactsIds=" + prospect.id}
              actionsOnAdd={[getProspectDetails]}
              actionsOnEdit={[getProspectDetails]}
              actionsOnGenerate={[getProspectDetails]}
              triggerGetTable={triggerGetTables}
            />,
          ]}
        />
      )}
      {!matches && prospect && (
        <TabsMobile
          tabs={["Informations", "Opportunités", "Interactions"]}
          components={[
            <Informations prospect={prospect!} />,
            <OpportunityTable
              additionalCriterias={"contact_id=" + prospect.id}
              actionsOnAdd={[getProspectDetails]}
              actionsOnEdit={[getProspectDetails]}
              actionsOnGenerate={[getProspectDetails]}
              triggerGetTable={triggerGetTables}
            />,
            <InteractionTable
              additionalCriterias={"contactsIds=" + prospect.id}
              actionsOnAdd={[getProspectDetails]}
              actionsOnEdit={[getProspectDetails]}
              actionsOnGenerate={[getProspectDetails]}
              triggerGetTable={triggerGetTables}
            />,
          ]}
        />
      )}
      <DeleteProspect
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
        getProspectsPage={() => {}}
      />
      <DeleteInteraction
        popupActive={popupDeleteInteractionActive}
        setPopupActive={setPopupDeleteInteractionActive}
        actionsOnDelete={[() => getInteractionsAction("")]}
      />
      {popupEditInteractionActive !== null && (
        <EditInteraction
          popupActive={popupEditInteractionActive}
          setPopupActive={setPopupEditInteractionActive}
          actionsOnEdit={[() => getInteractionsAction("")]}
        />
      )}
      {popupEditActive !== null && (
        <EditProspect
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getProspectsPage={() => {}}
        />
      )}
    </BlockContainer>
  );
};

export const ProspectDetails = connect(
  (state: RootState) => ({
    nextProspectInteractions: getInteractions(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getInteractionsAction: getInteractionsAction,
      },
      dispatch
    )
)(_ProspectDetails);

export default _ProspectDetails;
