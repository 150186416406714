import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CustomProvider } from "rsuite";
import frFR from "rsuite/locales/fr_FR";
import { CustomTheme } from "../../styles/Theme";
import { Feature } from "../Feature";
import Catalog from "../Feature/ContentSection/Catalog";
import Clients from "../Feature/ContentSection/Clients";
import { ClientDetails } from "../Feature/ContentSection/Clients/ClientDetails";
import CommercialCycles from "../Feature/ContentSection/CommercialCycles";
import Complaint from "../Feature/ContentSection/Complaints";
import { ComplaintDetails } from "../Feature/ContentSection/Complaints/ComplaintDetails";
import CustomerOrderArchive from "../Feature/ContentSection/CustomerOrderArchive";
import Dashboard from "../Feature/ContentSection/Dashboard";
import Documents from "../Feature/ContentSection/Document";
import Employees from "../Feature/ContentSection/Employees";
import ActivateEmployee from "../Feature/ContentSection/Employees/ActivateEmployee";
import EmployeeDetails from "../Feature/ContentSection/Employees/EmployeeDetails";
import SetForgotPassword from "../Feature/ContentSection/Employees/SetForgotPassword";
import { SetPasswordSuccess } from "../Feature/ContentSection/Employees/SetPasswordSuccess";
import Expenses from "../Feature/ContentSection/Expenses";
import FeatureHome from "../Feature/ContentSection/FeatureHome";
import Gains from "../Feature/ContentSection/Gains";
import Ingredient from "../Feature/ContentSection/Ingredients";
import { IngredientDetails } from "../Feature/ContentSection/Ingredients/IngredientDetails";
import Interaction from "../Feature/ContentSection/Interactions";
import Invoice from "../Feature/ContentSection/Invoice";
import Material from "../Feature/ContentSection/Materials";
import { MaterialDetails } from "../Feature/ContentSection/Materials/MaterialDetails";
import Opportunities from "../Feature/ContentSection/Opportunities";
import { OpportunityDetails } from "../Feature/ContentSection/Opportunities/OpportunityDetails";
import Parameters from "../Feature/ContentSection/Parameters";
import Profile from "../Feature/ContentSection/Profile";
import Prospects from "../Feature/ContentSection/Prospects";
import { ProspectDetails } from "../Feature/ContentSection/Prospects/ProspectDetails";
import Quote from "../Feature/ContentSection/Quote";
import RouteModule from "../Feature/ContentSection/Routes";
import Sale from "../Feature/ContentSection/Sales";
import Service from "../Feature/ContentSection/Service";
import Metrics from "../Feature/ContentSection/StatsMetrics";
import StorageAreas from "../Feature/ContentSection/StorageAreas";
import SupplierOrder from "../Feature/ContentSection/SupplierOrder";
import SupplierStock from "../Feature/ContentSection/Suppliers";
import { SupplierDetails } from "../Feature/ContentSection/Suppliers/SupplierDetails";
import Task from "../Feature/ContentSection/Tasks";
import { TaskDetails } from "../Feature/ContentSection/Tasks/TaskDetails";
import Vacations from "../Feature/ContentSection/Vacation";
import WebPage from "../Feature/ContentSection/WebPage";
import HomePage from "../HomePage";
import Blog from "../HomePage/Blog";
import BlogPost from "../HomePage/BlogPost";
import Contact from "../HomePage/Contact";
import Faq from "../HomePage/Faq";
import Features from "../HomePage/Features";
import ScrollToTop from "../ScrollToTop";
import SignIn from "../SignIn";
import SignUp from "../SignUp";

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider>
        <ThemeProvider theme={CustomTheme}>
          <CustomProvider locale={frFR}>
            <Router>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/features" element={<Features />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/activate/:uuid" element={<ActivateEmployee />} />
                <Route
                  path="/changePassword/:uuid"
                  element={<SetForgotPassword />}
                />
                <Route
                  path="/activate/success"
                  element={<SetPasswordSuccess />}
                />
                <Route path="/feature" element={<Feature />}>
                  <Route path="/feature" element={<FeatureHome />} />
                  <Route path="/feature/ingredients" element={<Ingredient />} />
                  <Route
                    path="/feature/ingredients/:ingredientId"
                    element={<IngredientDetails />}
                  />
                  {<Route path="/feature/materials" element={<Material />} />}
                  <Route
                    path="/feature/storageAreas"
                    element={<StorageAreas />}
                  />
                  {
                    <Route
                      path="/feature/materials/:materialId"
                      element={<MaterialDetails />}
                    />
                  }
                  <Route
                    path="/feature/suppliers"
                    element={<SupplierStock />}
                  />
                  <Route
                    path="/feature/suppliers/:supplierId"
                    element={<SupplierDetails />}
                  />
                  <Route path="/feature/catalog" element={<Catalog />} />
                  <Route path="/feature/services" element={<Service />} />
                  <Route path="/feature/quote" element={<Quote />} />
                  <Route path="/feature/invoices" element={<Invoice />} />
                  <Route path="/feature/sales" element={<Sale />} />
                  <Route path="/feature/delivery" element={<RouteModule />} />
                  <Route
                    path="/feature/supplierOrders"
                    element={<SupplierOrder />}
                  />
                  <Route
                    path="/feature/customerOrdersArchive"
                    element={<CustomerOrderArchive />}
                  />
                  <Route path="/feature/metrics" element={<Metrics />} />
                  <Route path="/feature/dashboard" element={<Dashboard />} />
                  <Route path="/feature/expenses" element={<Expenses />} />
                  <Route path="/feature/gains" element={<Gains />} />
                  <Route path="/feature/clients" element={<Clients />} />
                  <Route
                    path="/feature/clients/:clientId"
                    element={<ClientDetails />}
                  />
                  <Route path="/feature/prospects" element={<Prospects />} />
                  <Route
                    path="/feature/prospects/:prospectId"
                    element={<ProspectDetails />}
                  />
                  <Route
                    path="/feature/opportunities"
                    element={<Opportunities />}
                  />
                  <Route
                    path="/feature/opportunities/:opportunityId"
                    element={<OpportunityDetails />}
                  />
                  <Route
                    path="/feature/commercialCycles"
                    element={<CommercialCycles />}
                  />
                  <Route path="/feature/complaints" element={<Complaint />} />
                  <Route
                    path="/feature/complaints/:complaintId"
                    element={<ComplaintDetails />}
                  />
                  <Route path="/feature/tasks" element={<Task />} />
                  <Route
                    path="/feature/tasks/:taskId"
                    element={<TaskDetails />}
                  />
                  <Route
                    path="/feature/interactions"
                    element={<Interaction />}
                  />
                  <Route path="/feature/webPage" element={<WebPage />} />
                  <Route path="/feature/employees" element={<Employees />} />
                  <Route
                    path="/feature/employees/:employeeId"
                    element={<EmployeeDetails />}
                  />
                  <Route path="/feature/vacations" element={<Vacations />} />
                  <Route path="/feature/documents" element={<Documents />} />
                  <Route path="/feature/parameters" element={<Parameters />} />
                  <Route path="/feature/profile" element={<Profile />} />
                </Route>
              </Routes>
            </Router>
          </CustomProvider>
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
};

export default App;
