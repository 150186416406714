import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CustomField } from "../../../../../../models/customField";
import { View } from "../../../../../../models/view";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import DeleteCustomField from "./DeleteCustomField";
import DeleteView from "./DeleteView";
import EditCustomField from "./EditCustomField";
import EditView from "./EditView";

export interface CustomizeVacationsProps {}

const CustomizeVacations: React.FC<CustomizeVacationsProps> = (props) => {
  const addCustomField = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/customFields/vacation", {
      id: "",
      name: inputName,
      type: inputType,
      values: inputValues,
    })
      .then(() => {
        getCustomFields();
        setCustomFieldAddActive(false);
        resetCustomFieldForm();
        setIsAddCustomFieldLoading(false);
        setAddCustomFieldFailed("");
      })
      .catch((err) => {
        setAddCustomFieldFailed(err.cause);
        setIsAddCustomFieldLoading(false);
      });
  };

  const addView = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/views/vacation", {
      id: "",
      name: inputViewName,
      fields: inputSelectedFields,
    })
      .then(() => {
        getViews();
        setViewAddActive(false);
        resetViewForm();
        setIsAddViewLoading(false);
        setAddViewFailed("");
      })
      .catch((err) => {
        setAddViewFailed(err.cause);
        setIsAddViewLoading(false);
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/vacation"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/vacation")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const deleteCustomField = (customFieldId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/customFields/vacation/" + customFieldId
    )
      .then(() => {
        getCustomFields();
        setPopupDeleteActive(null);
        setIsDeleteCustomFieldLoading(false);
      })
      .catch((err) => {
        setIsDeleteCustomFieldLoading(false);
      });
  };

  const deleteView = (viewId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/views/vacation/" + viewId
    )
      .then(() => {
        getViews();
        setViewPopupDeleteActive(null);
        setIsDeleteViewLoading(false);
      })
      .catch((err) => {
        setIsDeleteViewLoading(false);
      });
  };

  const [inputViewName, setInputViewName] = useState("");

  const [inputViewNameError, setInputViewNameError] = useState(false);

  const [views, setViews] = useState<View[]>([]);

  const [viewPopupDeleteActive, setViewPopupDeleteActive] =
    useState<View | null>(null);

  const [viewPopupEditActive, setViewPopupEditActive] = useState<View | null>(
    null
  );

  const [viewAddActive, setViewAddActive] = useState(false);

  const [inputSelectedFields, setInputSelectedFields] = useState<string[]>([
    "Répartition des congés",
  ]);

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputType, setInputType] = useState("");

  const [inputTypeError, setInputTypeError] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [inputValueError, setInputValueError] = useState(false);

  const [customFieldNameExpanded, setCustomFieldNameExpanded] = useState("");

  const [inputValues, setInputValues] = useState<string[]>([]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldAddActive, setCustomFieldAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] =
    useState<CustomField | null>(null);

  const [popupEditActive, setPopupEditActive] = useState<CustomField | null>(
    null
  );

  const [addCustomFieldFailed, setAddCustomFieldFailed] = useState("");

  const [addViewFailed, setAddViewFailed] = useState("");

  const [isAddCustomFieldLoading, setIsAddCustomFieldLoading] = useState(false);

  const [isAddViewLoading, setIsAddViewLoading] = useState(false);

  const [isDeleteCustomFieldLoading, setIsDeleteCustomFieldLoading] =
    useState(false);

  const [isDeleteViewLoading, setIsDeleteViewLoading] = useState(false);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const resetCustomFieldForm = () => {
    setInputName("");
    setInputType("");
    setInputValue("");
    setInputValues([]);
  };

  const resetViewForm = () => {
    setInputViewName("");
    setInputSelectedFields(["Répartition des congés"]);
  };

  const validateCustomFieldForm = () => {
    setInputNameError(false);
    setInputTypeError(false);
    setInputValueError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputType === "") {
      setInputTypeError(true);
      result = false;
    }
    if (
      (inputType === "Liste déroulante" ||
        inputType === "Boutton radio" ||
        inputType === "Checkbox") &&
      inputValues.length === 0
    ) {
      setInputValueError(true);
      result = false;
    }
    if (!result) {
      setAddCustomFieldFailed("Champs manquants");
    }
    return result;
  };

  const validateViewForm = () => {
    setInputViewNameError(false);
    let result = true;
    if (inputViewName === "") {
      setInputViewNameError(true);
      result = false;
    }
    if (!result) {
      setAddViewFailed("Champs manquants");
    }
    return result;
  };

  const getAllFields = (): string[] => {
    let normalFields = [
      "N° du congé",
      "Employé",
      "Date de début",
      "Date de fin",
      "Durée du congé",
      "Répartition des congés",
      "Superviseur",
      "Copies à",
      "Fichiers de justification",
      "Commentaire",
      "Statut",
    ];
    return [
      ...normalFields,
      ...customFields.map((customField) => "Custom::" + customField.id),
    ];
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: string[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        inputSelectedFields,
        result.source.index,
        result.destination.index
      );

      setInputSelectedFields(items);
    }
  };

  useEffect(() => {
    getCustomFields();
    getViews();
  }, []);

  return (
    <FlexContainer flexDirection="column" width="100%">
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Champs personnalisés
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setCustomFieldAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un champ personnalisé
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={customFieldAddActive}>
        {addCustomFieldFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddCustomFieldFailed("");
              }}
            >
              {addCustomFieldFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="custpomFieldTypeLabel">Type</InputLabel>
                <Select
                  labelId="custpomFieldTypeLabel"
                  id="custpomFieldType"
                  required
                  error={inputTypeError}
                  value={inputType}
                  label="Type"
                  onChange={(e) => setInputType(e.target.value)}
                >
                  <MenuItem value="Texte">Texte</MenuItem>
                  <MenuItem value="Liste déroulante">Liste déroulante</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Boutton radio">Boutton radio</MenuItem>
                  <MenuItem value="Checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {["Liste déroulante", "Boutton radio", "Checkbox"].includes(
              inputType
            ) && (
              <Grid item xs={1} sm={1} md={2} alignItems="center">
                <Text16>
                  Veuillez saisir les valeur possibles pour ce champs!
                </Text16>
                <FlexContainer alignItems="center">
                  <TextField
                    fullWidth
                    name="name"
                    label="Valeur"
                    sx={{ margin: "0 16px 0 0" }}
                    error={inputValueError}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    disabled={inputValue === ""}
                    onClick={() => {
                      setInputValues([...inputValues, inputValue]);
                      setInputValue("");
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </FlexContainer>
                <FlexContainer justifyContent="center" margin="16px 0">
                  <FlexContainer
                    flexDirection="column"
                    backgroundColor={CustomTheme.palette.primary.light}
                    elevation={3}
                    $borderRadius="4px"
                  >
                    {inputValues.map((value, index) => (
                      <FlexContainer justifyContent="space-between" key={index}>
                        <Text14 color={CustomTheme.palette.primary.main}>
                          {value}
                        </Text14>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            let newArr = [...inputValues];
                            newArr.splice(index, 1);
                            setInputValues(newArr);
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            )}
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddCustomFieldLoading}
            loading={isAddCustomFieldLoading}
            onClick={() => {
              setIsAddCustomFieldLoading(true);
              if (validateCustomFieldForm()) addCustomField();
              else setIsAddCustomFieldLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setCustomFieldAddActive(false);
              resetCustomFieldForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 5 }}
      >
        {customFields.map((customField, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              elevation={3}
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                position="absolute"
                top="0"
                right="0"
                padding="4px"
                alignItems="flex-end"
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setPopupEditActive(customField)}
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => setPopupDeleteActive(customField)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </FlexContainer>
              <FlexContainer padding="32px 0 16px 0">
                <Text14>{customField.name}</Text14>
              </FlexContainer>
              <FlexContainer
                padding="8px 0"
                width="100%"
                backgroundColor={CustomTheme.palette.primary.light}
                $borderBottomLeftRadius="10px"
                $borderBottomRightRadius="10px"
                flexDirection="column"
              >
                <FlexContainer
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  $cursorHover={
                    ["Liste déroulante", "Boutton radio", "Checkbox"].includes(
                      customField.type
                    )
                      ? "pointer"
                      : "auto"
                  }
                  onClick={() => {
                    if (customFieldNameExpanded === customField.name)
                      setCustomFieldNameExpanded("");
                    else {
                      if (customField.values.length > 0)
                        setCustomFieldNameExpanded(customField.name);
                    }
                  }}
                >
                  <Text14
                    fontWeight="500"
                    margin="4px"
                    color={CustomTheme.palette.primary.main}
                  >
                    {customField.type}
                  </Text14>
                  {customField.values.length > 0 &&
                    customFieldNameExpanded !== customField.name && (
                      <ExpandMoreIcon color="primary" />
                    )}
                  {customField.values.length > 0 &&
                    customFieldNameExpanded === customField.name && (
                      <ExpandLessIcon color="primary" />
                    )}
                </FlexContainer>
                <Collapse
                  orientation="vertical"
                  in={customFieldNameExpanded === customField.name}
                >
                  {customField.values.map((value, index) => (
                    <FlexContainer key={index}>
                      <Text14 color={CustomTheme.palette.primary.main}>
                        {value}
                      </Text14>
                    </FlexContainer>
                  ))}
                </Collapse>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ mt: "32px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Vues personnalisées
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setViewAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une vue personnalisée
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={viewAddActive}>
        {addViewFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddViewFailed("");
              }}
            >
              {addViewFailed}
            </Alert>
          </BlockContainer>
        )}
        <BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputViewNameError}
                value={inputViewName}
                onChange={(e) => setInputViewName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={getAllFields()}
                disableCloseOnSelect
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={
                        option.includes("Custom::")
                          ? getCustomFieldNameByCustomFieldId(
                              option.substring(8)
                            )
                          : option
                      }
                      disabled={option === "Répartition des congés"}
                      key={index}
                    />
                  ));
                }}
                getOptionLabel={(option) => option}
                value={inputSelectedFields}
                getOptionDisabled={(option) =>
                  !inputSelectedFields.includes(option) &&
                  inputSelectedFields.length >= 7
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputSelectedFields.includes(option)}
                    />
                    {option.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(option.substring(8))
                      : option}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputSelectedFields([
                    "Répartition des congés",
                    ...values.filter(
                      (option) => option !== "Répartition des congés"
                    ),
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Champs"
                    helperText={
                      "Champs sélectionnés: " +
                      inputSelectedFields.length +
                      "/7"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <BlockContainer
            padding="16px 0"
            border="1px dashed black"
            margin="16px 0"
            $borderRadius="10px"
          >
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text16>Spécifiez l'ordre des champs</Text16>
            </FlexContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                direction={matches ? "horizontal" : "vertical"}
              >
                {(provided, snapshot) => (
                  <FlexContainer
                    padding="16px 8px 16px 8px"
                    flexDirection={matches ? "row" : "column"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {inputSelectedFields.map((field, index) => (
                      <Draggable
                        key={field + "id"}
                        draggableId={field + "id"}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <FlexContainer
                            height="56px"
                            backgroundColor={CustomTheme.palette.primary.main}
                            elevation={snapshot.isDragging ? 9 : 0}
                            flex="1"
                            alignItems="center"
                            justifyContent="center"
                            padding="16px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Text14
                              textAlign="center"
                              color="white"
                              margin="0"
                              lineHeight="14px"
                            >
                              {field.includes("Custom::")
                                ? getCustomFieldNameByCustomFieldId(
                                    field.substring(8)
                                  )
                                : field}
                            </Text14>
                          </FlexContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FlexContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BlockContainer>
        </BlockContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddViewLoading}
            loading={isAddViewLoading}
            onClick={() => {
              setIsAddViewLoading(true);
              if (validateViewForm()) addView();
              else setIsAddViewLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setViewAddActive(false);
              resetViewForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {views.map((view, index) => (
        <BlockContainer padding="16px 0" key={index}>
          <FlexContainer
            flexDirection="column"
            elevation={3}
            $borderRadius="10px"
            alignItems="center"
            position="relative"
          >
            <FlexContainer
              position="absolute"
              top="0"
              right="0"
              padding="4px"
              alignItems="flex-end"
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() => setViewPopupEditActive(view)}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => setViewPopupDeleteActive(view)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text14>Vue: </Text14>
              <Text14 fontWeight="500" margin="4px 0">
                {view.name}
              </Text14>
            </FlexContainer>
            <FlexContainer
              padding="16px 8px 16px 8px"
              width="100%"
              flexWrap="wrap"
            >
              {view.fields.map((field, index) => (
                <FlexContainer
                  key={index}
                  height="56px"
                  backgroundColor={CustomTheme.palette.primary.main}
                  flex="1"
                  alignItems="center"
                  justifyContent="center"
                  padding="16px"
                >
                  <Text14 color="white" margin="0" lineHeight="14px">
                    {field.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(field.substring(8))
                      : field}
                  </Text14>
                </FlexContainer>
              ))}
            </FlexContainer>
          </FlexContainer>
        </BlockContainer>
      ))}
      {popupEditActive !== null && (
        <EditCustomField
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getCustomFields={getCustomFields}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteCustomField
          popupActive={popupDeleteActive}
          isDeleteCustomFieldLoading={isDeleteCustomFieldLoading}
          setIsDeleteCustomFieldLoading={setIsDeleteCustomFieldLoading}
          setPopupActive={setPopupDeleteActive}
          deleteCustomField={deleteCustomField}
        />
      )}
      {viewPopupEditActive !== null && (
        <EditView
          popupActive={viewPopupEditActive}
          customFields={customFields}
          setPopupActive={setViewPopupEditActive}
          getViews={getViews}
        />
      )}
      {viewPopupDeleteActive !== null && (
        <DeleteView
          popupActive={viewPopupDeleteActive}
          isDeleteViewLoading={isDeleteViewLoading}
          setIsDeleteViewLoading={setIsDeleteViewLoading}
          setPopupActive={setViewPopupDeleteActive}
          deleteView={deleteView}
        />
      )}
    </FlexContainer>
  );
};

export default CustomizeVacations;
