import BusinessIcon from "@mui/icons-material/Business";
import ConstructionIcon from "@mui/icons-material/Construction";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import WorkIcon from "@mui/icons-material/Work";
import { Grid } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text20, Text36 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface ForWhoSectionProps {}

const ForWhoSection: React.FC<ForWhoSectionProps> = () => {
  const categories = [
    {
      icon: (
        <RestaurantIcon
          sx={{ fontSize: 60, color: CustomTheme.palette.secondary.main }}
        />
      ),
      label: "Industries et commerces alimentaires",
    },
    {
      icon: (
        <WorkIcon
          sx={{ fontSize: 60, color: CustomTheme.palette.secondary.main }}
        />
      ),
      label: "Fournisseurs de services",
    },
    {
      icon: (
        <ConstructionIcon
          sx={{ fontSize: 60, color: CustomTheme.palette.secondary.main }}
        />
      ),
      label: "Artisans",
    },
    {
      icon: (
        <BusinessIcon
          sx={{ fontSize: 60, color: CustomTheme.palette.secondary.main }}
        />
      ),
      label: "PME et startups",
    },
    {
      icon: (
        <LocalShippingIcon
          sx={{ fontSize: 60, color: CustomTheme.palette.secondary.main }}
        />
      ),
      label: "Transporteurs et sociétés de logistique",
    },
  ];
  return (
    <FlexContainer
      flexDirection={"column"}
      alignItems={"center"}
      backgroundColor="#F9F8FE"
      sx={{ padding: { xs: "16px", sm: "80px 128px" } }}
    >
      {/* Titre */}
      <FlexContainer marginBottom={"16px"}>
        <Text36 fontWeight="600">Pour qui ?</Text36>
      </FlexContainer>

      {/* Grid des catégories */}
      <Grid container spacing={4} justifyContent="center">
        {categories.map((category, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <FlexContainer
              sx={{
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <FlexContainer margin={"16px 0"}>{category.icon}</FlexContainer>
              <Text20 color="#A6A6A6" textAlign="center" fontWeight="600">
                {category.label}
              </Text20>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
    </FlexContainer>
  );
};

export default ForWhoSection;
