import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { SupplierCartItem } from "../../../../../models/cartItem";
import { EmailTemplate } from "../../../../../models/emailTemplate";
import { Employee } from "../../../../../models/employee";
import { SmsTemplate } from "../../../../../models/smsTemplate";
import { SupplierOrder } from "../../../../../models/supplierOrder";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getCartItemsAction } from "../../../../../store/Cart/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  CC_SUPPLIER_ORDER_EMAILS,
  CC_SUPPLIER_ORDER_PHONE_NUMBERS,
  SUPPLIER_ORDER_FORMAT,
  SUPPLIER_ORDER_INCREMENT,
  SUPPLIER_ORDER_NUMBER_OF_DIGITS,
  getItemObject,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../Reusable/Utils";

export interface SubmitOrderProps {
  popupActive: SupplierCartItem | null;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<SupplierCartItem | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getCartItemsAction: typeof getCartItemsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _SubmitOrder: React.FC<SubmitOrderProps> = ({
  popupActive,
  employees,
  getEmployeesAction,
  setPopupActive,
  getCartItemsAction,
}) => {
  const addSupplierOrder = () => {
    let totalHt = getTotalHt();
    let totalTTC = getTotalTTC();
    AxiosHttpClient.post<SupplierOrder>("api/v1/weeventpro/supplierOrders", {
      id: "",
      description:
        "Commande N° " +
        inputSupplierOrderInternalNumber +
        " Le " +
        dayjs().tz("Europe/Paris").format("DD/MM/YYYY") +
        " chez " +
        (popupActive!.supplier ? popupActive!.supplier.name : "?"),
      supplierOrderInternalNumber: inputSupplierOrderInternalNumber,
      supplierOrderNumber: "",
      orderDate: dayjs().tz("Europe/Paris").format("YYYY-MM-DD"),
      deliveryDate: dayjs().tz("Europe/Paris").format("YYYY-MM-DD"),
      supplierId: popupActive!.supplier?.id,
      comment: "",
      purchasedItems: popupActive!.items.map((item) => {
        return {
          id: uuid(),
          name: getItemObject(item).name,
          priceHT: getItemObject(item).priceHT,
          tva: getItemObject(item).tva,
          quantity: item.quantity,
          internalCode: getItemObject(item).productCode,
          imageUrl: getItemObject(item).imageUrl,
          parentId: item.ingredientMaterialId,
          purchaseOptionId: (item.purchaseOption || item.materialPurchaseOption)
            ?.id,
        };
      }),
      additionalFees: [],
      totalPriceHT: toFixed2(totalHt),
      totalPriceTVA: toFixed2(totalTTC - totalHt),
      totalPriceTTC: toFixed2(totalTTC),
      globalTVA: toFixed2((totalTTC / totalHt - 1) * 100),
      status: "Commandée",
      addedToStore: false,
      customFields: [],
    })
      .then((res) => {
        sendOrder(res.id);
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const sendOrder = (supplierOrderId: string) => {
    AxiosHttpClient.post<EmailTemplate[]>("api/v1/weeventpro/cart/send", {
      orderBy: inputOrderBy,
      to:
        inputOrderBy === "E-mail"
          ? inputToEmails.map((inputToEmails) =>
              typeof inputToEmails === "string"
                ? inputToEmails
                : inputToEmails.email
            )
          : inputToSms.map((inputToSms) =>
              typeof inputToSms === "string"
                ? inputToSms
                : inputToSms.phoneNumber
            ),
      cc: inputCCEmails.map((inputCCEmail) =>
        typeof inputCCEmail === "string" ? inputCCEmail : inputCCEmail.email
      ),
      subject: inputSubject,
      content: inputOrderBy === "E-mail" ? inputContent : inputContentSms,
      supplierOrderId: supplierOrderId,
    })
      .then(() => {
        emptySupplierCartItems();
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const emptySupplierCartItems = () => {
    if (popupActive!.supplier) {
      AxiosHttpClient.delete(
        "api/v1/weeventpro/cart/",
        popupActive!.supplier.id
      )
        .then(() => {
          getCartItemsAction();
          setPopupActive(null);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        CC_SUPPLIER_ORDER_EMAILS +
        "," +
        CC_SUPPLIER_ORDER_PHONE_NUMBERS +
        "," +
        SUPPLIER_ORDER_FORMAT +
        "," +
        SUPPLIER_ORDER_NUMBER_OF_DIGITS +
        "," +
        SUPPLIER_ORDER_INCREMENT,
    }).then((res) => {
      if (getUserParameterValueByName(CC_SUPPLIER_ORDER_EMAILS, res))
        setInputCCEmails(
          getUserParameterValueByName(CC_SUPPLIER_ORDER_EMAILS, res)
            .split(",")
            .map((email) => {
              let employee = employees.find(
                (employee) => employee.email === email
              );
              if (employee) return employee;
              return email;
            })
        );
      if (getUserParameterValueByName(CC_SUPPLIER_ORDER_PHONE_NUMBERS, res))
        setInputToSms([
          ...inputToSms,
          ...getUserParameterValueByName(CC_SUPPLIER_ORDER_PHONE_NUMBERS, res)
            .split(",")
            .filter((phoneNumber) => {
              return phoneNumber !== "";
            })
            .map((phoneNumber) => {
              let employee = employees.find(
                (employee) => employee.phoneNumber === phoneNumber
              );
              if (employee) return employee;
              return phoneNumber;
            }),
        ]);
      let incrementString = getUserParameterValueByName(
        SUPPLIER_ORDER_INCREMENT,
        res
      );
      let incrementPadding = incrementString.padStart(
        Number(
          getUserParameterValueByName(SUPPLIER_ORDER_NUMBER_OF_DIGITS, res)
        ),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        SUPPLIER_ORDER_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputSupplierOrderInternalNumber(invoiceNumber);
    });
  };

  const getInvoiceEmailTemplates = () => {
    AxiosHttpClient.get<EmailTemplate[]>(
      "api/v1/weeventpro/parameters/template/supplierOrder/email"
    )
      .then((res) => {
        setSupplierOrderEmailTemplates(res);
        if (res && res.length > 0) {
          setInputTemplateId(res[0].id);
        }
      })
      .catch((err) => {});
  };

  const getInvoiceSmsTemplates = () => {
    AxiosHttpClient.get<SmsTemplate[]>(
      "api/v1/weeventpro/parameters/template/supplierOrder/sms"
    )
      .then((res) => {
        setSupplierOrderSmsTemplates(res);
        if (res && res.length > 0) {
          setInputTemplateId(res[0].id);
        }
      })
      .catch((err) => {});
  };

  const getTotalHt = () => {
    return popupActive!.items
      .map(
        (cartItem) =>
          Number(cartItem.quantity) * Number(getItemObject(cartItem).priceHT)
      )
      .reduce((total, currentValue) => (total = total + currentValue), 0);
  };

  const getTotalTTC = () => {
    return popupActive!.items
      .map(
        (cartItem) =>
          (1 + Number(getItemObject(cartItem).tva) * 0.01) *
          Number(cartItem.quantity) *
          Number(getItemObject(cartItem).priceHT)
      )
      .reduce((total, currentValue) => (total = total + currentValue), 0);
  };

  const [
    inputSupplierOrderInternalNumber,
    setInputSupplierOrderInternalNumber,
  ] = useState("");

  const [inputOrderBy, setInputOrderBy] = useState("SMS");

  const [inputSubject, setInputSubject] = useState("");

  const [inputSubjectError, setInputSubjectError] = useState(false);

  const [inputContent, setInputContent] = useState("");

  const [inputContentError, setInputContentError] = useState(false);

  const [inputContentSms, setInputContentSms] = useState("");

  const [inputContentSmsError, setInputContentSmsError] = useState(false);

  const [inputCCEmails, setInputCCEmails] = useState<(Employee | string)[]>([]);

  const [inputCCEmailsText, setInputCCEmailsText] = useState("");

  const [inputToEmails, setInputToEmails] = useState<(Employee | string)[]>(
    popupActive!.supplier && popupActive!.supplier.email
      ? [popupActive!.supplier.email]
      : []
  );

  const [inputToEmailsText, setInputToEmailsText] = useState("");

  const [inputToError, setInputToError] = useState(false);

  const [inputToSms, setInputToSms] = useState<(Employee | string)[]>(
    popupActive!.supplier && popupActive!.supplier.phoneNumber
      ? [popupActive!.supplier.phoneNumber]
      : []
  );

  const [inputToSmsText, setInputToSmsText] = useState("");

  const [inputToSmsError, setInputToSmsError] = useState(false);

  const [inputTemplateId, setInputTemplateId] = useState("");

  const [supplierOrderEmailTemplates, setSupplierOrderEmailTemplates] =
    useState<EmailTemplate[]>([]);

  const [supplierOrderSmsTemplates, setSupplierOrderSmsTemplates] = useState<
    SmsTemplate[]
  >([]);

  const [sendFailed, setSendFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const filterEmployeesEmailsOptions = createFilterOptions<Employee>({
    stringify: (option) => option.email,
  });

  const filterEmployeesPhoneNumberOptions = createFilterOptions<Employee>({
    stringify: (option) => option.phoneNumber,
  });

  const validateForm = () => {
    setInputToError(false);
    setInputToSmsError(false);
    setInputSubjectError(false);
    setInputContentError(false);
    setInputContentSmsError(false);
    let result = true;
    if (inputToEmails.length === 0 && inputOrderBy === "E-mail") {
      setInputToError(true);
      result = false;
    }
    if (inputSubject === "" && inputOrderBy === "E-mail") {
      setInputSubjectError(true);
      result = false;
    }
    if (inputContent === "" && inputOrderBy === "E-mail") {
      setInputContentError(true);
      result = false;
    }
    if (inputToSms.length === 0 && inputOrderBy === "SMS") {
      setInputToSmsError(true);
      result = false;
    }
    if (inputContentSms === "" && inputOrderBy === "SMS") {
      setInputContentSmsError(true);
      result = false;
    }
    if (!result) {
      setSendFailed("Champs manquants");
    }
    return result;
  };

  useEffect(() => {
    getInvoiceEmailTemplates();
    getInvoiceSmsTemplates();
    getEmployeesAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let templateEmail: EmailTemplate | null = null;
    let templateSms: SmsTemplate | null = null;
    for (var invoiceEmailTemplate of supplierOrderEmailTemplates) {
      if (invoiceEmailTemplate.id === inputTemplateId) {
        templateEmail = invoiceEmailTemplate;
      }
    }
    for (var invoiceSmsTemplate of supplierOrderSmsTemplates) {
      if (invoiceSmsTemplate.id === inputTemplateId) {
        templateSms = invoiceSmsTemplate;
      }
    }
    if (templateEmail) {
      setInputSubject(templateEmail.subject);
      setInputContent(templateEmail.content);
    }
    if (templateSms) {
      setInputContentSms(templateSms.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTemplateId]);

  useEffect(() => {
    if (
      inputOrderBy === "E-mail" &&
      supplierOrderEmailTemplates &&
      supplierOrderEmailTemplates.length > 0
    ) {
      setInputTemplateId(supplierOrderEmailTemplates[0].id);
    } else if (
      inputOrderBy === "SMS" &&
      supplierOrderSmsTemplates &&
      supplierOrderSmsTemplates.length > 0
    ) {
      setInputTemplateId(supplierOrderSmsTemplates[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOrderBy, supplierOrderEmailTemplates, supplierOrderSmsTemplates]);

  useEffect(() => {
    // Fonction de remplacement des tokens dans un texte
    const replaceTokens = (
      text: string,
      mappings: Record<string, string>
    ): string => {
      let replacedText = text;
      Object.entries(mappings).forEach(([key, value]) => {
        // Échappe les caractères spéciaux pour la regex
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        replacedText = replacedText.replace(new RegExp(escapedKey, "g"), value);
      });
      return replacedText;
    };

    // Construction de la chaîne de produits achetés
    const purchasedItems = popupActive?.items
      .map((item) => {
        let purchaseOption = item.purchaseOption || item.materialPurchaseOption;
        let line = `${item.quantity} x ${purchaseOption?.name}`;
        if (
          purchaseOption?.productCode &&
          purchaseOption?.productCode.trim() !== ""
        ) {
          line += ` (${purchaseOption?.productCode})`;
        }
        return line;
      })
      .join("\n");

    // Création du mapping pour remplacer les placeholders
    /* eslint-disable no-template-curly-in-string */
    const mappings: Record<string, string> = {
      "${produits}": purchasedItems ?? "",
      "${fournisseur}": popupActive?.supplier.name ?? "",
    };
    /* eslint-enable no-template-curly-in-string */

    // Remplacement des tokens dans le sujet et dans le contenu
    const replacedSubject = replaceTokens(inputSubject, mappings);
    const replacedContent = replaceTokens(inputContent, mappings);
    const replacedContentSms = replaceTokens(inputContentSms, mappings);

    // Mise à jour des états pour afficher l'aperçu
    setInputSubject(replacedSubject);
    setInputContent(replacedContent);
    setInputContentSms(replacedContentSms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSubject, inputContent, inputContentSms]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Valider la commande"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {sendFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setSendFailed("");
              }}
            >
              {sendFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Commander par
                </InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputOrderBy}
                  label="Commander par"
                  onChange={(e) => {
                    setInputOrderBy(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="SMS">SMS</MenuItem>
                  <MenuItem value="E-mail">E-mail</MenuItem>
                  <MenuItem value="Aucun">Aucun</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {inputOrderBy === "E-mail" && (
              <>
                <Grid item xs={1} sm={1} md={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="templateId">Template</InputLabel>
                    <Select
                      labelId="templateId"
                      id="templateSelect"
                      value={inputTemplateId}
                      label="Template"
                      onChange={(e) => setInputTemplateId(e.target.value)}
                    >
                      {supplierOrderEmailTemplates.length === 0 && (
                        <MenuItem disabled sx={{ color: "red" }} value="">
                          Aucun template créé
                        </MenuItem>
                      )}
                      {supplierOrderEmailTemplates.map(
                        (invoiceEmailTemplate, index) => (
                          <MenuItem value={invoiceEmailTemplate.id} key={index}>
                            {invoiceEmailTemplate.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <Autocomplete
                    id="to"
                    filterOptions={filterEmployeesEmailsOptions}
                    noOptionsText={"Pas de suggestions"}
                    freeSolo
                    fullWidth
                    multiple
                    getOptionLabel={(option) => {
                      return typeof option === "string" ? option : option.email;
                    }}
                    options={employees}
                    value={inputToEmails}
                    onChange={(event, newValue) => {
                      setInputToEmails(newValue!);
                    }}
                    inputValue={inputToEmailsText}
                    onInputChange={(event, newInputValue) => {
                      setInputToEmailsText(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={inputToError}
                        {...params}
                        variant="outlined"
                        name="to"
                        label="À"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <Autocomplete
                    id="cc"
                    filterOptions={filterEmployeesEmailsOptions}
                    noOptionsText={"Pas de suggestions"}
                    freeSolo
                    fullWidth
                    multiple
                    filterSelectedOptions
                    getOptionLabel={(option) => {
                      return typeof option === "string" ? option : option.email;
                    }}
                    options={employees}
                    value={inputCCEmails}
                    onChange={(event, newValue) => {
                      setInputCCEmails(newValue!);
                    }}
                    inputValue={inputCCEmailsText}
                    onInputChange={(event, newInputValue) => {
                      setInputCCEmailsText(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name="cc"
                        label="CC"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    name="subject"
                    label="Objet"
                    error={inputSubjectError}
                    value={inputSubject}
                    onChange={(e) => setInputSubject(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    name="content"
                    label="Corps du message"
                    multiline
                    rows={5}
                    error={inputContentError}
                    value={inputContent}
                    onChange={(e) => setInputContent(e.target.value)}
                  />
                </Grid>
              </>
            )}
            {inputOrderBy === "SMS" && (
              <>
                <Grid item xs={1} sm={1} md={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="templateId">Template</InputLabel>
                    <Select
                      labelId="templateId"
                      id="templateSelect"
                      value={inputTemplateId}
                      label="Template"
                      onChange={(e) => setInputTemplateId(e.target.value)}
                    >
                      {supplierOrderSmsTemplates.length === 0 && (
                        <MenuItem disabled sx={{ color: "red" }} value="">
                          Aucun template créé
                        </MenuItem>
                      )}
                      {supplierOrderSmsTemplates.map(
                        (invoiceSmsTemplate, index) => (
                          <MenuItem value={invoiceSmsTemplate.id} key={index}>
                            {invoiceSmsTemplate.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <Autocomplete
                    id="to"
                    filterOptions={filterEmployeesPhoneNumberOptions}
                    noOptionsText={"Pas de suggestions"}
                    freeSolo
                    fullWidth
                    multiple
                    getOptionLabel={(option) => {
                      return typeof option === "string"
                        ? option
                        : option.phoneNumber;
                    }}
                    options={employees}
                    value={inputToSms}
                    onChange={(event, newValue) => {
                      setInputToSms(newValue!);
                    }}
                    inputValue={inputToSmsText}
                    onInputChange={(event, newInputValue) => {
                      setInputToSmsText(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={inputToSmsError}
                        {...params}
                        variant="outlined"
                        name="to"
                        label="À"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    name="content"
                    label="Corps du message"
                    multiline
                    rows={5}
                    error={inputContentSmsError}
                    value={inputContentSms}
                    onChange={(e) => setInputContentSms(e.target.value)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) {
              addSupplierOrder();
            } else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Commander
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const SubmitOrder = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_SubmitOrder);

export default SubmitOrder;
