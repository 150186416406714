import { Box, Grid } from "@mui/material";
import React from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text20, Text56 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";
import ContactSection from "../ContactSection";
import Footer from "../Footer";
import Header from "../Header";

export interface FeaturesProps {}

const sections = [
  {
    title: "Gestion du stock",
    description:
      "Suivez en temps réel vos stocks de produits, ingrédients et matériels pour une gestion optimisée et sans rupture.",
    videoId: "wLcXuWP2sAg",
  },
  {
    title: "Gestion des ventes",
    description:
      "Gérez vos commandes, automatisez votre facturation et suivez vos performances commerciales en un coup d’œil.",
    videoId: "VIDÉO_ID_2",
  },
  {
    title: "Gestion des livraisons",
    description:
      "Planifiez, optimisez et suivez vos tournées de livraison pour garantir rapidité et satisfaction client.",
    videoId: "VIDÉO_ID_3",
  },
  {
    title: "Gestion du catalogue",
    description:
      "Créez, organisez et mettez à jour facilement votre offre de produits et services à vendre.",
    videoId: "VIDÉO_ID_4",
  },
  {
    title: "Gestion des devis",
    description:
      "Générez des devis professionnels en quelques clics et suivez leur conversion en commandes.",
    videoId: "VIDÉO_ID_5",
  },
  {
    title: "Gestion des factures",
    description:
      "Automatisez la création et l’envoi de vos factures tout en assurant un suivi efficace des paiements.",
    videoId: "VIDÉO_ID_6",
  },
  {
    title: "CRM",
    description:
      "Centralisez et suivez vos clients, prospects et opportunités pour booster votre relation client et vos ventes.",
    videoId: "VIDÉO_ID_7",
  },
  {
    title: "Gestion RH",
    description:
      "Gérez vos employés, congés, tâches et suivis RH pour une gestion fluide et efficace de votre équipe.",
    videoId: "VIDÉO_ID_8",
  },
];

const Features: React.FC<FeaturesProps> = () => {
  return (
    <BlockContainer position="relative" overflow="hidden">
      <Header />
      <BlockContainer sx={{ padding: { xs: "0 16px", sm: "0 128px" } }}>
        {sections.map((section, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            columns={{ xs: 1, sm: 6, md: 12 }}
            alignItems="center"
            justifyContent="center"
            sx={{ padding: { xs: "40px 0", md: "80px 0" } }}
          >
            {/* Texte à gauche ou à droite selon l'index */}
            {index % 2 === 0 ? (
              <>
                {/* Colonne Gauche - Texte */}
                <Grid item xs={12} md={6}>
                  <FlexContainer
                    sx={{ textAlign: { xs: "center", md: "left" }, mb: 2 }}
                  >
                    <Text56 fontWeight="bold">{section.title}</Text56>
                  </FlexContainer>

                  {/* Image horizontale avec trait */}
                  <FlexContainer>
                    <Box
                      sx={{
                        margin: "0 0 40px -16px",
                        width: "100%",
                        height: "40px",
                        border: "solid 8px " + CustomTheme.palette.primary.main,
                        borderColor:
                          CustomTheme.palette.primary.main +
                          " transparent transparent transparent",
                        borderRadius: "50%/40px 40px 0 0",
                      }}
                    />
                  </FlexContainer>

                  <FlexContainer
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      color: "#555",
                    }}
                  >
                    <Text20>{section.description}</Text20>
                  </FlexContainer>
                </Grid>

                {/* Colonne Droite - Vidéo YouTube */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "560px",
                      aspectRatio: "16/9",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${section.videoId}`}
                      title={section.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                {/* Colonne Gauche - Vidéo YouTube */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "560px",
                      aspectRatio: "16/9",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${section.videoId}`}
                      title={section.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Box>
                </Grid>

                {/* Colonne Droite - Texte */}
                <Grid item xs={12} md={6}>
                  <FlexContainer
                    sx={{ textAlign: { xs: "center", md: "left" }, mb: 2 }}
                  >
                    <Text56 fontWeight="bold">{section.title}</Text56>
                  </FlexContainer>

                  {/* Image horizontale avec trait */}
                  <FlexContainer>
                    <Box
                      sx={{
                        margin: "0 0 40px -16px",
                        width: "100%",
                        height: "40px",
                        border: "solid 8px " + CustomTheme.palette.primary.main,
                        borderColor:
                          CustomTheme.palette.primary.main +
                          " transparent transparent transparent",
                        borderRadius: "50%/40px 40px 0 0",
                      }}
                    />
                  </FlexContainer>

                  <FlexContainer
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      color: "#555",
                    }}
                  >
                    <Text20>{section.description}</Text20>
                  </FlexContainer>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </BlockContainer>
      <ContactSection />
      <Footer />
    </BlockContainer>
  );
};

export default Features;
