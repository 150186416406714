import { Divider } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { Complaint } from "../../../../../../models/complaint";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import {
  getContactDescription,
  getEmployeeDescription,
} from "../../../../../Reusable/Utils";

export interface InformationsProps {
  complaint: Complaint;
}

const Informations: React.FC<InformationsProps> = ({ complaint }) => {
  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  return (
    <FlexContainer padding="16px">
      <BlockContainer width="100%">
        <Text16 fontWeight="500">Informations générales</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Numéro de la réclamation</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{complaint.complaintNumber}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Titre</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.title && complaint.title !== ""
                ? complaint.title
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de réclamation</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.complaintDate
                ? dayjs(complaint.complaintDate).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Client</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{getContactDescription(complaint.client)}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Responsables</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.responsibles && complaint.responsibles.length > 0
                ? complaint.responsibles
                    .map((responsible) => getEmployeeDescription(responsible))
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">
              Date de résolution prévue
            </Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.expectedResolutionDate
                ? dayjs(complaint.expectedResolutionDate).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de résolution</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.resolutionDate
                ? dayjs(complaint.resolutionDate).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Tags</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.tags && complaint.tags.length > 0
                ? complaint.tags.join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Catégorie</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.category && complaint.category !== ""
                ? complaint.category
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Priorité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.priority && complaint.priority !== ""
                ? complaint.priority
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Canal de réclamation</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.complaintChannel && complaint.complaintChannel !== ""
                ? complaint.complaintChannel
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Statut</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.status && complaint.status !== ""
                ? complaint.status
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Description</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.description && complaint.description !== ""
                ? complaint.description
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Associations</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Ventes associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.associatedSales
                .map((sale) => sale.saleNumber)
                .join(", ")}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">factures associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {complaint.associatedInvoices
                .map((invoice) => invoice.invoiceNumber)
                .join(", ")}
            </Text14>
          </FlexContainer>
        </FlexContainer>
      </BlockContainer>
    </FlexContainer>
  );
};

export default Informations;
