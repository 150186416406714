import { Alert, Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { AxiosHttpClient } from "../../../services/AxiosHttpService";
import { BlockContainer } from "../../../styles/BlockContainer";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../styles/InlineBlockContainer";
import { Text14, Text16, Text24, Text36 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface ContactSectionProps {}

const ContactSection: React.FC<ContactSectionProps> = () => {
  const sendMail = () => {
    AxiosHttpClient.postForNonAuth("api/v1/weeventpro/general/email", {
      email: email,
      message: message,
    })
      .then(() => {
        setEmail("");
        setMessage("");
        setIsLoading(false);
        setEmailError(false);
        setMessageError(false);
        setSendFailed("");
        setSendSucceeded(true);
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const validateForm = () => {
    setEmailError(false);
    setMessageError(false);
    let result = true;
    if (email === "") {
      setEmailError(true);
      result = false;
    }
    if (message === "") {
      setMessageError(true);
      result = false;
    }
    if (!result) {
      setSendFailed("Champs manquants");
    }
    return result;
  };

  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");

  const [sendFailed, setSendFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const [messageError, setMessageError] = useState(false);

  const [sendSucceeded, setSendSucceeded] = useState(false);

  const navigate = useNavigate();

  return (
    <FlexContainer
      backgroundColor="#161C28"
      sx={{
        padding: { xs: "32px 32px 0 32px", sm: "80px 128px 0 128px" },
      }}
    >
      <Grid container spacing={4}>
        {/* Bloc gauche - Message de l'équipe */}
        <Grid item xs={12} md={6}>
          <BlockContainer sx={{ padding: "0 16px" }}>
            <FlexContainer marginBottom="16px">
              <Text36 fontWeight="600" color="white" margin="4px 10px">
                Message de l’équipe
              </Text36>
            </FlexContainer>
            <FlexContainer marginBottom="16px">
              <img
                src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/guillemet.svg`}
                alt="Mon SVG"
                width={51.2}
                height={34.4}
                style={{ marginBottom: "24px" }}
              />
            </FlexContainer>
            <FlexContainer>
              <Text16 fontStyle="italic" color="#A6A6A6">
                Chaque entreprise mérite un outil simple, puissant et adapté à
                ses besoins. Cet ERP CRM a été conçu pour vous faire gagner du
                temps, optimiser votre gestion et vous aider à atteindre vos
                objectifs plus rapidement.
              </Text16>
            </FlexContainer>
            <Text16 fontStyle="italic" color="#A6A6A6">
              Nous avons mis tout notre savoir-faire pour vous offrir une
              solution performante et intuitive. Hâte de vous accompagner dans
              cette aventure !
            </Text16>
            <FlexContainer alignItems="center" marginTop="32px">
              <img
                src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/aymane.png`}
                alt="Fondateur"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                }}
              />
              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600" color="white" margin="0 10px">
                  Aymane MASSIT
                </Text16>
                <Text14 color="#A6A6A6" margin="0 10px">
                  Fondateur de WeEvent
                </Text14>
              </FlexContainer>
            </FlexContainer>
          </BlockContainer>
        </Grid>

        {/* Bloc droit - Formulaire de contact */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "#222938",
              padding: "32px",
              borderRadius: "12px",
              textAlign: "center",
            }}
          >
            <img
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/Logo.svg`}
              alt="Mon SVG"
              width={92.8}
              height={62.4}
              style={{ marginBottom: "8px" }}
            />
            <FlexContainer marginBottom="16px" justifyContent={"center"}>
              <Text24 fontWeight="600" color="white">
                Nous contacter
              </Text24>
            </FlexContainer>
            {sendSucceeded && (
              <BlockContainer margin="8px">
                <Alert
                  severity="success"
                  onClose={() => {
                    setSendSucceeded(false);
                  }}
                >
                  Votre message a bien été envoyé
                </Alert>
              </BlockContainer>
            )}
            {sendFailed !== "" && (
              <BlockContainer margin="8px">
                <Alert
                  severity="error"
                  onClose={() => {
                    setSendFailed("");
                  }}
                >
                  {sendFailed}
                </Alert>
              </BlockContainer>
            )}

            {/* Champs du formulaire */}
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Votre email"
              sx={{
                backgroundColor: "white",
                borderRadius: "6px",
                marginBottom: "16px",
              }}
              error={emailError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Votre message"
              multiline
              minRows={3}
              maxRows={7}
              sx={{
                backgroundColor: "white",
                borderRadius: "6px",
                marginBottom: "16px",
              }}
              error={messageError}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* Bouton Envoyer */}
            <ButtonMui
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: CustomTheme.palette.warning.main,
                color: "white",
                padding: "12px",
                borderRadius: "6px",
                "&:hover": {
                  backgroundColor: CustomTheme.palette.warning.dark,
                },
              }}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                if (validateForm()) sendMail();
                else setIsLoading(false);
              }}
            >
              Envoyer
            </ButtonMui>

            {/* Texte aligné à droite */}
            <FlexContainer justifyContent="flex-end" marginTop="8px">
              <Text14 color="rgba(255,255,255,0.7)">
                ou{" "}
                <InlineBlockContainer
                  fontWeight={"600"}
                  $colorHover="white"
                  $cursorHover="pointer"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Essayer gratuitement
                </InlineBlockContainer>
              </Text14>
            </FlexContainer>
          </Box>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default ContactSection;
