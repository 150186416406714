import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import MaterialTable from "./MaterialTable";

export interface MaterialProps {}

const Material: React.FC<MaterialProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Matériels"
        subTitle="Liste des matériels"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <MaterialTable />
    </BlockContainer>
  );
};

export default Material;
