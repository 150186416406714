import { RootAction } from "../types";
import {
  GET_BASE_METRICS_SUCCESS,
  GET_CUSTOM_METRICS_SUCCESS,
} from "./actions";
import { MetricState } from "./types";

const initialState: MetricState = {
  baseMetrics: [],
  customMetrics: [],
};

export function metricReducer(
  state: MetricState = initialState,
  action: RootAction
): MetricState {
  switch (action.type) {
    case GET_BASE_METRICS_SUCCESS:
      return {
        baseMetrics: action.baseMetrics,
        customMetrics: state.customMetrics,
      };
    case GET_CUSTOM_METRICS_SUCCESS:
      return {
        baseMetrics: state.baseMetrics,
        customMetrics: action.customMetrics,
      };

    default:
      return state;
  }
}
