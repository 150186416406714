import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Service, ServiceSaleOption } from "../../../../../models/service";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  SERVICE_TAGS,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";
import { SaleOptions } from "../AddService/SaleOptions";

const Input = styled("input")({
  display: "none",
});

export interface EditServiceProps {
  popupActive: Service | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Service | null>>;
  getServicesPage: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const EditService: React.FC<EditServiceProps> = ({
  popupActive,
  setPopupActive,
  getServicesPage,
}) => {
  const editService = () => {
    AxiosHttpClient.put("api/v1/weeventpro/service", {
      id: popupActive!.id,
      imageUrl: inputImageUrl,
      name: inputName,
      category: inputCategory,
      tags: inputTags,
      internalCode: inputInternalCode,
      saleOptions: inputSaleOptions,
      customFields: customFieldValues,
    })
      .then(() => {
        getServicesPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editServiceDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/service"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editServiceDialogContentId",
      });
    }
    return result;
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames: SERVICE_TAGS,
    }).then((res) => {
      const serviceTags = getUserParameterValueByName(SERVICE_TAGS, res);
      setSavedTags(serviceTags ? serviceTags.split(",") : []);
    });
  };

  useEffect(() => {
    getUserParameters();
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputName, setInputName] = useState(popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputCategory, setInputCategory] = useState(popupActive!.category);

  const [inputTags, setInputTags] = useState<string[]>(popupActive!.tags);

  const [savedTags, setSavedTags] = useState<string[]>([]);

  const [inputInternalCode, setInputInternalCode] = useState(
    popupActive!.internalCode
  );

  const [inputSaleOptions, setInputSaleOptions] = useState<ServiceSaleOption[]>(
    popupActive!.saleOptions
  );

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    popupActive!.imageUrl
  );

  const [serviceImageHovered, setServiceImageHovered] = useState(false);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un service"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editServiceDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setServiceImageHovered(true)}
                onMouseLeave={() => setServiceImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {serviceImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-service"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
          </Grid>

          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => {
                  setInputName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputCategory}
                  label="Catégorie"
                  onChange={(e) => {
                    setInputCategory(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Consulting">Consulting</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                  <MenuItem value="Installation">Installation</MenuItem>
                  <MenuItem value="Formation">Formation</MenuItem>
                  <MenuItem value="Support technique">
                    Support technique
                  </MenuItem>
                  <MenuItem value="Développement">Développement</MenuItem>
                  <MenuItem value="Conseil">Conseil</MenuItem>
                  <MenuItem value="Audit">Audit</MenuItem>
                  <MenuItem value="Gestion de projet">
                    Gestion de projet
                  </MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="internalCode"
                label="Code interne"
                value={inputInternalCode}
                onChange={(e) => {
                  setInputInternalCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="tags"
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                filterSelectedOptions
                options={savedTags}
                value={inputTags}
                onChange={(event, newValue) => {
                  setInputTags(newValue!);
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputTags.includes(option) ? true : false}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="tags"
                    label="Tags"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0" id="offSearch">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Options de vente
          </Text20>
        </BlockContainer>
        <SaleOptions
          inputSaleOptions={inputSaleOptions}
          inputImageUrl={inputImageUrl}
          setInputSaleOptions={setInputSaleOptions}
        />
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Champs personnalisés
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {customFields.map((customField, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              {customField.type === "Texte" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  name={customField.name}
                  label={customField.name}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(
                          customField.id
                        )![0] === undefined
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(customField.id)![0]
                  }
                  onChange={(e) =>
                    setCustomFieldValuesByCustomFieldId(customField.id, [
                      e.target.value,
                    ])
                  }
                />
              )}
              {customField.type === "Date" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={customField.name}
                    inputFormat="DD/MM/YYYY"
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0 ||
                      getCustomFieldValuesByCustomFieldId(
                        customField.id
                      )![0] === ""
                        ? null
                        : dayjs(
                            getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0],
                            "DD/MM/YYYY"
                          )
                    }
                    onChange={(value) =>
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        value === null ? "" : value!.format("DD/MM/YYYY"),
                      ])
                    }
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              )}
              {customField.type === "Liste déroulante" && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {customField.name}
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    label={customField.name}
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                  >
                    {customField.values.map((choice, index) => (
                      <MenuItem value={choice} key={index}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {customField.type === "Boutton radio" && (
                <FormControl>
                  <FormLabel>{customField.name}</FormLabel>
                  <RadioGroup
                    name={customField.name}
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                    row
                  >
                    {customField.values.map((choice, index) => (
                      <FormControlLabel
                        value={choice}
                        control={<Radio />}
                        label={choice}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {customField.type === "Checkbox" && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={customField.values}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? []
                      : getCustomFieldValuesByCustomFieldId(customField.id)!
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null
                            ? false
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )!.includes(option)
                            ? true
                            : false
                        }
                      />
                      {option}
                    </li>
                  )}
                  onChange={(e, values) => {
                    setCustomFieldValuesByCustomFieldId(customField.id, values);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={customField.name}
                    />
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editService();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditService;
