import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { InvoiceService } from "../../services/InvoiceService";
import { RootAction } from "../types";
import {
  GET_CREDIT_NOTE_TEMPLATES,
  GET_INVOICE_TEMPLATES,
  GET_INVOICES,
  getCreditNoteTemplatesActionFailure,
  GetCreditNoteTemplatesActionFailure,
  getCreditNoteTemplatesActionSuccess,
  GetCreditNoteTemplatesActionSuccess,
  GetInvoicesActionFailure,
  getInvoicesActionFailure,
  GetInvoicesActionSuccess,
  getInvoicesActionSuccess,
  getInvoiceTemplatesActionFailure,
  GetInvoiceTemplatesActionFailure,
  getInvoiceTemplatesActionSuccess,
  GetInvoiceTemplatesActionSuccess,
} from "./actions";

export const invoiceListEpic: Epic<
  RootAction,
  GetInvoicesActionSuccess | GetInvoicesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INVOICES),
    switchMap((action) =>
      from(
        InvoiceService.getInvoices({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getInvoicesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getInvoicesActionFailure()]))
        )
      )
    )
  );

export const invoiceTemplateEpic: Epic<
  RootAction,
  GetInvoiceTemplatesActionSuccess | GetInvoiceTemplatesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INVOICE_TEMPLATES),
    switchMap((action) =>
      from(InvoiceService.getInvoiceTemplates()).pipe(
        mergeMap((res) => {
          return [getInvoiceTemplatesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getInvoiceTemplatesActionFailure()]))
        )
      )
    )
  );

export const creditNoteTemplateEpic: Epic<
  RootAction,
  GetCreditNoteTemplatesActionSuccess | GetCreditNoteTemplatesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CREDIT_NOTE_TEMPLATES),
    switchMap((action) =>
      from(InvoiceService.getCreditNoteTemplates()).pipe(
        mergeMap((res) => {
          return [getCreditNoteTemplatesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(
            mergeMap(() => [getCreditNoteTemplatesActionFailure()])
          )
        )
      )
    )
  );

export const invoiceEpic = combineEpics<any>(
  invoiceListEpic,
  invoiceTemplateEpic,
  creditNoteTemplateEpic
);
