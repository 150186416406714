import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import { CustomField } from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { UserParameter } from "../../../../../models/userParameters";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import {
  EMPLOYEE_TABLE_ORDER,
  EMPLOYEE_TABLE_ORDER_BY,
  EMPLOYEE_TABLE_SEARCH_BY,
  EMPLOYEE_TABLE_VIEW,
  getEmployeeDescription,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../Reusable/Utils";
import AddEmployee from "../AddEmployee";
import DeleteEmployee from "../DeleteEmployee";
import EditEmployee from "../EditEmployee";
import ExportEmployees from "../ExportEmployees";
import PreactivateEmployeeConfirmation from "../PreactivateEmployeeConfirmation";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface EmployeeTableProps {
  additionalCriterias?: string;
  actionsOnAdd?: (() => void)[];
  actionsOnEdit?: (() => void)[];
  actionsOnDelete?: (() => void)[];
  actionsOnGenerate?: (() => void)[];
  triggerGetTable?: boolean;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  additionalCriterias,
  actionsOnAdd,
  actionsOnEdit,
  actionsOnDelete,
  actionsOnGenerate,
  triggerGetTable,
}) => {
  const getEmployeesPage = () => {
    setTableLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    AxiosHttpClient.get<any>(
      "api/v1/weeventpro/employees/page",
      {
        criteriaList:
          inputField +
          "=" +
          inputSearch +
          (additionalCriterias ? "," + additionalCriterias : ""),
        pageNumber: page - 1,
        pageSize: limit,
        sort: fieldsCorrespondanceMapForOrders.get(orderBy),
        order: order,
      },
      signal
    )
      .then((res) => {
        setEmployees(res.content);
        setTableLoading(false);
        setTotalElements(res.totalElements);
      })
      .catch((err) => {});
  };

  const previousController = useRef<AbortController>(null!);

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/employee")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/employee"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        EMPLOYEE_TABLE_ORDER +
        "," +
        EMPLOYEE_TABLE_ORDER_BY +
        "," +
        EMPLOYEE_TABLE_SEARCH_BY +
        "," +
        EMPLOYEE_TABLE_VIEW,
    }).then((res) => {
      setOrder(
        getUserParameterValueByName(EMPLOYEE_TABLE_ORDER, res) === "asc"
          ? "asc"
          : "desc"
      );
      setOrderBy(getUserParameterValueByName(EMPLOYEE_TABLE_ORDER_BY, res));
      setInputField(getUserParameterValueByName(EMPLOYEE_TABLE_SEARCH_BY, res));
      setInputView(getUserParameterValueByName(EMPLOYEE_TABLE_VIEW, res));
    });
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [inputSearch, setInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Employee | null>(null);

  const [popupPreActivateEmployeesActive, setPopupPreActivateEmployeesActive] =
    useState<string[]>([]);

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [inputView, setInputView] = useState("");

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  const [inputField, setInputField] = useState("");

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);

  const [totalElements, setTotalElements] = useState(0);

  const [employees, setEmployees] = useState<Employee[]>([]);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getEmployeesPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, page, limit, orderBy, order, triggerGetTable]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, limit, orderBy, order]);

  useEffect(() => {
    setInputSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  const setOrderParameters = (orderValue: string, orderByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: EMPLOYEE_TABLE_ORDER,
        parameterValue: orderValue,
      },
      {
        parameterName: EMPLOYEE_TABLE_ORDER_BY,
        parameterValue: orderByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrderParameters(isAsc ? "desc" : "asc", cellId);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "Image",
      "Identifiant d'employé",
      "Nom complet",
      "Date de naissance",
      "N° de téléphone",
      "E-mail",
      "Poste",
    ],
  };

  const getViewById = (id: string) => {
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : defaultView;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  let fieldsCorrespondanceMapForOrders: Map<string, string> = new Map([
    ["Identifiant d'employé", "employeeNumber"],
    ["Nom complet", "fullName"],
    ["Civilité", "title"],
    ["Prénom", "firstName"],
    ["Nom", "lastName"],
    ["Date de naissance", "dateOfBirth"],
    ["N° de téléphone", "phoneNumber"],
    ["E-mail", "email"],
    ["Adresse", "address"],
    ["Poste", "position"],
    ["Superviseur", "supervisorFullName"],
    ["Date d'embauche", "hireDate"],
    ["Type de contrat", "contractType"],
    ["Date de fin de contrat", "contratEndDate"],
    ["Statut", "workStatus"],
    ["Pourcentage", "percentage"],
    ["Nombre d'heure hebdomadaires", "weeklyHours"],
    ["Nombre d'heures effectif", "effectiveHours"],
    ["Salaire de base", "baseSalary"],
    ["Régime de travail", "workSchedule"],
    ["Numéro de sécurité sociale", "socialSecurityNumber"],
    ["Numéro d'identification fiscale", "taxIdNumber"],
    ["Nombre de CP annuel", "cpAnnualNumber"],
    ["Solde CP", "cpBalance"],
    ["Nombre de RTT annuel", "rttAnnualNumber"],
    ["Solde RTT", "rttBalance"],
    ["Compte pro", "status"],
  ]);

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["Identifiant d'employé", true],
      ["Nom complet", true],
      ["Civilité", true],
      ["Prénom", true],
      ["Nom", true],
      ["Date de naissance", true],
      ["N° de téléphone", true],
      ["E-mail", true],
      ["Adresse", true],
      ["Langues parlées", false],
      ["Poste", true],
      ["Superviseur", true],
      ["Date d'embauche", true],
      ["Type de contrat", true],
      ["Date de fin de contrat", true],
      ["Statut", true],
      ["Pourcentage", true],
      ["Nombre d'heure hebdomadaires", true],
      ["Nombre d'heures effectif", true],
      ["Salaire de base", true],
      ["Régime de travail", true],
      ["Numéro de sécurité sociale", true],
      ["Numéro d'identification fiscale", true],
      ["Nombre de CP annuel", true],
      ["Solde CP", true],
      ["Nombre de RTT annuel", true],
      ["Solde RTT", true],
      ["Compte pro", true],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (employee: Employee, customFieldId: string) => {
    if (employee.customFields !== null) {
      for (var customFieldValue of employee.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, employee: Employee) => {
    let headValuesMap: Map<string, string> = new Map([
      ["Identifiant d'employé", employee.employeeNumber],
      ["Civilité", employee.title],
      ["Nom complet", getEmployeeDescription(employee)],
      ["Prénom", employee.firstName],
      ["Nom", employee.lastName],
      [
        "Date de naissance",
        employee.dateOfBirth
          ? dayjs(employee.dateOfBirth).format("DD/MM/YYYY")
          : "",
      ],
      ["N° de téléphone", employee.phoneNumber],
      ["E-mail", employee.email],
      ["Adresse", employee.address],
      ["Langues parlées", employee.languages.join(", ")],
      ["Poste", employee.position],
      [
        "Superviseur",
        employee.supervisor
          ? `${employee.supervisor.firstName} ${employee.supervisor.lastName}`
          : "",
      ],
      [
        "Date d'embauche",
        employee.hireDate ? dayjs(employee.hireDate).format("DD/MM/YYYY") : "",
      ],
      ["Type de contrat", employee.contractType],
      [
        "Date de fin de contrat",
        employee.contratEndDate
          ? dayjs(employee.contratEndDate).format("DD/MM/YYYY")
          : "",
      ],
      ["Statut", employee.workStatus],
      ["Pourcentage", (employee.percentage?.toString() || "") + " %"],
      [
        "Nombre d'heure hebdomadaires",
        (employee.weeklyHours?.toString() || "") + " h",
      ],
      [
        "Nombre d'heures effectif",
        (employee.effectiveHours?.toString() || "") + " h",
      ],
      ["Salaire de base", toFixed2(employee.baseSalary) + " €"],
      ["Régime de travail", employee.workSchedule],
      ["Numéro de sécurité sociale", employee.socialSecurityNumber],
      ["Numéro d'identification fiscale", employee.taxIdNumber],
      [
        "Nombre de CP annuel",
        (employee.cpAnnualNumber?.toString() || "") + " j",
      ],
      ["Solde CP", (employee.cpBalance?.toString() || "") + " j"],
      [
        "Nombre de RTT annuel",
        (employee.rttAnnualNumber?.toString() || "") + " j",
      ],
      ["Solde RTT", (employee.rttBalance?.toString() || "") + " j"],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(
        customField.name,
        getCustomFieldValues(employee, customField.id)
      );
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, employee: Employee) => {
    let headValuesMap: Map<string, React.JSX.Element> = new Map([
      [
        "Image",
        <BlockContainer
          sx={{
            width: {
              xs: "60px",
              sm: "100px",
            },
            height: {
              xs: "60px",
              sm: "100px",
            },
          }}
          border="rgba(0,0,0,0.1) solid 1px"
          justifyContent="center"
          position="relative"
          textAlign="center"
          backgroundColor="white"
          margin="auto"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            alt=""
            src={employee?.imageUrl}
          />
        </BlockContainer>,
      ],
      [
        "Compte pro",
        <FlexContainer justifyContent="center">
          <FlexContainer
            justifyContent="center"
            backgroundColor={getStatusBackgroundColor(employee.status)}
            color="white"
            padding="4px 8px"
            $borderRadius="50px"
          >
            {employee.status}
          </FlexContainer>
        </FlexContainer>,
      ],
      [
        "Actions",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            disabled={employee.status === "Activé"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupPreActivateEmployeesActive([employee.id]);
            }}
            size="large"
          >
            <CheckCircleOutlineIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupEditActive(employee);
            }}
            size="large"
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupDeleteActive([employee.id]);
            }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>,
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "Inactif") return CustomTheme.palette.error.light;
    if (status === "Activé") return CustomTheme.palette.success.light;
    if (status === "En attente") return CustomTheme.palette.primary.main;
  };

  const getValueCells = (headValue: string, employee: Employee) => {
    let simpleValue = getSimpleCellValue(headValue, employee);
    let complexValue = getComplexCellValue(headValue, employee);
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  useEffect(() => {
    getClientsAction("");
    getCustomFields();
    getViews();
    getDishesAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="8px 10px 8px 8px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem disabled={true}>
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "customization,employees",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPopupPreActivateEmployeesActive([...selected]);
              }}
              size="large"
            >
              <CheckCircleOutlineIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                setPopupDeleteActive([...selected]);
                setSelected([]);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          inputField={inputField}
          setInputField={setInputField}
          setInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui sx={{ position: "relative" }}>
        {tableLoading && (
          <FlexContainer
            sx={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 57.297px)",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              top: "57.297px",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: "2",
              opacity: "0.7",
            }}
          >
            <CircularProgress size={80} />
          </FlexContainer>
        )}
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === employees.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = employees.map(
                        (employee) => employee.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading && employees.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "150px", borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            )}
            {!tableLoading && employees.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "100px", borderBottom: "none" }}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucun employé retrouvé
                  </Text14>
                </TableCell>
              </TableRow>
            )}
            {employees.map((item) => (
              <TableRow
                key={item.id}
                onClick={(e) => {
                  navigate(`/feature/employees/${item.id}`);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={selected.indexOf(item.id) > -1}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newArr = [...selected];
                      const index = selected.indexOf(item.id);
                      if (index > -1) {
                        newArr.splice(index, 1);
                        setSelected(newArr);
                      } else {
                        setSelected([...selected, item.id]);
                      }
                    }}
                  />
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell key={index}>
                    {getValueCells(headCell.label, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer sx={{ marginTop: "16px" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalElements}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={(dataKey) => {
            setPage(1);
            setLimit(dataKey);
          }}
        />
      </BlockContainer>
      {popupAddActive && (
        <AddEmployee
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          actionsOnAdd={[...(actionsOnAdd || []), getEmployeesPage]}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteEmployee
          popupActive={popupDeleteActive}
          setPopupActive={setPopupDeleteActive}
          actionsOnDelete={[...(actionsOnDelete || []), getEmployeesPage]}
        />
      )}
      {popupEditActive !== null && (
        <EditEmployee
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          actionsOnEdit={[...(actionsOnEdit || []), getEmployeesPage]}
        />
      )}
      {popupExportActive && (
        <ExportEmployees
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
      {popupPreActivateEmployeesActive !== null && (
        <PreactivateEmployeeConfirmation
          popupActive={popupPreActivateEmployeesActive}
          setPopupActive={setPopupPreActivateEmployeesActive}
          actionsOnSubmit={[getEmployeesPage]}
        />
      )}
    </Paper>
  );
};

export default EmployeeTable;
