import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Comment } from "../../../../../models/comment";
import { CommercialCycle } from "../../../../../models/commercialCycle";
import { Contact } from "../../../../../models/contact";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish, SaleOption } from "../../../../../models/dish";
import { Employee } from "../../../../../models/employee";
import { Ingredient } from "../../../../../models/ingredients";
import { Material } from "../../../../../models/materials";
import { SoldItem } from "../../../../../models/sale";
import { Service, ServiceSaleOption } from "../../../../../models/service";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getCommercialCyclesAction,
  getContactsAction,
} from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import {
  getCommercialCycles,
  getContacts,
  getDishes,
  getEmployees,
  getIngredients,
  getMaterials,
  getServices,
} from "../../../../../store/selectors";
import { getServicesAction } from "../../../../../store/Service/actions";
import {
  getIngredientsAction,
  getMaterialsAction,
} from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  OPPORTUNITY_FORMAT,
  OPPORTUNITY_INCREMENT,
  OPPORTUNITY_NUMBER_OF_DIGITS,
  OPPORTUNITY_TAGS,
  getContactDescription,
  getDishBySaleOptionId,
  getEmployeeDescription,
  getIngredientBySaleOptionId,
  getItemCode,
  getMaterialBySaleOptionId,
  getServiceBySaleOptionId,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../Reusable/Utils";
import Comments from "./Comments";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface AddOpportunityProps {
  popupActive: boolean;
  ingredients: Ingredient[];
  materials: Material[];
  dishes: Dish[];
  services: Service[];
  contacts: Contact[];
  commercialCycles: CommercialCycle[];
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
  getMaterialsAction: typeof getMaterialsAction;
  getDishesAction: typeof getDishesAction;
  getServicesAction: typeof getServicesAction;
  getContactsAction: typeof getContactsAction;
  getEmployeesAction: typeof getEmployeesAction;
  getCommercialCyclesAction: typeof getCommercialCyclesAction;
  actionsOnAdd?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _AddOpportunity: React.FC<AddOpportunityProps> = ({
  popupActive,
  ingredients,
  materials,
  dishes,
  services,
  contacts,
  commercialCycles,
  employees,
  setPopupActive,
  getIngredientsAction,
  getMaterialsAction,
  getDishesAction,
  getServicesAction,
  getContactsAction,
  getEmployeesAction,
  getCommercialCyclesAction,
  actionsOnAdd,
}) => {
  /* TODO */
  const addOpportunity = () => {
    AxiosHttpClient.post("api/v1/weeventpro/opportunities", {
      id: "",
      opportunityNumber: inputOpportunityNumber,
      title: inputTitle,
      startDate: inputStartDate
        ? inputStartDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      endDate: inputEndDate
        ? inputEndDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      contactId: inputContact?.id,
      responsiblesIds: inputResponsibles.map((responsible) => responsible.id),
      tags: inputTags,
      source: inputSource,
      amountHT: Number(inputAmountHT),
      amountFrequency: inputAmountFrequency,
      probability: Number(inputProbability),
      priority: inputPriority,
      description: inputDescription,
      soldItems: inputSoldItems,
      commercialCycleId: inputCommercialCycleId,
      commercialCycleStepId: inputCommercialCycleStepId,
      status: inputStatus,
      lossReason: inputLossReason,
      comments: inputComments.map((comment) => {
        return {
          id: comment.id,
          content: comment.content,
          commentWriterId: comment.commentWriter?.id,
          files: comment.files,
          creationDateTime: comment.creationDateTime,
        };
      }),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addOpportunityDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/opportunity"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        OPPORTUNITY_FORMAT +
        "," +
        OPPORTUNITY_NUMBER_OF_DIGITS +
        "," +
        OPPORTUNITY_INCREMENT +
        "," +
        OPPORTUNITY_TAGS,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(
        OPPORTUNITY_INCREMENT,
        res
      );
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(OPPORTUNITY_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        OPPORTUNITY_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputOpportunityNumber(invoiceNumber);

      const opportunityTags = getUserParameterValueByName(
        OPPORTUNITY_TAGS,
        res
      );
      setSavedTags(opportunityTags ? opportunityTags.split(",") : []);
    });
  };

  const validateAddSoldItemForm = () => {
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
    setAddSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOption === null) {
      setInputSaleOptionNameError(true);
      result = false;
    }
    if (inputSaleOptionQuantity === "") {
      setInputSaleOptionQuantityError(true);
      result = false;
    }
    if (!result) {
      setAddSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditSoldItemForm = () => {
    setInputSaleOptionQuantityEditError(false);
    setEditSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOptionQuantityEdit === "") {
      setInputSaleOptionQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputTitleError(false);
    setInputContactError(false);
    setInputStartDateError(false);
    let result = true;
    if (inputTitle === "") {
      setInputTitleError(true);
      result = false;
    }
    if (!inputContact) {
      setInputContactError(true);
      result = false;
    }
    if (inputStartDate === null) {
      setInputStartDateError(true);
      result = false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addOpportunityDialogContentId",
      });
    }
    return result;
  };

  const resetForm = () => {
    setAddSaleOptionItemFailed("");
    setInputSaleOption(null);
    setInputSaleOptionName("");
    setInputSaleOptionQuantity("");
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
  };

  const resetEditForm = () => {
    setEditSaleOptionItemFailed("");
    setInputSaleOptionQuantityEdit("");
    setInputSaleOptionQuantityEditError(false);
  };

  const [inputDescription, setInputDescription] = useState("");

  const [inputTitle, setInputTitle] = useState("");

  const [inputTitleError, setInputTitleError] = useState(false);

  const [inputContact, setInputContact] = useState<Contact | null>(null);

  const [inputContactError, setInputContactError] = useState(false);

  const [inputContactText, setInputContactText] = useState("");

  const [inputStartDate, setInputStartDate] = useState<Dayjs | null>(dayjs());

  const [inputStartDateError, setInputStartDateError] = useState(false);

  const [inputEndDate, setInputEndDate] = useState<Dayjs | null>(null);

  const [inputResponsibles, setInputResponsibles] = useState<Employee[]>([]);

  const [inputResponsiblesText, setInputResponsiblesText] = useState("");

  const [inputTags, setInputTags] = useState<string[]>([]);

  const [inputTagsText, setInputTagsText] = useState("");

  const [savedTags, setSavedTags] = useState<string[]>([]);

  const [inputSource, setInputSource] = useState("");

  const [inputSourceText, setInputSourceText] = useState("");

  const [inputAmountHT, setInputAmountHT] = useState("");

  const [inputAmountFrequency, setInputAmountFrequency] = useState("once");

  const [inputProbability, setInputProbability] = useState("");

  const [inputPriority, setInputPriority] = useState("Moyenne");

  const [inputCommercialCycle, setInputCommercialCycle] =
    useState<CommercialCycle | null>(null);

  const [inputCommercialCycleId, setInputCommercialCycleId] = useState("");

  const [inputCommercialCycleStepId, setInputCommercialCycleStepId] =
    useState("");

  const [inputLossReason, setInputLossReason] = useState("");

  const [inputOpportunityNumber, setInputOpportunityNumber] = useState("");

  const [inputComments, setInputComments] = useState<Comment[]>([]);

  const [inputSoldItems, setInputSoldItems] = useState<SoldItem[]>([]);

  const [saleOptionsTmp, setSaleOptionsTmp] = useState<
    (SaleOption | ServiceSaleOption)[]
  >([]);

  const [inputSaleOptionName, setInputSaleOptionName] = useState("");

  const [inputSaleOption, setInputSaleOption] = React.useState<
    SaleOption | ServiceSaleOption | null
  >(null);

  const [inputSaleOptionQuantity, setInputSaleOptionQuantity] = useState("");

  const [inputSaleOptionQuantityEdit, setInputSaleOptionQuantityEdit] =
    useState("");

  const [inputSaleOptionNameError, setInputSaleOptionNameError] =
    useState(false);

  const [inputSaleOptionQuantityError, setInputSaleOptionQuantityError] =
    useState(false);

  const [inputStatus, setInputStatus] = useState("En cours");

  const [addSaleOptionItemFailed, setAddSaleOptionItemFailed] = useState("");

  const [
    inputSaleOptionQuantityEditError,
    setInputSaleOptionQuantityEditError,
  ] = useState(false);

  const [editSaleOptionItemFailed, setEditSaleOptionItemFailed] = useState("");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [opportunityItemEditActive, setOpportunityItemEditActive] =
    useState("");

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getTotalHt = () => {
    return inputSoldItems
      .map((soldItem) => Number(soldItem.quantity) * Number(soldItem.priceHT))
      .reduce((total, currentValue) => (total = total + currentValue), 0);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  let removeSaleOptionItem = (index: number) => {
    let newArr = [...inputSoldItems];
    newArr.splice(index, 1);
    setInputSoldItems(newArr);
  };

  const filterContactsOptions = createFilterOptions<Contact | null>({
    stringify: (option) => getContactDescription(option),
    limit: 20,
  });

  const filterEmployeesOptions = createFilterOptions<Employee>({
    stringify: (option) => getEmployeeDescription(option),
    limit: 20,
  });

  const filterSaleOptionOptions = (
    options: (SaleOption | ServiceSaleOption)[],
    { inputValue }
  ) => {
    if (inputValue === null || inputValue === undefined) return [];
    const searchTokens = inputValue.toLowerCase().split(" ");
    return options
      .filter((option) =>
        searchTokens.every((token) => option.name.toLowerCase().includes(token))
      )
      .slice(0, 20);
  };

  useEffect(() => {
    getIngredientsAction("");
    getMaterialsAction("");
    getDishesAction("");
    getServicesAction("");
    getContactsAction("");
    getEmployeesAction("");
    getCommercialCyclesAction("");
    getCustomFields();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getIngredientsAction,
    getMaterialsAction,
    getDishesAction,
    getServicesAction,
    getContactsAction,
  ]);

  useEffect(() => {
    if (commercialCycles && commercialCycles.length > 0)
      setInputCommercialCycleId(commercialCycles[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercialCycles]);

  useEffect(() => {
    let commercialCycleFound =
      commercialCycles.find(
        (commercialCycle) => commercialCycle.id === inputCommercialCycleId
      ) ?? null;
    if (commercialCycleFound !== null) {
      setInputCommercialCycle(commercialCycleFound);
      setInputCommercialCycleStepId(
        commercialCycleFound.steps.length > 0
          ? commercialCycleFound.steps[0].id
          : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCommercialCycleId, commercialCycles]);

  useEffect(() => {
    setInputAmountHT(toFixed2(getTotalHt()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSoldItems]);

  useEffect(() => {
    let saleOptions: (SaleOption | ServiceSaleOption)[] = [];

    for (var ingredient of ingredients) {
      ingredient.saleOptions.forEach((saleOption) =>
        saleOptions.push(saleOption)
      );
    }

    for (var material of materials) {
      material.saleOptions.forEach((saleOption) =>
        saleOptions.push(saleOption)
      );
    }

    for (var dish of dishes) {
      dish.saleOptions.forEach((saleOption) => saleOptions.push(saleOption));
    }

    for (var service of services) {
      service.saleOptions.forEach((saleOption) => saleOptions.push(saleOption));
    }

    if (saleOptions[0] !== undefined) {
      lastSaleOptions.current = saleOptions;
    }

    // Utilisez lastSaleOptions.current comme valeur pour setSaleOptionsTmp
    setSaleOptionsTmp(lastSaleOptions.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, materials, dishes, services]);

  // À l'extérieur du composant, définissez une référence pour stocker la dernière valeur de saleOptions
  const lastSaleOptions = useRef<(SaleOption | ServiceSaleOption)[]>([]);

  useEffect(() => {
    let generatedTitle = "Opportunité avec ";

    if (inputContact !== null)
      generatedTitle += getContactDescription(inputContact);

    setInputTitle(generatedTitle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputContact]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une opportunité"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addOpportunityDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="title"
                label="Titre"
                error={inputTitleError}
                value={inputTitle}
                onChange={(e) => {
                  setInputTitle(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de début"
                  inputFormat="DD/MM/YYYY"
                  value={inputStartDate}
                  onChange={(value) => setInputStartDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputStartDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date d'échéance"
                  inputFormat="DD/MM/YYYY"
                  value={inputEndDate}
                  onChange={(value) => setInputEndDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="contacts"
                filterOptions={filterContactsOptions}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                noOptionsText={"Pas de suggestions"}
                fullWidth
                getOptionLabel={(option) => {
                  return getContactDescription(option);
                }}
                options={contacts}
                value={inputContact}
                onChange={(event, newValue) => {
                  setInputContact(newValue!);
                }}
                inputValue={inputContactText}
                onInputChange={(event, newInputValue) => {
                  setInputContactText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option?.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {getContactDescription(option)}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputContactError}
                    variant="outlined"
                    name="contact"
                    label="Client/Prospect"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="responsibles"
                filterOptions={filterEmployeesOptions}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                multiple
                disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.firstName + " " + option.lastName;
                }}
                options={employees}
                value={inputResponsibles}
                onChange={(event, newValue) => {
                  setInputResponsibles(newValue!);
                }}
                inputValue={inputResponsiblesText}
                onInputChange={(event, newInputValue) => {
                  setInputResponsiblesText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {option.firstName + " " + option.lastName}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="responsibles"
                    label="Responsables"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="tags"
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                filterSelectedOptions
                options={savedTags}
                value={inputTags}
                onChange={(event, newValue) => {
                  setInputTags(newValue!);
                }}
                inputValue={inputTagsText}
                onInputChange={(event, newInputValue) => {
                  setInputTagsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="tags"
                    label="Tags"
                  />
                )}
              />
            </Grid>

            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="source"
                noOptionsText={"Pas de suggestions"}
                fullWidth
                disableClearable
                freeSolo
                options={[
                  "Référence client",
                  "Marketing en ligne",
                  "Marketing hors ligne",
                  "Recommandation",
                  "Partenariat",
                  "Événement professionnel",
                  "Site web",
                  "Contact direct",
                  "Réseaux sociaux",
                ]}
                value={inputSource}
                onChange={(event, newValue) => {
                  setInputSource(newValue!);
                }}
                inputValue={inputSourceText}
                onInputChange={(event, newInputValue) => {
                  setInputSourceText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="source"
                    label="Source"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="amount"
                label="Montant estimé HT"
                value={inputAmountHT}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputAmountHT(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        "& .MuiInputBase-root:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:hover:before": {
                          borderBottom: "none !important",
                        },
                      }}
                    >
                      <Select
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select:focus": {
                            borderBottom: "none",
                            backgroundColor: "white",
                          },
                        }}
                        value={inputAmountFrequency}
                        onChange={(e) =>
                          setInputAmountFrequency(e.target.value)
                        }
                      >
                        <MenuItem key="once" value="once">
                          € Une fois
                        </MenuItem>
                        <MenuItem key="month" value="month">
                          €/Mois
                        </MenuItem>
                        <MenuItem key="year" value="year">
                          €/Année
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="probability"
                label="Probabilité de réussite"
                value={inputProbability}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputProbability(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Priorité</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputPriority}
                  label="Priorité"
                  onChange={(e) => {
                    setInputPriority(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Basse">Basse</MenuItem>
                  <MenuItem value="Moyenne">Moyenne</MenuItem>
                  <MenuItem value="Haute">Haute</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                multiline
                rows={4}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Produits associés
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Sélectionnez les items vendus avec leurs quantités respectives:
            </Text14>
          </Grid>
          {addSaleOptionItemFailed !== "" && (
            <Grid item xs={1} sm={1} md={2}>
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addSaleOptionItemFailed}</Alert>
              </BlockContainer>
            </Grid>
          )}

          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="saleOptions"
              filterOptions={filterSaleOptionOptions}
              options={saleOptionsTmp}
              getOptionLabel={(option) => option.name}
              value={inputSaleOption}
              onChange={(event, newValue) => {
                setInputSaleOption(newValue);
              }}
              inputValue={inputSaleOptionName}
              onInputChange={(event, newInputValue) => {
                setInputSaleOptionName(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                    maxWidth="90px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <Text14 textAlign="left">{option.name}</Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="saleOption"
                  label="Produit"
                  error={inputSaleOptionNameError}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="ingredientQuantity"
              label="Quantité"
              error={inputSaleOptionQuantityError}
              value={inputSaleOptionQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSaleOptionQuantity(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddSoldItemForm()) {
                    resetForm();
                    let item =
                      getDishBySaleOptionId(inputSaleOption!.id, dishes) ||
                      getServiceBySaleOptionId(inputSaleOption!.id, services) ||
                      getIngredientBySaleOptionId(
                        inputSaleOption!.id,
                        ingredients
                      ) ||
                      getMaterialBySaleOptionId(inputSaleOption!.id, materials);
                    if (item) {
                      setInputSoldItems([
                        ...inputSoldItems,
                        {
                          id: uuid(),
                          name: item.name,
                          priceHT: inputSaleOption!.priceHT,
                          tva: inputSaleOption!.tva,
                          quantity: Number(inputSaleOptionQuantity),
                          internalCode: getItemCode(item),
                          imageUrl: inputSaleOption!.imageUrl,
                          dishServiceId: item.id,
                        },
                      ]);
                    }
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="product">Produit</TableCell>
                    <TableCell key="code">Code</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="pu">P.U</TableCell>
                    <TableCell key="pht">Prix H.T</TableCell>
                    <TableCell key="tva">TVA</TableCell>
                    <TableCell key="pttc">Prix TTC</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputSoldItems.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={9}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Veuillez ajouter les produits vendus dans cette
                            opportunité!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputSoldItems.map((soldItem, index) => (
                    <React.Fragment key={"fragment" + soldItem.id}>
                      <TableRow key={soldItem.id}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={soldItem.imageUrl}
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>{soldItem.name}</TableCell>
                        <TableCell>{soldItem.internalCode}</TableCell>
                        <TableCell>{soldItem.quantity}</TableCell>
                        <TableCell>{soldItem.priceHT + " €"}</TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(soldItem.quantity) * Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>{soldItem.tva}</TableCell>
                        <TableCell>
                          {toFixed2(
                            (1 + Number(soldItem.tva) * 0.01) *
                              Number(soldItem.quantity) *
                              Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputSaleOptionQuantityEdit(
                                  toFixed2(soldItem.quantity)
                                );
                                setOpportunityItemEditActive(soldItem.id);
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeSaleOptionItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + soldItem.id}
                        sx={{
                          display:
                            opportunityItemEditActive === soldItem.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={9}>
                          <Collapse
                            orientation="vertical"
                            in={opportunityItemEditActive === soldItem.id}
                          >
                            {opportunityItemEditActive === soldItem.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editSaleOptionItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editSaleOptionItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={2}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="saleOptionQuantity"
                                    label="Quantité"
                                    error={inputSaleOptionQuantityEditError}
                                    value={inputSaleOptionQuantityEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        setInputSaleOptionQuantityEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditSoldItemForm()) {
                                          resetEditForm();
                                          let tmpArray = [...inputSoldItems];
                                          tmpArray[index] = {
                                            id: soldItem.id,
                                            name: soldItem.name,
                                            priceHT: soldItem.priceHT,
                                            tva: soldItem.tva,
                                            quantity: Number(
                                              inputSaleOptionQuantityEdit
                                            ),
                                            internalCode: soldItem.internalCode,
                                            imageUrl: soldItem.imageUrl,
                                            dishServiceId:
                                              soldItem.dishServiceId,
                                          };
                                          setInputSoldItems(tmpArray);
                                          setOpportunityItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetEditForm();
                                        setOpportunityItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Progression de l'opportunité
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="commercialCycle">Cycle commercial</InputLabel>
              <Select
                labelId="commercialCycle"
                id="commercialCycle"
                required
                value={inputCommercialCycleId}
                label="Cycle commercial"
                onChange={(e) => setInputCommercialCycleId(e.target.value)}
              >
                {commercialCycles &&
                  commercialCycles.map((commercialCycle, index) => (
                    //@ts-ignore - necessary to load object into value
                    <MenuItem value={commercialCycle.id} key={index}>
                      {commercialCycle.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="commercialCycleStep">
                Phase du cycle commercial
              </InputLabel>
              <Select
                labelId="commercialCycleStep"
                id="commercialCycleStep"
                required
                value={inputCommercialCycleStepId}
                label="Phase du cycle commercial"
                onChange={(e) => setInputCommercialCycleStepId(e.target.value)}
              >
                {inputCommercialCycle &&
                  inputCommercialCycle.steps.map(
                    (commercialCycleStep, index) => (
                      <MenuItem value={commercialCycleStep.id} key={index}>
                        {commercialCycleStep.name}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Statut</InputLabel>
              <Select
                required
                fullWidth
                value={inputStatus}
                label="Statut"
                onChange={(e) => {
                  setInputStatus(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="En cours">En cours</MenuItem>
                <MenuItem value="Gagnée">Gagnée</MenuItem>
                <MenuItem value="Perdue">Perdue</MenuItem>
                <MenuItem value="En attente">En attente</MenuItem>
                <MenuItem value="Abandonnée">Abandonnée</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {inputStatus === "Perdue" && (
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="lossReason"
                label="Raison de la perte"
                value={inputLossReason}
                onChange={(e) => {
                  setInputLossReason(e.target.value);
                }}
              />
            </Grid>
          )}
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Commentaires
          </Text20>
        </BlockContainer>

        <Comments
          inputComments={inputComments}
          setInputComments={setInputComments}
        />
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addOpportunity();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddOpportunity = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
    materials: getMaterials(state),
    dishes: getDishes(state),
    services: getServices(state),
    contacts: getContacts(state),
    employees: getEmployees(state),
    commercialCycles: getCommercialCycles(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getMaterialsAction: getMaterialsAction,
        getDishesAction: getDishesAction,
        getServicesAction: getServicesAction,
        getContactsAction: getContactsAction,
        getEmployeesAction: getEmployeesAction,
        getCommercialCyclesAction: getCommercialCyclesAction,
      },
      dispatch
    )
)(_AddOpportunity);

export default AddOpportunity;
