import { Divider } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { Task } from "../../../../../../models/task";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { getEmployeeDescription } from "../../../../../Reusable/Utils";

export interface InformationsProps {
  task: Task;
}

const Informations: React.FC<InformationsProps> = ({ task }) => {
  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  return (
    <FlexContainer padding="16px">
      <BlockContainer width="100%">
        <Text16 fontWeight="500">Informations générales</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Numéro de la tâche</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{task.taskNumber}</Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Titre</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.title && task.title !== "" ? task.title : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de début</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.startDateTime
                ? dayjs(task.startDateTime).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Date de fin</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.endDateTime
                ? dayjs(task.endDateTime).format("DD/MM/YYYY")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Responsables</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.responsibles && task.responsibles.length > 0
                ? task.responsibles
                    .map((employee) => getEmployeeDescription(employee))
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Tags</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.tags && task.tags.length > 0 ? task.tags.join(", ") : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Statut</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.status && task.status !== "" ? task.status : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Description</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.description && task.description !== ""
                ? task.description
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Complexité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.complexity && task.complexity !== ""
                ? task.complexity
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Estimation du temps</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.timeEstimation && task.timeEstimation !== ""
                ? task.timeEstimation + " " + task.timeEstimationUnit
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Priorité</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.priority && task.priority !== "" ? task.priority : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Associations</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Opportunités associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.associatedOpportunities &&
              task.associatedOpportunities.length > 0
                ? task.associatedOpportunities
                    .map((opportunity) => opportunity.opportunityNumber)
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Ventes associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.associatedSales && task.associatedSales.length > 0
                ? task.associatedSales.map((sale) => sale.saleNumber).join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Routes associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.associatedRoutes && task.associatedRoutes.length > 0
                ? task.associatedRoutes
                    .map((route) => route.description)
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Réclamations associées</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.associatedComplaints && task.associatedComplaints.length > 0
                ? task.associatedComplaints
                    .map((complaint) => complaint.complaintNumber)
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>

        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Visibilité</Text16>

        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Visible par</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {task.visibleBy && task.visibleBy.length > 0
                ? task.visibleBy
                    .map((visibleByItem) =>
                      getEmployeeDescription(visibleByItem)
                    )
                    .join(", ")
                : "-"}
            </Text14>
          </FlexContainer>
        </FlexContainer>
      </BlockContainer>
    </FlexContainer>
  );
};

export default Informations;
