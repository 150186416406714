import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import { DialogTitle, Divider, Link, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Interaction } from "../../../../../../models/interaction";
import { DialogContentMui } from "../../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import AvatarWithInitials from "../../../../../Reusable/AvatarWithInitials";
import { getParticipantDescription } from "../../../../../Reusable/Utils";

export interface PhysicalMeetingDetailsProps {
  popupActive: Interaction;
}

const PhysicalMeetingDetails: React.FC<PhysicalMeetingDetailsProps> = ({
  popupActive,
}) => {
  return (
    <>
      <DialogTitle id="responsive-dialog-title">
        <FlexContainer textAlign="left" alignItems="center">
          <GroupsTwoToneIcon color="primary" sx={{ marginRight: "8px" }} />
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, lineHeight: "20px" }}
          >
            {popupActive.interactionNumber +
              " (" +
              popupActive.type +
              ")" +
              (popupActive.title !== "" ? " - " + popupActive.title : "")}
          </Typography>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui>
        {popupActive.startDateTime && (
          <FlexContainer>
            <Text14 color="rgb(107, 114, 128)" margin="4px 10px 0 10px">
              {popupActive.startDateTime
                ? "Début: " +
                  dayjs(popupActive.startDateTime).format(
                    "DD/MM/YYYY [à] HH:mm"
                  )
                : ""}
            </Text14>
          </FlexContainer>
        )}
        {popupActive.endDateTime && (
          <FlexContainer>
            <Text14 color="rgb(107, 114, 128)" margin="0 10px 0 10px">
              {popupActive.endDateTime
                ? "Fin: " +
                  dayjs(popupActive.endDateTime).format("DD/MM/YYYY [à] HH:mm")
                : ""}
            </Text14>
          </FlexContainer>
        )}
        {popupActive.location && popupActive.location !== "" && (
          <FlexContainer>
            <Text14 color="rgb(107, 114, 128)" margin="0 10px 8px 10px">
              {"Lieu: " + popupActive.location}
            </Text14>
          </FlexContainer>
        )}
        <FlexContainer>
          <Text14 fontWeight="500" color="rgb(107, 114, 128)">
            Participants
          </Text14>
          <FlexContainer flexDirection={"column"}>
            {[
              ...popupActive.contactsParticipants,
              ...popupActive.employeesParticipants,
            ].map((participant) => (
              <FlexContainer alignItems={"center"} margin={"2px 8px"}>
                <AvatarWithInitials entity={participant} />
                <FlexContainer flexDirection={"column"}>
                  <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                    {getParticipantDescription(participant)}
                  </Text16>
                </FlexContainer>
              </FlexContainer>
            ))}
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        <FlexContainer flexDirection={"column"}>
          <Text16 fontWeight="500">Description</Text16>
          <Text14>{popupActive.description}</Text14>
        </FlexContainer>
        {popupActive.report && popupActive.report !== "" && (
          <>
            <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
            <FlexContainer flexDirection={"column"}>
              <Text16 fontWeight="500">Rapport</Text16>
              <Text14>{popupActive.report}</Text14>
            </FlexContainer>
          </>
        )}
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        {popupActive.associatedOpportunities &&
          popupActive.associatedOpportunities.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Opportunités associées</Text14>
              {popupActive.associatedOpportunities.map((opportunity) => (
                <Link href={"/feature/opportunities/" + opportunity.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {opportunity.opportunityNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedInvoices &&
          popupActive.associatedInvoices.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Factures associées</Text14>
              {popupActive.associatedInvoices.map((invoice) => (
                <Link href={"/feature/invoices/" + invoice.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {invoice.invoiceNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedSales &&
          popupActive.associatedSales.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Ventes associées</Text14>
              {popupActive.associatedSales.map((sale) => (
                <Link href={"/feature/sales/" + sale.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {sale.saleNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedInvoices &&
          popupActive.associatedInvoices.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Factures associées</Text14>
              {popupActive.associatedInvoices.map((invoice) => (
                <Link href={"/feature/invoices/" + invoice.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {invoice.invoiceNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedComplaints &&
          popupActive.associatedComplaints.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Réclamations associées</Text14>
              {popupActive.associatedComplaints.map((complaint) => (
                <Link href={"/feature/complaints/" + complaint.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {complaint.complaintNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedTasks &&
          popupActive.associatedTasks.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Tâches associées</Text14>
              {popupActive.associatedTasks.map((task) => (
                <Link href={"/feature/tasks/" + task.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {task.taskNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
      </DialogContentMui>
    </>
  );
};

export default PhysicalMeetingDetails;
