import { createFilterOptions } from "@mui/core";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MaterialItemQuantity } from "../../../../../../models/materialItemQuantity";
import { Material } from "../../../../../../models/materials";
import { Dispatch, RootState } from "../../../../../../store";
import { getMaterials } from "../../../../../../store/selectors";
import { getMaterialsAction } from "../../../../../../store/Stock/actions";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text16 } from "../../../../../../styles/Text";

export interface EditCustomerOrderArchiveStep4Props {
  materialItemsQuantities: MaterialItemQuantity[];
  setMaterialItemsQuantities: React.Dispatch<
    React.SetStateAction<MaterialItemQuantity[]>
  >;
  materials: Material[];
  getMaterialsAction: typeof getMaterialsAction;
}

const _EditCustomerOrderArchiveStep4: React.FC<
  EditCustomerOrderArchiveStep4Props
> = ({
  materialItemsQuantities,
  setMaterialItemsQuantities,
  materials,
  getMaterialsAction,
}) => {
  const [inputMaterial, setInputMaterial] = useState("");

  const [inputQuantity, setInputQuantity] = useState("");

  const [value, setValue] = useState<Material | null>();

  const [materialTmp, setMaterialTmp] = useState<Material[]>([]);

  let filterMaterials = () => {
    if (materialTmp[0] !== undefined) {
      setMaterialTmp(
        materialTmp.filter(
          (material) =>
            !materialItemsQuantities.some(
              (materialItem) => materialItem.name === material.name
            )
        )
      );
    }
  };

  useEffect(() => {
    getMaterialsAction("");
  }, [getMaterialsAction]);

  useEffect(() => {
    if (materials[0] !== undefined) {
      setMaterialTmp([...materials]);
      filterMaterials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialItemsQuantities, materials]);

  const filterOptions = createFilterOptions<Material>({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  let removeMaterialItemQuantity = (index: number) => {
    let newArr = [...materialItemsQuantities];
    newArr.splice(index, 1);
    setMaterialItemsQuantities(newArr);
  };

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Grid item xs={6}>
      <Text16>Choississez la liste du matériel</Text16>
      <FlexContainer alignItems="center">
        <Autocomplete
          id="Materials"
          ref={ref}
          filterOptions={filterOptions}
          options={materialTmp}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputMaterial}
          onInputChange={(event, newInputValue) => {
            setInputMaterial(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              name="material"
              label="Matériel"
              value={inputMaterial}
              onChange={(e) => setInputMaterial(e.target.value)}
              style={{ width: 230 }}
            />
          )}
        />
        <TextField
          variant="outlined"
          name="quantity"
          label="Quantité"
          type="number"
          value={inputQuantity}
          onChange={(e) => setInputQuantity(e.target.value)}
          style={{ width: 130, margin: "8px" }}
        />
        <Fab
          size="small"
          color="secondary"
          aria-label="Ajouter"
          disabled={
            inputMaterial === "" || inputQuantity === "" || value === null
          }
          onClick={() => {
            setMaterialItemsQuantities([
              ...materialItemsQuantities,
              {
                name: inputMaterial,
                quantity: inputQuantity,
              },
            ]);
            setInputMaterial("");
            setInputQuantity("");
          }}
        >
          <AddIcon />
        </Fab>
      </FlexContainer>

      <TableContainerMui $maxHeight="430px" margin="0">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="material">Matériel</TableCell>
              <TableCell key="quantity">Quantité</TableCell>
              <TableCell key="delete">Supprimer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialItemsQuantities.map((materialItemQuantity, index) => (
              <TableRow key={index}>
                <TableCell>{materialItemsQuantities[index].name}</TableCell>
                <TableCell>
                  {materialItemsQuantities[index].quantity}{" "}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeMaterialItemQuantity(index);
                      setMaterialTmp([...materials]);
                    }}
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
    </Grid>
  );
};

export const EditCustomerOrderArchiveStep4 = connect(
  (state: RootState) => ({
    materials: getMaterials(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getMaterialsAction: getMaterialsAction,
      },
      dispatch
    )
)(_EditCustomerOrderArchiveStep4);

export default EditCustomerOrderArchiveStep4;
