import { createFilterOptions } from "@mui/core";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/styles";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import { Complaint } from "../../../../../../models/complaint";
import { Contact } from "../../../../../../models/contact";
import { Employee } from "../../../../../../models/employee";
import { File } from "../../../../../../models/file";
import { Invoice } from "../../../../../../models/invoice";
import { Opportunity } from "../../../../../../models/opportunity";
import { Quote } from "../../../../../../models/quote";
import { Sale } from "../../../../../../models/sale";
import { Task } from "../../../../../../models/task";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getParticipantDescription,
  trimLongString,
} from "../../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

const Input = styled("input")({
  display: "none",
});

export interface ActivitiesProps {
  inputType: string;
  inputTitle: string;
  inputTitleError: boolean;
  inputStartDate: Dayjs | null;
  inputStartDateError: boolean;
  inputStartTime: Dayjs | null;
  inputFrom: Employee | Contact | null;
  inputFromError: boolean;
  inputTo: (Employee | Contact)[];
  inputToError: boolean;
  inputCC: (Employee | Contact)[];
  inputObject: string;
  inputContent: string;
  inputContentError: boolean;
  inputAssociatedOpportunities: Opportunity[];
  inputAssociatedOpportunitiesText: string;
  inputAssociatedQuotes: Quote[];
  inputAssociatedQuotesText: string;
  inputAssociatedInvoices: Invoice[];
  inputAssociatedInvoicesText: string;
  inputAssociatedSales: Sale[];
  inputAssociatedSalesText: string;
  inputAssociatedComplaints: Complaint[];
  inputAssociatedComplaintsText: string;
  inputAssociatedTasks: Task[];
  inputAssociatedTasksText: string;
  inputFiles: File[];
  opportunities: Opportunity[];
  quotes: Quote[];
  invoices: Invoice[];
  sales: Sale[];
  complaints: Complaint[];
  tasks: Task[];
  employees: Employee[];
  contacts: Contact[];
  setInputType: React.Dispatch<React.SetStateAction<string>>;
  setInputTitle: React.Dispatch<React.SetStateAction<string>>;
  setInputStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputStartTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputFrom: React.Dispatch<React.SetStateAction<Employee | Contact | null>>;
  setInputTo: React.Dispatch<React.SetStateAction<(Employee | Contact)[]>>;
  setInputCC: React.Dispatch<React.SetStateAction<(Employee | Contact)[]>>;
  setInputObject: React.Dispatch<React.SetStateAction<string>>;
  setInputContent: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedOpportunities: React.Dispatch<
    React.SetStateAction<Opportunity[]>
  >;
  setInputAssociatedOpportunitiesText: React.Dispatch<
    React.SetStateAction<string>
  >;
  setInputAssociatedQuotes: React.Dispatch<React.SetStateAction<Quote[]>>;
  setInputAssociatedQuotesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
  setInputAssociatedInvoicesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedSales: React.Dispatch<React.SetStateAction<Sale[]>>;
  setInputAssociatedSalesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedComplaints: React.Dispatch<
    React.SetStateAction<Complaint[]>
  >;
  setInputAssociatedComplaintsText: React.Dispatch<
    React.SetStateAction<string>
  >;
  setInputAssociatedTasks: React.Dispatch<React.SetStateAction<Task[]>>;
  setInputAssociatedTasksText: React.Dispatch<React.SetStateAction<string>>;
  setInputFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const EmailInteraction: React.FC<ActivitiesProps> = ({
  inputType,
  inputTitle,
  inputTitleError,
  inputStartDate,
  inputStartDateError,
  inputStartTime,
  inputFrom,
  inputFromError,
  inputTo,
  inputToError,
  inputCC,
  inputObject,
  inputContent,
  inputContentError,
  inputAssociatedOpportunities,
  inputAssociatedOpportunitiesText,
  inputAssociatedQuotes,
  inputAssociatedQuotesText,
  inputAssociatedInvoices,
  inputAssociatedInvoicesText,
  inputAssociatedSales,
  inputAssociatedSalesText,
  inputAssociatedComplaints,
  inputAssociatedComplaintsText,
  inputAssociatedTasks,
  inputAssociatedTasksText,
  inputFiles,
  opportunities,
  quotes,
  invoices,
  sales,
  complaints,
  tasks,
  employees,
  contacts,
  setInputType,
  setInputTitle,
  setInputStartDate,
  setInputStartTime,
  setInputFrom,
  setInputTo,
  setInputCC,
  setInputObject,
  setInputContent,
  setInputAssociatedOpportunities,
  setInputAssociatedOpportunitiesText,
  setInputAssociatedQuotes,
  setInputAssociatedQuotesText,
  setInputAssociatedInvoices,
  setInputAssociatedInvoicesText,
  setInputAssociatedSales,
  setInputAssociatedSalesText,
  setInputAssociatedComplaints,
  setInputAssociatedComplaintsText,
  setInputAssociatedTasks,
  setInputAssociatedTasksText,
  setInputFiles,
}) => {
  let removeDocument = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  const filterComplaintsOptions = createFilterOptions<Complaint>({
    stringify: (option) => option.complaintNumber + " - " + option.title,
    limit: 20,
  });

  const filterTasksOptions = createFilterOptions<Task>({
    stringify: (option) => option.taskNumber + " - " + option.title,
    limit: 20,
  });

  const filterParticipantsOptions = createFilterOptions<Contact | Employee>({
    stringify: (option) => getParticipantDescription(option),
    limit: 20,
  });

  const filterSalesOptions = createFilterOptions<Sale>({
    stringify: (option) => option.saleNumber,
    limit: 20,
  });

  const filterQuotesOptions = createFilterOptions<Quote>({
    stringify: (option) => option.quoteNumber,
    limit: 20,
  });

  const filterInvoicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
    limit: 20,
  });

  const filterOpportunitiesOptions = createFilterOptions<Opportunity>({
    stringify: (option) => option.opportunityNumber + " - " + option.title,
    limit: 20,
  });

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Informations générales{" "}
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Type de l'interaction
            </InputLabel>
            <Select
              required
              fullWidth
              value={inputType}
              label="Type de l'interaction"
              onChange={(e) => {
                setInputType(
                  typeof e.target.value === "string" ? e.target.value : ""
                );
              }}
            >
              <MenuItem value="Appel téléphonique">Appel téléphonique</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="E-mail">E-mail</MenuItem>
              <MenuItem value="Réunion en personne">
                Réunion en personne
              </MenuItem>
              <MenuItem value="Réunion virtuelle">Réunion virtuelle</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="title"
            label="Titre"
            error={inputTitleError}
            value={inputTitle}
            onChange={(e) => {
              setInputTitle(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date"
              inputFormat="DD/MM/YYYY"
              value={inputStartDate}
              onChange={(value) => setInputStartDate(value!)}
              renderInput={(params) => (
                <TextField fullWidth {...params} error={inputStartDateError} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              ampm={false}
              label="Heure"
              value={inputStartTime}
              onChange={(newValue) => {
                setInputStartTime(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="from"
            filterOptions={filterParticipantsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return getParticipantDescription(option);
            }}
            options={[...employees, ...contacts]}
            value={inputFrom}
            onChange={(event, newValue) => {
              setInputFrom(newValue!);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  height: "100px",
                }}
                {...props}
              >
                <FlexContainer
                  flex="1"
                  justifyContent="center"
                  marginRight="16px"
                >
                  <img
                    style={{
                      maxWidth: "90px",
                      maxHeight: "90px",
                    }}
                    loading="lazy"
                    src={
                      "https://" +
                      process.env.REACT_APP_BUCKET_NAME! +
                      ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                    }
                    alt=""
                  />
                </FlexContainer>
                <FlexContainer flex="4">
                  <Text14 textAlign="left">
                    {getParticipantDescription(option)}
                  </Text14>
                </FlexContainer>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="from"
                error={inputFromError}
                label="De"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="to"
            filterOptions={filterParticipantsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return getParticipantDescription(option);
            }}
            options={[...employees, ...contacts]}
            value={inputTo}
            onChange={(event, newValue) => {
              setInputTo(newValue!);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  height: "100px",
                }}
                {...props}
              >
                <FlexContainer
                  flex="1"
                  justifyContent="center"
                  marginRight="16px"
                >
                  <img
                    style={{
                      maxWidth: "90px",
                      maxHeight: "90px",
                    }}
                    loading="lazy"
                    src={
                      "https://" +
                      process.env.REACT_APP_BUCKET_NAME! +
                      ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                    }
                    alt=""
                  />
                </FlexContainer>
                <FlexContainer flex="4">
                  <Text14 textAlign="left">
                    {getParticipantDescription(option)}
                  </Text14>
                </FlexContainer>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={inputToError}
                name="to"
                label="À"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="cc"
            filterOptions={filterParticipantsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.firstName + " " + option.lastName;
            }}
            options={[...employees, ...contacts]}
            value={inputCC}
            onChange={(event, newValue) => {
              setInputCC(newValue!);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  height: "100px",
                }}
                {...props}
              >
                <FlexContainer
                  flex="1"
                  justifyContent="center"
                  marginRight="16px"
                >
                  <img
                    style={{
                      maxWidth: "90px",
                      maxHeight: "90px",
                    }}
                    loading="lazy"
                    src={
                      "https://" +
                      process.env.REACT_APP_BUCKET_NAME! +
                      ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                    }
                    alt=""
                  />
                </FlexContainer>
                <FlexContainer flex="4">
                  <Text14 textAlign="left">
                    {getParticipantDescription(option)}
                  </Text14>
                </FlexContainer>
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" name="cc" label="CC" />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="object"
            label="Objet"
            value={inputObject}
            onChange={(e) => {
              setInputObject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          {inputFiles.length > 0 && (
            <Grid
              container
              border="2px dashed rgba(0,0,0,0.1)"
              margin={"0 0 8px 0"}
            >
              {inputFiles!.map((justification, index) => (
                <Grid>
                  <FlexContainer
                    elevation={3}
                    margin="8px"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                    $borderRadius="16px"
                  >
                    <FlexContainer margin="0 0 0 8px" alignItems="center">
                      <AttachFileRoundedIcon
                        fontSize="medium"
                        color="primary"
                      />
                      <Text14
                        cursor="pointer"
                        margin="0 4px"
                        color={CustomTheme.palette.secondary.main}
                        textDecorationLine="underline"
                        onClick={(e) => {
                          fetch(justification.url)
                            .then((response) => response.blob())
                            .then((blob) => {
                              const url = window.URL.createObjectURL(blob);
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", justification.name);
                              document.body.appendChild(link);
                              link.click();
                            });
                        }}
                      >
                        {trimLongString(justification.name)}
                      </Text14>
                    </FlexContainer>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        removeDocument(index);
                      }}
                      size="medium"
                      style={{ margin: "0 8px", padding: "4px", zIndex: "1" }}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </FlexContainer>
                </Grid>
              ))}
            </Grid>
          )}
          <FlexContainer>
            <TextField
              fullWidth
              variant="outlined"
              name="content"
              label="Contenu"
              multiline
              minRows={4}
              error={inputContentError}
              value={inputContent}
              onChange={(e) => {
                setInputContent(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <label htmlFor="contained-button-file">
                      <Input
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          filesPreviewHandler(e.target.files);
                        }}
                      />
                      <IconButton component="span">
                        <AttachFileRoundedIcon fontSize="medium" />
                      </IconButton>
                    </label>
                  </>
                ),
              }}
            />
          </FlexContainer>
        </Grid>
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Associations
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedOpportunities"
            filterOptions={filterOpportunitiesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.opportunityNumber + " - " + option.title;
            }}
            options={opportunities}
            value={inputAssociatedOpportunities}
            onChange={(event, newValue) => {
              setInputAssociatedOpportunities(newValue!);
            }}
            inputValue={inputAssociatedOpportunitiesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedOpportunitiesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedOpportunities"
                label="Opportunités associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedQuotes"
            filterOptions={filterQuotesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.quoteNumber;
            }}
            options={quotes}
            value={inputAssociatedQuotes}
            onChange={(event, newValue) => {
              setInputAssociatedQuotes(newValue!);
            }}
            inputValue={inputAssociatedQuotesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedQuotesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedQuote"
                label="Devis associés"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedSales"
            filterOptions={filterSalesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.saleNumber;
            }}
            options={sales}
            value={inputAssociatedSales}
            onChange={(event, newValue) => {
              setInputAssociatedSales(newValue!);
            }}
            inputValue={inputAssociatedSalesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedSalesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedSales"
                label="Ventes associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedInvoices"
            filterOptions={filterInvoicesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.invoiceNumber;
            }}
            options={invoices}
            value={inputAssociatedInvoices}
            onChange={(event, newValue) => {
              setInputAssociatedInvoices(newValue!);
            }}
            inputValue={inputAssociatedInvoicesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedInvoicesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedInvoices"
                label="Factures associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedComplaints"
            filterOptions={filterComplaintsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.complaintNumber + " - " + option.title;
            }}
            options={complaints}
            value={inputAssociatedComplaints}
            onChange={(event, newValue) => {
              setInputAssociatedComplaints(newValue!);
            }}
            inputValue={inputAssociatedComplaintsText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedComplaintsText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedComplaints"
                label="Réclamations associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedTasks"
            filterOptions={filterTasksOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.taskNumber + " - " + option.title;
            }}
            options={tasks}
            value={inputAssociatedTasks}
            onChange={(event, newValue) => {
              setInputAssociatedTasks(newValue!);
            }}
            inputValue={inputAssociatedTasksText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedTasksText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedTasks"
                label="Tâches associées"
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmailInteraction;
