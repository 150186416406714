import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import OpportunityTable from "./OpportunityTable";

export interface OpportunityProps {}

const Opportunity: React.FC<OpportunityProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Opportunités"
        subTitle="Liste des opportunités"
        icon={<HandshakeTwoToneIcon fontSize="large" color="primary" />}
      />
      <OpportunityTable />
    </BlockContainer>
  );
};

export default Opportunity;
