import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import IngredientTable from "./IngredientTable";

export interface IngredientProps {}

const Ingredient: React.FC<IngredientProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Ingrédients"
        subTitle="Liste des ingrédients"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <IngredientTable />
    </BlockContainer>
  );
};

export default Ingredient;
