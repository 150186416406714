import {
  default as Axios,
  default as axios,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export enum HttpErrors {
  NETWORK_ERROR = "NETWORK_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
}

export interface HttpError {
  type: HttpErrors;
  cause: string | null;
}

const statusToError = new Map<number, HttpErrors>();

statusToError.set(400, HttpErrors.BAD_REQUEST);
statusToError.set(403, HttpErrors.FORBIDDEN);
statusToError.set(404, HttpErrors.NOT_FOUND);
statusToError.set(422, HttpErrors.UNPROCESSABLE_ENTITY);

export class AxiosHttpClient {
  static baseUrl: string = process.env.REACT_APP_API_URL!;

  private static refreshingFunc: Promise<any[]> | undefined = undefined;

  private static async renewToken() {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      throw new Error("No refreshToken found in localStorage");
    }

    const response = await axios.post(
      this.buildAbsoluteUrl("api/v1/weeventpro/user/refreshToken"),
      "",
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("refreshToken"),
        },
      }
    );

    const newAccessToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;

    return [newAccessToken, newRefreshToken];
  }

  public static initializeInterceptors() {
    axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        const originalConfig = error.config;
        const token = localStorage.getItem("jwtToken");

        if (!token || error.response.status !== 401) {
          return Promise.reject(error);
        }

        if (error.response.data.message === "Refresh JWT expired") {
          localStorage.removeItem("jwtToken");
          localStorage.removeItem("refreshToken");

          window.location.href = `${window.location.origin}/signin`;
          return Promise.reject(error);
        }

        try {
          if (!AxiosHttpClient.refreshingFunc) {
            AxiosHttpClient.refreshingFunc = AxiosHttpClient.renewToken();
          }

          const [newToken, newRefreshToken] =
            await AxiosHttpClient.refreshingFunc;

          localStorage.setItem("jwtToken", newToken);
          localStorage.setItem("refreshToken", newRefreshToken);
          originalConfig.headers = {
            authorization: "Bearer " + newToken,
          };

          try {
            return await axios.request(originalConfig);
          } catch (innerError: any) {
            if (innerError.response.status === 401) {
              throw innerError;
            }
          }
        } catch (err) {
          localStorage.removeItem("jwtToken");
          localStorage.removeItem("refreshToken");

          window.location.href = `${window.location.origin}/signin`;
        } finally {
          this.refreshingFunc = undefined;
        }
      }
    );
  }

  public static postForNonAuth<T = unknown>(
    url: string,
    body?: any
  ): Promise<T> {
    return axios
      .post<T>(this.buildAbsoluteUrl(url), body)
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static getForNonAuth<T = unknown>(url: string): Promise<T> {
    return axios
      .get<T>(this.buildAbsoluteUrl(url))
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static post<T = unknown>(url: string, body?: any): Promise<T> {
    return axios
      .post<T>(this.buildAbsoluteUrl(url), body, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static postBlob(url: string, data?: any): Promise<any> {
    return Axios({
      url: this.buildAbsoluteUrl(url), //your url
      method: "POST",
      responseType: "blob", // important
      data: data,
      headers: {
        authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static getRefreshToken<T = unknown>(): Promise<T> {
    return axios
      .get<T>(this.buildAbsoluteUrl("api/v1/weeventpro/user/refreshToken"), {
        headers: {
          authorization: "Bearer " + localStorage.getItem("refreshToken"),
        },
      })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static get<T = unknown>(
    url: string,
    params?: any,
    signal?: any
  ): Promise<T> {
    return axios
      .get<T>(this.buildAbsoluteUrl(url), {
        headers: {
          authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
        params: params,
        signal: signal,
      })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static getBlob(url: string): Promise<any> {
    return Axios({
      url: this.buildAbsoluteUrl(url), //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static put<T = unknown>(url: string, body?: any): Promise<T> {
    return axios
      .put<T>(this.buildAbsoluteUrl(url), body, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static putForNonAuth<T = unknown>(
    url: string,
    body?: any
  ): Promise<T> {
    return axios
      .put<T>(this.buildAbsoluteUrl(url), body)
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  public static delete<T = unknown>(url: string, body?: any): Promise<T> {
    return axios
      .delete<T>(this.buildAbsoluteUrl(url), {
        data: body,
        headers: {
          authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(this.parseResponseData)
      .catch(this.parseError);
  }

  private static getHeaderConfig(): AxiosRequestConfig {
    const token = localStorage.getItem("jwtToken");
    var config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json", Authorization: token! },
      responseType: "json",
    };
    return config;
  }

  public static buildAbsoluteUrl = (url: string) =>
    `${AxiosHttpClient.baseUrl}${url}`;

  private static parseResponseData = <T>(response: AxiosResponse<T>) =>
    response.data;

  private static parseError = (err: any) => {
    if (!err.response) {
      throw err;
    }

    const errorType =
      statusToError.get(err.response.status) || HttpErrors.NETWORK_ERROR;

    const parsedError: HttpError = {
      type: errorType,
      cause: err.response.data.message || null,
    };

    throw parsedError;
  };
}
AxiosHttpClient.initializeInterceptors();
