import { BaseMetric, CustomMetric } from "../../models/metric";

export const GET_BASE_METRICS = "GET_BASE_METRICS" as const;
export const GET_BASE_METRICS_SUCCESS = "GET_BASE_METRICS_SUCCESS" as const;
export const GET_BASE_METRICS_FAILURE = "GET_BASE_METRICS_FAILURE" as const;

export const GET_CUSTOM_METRICS = "GET_CUSTOM_METRICS" as const;
export const GET_CUSTOM_METRICS_SUCCESS = "GET_CUSTOM_METRICS_SUCCESS" as const;
export const GET_CUSTOM_METRICS_FAILURE = "GET_CUSTOM_METRICS_FAILURE" as const;

export const getBaseMetricsAction = (
  input: string,
  startDate: string,
  endDate: string
) => ({
  type: GET_BASE_METRICS,
  input: input,
  startDate: startDate,
  endDate: endDate,
});
export type GetBaseMetricsAction = ReturnType<typeof getBaseMetricsAction>;

export const getBaseMetricsActionSuccess = (baseMetrics: BaseMetric[]) => ({
  type: GET_BASE_METRICS_SUCCESS,
  baseMetrics: baseMetrics,
});
export type GetBaseMetricsActionSuccess = ReturnType<
  typeof getBaseMetricsActionSuccess
>;

export const getBaseMetricsActionFailure = () => ({
  type: GET_BASE_METRICS_FAILURE,
});
export type GetBaseMetricsActionFailure = ReturnType<
  typeof getBaseMetricsActionFailure
>;

export const getCustomMetricsAction = (
  input: string,
  startDate: string,
  endDate: string
) => ({
  type: GET_CUSTOM_METRICS,
  input: input,
  startDate: startDate,
  endDate: endDate,
});
export type GetCustomMetricsAction = ReturnType<typeof getCustomMetricsAction>;

export const getCustomMetricsActionSuccess = (
  customMetrics: CustomMetric[]
) => ({
  type: GET_CUSTOM_METRICS_SUCCESS,
  customMetrics: customMetrics,
});
export type GetCustomMetricsActionSuccess = ReturnType<
  typeof getCustomMetricsActionSuccess
>;

export const getCustomMetricsActionFailure = () => ({
  type: GET_CUSTOM_METRICS_FAILURE,
});
export type GetCustomMetricsActionFailure = ReturnType<
  typeof getCustomMetricsActionFailure
>;
