import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface DeleteInteractionProps {
  popupActive: string[];
  setPopupActive: React.Dispatch<React.SetStateAction<string[]>>;
  actionsOnDelete?: (() => void)[];
  redirect?: boolean;
}

const DeleteInteraction: React.FC<DeleteInteractionProps> = (props) => {
  const deleteInteraction = () => {
    AxiosHttpClient.delete("api/v1/weeventpro/interactions/", props.popupActive)
      .then(() => {
        props.setPopupActive([]);
        props.actionsOnDelete?.forEach((action) => action());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Dialog
      open={props.popupActive.length !== 0}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive([]);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer une interaction?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          {props.popupActive.length === 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer cette interaction
            </React.Fragment>
          )}
          {props.popupActive.length > 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer les interactions sélectionnées?
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            if (props.redirect) navigate("/feature/interactions");
            setIsLoading(true);
            deleteInteraction();
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive([])}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteInteraction;
