import { Divider, Grid } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Material } from "../../../../../../models/materials";
import { StorageArea } from "../../../../../../models/storageArea";
import { Supplier } from "../../../../../../models/suppliers";
import { Dispatch, RootState } from "../../../../../../store";
import {
  getStorageAreasAction,
  getSuppliersAction,
} from "../../../../../../store/Stock/actions";
import {
  getStorageAreas,
  getSuppliers,
} from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16, Text36 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getUnitDescription } from "../../../../../Reusable/Utils";
export interface InformationsProps {
  material: Material;
  suppliers: Supplier[];
  storageAreas: StorageArea[];
  getSuppliersAction: typeof getSuppliersAction;
  getStorageAreasAction: typeof getStorageAreasAction;
}

const _Informations: React.FC<InformationsProps> = (props) => {
  useEffect(() => {
    props.getSuppliersAction("");
    props.getStorageAreasAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer padding="16px">
      <BlockContainer width="100%">
        <Text16 fontWeight="500">Informations générales</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Nom</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{props.material?.name}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Catégorie</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{props.material?.category}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">
              Durée d'expiration moyenne
            </Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.averageExpiryDelay !== null
                ? props.material?.averageExpiryDelay +
                  " " +
                  props.material?.averageExpiryDelayUnit
                : ""}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">
              Suggéstion de commande par évènement
            </Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.suggestionPerEvent ? "Oui" : "Non"}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Vous vendez ce produit</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{props.material?.materialForSale ? "Oui" : "Non"}</Text14>
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Unités</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Unités d'achat</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.purchaseUnits
                .map((purchaseUnit) => getUnitDescription(purchaseUnit))
                .join(", ")}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Unités de stockage</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.storageUnits
                .map((storageUnit) => getUnitDescription(storageUnit))
                .join(", ")}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Unités de production</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.productionUnits
                .map((productionUnit) => getUnitDescription(productionUnit))
                .join(", ")}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Alertes de stockage</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">
              Unités de stockage globale
            </Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{props.material?.globalStorageUnit?.name}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Quantité minimale</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.minimalQuantity +
                " " +
                props.material?.globalStorageUnit?.name}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Quantité idéale</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>
              {props.material?.idealQuantity +
                " " +
                props.material?.globalStorageUnit?.name}
            </Text14>
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Lots et espaces de stockages</Text16>
        {props.material?.storageRecords.length === 0 && (
          <FlexContainer>
            <Text14 color="rgba(0,0,0,0.5)" textAlign="center" width="100%">
              Aucun lot créé
            </Text14>
          </FlexContainer>
        )}
        {props.storageAreas.map((storageArea, index) => (
          <Fragment key={index}>
            {props.material?.storageRecords.filter(
              (storageRecord) => storageRecord.storageArea.id === storageArea.id
            ).length !== 0 && (
              <FlexContainer flexDirection="column">
                <Text16 fontWeight="500" color="rgb(107, 114, 128)">
                  {storageArea.name}
                </Text16>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 3 }}
                  columns={{ xs: 1, sm: 1, md: 3 }}
                >
                  {props.material?.storageRecords
                    .filter(
                      (storageRecord) =>
                        storageRecord.storageArea.id === storageArea.id
                    )
                    .map((storageRecord, index) => (
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        display="flex"
                        justifyContent="center"
                        key={index}
                      >
                        <FlexContainer
                          flexDirection="column"
                          margin="16px 0"
                          elevation={3}
                          border="1px solid rgba(0,0,0,0.1)"
                          $borderRadius="10px"
                          alignItems="center"
                          padding="16px 8px"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <BlockContainer alignItems="center">
                            <BlockContainer
                              width="150px"
                              height="150px"
                              border="rgba(0,0,0,0.1) solid 1px"
                              justifyContent="center"
                              position="relative"
                              textAlign="center"
                              backgroundColor="white"
                              margin="auto"
                            >
                              <img
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  left: "0",
                                }}
                                alt=""
                                src={props.material?.imageUrl}
                              />
                            </BlockContainer>
                            <Text16
                              textAlign="center"
                              fontWeight="500"
                              margin="0"
                            >
                              {props.material?.name}
                            </Text16>
                          </BlockContainer>
                          <FlexContainer
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <FlexContainer
                              $borderRadius="10px"
                              backgroundColor={
                                CustomTheme.palette.primary.light
                              }
                              alignSelf="center"
                              padding="16px"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Text36
                                color={CustomTheme.palette.primary.main}
                                margin="4px 10px 0 10px"
                              >
                                {storageRecord.quantity}
                              </Text36>
                              <Text16
                                color={CustomTheme.palette.primary.main}
                                margin="4px 10px 0 10px"
                              >
                                {storageRecord.storageUnit?.name}
                              </Text16>
                            </FlexContainer>
                            {storageRecord.expirationDate !== null && (
                              <FlexContainer
                                width="100%"
                                justifyContent="center"
                              >
                                <Text16 margin="0px 0 4px 10px">D.E:</Text16>
                                <Text16
                                  color={CustomTheme.palette.secondary.main}
                                  fontWeight="500"
                                  margin="0px 10px 4px 4px"
                                >
                                  {dayjs(storageRecord.expirationDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Text16>
                              </FlexContainer>
                            )}
                          </FlexContainer>
                        </FlexContainer>
                      </Grid>
                    ))}
                </Grid>
              </FlexContainer>
            )}
          </Fragment>
        ))}
      </BlockContainer>
    </FlexContainer>
  );
};

export const Informations = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
    storageAreas: getStorageAreas(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
        getStorageAreasAction: getStorageAreasAction,
      },
      dispatch
    )
)(_Informations);

export default _Informations;
