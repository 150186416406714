import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  PurchaseOption,
  StorageRecord,
  Unit,
} from "../../../../../../models/ingredients";
import { StorageArea } from "../../../../../../models/storageArea";
import { Dispatch, RootState } from "../../../../../../store";
import { getStorageAreasAction } from "../../../../../../store/Stock/actions";
import { getStorageAreas } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16, Text24 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { calculateFutureDate } from "../../../../../Reusable/Utils";

export interface StorageRecordsProps {
  inputRefStorageProduct: React.RefObject<HTMLInputElement>;
  inputRefStorageUnit: React.RefObject<HTMLInputElement>;
  inputPurchaseOptions: PurchaseOption[];
  inputStorageRecords: StorageRecord[];
  inputStorageUnits: Unit[];
  handleExpiry: string;
  inputImageUrl: string;
  inputName: string;
  storageAreas: StorageArea[];
  inputAverageExpiryDelay: string;
  inputAverageExpiryDelayUnit: string;
  setInputStorageRecords: React.Dispatch<React.SetStateAction<StorageRecord[]>>;
  setStorageUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStorageUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseOptionAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  getStorageAreasAction: typeof getStorageAreasAction;
}

const _StorageRecords: React.FC<StorageRecordsProps> = (props) => {
  const [inputStorageArea, setInputStorageArea] = useState<StorageArea | null>(
    null
  );

  const [inputStorageAreaNameError, setInputStorageAreaNameError] =
    useState(false);

  const [inputQuantity, setInputQuantity] = useState("");

  const [inputQuantityError, setInputQuantityError] = useState(false);

  const [inputStorageUnit, setInputStorageUnit] = useState<Unit | null>(null);

  const [inputStorageUnitError, setInputStorageUnitError] = useState(false);

  const [inputProduct, setInputProduct] = useState<PurchaseOption | null>(null);

  const [inputProductCodeError, setInputProductCodeError] = useState(false);

  const [storageAreaAddActive, setStorageAreaAddActive] = useState(false);

  const [storageAreaEditActive, setStorageAreaEditActive] = useState("");

  const [inputExpirationDate, setInputExpirationDate] = useState<Dayjs | null>(
    null
  );

  const [selectStorageUnitOpen, setSelectStorageUnitOpen] = useState(false);

  const [selectStorageProductOpen, setSelectStorageProductOpen] =
    useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const [productSelectWidth, setProductSelectWidth] = useState(0);

  const [addFailed, setAddFailed] = useState("");

  const getStorageUnitById = (id: string | undefined) => {
    for (var storageUnit of props.inputStorageUnits) {
      if (storageUnit.id === id) return storageUnit;
    }
    return null;
  };

  const getStorageAreaById = (id: string | undefined) => {
    for (var storageArea of props.storageAreas) {
      if (storageArea.id === id) return storageArea;
    }
    return null;
  };

  const getPurchaseOptionById = (id: string | undefined) => {
    for (var purchaseOption of props.inputPurchaseOptions) {
      if (purchaseOption.id === id) return purchaseOption;
    }
    return null;
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputStorageAreaNameError(false);
    setInputQuantityError(false);
    setInputStorageUnitError(false);
    setInputProductCodeError(false);
  };

  const validateForm = () => {
    setInputProductCodeError(false);
    setInputStorageAreaNameError(false);
    setInputStorageUnitError(false);
    setInputQuantityError(false);
    let result = true;
    if (inputProduct === null) {
      setInputProductCodeError(true);
      result = false;
    }
    if (inputStorageArea === null) {
      setInputStorageAreaNameError(true);
      result = false;
    }
    if (inputStorageUnit === null) {
      setInputStorageUnitError(true);
      result = false;
    }
    if (inputQuantity === "") {
      setInputQuantityError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    if (document.getElementById("storageUnit") !== null)
      setSelectWidth(document.getElementById("storageUnit")!.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("storageUnit")]);

  useEffect(() => {
    if (document.getElementById("storageProduct") !== null)
      setProductSelectWidth(
        document.getElementById("storageProduct")!.clientWidth
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("storageProduct")]);

  useEffect(() => {
    props.getStorageAreasAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getStorageAreasAction]);

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setStorageAreaEditActive("");
            setStorageAreaAddActive(true);
            if (props.inputPurchaseOptions.length !== 0)
              setInputProduct(props.inputPurchaseOptions[0]);
            else setInputProduct(null);
            if (props.storageAreas.length !== 0)
              setInputStorageArea(props.storageAreas[0]);
            else setInputStorageArea(null);
            if (props.inputStorageUnits.length !== 0)
              setInputStorageUnit(props.inputStorageUnits[0]);
            else setInputStorageUnit(null);
            setInputQuantity("");
            if (props.handleExpiry === "Yes") {
              setInputExpirationDate(
                calculateFutureDate(
                  props.inputAverageExpiryDelay,
                  props.inputAverageExpiryDelayUnit
                )
              );
            }
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un lot de stockage
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={storageAreaAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputProductCodeError}
              >
                <InputLabel id="storageUnit">Produit</InputLabel>
                <Select
                  labelId="storageUnit"
                  id="storageUnit"
                  required
                  open={selectStorageProductOpen}
                  onClose={() => setSelectStorageProductOpen(false)}
                  onOpen={() => setSelectStorageProductOpen(true)}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        width: productSelectWidth,
                      },
                      "& .MuiButtonBase-root": {
                        whiteSpace: "normal",
                      },
                    },
                  }}
                  value={(inputProduct || "") as any}
                  renderValue={(selected) => selected.name}
                  label="Produit"
                  onChange={(e) => setInputProduct(e.target.value)}
                >
                  {props.inputPurchaseOptions.length === 0 && (
                    <Link
                      to="offSearch"
                      spy={true}
                      smooth={true}
                      duration={500}
                      containerId={"addIngredientDialogContentId"}
                    >
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => {
                          props.setPurchaseOptionAddActive(true);
                          setTimeout(function () {
                            setSelectStorageProductOpen(false);
                            props.inputRefStorageProduct.current?.focus({
                              preventScroll: true,
                            });
                          }, 100);
                        }}
                      >
                        Aucune option d'achat créée. Cliquez ici pour en créer
                        une!
                      </MenuItem>
                    </Link>
                  )}
                  {props.inputPurchaseOptions.map((purchaseOption, index) => (
                    <MenuItem
                      value={purchaseOption as any}
                      key={index}
                      sx={{ height: "100px" }}
                      disabled={
                        props.handleExpiry !== "Yes" &&
                        inputStorageArea !== null &&
                        props.inputStorageRecords.find(
                          (storageArea) =>
                            storageArea.storageArea.id ===
                              inputStorageArea.id &&
                            storageArea.product?.id === purchaseOption.id
                        ) !== undefined
                      }
                    >
                      <React.Fragment>
                        <FlexContainer
                          flex="1"
                          justifyContent="center"
                          marginRight="16px"
                        >
                          <img
                            style={{
                              maxWidth: "90px",
                              maxHeight: "90px",
                            }}
                            loading="lazy"
                            src={purchaseOption.imageUrl}
                            alt=""
                          />
                        </FlexContainer>
                        <FlexContainer flex="4">
                          <Text14 textAlign="left">
                            {purchaseOption.name}
                          </Text14>
                        </FlexContainer>
                      </React.Fragment>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputStorageAreaNameError}
              >
                <InputLabel id="demo-simple-select-label">
                  Espace de stockage
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="storageProduct"
                  required
                  value={(inputStorageArea || "") as any}
                  label="Espace de stockage"
                  onChange={(e) => {
                    setInputStorageArea(e.target.value);
                  }}
                >
                  {props.storageAreas.map((storageArea, index) => (
                    <MenuItem
                      value={storageArea as any}
                      key={index}
                      disabled={
                        props.handleExpiry !== "Yes" &&
                        inputProduct !== null &&
                        props.inputStorageRecords.find(
                          (storageRecord) =>
                            storageRecord.storageArea.id === storageArea.id &&
                            storageRecord.product?.id === inputProduct?.id
                        ) !== undefined
                      }
                    >
                      {storageArea.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputStorageUnitError}
              >
                <InputLabel id="storageUnit">Unité de stockage</InputLabel>
                <Select
                  labelId="storageUnit"
                  id="storageUnit"
                  required
                  open={selectStorageUnitOpen}
                  onClose={() => setSelectStorageUnitOpen(false)}
                  onOpen={() => setSelectStorageUnitOpen(true)}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        width: selectWidth,
                      },
                      "& .MuiButtonBase-root": {
                        whiteSpace: "normal",
                      },
                    },
                  }}
                  value={(inputStorageUnit || "") as any}
                  label="Unité de stockage"
                  onChange={(e) => setInputStorageUnit(e.target.value)}
                >
                  {props.inputStorageUnits.length === 0 && (
                    <Link
                      to="storageUnitsTopId"
                      spy={true}
                      smooth={true}
                      duration={500}
                      containerId={"addIngredientDialogContentId"}
                    >
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => {
                          props.setStorageUnitAddActive(true);
                          setTimeout(function () {
                            setSelectStorageUnitOpen(false);
                            props.inputRefStorageUnit.current?.focus({
                              preventScroll: true,
                            });
                          }, 100);
                          setTimeout(function () {
                            props.setStorageUnitOpen(true);
                          }, 500);
                        }}
                      >
                        Aucune unité de stockage créée. Cliquez ici pour en
                        créer une!
                      </MenuItem>
                    </Link>
                  )}
                  {props.inputStorageUnits.map((storageUnit, index) => (
                    <MenuItem value={storageUnit as any} key={index}>
                      {storageUnit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="quantity"
                label="Quantité"
                error={inputQuantityError}
                value={inputQuantity}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputQuantity(e.target.value);
                  }
                }}
              />
            </Grid>
            {props.handleExpiry === "Yes" && (
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date de péremption"
                    inputFormat="DD/MM/YYYY"
                    value={inputExpirationDate}
                    onChange={(value) => setInputExpirationDate(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputStorageRecords([
                  ...props.inputStorageRecords,
                  {
                    id: uuid(),
                    storageArea: inputStorageArea!,
                    product: inputProduct!,
                    storageUnit: inputStorageUnit,
                    quantity: Number(inputQuantity),
                    expirationDate: inputExpirationDate,
                  },
                ]);
                setStorageAreaAddActive(false);
                setInputStorageArea(null);
                setInputQuantity("");
                setInputStorageUnit(null);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setStorageAreaAddActive(false);
              setInputStorageArea(null);
              setInputQuantity("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.storageAreas.map((storageArea, index) => (
        <FlexContainer flexDirection="column" key={index}>
          {props.inputStorageRecords.filter(
            (storageRecord) => storageRecord.storageArea.id === storageArea.id
          ).length !== 0 && (
            <FlexContainer alignItems={"center"} margin={"8px 0"}>
              <WarehouseIcon fontSize="small" color="primary" />
              <Text16>{storageArea.name}</Text16>
            </FlexContainer>
          )}
          {props.inputStorageRecords.map(
            (storageRecord, index) =>
              storageRecord.storageArea.id === storageArea.id && (
                <React.Fragment key={index}>
                  <FlexContainer
                    margin="8px 0"
                    elevation={3}
                    $borderRadius="10px"
                    border="1px solid rgba(0,0,0,0.1)"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="16px"
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <FlexContainer>
                      <BlockContainer
                        width="100px"
                        height="100px"
                        border="rgba(0,0,0,0.1) solid 1px"
                        justifyContent="center"
                        position="relative"
                        textAlign="center"
                        backgroundColor="white"
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            margin: "auto",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                          }}
                          alt=""
                          src={
                            storageRecord.product?.imageUrl === ""
                              ? "https://" +
                                process.env.REACT_APP_BUCKET_NAME! +
                                ".s3.eu-west-3.amazonaws.com/DefaultImages/default-ingredient.svg"
                              : storageRecord.product?.imageUrl
                          }
                        />
                      </BlockContainer>
                      <FlexContainer
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Text16 fontWeight="600">
                          {storageRecord.product.name}
                        </Text16>
                        <FlexContainer alignItems={"center"}>
                          <Text24
                            margin="4px 0 0 10px"
                            color={CustomTheme.palette.primary.main}
                            fontWeight="500"
                          >
                            {storageRecord.quantity}
                          </Text24>
                          <Text16
                            margin="4px 10px 0 0"
                            color={CustomTheme.palette.primary.main}
                            fontWeight="500"
                          >
                            {" " + storageRecord.storageUnit?.name}
                          </Text16>
                        </FlexContainer>
                        {storageRecord.expirationDate !== null &&
                          props.handleExpiry === "Yes" && (
                            <FlexContainer>
                              <Text14 margin="0px 0 4px 10px">{"D.E: "}</Text14>
                              <Text14
                                margin="0px 10px 4px 0"
                                color={CustomTheme.palette.secondary.main}
                                fontWeight="500"
                              >
                                {dayjs(storageRecord.expirationDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </Text14>
                            </FlexContainer>
                          )}
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setStorageAreaEditActive(storageRecord.id);
                          setStorageAreaAddActive(false);
                          setInputProduct(
                            getPurchaseOptionById(storageRecord.product.id)
                          );
                          setInputStorageArea(
                            getStorageAreaById(storageRecord.storageArea.id)
                          );
                          setInputStorageUnit(
                            getStorageUnitById(storageRecord.storageUnit?.id)
                          );
                          setInputQuantity(storageRecord.quantity.toString());
                          setInputExpirationDate(storageRecord.expirationDate);
                          resetValidation();
                        }}
                        size="large"
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          let newArr = [...props.inputStorageRecords];
                          newArr.splice(index, 1);
                          props.setInputStorageRecords(newArr);
                        }}
                        size="large"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </FlexContainer>
                  </FlexContainer>
                  {/*Part Edit option*/}
                  <Collapse
                    orientation="vertical"
                    in={storageAreaEditActive === storageRecord.id}
                  >
                    {addFailed !== "" && (
                      <BlockContainer margin="0 0 8px 0">
                        <Alert severity="error">{addFailed}</Alert>
                      </BlockContainer>
                    )}
                    <FlexContainer>
                      <Grid
                        container
                        spacing={{ xs: 1, sm: 1, md: 2 }}
                        columns={{ xs: 1, sm: 1, md: 2 }}
                      >
                        <Grid item xs={1} sm={1} md={2}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={inputProductCodeError}
                          >
                            <InputLabel id="storageUnit">Produit</InputLabel>
                            <Select
                              labelId="storageUnit"
                              id="storageUnit"
                              required
                              value={(inputProduct || "") as any}
                              renderValue={(selected) => selected.name}
                              label="Produit"
                              onChange={(e) => setInputProduct(e.target.value)}
                            >
                              {props.inputPurchaseOptions.map(
                                (purchaseOption, index) => (
                                  <MenuItem
                                    value={purchaseOption as any}
                                    key={index}
                                    disabled={
                                      props.handleExpiry !== "Yes" &&
                                      inputStorageArea !== null &&
                                      props.inputStorageRecords
                                        .filter(
                                          (storageRecordTofilter) =>
                                            storageRecord.id !==
                                            storageRecordTofilter.id
                                        )
                                        .find(
                                          (storageArea) =>
                                            storageArea.storageArea ===
                                              inputStorageArea &&
                                            storageArea.product?.id ===
                                              purchaseOption.id
                                        ) !== undefined
                                    }
                                  >
                                    <React.Fragment>
                                      <FlexContainer
                                        flex="1"
                                        justifyContent="center"
                                        marginRight="16px"
                                      >
                                        <img
                                          style={{
                                            maxWidth: "90px",
                                            maxHeight: "90px",
                                          }}
                                          loading="lazy"
                                          src={purchaseOption.imageUrl}
                                          alt=""
                                        />
                                      </FlexContainer>
                                      <FlexContainer flex="4">
                                        <Text14 textAlign="left">
                                          {purchaseOption.name}
                                        </Text14>
                                      </FlexContainer>
                                    </React.Fragment>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={inputStorageAreaNameError}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Espace de stockage
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={(inputStorageArea || "") as any}
                              label="Espace de stockage"
                              onChange={(e) => {
                                setInputStorageArea(e.target.value);
                              }}
                            >
                              {props.storageAreas.map((storageArea, index) => (
                                <MenuItem
                                  value={storageArea as any}
                                  key={index}
                                  disabled={
                                    props.handleExpiry !== "Yes" &&
                                    inputProduct !== null &&
                                    props.inputStorageRecords
                                      .filter(
                                        (storageRecordTofilter) =>
                                          storageRecord.id !==
                                          storageRecordTofilter.id
                                      )
                                      .find(
                                        (storageRecord) =>
                                          storageRecord.storageArea.id ===
                                            storageArea.id &&
                                          storageRecord.product === inputProduct
                                      ) !== undefined
                                  }
                                >
                                  {storageArea.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={inputStorageUnitError}
                          >
                            <InputLabel id="storageUnit">
                              Unité de stockage
                            </InputLabel>
                            <Select
                              labelId="storageUnit"
                              id="storageUnit"
                              required
                              value={(inputStorageUnit || "") as any}
                              label="Unité de stockage"
                              onChange={(e) =>
                                setInputStorageUnit(e.target.value)
                              }
                            >
                              {props.inputStorageUnits.map(
                                (storageUnit, index) => (
                                  <MenuItem
                                    value={storageUnit as any}
                                    key={index}
                                  >
                                    {storageUnit.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="quantity"
                            label="Quantité"
                            error={inputQuantityError}
                            value={inputQuantity}
                            onChange={(e) => {
                              if (
                                /^\d*\.?\d*$/.test(e.target.value) ||
                                e.target.value === ""
                              ) {
                                setInputQuantity(e.target.value);
                              }
                            }}
                          />
                        </Grid>
                        {props.handleExpiry === "Yes" && (
                          <Grid item xs={1} sm={1} md={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                label="Date de péremption"
                                inputFormat="DD/MM/YYYY"
                                value={inputExpirationDate}
                                onChange={(value) =>
                                  setInputExpirationDate(value)
                                }
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                      </Grid>
                    </FlexContainer>
                    <FlexContainer justifyContent="center">
                      <ButtonMui
                        margin="16px 4px 0 0"
                        onClick={() => {
                          if (validateForm()) {
                            let tmpArray = [...props.inputStorageRecords];
                            tmpArray[index] = {
                              id: storageRecord.id,
                              storageArea: inputStorageArea!,
                              product: inputProduct!,
                              storageUnit: inputStorageUnit,
                              quantity: Number(inputQuantity),
                              expirationDate: inputExpirationDate,
                            };
                            props.setInputStorageRecords(tmpArray);

                            setInputProduct(null);
                            setInputStorageArea(null);
                            setInputQuantity("");
                            setInputStorageUnit(null);
                            setStorageAreaEditActive("");
                            resetValidation();
                          }
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                      >
                        Modifier
                      </ButtonMui>
                      <ButtonMui
                        margin="16px 0 0 4px"
                        onClick={() => {
                          setInputStorageArea(null);
                          setInputQuantity("");
                          setStorageAreaEditActive("");
                          resetValidation();
                        }}
                        color="primary"
                        variant="outlined"
                        size="large"
                      >
                        Annuler
                      </ButtonMui>
                    </FlexContainer>
                  </Collapse>
                </React.Fragment>
              )
          )}
        </FlexContainer>
      ))}
    </React.Fragment>
  );
};

export const StorageRecords = connect(
  (state: RootState) => ({
    storageAreas: getStorageAreas(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getStorageAreasAction: getStorageAreasAction,
      },
      dispatch
    )
)(_StorageRecords);

export default StorageRecords;
