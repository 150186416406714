import { Ingredient } from "../../models/ingredients";
import { Material } from "../../models/materials";
import { StorageArea } from "../../models/storageArea";
import { Supplier } from "../../models/suppliers";

export const GET_INGREDIENTS = "GET_INGREDIENTS" as const;
export const GET_INGREDIENTS_SUCCESS = "GET_INGREDIENTS_SUCCESS" as const;
export const GET_INGREDIENTS_FAILURE = "GET_INGREDIENTS_FAILURE" as const;
export const ADD_INGREDIENT = "ADD_INGREDIENT" as const;

export const GET_MATERIALS = "GET_MATERIALS" as const;
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS" as const;
export const GET_MATERIALS_FAILURE = "GET_MATERIALS_FAILURE" as const;

export const GET_STORAGE_AREAS = "GET_STORAGE_AREAS" as const;
export const GET_STORAGE_AREAS_SUCCESS = "GET_STORAGE_AREAS_SUCCESS" as const;
export const GET_STORAGE_AREAS_FAILURE = "GET_STORAGE_AREAS_FAILURE" as const;

export const GET_SUPPLIERS = "GET_SUPPLIERS" as const;
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS" as const;
export const GET_SUPPLIERS_FAILURE = "GET_SUPPLIERS_FAILURE" as const;

export const getIngredientsAction = (criteriaList?: string) => ({
  type: GET_INGREDIENTS,
  criteriaList: criteriaList,
});
export type GetIngredientsAction = ReturnType<typeof getIngredientsAction>;

export const getIngredientsActionSuccess = (ingredients: Ingredient[]) => ({
  type: GET_INGREDIENTS_SUCCESS,
  ingredients: ingredients,
});
export type GetIngredientsActionSuccess = ReturnType<
  typeof getIngredientsActionSuccess
>;

export const getIngredientsActionFailure = () => ({
  type: GET_INGREDIENTS_FAILURE,
});
export type GetIngredientsActionFailure = ReturnType<
  typeof getIngredientsActionFailure
>;

export const addIngredientAction = () => ({
  type: ADD_INGREDIENT,
});
export type AddIngredientAction = ReturnType<typeof addIngredientAction>;

export const getMaterialsAction = (criteriaList?: string) => ({
  type: GET_MATERIALS,
  criteriaList: criteriaList,
});
export type GetMaterialsAction = ReturnType<typeof getMaterialsAction>;

export const getMaterialsActionSuccess = (materials: Material[]) => ({
  type: GET_MATERIALS_SUCCESS,
  materials: materials,
});
export type GetMaterialsActionSuccess = ReturnType<
  typeof getMaterialsActionSuccess
>;

export const getMaterialsActionFailure = () => ({
  type: GET_MATERIALS_FAILURE,
});
export type GetMaterialsActionFailure = ReturnType<
  typeof getMaterialsActionFailure
>;

export const getStorageAreasAction = (criteriaList?: string) => ({
  type: GET_STORAGE_AREAS,
  criteriaList: criteriaList,
});
export type GetStorageAreasAction = ReturnType<typeof getStorageAreasAction>;

export const getStorageAreasActionSuccess = (storageAreas: StorageArea[]) => ({
  type: GET_STORAGE_AREAS_SUCCESS,
  storageAreas: storageAreas,
});
export type GetStorageAreasActionSuccess = ReturnType<
  typeof getStorageAreasActionSuccess
>;

export const getStorageAreasActionFailure = () => ({
  type: GET_STORAGE_AREAS_FAILURE,
});
export type GetStorageAreasActionFailure = ReturnType<
  typeof getStorageAreasActionFailure
>;

export const getSuppliersAction = (criteriaList?: string) => ({
  type: GET_SUPPLIERS,
  criteriaList: criteriaList,
});
export type GetSuppliersAction = ReturnType<typeof getSuppliersAction>;

export const getSuppliersActionSuccess = (suppliers: Supplier[]) => ({
  type: GET_SUPPLIERS_SUCCESS,
  suppliers: suppliers,
});
export type GetSuppliersActionSuccess = ReturnType<
  typeof getSuppliersActionSuccess
>;

export const getSuppliersActionFailure = () => ({
  type: GET_SUPPLIERS_FAILURE,
});
export type GetSuppliersActionFailure = ReturnType<
  typeof getSuppliersActionFailure
>;
