import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CartItem, SupplierCartItem } from "../../../../models/cartItem";
import { Ingredient, PurchaseOption } from "../../../../models/ingredients";
import { Material } from "../../../../models/materials";
import { AxiosHttpClient } from "../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../store";
import { getCartItemsAction } from "../../../../store/Cart/actions";
import {
  getIngredientsAction,
  getMaterialsAction,
} from "../../../../store/Stock/actions";
import {
  getCartItems,
  getIngredients,
  getMaterials,
} from "../../../../store/selectors";
import { BlockContainer } from "../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Image } from "../../../../styles/Image";
import { Text12, Text14, Text16 } from "../../../../styles/Text";
import { CustomTheme } from "../../../../styles/Theme";
import AutocompleteNotClickable from "../../../Reusable/AutocompleteNotClickable";
import {
  getExpectedDeliveryDate,
  getItemObject,
  toFixed2,
} from "../../../Reusable/Utils";
import SubmitOrder from "./SubmitOrder";

export interface CartProps {
  popupActive: boolean;
  ingredients: Ingredient[];
  materials: Material[];
  cartItems: CartItem[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
  getMaterialsAction: typeof getMaterialsAction;
  getCartItemsAction: typeof getCartItemsAction;
}

const _Cart: React.FC<CartProps> = ({
  popupActive,
  cartItems,
  ingredients,
  materials,
  setPopupActive,
  getIngredientsAction,
  getMaterialsAction,
  getCartItemsAction,
}) => {
  const addItemToCart = (cartItem: any) => {
    AxiosHttpClient.post("api/v1/weeventpro/cart", cartItem)
      .then(() => {
        getCartItemsAction();
      })
      .catch((err) => {});
  };

  const emptyCartItems = () => {
    AxiosHttpClient.delete("api/v1/weeventpro/cart/all")
      .then(() => {
        getCartItemsAction();
        //setIsLoading(false);
      })
      .catch((err) => {
        //setIsLoading(false);
      });
  };

  const getPurchasableItemQuantity = (id: string) => {
    // Chercher dans la liste des cartItems la quantité du purchaseOption avec l'ID spécifié
    const cartItem = cartItems.find(
      (item) =>
        (item.purchaseOption?.id || item.materialPurchaseOption?.id) === id
    );
    return cartItem ? cartItem.quantity : 0; // Retourner la quantité ou 0 si non trouvée
  };

  const [inputPurchaseOptionText, setInputPurchaseOptionText] = useState("");

  const [purchaseOptions, setPurchaseOptions] = useState<PurchaseOption[]>([]);

  const [suppliersCartItems, setSuppliersCartItems] = useState<
    SupplierCartItem[]
  >([]);

  const [expanded, setExpanded] = useState("");

  const [popupSubmitOrderActive, setPopupSubmitOrderActive] =
    useState<SupplierCartItem | null>(null);

  //const [addFailed, SetaddFailed] = useState("");

  function convertToSupplierCartItems(
    cartItems: CartItem[]
  ): SupplierCartItem[] {
    // Créer une carte pour regrouper les cartItems par fournisseur
    const supplierItemsMap = new Map<string, CartItem[]>();

    // Parcourir chaque cartItem et les regrouper par fournisseur
    cartItems.forEach((cartItem) => {
      const supplierId = getItemObject(cartItem).supplier.id;
      if (supplierId) {
        if (supplierItemsMap.has(supplierId)) {
          supplierItemsMap.get(supplierId)?.push(cartItem);
        } else {
          supplierItemsMap.set(supplierId, [cartItem]);
        }
      }
    });

    // Convertir la carte en une liste de SupplierCartItem
    const supplierCartItems: SupplierCartItem[] = [];
    supplierItemsMap.forEach((cartItems, supplierId) => {
      const supplierCartItem: SupplierCartItem = {
        supplier: getItemObject(cartItems[0]).supplier, // Utiliser le fournisseur du premier cartItem
        items: cartItems,
      };
      supplierCartItems.push(supplierCartItem);
    });

    return supplierCartItems;
  }

  useEffect(() => {
    let purchaseOptions: PurchaseOption[] = [];

    for (var ingredient of ingredients) {
      ingredient.purchaseOptions.forEach((purchaseOption) =>
        purchaseOptions.push(purchaseOption)
      );
    }

    for (var material of materials) {
      material.purchaseOptions.forEach((purchaseOption) =>
        purchaseOptions.push(purchaseOption)
      );
    }

    if (purchaseOptions[0]) {
      lastPurchaseOptions.current = purchaseOptions;
    }

    // Utilisez lastSaleOptions.current comme valeur pour setSaleOptionsTmp
    setPurchaseOptions(lastPurchaseOptions.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, materials]);

  // À l'extérieur du composant, définissez une référence pour stocker la dernière valeur de saleOptions
  const lastPurchaseOptions = useRef<PurchaseOption[]>([]);

  const isIngredientPurchaseOption = (purchaseOptionId: string) => {
    for (const ingredient of ingredients) {
      if (ingredient.purchaseOptions.some((po) => po.id === purchaseOptionId)) {
        return true; // Le purchaseOptionId appartient à un ingrédient
      }
    }
    return false; // Le purchaseOptionId n'appartient pas à un ingrédient
  };

  useEffect(() => {
    getIngredientsAction();
    getMaterialsAction();
    getCartItemsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIngredientsAction, getCartItemsAction]);

  useEffect(() => {
    let calculatedSuppliersCartItems = convertToSupplierCartItems(cartItems);
    if (calculatedSuppliersCartItems.length > 0) {
      setSuppliersCartItems(calculatedSuppliersCartItems);
      if (expanded === "")
        setExpanded(calculatedSuppliersCartItems[0].supplier.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  const filterPurchaseOptions = (options: PurchaseOption[]) => {
    if (!inputPurchaseOptionText) return [];
    const searchTokens = inputPurchaseOptionText.toLowerCase().split(" ");
    return options
      .filter((option) =>
        searchTokens.every((token) => option.name.toLowerCase().includes(token))
      )
      .slice(0, 20);
  };

  return (
    <Drawer
      open={popupActive}
      onClose={(event, reason) => {
        setPopupActive(false);
      }}
      anchor="right"
      PaperProps={{
        sx: {
          width: "500px",
          maxWidth: "100vw",
        },
      }}
    >
      <BlockContainer margin={"24px 16px"}>
        <AutocompleteNotClickable
          inputValue={inputPurchaseOptionText}
          name="purchaseOptionSearch"
          label="Rechercher des produits"
          options={filterPurchaseOptions(purchaseOptions)}
          onChange={(e) => {
            setInputPurchaseOptionText(e.target.value);
          }}
          getOptionLabel={(purchasableItem: PurchaseOption) => (
            <FlexContainer width="100%" alignItems="center">
              <FlexContainer flex={"1"}>
                <BlockContainer
                  width="80px"
                  height="80px"
                  border="rgba(0,0,0,0.1) solid 1px"
                  justifyContent="center"
                  position="relative"
                  textAlign="center"
                  backgroundColor="white"
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      margin: "auto",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                    }}
                    alt=""
                    src={purchasableItem.imageUrl}
                  />
                </BlockContainer>
                <FlexContainer flex={"1"}>
                  <Text14 textAlign="left">{purchasableItem.name}</Text14>
                </FlexContainer>
              </FlexContainer>
              {getPurchasableItemQuantity(purchasableItem.id) === 0 && (
                <FlexContainer>
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      addItemToCart({
                        purchaseOptionId: isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        materialPurchaseOptionId: !isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        quantity: 1,
                        ingredientMaterialId: purchasableItem.parentId,
                      });
                    }}
                    sx={{ minWidth: "unset" }}
                  >
                    <AddShoppingCartIcon fontSize="medium" />
                  </ButtonMui>
                </FlexContainer>
              )}
              {getPurchasableItemQuantity(purchasableItem.id) > 0 && (
                <FlexContainer alignItems="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      addItemToCart({
                        purchaseOptionId: isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        materialPurchaseOptionId: !isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        quantity:
                          getPurchasableItemQuantity(purchasableItem.id) - 1,
                        ingredientMaterialId: purchasableItem.parentId,
                      });
                    }}
                  >
                    <RemoveIcon fontSize="medium" />
                  </IconButton>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="quantity"
                    label=""
                    value={getPurchasableItemQuantity(purchasableItem.id)}
                    onChange={(e) => {
                      addItemToCart({
                        purchaseOptionId: isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        materialPurchaseOptionId: !isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        quantity: e.target.value,
                        ingredientMaterialId: purchasableItem.parentId,
                      });
                    }}
                    size="small"
                    sx={{
                      width: "40px",
                      margin: "0 4px",
                    }}
                    inputProps={{
                      sx: {
                        padding: "8.5px 2px",
                        textAlign: "center",
                      },
                    }}
                  />
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      addItemToCart({
                        purchaseOptionId: isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        materialPurchaseOptionId: !isIngredientPurchaseOption(
                          purchasableItem.id
                        )
                          ? purchasableItem.id
                          : null,
                        quantity:
                          getPurchasableItemQuantity(purchasableItem.id) + 1,
                        ingredientMaterialId: purchasableItem.parentId,
                      });
                    }}
                  >
                    <AddIcon fontSize="medium" />
                  </IconButton>
                </FlexContainer>
              )}
            </FlexContainer>
          )}
          InputProps={{
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: "16px" }}>
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </BlockContainer>
      <DialogContentMui style={{ position: "relative" }} dividers>
        {cartItems.length === 0 && (
          <FlexContainer
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              height="180px"
              width="180px"
              background={
                "url(https://" +
                process.env.REACT_APP_BUCKET_NAME! +
                ".s3.eu-west-3.amazonaws.com/icons/cart1.png)"
              }
            />
            <Text16>Votre panier est vide</Text16>
          </FlexContainer>
        )}

        {cartItems.length !== 0 && (
          <FlexContainer flexDirection="column">
            {suppliersCartItems.map((supplierCartItems, index) => (
              <FlexContainer
                width={"100%"}
                margin={"16px 0"}
                flexDirection="column"
              >
                <Accordion
                  expanded={expanded === supplierCartItems.supplier.id}
                  onChange={() =>
                    setExpanded(
                      supplierCartItems.supplier.id === expanded
                        ? "none"
                        : supplierCartItems.supplier.id
                    )
                  }
                  sx={{
                    "& .MuiAccordionSummary-root": {
                      alignItems: "baseline",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <BlockContainer width={"100%"}>
                      <FlexContainer
                        flex={"1"}
                        alignItems={"center"}
                        marginBottom={"16px"}
                      >
                        <BlockContainer
                          width="30px"
                          height="30px"
                          border="rgba(0,0,0,0.1) solid 1px"
                          justifyContent="center"
                          position="relative"
                          textAlign="center"
                          backgroundColor="white"
                        >
                          <img
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              margin: "auto",
                              position: "absolute",
                              top: "0",
                              right: "0",
                              bottom: "0",
                              left: "0",
                            }}
                            alt=""
                            src={supplierCartItems.supplier.imageUrl}
                          />
                        </BlockContainer>
                        <FlexContainer flex={"1"} flexDirection={"column"}>
                          <Text16 textAlign="left" fontWeight="500">
                            {supplierCartItems.supplier.name +
                              " (" +
                              supplierCartItems.items.length +
                              " produits)"}
                          </Text16>
                          {supplierCartItems.supplier.openWeekDays.length >
                            0 && (
                            <Text12 textAlign="left" margin="0 0 0 10px">
                              {"Livraison prévue: " +
                                getExpectedDeliveryDate(
                                  supplierCartItems.supplier
                                )}
                            </Text12>
                          )}
                        </FlexContainer>
                      </FlexContainer>
                      {expanded !== supplierCartItems.supplier.id && (
                        <>
                          <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
                            {supplierCartItems.items.map((item, index) => (
                              <>
                                {index === 3 && (
                                  <Grid
                                    item
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <Text14 margin="4px 0">
                                      {"+" +
                                        (supplierCartItems.items.length - 3)}
                                    </Text14>
                                  </Grid>
                                )}
                                {index < 3 && (
                                  <Grid
                                    item
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <BlockContainer
                                      width="40px"
                                      height="40px"
                                      border="rgba(0,0,0,0.1) solid 1px"
                                      justifyContent="center"
                                      position="relative"
                                      textAlign="center"
                                      backgroundColor="white"
                                    >
                                      <img
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          margin: "auto",
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          bottom: "0",
                                          left: "0",
                                        }}
                                        alt=""
                                        src={getItemObject(item).imageUrl}
                                      />
                                    </BlockContainer>
                                  </Grid>
                                )}
                              </>
                            ))}
                          </Grid>
                          <FlexContainer
                            width={"100%"}
                            justifyContent={"center"}
                            margin={"24px 0 0 0"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <ButtonMui
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setPopupSubmitOrderActive(supplierCartItems);
                              }}
                              disabled={
                                supplierCartItems.items.reduce(
                                  (total, item) => {
                                    return (
                                      total +
                                      getItemObject(item).priceTTC *
                                        item.quantity
                                    );
                                  },
                                  0
                                ) <
                                Number(supplierCartItems.supplier.minimumOrder)
                              }
                              color="primary"
                              variant="contained"
                              size="large"
                            >
                              {"Commander (Total: " +
                                toFixed2(
                                  supplierCartItems.items.reduce(
                                    (totalTTC, item) =>
                                      totalTTC +
                                      item.quantity *
                                        getItemObject(item).priceTTC,
                                    0
                                  )
                                ) +
                                " € TTC)"}
                            </ButtonMui>
                            {supplierCartItems.items.reduce((total, item) => {
                              return (
                                total +
                                getItemObject(item).priceTTC * item.quantity
                              );
                            }, 0) <
                              Number(supplierCartItems.supplier.minimumOrder) &&
                              supplierCartItems.supplier.minimumOrder && (
                                <Text14
                                  color={CustomTheme.palette.secondary.main}
                                >
                                  {"Commande minimum: " +
                                    supplierCartItems.supplier.minimumOrder +
                                    " €"}
                                </Text14>
                              )}
                          </FlexContainer>
                        </>
                      )}
                    </BlockContainer>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider sx={{ mb: "16px" }} />
                    {supplierCartItems.items.map((item, index) => (
                      <FlexContainer
                        width="100%"
                        alignItems="center"
                        margin={"16px 0"}
                      >
                        <FlexContainer flex={"1"}>
                          <BlockContainer
                            width="80px"
                            height="80px"
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={getItemObject(item).imageUrl}
                            />
                          </BlockContainer>
                          <FlexContainer flex={"1"} flexDirection={"column"}>
                            <Text16 fontWeight="600">
                              {getItemObject(item).name}
                            </Text16>
                            <Text14
                              margin="0 0 0 10px"
                              color={CustomTheme.palette.secondary.main}
                            >
                              {getItemObject(item).priceTTC +
                                " €/" +
                                getItemObject(item).purchaseUnit?.name}
                            </Text14>
                          </FlexContainer>
                        </FlexContainer>
                        {getPurchasableItemQuantity(getItemObject(item).id) ===
                          0 && (
                          <FlexContainer>
                            <ButtonMui
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                addItemToCart({
                                  purchaseOptionId: isIngredientPurchaseOption(
                                    getItemObject(item).id
                                  )
                                    ? getItemObject(item).id
                                    : null,
                                  materialPurchaseOptionId:
                                    !isIngredientPurchaseOption(
                                      getItemObject(item).id
                                    )
                                      ? getItemObject(item).id
                                      : null,
                                  quantity: 1,
                                  ingredientMaterialId:
                                    getItemObject(item).parentId,
                                });
                              }}
                              sx={{ minWidth: "unset" }}
                            >
                              <AddShoppingCartIcon fontSize="medium" />
                            </ButtonMui>
                          </FlexContainer>
                        )}
                        {getPurchasableItemQuantity(getItemObject(item).id) >
                          0 && (
                          <FlexContainer
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Text14
                              color={CustomTheme.palette.secondary.main}
                              textDecorationLine="underline"
                              cursor="pointer"
                              margin="0"
                              onClick={() => {
                                addItemToCart({
                                  purchaseOptionId: isIngredientPurchaseOption(
                                    getItemObject(item).id
                                  )
                                    ? getItemObject(item).id
                                    : null,
                                  materialPurchaseOptionId:
                                    !isIngredientPurchaseOption(
                                      getItemObject(item).id
                                    )
                                      ? getItemObject(item).id
                                      : null,
                                  quantity: 0,
                                  ingredientMaterialId:
                                    getItemObject(item).parentId,
                                });
                              }}
                            >
                              Supprimer
                            </Text14>
                            <FlexContainer alignItems="center">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  addItemToCart({
                                    purchaseOptionId:
                                      isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    materialPurchaseOptionId:
                                      !isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    quantity:
                                      getPurchasableItemQuantity(
                                        getItemObject(item).id
                                      ) - 1,
                                    ingredientMaterialId:
                                      getItemObject(item).parentId,
                                  });
                                }}
                              >
                                <RemoveIcon fontSize="medium" />
                              </IconButton>
                              <TextField
                                fullWidth
                                variant="outlined"
                                name="quantity"
                                label=""
                                value={getPurchasableItemQuantity(
                                  getItemObject(item).id
                                )}
                                onChange={(e) => {
                                  addItemToCart({
                                    purchaseOptionId:
                                      isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    materialPurchaseOptionId:
                                      !isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    quantity: e.target.value,
                                    ingredientMaterialId:
                                      getItemObject(item).parentId,
                                  });
                                }}
                                size="small"
                                sx={{
                                  width: "40px",
                                  margin: "0 4px",
                                }}
                                inputProps={{
                                  sx: {
                                    padding: "8.5px 2px",
                                    textAlign: "center",
                                  },
                                }}
                              />
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  addItemToCart({
                                    purchaseOptionId:
                                      isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    materialPurchaseOptionId:
                                      !isIngredientPurchaseOption(
                                        getItemObject(item).id
                                      )
                                        ? getItemObject(item).id
                                        : null,
                                    quantity:
                                      getPurchasableItemQuantity(
                                        getItemObject(item).id
                                      ) + 1,
                                    ingredientMaterialId:
                                      getItemObject(item).parentId,
                                  });
                                }}
                              >
                                <AddIcon fontSize="medium" />
                              </IconButton>
                            </FlexContainer>
                            <Text16
                              color={CustomTheme.palette.primary.main}
                              margin="8px 0 0 0"
                              fontWeight="600"
                            >
                              {toFixed2(
                                getItemObject(item).priceTTC * item.quantity
                              ) + " €"}
                            </Text16>
                          </FlexContainer>
                        )}
                      </FlexContainer>
                    ))}
                  </AccordionDetails>
                  <FlexContainer
                    justifyContent={"center"}
                    margin={"16px"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <ButtonMui
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setPopupSubmitOrderActive(supplierCartItems);
                      }}
                      disabled={
                        supplierCartItems.items.reduce((total, item) => {
                          return (
                            total + getItemObject(item).priceTTC * item.quantity
                          );
                        }, 0) < Number(supplierCartItems.supplier.minimumOrder)
                      }
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      {"Commander (Total: " +
                        toFixed2(
                          supplierCartItems.items.reduce(
                            (totalTTC, item) =>
                              totalTTC +
                              item.quantity * getItemObject(item).priceTTC,
                            0
                          )
                        ) +
                        " € TTC)"}
                    </ButtonMui>
                    {supplierCartItems.items.reduce((total, item) => {
                      return (
                        total + getItemObject(item).priceTTC * item.quantity
                      );
                    }, 0) < Number(supplierCartItems.supplier.minimumOrder) &&
                      supplierCartItems.supplier.minimumOrder && (
                        <Text14 color={CustomTheme.palette.secondary.main}>
                          {"Commande minimum: " +
                            supplierCartItems.supplier.minimumOrder +
                            " €"}
                        </Text14>
                      )}
                  </FlexContainer>
                </Accordion>
              </FlexContainer>
            ))}
          </FlexContainer>
        )}
      </DialogContentMui>
      <DialogActions sx={{ justifyContent: "center" }}>
        <ButtonMui
          onClick={() => {
            emptyCartItems();
          }}
          disabled={cartItems.length === 0}
          color="secondary"
          variant="outlined"
          size="large"
        >
          <DeleteIcon sx={{ mr: "8px" }} fontSize="small" color="secondary" />
          Vider le panier
        </ButtonMui>
      </DialogActions>
      {popupSubmitOrderActive && (
        <SubmitOrder
          popupActive={popupSubmitOrderActive}
          setPopupActive={setPopupSubmitOrderActive}
        />
      )}
    </Drawer>
  );
};

export const Cart = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
    materials: getMaterials(state),
    cartItems: getCartItems(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getMaterialsAction: getMaterialsAction,
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_Cart);

export default Cart;
