import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Alert, Collapse, Grid, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Comment } from "../../../../../../models/comment";
import { Employee } from "../../../../../../models/employee";
import { File } from "../../../../../../models/file";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getEmployeeDescription,
  trimLongString,
} from "../../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface CommentsProps {
  inputComments: Comment[];
  setInputComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}

const Comments: React.FC<CommentsProps> = (props) => {
  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  const [user, setUser] = useState<Employee | null>(null);

  const [commentAddActive, setCommentAddActive] = useState(false);

  const [commentEditActive, setCommentEditActive] = useState("");

  const [inputContent, setInputContent] = useState("");

  const [inputContentError, setInputContentError] = useState(false);

  const [inputFiles, setInputFiles] = useState<File[]>([]);

  const [addFailed, setAddFailed] = useState("");

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputContentError(false);
  };

  const validateForm = () => {
    setInputContentError(false);
    let result = true;
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = () => {
    setInputContentError(false);
    let result = true;
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  let removeDocument = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setCommentAddActive(true);
            setCommentEditActive("");
            setInputContent("");
            setInputFiles([]);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un commentaire
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={commentAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}

        {inputFiles.length > 0 && (
          <Grid
            container
            border="2px dashed rgba(0,0,0,0.1)"
            margin={"0 0 8px 0"}
          >
            {inputFiles!.map((justification, index) => (
              <Grid>
                <FlexContainer
                  elevation={3}
                  margin="8px"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                  $borderRadius="16px"
                >
                  <FlexContainer margin="0 0 0 8px" alignItems="center">
                    <AttachFileRoundedIcon fontSize="medium" color="primary" />
                    <Text14
                      cursor="pointer"
                      margin="0 4px"
                      color={CustomTheme.palette.secondary.main}
                      textDecorationLine="underline"
                      onClick={(e) => {
                        fetch(justification.url)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", justification.name);
                            document.body.appendChild(link);
                            link.click();
                          });
                      }}
                    >
                      {trimLongString(justification.name)}
                    </Text14>
                  </FlexContainer>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeDocument(index);
                    }}
                    size="medium"
                    style={{ margin: "0 8px", padding: "4px", zIndex: "1" }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </FlexContainer>
              </Grid>
            ))}
          </Grid>
        )}
        <FlexContainer>
          <TextField
            fullWidth
            variant="outlined"
            name="comment"
            label="Commentaire"
            multiline
            minRows={4}
            value={inputContent}
            onChange={(e) => {
              setInputContent(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <>
                  <label htmlFor="contained-button-file">
                    <Input
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {
                        filesPreviewHandler(e.target.files);
                      }}
                    />
                    <IconButton component="span">
                      <AttachFileRoundedIcon fontSize="medium" />
                    </IconButton>
                  </label>
                </>
              ),
            }}
          />
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputComments([
                  ...props.inputComments,
                  {
                    id: uuid(),
                    content: inputContent,
                    commentWriter: user,
                    files: inputFiles,
                    creationDateTime: null,
                  },
                ]);
                setCommentAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setCommentAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputComments.map((comment, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer
              flexDirection={"column"}
              alignItems={"flex-start"}
              flex={"1"}
            >
              <FlexContainer alignItems={"center"} margin={"0 8px"}>
                <BlockContainer
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  border="rgba(0,0,0,0.1) solid 1px"
                  justifyContent="center"
                  position="relative"
                  textAlign="center"
                  backgroundColor="white"
                  margin="auto"
                  borderRadius={"40px"}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      margin: "auto",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                    }}
                    alt=""
                    src={comment?.commentWriter?.imageUrl}
                  />
                </BlockContainer>
                <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                  {getEmployeeDescription(comment.commentWriter)}
                </Text16>
              </FlexContainer>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="8px 16px"
                lineHeight="20px"
              >
                {comment.content}
              </Text14>
              {comment.files.map((file) => (
                <FlexContainer alignItems={"center"} margin="0 12px">
                  <AttachFileRoundedIcon fontSize="small" color="primary" />
                  <Text14
                    cursor="pointer"
                    margin="0 4px"
                    color={CustomTheme.palette.secondary.main}
                    textDecorationLine="underline"
                    onClick={(e) => {
                      fetch(file.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", file.name);
                          document.body.appendChild(link);
                          link.click();
                        });
                    }}
                  >
                    {file.name}
                  </Text14>
                </FlexContainer>
              ))}
            </FlexContainer>
            {user?.id === comment.commentWriter?.id && (
              <FlexContainer>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setCommentAddActive(false);
                    setCommentEditActive(comment.id);
                    setInputContent(comment.content);
                    setInputFiles(comment.files);
                    resetValidation();
                  }}
                  size="large"
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    let newArr = [...props.inputComments];
                    newArr.splice(index, 1);
                    props.setInputComments(newArr);
                  }}
                  size="large"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </FlexContainer>
            )}
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={commentEditActive === comment.id}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={2}>
                  {inputFiles.length > 0 && (
                    <Grid
                      container
                      border="2px dashed rgba(0,0,0,0.1)"
                      margin={"0 0 8px 0"}
                    >
                      {inputFiles!.map((justification, index) => (
                        <Grid>
                          <FlexContainer
                            elevation={3}
                            margin="8px"
                            height="40px"
                            justifyContent="space-between"
                            alignItems="center"
                            $borderRadius="16px"
                          >
                            <FlexContainer
                              margin="0 0 0 8px"
                              alignItems="center"
                            >
                              <AttachFileRoundedIcon
                                fontSize="medium"
                                color="primary"
                              />
                              <Text14
                                cursor="pointer"
                                margin="0 4px"
                                color={CustomTheme.palette.secondary.main}
                                textDecorationLine="underline"
                                onClick={(e) => {
                                  fetch(justification.url)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      const url =
                                        window.URL.createObjectURL(blob);
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.setAttribute(
                                        "download",
                                        justification.name
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                    });
                                }}
                              >
                                {trimLongString(justification.name)}
                              </Text14>
                            </FlexContainer>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeDocument(index);
                              }}
                              size="medium"
                              style={{
                                margin: "0 8px",
                                padding: "4px",
                                zIndex: "1",
                              }}
                            >
                              <CloseIcon fontSize="medium" />
                            </IconButton>
                          </FlexContainer>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="comment"
                    label="Commentaire"
                    multiline
                    minRows={4}
                    error={inputContentError}
                    value={inputContent}
                    onChange={(e) => {
                      setInputContent(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <label htmlFor="contained-button-file">
                            <Input
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => {
                                filesPreviewHandler(e.target.files);
                              }}
                            />
                            <IconButton component="span">
                              <AttachFileRoundedIcon fontSize="medium" />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setCommentAddActive(false);
                  resetValidation();
                  if (validateEditForm()) {
                    let tmpArray = [...props.inputComments];
                    tmpArray[index] = {
                      id: comment.id,
                      content: inputContent,
                      commentWriter: user,
                      files: inputFiles,
                      creationDateTime: comment.creationDateTime,
                    };

                    props.setInputComments(tmpArray);
                    setCommentEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setCommentEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default Comments;
