import React, { useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Dispatch } from "../../../../../store";
import { signInAction } from "../../../../../store/Signin/actions";
import { AppName } from "../../../../../styles/AppName";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface SetPasswordSuccessProps {
  signIn: typeof signInAction;
}

interface StateType {
  employeeId: string;
  email: string;
  password: string;
}

const _SetPasswordSuccess: React.FC<SetPasswordSuccessProps> = (props) => {
  const [signInClicked, setSignInClicked] = useState(false);

  const location = useLocation();
  const state = location.state as StateType;

  return (
    <FlexContainer justifyContent="center" alignItems="center" height="100vh">
      {signInClicked && <Navigate to="/feature" replace />}
      <FlexContainer
        elevation={3}
        padding="40px"
        alignItems="center"
        flexDirection="column"
        backgroundColor="white"
        width="500px"
      >
        <FlexContainer margin="20px" alignSelf="center">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>
        <FlexContainer>
          <Text16 fontWeight="600">
            Votre mot de passe a été enregistré avec succès!
          </Text16>
        </FlexContainer>
        <FlexContainer margin="24px 0 16px" width="100%">
          <ButtonMui
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              props.signIn(state.email, state.password);
              setSignInClicked(true);
            }}
          >
            CONNEXION
          </ButtonMui>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export const SetPasswordSuccess = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signIn: signInAction,
    },
    dispatch
  )
)(_SetPasswordSuccess);

export default _SetPasswordSuccess;
