import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { CustomerOrderService } from "../../services/CustomerOrderService";
import { RootAction } from "../types";
import {
  GET_CUSTOMER_ORDERS,
  GET_CUSTOMER_ORDERS_ARCHIVE,
  GetCustomerOrdersActionFailure,
  getCustomerOrdersActionFailure,
  GetCustomerOrdersActionSuccess,
  getCustomerOrdersActionSuccess,
  GetCustomerOrdersArchiveActionFailure,
  getCustomerOrdersArchiveActionFailure,
  GetCustomerOrdersArchiveActionSuccess,
  getCustomerOrdersArchiveActionSuccess,
} from "./actions";

export const customerOrderListEpic: Epic<
  RootAction,
  GetCustomerOrdersActionSuccess | GetCustomerOrdersActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CUSTOMER_ORDERS),
    switchMap((action) =>
      from(
        CustomerOrderService.getCustomerOrders({ input: action.input })
      ).pipe(
        mergeMap((res) => {
          return [getCustomerOrdersActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getCustomerOrdersActionFailure()]))
        )
      )
    )
  );

export const customerOrderArchiveListEpic: Epic<
  RootAction,
  GetCustomerOrdersArchiveActionSuccess | GetCustomerOrdersArchiveActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CUSTOMER_ORDERS_ARCHIVE),
    switchMap((action) =>
      from(
        CustomerOrderService.getCustomerOrdersArchive({ input: action.input })
      ).pipe(
        mergeMap((res) => {
          return [getCustomerOrdersArchiveActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(
            mergeMap(() => [getCustomerOrdersArchiveActionFailure()])
          )
        )
      )
    )
  );

export const customerOrderEpic = combineEpics<any>(
  customerOrderListEpic,
  customerOrderArchiveListEpic
);
