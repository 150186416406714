import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import TaskTable from "./TaskTable";

export interface TaskProps {}

const Task: React.FC<TaskProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Tâches"
        subTitle="Liste des tâches"
        icon={<AssignmentTwoToneIcon fontSize="large" color="primary" />}
      />
      <TaskTable />
    </BlockContainer>
  );
};

export default Task;
