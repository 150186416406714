import React from "react";
import { BlockContainer } from "../../styles/BlockContainer";
import ContactSection from "./ContactSection";
import FeaturesSection from "./FeaturesSection";
import Footer from "./Footer";
import ForWhoSection from "./ForWhoSection";
import Header from "./Header";
import HeroSection from "./HeroSection";
import WhyUsSection from "./WhyUsSection";

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <>
      <BlockContainer position="relative" overflow="hidden">
        <Header />
        <HeroSection />
        <ForWhoSection />
        <FeaturesSection />
        <WhyUsSection />
        <ContactSection />
        <Footer />
      </BlockContainer>
    </>
  );
};

export default HomePage;
