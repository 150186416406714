import { RootAction } from "../types";
import { GET_INTERACTIONS_SUCCESS } from "./actions";
import { InteractionState } from "./types";

const initialInteractionState: InteractionState = {
  interactions: [],
};

export function interactionReducer(
  state: InteractionState = initialInteractionState,
  action: RootAction
): InteractionState {
  switch (action.type) {
    case GET_INTERACTIONS_SUCCESS:
      return {
        interactions: action.interactions,
      };

    default:
      return state;
  }
}
