import SellTwoToneIcon from "@mui/icons-material/SellTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import SupplierOrderTable from "./SupplierOrderTable";

export interface SupplierOrderProps {}

const SupplierOrder: React.FC<SupplierOrderProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Provisionnement"
        subTitle="Liste des commandes fournisseurs"
        icon={<SellTwoToneIcon fontSize="large" color="primary" />}
      />
      <SupplierOrderTable />
    </BlockContainer>
  );
};

export default SupplierOrder;
