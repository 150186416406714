import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { EmailTemplate } from "../../../../../../../models/emailTemplate";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteQuoteEmailTemplateProps {
  popupActive: EmailTemplate | null;
  isDeleteQuoteEmailTemplateLoading: boolean;
  setIsDeleteQuoteEmailTemplateLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setPopupActive: React.Dispatch<React.SetStateAction<EmailTemplate | null>>;
  deleteQuoteEmailTemplate: (quoteEmailTemplateId: string) => void;
}

const DeleteQuoteEmailTemplate: React.FC<DeleteQuoteEmailTemplateProps> = (
  props
) => {
  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={props.isDeleteQuoteEmailTemplateLoading}
          loading={props.isDeleteQuoteEmailTemplateLoading}
          onClick={() => {
            props.deleteQuoteEmailTemplate(props.popupActive!.id);
            props.setIsDeleteQuoteEmailTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteQuoteEmailTemplate;
