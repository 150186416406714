import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { CrmService } from "../../services/CrmService";
import { RootAction } from "../types";
import {
  GET_CLIENTS,
  GET_COMMERCIAL_CYCLES,
  GET_COMPLAINTS,
  GET_CONTACTS,
  GET_OPPORTUNITIES,
  GET_PROSPECTS,
  GET_TASKS,
  GetClientsActionFailure,
  getClientsActionFailure,
  GetClientsActionSuccess,
  getClientsActionSuccess,
  GetCommercialCyclesActionFailure,
  getCommercialCyclesActionFailure,
  GetCommercialCyclesActionSuccess,
  getCommercialCyclesActionSuccess,
  GetComplaintsActionFailure,
  getComplaintsActionFailure,
  GetComplaintsActionSuccess,
  getComplaintsActionSuccess,
  GetContactsActionFailure,
  getContactsActionFailure,
  GetContactsActionSuccess,
  getContactsActionSuccess,
  GetOpportunitiesActionFailure,
  getOpportunitiesActionFailure,
  GetOpportunitiesActionSuccess,
  getOpportunitiesActionSuccess,
  GetProspectsActionFailure,
  getProspectsActionFailure,
  GetProspectsActionSuccess,
  getProspectsActionSuccess,
  GetTasksActionFailure,
  getTasksActionFailure,
  GetTasksActionSuccess,
  getTasksActionSuccess,
} from "./actions";

export const clientsEpic: Epic<
  RootAction,
  GetClientsActionSuccess | GetClientsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CLIENTS),
    switchMap((action) =>
      from(
        CrmService.getContacts({
          criteriaList: action.criteriaList
            ? action.criteriaList + ",prospect=false"
            : "prospect=false",
        })
      ).pipe(
        mergeMap((res) => {
          return [getClientsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getClientsActionFailure()]))
        )
      )
    )
  );

export const prospectsEpic: Epic<
  RootAction,
  GetProspectsActionSuccess | GetProspectsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_PROSPECTS),
    switchMap((action) =>
      from(
        CrmService.getContacts({
          criteriaList: action.criteriaList
            ? action.criteriaList + ",prospect=true"
            : "prospect=true",
        })
      ).pipe(
        mergeMap((res) => {
          return [getProspectsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getProspectsActionFailure()]))
        )
      )
    )
  );

export const contactsEpic: Epic<
  RootAction,
  GetContactsActionSuccess | GetContactsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CONTACTS),
    switchMap((action) =>
      from(CrmService.getContacts({ criteriaList: action.criteriaList })).pipe(
        mergeMap((res) => {
          return [getContactsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getContactsActionFailure()]))
        )
      )
    )
  );

export const opportunitiesEpic: Epic<
  RootAction,
  GetOpportunitiesActionSuccess | GetOpportunitiesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_OPPORTUNITIES),
    switchMap((action) =>
      from(
        CrmService.getOpportunities({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getOpportunitiesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getOpportunitiesActionFailure()]))
        )
      )
    )
  );

export const commercialCyclesEpic: Epic<
  RootAction,
  GetCommercialCyclesActionSuccess | GetCommercialCyclesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_COMMERCIAL_CYCLES),
    switchMap((action) =>
      from(
        CrmService.getCommercialCycles({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getCommercialCyclesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getCommercialCyclesActionFailure()]))
        )
      )
    )
  );

export const complaintsEpic: Epic<
  RootAction,
  GetComplaintsActionSuccess | GetComplaintsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_COMPLAINTS),
    switchMap((action) =>
      from(
        CrmService.getComplaints({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getComplaintsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getComplaintsActionFailure()]))
        )
      )
    )
  );

export const tasksEpic: Epic<
  RootAction,
  GetTasksActionSuccess | GetTasksActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_TASKS),
    switchMap((action) =>
      from(
        CrmService.getTasks({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getTasksActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getTasksActionFailure()]))
        )
      )
    )
  );

export const CrmEpic = combineEpics<any>(
  clientsEpic,
  prospectsEpic,
  contactsEpic,
  opportunitiesEpic,
  commercialCyclesEpic,
  complaintsEpic,
  tasksEpic
);
