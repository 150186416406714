import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Vacation } from "../../../../../models/vacation";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14 } from "../../../../../styles/Text";
import { trimLongString } from "../../../../Reusable/Utils";

export interface JustificationsProps {
  popupActive: Vacation | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Vacation | null>>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "700px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const Justifications: React.FC<JustificationsProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        props.setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        {"Liste des justificatifs"}
      </DialogTitle>
      <DialogContent>
        {props.popupActive!.justificationFiles.map((file, index) => (
          <FlexContainer
            elevation={3}
            margin="8px"
            height="48px"
            justifyContent="space-between"
            alignItems="center"
            $borderRadius="16px"
          >
            <FlexContainer margin="0 0 0 8px" alignItems="center">
              <ArticleIcon fontSize="large" color="primary" />
              <Text14>{trimLongString(file.name)}</Text14>
            </FlexContainer>
            <IconButton
              color="secondary"
              onClick={(e) => {
                fetch(file.url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", file.name);
                    document.body.appendChild(link);
                    link.click();
                  });
              }}
              size="medium"
              style={{ marginRight: "8px", padding: "4px", zIndex: "1" }}
            >
              <DownloadIcon fontSize="medium" />
            </IconButton>
          </FlexContainer>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default Justifications;
