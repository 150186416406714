import { CustomerOrderArchive } from "../../models/customerOrderArchive";
import { CustomerOrder } from "../../models/sale";

export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS" as const;
export const GET_CUSTOMER_ORDERS_SUCCESS =
  "GET_CUSTOMER_ORDERS_SUCCESS" as const;
export const GET_CUSTOMER_ORDERS_FAILURE =
  "GET_CUSTOMER_ORDERS_FAILURE" as const;

export const GET_CUSTOMER_ORDERS_ARCHIVE =
  "GET_CUSTOMER_ORDERS_ARCHIVE" as const;
export const GET_CUSTOMER_ORDERS_ARCHIVE_SUCCESS =
  "GET_CUSTOMER_ORDERS_ARCHIVE_SUCCESS" as const;
export const GET_CUSTOMER_ORDERS_ARCHIVE_FAILURE =
  "GET_CUSTOMER_ORDERS_ARCHIVE_FAILURE" as const;

export const getCustomerOrdersAction = (input: string) => ({
  type: GET_CUSTOMER_ORDERS,
  input: input,
});
export type GetCustomerOrdersAction = ReturnType<
  typeof getCustomerOrdersAction
>;

export const getCustomerOrdersActionSuccess = (
  customerOrders: CustomerOrder[]
) => ({
  type: GET_CUSTOMER_ORDERS_SUCCESS,
  customerOrders: customerOrders,
});
export type GetCustomerOrdersActionSuccess = ReturnType<
  typeof getCustomerOrdersActionSuccess
>;

export const getCustomerOrdersActionFailure = () => ({
  type: GET_CUSTOMER_ORDERS_FAILURE,
});
export type GetCustomerOrdersActionFailure = ReturnType<
  typeof getCustomerOrdersActionFailure
>;

export const getCustomerOrdersArchiveAction = (input: string) => ({
  type: GET_CUSTOMER_ORDERS_ARCHIVE,
  input: input,
});
export type GetCustomerOrdersArchiveAction = ReturnType<
  typeof getCustomerOrdersArchiveAction
>;

export const getCustomerOrdersArchiveActionSuccess = (
  customerOrdersArchive: CustomerOrderArchive[]
) => ({
  type: GET_CUSTOMER_ORDERS_ARCHIVE_SUCCESS,
  customerOrdersArchive: customerOrdersArchive,
});
export type GetCustomerOrdersArchiveActionSuccess = ReturnType<
  typeof getCustomerOrdersArchiveActionSuccess
>;

export const getCustomerOrdersArchiveActionFailure = () => ({
  type: GET_CUSTOMER_ORDERS_ARCHIVE_FAILURE,
});
export type GetCustomerOrdersArchiveActionFailure = ReturnType<
  typeof getCustomerOrdersArchiveActionFailure
>;
