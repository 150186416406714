import { createFilterOptions } from "@mui/core";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import { Complaint } from "../../../../../../models/complaint";
import { Contact } from "../../../../../../models/contact";
import { Employee } from "../../../../../../models/employee";
import { Invoice } from "../../../../../../models/invoice";
import { Opportunity } from "../../../../../../models/opportunity";
import { Quote } from "../../../../../../models/quote";
import { Sale } from "../../../../../../models/sale";
import { Task } from "../../../../../../models/task";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../../styles/Text";
import { getParticipantDescription } from "../../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface ActivitiesProps {
  inputType: string;
  inputTitle: string;
  inputTitleError: boolean;
  inputStartDate: Dayjs | null;
  inputStartDateError: boolean;
  inputStartTime: Dayjs | null;
  inputEndDate: Dayjs | null;
  inputEndTime: Dayjs | null;
  inputParticipants: (Employee | Contact)[];
  inputParticipantsError: boolean;
  inputParticipantsText: string;
  inputLocation: string;
  inputDescription: string;
  inputAssociatedOpportunities: Opportunity[];
  inputAssociatedOpportunitiesText: string;
  inputAssociatedQuotes: Quote[];
  inputAssociatedQuotesText: string;
  inputAssociatedInvoices: Invoice[];
  inputAssociatedInvoicesText: string;
  inputAssociatedSales: Sale[];
  inputAssociatedSalesText: string;
  inputAssociatedComplaints: Complaint[];
  inputAssociatedComplaintsText: string;
  inputAssociatedTasks: Task[];
  inputAssociatedTasksText: string;
  inputReminderModes: string[];
  inputReminderTime: string;
  inputReminderTimeUnit: string;
  inputReport: string;
  employees: Employee[];
  contacts: Contact[];
  opportunities: Opportunity[];
  quotes: Quote[];
  invoices: Invoice[];
  sales: Sale[];
  complaints: Complaint[];
  tasks: Task[];

  setInputType: React.Dispatch<React.SetStateAction<string>>;
  setInputTitle: React.Dispatch<React.SetStateAction<string>>;
  setInputStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputStartTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputEndTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setInputParticipants: React.Dispatch<
    React.SetStateAction<(Employee | Contact)[]>
  >;
  setInputParticipantsText: React.Dispatch<React.SetStateAction<string>>;
  setInputLocation: React.Dispatch<React.SetStateAction<string>>;
  setInputDescription: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedOpportunities: React.Dispatch<
    React.SetStateAction<Opportunity[]>
  >;
  setInputAssociatedOpportunitiesText: React.Dispatch<
    React.SetStateAction<string>
  >;
  setInputAssociatedQuotes: React.Dispatch<React.SetStateAction<Quote[]>>;
  setInputAssociatedQuotesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
  setInputAssociatedInvoicesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedSales: React.Dispatch<React.SetStateAction<Sale[]>>;
  setInputAssociatedSalesText: React.Dispatch<React.SetStateAction<string>>;
  setInputAssociatedComplaints: React.Dispatch<
    React.SetStateAction<Complaint[]>
  >;
  setInputAssociatedComplaintsText: React.Dispatch<
    React.SetStateAction<string>
  >;
  setInputAssociatedTasks: React.Dispatch<React.SetStateAction<Task[]>>;
  setInputAssociatedTasksText: React.Dispatch<React.SetStateAction<string>>;
  setInputReminderModes: React.Dispatch<React.SetStateAction<string[]>>;
  setInputReminderTime: React.Dispatch<React.SetStateAction<string>>;
  setInputReminderTimeUnit: React.Dispatch<React.SetStateAction<string>>;
  setInputReport: React.Dispatch<React.SetStateAction<string>>;
}

const PhysicalMeetingInteraction: React.FC<ActivitiesProps> = ({
  inputType,
  inputTitle,
  inputTitleError,
  inputStartDate,
  inputStartDateError,
  inputStartTime,
  inputEndDate,
  inputEndTime,
  inputParticipants,
  inputParticipantsError,
  inputParticipantsText,
  inputLocation,
  inputDescription,
  inputAssociatedOpportunities,
  inputAssociatedOpportunitiesText,
  inputAssociatedQuotes,
  inputAssociatedQuotesText,
  inputAssociatedInvoices,
  inputAssociatedInvoicesText,
  inputAssociatedSales,
  inputAssociatedSalesText,
  inputAssociatedComplaints,
  inputAssociatedComplaintsText,
  inputAssociatedTasks,
  inputAssociatedTasksText,
  inputReminderModes,
  inputReminderTime,
  inputReminderTimeUnit,
  inputReport,
  opportunities,
  quotes,
  invoices,
  sales,
  complaints,
  tasks,
  employees,
  contacts,
  setInputType,
  setInputTitle,
  setInputStartDate,
  setInputStartTime,
  setInputEndDate,
  setInputEndTime,
  setInputParticipants,
  setInputParticipantsText,
  setInputLocation,
  setInputDescription,
  setInputAssociatedOpportunities,
  setInputAssociatedOpportunitiesText,
  setInputAssociatedQuotes,
  setInputAssociatedQuotesText,
  setInputAssociatedInvoices,
  setInputAssociatedInvoicesText,
  setInputAssociatedSales,
  setInputAssociatedSalesText,
  setInputAssociatedComplaints,
  setInputAssociatedComplaintsText,
  setInputAssociatedTasks,
  setInputAssociatedTasksText,
  setInputReminderModes,
  setInputReminderTime,
  setInputReminderTimeUnit,
  setInputReport,
}) => {
  const filterComplaintsOptions = createFilterOptions<Complaint>({
    stringify: (option) => option.complaintNumber + " - " + option.title,
    limit: 20,
  });

  const filterTasksOptions = createFilterOptions<Task>({
    stringify: (option) => option.taskNumber + " - " + option.title,
    limit: 20,
  });

  const filterParticipantsOptions = createFilterOptions<Contact | Employee>({
    stringify: (option) => getParticipantDescription(option),
    limit: 20,
  });

  const filterSalesOptions = createFilterOptions<Sale>({
    stringify: (option) => option.saleNumber,
    limit: 20,
  });

  const filterQuotesOptions = createFilterOptions<Quote>({
    stringify: (option) => option.quoteNumber,
    limit: 20,
  });

  const filterInvoicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
    limit: 20,
  });

  const filterOpportunitiesOptions = createFilterOptions<Opportunity>({
    stringify: (option) => option.opportunityNumber + " - " + option.title,
    limit: 20,
  });

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Informations générales{" "}
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Type de l'interaction
            </InputLabel>
            <Select
              required
              fullWidth
              value={inputType}
              label="Type de l'interaction"
              onChange={(e) => {
                setInputType(
                  typeof e.target.value === "string" ? e.target.value : ""
                );
              }}
            >
              <MenuItem value="Appel téléphonique">Appel téléphonique</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="E-mail">E-mail</MenuItem>
              <MenuItem value="Réunion en personne">
                Réunion en personne
              </MenuItem>
              <MenuItem value="Réunion virtuelle">Réunion virtuelle</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="title"
            label="Titre"
            error={inputTitleError}
            value={inputTitle}
            onChange={(e) => {
              setInputTitle(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date de début"
              inputFormat="DD/MM/YYYY"
              value={inputStartDate}
              onChange={(value) => setInputStartDate(value!)}
              renderInput={(params) => (
                <TextField fullWidth {...params} error={inputStartDateError} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              ampm={false}
              label="Heure de début"
              value={inputStartTime}
              onChange={(newValue) => {
                setInputStartTime(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date de fin"
              inputFormat="DD/MM/YYYY"
              value={inputEndDate}
              onChange={(value) => setInputEndDate(value!)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              ampm={false}
              label="Heure de fin"
              value={inputEndTime}
              onChange={(newValue) => {
                setInputEndTime(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="participants"
            filterOptions={filterParticipantsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return getParticipantDescription(option);
            }}
            options={[...employees, ...contacts]}
            value={inputParticipants}
            onChange={(event, newValue) => {
              setInputParticipants(newValue!);
            }}
            inputValue={inputParticipantsText}
            onInputChange={(event, newInputValue) => {
              setInputParticipantsText(newInputValue);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  height: "100px",
                }}
                {...props}
              >
                <FlexContainer
                  flex="1"
                  justifyContent="center"
                  marginRight="16px"
                >
                  <img
                    style={{
                      maxWidth: "90px",
                      maxHeight: "90px",
                    }}
                    loading="lazy"
                    src={
                      "https://" +
                      process.env.REACT_APP_BUCKET_NAME! +
                      ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                    }
                    alt=""
                  />
                </FlexContainer>
                <FlexContainer flex="4">
                  <Text14 textAlign="left">
                    {getParticipantDescription(option)}
                  </Text14>
                </FlexContainer>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="participants"
                error={inputParticipantsError}
                label="Participants"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="location"
            label="Lieu"
            value={inputLocation}
            onChange={(e) => {
              setInputLocation(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            label="Decription"
            multiline
            rows={4}
            value={inputDescription}
            onChange={(e) => {
              setInputDescription(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Associations
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedOpportunities"
            filterOptions={filterOpportunitiesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.opportunityNumber + " - " + option.title;
            }}
            options={opportunities}
            value={inputAssociatedOpportunities}
            onChange={(event, newValue) => {
              setInputAssociatedOpportunities(newValue!);
            }}
            inputValue={inputAssociatedOpportunitiesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedOpportunitiesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedOpportunities"
                label="Opportunités associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedQuotes"
            filterOptions={filterQuotesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.quoteNumber;
            }}
            options={quotes}
            value={inputAssociatedQuotes}
            onChange={(event, newValue) => {
              setInputAssociatedQuotes(newValue!);
            }}
            inputValue={inputAssociatedQuotesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedQuotesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedQuote"
                label="Devis associés"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedSales"
            filterOptions={filterSalesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.saleNumber;
            }}
            options={sales}
            value={inputAssociatedSales}
            onChange={(event, newValue) => {
              setInputAssociatedSales(newValue!);
            }}
            inputValue={inputAssociatedSalesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedSalesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedSales"
                label="Ventes associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedInvoices"
            filterOptions={filterInvoicesOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.invoiceNumber;
            }}
            options={invoices}
            value={inputAssociatedInvoices}
            onChange={(event, newValue) => {
              setInputAssociatedInvoices(newValue!);
            }}
            inputValue={inputAssociatedInvoicesText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedInvoicesText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedInvoices"
                label="Factures associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedComplaints"
            filterOptions={filterComplaintsOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.complaintNumber + " - " + option.title;
            }}
            options={complaints}
            value={inputAssociatedComplaints}
            onChange={(event, newValue) => {
              setInputAssociatedComplaints(newValue!);
            }}
            inputValue={inputAssociatedComplaintsText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedComplaintsText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedComplaints"
                label="Réclamations associées"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            id="associatedTasks"
            filterOptions={filterTasksOptions}
            noOptionsText={"Pas de suggestions"}
            fullWidth
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.taskNumber + " - " + option.title;
            }}
            options={tasks}
            value={inputAssociatedTasks}
            onChange={(event, newValue) => {
              setInputAssociatedTasks(newValue!);
            }}
            inputValue={inputAssociatedTasksText}
            onInputChange={(event, newInputValue) => {
              setInputAssociatedTasksText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="associatedTasks"
                label="Tâches associées"
              />
            )}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Notifications
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={1}>
          <Autocomplete
            fullWidth
            multiple
            options={["E-mail", "Notification"]}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={inputReminderModes}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={inputReminderModes.includes(option) ? true : false}
                />
                {option}
              </li>
            )}
            onChange={(e, values) => {
              setInputReminderModes(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="reminderModes"
                label="Rappel par"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="timeEstimation"
            label="Rappel avant"
            value={inputReminderTime}
            onChange={(e) => {
              if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === "") {
                setInputReminderTime(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      borderBottom: "none",
                    },
                    "& .MuiInputBase-root:hover:before": {
                      borderBottom: "none !important",
                    },
                  }}
                >
                  <Select
                    variant="standard"
                    sx={{
                      "& .MuiSelect-select:focus": {
                        borderBottom: "none",
                        backgroundColor: "white",
                      },
                    }}
                    value={inputReminderTimeUnit}
                    onChange={(e) => setInputReminderTimeUnit(e.target.value)}
                  >
                    <MenuItem key="minutes" value="minutes">
                      Minute(s)
                    </MenuItem>
                    <MenuItem key="hours" value="hours">
                      Heure(s)
                    </MenuItem>
                    <MenuItem key="days" value="days">
                      Jour(s)
                    </MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Compte rendu
        </Text20>
      </BlockContainer>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <TextField
            fullWidth
            variant="outlined"
            name="report"
            label="Compte rendu"
            multiline
            rows={4}
            value={inputReport}
            onChange={(e) => {
              setInputReport(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PhysicalMeetingInteraction;
