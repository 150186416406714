import { createFilterOptions } from "@mui/core";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { Route, RouteName } from "../../../../../models/route";
import { Sale } from "../../../../../models/sale";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getRoutesAction } from "../../../../../store/Route/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getContactDescription } from "../../../../Reusable/Utils";

export interface CloneRouteProps {
  popupActive: Route | null;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<Route | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getRoutesPage: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "850px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _CloneRoute: React.FC<CloneRouteProps> = ({
  popupActive,
  employees,
  setPopupActive,
  getEmployeesAction,
  getRoutesPage,
}) => {
  const cloneRoute = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/sales/multiple",
      sales
        .filter((sale) => sale.deliverSale)
        .map((sale) => {
          return {
            id: "",
            description: sale.description,
            saleNumber: "auto",
            clientId: sale.client?.id,
            comments: sale.comments,
            deliverSale: true,
            routeNameName: inputRouteNameText,
            deliveryDate: inputDeliveryDate
              ? inputDeliveryDate.tz("Europe/Paris").format("YYYY-MM-DD")
              : null,
            deliveryTime: sale.deliveryTime,
            deliveryAddress: sale.deliveryAddress,
            deliveryInstructions: sale.deliveryInstructions,
            delivered: false,
            soldItems: sale.soldItems.map((soldItem) => {
              return {
                ...soldItem,
                id: uuid(),
              };
            }),
            status: "Validée",
            date: dayjs(),
            totalPriceHT: sale.totalPriceHT,
            totalPriceTVA: sale.totalPriceTVA,
            totalPriceTTC: sale.totalPriceTTC,
            globalTVA: sale.globalTVA,
            invoiceId: null,
            customFields: customFieldValues,
          };
        })
    )
      .then(() => {
        getRoutesPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setCloneFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "cloneRouteDialogContentId",
        });
      });
  };

  const getRouteNames = () => {
    AxiosHttpClient.get<RouteName[]>("api/v1/weeventpro/route/names").then(
      (res) => {
        setRouteNames(res);
      }
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: Sale[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        sales,
        result.source.index,
        result.destination.index
      );

      setSales(items);
    }
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/route"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputRouteNameError(false);
    setInputDeliveryDateError(false);
    let result = true;
    if (sales.filter((sale) => sale.deliverSale).length === 0) {
      setCloneFailed("La liste des ventes est vide!");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "cloneRouteDialogContentId",
      });
      return false;
    }
    if (inputRouteNameText === "") {
      setInputRouteNameError(true);
      result = false;
    }
    if (inputDeliveryDate === null) {
      setInputDeliveryDateError(true);
      result = false;
    }
    if (!result) {
      setCloneFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "cloneRouteDialogContentId",
      });
    }
    return result;
  };

  useEffect(() => {
    getEmployeesAction("");
    getCustomFields();
    getRouteNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputRouteName, setInputRouteName] = useState<RouteName | string>(
    popupActive?.routeName ?? ""
  );

  const [inputRouteNameText, setInputRouteNameText] = useState("");

  const [inputRouteNameError, setInputRouteNameError] = useState(false);

  const [inputDeliveryDate, setInputDeliveryDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputDeliveryDateError, setInputDeliveryDateError] = useState(false);

  const [draggedSale, setDraggedSale] = useState<Sale | null>(null);

  const [sales, setSales] = useState<Sale[]>(popupActive!.sales);

  const [cloneFailed, setCloneFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [routeNames, setRouteNames] = useState<RouteName[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const filterRouteNameOptions = createFilterOptions<RouteName>({
    stringify: (option) => option.name,
    limit: 20,
  });

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={popupActive?.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Cloner une tournée"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="cloneRouteDialogContentId" dividers>
        {cloneFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setCloneFailed("");
              }}
            >
              {cloneFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="route"
                filterOptions={filterRouteNameOptions}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                noOptionsText={"Pas de suggestions"}
                fullWidth
                disableClearable
                freeSolo
                options={routeNames}
                value={inputRouteName}
                onChange={(event, newValue) => {
                  setInputRouteName(newValue!);
                }}
                inputValue={inputRouteNameText}
                onInputChange={(event, newInputValue) => {
                  setInputRouteNameText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputRouteNameError}
                    variant="outlined"
                    name="route"
                    label="Tournée"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de livraison"
                  inputFormat="DD/MM/YYYY"
                  value={inputDeliveryDate}
                  onChange={(value) => setInputDeliveryDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputDeliveryDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Programme de livraison
          </Text20>
        </BlockContainer>
        <Grid item xs={1} sm={1} md={2}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell key="image">Client</TableCell>
                  <TableCell key="product">Heure</TableCell>
                  <TableCell key="code">Produits</TableCell>
                  <TableCell key="quantity">Instructions</TableCell>
                  <TableCell key="pu">Actions</TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction={"vertical"}>
                  {(provided, snapshot) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {draggedSale && (
                        <TableRow
                          sx={{
                            backgroundColor: "lightgrey",
                            visibility: "collapse",
                          }} // Style de fond pour indiquer le drag
                          key={draggedSale.id}
                        >
                          {/* Contenu de la ligne similaire à la ligne originale */}
                          <TableCell>
                            {getContactDescription(draggedSale.client)}
                          </TableCell>
                          <TableCell>
                            {draggedSale.deliveryTime
                              ? dayjs(draggedSale.deliveryTime).format("HH:mm")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {draggedSale.soldItems.map((item, index) => (
                              <FlexContainer textAlign={"center"} key={index}>
                                • {item.quantity + " " + item.name}
                              </FlexContainer>
                            ))}
                          </TableCell>
                          <TableCell>
                            {draggedSale.deliveryInstructions}
                          </TableCell>
                          <TableCell>
                            <FlexContainer justifyContent="center">
                              <IconButton
                                color="secondary"
                                onClick={() => {}}
                                size="large"
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </FlexContainer>
                          </TableCell>
                        </TableRow>
                      )}

                      {sales.map((sale, index) => (
                        <Draggable
                          key={sale.id}
                          draggableId={sale.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <TableRow
                                sx={{
                                  backgroundColor: "white",
                                }}
                                key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onMouseDown={() => {
                                  setDraggedSale(sale);
                                }}
                                onMouseUp={() => {
                                  setDraggedSale(null);
                                }}
                              >
                                {/* Contenu de la ligne */}
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {getContactDescription(sale.client)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.deliveryTime
                                    ? dayjs(sale.deliveryTime).format("HH:mm")
                                    : ""}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.soldItems.map((item, index) => (
                                    <FlexContainer
                                      textAlign={"center"}
                                      key={index}
                                    >
                                      • {item.quantity + " " + item.name}
                                    </FlexContainer>
                                  ))}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.deliveryInstructions}
                                </TableCell>
                                <TableCell>
                                  {sale.deliverSale && (
                                    <FlexContainer justifyContent="center">
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          let tmpArray = [...sales];
                                          tmpArray[index] = {
                                            ...sale,
                                            deliverSale: false,
                                          };
                                          setSales(tmpArray);
                                        }}
                                        size="large"
                                      >
                                        <CloseIcon fontSize="small" />
                                      </IconButton>
                                    </FlexContainer>
                                  )}
                                  {!sale.deliverSale && (
                                    <FlexContainer justifyContent="center">
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          let tmpArray = [...sales];
                                          tmpArray[index] = {
                                            ...sale,
                                            deliverSale: true,
                                          };
                                          setSales(tmpArray);
                                        }}
                                        size="large"
                                      >
                                        <AddCircleTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </FlexContainer>
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) {
              cloneRoute();
            } else setIsLoading(false);
          }}
        >
          Cloner
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const CloneRoute = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getRoutesAction: getRoutesAction,
      },
      dispatch
    )
)(_CloneRoute);

export default CloneRoute;
