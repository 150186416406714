import EventBusyIcon from "@mui/icons-material/EventBusy";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { VacationTables } from "./VacationTables";

export interface VacationProps {}

const Vacations: React.FC<VacationProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Congés"
        subTitle="Liste des congés"
        icon={<EventBusyIcon fontSize="large" color="primary" />}
      />
      <VacationTables />
    </BlockContainer>
  );
};

export default Vacations;
