import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Comment } from "../../../../../models/comment";
import { Contact } from "../../../../../models/contact";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { Invoice } from "../../../../../models/invoice";
import { Sale } from "../../../../../models/sale";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getInvoicesAction } from "../../../../../store/Invoice/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getSalesAction } from "../../../../../store/Sale/actions";
import {
  getClients,
  getEmployees,
  getInvoices,
  getSales,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  COMPLAINT_FORMAT,
  COMPLAINT_INCREMENT,
  COMPLAINT_NUMBER_OF_DIGITS,
  COMPLAINT_TAGS,
  getContactDescription,
  getEmployeeDescription,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";
import Comments from "../../Opportunities/AddOpportunity/Comments";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface AddComplaintProps {
  popupActive: boolean;
  clients: Contact[];
  sales: Sale[];
  invoices: Invoice[];
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getClientsAction: typeof getClientsAction;
  getEmployeesAction: typeof getEmployeesAction;
  getSalesAction: typeof getSalesAction;
  getInvoicesAction: typeof getInvoicesAction;
  actionsOnAdd?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _AddComplaint: React.FC<AddComplaintProps> = ({
  popupActive,
  clients,
  sales,
  invoices,
  employees,
  setPopupActive,
  getClientsAction,
  getEmployeesAction,
  getSalesAction,
  getInvoicesAction,
  actionsOnAdd,
}) => {
  /* TODO */
  const addComplaint = () => {
    AxiosHttpClient.post("api/v1/weeventpro/complaints", {
      id: "",
      complaintNumber: inputComplaintNumber,
      title: inputTitle,
      clientId: inputClient?.id,
      responsiblesIds: inputResponsibles.map((responsible) => responsible.id),
      complaintDate: inputComplaintDate
        ? inputComplaintDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      expectedResolutionDate: inputExpectedResolutionDate
        ? inputExpectedResolutionDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      resolutionDate: inputResolutionDate
        ? inputResolutionDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      tags: inputTags,
      category: inputCategory,
      priority: inputPriority,
      complaintChannel: inputComplaintChannel,
      status: inputStatus,
      description: inputDescription,
      associatedSalesIds: inputAssociatedSales.map((sale) => sale.id),
      associatedInvoicesIds: inputAssociatedInvoices.map(
        (invoice) => invoice.id
      ),
      comments: inputComments.map((comment) => {
        return {
          id: comment.id,
          content: comment.content,
          commentWriterId: comment.commentWriter?.id,
          files: comment.files,
          creationDateTime: comment.creationDateTime,
        };
      }),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addComplaintDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/complaint"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        COMPLAINT_FORMAT +
        "," +
        COMPLAINT_NUMBER_OF_DIGITS +
        "," +
        COMPLAINT_INCREMENT +
        "," +
        COMPLAINT_TAGS,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(
        COMPLAINT_INCREMENT,
        res
      );
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(COMPLAINT_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        COMPLAINT_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputComplaintNumber(invoiceNumber);

      const complaintTags = getUserParameterValueByName(COMPLAINT_TAGS, res);
      setSavedTags(complaintTags ? complaintTags.split(",") : []);
    });
  };

  const validateForm = () => {
    setInputTitleError(false);
    setInputClientError(false);
    setInputStartDateError(false);
    let result = true;
    if (inputTitle === "") {
      setInputTitleError(true);
      result = false;
    }
    if (!inputClient) {
      setInputClientError(true);
      result = false;
    }
    if (inputComplaintDate === null) {
      setInputStartDateError(true);
      result = false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addComplaintDialogContentId",
      });
    }
    return result;
  };

  const [inputDescription, setInputDescription] = useState("");

  const [inputTitle, setInputTitle] = useState("");

  const [inputTitleError, setInputTitleError] = useState(false);

  const [inputClient, setInputClient] = useState<Contact | null>(null);

  const [inputClientError, setInputClientError] = useState(false);

  const [inputClientText, setInputClientText] = useState("");

  const [inputComplaintDate, setInputComplaintDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputComplaintDateError, setInputStartDateError] = useState(false);

  const [inputExpectedResolutionDate, setInputExpectedResolutionDate] =
    useState<Dayjs | null>(null);

  const [inputResolutionDate, setInputResolutionDate] = useState<Dayjs | null>(
    null
  );

  const [inputResponsibles, setInputResponsibles] = useState<Employee[]>([]);

  const [inputResponsiblesText, setInputResponsiblesText] = useState("");

  const [inputAssociatedSales, setInputAssociatedSales] = useState<Sale[]>([]);

  const [inputAssociatedSalesText, setInputAssociatedSalesText] = useState("");

  const [inputAssociatedInvoices, setInputAssociatedInvoices] = useState<
    Invoice[]
  >([]);

  const [inputAssociatedInvoicesText, setInputAssociatedInvoicesText] =
    useState("");

  const [inputTags, setInputTags] = useState<string[]>([]);

  const [inputTagsText, setInputTagsText] = useState("");

  const [savedTags, setSavedTags] = useState<string[]>([]);

  const [inputCategory, setInputCategory] = useState("");

  const [inputCategoryText, setInputCategoryText] = useState("");

  const [inputPriority, setInputPriority] = useState("Moyenne");

  const [inputComplaintChannel, setInputComplaintChannel] = useState("");

  const [inputComplaintNumber, setInputComplaintNumber] = useState("");

  const [inputComments, setInputComments] = useState<Comment[]>([]);

  const [inputStatus, setInputStatus] = useState("En cours");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterClientsOptions = createFilterOptions<Contact | null>({
    stringify: (option) => getContactDescription(option),
    limit: 20,
  });

  const filterEmployeesOptions = createFilterOptions<Employee>({
    stringify: (option) => getEmployeeDescription(option),
    limit: 20,
  });

  const filterSalesOptions = createFilterOptions<Sale>({
    stringify: (option) => option.saleNumber,
    limit: 20,
  });

  const filterInvoicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
    limit: 20,
  });

  useEffect(() => {
    getClientsAction("");
    getEmployeesAction("");
    getSalesAction("");
    getInvoicesAction("");
    getCustomFields();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientsAction]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une réclamation"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addComplaintDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="title"
                label="Titre"
                error={inputTitleError}
                value={inputTitle}
                onChange={(e) => {
                  setInputTitle(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="clients"
                filterOptions={filterClientsOptions}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                noOptionsText={"Pas de suggestions"}
                fullWidth
                getOptionLabel={(option) => {
                  return getContactDescription(option);
                }}
                options={clients}
                value={inputClient}
                onChange={(event, newValue) => {
                  setInputClient(newValue!);
                }}
                inputValue={inputClientText}
                onInputChange={(event, newInputValue) => {
                  setInputClientText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option?.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {getContactDescription(option)}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputClientError}
                    variant="outlined"
                    name="client"
                    label="Client"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="responsibles"
                filterOptions={filterEmployeesOptions}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                multiple
                disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.firstName + " " + option.lastName;
                }}
                options={employees}
                value={inputResponsibles}
                onChange={(event, newValue) => {
                  setInputResponsibles(newValue!);
                }}
                inputValue={inputResponsiblesText}
                onInputChange={(event, newInputValue) => {
                  setInputResponsiblesText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {option.firstName + " " + option.lastName}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="responsibles"
                    label="Responsables"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de la réclamation"
                  inputFormat="DD/MM/YYYY"
                  value={inputComplaintDate}
                  onChange={(value) => setInputComplaintDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputComplaintDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de résolution prévue"
                  inputFormat="DD/MM/YYYY"
                  value={inputExpectedResolutionDate}
                  onChange={(value) => setInputExpectedResolutionDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="tags"
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                filterSelectedOptions
                options={savedTags}
                value={inputTags}
                onChange={(event, newValue) => {
                  setInputTags(newValue!);
                }}
                inputValue={inputTagsText}
                onInputChange={(event, newInputValue) => {
                  setInputTagsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="tags"
                    label="Tags"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="category"
                noOptionsText={"Pas de suggestions"}
                fullWidth
                disableClearable
                freeSolo
                options={[
                  "Produit défectueux",
                  "Produit manquant",
                  "Mauvais article livré",
                  "Service non conforme",
                  "Problème de livraison",
                  "Problème de qualité",
                  "Erreur de commande",
                  "Facturation incorrecte",
                ]}
                value={inputCategory}
                onChange={(event, newValue) => {
                  setInputCategory(newValue!);
                }}
                inputValue={inputCategoryText}
                onInputChange={(event, newInputValue) => {
                  setInputCategoryText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="category"
                    label="Catégorie"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Priorité</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputPriority}
                  label="Priorité"
                  onChange={(e) => {
                    setInputPriority(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Basse">Basse</MenuItem>
                  <MenuItem value="Moyenne">Moyenne</MenuItem>
                  <MenuItem value="Haute">Haute</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Canal de réclamation
                </InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputComplaintChannel}
                  label="Canal de réclamation"
                  onChange={(e) => {
                    setInputComplaintChannel(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Téléphone">Téléphone</MenuItem>
                  <MenuItem value="SMS">SMS</MenuItem>
                  <MenuItem value="E-mail">E-mail</MenuItem>
                  <MenuItem value="Site web">Site web</MenuItem>
                  <MenuItem value="Courrier postal">Courrier postal</MenuItem>
                  <MenuItem value="En personne">En personne</MenuItem>
                  <MenuItem value="Réseaux sociaux">Réseaux sociaux</MenuItem>
                  <MenuItem value="Chat en direct">Chat en direct</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputStatus}
                  label="Statut"
                  onChange={(e) => {
                    setInputStatus(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="En attente">En attente</MenuItem>
                  <MenuItem value="En cours">En cours</MenuItem>
                  <MenuItem value="Résolue">Résolue</MenuItem>
                  <MenuItem value="Abandonnée">Abandonnée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {inputStatus === "Résolue" && (
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date de résolution"
                    inputFormat="DD/MM/YYYY"
                    value={inputResolutionDate}
                    onChange={(value) => setInputResolutionDate(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                multiline
                rows={4}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Associations
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="associatedSales"
              filterOptions={filterSalesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return option.saleNumber;
              }}
              options={sales}
              value={inputAssociatedSales}
              onChange={(event, newValue) => {
                setInputAssociatedSales(newValue!);
              }}
              inputValue={inputAssociatedSalesText}
              onInputChange={(event, newInputValue) => {
                setInputAssociatedSalesText(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="associatedSales"
                  label="Ventes associées"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="associatedInvoices"
              filterOptions={filterInvoicesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return option.invoiceNumber;
              }}
              options={invoices}
              value={inputAssociatedInvoices}
              onChange={(event, newValue) => {
                setInputAssociatedInvoices(newValue!);
              }}
              inputValue={inputAssociatedInvoicesText}
              onInputChange={(event, newInputValue) => {
                setInputAssociatedInvoicesText(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="associatedInvoices"
                  label="Factures associées"
                />
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Commentaires
          </Text20>
        </BlockContainer>

        <Comments
          inputComments={inputComments}
          setInputComments={setInputComments}
        />
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addComplaint();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddComplaint = connect(
  (state: RootState) => ({
    clients: getClients(state),
    employees: getEmployees(state),
    sales: getSales(state),
    invoices: getInvoices(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
        getEmployeesAction: getEmployeesAction,
        getSalesAction: getSalesAction,
        getInvoicesAction: getInvoicesAction,
      },
      dispatch
    )
)(_AddComplaint);

export default AddComplaint;
