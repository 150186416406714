import { Invoice } from "../models/invoice";
import { Template } from "../models/template";
import { AxiosHttpClient } from "./AxiosHttpService";

export class InvoiceService {
  static getInvoices = (input: any) => {
    return AxiosHttpClient.get<Invoice[]>("api/v1/weeventpro/invoices", input);
  };

  static getInvoiceTemplates = () => {
    return AxiosHttpClient.get<Template[]>(
      "api/v1/weeventpro/invoice/template"
    );
  };

  static getCreditNoteTemplates = () => {
    return AxiosHttpClient.get<Template[]>(
      "api/v1/weeventpro/creditNote/template"
    );
  };
}
