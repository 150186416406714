import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { BlockContainer } from "../../../styles/BlockContainer";
import { blogPosts } from "../../Reusable/Utils";
import ContactSection from "../ContactSection";
import Footer from "../Footer";
import Header from "../Header";

const Blog: React.FC = () => {
  return (
    <BlockContainer position="relative" overflow="hidden">
      <Header />
      <Box sx={{ padding: { xs: "40px 16px", md: "80px 128px" } }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          marginBottom={4}
          color="primary.main"
        >
          Articles du blog
        </Typography>
        <Grid
          container
          spacing={4}
          justifyContent="flex-start"
          alignItems="stretch"
        >
          {blogPosts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.03)" },
                }}
              >
                <CardMedia
                  component="img"
                  height="180"
                  image={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/${post.image}`}
                  alt={post.title}
                />
                <CardContent
                  sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color="secondary.main"
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ flexGrow: 1, marginBottom: "16px" }}
                  >
                    {post.description}
                  </Typography>
                  <Box mt="auto" marginBottom={"16px"}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ display: "block", marginBottom: "8px" }}
                    >
                      {post.date}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      component={Link}
                      to={`/blog/${index}`} // Lien vers l'article détaillé
                      sx={{
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                        "&:hover": { backgroundColor: "primary.dark" },
                      }}
                    >
                      Lire la suite
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ContactSection />
      <Footer />
    </BlockContainer>
  );
};

export default Blog;
