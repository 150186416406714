import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SmsTemplate } from "../../../../../../../models/smsTemplate";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteSupplierOrderSmsTemplateProps {
  popupActive: SmsTemplate | null;
  setPopupActive: React.Dispatch<React.SetStateAction<SmsTemplate | null>>;
  getSupplierOrderSmsTemplates: () => void;
}

const DeleteSupplierOrderSmsTemplate: React.FC<
  DeleteSupplierOrderSmsTemplateProps
> = (props) => {
  const deleteSupplierOrderSmsTemplate = (
    supplierOrderSmsTemplateId: string
  ) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/template/supplierOrder/sms/" +
        supplierOrderSmsTemplateId
    )
      .then(() => {
        props.getSupplierOrderSmsTemplates();
        props.setPopupActive(null);
        setIsDeleteSupplierOrderSmsTemplateLoading(false);
      })
      .catch((err) => {
        setIsDeleteSupplierOrderSmsTemplateLoading(false);
      });
  };

  const [
    isDeleteSupplierOrderSmsTemplateLoading,
    setIsDeleteSupplierOrderSmsTemplateLoading,
  ] = useState(false);

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isDeleteSupplierOrderSmsTemplateLoading}
          loading={isDeleteSupplierOrderSmsTemplateLoading}
          onClick={() => {
            deleteSupplierOrderSmsTemplate(props.popupActive!.id);
            setIsDeleteSupplierOrderSmsTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSupplierOrderSmsTemplate;
