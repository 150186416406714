import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import {
  BaseIngredient,
  Ingredient,
  Unit,
} from "../../../../../models/ingredients";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getIngredients } from "../../../../../store/selectors";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ThemeCustom } from "../../../../../styles/Utils";
import MapFields from "./MapFields";
import UploadFile from "./UploadFile";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

export interface ImportIngredientsProps {
  inputSearch: string;
  popupActive: boolean;
  ingredients: Ingredient[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
}

const _ImportIngredients: React.FC<ImportIngredientsProps> = (props) => {
  const addIngredients = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/ingredients/multiple",
      constructedIngredients
    )
      .then(() => {
        props.getIngredientsAction(props.inputSearch);
        props.setPopupActive(false);
      })
      .catch((err) => {});
  };

  const allergies = [
    "Arachides",
    "Céleri",
    "Crustacés",
    "Fruits à coques",
    "Gluten",
    "Lait",
    "Lupins",
    "Mollusques",
    "Moutarde",
    "Oeufs",
    "Poissons",
    "Soja",
    "Sulfites",
    "Sésame",
  ];

  const getConstructedIngredients = () => {
    let errors: string[] = [];
    let ingredients: Ingredient[] = [];

    for (let i = 1; i < fetchedValues.length; i++) {
      let purchaseUnits: Unit[] = [];
      let storageUnits: Unit[] = [];
      let productionUnits: Unit[] = [];
      let isKgBased = false;
      let isLBased = false;

      /*let purchaseUnitsText = getValue("Unités d'achat", i).split(
        /(?<=[^\d]),/g
      );*/

      /*for (var purchaseUnitText of purchaseUnitsText) {
        if (purchaseUnitText !== "") {
          if (purchaseUnitText.includes("(")) {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                purchaseUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/\d+\.?\d*/ /*g, "")
                  .replace(")", "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  purchaseUnitText
                    .split("(")[1]
                    .replace(",", ".")
                    .replace(/\d+\.?\d*/ /*g, "")
                    .replace(")", "")
                    .replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );
            if (
              purchaseUnitText.split("(")[0].replace(/^[ \t]+|[ \t]+$/g, "") ===
                "" ||
              purchaseUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/[^\d.-]/g, "")
                .replace(/^[ \t]+|[ \t]+$/g, "") === ""
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": Format d'unité d'achat incorrecte"
              );

            let unit: Unit = {
              id: uuid(),
              unit: "Personnalisée",
              customUnit: purchaseUnitText
                .split("(")[0]
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceUnit: purchaseUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/\d+\.?\d*/ /*g, "")
                .replace(")", "")
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceValue: Number(
                purchaseUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/[^\d.-]/g, "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              ),
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            purchaseUnits.push(unit);
          } else {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                purchaseUnitText.replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  purchaseUnitText.replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );

            let unit: Unit = {
              id: uuid(),
              unit: purchaseUnitText.replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnit: "",
              customUnitCorrespondanceUnit: "",
              customUnitCorrespondanceValue: 0,
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            purchaseUnits.push(unit);
          }
        }
      }*/

      /*let storageUnitsText = getValue("Unités de stockage", i).split(
        /(?<=[^\d]),/g
      );*/

      /*for (var storageUnitText of storageUnitsText) {
        if (storageUnitText !== "") {
          if (storageUnitText.includes("(")) {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                storageUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/\d+\.?\d*/ /*g, "")
                  .replace(")", "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  storageUnitText
                    .split("(")[1]
                    .replace(",", ".")
                    .replace(/\d+\.?\d*/ /*g, "")
                    .replace(")", "")
                    .replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );
            if (
              storageUnitText.split("(")[0].replace(/^[ \t]+|[ \t]+$/g, "") ===
                "" ||
              storageUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/[^\d.-]/g, "")
                .replace(/^[ \t]+|[ \t]+$/g, "") === ""
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": Format d'unité de stockage incorrecte"
              );

            let unit: Unit = {
              id: uuid(),
              unit: "Personnalisée",
              customUnit: storageUnitText
                .split("(")[0]
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceUnit: storageUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/\d+\.?\d*/ /*g, "")
                .replace(")", "")
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceValue: Number(
                storageUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/[^\d.-]/g, "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              ),
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            storageUnits.push(unit);
          } else {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                storageUnitText.replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  storageUnitText.replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );

            let unit: Unit = {
              id: uuid(),
              unit: storageUnitText.replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnit: "",
              customUnitCorrespondanceUnit: "",
              customUnitCorrespondanceValue: 0,
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            storageUnits.push(unit);
          }
        }
      }*/

      /*let productionUnitsText = getValue("Unités de production", i).split(
        /(?<=[^\d]),/g
      );*/

      /*for (var productionUnitText of productionUnitsText) {
        if (productionUnitText !== "") {
          if (productionUnitText.includes("(")) {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                productionUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/\d+\.?\d*/ /*g, "")
                  .replace(")", "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  productionUnitText
                    .split("(")[1]
                    .replace(",", ".")
                    .replace(/\d+\.?\d*/ /*g, "")
                    .replace(")", "")
                    .replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );
            if (
              productionUnitText
                .split("(")[0]
                .replace(/^[ \t]+|[ \t]+$/g, "") === "" ||
              productionUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/[^\d.-]/g, "")
                .replace(/^[ \t]+|[ \t]+$/g, "") === ""
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": Format d'unité de production incorrecte"
              );

            let unit: Unit = {
              id: uuid(),
              unit: "Personnalisée",
              customUnit: productionUnitText
                .split("(")[0]
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceUnit: productionUnitText
                .split("(")[1]
                .replace(",", ".")
                .replace(/\d+\.?\d*/ /*g, "")
                .replace(")", "")
                .replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnitCorrespondanceValue: Number(
                productionUnitText
                  .split("(")[1]
                  .replace(",", ".")
                  .replace(/[^\d.-]/g, "")
                  .replace(/^[ \t]+|[ \t]+$/g, "")
              ),
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            productionUnits.push(unit);
          } else {
            if (
              !["Kg", "g", "mg", "L", "mL"].includes(
                productionUnitText.replace(/^[ \t]+|[ \t]+$/g, "")
              )
            )
              errors.push(
                "L'ingrédient " +
                  getValue("Nom", i) +
                  ": L'unité " +
                  productionUnitText.replace(/^[ \t]+|[ \t]+$/g, "") +
                  " n'est pas reconnue!"
              );

            let unit: Unit = {
              id: uuid(),
              unit: productionUnitText.replace(/^[ \t]+|[ \t]+$/g, ""),
              customUnit: "",
              customUnitCorrespondanceUnit: "",
              customUnitCorrespondanceValue: 0,
            };

            if (unitIsKgBased(unit)) isKgBased = true;

            if (unitIsLBased(unit)) isLBased = true;

            productionUnits.push(unit);
          }
        }
      }*/

      if (isKgBased && isLBased)
        errors.push(
          "Les unités d'achat, de stockage et de production sont incompatibles. Certaines sont basées sur le gramme et d'autres sur le litre"
        );

      let customFieldValues: CustomFieldValue[] = [];

      for (var customField of customFields) {
        if (getValue(customField.name, i) !== "") {
          customFieldValues.push({
            id: uuid(),
            customFieldId: customField.id,
            values: getValue(customField.name, i).split(","),
          });
        }
      }
      if (
        props.ingredients
          .map((ingredient) => ingredient.name)
          .includes(getValue("Nom", i))
      )
        errors.push("L'ingrédient " + getValue("Nom", i) + " existe déjà");

      getValue("Allergies", i)
        .split(",")
        .map((allergy) => {
          if (
            !allergies.includes(allergy.replace(/^[ \t]+|[ \t]+$/g, "")) &&
            allergy.replace(/^[ \t]+|[ \t]+$/g, "") !== ""
          )
            errors.push(
              "L'allergene " +
                allergy.replace(/^[ \t]+|[ \t]+$/g, "") +
                " pour l'ingrédient " +
                getValue("Nom", i) +
                " est inconnu"
            );
          return null;
        });

      if (
        getValue("Quantité minimale", i)
          .replace(",", ".")
          .replace(/\d+\.?\d*/g, "")
          .replace(")", "")
          .replace(/^[ \t]+|[ \t]+$/g, "") !== "" &&
        storageUnits.filter(
          (storageUnit) =>
            storageUnit.name ===
            getValue("Quantité minimale", i)
              .replace(",", ".")
              .replace(/\d+\.?\d*/g, "")
              .replace(")", "")
              .replace(/^[ \t]+|[ \t]+$/g, "")
        ).length === 0
      )
        errors.push(
          "L'unité de stockage " +
            getValue("Quantité minimale", i)
              .replace(",", ".")
              .replace(/\d+\.?\d*/g, "")
              .replace(")", "")
              .replace(/^[ \t]+|[ \t]+$/g, "") +
            " n'existe pas"
        );

      if (
        getValue("Quantité minimale", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, "") !== "" &&
        getValue("Quantité minimale", i)
          .replace(",", ".")
          .replace(/\d+\.?\d*/g, "")
          .replace(")", "")
          .replace(/^[ \t]+|[ \t]+$/g, "") === ""
      )
        errors.push(
          "L'ingrédient " +
            getValue("Nom", i) +
            ": Une valeur est spécifiée pour la quantité minimale sans unité"
        );

      if (
        getValue("Quantité idéale", i)
          .replace(",", ".")
          .replace(/\d+\.?\d*/g, "")
          .replace(")", "")
          .replace(/^[ \t]+|[ \t]+$/g, "") !== "" &&
        storageUnits.filter(
          (storageUnit) =>
            storageUnit.name ===
            getValue("Quantité idéale", i)
              .replace(",", ".")
              .replace(/\d+\.?\d*/g, "")
              .replace(")", "")
              .replace(/^[ \t]+|[ \t]+$/g, "")
        ).length === 0
      )
        errors.push(
          "L'unité de stockage " +
            getValue("Quantité idéale", i)
              .replace(",", ".")
              .replace(/\d+\.?\d*/g, "")
              .replace(")", "")
              .replace(/^[ \t]+|[ \t]+$/g, "") +
            " n'existe pas"
        );

      if (
        getValue("Quantité idéale", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, "") !== "" &&
        getValue("Quantité idéale", i)
          .replace(",", ".")
          .replace(/\d+\.?\d*/g, "")
          .replace(")", "")
          .replace(/^[ \t]+|[ \t]+$/g, "") === ""
      )
        errors.push(
          "L'ingrédient " +
            getValue("Nom", i) +
            ": Une valeur est spécifiée pour la quantité idéale sans unité"
        );

      if (
        getValue("Durée d'expiration", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, "") !== "" &&
        !["Jours", "Mois", "Années"].includes(
          getValue("Durée d'expiration", i)
            .replace(/\d+\.?\d*/g, "")
            .replace(/^[ \t]+|[ \t]+$/g, "")
        )
      )
        errors.push(
          "L'unité de mesure de la durée d'expiration" +
            getValue("Durée d'expiration", i)
              .replace(/\d+\.?\d*/g, "")
              .replace(/^[ \t]+|[ \t]+$/g, "") +
            " n'est pas reconnue. Utilisez l'une des trois valeurs suivantes: (Jours, Mois, Années) "
        );

      ingredients.push({
        id: uuid(),
        imageUrl:
          baseIngredients.filter(
            (baseIngredient) => baseIngredient.name === getValue("Nom", i)
          ).length > 0 && importDataFromDB
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].imageUrl
            : "https://" +
              process.env.REACT_APP_BUCKET_NAME! +
              ".s3.eu-west-3.amazonaws.com/DefaultImages/default-ingredient.svg",
        name: getValue("Nom", i),
        minimalQuantity: Number(
          getValue("Quantité minimale", i)
            .replace(",", ".")
            .replace(/^[ \t]+|[ \t]+$/g, "")
            .replace(/[^\d.-]/g, "")
        ),
        idealQuantity: Number(
          getValue("Quantité idéale", i)
            .replace(",", ".")
            .replace(/^[ \t]+|[ \t]+$/g, "")
            .replace(/[^\d.-]/g, "")
        ),
        category: getValue("Catégorie", i),
        ingredientCode: getValue("Référence", i),
        suggestionPerEvent:
          getValue("Suggestion par évènement", i) === "Oui" ? true : false,
        storageRecords: [],
        ingredientForSale: getValue("À vendre", i) === "Oui" ? true : false,
        handleExpiry:
          getValue("Gestion péremption", i) === "Oui" ? true : false,
        handlePurchaseOptions:
          getValue("Gestion des options d'achat", i) === "Oui" ? true : false,
        averageExpiryDelay: Number(
          getValue("Durée d'expiration", i)
            .replace(",", ".")
            .replace(/^[ \t]+|[ \t]+$/g, "")
            .replace(/[^\d.-]/g, "")
        ),
        averageExpiryDelayUnit: getValue("Durée d'expiration", i)
          .replace(/\d+\.?\d*/g, "")
          .replace(/^[ \t]+|[ \t]+$/g, ""),
        globalStorageUnit:
          storageUnits.filter(
            (storageUnit) =>
              storageUnit.name ===
              getValue("Quantité minimale", i)
                .replace(",", ".")
                .replace(/\d+\.?\d*/g, "")
                .replace(")", "")
                .replace(/^[ \t]+|[ \t]+$/g, "")
          ).length === 0
            ? null
            : storageUnits.filter(
                (storageUnit) =>
                  storageUnit.name ===
                  getValue("Quantité minimale", i)
                    .replace(",", ".")
                    .replace(/\d+\.?\d*/g, "")
                    .replace(")", "")
                    .replace(/^[ \t]+|[ \t]+$/g, "")
              )[0],
        purchaseUnits: purchaseUnits,
        storageUnits: storageUnits,
        productionUnits: productionUnits,
        saleUnits: [],
        saleOptions: [],
        unitBased:
          purchaseUnits.length === 0 &&
          storageUnits.length === 0 &&
          storageUnits.length === 0,
        allergies: (!importDataFromDB
          ? getValue("Allergies", i).replace(/^[ \t]+|[ \t]+$/g, "")
          : baseIngredients.filter(
              (baseIngredient) => baseIngredient.name === getValue("Nom", i)
            ).length > 0
          ? baseIngredients.filter(
              (baseIngredient) => baseIngredient.name === getValue("Nom", i)
            )[0].allergens
          : ""
        )
          .split(",")
          .map((allergie) => allergie.replace(/^[ \t]+|[ \t]+$/g, "")),
        purchaseOptions: [],
        energyKj: Number(
          !importDataFromDB
            ? getValue("Énergie (Kj)", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].energyKj
            : ""
        ),
        energyKcal: Number(
          !importDataFromDB
            ? getValue("Énergie (Kcal)", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].energyKcal
            : ""
        ),
        proteins: Number(
          !importDataFromDB
            ? getValue("Protéines", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].proteins
            : ""
        ),
        carbohydrates: Number(
          !importDataFromDB
            ? getValue("Glucides", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].carbohydrates
            : ""
        ),
        lipids: Number(
          !importDataFromDB
            ? getValue("Lipides", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].lipids
            : ""
        ),
        sugar: Number(
          !importDataFromDB
            ? getValue("Sucres", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].sugar
            : ""
        ),
        saturatedFattyAcids: Number(
          !importDataFromDB
            ? getValue("Acides gras saturés", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].saturatedFattyAcids
            : ""
        ),
        salt: Number(
          !importDataFromDB
            ? getValue("Sel", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].salt
            : ""
        ),
        calcium: Number(
          !importDataFromDB
            ? getValue("Calcium", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].calcium
            : ""
        ),
        vitaminD: Number(
          !importDataFromDB
            ? getValue("Vitamine D", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].vitaminD
            : ""
        ),
        dietaryFiber: Number(
          !importDataFromDB
            ? getValue("Fibres alimentaires", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].dietaryFiber
            : ""
        ),
        cholesterol: Number(
          !importDataFromDB
            ? getValue("Cholestérol", i)
            : baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              ).length > 0
            ? baseIngredients.filter(
                (baseIngredient) => baseIngredient.name === getValue("Nom", i)
              )[0].cholesterol
            : ""
        ),
        customFields: customFieldValues,
      });
    }
    setOutputErrors(errors);
    setConstructedIngredients(ingredients);
  };

  const readImportFile = () => {
    const data = new FormData();
    data.append("importFile", inputTemplate);

    AxiosHttpClient.put<string[][]>(
      "api/v1/weeventpro/ingredients/readImportFile",
      data
    )
      .then((res) => {
        setFetchedValues(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/ingredient"
    ).then((response) => {
      setCustomFields(response);
    });
  };

  const getBaseIngredients = () => {
    AxiosHttpClient.get<BaseIngredient[]>(
      "api/v1/weeventpro/baseIngredients/get"
    ).then((response) => {
      setBaseIngredients(response);
    });
  };

  const getValue = (fieldName: string, i: number) => {
    let headers = fetchedValues[0];
    let indexOfField = headers.indexOf(fieldName);
    return indexOfField === -1 ? "" : fetchedValues[i][indexOfField];
  };

  const validateFirstStep = () => {
    if (inputTemplate === null) {
      setFailMessage("Veuillez sélectionner un fichier!");
      return false;
    }
    return true;
  };

  const validateSecondStep = () => {
    if (outputErrors.length > 0) {
      setFailMessage(
        "Fichier non valide. Veuillez corriger les erreurs spécifiées ci-dessous et recommencer!"
      );
      return false;
    }
    return true;
  };

  const [constructedIngredients, setConstructedIngredients] = useState<
    Ingredient[]
  >([]);

  const [inputTemplate, setInputTemplate] = useState<any>(null);

  const [fetchedValues, setFetchedValues] = useState<string[][]>([[]]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [baseIngredients, setBaseIngredients] = useState<BaseIngredient[]>([]);

  const [step, setStep] = useState(0);

  const [importDataFromDB, setImportDataFromDB] = useState(true);

  const [outputErrors, setOutputErrors] = useState<string[]>([]);

  const [failMessage, setFailMessage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    getCustomFields();
    getBaseIngredients();
  }, []);

  useEffect(() => {
    getConstructedIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedValues]);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Importer des ingrédients"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui dividers>
        {failMessage !== "" && (
          <BlockContainer margin="4px">
            <Alert
              severity="error"
              onClose={() => {
                setFailMessage("");
              }}
            >
              {failMessage}
            </Alert>
          </BlockContainer>
        )}
        {step === 0 && (
          <UploadFile
            inputTemplate={inputTemplate}
            importDataFromDB={importDataFromDB}
            setInputTemplate={setInputTemplate}
            setImportDataFromDB={setImportDataFromDB}
          />
        )}
        {step === 1 && (
          <MapFields
            ingredients={constructedIngredients}
            fetchedValues={fetchedValues}
            outputErrors={outputErrors}
            popupActive={props.popupActive}
            setPopupActive={props.setPopupActive}
          />
        )}
      </DialogContentMui>
      <DialogActions>
        {step === 1 && (
          <ButtonMui
            onClick={() => {
              setStep(step - 1);
              setFailMessage("");
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Précédent
          </ButtonMui>
        )}
        {step === 1 ? (
          <ButtonMui
            onClick={() => {
              if (validateSecondStep()) addIngredients();
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Importer
          </ButtonMui>
        ) : (
          <ButtonMui
            onClick={() => {
              if (validateFirstStep()) {
                setFailMessage("");
                readImportFile();
                setStep(step + 1);
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Suivant
          </ButtonMui>
        )}
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const ImportIngredients = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_ImportIngredients);

export default ImportIngredients;
