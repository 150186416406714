import { Route } from "../../models/route";

export const GET_ROUTES = "GET_ROUTES" as const;
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS" as const;
export const GET_ROUTES_FAILURE = "GET_ROUTES_FAILURE" as const;

export const getRoutesAction = (criteriaList?: string) => ({
  type: GET_ROUTES,
  criteriaList: criteriaList,
});
export type GetRoutesAction = ReturnType<typeof getRoutesAction>;

export const getRoutesActionSuccess = (routes: Route[]) => ({
  type: GET_ROUTES_SUCCESS,
  routes: routes,
});
export type GetRoutesActionSuccess = ReturnType<typeof getRoutesActionSuccess>;

export const getRoutesActionFailure = () => ({
  type: GET_ROUTES_FAILURE,
});
export type GetRoutesActionFailure = ReturnType<typeof getRoutesActionFailure>;
