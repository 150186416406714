import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { CommercialCycleStep } from "../../../../../../models/commercialCycle";
import { Dispatch, RootState } from "../../../../../../store";
import { getClientsAction } from "../../../../../../store/Crm/actions";
import { getClients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface CommercialCycleStepsProps {
  inputCommercialCycleSteps: CommercialCycleStep[];
  setInputCommercialCycleSteps: React.Dispatch<
    React.SetStateAction<CommercialCycleStep[]>
  >;
}

const _CommercialCycleSteps: React.FC<CommercialCycleStepsProps> = (props) => {
  const [commercialCycleStepAddActive, setCommercialCycleStepAddActive] =
    useState(false);

  const [commercialCycleStepEditActive, setCommercialCycleStepEditActive] =
    useState("");

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputDuration, setInputDuration] = useState("");

  const [inputDurationUnit, setInputDurationUnit] = useState("day");

  const [inputStatus, setInputStatus] = useState("Ne pas mettre à jour");

  const [inputRecommendedActions, setInputRecommendedActions] = useState("");

  const [inputRisks, setInputRisks] = useState("");

  const [addFailed, setAddFailed] = useState("");

  const resetValidation = () => {
    setAddFailed("");
    setInputNameError(false);
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setCommercialCycleStepAddActive(true);
            setCommercialCycleStepEditActive("");
            setInputName("");
            setInputDuration("");
            setInputDurationUnit("day");
            setInputStatus("Ne pas mettre à jour");
            setInputRecommendedActions("");
            setInputRisks("");
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une étape
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={commercialCycleStepAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="duration"
                label="Durée moyenne"
                value={inputDuration}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputDuration(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        "& .MuiInputBase-root:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:hover:before": {
                          borderBottom: "none !important",
                        },
                      }}
                    >
                      <Select
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select:focus": {
                            borderBottom: "none",
                            backgroundColor: "white",
                          },
                        }}
                        value={inputDurationUnit}
                        onChange={(e) => setInputDurationUnit(e.target.value)}
                      >
                        <MenuItem key="day" value="day">
                          jour(s)
                        </MenuItem>
                        <MenuItem key="week" value="week">
                          semaine(s)
                        </MenuItem>
                        <MenuItem key="month" value="month">
                          mois(s)
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Mettre à jour le statut de l'opportunité
                </InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputStatus}
                  label="Mettre à jour le statut de l'opportunité"
                  onChange={(e) => {
                    setInputStatus(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Ne pas mettre à jour">
                    Ne pas mettre à jour
                  </MenuItem>
                  <MenuItem value="En cours">En cours</MenuItem>
                  <MenuItem value="Gagnée">Gagnée</MenuItem>
                  <MenuItem value="Perdue">Perdue</MenuItem>
                  <MenuItem value="En attente">En attente</MenuItem>
                  <MenuItem value="Abandonnée">Abandonnée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="recommendedActions"
                label="Actions recommandées"
                multiline
                rows={4}
                value={inputRecommendedActions}
                onChange={(e) => setInputRecommendedActions(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="risks"
                label="Risques"
                multiline
                rows={4}
                value={inputRisks}
                onChange={(e) => setInputRisks(e.target.value)}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputCommercialCycleSteps([
                  ...props.inputCommercialCycleSteps,
                  {
                    id: uuid(),
                    name: inputName,
                    duration: inputDuration,
                    durationUnit: inputDurationUnit,
                    status: inputStatus,
                    recommendedActions: inputRecommendedActions,
                    risks: inputRisks,
                  },
                ]);
                setCommercialCycleStepAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setCommercialCycleStepAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputCommercialCycleSteps.map((commercialCycleStep, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer flexDirection="column" alignItems="flex-start">
              <Text16 fontWeight="600">{commercialCycleStep.name}</Text16>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setCommercialCycleStepAddActive(false);
                  setCommercialCycleStepEditActive(commercialCycleStep.id);
                  setInputName(commercialCycleStep.name);
                  setInputDuration(commercialCycleStep.duration);
                  setInputDurationUnit(commercialCycleStep.durationUnit);
                  setInputStatus(commercialCycleStep.status);
                  setInputRecommendedActions(
                    commercialCycleStep.recommendedActions
                  );
                  setInputRisks(commercialCycleStep.risks);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputCommercialCycleSteps];
                  newArr.splice(index, 1);
                  props.setInputCommercialCycleSteps(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={commercialCycleStepEditActive === commercialCycleStep.id}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Nom"
                    error={inputNameError}
                    value={inputName}
                    onChange={(e) => setInputName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="duration"
                    label="Durée moyenne"
                    value={inputDuration}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputDuration(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            "& .MuiInputBase-root:before": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-root:hover:before": {
                              borderBottom: "none !important",
                            },
                          }}
                        >
                          <Select
                            variant="standard"
                            sx={{
                              "& .MuiSelect-select:focus": {
                                borderBottom: "none",
                                backgroundColor: "white",
                              },
                            }}
                            value={inputDurationUnit}
                            onChange={(e) =>
                              setInputDurationUnit(e.target.value)
                            }
                          >
                            <MenuItem key="day" value="day">
                              jour(s)
                            </MenuItem>
                            <MenuItem key="week" value="week">
                              semaine(s)
                            </MenuItem>
                            <MenuItem key="month" value="month">
                              mois(s)
                            </MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mettre à jour le statut de l'opportunité
                    </InputLabel>
                    <Select
                      required
                      fullWidth
                      value={inputStatus}
                      label="Mettre à jour le statut de l'opportunité"
                      onChange={(e) => {
                        setInputStatus(
                          typeof e.target.value === "string"
                            ? e.target.value
                            : ""
                        );
                      }}
                    >
                      <MenuItem value="Ne pas mettre à jour">
                        Ne pas mettre à jour
                      </MenuItem>
                      <MenuItem value="En cours">En cours</MenuItem>
                      <MenuItem value="Gagnée">Gagnée</MenuItem>
                      <MenuItem value="Perdue">Perdue</MenuItem>
                      <MenuItem value="En attente">En attente</MenuItem>
                      <MenuItem value="Abandonnée">Abandonnée</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="recommendedActions"
                    label="Actions recommandées"
                    multiline
                    rows={4}
                    value={inputRecommendedActions}
                    onChange={(e) => setInputRecommendedActions(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="risks"
                    label="Risques"
                    multiline
                    rows={4}
                    value={inputRisks}
                    onChange={(e) => setInputRisks(e.target.value)}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setCommercialCycleStepAddActive(false);
                  resetValidation();
                  if (validateEditForm()) {
                    let tmpArray = [...props.inputCommercialCycleSteps];
                    tmpArray[index] = {
                      id: commercialCycleStep.id,
                      name: inputName,
                      duration: inputDuration,
                      durationUnit: inputDurationUnit,
                      status: inputStatus,
                      recommendedActions: inputRecommendedActions,
                      risks: inputRisks,
                    };

                    props.setInputCommercialCycleSteps(tmpArray);
                    setCommercialCycleStepEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setCommercialCycleStepEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const CommercialCycleSteps = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_CommercialCycleSteps);

export default _CommercialCycleSteps;
