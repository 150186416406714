import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { Task } from "../../../../../../models/task";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../styles/ButtonMui";

export interface DeleteCommentProps {
  task: Task | null;
  popupActive: string[];
  setPopupActive: React.Dispatch<React.SetStateAction<string[]>>;
  actionsOnDelete?: (() => void)[];
}

const DeleteComment: React.FC<DeleteCommentProps> = (props) => {
  const deleteTaskComment = () => {
    if (props.task) {
      AxiosHttpClient.put("api/v1/weeventpro/tasks", {
        id: props.task.id,
        taskNumber: props.task.taskNumber,
        title: props.task.title,
        startDateTime: props.task.startDateTime,
        endDateTime: props.task.endDateTime,
        responsiblesIds: props.task.responsibles.map(
          (responsible) => responsible.id
        ),
        tags: props.task.tags,
        status: props.task.status,
        description: props.task.description,
        complexity: props.task.complexity,
        timeEstimation: props.task.timeEstimation,
        timeEstimationUnit: props.task.timeEstimationUnit,
        priority: props.task.priority,
        associatedOpportunitiesIds: props.task.associatedOpportunities.map(
          (opportunity) => opportunity.id
        ),
        associatedSalesIds: props.task.associatedSales.map((sale) => sale.id),
        associatedRoutesIds: props.task.associatedRoutes.map(
          (route) => route.id
        ),
        associatedComplaintsIds: props.task.associatedComplaints.map(
          (complaint) => complaint.id
        ),
        comments: [
          ...props.task.comments
            .filter((comment) => !props.popupActive.includes(comment.id))
            .map((comment) => {
              return {
                id: comment.id,
                content: comment.content,
                commentWriterId: comment.commentWriter?.id,
                files: comment.files,
                creationDateTime: comment.creationDateTime,
              };
            }),
        ],
        reminderModes: props.task.reminderModes,
        reminderTime: props.task.reminderTime,
        reminderTimeUnit: props.task.reminderTimeUnit,
        visibleBy: props.task.visibleBy.map((visibleBy) => visibleBy.id),
        customFields: props.task.customFields,
      })
        .then(() => {
          props.setPopupActive([]);
          props.actionsOnDelete?.forEach((action) => action());
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={props.popupActive.length !== 0}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive([]);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer un commentaire?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          {props.popupActive.length === 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer ce commentaire
            </React.Fragment>
          )}
          {props.popupActive.length > 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer les commentaires sélectionnés?
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            deleteTaskComment();
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive([])}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteComment;
