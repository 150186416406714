import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import GroupWorkTwoTone from "@mui/icons-material/GroupWorkTwoTone";
import LeaderboardTwoToneIcon from "@mui/icons-material/LeaderboardTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import { Box, Drawer } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../store";
import { setExpandedMenu } from "../../../store/ExpandedMenuItem/actions";
import { getExpandedMenuItem } from "../../../store/selectors";
import {
  AccordionDetailsMui,
  AccordionMui,
  AccordionSummaryMui,
} from "../../../styles/AccordionMui";
import { BlockContainer } from "../../../styles/BlockContainer";
import { ButtonAnchor } from "../../../styles/ButtonAnchor";
import { FlexContainer } from "../../../styles/FlexContainer";
import { SideNavMenuLink } from "../../../styles/SideNavMenuLink";
import { Text14 } from "../../../styles/Text";
import { ThemeCustom } from "../../../styles/Utils";

export interface SideNavMenuProps {
  sideNavShow: boolean;
  expanded: string;
  setExpandedMenu: typeof setExpandedMenu;
  setSideNavShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function DrawerWrapper(props) {
  return (
    <React.Fragment>
      <Drawer
        sx={{ display: { xs: "block", md: "none" } }}
        anchor="left"
        open={props.open}
        onClose={props.onClose}
      >
        {props.children}
      </Drawer>
      <Box sx={{ display: { xs: "none", md: "block" } }}>{props.children}</Box>
    </React.Fragment>
  );
}

const _SideNavMenu: React.FC<SideNavMenuProps> = ({
  sideNavShow,
  expanded,
  setSideNavShow,
  setExpandedMenu,
}) => {
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>) => {
    setExpandedMenu(panel === expanded ? "none" : panel);
  };

  return (
    <DrawerWrapper open={sideNavShow} onClose={() => setSideNavShow(false)}>
      <Box
        sx={{
          background: ThemeCustom.colors.linearOrange,
          height: "100vh",
          width: "258px",
          top: {
            md: "0",
          },
          position: {
            md: "fixed",
          },
          zIndex: "1200",
          overflowY: "auto",

          "&::-webkit-scrollbar": {
            width: "0px",
            height: "0px",
          },

          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "10px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: ThemeCustom.colors.lightOrange,
            borderRadius: "10px",
          },

          "&::-webkit-scrollbar-thumb:hover": {
            background: ThemeCustom.colors.orange,
            borderRadius: "10px",
          },
        }}
      >
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          height="70px"
          margin="8px 0 43px 0"
        >
          <ButtonAnchor href="/feature">
            <FlexContainer
              alignItems="center"
              $cursorHover="pointer"
              onClick={() => {}}
            >
              <div
                style={{
                  position: "relative",
                  width: "85px",
                  height: "85px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Effet blurry progressif */}
                <div
                  style={{
                    position: "absolute",
                    width: "130px", // Taille du halo plus grande
                    height: "130px",
                    borderRadius: "50%",
                    background:
                      "radial-gradient(circle, rgba(255,213,153,0.7) 30%, rgba(255,165,0,0) 90%)",
                    filter: "blur(30px)", // Flou plus intense pour lisser les bords
                    zIndex: 0, // Toujours en arrière-plan
                  }}
                ></div>

                {/* Cercle central avec logo et bords flous */}
                <div
                  style={{
                    width: "65px",
                    height: "65px",
                    background:
                      "radial-gradient(circle, rgba(255,213,153,1) 40%, rgba(255,165,0,0) 100%)", // Dégradé interne
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    zIndex: 1,
                    boxShadow: "0 0 20px rgba(255, 165, 0, 0.4)", // Glow plus doux
                    maskImage:
                      "radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)", // Appliquer un flou progressif
                    WebkitMaskImage:
                      "radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)",
                  }}
                >
                  <img
                    src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/Logo.svg`}
                    alt="Mon SVG"
                    width={50}
                    height={45}
                  />
                </div>
              </div>
            </FlexContainer>
          </ButtonAnchor>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummaryMui
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <WarehouseTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Stock/Fournisseurs
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/ingredients">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Stock alimentaire
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/materials">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Stock matériel
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/storageAreas">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Espaces de stockage
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/suppliers">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Fournisseurs
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/supplierOrders">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Provisionnements
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummaryMui
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <LocalShippingTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Commandes
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/sales">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Ventes
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/delivery">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Livraisons
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummaryMui
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <MenuBookTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Catalogue
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/catalog">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Recettes
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/services">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Services
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummaryMui
              aria-controls="panel5d-content"
              id="panel5d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <FileCopyTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Documents de vente
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/quote">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Devis
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/invoices">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Factures
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummaryMui
              aria-controls="panel6d-content"
              id="panel6d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <GroupWorkTwoTone
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                CRM
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/clients">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Clients
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/prospects">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Prospects
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/opportunities">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Opportunités
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/commercialCycles">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Cycle commercial
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/complaints">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Réclamations
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/interactions">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Interactions
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummaryMui
              aria-controls="panel7d-content"
              id="panel7d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <BadgeTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                RH
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/employees">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Employés
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/vacations">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Gestion des absences
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/tasks">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Tâches
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummaryMui
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <LeaderboardTwoToneIcon
                sx={{ color: "white", fontSize: "30px", margin: "8px" }}
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Statistiques
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/metrics">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Métriques
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>
      </Box>
    </DrawerWrapper>
  );
};

export const SideNavMenu = connect(
  (state: RootState) => ({
    expanded: getExpandedMenuItem(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        setExpandedMenu: setExpandedMenu,
      },
      dispatch
    )
)(_SideNavMenu);

export default SideNavMenu;
