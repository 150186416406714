import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
  CommercialCycle,
  CommercialCycleStep,
} from "../../../../../models/commercialCycle";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import { CommercialCycleSteps } from "../AddCommercialCycle/CommercialCycleSteps";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface EditCommercialCycleProps {
  popupActive: CommercialCycle | null;
  setPopupActive: React.Dispatch<React.SetStateAction<CommercialCycle | null>>;
  actionsOnEdit?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const EditCommercialCycle: React.FC<EditCommercialCycleProps> = ({
  popupActive,
  setPopupActive,
  actionsOnEdit,
}) => {
  /* TODO */
  const editCommercialCycle = () => {
    AxiosHttpClient.put("api/v1/weeventpro/commercialCycles", {
      id: popupActive!.id,
      name: inputName,
      description: inputDescription,
      steps: inputCommercialCycleSteps,
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnEdit?.forEach((action) => action());
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editCommercialCycleDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/commercialCycle"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editCommercialCycleDialogContentId",
      });
    }
    return result;
  };

  const [inputName, setInputName] = useState(popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputDescription, setInputDescription] = useState(
    popupActive!.description
  );

  const [inputCommercialCycleSteps, setInputCommercialCycleSteps] = useState<
    CommercialCycleStep[]
  >(popupActive!.steps);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un cycle commercial"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editCommercialCycleDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Nom du cycle commercial"
                error={inputNameError}
                value={inputName}
                onChange={(e) => {
                  setInputName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                multiline
                rows={4}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Etapes du cycle commercial
          </Text20>
        </BlockContainer>

        <CommercialCycleSteps
          inputCommercialCycleSteps={inputCommercialCycleSteps}
          setInputCommercialCycleSteps={setInputCommercialCycleSteps}
        />
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editCommercialCycle();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommercialCycle;
