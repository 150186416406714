import SetMealTwoToneIcon from "@mui/icons-material/SetMealTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import ServiceTable from "./ServiceTable";

export interface ServiceProps {}

const Service: React.FC<ServiceProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Services"
        subTitle="Liste des services"
        icon={<SetMealTwoToneIcon fontSize="large" color="primary" />}
      />
      <ServiceTable />
    </BlockContainer>
  );
};

export default Service;
