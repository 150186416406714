import GroupWorkIcon from "@mui/icons-material/GroupWork";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Grid } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text00, Text36 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface WhyUsSectionProps {}

const WhyUsSection: React.FC<WhyUsSectionProps> = () => {
  const advantages = [
    {
      icon: (
        <SyncIcon
          sx={{ fontSize: 30, color: CustomTheme.palette.error.main }}
        />
      ),
      text: "Gestion centralisée de votre activité",
    },
    {
      icon: (
        <InventoryIcon
          sx={{ fontSize: 30, color: CustomTheme.palette.error.main }}
        />
      ),
      text: "Stock & Logistique simplifiés",
    },
    {
      icon: (
        <ReceiptLongIcon
          sx={{ fontSize: 30, color: CustomTheme.palette.error.main }}
        />
      ),
      text: "Gestion des ventes et facturation automatisée",
    },
    {
      icon: (
        <GroupWorkIcon
          sx={{ fontSize: 30, color: CustomTheme.palette.error.main }}
        />
      ),
      text: "CRM intégré pour booster vos opportunités",
    },
    {
      icon: (
        <SettingsIcon
          sx={{ fontSize: 30, color: CustomTheme.palette.error.main }}
        />
      ),
      text: "Interface intuitive et personnalisable",
    },
  ];

  return (
    <FlexContainer
      backgroundColor="#FDF7F7"
      sx={{ padding: { xs: "16px", sm: "80px 128px" } }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Image du super-héros */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <img
            src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/superman.svg`}
            alt="Superhero ERP"
            style={{ width: "100%", maxWidth: "400px" }}
          />
        </Grid>

        {/* Liste des avantages */}
        <Grid item xs={12} md={6}>
          <FlexContainer marginBottom="16px">
            <Text36 fontWeight="600">Pourquoi choisir notre ERP ?</Text36>
          </FlexContainer>
          <FlexContainer flexDirection="column" gap="12px">
            {advantages.map((advantage, index) => (
              <FlexContainer key={index} alignItems="center" gap="12px">
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 99, 99, 0.2)",
                  }}
                >
                  {advantage.icon}
                </Box>
                <Text00 fontSize="18px">{advantage.text}</Text00>
              </FlexContainer>
            ))}
          </FlexContainer>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default WhyUsSection;
