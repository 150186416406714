import PixIcon from "@mui/icons-material/Pix";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import CommercialCycleTable from "./CommercialCycleTable";

export interface CommercialCycleProps {}

const CommercialCycles: React.FC<CommercialCycleProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Cycles commerciaux"
        subTitle="Liste des cycles commerciaux"
        icon={<PixIcon fontSize="large" color="primary" />}
      />
      <CommercialCycleTable />
    </BlockContainer>
  );
};

export default CommercialCycles;
