import { RootAction } from "../types";
import {
  GET_CUSTOMER_ORDERS_ARCHIVE_SUCCESS,
  GET_CUSTOMER_ORDERS_SUCCESS,
} from "./actions";
import { CustomerOrderArchiveState, CustomerOrderState } from "./types";

const initialCustomerOrderState: CustomerOrderState = {
  customerOrders: [],
};

const initialCustomerOrderArchiveState: CustomerOrderArchiveState = {
  customerOrdersArchive: [],
};

export function customerOrderReducer(
  state: CustomerOrderState = initialCustomerOrderState,
  action: RootAction
): CustomerOrderState {
  switch (action.type) {
    case GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        customerOrders: action.customerOrders,
      };

    default:
      return state;
  }
}

export function customerOrderArchiveReducer(
  state: CustomerOrderArchiveState = initialCustomerOrderArchiveState,
  action: RootAction
): CustomerOrderArchiveState {
  switch (action.type) {
    case GET_CUSTOMER_ORDERS_ARCHIVE_SUCCESS:
      return {
        customerOrdersArchive: action.customerOrdersArchive,
      };

    default:
      return state;
  }
}
