import FeedbackTwoToneIcon from "@mui/icons-material/FeedbackTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import ComplaintTable from "./ComplaintTable";

export interface ComplaintProps {}

const Complaint: React.FC<ComplaintProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Réclamations"
        subTitle="Liste des réclamations"
        icon={<FeedbackTwoToneIcon fontSize="large" color="primary" />}
      />
      <ComplaintTable />
    </BlockContainer>
  );
};

export default Complaint;
