import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { NotificationService } from "../../services/NotificationService";
import { RootAction } from "../types";
import {
  GET_NOTIFICATIONS,
  GetNotificationsActionFailure,
  getNotificationsActionFailure,
  GetNotificationsActionSuccess,
  getNotificationsActionSuccess,
} from "./actions";

export const notificationListEpic: Epic<
  RootAction,
  GetNotificationsActionSuccess | GetNotificationsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_NOTIFICATIONS),
    switchMap((action) =>
      from(NotificationService.getNotifications(action.page)).pipe(
        mergeMap((res) => {
          return [getNotificationsActionSuccess(res.content)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getNotificationsActionFailure()]))
        )
      )
    )
  );

export const notificationEpic = combineEpics<any>(notificationListEpic);
