import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text20, Text56 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = () => {
  const navigate = useNavigate();
  return (
    <FlexContainer sx={{ padding: { xs: "0 16px", sm: "0 128px" } }}>
      <Grid
        container
        spacing={2}
        columns={{ xs: 1, sm: 6, md: 12 }}
        alignItems="center"
        justifyContent="center"
        sx={{ padding: { xs: "40px 0", md: "80px 0" } }}
      >
        {/* Colonne Gauche - Texte et image horizontale */}
        <Grid item xs={12} md={6}>
          <FlexContainer
            sx={{ textAlign: { xs: "center", md: "left" }, mb: 2 }}
          >
            <Text56 fontWeight="bold">
              Logiciel cloud de gestion ERP pour les professionnels
            </Text56>
          </FlexContainer>

          {/* Image horizontale avec trait */}
          <FlexContainer>
            <Box
              sx={{
                margin: "0 0 40px -16px",
                width: "100%",
                height: "40px", // Ajuste la hauteur du trait
                border: "solid 8px " + CustomTheme.palette.primary.main,
                borderColor:
                  CustomTheme.palette.primary.main +
                  " transparent transparent transparent",
                borderRadius: "50%/40px 40px 0 0", // Forme incurvée
              }}
            />
          </FlexContainer>
          <FlexContainer
            sx={{ textAlign: { xs: "center", md: "left" }, color: "#555" }}
          >
            <Text20>
              Gérez vos stocks, vos clients, vos ventes, vos factures et bien
              plus encore en un seul outil puissant et intuitif.
            </Text20>
          </FlexContainer>
          <FlexContainer marginTop={"40px"}>
            <Button
              sx={{
                marginRight: "16px",
                backgroundColor: CustomTheme.palette.primary.main, // Orange
                color: "white",
                fontFamily: "Roboto", // Utilisation de Roboto
                fontWeight: 500,
                fontSize: 16,
                padding: "18px 24px",
                borderRadius: "40px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: CustomTheme.palette.primary.dark, // Orange plus foncé au survol
                },
              }}
              onClick={() => {
                navigate("signup");
              }}
            >
              Essayer gratuitement
            </Button>
            <Button
              sx={{
                fontFamily: "Roboto", // Utilisation de Roboto
                fontWeight: 500,
                fontSize: 16,
                padding: "8px 16px",
                borderRadius: "40px",
                textTransform: "none",
                color: CustomTheme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: CustomTheme.palette.secondary.main, // Orange plus foncé au survol
                  color: "white",
                },
              }}
              onClick={() => {
                navigate("signup");
              }}
            >
              <PlayCircleOutlineIcon
                sx={{
                  marginRight: "8px",
                }}
                fontSize="large"
              />
              Voir la démo
            </Button>
          </FlexContainer>
        </Grid>
        {/* Colonne Droite - Image principale + petites images */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Box sx={{ position: "relative", width: "62%" }}>
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/main-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                width: "100%",
                borderRadius: "16px",
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/clients-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "-7%", // Position relative à la hauteur de l'image principale
                left: "35%",
                width: "15.2%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimation 2s infinite",
                "@keyframes pulseAnimation": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.2)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/unpaid-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "10%",
                left: "-20%",
                width: "49%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimationSmall 2s infinite",
                "@keyframes pulseAnimationSmall": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.05)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/ca-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "50%",
                left: "80%",
                width: "49%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimationSmall 2s infinite",
                "@keyframes pulseAnimationSmall": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.05)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/sale-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "65%",
                left: "-7%",
                width: "15.2%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimation 2s infinite",
                "@keyframes pulseAnimation": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.2)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/invoice-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "98%",
                left: "70%",
                width: "15.2%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimation 2s infinite",
                "@keyframes pulseAnimation": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.2)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
            <Box
              component="img"
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/storage-hero.svg`}
              alt="Logiciel ERP"
              sx={{
                position: "absolute",
                top: "8%",
                left: "94%",
                width: "15.2%", // Taille proportionnelle
                height: "auto", // Garde le ratio
                borderRadius: "16px",
                animation: "pulseAnimation 2s infinite",
                "@keyframes pulseAnimation": {
                  "0%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    transform: "scale(1.3)",
                  },
                  "100%": {
                    transform: "scale(1)",
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default HeroSection;
