import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { CustomField } from "../../../../../../../models/customField";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../../../styles/Utils";

export interface EditCustomFieldProps {
  popupActive: CustomField | null;
  setPopupActive: React.Dispatch<React.SetStateAction<CustomField | null>>;
  getCustomFields: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const EditCustomField: React.FC<EditCustomFieldProps> = (props) => {
  const editCustomFields = () => {
    AxiosHttpClient.put<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/complaint",
      {
        id: props.popupActive?.id,
        name: inputName,
        type: inputType,
        values: !["Liste déroulante", "Boutton radio", "Checkbox"].includes(
          inputType
        )
          ? []
          : inputValues,
      }
    )
      .then((res) => {
        props.getCustomFields();
        props.setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setInputName("");
    setInputType("");
    setInputValue("");
    setInputValues([]);
  };

  const [inputName, setInputName] = useState(props.popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputType, setInputType] = useState(props.popupActive!.type);

  const [inputTypeError, setInputTypeError] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [inputValueError, setInputValueError] = useState(false);

  const [inputValues, setInputValues] = useState(props.popupActive!.values);

  const [editFailed, setEditFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const validateForm = () => {
    setInputNameError(false);
    setInputTypeError(false);
    setInputValueError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputType === "") {
      setInputTypeError(true);
      result = false;
    }
    if (
      (inputType === "Liste déroulante" ||
        inputType === "Boutton radio" ||
        inputType === "Checkbox") &&
      inputValues.length === 0
    ) {
      setInputValueError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
    }
    return result;
  };

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un champs personnalisé"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="custpomFieldTypeLabel">Type</InputLabel>
                <Select
                  labelId="custpomFieldTypeLabel"
                  id="custpomFieldType"
                  required
                  error={inputTypeError}
                  value={inputType}
                  label="Type"
                  onChange={(e) => setInputType(e.target.value)}
                >
                  <MenuItem value="Texte">Texte</MenuItem>
                  <MenuItem value="Liste déroulante">Liste déroulante</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Boutton radio">Boutton radio</MenuItem>
                  <MenuItem value="Checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {["Liste déroulante", "Boutton radio", "Checkbox"].includes(
              inputType
            ) && (
              <Grid item xs={1} sm={1} md={2} alignItems="center">
                <Text16>
                  Veuillez saisir les valeur possibles pour ce champs!
                </Text16>
                <FlexContainer alignItems="center">
                  <TextField
                    fullWidth
                    name="name"
                    label="Valeur"
                    sx={{ margin: "0 16px 0 0" }}
                    error={inputValueError}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    disabled={inputValue === ""}
                    onClick={() => {
                      setInputValues([...inputValues, inputValue]);
                      setInputValue("");
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </FlexContainer>
                <FlexContainer justifyContent="center" margin="16px 0">
                  <FlexContainer
                    flexDirection="column"
                    backgroundColor={CustomTheme.palette.primary.light}
                    elevation={3}
                    $borderRadius="4px"
                  >
                    {inputValues.map((value, index) => (
                      <FlexContainer justifyContent="space-between" key={index}>
                        <Text14 color={CustomTheme.palette.primary.main}>
                          {value}
                        </Text14>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            let newArr = [...inputValues];
                            newArr.splice(index, 1);
                            setInputValues(newArr);
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editCustomFields();
            else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            resetForm();
            props.setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomField;
