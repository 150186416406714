import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { bindActionCreators } from "redux";
import {
  removeSignInActionFailure,
  signInAction,
} from "../../../store/Signin/actions";
import {
  isUserSigninFailed,
  isUserSigninSucceed,
} from "../../../store/selectors";
import { Dispatch, RootState } from "../../../store/types";
import { AppName } from "../../../styles/AppName";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { ThemeCustom } from "../../../styles/Utils";

export interface SignInFormProps {
  isUserSigninSucceed: boolean;
  isUserSigninFailed: boolean;
  emailSentAlert: boolean;
  signIn: typeof signInAction;
  removeSignInFailure: typeof removeSignInActionFailure;
  setEmailSentAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setForgotPasswordActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const _SignInForm: React.FC<SignInFormProps> = ({
  isUserSigninSucceed,
  isUserSigninFailed,
  emailSentAlert,
  signIn,
  removeSignInFailure,
  setEmailSentAlert,
  setForgotPasswordActive,
}) => {
  const [inputEmail, SetInputEmail] = useState("");

  const [inputPassword, SetInputPassword] = useState("");

  return (
    <FlexContainer flex="1" elevation={5} backgroundColor="white">
      <FlexContainer
        flexDirection="column"
        margin="64px 32px"
        textAlign="left"
        width="100%"
      >
        <FlexContainer margin="20px" alignSelf="center">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>
        {isUserSigninSucceed && <Navigate to="/feature" replace />}
        {isUserSigninFailed && (
          <Alert
            severity="error"
            onClose={() => {
              removeSignInFailure();
            }}
          >
            Email ou mot de passe incorrectes!
          </Alert>
        )}
        {emailSentAlert && (
          <Alert
            severity="success"
            onClose={() => {
              setEmailSentAlert(false);
            }}
          >
            Un email vous a été envoyé pour réinitialiser votre mot de passe!
          </Alert>
        )}

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <TextField
              value={inputEmail}
              onChange={(e) => SetInputEmail(e.target.value)}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={isUserSigninFailed}
              required
            />
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <TextField
              value={inputPassword}
              onChange={(e) => SetInputPassword(e.target.value)}
              type="password"
              label="Mot de passe"
              variant="outlined"
              fullWidth
              margin="normal"
              error={isUserSigninFailed}
              required
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Rester connecté"
        />
        <FlexContainer margin="24px 0 16px">
          <ButtonMui
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              signIn(inputEmail, inputPassword);
            }}
          >
            CONNEXION
          </ButtonMui>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <FlexContainer>
            <Link
              href="#"
              variant="body2"
              color="secondary"
              onClick={() => setForgotPasswordActive(true)}
            >
              Mot de passe oublié?
            </Link>
          </FlexContainer>
          <FlexContainer>
            <Link href="/signup" variant="body2" color="secondary">
              {"Pas de compte? Inscrivez-vous"}
            </Link>
          </FlexContainer>
        </FlexContainer>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="/">
              WeEvent
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </FlexContainer>
    </FlexContainer>
  );
};

export const SignInForm = connect(
  (state: RootState) => ({
    isUserSigninSucceed: isUserSigninSucceed(state),
    isUserSigninFailed: isUserSigninFailed(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        signIn: signInAction,
        removeSignInFailure: removeSignInActionFailure,
      },
      dispatch
    )
)(_SignInForm);

export default _SignInForm;
