import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Contact } from "../../../../../../../models/contact";
import { RouteName } from "../../../../../../../models/route";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../../../store";
import { getClientsAction } from "../../../../../../../store/Crm/actions";
import { getClients } from "../../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../../../styles/Utils";
import { getContactDescription } from "../../../../../../Reusable/Utils";

export interface EditRouteNameProps {
  popupActive: RouteName | null;
  clients: Contact[];
  setPopupActive: React.Dispatch<React.SetStateAction<RouteName | null>>;
  getRouteNames: () => void;
  getClientsAction: typeof getClientsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _EditRouteName: React.FC<EditRouteNameProps> = (props) => {
  const editRouteNames = () => {
    AxiosHttpClient.put<RouteName[]>("api/v1/weeventpro/route/names", {
      id: props.popupActive?.id,
      name: inputName,
      clientsIds: inputSelectedClients.map((client) => client.id),
    })
      .then((res) => {
        props.getRouteNames();
        props.setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setInputName("");
    setInputSelectedClients([]);
  };

  const [inputName, setInputName] = useState(props.popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputSelectedClients, setInputSelectedClients] = useState(
    props.popupActive!.clientsIds
      ? props
          .popupActive!.clientsIds.filter(
            (id) =>
              props.clients.find((client) => client.id === id) !== undefined
          )
          .map((id) => props.clients.find((client) => client.id === id)!)
      : []
  );

  const [editFailed, setEditFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
    }
    return result;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: Contact[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        inputSelectedClients,
        result.source.index,
        result.destination.index
      );

      setInputSelectedClients(items);
    }
  };

  useEffect(() => {
    props.getClientsAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un champs personnalisé"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={props.clients}
                disableCloseOnSelect
                getOptionLabel={(option) => getContactDescription(option)}
                value={inputSelectedClients}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={
                        inputSelectedClients.find(
                          (client) => option.id === client.id
                        ) !== undefined
                      }
                    />
                    {getContactDescription(option)}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputSelectedClients(values);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Clients" />
                )}
              />
            </Grid>
          </Grid>
          <BlockContainer
            padding="16px 0"
            border="1px dashed black"
            margin="16px 0"
            $borderRadius="10px"
          >
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text16>
                Spécifiez l'ordre des clients par défaut pour cette tournée
              </Text16>
            </FlexContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction={"vertical"}>
                {(provided, snapshot) => (
                  <FlexContainer
                    padding="16px 8px 16px 8px"
                    flexDirection={"column"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {inputSelectedClients.map((client, index) => (
                      <Draggable
                        key={client.id}
                        draggableId={client.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <FlexContainer
                            height="56px"
                            backgroundColor={CustomTheme.palette.primary.main}
                            elevation={snapshot.isDragging ? 9 : 0}
                            flex="1"
                            alignItems="center"
                            justifyContent="center"
                            padding="16px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Text14
                              textAlign="center"
                              color="white"
                              margin="0"
                              lineHeight="14px"
                            >
                              {getContactDescription(client)}
                            </Text14>
                          </FlexContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FlexContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BlockContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) {
              editRouteNames();
              resetForm();
            } else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            resetForm();
            props.setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditRouteName = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_EditRouteName);

export default EditRouteName;
