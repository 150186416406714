import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Unit } from "../../../../../../models/ingredients";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  baseUnits,
  getUnitById,
  getUnitDescription,
  isBaseUnit,
  isParentOf,
} from "../../../../../Reusable/Utils";

export interface PurchaseUnitsProps {
  inputRef: React.RefObject<HTMLInputElement | null>;
  enabledUnitOptions: string[];
  inputPurchaseUnits: Unit[];
  inputStorageUnits: Unit[];
  inputProductionUnits: Unit[];
  inputSaleUnits: Unit[];
  purchaseUnitAddActive: boolean;
  purchaseUnitOpen: boolean;
  inputForSale: boolean;
  setInputPurchaseUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputStorageUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputProductionUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputSaleUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setPurchaseUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PurchaseUnits: React.FC<PurchaseUnitsProps> = (props) => {
  const [inputPurchaseUnit, setInputPurchaseUnit] = useState("");

  const [inputPurchaseUnitError, setInputPurchaseUnitError] = useState(false);

  const [purchaseUnitEditActive, setPurchaseUnitEditActive] = useState("");

  const [inputCustomPurchaseUnit, setInputCustomPurchaseUnit] = useState("");

  const [inputCustomPurchaseUnitError, setInputCustomPurchaseUnitError] =
    useState(false);

  const [
    inputCustomPurchaseUnitCorrespondanceValue,
    setInputCustomPurchaseUnitCorrespondanceValue,
  ] = useState("");

  const [
    inputCustomPurchaseUnitCorrespondanceValueError,
    setInputCustomPurchaseUnitCorrespondanceValueError,
  ] = useState(false);

  const [
    inputCustomPurchaseUnitCorrespondanceUnit,
    setInputCustomPurchaseUnitCorrespondanceUnit,
  ] = useState("");

  const [
    inputCustomPurchaseUnitCorrespondanceUnitError,
    setInputCustomPurchaseUnitCorrespondanceUnitError,
  ] = useState(false);

  const [addToSaleUnits, setAddToSaleUnits] = useState(true);

  const [addToStorageUnits, setAddToStorageUnits] = useState(true);

  const [addToProductionUnits, setAddToProductionUnits] = useState(true);

  const [addFailed, setAddFailed] = useState("");

  const isUnitDisabled = (unit: string) => {
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const isUnitDisabledForEdit = (unit: string) => {
    if (
      props.inputPurchaseUnits.length +
        props.inputStorageUnits.length +
        props.inputProductionUnits.length +
        props.inputSaleUnits.length <=
      1
    )
      return false;
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputPurchaseUnitError(false);
    setInputCustomPurchaseUnitError(false);
    setInputCustomPurchaseUnitCorrespondanceValueError(false);
    setInputCustomPurchaseUnitCorrespondanceUnitError(false);
  };

  const validateForm = () => {
    if (
      props.inputPurchaseUnits.some(
        (purchaseUnit) =>
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) === purchaseUnit.name
      )
    ) {
      setAddFailed(
        "L'unité d'achat \"" +
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) +
          '" existe déjà!'
      );
      return false;
    }

    if (
      props.inputStorageUnits.some(
        (storageUnit) =>
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) === storageUnit.name
      ) &&
      addToStorageUnits
    ) {
      setAddFailed(
        "L'unité de stockage \"" +
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de stockage!"
      );
      return false;
    }

    if (
      props.inputProductionUnits.some(
        (productionUnit) =>
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) === productionUnit.name
      ) &&
      addToProductionUnits
    ) {
      setAddFailed(
        "L'unité de production \"" +
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de production!"
      );
      return false;
    }

    if (
      props.inputForSale &&
      props.inputSaleUnits.some(
        (saleUnit) =>
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) === saleUnit.name
      ) &&
      addToSaleUnits
    ) {
      setAddFailed(
        "L'unité de vente \"" +
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de vente!"
      );
      return false;
    }
    setInputPurchaseUnitError(false);
    setInputCustomPurchaseUnitError(false);
    setInputCustomPurchaseUnitCorrespondanceValueError(false);
    setInputCustomPurchaseUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputPurchaseUnit === "") {
      setInputPurchaseUnitError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnit === ""
    ) {
      setInputCustomPurchaseUnitError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnitCorrespondanceValue === ""
    ) {
      setInputCustomPurchaseUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnitCorrespondanceUnit === ""
    ) {
      setInputCustomPurchaseUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (purchaseUnit: Unit) => {
    if (
      props.inputPurchaseUnits
        .filter((unit) => unit.id !== purchaseUnit.id)
        .some(
          (unit) =>
            (inputPurchaseUnit === "Personnalisée"
              ? inputCustomPurchaseUnit
              : inputPurchaseUnit) === unit.name
        )
    ) {
      setAddFailed(
        "L'unité d'achat \"" +
          (inputPurchaseUnit === "Personnalisée"
            ? inputCustomPurchaseUnit
            : inputPurchaseUnit) +
          '" existe déjà!'
      );
      return false;
    }

    setInputPurchaseUnitError(false);
    setInputCustomPurchaseUnitError(false);
    setInputCustomPurchaseUnitCorrespondanceValueError(false);
    setInputCustomPurchaseUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputPurchaseUnit === "") {
      setInputPurchaseUnitError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnit === ""
    ) {
      setInputCustomPurchaseUnitError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnitCorrespondanceValue === ""
    ) {
      setInputCustomPurchaseUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputPurchaseUnit === "Personnalisée" &&
      inputCustomPurchaseUnitCorrespondanceUnit === ""
    ) {
      setInputCustomPurchaseUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const addUnitWithChildren = (
    unit: Unit,
    targetUnits: Unit[],
    setTargetUnits: (units: Unit[]) => void
  ) => {
    const newUnits = [...targetUnits];

    const addMissingChildUnits = (unit: Unit): Unit | null => {
      if (unit.correspondanceUnit) {
        const existingUnit = newUnits.find(
          (existingUnit) => existingUnit.name === unit.correspondanceUnit!.name
        );

        if (existingUnit) {
          return existingUnit;
        } else {
          const createdChildUnit = addMissingChildUnits(
            unit.correspondanceUnit
          );
          const createdUnit = {
            id: uuid(),
            name: unit.correspondanceUnit.name,
            correspondanceValue: unit.correspondanceUnit.correspondanceValue,
            correspondanceUnit: createdChildUnit,
            baseUnit: unit.correspondanceUnit.baseUnit,
          };
          newUnits.push(createdUnit);
          return createdUnit;
        }
      }
      return null;
    };

    addMissingChildUnits(unit);

    newUnits.push({
      ...unit,
      id: uuid(),
      correspondanceUnit: unit.correspondanceUnit
        ? newUnits.find(
            (existingUnit) =>
              existingUnit.name === unit.correspondanceUnit!.name
          ) || null
        : null,
    });
    setTargetUnits(newUnits);
  };

  useEffect(() => {
    if (inputPurchaseUnit !== "Personnalisée") {
      setInputCustomPurchaseUnit("");
      setInputCustomPurchaseUnitCorrespondanceValue("");
      setInputCustomPurchaseUnitCorrespondanceUnit("");
    }
  }, [inputPurchaseUnit]);

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0" id="purchaseUnitsTopId">
        <Text16 textAlign="left" margin="0">
          Unités d'achat
        </Text16>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setPurchaseUnitEditActive("");
            setInputPurchaseUnit("");
            setInputCustomPurchaseUnit("");
            setInputCustomPurchaseUnitCorrespondanceValue("");
            setInputCustomPurchaseUnitCorrespondanceUnit("");
            props.setPurchaseUnitAddActive(true);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une unité d'achat
          </Text16>
        </FlexContainer>
      </FlexContainer>
      <Collapse orientation="vertical" in={props.purchaseUnitAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputPurchaseUnitError}
              >
                <InputLabel id="unitLabel">Unité</InputLabel>
                <Select
                  labelId="unitLabel"
                  id="unit"
                  required
                  inputRef={props.inputRef}
                  open={props.purchaseUnitOpen}
                  onClose={() => props.setPurchaseUnitOpen(false)}
                  onOpen={() => props.setPurchaseUnitOpen(true)}
                  value={inputPurchaseUnit}
                  label="Unité"
                  onChange={(e) => setInputPurchaseUnit(e.target.value)}
                >
                  <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                    Kg
                  </MenuItem>
                  <MenuItem value="g" disabled={isUnitDisabled("g")}>
                    g
                  </MenuItem>
                  <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                    mg
                  </MenuItem>
                  <MenuItem value="L" disabled={isUnitDisabled("L")}>
                    L
                  </MenuItem>
                  <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                    mL
                  </MenuItem>
                  <MenuItem value="Unité" disabled={isUnitDisabled("Unité")}>
                    Unité
                  </MenuItem>
                  <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Nom personnalisé de votre unité"
              >
                <TextField
                  fullWidth
                  disabled={inputPurchaseUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputPurchaseUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomPurchaseUnitError}
                  variant="outlined"
                  name="label"
                  label="Libellé"
                  value={inputCustomPurchaseUnit}
                  onChange={(e) => setInputCustomPurchaseUnit(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Valeur de votre unité personnalisée en unité internationale"
              >
                <TextField
                  fullWidth
                  disabled={inputPurchaseUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputPurchaseUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomPurchaseUnitCorrespondanceValueError}
                  variant="outlined"
                  name="customUnitPurchaseCorrespondanceValue"
                  label="Valeur de correspondance"
                  value={inputCustomPurchaseUnitCorrespondanceValue}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputCustomPurchaseUnitCorrespondanceValue(
                        e.target.value
                      );
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Unité internationale utilisée pour calculer la valeur de correspondance"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={inputCustomPurchaseUnitCorrespondanceUnitError}
                >
                  <InputLabel id="customPurchaseUnitCorrespondanceUnitLabel">
                    Unité de correspondance
                  </InputLabel>
                  <Select
                    disabled={inputPurchaseUnit !== "Personnalisée"}
                    sx={{
                      backgroundColor:
                        inputPurchaseUnit !== "Personnalisée"
                          ? "#e8e8e8"
                          : "white",
                    }}
                    labelId="customPurchaseUnitCorrespondanceUnitLabel"
                    id="customPurchaseUnitCorrespondanceUnit"
                    required
                    value={inputCustomPurchaseUnitCorrespondanceUnit}
                    label="Unité de correspondance"
                    onChange={(e) =>
                      setInputCustomPurchaseUnitCorrespondanceUnit(
                        e.target.value
                      )
                    }
                  >
                    <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                      Kg
                    </MenuItem>
                    <MenuItem value="g" disabled={isUnitDisabled("g")}>
                      g
                    </MenuItem>
                    <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                      mg
                    </MenuItem>
                    <MenuItem value="L" disabled={isUnitDisabled("L")}>
                      L
                    </MenuItem>
                    <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                      mL
                    </MenuItem>
                    <MenuItem value="Unité" disabled={isUnitDisabled("Unité")}>
                      Unité
                    </MenuItem>
                    {props.inputPurchaseUnits
                      .filter((unit) => !baseUnits.includes(unit.name))
                      .map((unit) => (
                        <MenuItem value={unit.id}>{unit.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <FlexContainer flexDirection="column" padding="8px 0 0 16px">
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToStorageUnits}
                  onChange={(event) => {
                    setAddToStorageUnits(!addToStorageUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités de stockage
                </Text16>
              </FlexContainer>
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToProductionUnits}
                  onChange={(event) => {
                    setAddToProductionUnits(!addToProductionUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités de production
                </Text16>
              </FlexContainer>
              {props.inputForSale && (
                <FlexContainer>
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={addToSaleUnits}
                    onChange={(event) => {
                      setAddToSaleUnits(!addToSaleUnits);
                    }}
                  />
                  <Text16 display="inline-block">
                    Ajouter aux unités de vente
                  </Text16>
                </FlexContainer>
              )}
            </FlexContainer>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                const correspondancePurchaseUnit = getUnitById(
                  inputCustomPurchaseUnitCorrespondanceUnit,
                  props.inputPurchaseUnits
                );
                const newUnit: Unit = {
                  id: uuid(),
                  name:
                    inputPurchaseUnit === "Personnalisée"
                      ? inputCustomPurchaseUnit
                      : inputPurchaseUnit,
                  correspondanceValue:
                    inputCustomPurchaseUnitCorrespondanceValue !== ""
                      ? Number(inputCustomPurchaseUnitCorrespondanceValue)
                      : 1,
                  correspondanceUnit: correspondancePurchaseUnit,
                  baseUnit:
                    correspondancePurchaseUnit?.baseUnit ??
                    (inputCustomPurchaseUnitCorrespondanceUnit !== ""
                      ? inputCustomPurchaseUnitCorrespondanceUnit
                      : inputPurchaseUnit),
                };

                props.setInputPurchaseUnits([
                  ...props.inputPurchaseUnits,
                  newUnit,
                ]);

                if (addToStorageUnits) {
                  addUnitWithChildren(
                    newUnit,
                    props.inputStorageUnits,
                    props.setInputStorageUnits
                  );
                }

                if (addToProductionUnits) {
                  addUnitWithChildren(
                    newUnit,
                    props.inputProductionUnits,
                    props.setInputProductionUnits
                  );
                }

                if (addToSaleUnits && props.inputForSale) {
                  addUnitWithChildren(
                    newUnit,
                    props.inputSaleUnits,
                    props.setInputSaleUnits
                  );
                }
                setAddToStorageUnits(true);
                setAddToProductionUnits(true);
                setAddToSaleUnits(true);
                props.setPurchaseUnitAddActive(false);
                setInputPurchaseUnit("");
                setInputCustomPurchaseUnit("");
                setInputCustomPurchaseUnitCorrespondanceValue("");
                setInputCustomPurchaseUnitCorrespondanceUnit("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              props.setPurchaseUnitAddActive(false);
              setInputPurchaseUnit("");
              setInputCustomPurchaseUnit("");
              setInputCustomPurchaseUnitCorrespondanceValue("");
              setInputCustomPurchaseUnitCorrespondanceUnit("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {props.inputPurchaseUnits.map((purchaseUnit, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            border="1px solid rgba(0,0,0,0.1)"
            margin="8px 0"
            elevation={3}
            $borderRadius="10px"
            padding="8px 8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text16>{getUnitDescription(purchaseUnit)}</Text16>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setPurchaseUnitAddActive(false);
                  setInputPurchaseUnit(
                    isBaseUnit(purchaseUnit)
                      ? purchaseUnit.name
                      : "Personnalisée"
                  );
                  setInputCustomPurchaseUnit(
                    isBaseUnit(purchaseUnit) ? "" : purchaseUnit.name
                  );
                  setInputCustomPurchaseUnitCorrespondanceValue(
                    purchaseUnit.correspondanceValue.toString()
                  );
                  setInputCustomPurchaseUnitCorrespondanceUnit(
                    isBaseUnit(purchaseUnit)
                      ? ""
                      : purchaseUnit.correspondanceUnit?.id ??
                          purchaseUnit.baseUnit
                  );
                  setPurchaseUnitEditActive(purchaseUnit.id);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                disabled={props.inputPurchaseUnits.some(
                  (parentUnit) =>
                    parentUnit.correspondanceUnit?.id === purchaseUnit.id
                )}
                onClick={() => {
                  let newArr = [...props.inputPurchaseUnits];
                  newArr.splice(index, 1);
                  props.setInputPurchaseUnits(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          <Collapse
            orientation="vertical"
            in={purchaseUnit.id === purchaseUnitEditActive}
          >
            <BlockContainer margin="8px 0">
              {addFailed !== "" && (
                <BlockContainer margin="8px 0 16px 0">
                  <Alert severity="error">{addFailed}</Alert>
                </BlockContainer>
              )}
              <FlexContainer>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={inputPurchaseUnitError}
                    >
                      <InputLabel id="unitLabel">Unité</InputLabel>
                      <Select
                        labelId="unitLabel"
                        id="unit"
                        required
                        value={inputPurchaseUnit}
                        label="Unité"
                        onChange={(e) => setInputPurchaseUnit(e.target.value)}
                      >
                        <MenuItem
                          value="Kg"
                          disabled={isUnitDisabledForEdit("Kg")}
                        >
                          Kg
                        </MenuItem>
                        <MenuItem
                          value="g"
                          disabled={isUnitDisabledForEdit("g")}
                        >
                          g
                        </MenuItem>
                        <MenuItem
                          value="mg"
                          disabled={isUnitDisabledForEdit("mg")}
                        >
                          mg
                        </MenuItem>
                        <MenuItem
                          value="L"
                          disabled={isUnitDisabledForEdit("L")}
                        >
                          L
                        </MenuItem>
                        <MenuItem
                          value="mL"
                          disabled={isUnitDisabledForEdit("mL")}
                        >
                          mL
                        </MenuItem>
                        <MenuItem
                          value="Unité"
                          disabled={isUnitDisabledForEdit("Unité")}
                        >
                          Unité
                        </MenuItem>
                        <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Nom personnalisé de votre unité"
                    >
                      <TextField
                        fullWidth
                        disabled={inputPurchaseUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputPurchaseUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomPurchaseUnitError}
                        variant="outlined"
                        name="label"
                        label="Libellé"
                        value={inputCustomPurchaseUnit}
                        onChange={(e) =>
                          setInputCustomPurchaseUnit(e.target.value)
                        }
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Valeur de votre unité personnalisée en unité internationale"
                    >
                      <TextField
                        fullWidth
                        disabled={inputPurchaseUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputPurchaseUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomPurchaseUnitCorrespondanceValueError}
                        variant="outlined"
                        name="customUnitPurchaseCorrespondanceValue"
                        label="Valeur de correspondance"
                        value={inputCustomPurchaseUnitCorrespondanceValue}
                        onChange={(e) => {
                          if (
                            /^\d*\.?\d*$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setInputCustomPurchaseUnitCorrespondanceValue(
                              e.target.value
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Unité internationale utilisée pour calculer la valeur de correspondance"
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={inputCustomPurchaseUnitCorrespondanceUnitError}
                      >
                        <InputLabel id="customPurchaseUnitCorrespondanceUnitLabel">
                          Unité de correspondance
                        </InputLabel>
                        <Select
                          disabled={inputPurchaseUnit !== "Personnalisée"}
                          sx={{
                            backgroundColor:
                              inputPurchaseUnit !== "Personnalisée"
                                ? "#e8e8e8"
                                : "white",
                          }}
                          labelId="customPurchaseUnitCorrespondanceUnitLabel"
                          id="customPurchaseUnitCorrespondanceUnit"
                          required
                          value={inputCustomPurchaseUnitCorrespondanceUnit}
                          label="Unité de correspondance"
                          onChange={(e) =>
                            setInputCustomPurchaseUnitCorrespondanceUnit(
                              e.target.value
                            )
                          }
                        >
                          <MenuItem
                            value="Kg"
                            disabled={isUnitDisabledForEdit("Kg")}
                          >
                            Kg
                          </MenuItem>
                          <MenuItem
                            value="g"
                            disabled={isUnitDisabledForEdit("g")}
                          >
                            g
                          </MenuItem>
                          <MenuItem
                            value="mg"
                            disabled={isUnitDisabledForEdit("mg")}
                          >
                            mg
                          </MenuItem>
                          <MenuItem
                            value="L"
                            disabled={isUnitDisabledForEdit("L")}
                          >
                            L
                          </MenuItem>
                          <MenuItem
                            value="mL"
                            disabled={isUnitDisabledForEdit("mL")}
                          >
                            mL
                          </MenuItem>
                          <MenuItem
                            value="Unité"
                            disabled={isUnitDisabledForEdit("Unité")}
                          >
                            Unité
                          </MenuItem>
                          {props.inputPurchaseUnits
                            .filter(
                              (unit) =>
                                unit.id !== purchaseUnitEditActive &&
                                !baseUnits.includes(unit.name)
                            )
                            .map((unit) => (
                              <MenuItem
                                value={unit.id}
                                disabled={isParentOf(
                                  unit,
                                  getUnitById(
                                    purchaseUnitEditActive,
                                    props.inputPurchaseUnits
                                  )!
                                )}
                              >
                                {unit.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FlexContainer>
              <FlexContainer justifyContent="center">
                <ButtonMui
                  margin="16px 4px 0 0"
                  onClick={() => {
                    if (validateEditForm(purchaseUnit)) {
                      let tmpArray = [...props.inputPurchaseUnits];
                      tmpArray[index] = {
                        id: purchaseUnit.id,
                        name:
                          inputPurchaseUnit === "Personnalisée"
                            ? inputCustomPurchaseUnit
                            : inputPurchaseUnit,
                        correspondanceValue:
                          inputCustomPurchaseUnitCorrespondanceValue !== ""
                            ? Number(inputCustomPurchaseUnitCorrespondanceValue)
                            : 1,
                        correspondanceUnit: getUnitById(
                          inputCustomPurchaseUnitCorrespondanceUnit,
                          props.inputPurchaseUnits
                        ),
                        baseUnit:
                          getUnitById(
                            inputCustomPurchaseUnitCorrespondanceUnit,
                            props.inputPurchaseUnits
                          )?.baseUnit ??
                          (inputCustomPurchaseUnitCorrespondanceUnit !== ""
                            ? inputCustomPurchaseUnitCorrespondanceUnit
                            : inputPurchaseUnit),
                      };

                      props.setInputPurchaseUnits(tmpArray);
                      props.setPurchaseUnitAddActive(false);
                      setPurchaseUnitEditActive("");
                      setInputPurchaseUnit("");
                      setInputCustomPurchaseUnit("");
                      setInputCustomPurchaseUnitCorrespondanceValue("");
                      setInputCustomPurchaseUnitCorrespondanceUnit("");
                      resetValidation();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Modifier
                </ButtonMui>
                <ButtonMui
                  margin="16px 0 0 4px"
                  onClick={() => {
                    setPurchaseUnitEditActive("");
                    setInputPurchaseUnit("");
                    setInputCustomPurchaseUnit("");
                    setInputCustomPurchaseUnitCorrespondanceValue("");
                    setInputCustomPurchaseUnitCorrespondanceUnit("");
                    resetValidation();
                  }}
                  color="primary"
                  variant="outlined"
                  size="large"
                >
                  Annuler
                </ButtonMui>
              </FlexContainer>
            </BlockContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default PurchaseUnits;
