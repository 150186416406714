import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { CustomTheme } from "../../../../../../styles/Theme";
import CustomDateRangePicker from "../../../../../Reusable/CustomDateRangePicker";
import {
  VACATION_TABLE_SEARCH_BY,
  parseToDate,
} from "../../../../../Reusable/Utils";

export interface SearchInputProps {
  inputSearch: string;
  displayedTool: string | null;
  inputField: string;
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  setInputField: React.Dispatch<React.SetStateAction<string>>;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const setSearchByParameter = (searchByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: VACATION_TABLE_SEARCH_BY,
        parameterValue: searchByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
  };

  let fieldsCorrespondanceMap: Map<string, string> = new Map([
    ["Employé", "employee_fullName"],
    ["Date de début", "startDate"],
    ["Date de fin", "endDate"],
    ["Durée du congé", "vacationDuration"],
    ["Superviseur", "supervisor_fullName"],
    ["Commentaire", "comment"],
    ["Statut", "status"],
  ]);

  return (
    <TextField
      variant="outlined"
      label="Rechercher un congé"
      disabled={props.inputField.toLowerCase().includes("date")}
      size={matches ? "medium" : "small"}
      sx={{
        margin: "8px 16px 8px 0",
        flex: "1",
        maxWidth: { sm: "50%" },
        width: { xs: "100%" },
        display: {
          xs: props.displayedTool === "search" ? "inline-flex" : "none",
          sm: "inline-flex",
        },
      }}
      value={
        props.inputField.toLowerCase().includes("date") ? "" : props.inputSearch
      }
      onChange={(e) => props.setInputSearch(e.target.value)}
      InputProps={{
        startAdornment: props.inputField.toLowerCase().includes("date") ? (
          <FlexContainer maxWidth={"calc(100% - 150px)"}>
            <CustomDateRangePicker
              value={
                !props.inputSearch.includes("-")
                  ? null
                  : [
                      parseToDate(props.inputSearch.split("-")[0]),
                      parseToDate(props.inputSearch.split("-")[1]),
                    ]
              }
              onChange={(value) => {
                if (!value) props.setInputSearch("");
                else {
                  let result =
                    format(value[0], "dd/MM/yyyy") +
                    "-" +
                    format(value[1], "dd/MM/yyyy");
                  props.setInputSearch(result);
                }
              }}
            />
          </FlexContainer>
        ) : (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              "& .MuiInputBase-root:before": {
                borderBottom: "none",
              },
              "& .MuiInputBase-root:after": {
                borderBottom: "none",
              },
              "& .MuiInputBase-root:hover:before": {
                borderBottom: "none !important",
              },
            }}
          >
            <Select
              variant="standard"
              sx={{
                "& .MuiSelect-select:focus": {
                  borderBottom: "none",
                  backgroundColor: "white",
                },
              }}
              value={props.inputField}
              onChange={(e) => {
                setSearchByParameter(e.target.value);
                props.setInputField(e.target.value);
                props.setInputSearch("");
              }}
            >
              {Array.from(fieldsCorrespondanceMap.keys()).map((field) => (
                <MenuItem
                  key={fieldsCorrespondanceMap.get(field)}
                  value={fieldsCorrespondanceMap.get(field)}
                >
                  {field}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchInput;
