import { CartItem } from "../../models/cartItem";

export const GET_CART_ITEMS = "GET_CART_ITEMS" as const;
export const GET_CART_ITEMS_SUCCESS = "GET_CART_ITEMS_SUCCESS" as const;
export const GET_CART_ITEMS_FAILURE = "GET_CART_ITEMS_FAILURE" as const;

export const getCartItemsAction = () => ({
  type: GET_CART_ITEMS,
});
export type GetCartItemsAction = ReturnType<typeof getCartItemsAction>;

export const getCartItemsActionSuccess = (cartItems: CartItem[]) => ({
  type: GET_CART_ITEMS_SUCCESS,
  cartItems: cartItems,
});
export type GetCartItemsActionSuccess = ReturnType<
  typeof getCartItemsActionSuccess
>;

export const getCartItemsActionFailure = () => ({
  type: GET_CART_ITEMS_FAILURE,
});
export type GetCartItemsActionFailure = ReturnType<
  typeof getCartItemsActionFailure
>;
