import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { MetricService } from "../../services/StatisticsService";
import { RootAction } from "../types";
import {
  GET_BASE_METRICS,
  GET_CUSTOM_METRICS,
  GetBaseMetricsActionFailure,
  getBaseMetricsActionFailure,
  GetBaseMetricsActionSuccess,
  getBaseMetricsActionSuccess,
  getCustomMetricsActionFailure,
  GetCustomMetricsActionFailure,
  getCustomMetricsActionSuccess,
  GetCustomMetricsActionSuccess,
} from "./actions";

export const baseMetricsEpic: Epic<
  RootAction,
  GetBaseMetricsActionSuccess | GetBaseMetricsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_BASE_METRICS),
    switchMap((action) =>
      from(
        MetricService.getBaseMetrics(
          action.input,
          action.startDate,
          action.endDate
        )
      ).pipe(
        mergeMap((res) => {
          return [getBaseMetricsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getBaseMetricsActionFailure()]))
        )
      )
    )
  );

export const customMetricsEpic: Epic<
  RootAction,
  GetCustomMetricsActionSuccess | GetCustomMetricsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CUSTOM_METRICS),
    switchMap((action) =>
      from(
        MetricService.getCustomMetrics(
          action.input,
          action.startDate,
          action.endDate
        )
      ).pipe(
        mergeMap((res) => {
          return [getCustomMetricsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getCustomMetricsActionFailure()]))
        )
      )
    )
  );

export const metricEpic = combineEpics<any>(baseMetricsEpic, customMetricsEpic);
