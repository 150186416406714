import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BlockContainer } from "../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Text16, Text20 } from "../../../../styles/Text";
import { CustomTheme } from "../../../../styles/Theme";
import CustomizeClients from "./CustomizeSections/CustomizeClients";
import CustomizeCommercialCycles from "./CustomizeSections/CustomizeCommercialCycles";
import CustomizeComplaints from "./CustomizeSections/CustomizeComplaints";
import CustomizeDishes from "./CustomizeSections/CustomizeDishes";
import CustomizeEmployees from "./CustomizeSections/CustomizeEmployees";
import CustomizeIngredients from "./CustomizeSections/CustomizeIngredients";
import CustomizeInteractions from "./CustomizeSections/CustomizeInteractions";
import CustomizeInvoices from "./CustomizeSections/CustomizeInvoices";
import CustomizeMaterials from "./CustomizeSections/CustomizeMaterials";
import CustomizeOpportunities from "./CustomizeSections/CustomizeOpportunities";
import CustomizeQuotes from "./CustomizeSections/CustomizeQuotes";
import CustomizeRoutes from "./CustomizeSections/CustomizeRoutes";
import CustomizeSales from "./CustomizeSections/CustomizeSales";
import CustomizeServices from "./CustomizeSections/CustomizeServices";
import CustomizeStorageAreas from "./CustomizeSections/CustomizeStorageAreas";
import CustomizeSupplierOrders from "./CustomizeSections/CustomizeSupplierOrders";
import CustomizeSuppliers from "./CustomizeSections/CustomizeSuppliers";
import CustomizeTasks from "./CustomizeSections/CustomizeTasks";
import CustomizeVacations from "./CustomizeSections/CustomizeVacations";
import GeneralParameters from "./GeneralParameters";

export interface ParametersProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index1: string;
  index2: string;
  value1: string;
  value2: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value1, value2, index1, index2, ...other } = props;

  return (
    <FlexContainer
      role="tabpanel"
      width="100%"
      hidden={value1 !== index1 || value2 !== index2}
      id={`vertical-tabpanel-${index1}-${index2}`}
      aria-labelledby={`vertical-tab-${index1}-${index2}`}
      flex={value1 === index1 && value2 === index2 ? "1" : "0 1 auto"}
      {...other}
    >
      {value1 === index1 && value2 === index2 && (
        <FlexContainer
          sx={{ p: { xs: "0 24px 24px 24px", sm: 3 } }}
          width="100%"
        >
          {children}
        </FlexContainer>
      )}
    </FlexContainer>
  );
}

const Parameters: React.FC<ParametersProps> = (props) => {
  const params = useLocation();

  const path: string = params.state as string;

  const [value, setValue] = useState("general");

  const [value2, setValue2] = useState("");

  const [menuOpened, setMenuOpened] = useState(false);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setValue2("");
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  function DrawerWrapper(props) {
    return (
      <React.Fragment>
        <Drawer
          sx={{ display: { xs: "block", md: "none" } }}
          anchor="left"
          open={props.open}
          onClose={props.onClose}
        >
          {props.children}
        </Drawer>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {props.children}
        </Box>
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (path !== null) {
      console.log(path);
      setValue(path.split(",")[0]);
      if (path.split(",").length > 1) setValue2(path.split(",")[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value === "customization" && value2 === "") {
      setValue2("ingredients");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "8px",
          md: "32px",
        },
      }}
    >
      <FlexContainer>
        <Text20>Paramètres</Text20>
      </FlexContainer>
      <FlexContainer
        margin="16px 0"
        backgroundColor="white"
        sx={{
          padding: {
            xs: "0",
            sm: "32px 16px",
          },
        }}
        elevation={3}
        $borderRadius="4px"
      >
        <DrawerWrapper open={menuOpened} onClose={() => setMenuOpened(false)}>
          {!matches && (
            <FlexContainer margin="16px">
              <Text16 fontWeight="500">Paramètres</Text16>
            </FlexContainer>
          )}
          <FlexContainer width="240px">
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                width: value === "customization" ? 60 : 240,
                transition: "width 0.5s",
                "& .MuiTab-root": { alignItems: "flex-start" },
              }}
            >
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <TuneIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== "customization" && (
                      <React.Fragment>Général</React.Fragment>
                    )}
                  </FlexContainer>
                }
                value="general"
              />
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <EmailIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== "customization" && (
                      <React.Fragment>Emails</React.Fragment>
                    )}
                  </FlexContainer>
                }
                value="email"
              />
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <SettingsIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== "customization" && (
                      <React.Fragment>Sections</React.Fragment>
                    )}
                  </FlexContainer>
                }
                value="customization"
              />
            </Tabs>
            {value === "customization" && (
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value2}
                onChange={handleChange2}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  flex: 1,
                  borderColor: "divider",
                  transition: "width 0.5s",
                  "& .MuiTab-root": { alignItems: "flex-start" },
                }}
              >
                <Tab value="ingredients" label="Ingrédients" />
                <Tab value="materials" label="Matériel" />
                <Tab value="storageAreas" label="Espaces de stockage" />
                <Tab value="suppliers" label="Fournisseurs" />
                <Tab value="sales" label="Ventes" />
                <Tab value="routes" label="Livraisons" />
                <Tab value="supplierOrders" label="Provisionnement" />
                <Tab value="dishes" label="Recettes" />
                <Tab value="services" label="Services" />
                <Tab value="quotes" label="Devis" />
                <Tab value="invoices" label="Factures" />
                <Tab value="contacts" label="Clients/Prospects" />
                <Tab value="opportunities" label="Opportunités" />
                <Tab value="commercialCycles" label="Cycles commerciaux" />
                <Tab value="complaints" label="Réclamations" />
                <Tab value="tasks" label="Tâches" />
                <Tab value="interactions" label="Intéractions" />
                <Tab value="employees" label="Employés" />
                <Tab value="vacations" label="Absences" />
              </Tabs>
            )}
          </FlexContainer>
        </DrawerWrapper>
        <FlexContainer flex="1" width="100%" flexDirection="column">
          <FlexContainer alignItems="center" flexDirection="column">
            <IconButton
              size="small"
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              onClick={() => setMenuOpened(!menuOpened)}
            >
              <MenuIcon color="action" style={{ fontSize: 25 }} />
            </IconButton>
          </FlexContainer>
          <TabPanel value1={value} value2={value2} index1="general" index2="">
            <GeneralParameters />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1="email" index2="">
            Item Two
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="ingredients"
          >
            <CustomizeIngredients />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="materials"
          >
            <CustomizeMaterials />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="storageAreas"
          >
            <CustomizeStorageAreas />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="suppliers"
          >
            <CustomizeSuppliers />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="sales"
          >
            <CustomizeSales />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="routes"
          >
            <CustomizeRoutes />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="supplierOrders"
          >
            <CustomizeSupplierOrders />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="dishes"
          >
            <CustomizeDishes />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="services"
          >
            <CustomizeServices />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="quotes"
          >
            <CustomizeQuotes />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="invoices"
          >
            <CustomizeInvoices />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="contacts"
          >
            <CustomizeClients />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="opportunities"
          >
            <CustomizeOpportunities />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="commercialCycles"
          >
            <CustomizeCommercialCycles />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="complaints"
          >
            <CustomizeComplaints />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="tasks"
          >
            <CustomizeTasks />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="interactions"
          >
            <CustomizeInteractions />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="employees"
          >
            <CustomizeEmployees />
          </TabPanel>
          <TabPanel
            value1={value}
            value2={value2}
            index1="customization"
            index2="vacations"
          >
            <CustomizeVacations />
          </TabPanel>
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
};

export default Parameters;
