import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { FlexContainer } from "../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../styles/InlineBlockContainer";
import { Text14, Text20 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => () => {
    setMenuOpen(open);
  };

  // Les cercles de fond restent dans le DOM, mais ils ne devraient pas affecter la hauteur
  let bgCircles = (
    <>
      <FlexContainer
        sx={{
          position: "absolute",
          top: -280,
          left: -317,
          width: "634px",
          height: "634px",
          backgroundColor: CustomTheme.palette.primary.dark,
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: 219,
          left: 376,
          width: "634px",
          height: "634px",
          backgroundColor: "#FFD6D6",
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: 219,
          left: 376,
          width: "634px",
          height: "634px",
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: -364,
          left: 581,
          width: "634px",
          height: "634px",
          backgroundColor: "#FFD6D6",
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: 243,
          left: 1144,
          width: "634px",
          height: "634px",
          backgroundColor: CustomTheme.palette.primary.dark,
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: 1849,
          left: -194,
          width: "634px",
          height: "634px",
          backgroundColor: CustomTheme.palette.secondary.main,
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
      <FlexContainer
        sx={{
          position: "absolute",
          top: 2949,
          left: 1123,
          width: "634px",
          height: "634px",
          backgroundColor: CustomTheme.palette.primary.dark,
          borderRadius: "50%",
          filter: "blur(1000px)",
          pointerEvents: "none",
          zIndex: "-1",
        }}
      ></FlexContainer>
    </>
  );

  return (
    <>
      {bgCircles}
      <FlexContainer
        sx={{
          padding: { xs: "0 16px", sm: "0 128px" },
          marginTop: "24px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 2, md: 2 }}
          columns={{ xs: 2, sm: 6, md: 12 }}
          alignItems="center"
        >
          {/* Logo */}
          <Grid
            item
            xs={1}
            sm={3}
            md={4}
            display="flex"
            justifyContent={"flex-start"}
          >
            <FlexContainer
              alignItems="center"
              $cursorHover="pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/Logo.svg`}
                alt="Mon SVG"
                width={51.2}
                height={34.4}
                style={{ marginBottom: "24px" }}
              />
              <Text20
                color={CustomTheme.palette.primary.main}
                fontFamily="Oleo Script Swash Caps"
                margin="0 0 0 -4px"
              >
                eEvent
              </Text20>
            </FlexContainer>
          </Grid>

          {/* Menu (Visible sauf en xs) */}
          <Grid
            item
            xs={6}
            sm={3}
            md={5}
            display={{ xs: "none", sm: "flex" }}
            justifyContent="center"
            sx={{
              flexWrap: "wrap",
              gap: { xs: "10px", md: "20px" },
            }}
          >
            <Text14 fontWeight="500" color="#A6A6A6">
              <InlineBlockContainer
                $colorHover="#7F7F7F"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/features");
                }}
              >
                Fonctionnalités
              </InlineBlockContainer>
            </Text14>
            <Text14 fontWeight="500" color="#A6A6A6">
              <InlineBlockContainer
                $colorHover="#7F7F7F"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/faq");
                }}
              >
                FAQ
              </InlineBlockContainer>
            </Text14>
            <Text14 fontWeight="500" color="#A6A6A6">
              <InlineBlockContainer
                $colorHover="#7F7F7F"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/blog");
                }}
              >
                Blog
              </InlineBlockContainer>
            </Text14>
            <Text14 fontWeight="500" color="#A6A6A6">
              <InlineBlockContainer
                $colorHover="#7F7F7F"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact
              </InlineBlockContainer>
            </Text14>
          </Grid>

          {/* Connexion / Inscription (Visible sauf en xs) */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            display={{ xs: "none", sm: "flex" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            sx={{
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "transparent", // Fond transparent par défaut
                color: "#A6A6A6", // Texte gris par défaut
                fontFamily: "Roboto", // Utilisation de Roboto
                fontWeight: 500,
                fontSize: 14,
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "none",
                transition: "0.3s", // Animation fluide
                "&:hover": {
                  backgroundColor: CustomTheme.palette.secondary.main, // Fond rose au hover
                  color: "white", // Texte blanc au hover
                },
              }}
              onClick={() => {
                navigate("/signin");
              }}
            >
              Connexion
            </Button>
            <Button
              sx={{
                backgroundColor: CustomTheme.palette.primary.main, // Orange
                color: "white",
                fontFamily: "Roboto", // Utilisation de Roboto
                fontWeight: 500,
                fontSize: 14,
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: CustomTheme.palette.primary.dark, // Orange plus foncé au survol
                },
              }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              Inscription
            </Button>
          </Grid>

          {/* Icône Menu (Visible seulement en xs) */}
          <Grid
            item
            xs={1}
            display={{ xs: "flex", sm: "none" }}
            justifyContent="flex-end"
          >
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </Grid>

          {/* Drawer pour le menu mobile */}
          <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
            <List sx={{ width: 250 }}>
              {[
                { name: "Fonctionnalités", to: "/features" },
                { name: "FAQ", to: "/faq" },
                { name: "Blog", to: "/blog" },
                { name: "Contact", to: "/contact" },
                { name: "Connexion", to: "/signin" },
                { name: "Inscription", to: "/signup" },
              ].map((obj) => (
                <ListItem
                  button
                  key={obj.name}
                  onClick={() => {
                    navigate(obj.to);
                  }}
                >
                  <ListItemText primary={obj.name} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Grid>
      </FlexContainer>
    </>
  );
};

export default Header;
