import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import InteractionTable from "./InteractionTable";

export interface InteractionProps {}

const Interaction: React.FC<InteractionProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Interactions"
        subTitle="Liste des interactions"
        icon={<QuestionAnswerTwoToneIcon fontSize="large" color="primary" />}
      />
      <InteractionTable />
    </BlockContainer>
  );
};

export default Interaction;
