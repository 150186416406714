import { Interaction } from "../models/interaction";
import { AxiosHttpClient } from "./AxiosHttpService";

export class InteractionService {
  static getInteractions = (input: any) => {
    return AxiosHttpClient.get<Interaction[]>(
      "api/v1/weeventpro/interactions",
      input
    );
  };
}
