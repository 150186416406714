import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { BlockContainer } from "../../../styles/BlockContainer";
import { blogPosts } from "../../Reusable/Utils";
import ContactSection from "../ContactSection";
import Footer from "../Footer";
import Header from "../Header";

const BlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const post = id ? blogPosts[parseInt(id)] : null;

  if (!post) {
    return <Typography variant="h6">Article introuvable.</Typography>;
  }

  return (
    <BlockContainer position="relative" overflow="hidden">
      <Header />
      <Box sx={{ padding: { xs: "40px 16px", md: "80px 128px" } }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          color="secondary.main"
          gutterBottom
        >
          {post.title}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {"Reda EL GUENDOUZI . " + post.date}
        </Typography>
        <Box my={3}>
          <img
            src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/${post.image}`}
            alt={post.title}
            width="70%"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <BlockContainer
          dangerouslySetInnerHTML={{ __html: post.content }}
          sx={{ whiteSpace: "pre-line" }} // Permet de garder les sauts de ligne
        ></BlockContainer>
        <Box mt={4}>
          <Button
            variant="contained"
            component={Link}
            to="/blog"
            sx={{ backgroundColor: "primary.main" }}
          >
            Retour au blog
          </Button>
        </Box>
      </Box>
      <ContactSection />
      <Footer />
    </BlockContainer>
  );
};

export default BlogPost;
