import { Dish } from "../../models/dish";

export const GET_DISHES = "GET_DISHES" as const;
export const GET_DISHES_SUCCESS = "GET_DISHES_SUCCESS" as const;
export const GET_DISHES_FAILURE = "GET_DISHES_FAILURE" as const;

export const getDishesAction = (criteriaList?: string) => ({
  type: GET_DISHES,
  criteriaList: criteriaList,
});
export type GetDishesAction = ReturnType<typeof getDishesAction>;

export const getDishesActionSuccess = (dishes: Dish[]) => ({
  type: GET_DISHES_SUCCESS,
  dishes: dishes,
});
export type GetDishesActionSuccess = ReturnType<typeof getDishesActionSuccess>;

export const getDishesActionFailure = () => ({
  type: GET_DISHES_FAILURE,
});
export type GetDishesActionFailure = ReturnType<typeof getDishesActionFailure>;
