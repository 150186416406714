import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Template } from "../../../../../../../models/template";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../../../store";
import { getUpfrontInvoiceTemplatesAction } from "../../../../../../../store/UpfrontInvoice/actions";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteUpfrontTemplateProps {
  popupActive: Template | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Template | null>>;
  getUpfrontInvoiceTemplatesAction: typeof getUpfrontInvoiceTemplatesAction;
}

const _DeleteUpfrontTemplate: React.FC<DeleteUpfrontTemplateProps> = (
  props
) => {
  const deleteTemplate = (viewId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/upfrontInvoice/template/" + viewId
    )
      .then(() => {
        props.getUpfrontInvoiceTemplatesAction();
        props.setPopupActive(null);
        setIsDeleteUpfrontTemplateLoading(false);
      })
      .catch((err) => {
        setIsDeleteUpfrontTemplateLoading(false);
      });
  };

  const [isDeleteUpfrontTemplateLoading, setIsDeleteUpfrontTemplateLoading] =
    useState(false);

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.label}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isDeleteUpfrontTemplateLoading}
          loading={isDeleteUpfrontTemplateLoading}
          onClick={() => {
            deleteTemplate(props.popupActive!.id);
            setIsDeleteUpfrontTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};
export const DeleteUpfrontTemplate = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUpfrontInvoiceTemplatesAction: getUpfrontInvoiceTemplatesAction,
    },
    dispatch
  )
)(_DeleteUpfrontTemplate);

export default DeleteUpfrontTemplate;
