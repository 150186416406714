import { createFilterOptions } from "@mui/core";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import validator from "validator";
import { AssociatedContact, Contact } from "../../../../../models/contact";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import AssociatedContacts from "../../Clients/AddClient/AssociatedContacts";

const Input = styled("input")({
  display: "none",
});

export interface EditProspectProps {
  popupActive: Contact | null;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<Contact | null>>;
  getProspectsPage: () => void;
  getEmployeesAction: typeof getEmployeesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _EditProspect: React.FC<EditProspectProps> = ({
  popupActive,
  employees,
  setPopupActive,
  getProspectsPage,
  getEmployeesAction,
}) => {
  const editProspect = () => {
    AxiosHttpClient.put("api/v1/weeventpro/contacts", {
      id: popupActive!.id,
      prospect: true,
      imageUrl: inputImageUrl,
      contactCode: inputContactCode,
      type: inputType,
      companyName: inputCompanyName,
      businessName: inputBusinessName,
      legalForm: inputLegalForm,
      fiscalRegime: inputFiscalRegime,
      siretNumber: inputSIRETNumber,
      intracommunityVATNumber: inputIntracommunityVATNumber,
      title: inputTitle,
      firstName: inputFirstName,
      lastName: inputLastName,
      dateOfBirth: inputDateOfBirth
        ? dayjs(inputDateOfBirth).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      maritalStatus: inputMaritalStatus,
      socioProfessionalCategory: inputSocioProfessionalCategory,
      phoneNumber: inputPhoneNumber,
      email: inputEmail,
      address: inputAddress,
      deliveryInstructions: inputDeliveryInstructions,
      associatedContacts: inputContacts,
      preferredPaymentMethod: inputPreferredPaymentMethod,
      responsibleId: inputResponsible?.id,
      customFields: customFieldValues,
    })
      .then(() => {
        getProspectsPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editProspectDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/client"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputFirstNameError(false);
    setInputLastNameError(false);
    setInputCompanyNameError(false);
    setInputEmailError(false);
    setInputPhoneNumberError(false);
    let result = true;
    if (
      inputType === "Particulier" &&
      (inputFirstName === "" || inputLastName === "")
    ) {
      setInputFirstNameError(true);
      setInputLastNameError(true);
      result = false;
    }
    if (inputType === "Entreprise" && inputCompanyName === "") {
      setInputCompanyNameError(true);
      result = false;
    }
    if (inputEmail !== "" && !validator.isEmail(inputEmail)) {
      setInputEmailError(true);
      result = false;
    }
    if (inputPhoneNumber !== "" && !validator.isMobilePhone(inputPhoneNumber)) {
      setInputPhoneNumberError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editProspectDialogContentId",
      });
    }
    return result;
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    popupActive!.imageUrl
  );

  const [prospectImageHovered, setProspectImageHovered] = useState(false);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [inputFirstNameError, setInputFirstNameError] = useState(false);

  const [inputLastNameError, setInputLastNameError] = useState(false);

  const [inputCompanyNameError, setInputCompanyNameError] = useState(false);

  const [inputContactCode, setInputContactCode] = useState(
    popupActive!.contactCode
  );
  const [inputType, setInputType] = useState(popupActive!.type); // Individual, Company, etc.

  // General Information (Company)
  const [inputCompanyName, setInputCompanyName] = useState(
    popupActive!.companyName
  );
  const [inputBusinessName, setInputBusinessName] = useState(
    popupActive!.businessName
  );
  const [inputLegalForm, setInputLegalForm] = useState(popupActive!.legalForm);
  const [inputFiscalRegime, setInputFiscalRegime] = useState(
    popupActive!.fiscalRegime
  );
  const [inputSIRETNumber, setInputSIRETNumber] = useState(
    popupActive!.siretNumber
  );
  const [inputIntracommunityVATNumber, setInputIntracommunityVATNumber] =
    useState(popupActive!.intracommunityVATNumber);

  // General Information (Individual)
  const [inputTitle, setInputTitle] = useState(popupActive!.title);
  const [inputFirstName, setInputFirstName] = useState(popupActive!.firstName);
  const [inputLastName, setInputLastName] = useState(popupActive!.lastName);
  const [inputDateOfBirth, setInputDateOfBirth] = useState<Dayjs | null>(
    popupActive!.dateOfBirth
  );
  const [inputMaritalStatus, setInputMaritalStatus] = useState(
    popupActive!.maritalStatus
  );
  const [inputSocioProfessionalCategory, setInputSocioProfessionalCategory] =
    useState(popupActive!.socioProfessionalCategory);

  // Contact Information
  const [inputPhoneNumber, setInputPhoneNumber] = useState(
    popupActive!.phoneNumber
  );
  const [inputPhoneNumberError, setInputPhoneNumberError] = useState(false);
  const [inputEmail, setInputEmail] = useState(popupActive!.email);
  const [inputEmailError, setInputEmailError] = useState(false);
  const [inputAddress, setInputAddress] = useState(popupActive!.address);
  const [inputDeliveryInstructions, setInputDeliveryInstructions] = useState(
    popupActive!.deliveryInstructions
  );

  // Associated Contacts (Company)
  const [inputContacts, setInputContacts] = useState<AssociatedContact[]>(
    popupActive!.associatedContacts
  );

  // Additional Information
  const [inputPreferredPaymentMethod, setInputPreferredPaymentMethod] =
    useState(popupActive!.preferredPaymentMethod);
  const [inputResponsible, setInputResponsible] = useState<Employee | null>(
    popupActive!.responsible
  );
  const [inputResponsibleText, setInputResponsibleText] = useState("");

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterOptions = createFilterOptions<Employee>({
    stringify: (option) => option.firstName + " " + option.lastName,
  });

  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un prospect"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editProspectDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <BlockContainer
              width="150px"
              height="150px"
              border="rgba(0,0,0,0.1) solid 1px"
              justifyContent="center"
              position="relative"
              textAlign="center"
              onMouseEnter={() => setProspectImageHovered(true)}
              onMouseLeave={() => setProspectImageHovered(false)}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                }}
                alt=""
                src={inputImageUrl}
              />
              {prospectImageHovered && (
                <FlexContainer
                  position="absolute"
                  top="0"
                  left="0"
                  width="150px"
                  height="150px"
                  backgroundColor="rgba(0,0,0,0.5)"
                  alignItems="center"
                  justifyContent="center"
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => {
                        imagePreviewHandler(e.target.files![0]);
                      }}
                    />
                    <IconButton
                      color="secondary"
                      component="span"
                      onClick={() => {}}
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <AddAPhotoIcon
                        sx={{ color: "white", fontSize: "32px" }}
                      />
                    </IconButton>
                  </label>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setInputImageUrl(
                        "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                      )
                    }
                    size="large"
                    style={{ padding: "4px", zIndex: "1" }}
                  >
                    <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                  </IconButton>
                </FlexContainer>
              )}
            </BlockContainer>
          </Grid>
        </Grid>

        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Informations générales
          </Text20>
        </BlockContainer>

        {/* Information sections */}
        {inputType === "Entreprise" && (
          <>
            {/* Section "Informations générales" */}
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <FormControl component="fieldset">
                  <FormLabel>Type prospect</FormLabel>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={inputType}
                    onChange={(e) => setInputType(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Particulier"
                      control={<Radio />}
                      label="Particulier"
                    />
                    <FormControlLabel
                      value="Entreprise"
                      control={<Radio />}
                      label="Entreprise"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Code prospect"
                  value={inputContactCode}
                  onChange={(e) => setInputContactCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nom entreprise"
                  error={inputCompanyNameError}
                  value={inputCompanyName}
                  onChange={(e) => setInputCompanyName(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Raison sociale"
                  value={inputBusinessName}
                  onChange={(e) => setInputBusinessName(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Forme juridique"
                  value={inputLegalForm}
                  onChange={(e) => setInputLegalForm(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Type de régime fiscal"
                  value={inputFiscalRegime}
                  onChange={(e) => setInputFiscalRegime(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Numéro SIRET"
                  value={inputSIRETNumber}
                  onChange={(e) => setInputSIRETNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Numéro de TVA intracommunautaire"
                  value={inputIntracommunityVATNumber}
                  onChange={(e) =>
                    setInputIntracommunityVATNumber(e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Coordonnées
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="N° de téléphone"
                  error={inputPhoneNumberError}
                  value={inputPhoneNumber}
                  onChange={(e) => setInputPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Adresse e-mail"
                  error={inputEmailError}
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Adresse"
                  value={inputAddress}
                  onChange={(e) => setInputAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Insrtuctions de livraisons"
                  multiline
                  rows={4}
                  value={inputDeliveryInstructions}
                  onChange={(e) => {
                    setInputDeliveryInstructions(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Contacts associés
              </Text20>
            </BlockContainer>
            <AssociatedContacts
              inputContacts={inputContacts}
              setInputContacts={setInputContacts}
            />

            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Informations complémentaires
              </Text20>
            </BlockContainer>

            {/* Section "Informations complémentaires" */}
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <Autocomplete
                  id="responsibles"
                  filterOptions={filterOptions}
                  noOptionsText={"Pas de suggestions"}
                  fullWidth
                  getOptionLabel={(option) => {
                    return option.firstName + " " + option.lastName;
                  }}
                  options={employees}
                  value={inputResponsible}
                  onChange={(event, newValue) => {
                    setInputResponsible(newValue!);
                  }}
                  inputValue={inputResponsibleText}
                  onInputChange={(event, newInputValue) => {
                    setInputResponsibleText(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        height: "100px",
                      }}
                      {...props}
                    >
                      <FlexContainer
                        flex="1"
                        justifyContent="center"
                        marginRight="16px"
                      >
                        <img
                          style={{
                            maxWidth: "90px",
                            maxHeight: "90px",
                          }}
                          loading="lazy"
                          src={option.imageUrl}
                          alt=""
                        />
                      </FlexContainer>
                      <FlexContainer flex="4">
                        <Text14 textAlign="left">
                          {option.firstName + " " + option.lastName}
                        </Text14>
                      </FlexContainer>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="responsibles"
                      label="Responsables"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mode de paiement préféré
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={inputPreferredPaymentMethod}
                    label="Mode de paiement préféré"
                    onChange={(e) => {
                      setInputPreferredPaymentMethod(
                        typeof e.target.value === "string" ? e.target.value : ""
                      );
                    }}
                  >
                    <MenuItem value="Espèces">Espèces</MenuItem>
                    <MenuItem value="Chèque">Chèque</MenuItem>
                    <MenuItem value="Virement bancaire">
                      Virement bancaire
                    </MenuItem>
                    <MenuItem value="Carte de crédit">Carte de crédit</MenuItem>
                    <MenuItem value="Carte de débit">Carte de débit</MenuItem>
                    <MenuItem value="PayPal">PayPal</MenuItem>
                    <MenuItem value="Portefeuille numérique">
                      Portefeuille numérique
                    </MenuItem>
                    <MenuItem value="Prélèvement automatique">
                      Prélèvement automatique
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {inputType === "Particulier" && (
          <>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <FormControl component="fieldset">
                  <FormLabel>Type prospect</FormLabel>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={inputType}
                    onChange={(e) => setInputType(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Particulier"
                      control={<Radio />}
                      label="Particulier"
                    />
                    <FormControlLabel
                      value="Entreprise"
                      control={<Radio />}
                      label="Entreprise"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Code prospect"
                  value={inputContactCode}
                  onChange={(e) => setInputContactCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Civilité
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={inputTitle}
                    onChange={(e) => setInputTitle(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="Mr"
                      control={<Radio />}
                      label="Mr"
                    />
                    <FormControlLabel
                      value="Mme"
                      control={<Radio />}
                      label="Mme"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Prénom du prospect"
                  error={inputFirstNameError}
                  value={inputFirstName}
                  onChange={(e) => setInputFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nom du prospect"
                  error={inputLastNameError}
                  value={inputLastName}
                  onChange={(e) => setInputLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date de naissance"
                    inputFormat="DD/MM/YYYY"
                    value={inputDateOfBirth}
                    onChange={(value) => setInputDateOfBirth(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Situation familiale
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={inputMaritalStatus}
                    label="Situation familiale"
                    onChange={(e) => {
                      setInputMaritalStatus(
                        typeof e.target.value === "string" ? e.target.value : ""
                      );
                    }}
                  >
                    <MenuItem value="Célibataire">Célibataire</MenuItem>
                    <MenuItem value="Marié(e)">Marié(e)</MenuItem>
                    <MenuItem value="Divorcé(e)">Divorcé(e)</MenuItem>
                    <MenuItem value="Veuf/Veuve">Veuf/Veuve</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Catégorie socio-professionnelle
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={inputSocioProfessionalCategory}
                    label="Catégorie socio-professionnelle"
                    onChange={(e) => {
                      setInputSocioProfessionalCategory(
                        typeof e.target.value === "string" ? e.target.value : ""
                      );
                    }}
                  >
                    <MenuItem value="Agriculteurs exploitants">
                      Agriculteurs exploitants
                    </MenuItem>
                    <MenuItem value="Artisans, commerçants et chefs d’entreprise">
                      Artisans, commerçants et chefs d’entreprise
                    </MenuItem>
                    <MenuItem value="Cadres et professions intellectuelles supérieures">
                      Cadres et professions intellectuelles supérieures
                    </MenuItem>
                    <MenuItem value="Professions intermédiaires">
                      Professions intermédiaires
                    </MenuItem>
                    <MenuItem value="Employés">Employés</MenuItem>
                    <MenuItem value="Ouvriers">Ouvriers</MenuItem>
                    <MenuItem value="Retraités">Retraités</MenuItem>
                    <MenuItem value="Sans activité professionnelle">
                      Sans activité professionnelle
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Coordonnées
              </Text20>
            </BlockContainer>

            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Adresse e-mail"
                  error={inputEmailError}
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="N° de téléphone"
                  error={inputPhoneNumberError}
                  value={inputPhoneNumber}
                  onChange={(e) => setInputPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Adresse"
                  value={inputAddress}
                  onChange={(e) => setInputAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Insrtuctions de livraisons"
                  multiline
                  rows={4}
                  value={inputDeliveryInstructions}
                  onChange={(e) => {
                    setInputDeliveryInstructions(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Informations complémentaires
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <Autocomplete
                  id="responsibles"
                  filterOptions={filterOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText={"Pas de suggestions"}
                  fullWidth
                  getOptionLabel={(option) => {
                    return option.firstName + " " + option.lastName;
                  }}
                  options={employees}
                  value={inputResponsible}
                  onChange={(event, newValue) => {
                    setInputResponsible(newValue!);
                  }}
                  inputValue={inputResponsibleText}
                  onInputChange={(event, newInputValue) => {
                    setInputResponsibleText(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        height: "100px",
                      }}
                      {...props}
                    >
                      <FlexContainer
                        flex="1"
                        justifyContent="center"
                        marginRight="16px"
                      >
                        <img
                          style={{
                            maxWidth: "90px",
                            maxHeight: "90px",
                          }}
                          loading="lazy"
                          src={option.imageUrl}
                          alt=""
                        />
                      </FlexContainer>
                      <FlexContainer flex="4">
                        <Text14 textAlign="left">
                          {option.firstName + " " + option.lastName}
                        </Text14>
                      </FlexContainer>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="responsibles"
                      label="Responsables"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mode de paiement préféré
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={inputPreferredPaymentMethod}
                    label="Mode de paiement préféré"
                    onChange={(e) => {
                      setInputPreferredPaymentMethod(
                        typeof e.target.value === "string" ? e.target.value : ""
                      );
                    }}
                  >
                    <MenuItem value="Espèces">Espèces</MenuItem>
                    <MenuItem value="Chèque">Chèque</MenuItem>
                    <MenuItem value="Virement bancaire">
                      Virement bancaire
                    </MenuItem>
                    <MenuItem value="Carte de crédit">Carte de crédit</MenuItem>
                    <MenuItem value="Carte de débit">Carte de débit</MenuItem>
                    <MenuItem value="PayPal">PayPal</MenuItem>
                    <MenuItem value="Portefeuille numérique">
                      Portefeuille numérique
                    </MenuItem>
                    <MenuItem value="Prélèvement automatique">
                      Prélèvement automatique
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editProspect();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditProspect = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_EditProspect);

export default EditProspect;
