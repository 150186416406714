import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import CommentTwoToneIcon from "@mui/icons-material/CommentTwoTone";
import { Dialog, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React from "react";
import { Complaint } from "../../../../../models/complaint";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getEmployeeDescription } from "../../../../Reusable/Utils";

export interface ComplaintCommentsProps {
  popupActive: Complaint | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Complaint | null>>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "850px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const ComplaintComments: React.FC<ComplaintCommentsProps> = ({
  popupActive,
  setPopupActive,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Commentaires"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addCommentDialogContentId" dividers>
        {popupActive!.comments.map((comment) => (
          <FlexContainer padding={"8px 0 0 0"} position={"relative"}>
            <FlexContainer flexDirection={"column"} margin={"0 0 24px 0"}>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="8px 16px"
                lineHeight="20px"
                fontWeight="600"
              >
                {comment.creationDateTime
                  ? dayjs(comment.creationDateTime).format(
                      "DD/MM/YYYY [à] HH:mm"
                    )
                  : ""}
              </Text14>
            </FlexContainer>
            <FlexContainer flexDirection={"column"} alignItems={"center"}>
              <FlexContainer
                width={"40px"}
                height={"40px"}
                backgroundColor={CustomTheme.palette.background.default}
                justifyContent={"center"}
                alignItems={"center"}
                margin={"0 0 8px 0"}
              >
                <CommentTwoToneIcon color="primary" />
              </FlexContainer>
              <FlexContainer
                border={"1px solid rgba(0,0,0,0.1)"}
                flex={"1"}
                width={"0"}
              ></FlexContainer>
            </FlexContainer>
            <FlexContainer
              flexDirection={"column"}
              alignItems={"flex-start"}
              margin={"0 0 24px 0"}
              flex={"1"}
            >
              <FlexContainer alignItems={"center"} margin={"0 8px"}>
                <BlockContainer
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  border="rgba(0,0,0,0.1) solid 1px"
                  justifyContent="center"
                  position="relative"
                  textAlign="center"
                  backgroundColor="white"
                  margin="auto"
                  borderRadius={"40px"}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      margin: "auto",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                    }}
                    alt=""
                    src={comment.commentWriter?.imageUrl}
                  />
                </BlockContainer>
                <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                  {getEmployeeDescription(comment.commentWriter)}
                </Text16>
              </FlexContainer>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="8px 16px"
                lineHeight="20px"
              >
                {comment.content}
              </Text14>
              {comment.files.map((file) => (
                <FlexContainer alignItems={"center"} margin="0 12px">
                  <AttachFileRoundedIcon fontSize="small" color="primary" />
                  <Text14
                    cursor="pointer"
                    margin="0 4px"
                    color={CustomTheme.palette.secondary.main}
                    textDecorationLine="underline"
                    onClick={(e) => {
                      fetch(file.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", file.name);
                          document.body.appendChild(link);
                          link.click();
                        });
                    }}
                  >
                    {file.name}
                  </Text14>
                </FlexContainer>
              ))}
            </FlexContainer>
          </FlexContainer>
        ))}
      </DialogContentMui>
    </Dialog>
  );
};

export default ComplaintComments;
