import { createFilterOptions } from "@mui/core";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CustomField } from "../../../../../../models/customField";
import { EmailTemplate } from "../../../../../../models/emailTemplate";
import { Employee } from "../../../../../../models/employee";
import { SmsTemplate } from "../../../../../../models/smsTemplate";
import { UserParameter } from "../../../../../../models/userParameters";
import { View } from "../../../../../../models/view";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../../store";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text12, Text14, Text16, Text20 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  CC_SUPPLIER_ORDER_EMAILS,
  CC_SUPPLIER_ORDER_PHONE_NUMBERS,
  SUPPLIER_ORDER_FORMAT,
  SUPPLIER_ORDER_INCREMENT,
  SUPPLIER_ORDER_NUMBER_OF_DIGITS,
  getUserParameterValueByName,
} from "../../../../../Reusable/Utils";
import DeleteCustomField from "./DeleteCustomField";
import DeleteSupplierOrderEmailTemplate from "./DeleteSupplierOrderEmailTemplate";
import DeleteSupplierOrderSmsTemplate from "./DeleteSupplierOrderSmsTemplate";
import DeleteView from "./DeleteView";
import EditCustomField from "./EditCustomField";
import EditSupplierOrderEmailTemplate from "./EditSupplierOrderEmailTemplate";
import EditSupplierOrderSmsTemplate from "./EditSupplierOrderSmsTemplate";
import EditView from "./EditView";

export interface CustomizeSupplierOrdersProps {
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
}

const _CustomizeSupplierOrders: React.FC<CustomizeSupplierOrdersProps> = ({
  employees,
  getEmployeesAction,
}) => {
  const addCustomField = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/parameters/customFields/supplierOrder",
      {
        id: "",
        name: inputName,
        type: inputType,
        values: inputValues,
      }
    )
      .then(() => {
        getCustomFields();
        setCustomFieldAddActive(false);
        resetCustomFieldForm();
        setIsAddCustomFieldLoading(false);
        setAddCustomFieldFailed("");
      })
      .catch((err) => {
        setAddCustomFieldFailed(err.cause);
        setIsAddCustomFieldLoading(false);
      });
  };

  const addView = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/views/supplierOrder", {
      id: "",
      name: inputViewName,
      fields: inputSelectedFields,
    })
      .then(() => {
        getViews();
        setViewAddActive(false);
        resetViewForm();
        setIsAddViewLoading(false);
        setAddViewFailed("");
      })
      .catch((err) => {
        setAddViewFailed(err.cause);
        setIsAddViewLoading(false);
      });
  };

  const addSupplierOrderNumberFormat = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: SUPPLIER_ORDER_FORMAT,
        parameterValue: inputSupplierOrderNumberFormat,
      },
      {
        parameterName: SUPPLIER_ORDER_NUMBER_OF_DIGITS,
        parameterValue: inputNumberOfDigits,
      },
    ])
      .then(() => {})
      .catch((err) => {
        setAddSupplierOrderNumberFormatFailed(err.cause);
        setIsAddSupplierOrderNumberFormatLoading(false);
      });
  };

  const editIncrementValue = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: SUPPLIER_ORDER_INCREMENT,
        parameterValue: inputIncrementValue,
      },
    ]).then(() => {});
  };

  const addSupplierOrderEmailTemplate = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/parameters/template/supplierOrder/email",
      {
        id: "",
        name: inputEmailTemplateName,
        subject: inputSubject,
        content: inputEmailContent,
      }
    )
      .then(() => {
        getSupplierOrderEmailTemplates();
        setSupplierOrderEmailTemplateAddActive(false);
        resetSupplierOrderEmailTemplateForm();
        setIsAddSupplierOrderEmailTemplateLoading(false);
        setAddSupplierOrderEmailTemplateFailed("");
      })
      .catch((err) => {
        setAddSupplierOrderEmailTemplateFailed(err.cause);
        setIsAddSupplierOrderEmailTemplateLoading(false);
      });
  };

  const addSupplierOrderSmsTemplate = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/parameters/template/supplierOrder/sms",
      {
        id: "",
        name: inputSmsTemplateName,
        content: inputSmsContent,
      }
    )
      .then(() => {
        getSupplierOrderSmsTemplates();
        setSupplierOrderSmsTemplateAddActive(false);
        resetSupplierOrderSmsTemplateForm();
        setIsAddSupplierOrderSmsTemplateLoading(false);
        setAddSupplierOrderSmsTemplateFailed("");
      })
      .catch((err) => {
        setAddSupplierOrderSmsTemplateFailed(err.cause);
        setIsAddSupplierOrderSmsTemplateLoading(false);
      });
  };

  const getSupplierOrderEmailTemplates = () => {
    AxiosHttpClient.get<EmailTemplate[]>(
      "api/v1/weeventpro/parameters/template/supplierOrder/email"
    )
      .then((res) => {
        setSupplierOrderEmailTemplates(res);
      })
      .catch((err) => {});
  };

  const getSupplierOrderSmsTemplates = () => {
    AxiosHttpClient.get<SmsTemplate[]>(
      "api/v1/weeventpro/parameters/template/supplierOrder/sms"
    )
      .then((res) => {
        setSupplierOrderSmsTemplates(res);
      })
      .catch((err) => {});
  };

  const setCCSupplierOrderEmails = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: CC_SUPPLIER_ORDER_EMAILS,
        parameterValue: inputCCEmails
          .map((inputCCEmail) =>
            typeof inputCCEmail === "string" ? inputCCEmail : inputCCEmail.email
          )
          .join(","),
      },
    ])
      .then(() => {})
      .catch((err) => {
        setAddCCSupplierOrderEmailsFailed(err.cause);
        setIsAddCCSupplierOrderEmailsLoading(false);
      });
  };

  const setCCSupplierOrderPhoneNumbers = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: CC_SUPPLIER_ORDER_PHONE_NUMBERS,
        parameterValue: inputCCPhoneNumbers
          .map((inputCCPhoneNumber) =>
            typeof inputCCPhoneNumber === "string"
              ? inputCCPhoneNumber
              : inputCCPhoneNumber.phoneNumber
          )
          .join(","),
      },
    ])
      .then(() => {})
      .catch((err) => {
        //setAddCCSupplierOrderPhoneNumbersFailed(err.cause);
        //setIsAddCCSupplierOrderPhoneNumbersLoading(false);
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        SUPPLIER_ORDER_FORMAT +
        "," +
        SUPPLIER_ORDER_NUMBER_OF_DIGITS +
        "," +
        SUPPLIER_ORDER_INCREMENT +
        "," +
        CC_SUPPLIER_ORDER_EMAILS +
        "," +
        CC_SUPPLIER_ORDER_PHONE_NUMBERS,
    }).then((res) => {
      setInputSupplierOrderNumberFormat(
        getUserParameterValueByName(SUPPLIER_ORDER_FORMAT, res)
      );
      setInputNumberOfDigits(
        getUserParameterValueByName(SUPPLIER_ORDER_NUMBER_OF_DIGITS, res)
      );
      setInputIncrementValue(
        getUserParameterValueByName(SUPPLIER_ORDER_INCREMENT, res)
      );
      if (getUserParameterValueByName(CC_SUPPLIER_ORDER_EMAILS, res))
        setInputCCEmails(
          getUserParameterValueByName(CC_SUPPLIER_ORDER_EMAILS, res)
            .split(",")
            .map((email) => {
              let employee = employees.find(
                (employee) => employee.email === email
              );
              if (employee) return employee;
              return email;
            })
        );
      if (getUserParameterValueByName(CC_SUPPLIER_ORDER_PHONE_NUMBERS, res))
        setInputCCPhoneNumbers(
          getUserParameterValueByName(CC_SUPPLIER_ORDER_PHONE_NUMBERS, res)
            .split(",")
            .map((phoneNumber) => {
              let employee = employees.find(
                (employee) => employee.phoneNumber === phoneNumber
              );
              if (employee) return employee;
              return phoneNumber;
            })
        );
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/supplierOrder"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getViews = () => {
    AxiosHttpClient.get<View[]>(
      "api/v1/weeventpro/parameters/views/supplierOrder"
    )
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const deleteCustomField = (customFieldId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/customFields/supplierOrder/" + customFieldId
    )
      .then(() => {
        getCustomFields();
        setPopupDeleteActive(null);
        setIsDeleteCustomFieldLoading(false);
      })
      .catch((err) => {
        setIsDeleteCustomFieldLoading(false);
      });
  };

  const deleteView = (viewId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/views/supplierOrder/" + viewId
    )
      .then(() => {
        getViews();
        setViewPopupDeleteActive(null);
        setIsDeleteViewLoading(false);
      })
      .catch((err) => {
        setIsDeleteViewLoading(false);
      });
  };

  const validateSupplierOrderEmailTemplateForm = () => {
    setInputEmailTemplateNameError(false);
    setInputSubjectError(false);
    setInputEmailContentError(false);
    let result = true;
    if (inputEmailTemplateName === "") {
      setInputEmailTemplateNameError(true);
      result = false;
    }
    if (inputSubject === "") {
      setInputSubjectError(true);
      result = false;
    }
    if (inputEmailContent === "") {
      setInputEmailContentError(true);
      result = false;
    }
    if (!result) {
      setAddSupplierOrderEmailTemplateFailed("Champs manquants");
    }
    return result;
  };

  const resetSupplierOrderEmailTemplateForm = () => {
    setAddSupplierOrderEmailTemplateFailed("");
    setInputEmailTemplateName("");
    setInputSubject("");
    setInputEmailContent("");
    setInputEmailTemplateNameError(false);
    setInputSubjectError(false);
    setInputEmailContentError(false);
  };

  const validateSupplierOrderSmsTemplateForm = () => {
    setInputSmsTemplateNameError(false);
    setInputSmsContentError(false);
    let result = true;
    if (inputSmsTemplateName === "") {
      setInputSmsTemplateNameError(true);
      result = false;
    }
    if (inputSmsContent === "") {
      setInputSmsContentError(true);
      result = false;
    }
    if (!result) {
      setAddSupplierOrderSmsTemplateFailed("Champs manquants");
    }
    return result;
  };

  const resetSupplierOrderSmsTemplateForm = () => {
    setAddSupplierOrderSmsTemplateFailed("");
    setInputSmsTemplateName("");
    setInputSmsContent("");
    setInputSmsTemplateNameError(false);
    setInputSmsContentError(false);
  };

  const [inputViewName, setInputViewName] = useState("");

  const [inputViewNameError, setInputViewNameError] = useState(false);

  const [views, setViews] = useState<View[]>([]);

  const [viewPopupDeleteActive, setViewPopupDeleteActive] =
    useState<View | null>(null);

  const [viewPopupEditActive, setViewPopupEditActive] = useState<View | null>(
    null
  );

  const [viewAddActive, setViewAddActive] = useState(false);

  const [inputSelectedFields, setInputSelectedFields] = useState<string[]>([
    "N° de commande interne",
  ]);

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputType, setInputType] = useState("");

  const [inputTypeError, setInputTypeError] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [inputValueError, setInputValueError] = useState(false);

  const [customFieldNameExpanded, setCustomFieldNameExpanded] = useState("");

  const [inputValues, setInputValues] = useState<string[]>([]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldAddActive, setCustomFieldAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] =
    useState<CustomField | null>(null);

  const [popupEditActive, setPopupEditActive] = useState<CustomField | null>(
    null
  );

  const [addCustomFieldFailed, setAddCustomFieldFailed] = useState("");

  const [addViewFailed, setAddViewFailed] = useState("");

  const [
    addSupplierOrderNumberFormatFailed,
    setAddSupplierOrderNumberFormatFailed,
  ] = useState("");

  const [isAddCustomFieldLoading, setIsAddCustomFieldLoading] = useState(false);

  const [isAddViewLoading, setIsAddViewLoading] = useState(false);

  const [
    isAddSupplierOrderNumberFormatLoading,
    setIsAddSupplierOrderNumberFormatLoading,
  ] = useState(false);

  const [isDeleteCustomFieldLoading, setIsDeleteCustomFieldLoading] =
    useState(false);

  const [isDeleteViewLoading, setIsDeleteViewLoading] = useState(false);

  const [inputSupplierOrderNumberFormat, setInputSupplierOrderNumberFormat] =
    useState("");

  const [inputNumberOfDigits, setInputNumberOfDigits] = useState("");

  const [inputIncrementValue, setInputIncrementValue] = useState("");

  const [inputCCEmails, setInputCCEmails] = useState<(Employee | string)[]>([]);

  const [inputCCEmailsText, setInputCCEmailsText] = useState("");

  const [inputCCPhoneNumbers, setInputCCPhoneNumbers] = useState<
    (Employee | string)[]
  >([]);

  const [inputCCPhoneNumbersText, setInputCCPhoneNumbersText] = useState("");

  const [addCCSupplierOrderEmailsFailed, setAddCCSupplierOrderEmailsFailed] =
    useState("");

  const [
    isAddCCSupplierOrderEmailsLoading,
    setIsAddCCSupplierOrderEmailsLoading,
  ] = useState(false);

  const [
    supplierOrderEmailTemplateAddActive,
    setSupplierOrderEmailTemplateAddActive,
  ] = useState(false);

  const [
    supplierOrderSmsTemplateAddActive,
    setSupplierOrderSmsTemplateAddActive,
  ] = useState(false);

  const [
    addSupplierOrderEmailTemplateFailed,
    setAddSupplierOrderEmailTemplateFailed,
  ] = useState("");

  const [
    addSupplierOrderSmsTemplateFailed,
    setAddSupplierOrderSmsTemplateFailed,
  ] = useState("");

  const [inputEmailTemplateName, setInputEmailTemplateName] = useState("");

  const [inputEmailTemplateNameError, setInputEmailTemplateNameError] =
    useState(false);

  const [inputSmsTemplateName, setInputSmsTemplateName] = useState("");

  const [inputSmsTemplateNameError, setInputSmsTemplateNameError] =
    useState(false);

  const [inputSubject, setInputSubject] = useState("");

  const [inputSubjectError, setInputSubjectError] = useState(false);

  const [inputEmailContent, setInputEmailContent] = useState("");

  const [inputEmailContentError, setInputEmailContentError] = useState(false);

  const [inputSmsContent, setInputSmsContent] = useState("");

  const [inputSmsContentError, setInputSmsContentError] = useState(false);

  const [
    isAddSupplierOrderEmailTemplateLoading,
    setIsAddSupplierOrderEmailTemplateLoading,
  ] = useState(false);

  const [
    isAddSupplierOrderSmsTemplateLoading,
    setIsAddSupplierOrderSmsTemplateLoading,
  ] = useState(false);

  const [supplierOrderEmailTemplates, setSupplierOrderEmailTemplates] =
    useState<EmailTemplate[]>([]);

  const [
    supplierOrderEmailTemplatePopupEditActive,
    setSupplierOrderEmailTemplatePopupEditActive,
  ] = useState<EmailTemplate | null>(null);

  const [
    supplierOrderEmailTemplatePopupDeleteActive,
    setSupplierOrderEmailTemplatePopupDeleteActive,
  ] = useState<EmailTemplate | null>(null);

  const [supplierOrderSmsTemplates, setSupplierOrderSmsTemplates] = useState<
    SmsTemplate[]
  >([]);

  const [
    supplierOrderSmsTemplatePopupEditActive,
    setSupplierOrderSmsTemplatePopupEditActive,
  ] = useState<SmsTemplate | null>(null);

  const [
    supplierOrderSmsTemplatePopupDeleteActive,
    setSupplierOrderSmsTemplatePopupDeleteActive,
  ] = useState<SmsTemplate | null>(null);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const filterEmployeesEmailsOptions = createFilterOptions<Employee>({
    stringify: (option) => option.email,
  });

  const filterEmployeesPhoneNumbersOptions = createFilterOptions<Employee>({
    stringify: (option) => option.phoneNumber,
  });

  const resetCustomFieldForm = () => {
    setInputName("");
    setInputType("");
    setInputValue("");
    setInputValues([]);
  };

  const resetViewForm = () => {
    setInputViewName("");
    setInputSelectedFields(["N° de commande interne"]);
  };

  const validateCustomFieldForm = () => {
    setInputNameError(false);
    setInputTypeError(false);
    setInputValueError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputType === "") {
      setInputTypeError(true);
      result = false;
    }
    if (
      (inputType === "Liste déroulante" ||
        inputType === "Boutton radio" ||
        inputType === "Checkbox") &&
      inputValues.length === 0
    ) {
      setInputValueError(true);
      result = false;
    }
    if (!result) {
      setAddCustomFieldFailed("Champs manquants");
    }
    return result;
  };

  const validateViewForm = () => {
    setInputViewNameError(false);
    let result = true;
    if (inputViewName === "") {
      setInputViewNameError(true);
      result = false;
    }
    if (!result) {
      setAddViewFailed("Champs manquants");
    }
    return result;
  };

  const getAllFields = (): string[] => {
    let normalFields = [
      "N° de commande interne",
      "N° de commande",
      "Date de commande",
      "Date de livraison",
      "Fournisseur",
      "Description",
      "Prix total TTC",
      "Prix total HT",
      "Prix total TVA",
      "TVA globale",
      "Statut",
      "Produits",
      "Frais supplémentaires",
      "Commentaire",
    ];
    return [
      ...normalFields,
      ...customFields.map((customField) => "Custom::" + customField.id),
    ];
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: string[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        inputSelectedFields,
        result.source.index,
        result.destination.index
      );

      setInputSelectedFields(items);
    }
  };

  useEffect(() => {
    getSupplierOrderEmailTemplates();
    getSupplierOrderSmsTemplates();
    getEmployeesAction();
    getCustomFields();
    getViews();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer flexDirection="column" width="100%">
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Templates des emails
        </Text20>
      </BlockContainer>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier les emails à toujours mettre en CC:
          </Text14>
        </Grid>
        {addCCSupplierOrderEmailsFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddCCSupplierOrderEmailsFailed("");
              }}
            >
              {addCCSupplierOrderEmailsFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid item xs={1} sm={1} md={2}>
          <Autocomplete
            id="cc"
            filterOptions={filterEmployeesEmailsOptions}
            noOptionsText={"Pas de suggestions"}
            freeSolo
            fullWidth
            multiple
            filterSelectedOptions
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.email;
            }}
            options={employees}
            value={inputCCEmails}
            onChange={(event, newValue) => {
              setInputCCEmails(newValue!);
            }}
            inputValue={inputCCEmailsText}
            onInputChange={(event, newInputValue) => {
              setInputCCEmailsText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" name="cc" label="CC" />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            loading={isAddCCSupplierOrderEmailsLoading}
            disabled={isAddCCSupplierOrderEmailsLoading}
            onClick={() => {
              setCCSupplierOrderEmails();
            }}
          >
            Enregistrer
          </ButtonMui>
        </Grid>
      </Grid>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setSupplierOrderEmailTemplateAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un template
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={supplierOrderEmailTemplateAddActive}>
        {addSupplierOrderEmailTemplateFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddSupplierOrderEmailTemplateFailed("");
              }}
            >
              {addSupplierOrderEmailTemplateFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputEmailTemplateNameError}
                value={inputEmailTemplateName}
                onChange={(e) => setInputEmailTemplateName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="subject"
                label="Objet"
                error={inputSubjectError}
                value={inputSubject}
                onChange={(e) => setInputSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="content"
                label="Corps du message"
                multiline
                rows={5}
                error={inputEmailContentError}
                value={inputEmailContent}
                onChange={(e) => setInputEmailContent(e.target.value)}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddSupplierOrderEmailTemplateLoading}
            loading={isAddSupplierOrderEmailTemplateLoading}
            onClick={() => {
              setIsAddSupplierOrderEmailTemplateLoading(true);
              if (validateSupplierOrderEmailTemplateForm())
                addSupplierOrderEmailTemplate();
              else setIsAddSupplierOrderEmailTemplateLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setSupplierOrderEmailTemplateAddActive(false);
              resetSupplierOrderEmailTemplateForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {supplierOrderEmailTemplates.map(
          (supplierOrderEmailTemplate, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              <FlexContainer
                flexDirection="column"
                $borderRadius="10px"
                alignItems="center"
                position="relative"
              >
                <FlexContainer
                  flexDirection="column"
                  alignItems="center"
                  $borderRadius="15px"
                  elevation={3}
                  width="255px"
                  overflow="hidden"
                >
                  <MailIcon color="primary" style={{ fontSize: 100 }} />
                  <Text16>{supplierOrderEmailTemplate.name}</Text16>
                  <FlexContainer margin="10px">
                    <ButtonMui
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        setSupplierOrderEmailTemplatePopupEditActive(
                          supplierOrderEmailTemplate
                        )
                      }
                    >
                      Modifier
                    </ButtonMui>
                    <ButtonMui
                      color="secondary"
                      variant="outlined"
                      margin="0 0 0 10px"
                      onClick={() =>
                        setSupplierOrderEmailTemplatePopupDeleteActive(
                          supplierOrderEmailTemplate
                        )
                      }
                    >
                      Supprimer
                    </ButtonMui>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Grid>
          )
        )}
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Templates des SMS
        </Text20>
      </BlockContainer>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier les numéros de téléphone à toujours mettre en CC:
          </Text14>
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <Autocomplete
            id="cc"
            filterOptions={filterEmployeesPhoneNumbersOptions}
            noOptionsText={"Pas de suggestions"}
            freeSolo
            fullWidth
            multiple
            filterSelectedOptions
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.phoneNumber;
            }}
            options={employees}
            value={inputCCPhoneNumbers}
            onChange={(event, newValue) => {
              setInputCCPhoneNumbers(newValue!);
            }}
            inputValue={inputCCPhoneNumbersText}
            onInputChange={(event, newInputValue) => {
              setInputCCPhoneNumbersText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" name="cc" label="CC" />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              setCCSupplierOrderPhoneNumbers();
            }}
          >
            Enregistrer
          </ButtonMui>
        </Grid>
      </Grid>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setSupplierOrderSmsTemplateAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un template
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={supplierOrderSmsTemplateAddActive}>
        {addSupplierOrderSmsTemplateFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddSupplierOrderSmsTemplateFailed("");
              }}
            >
              {addSupplierOrderSmsTemplateFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputSmsTemplateNameError}
                value={inputSmsTemplateName}
                onChange={(e) => setInputSmsTemplateName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="content"
                label="Corps du message"
                multiline
                rows={5}
                error={inputSmsContentError}
                value={inputSmsContent}
                onChange={(e) => setInputSmsContent(e.target.value)}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddSupplierOrderSmsTemplateLoading}
            loading={isAddSupplierOrderSmsTemplateLoading}
            onClick={() => {
              setIsAddSupplierOrderSmsTemplateLoading(true);
              if (validateSupplierOrderSmsTemplateForm())
                addSupplierOrderSmsTemplate();
              else setIsAddSupplierOrderSmsTemplateLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setSupplierOrderSmsTemplateAddActive(false);
              resetSupplierOrderSmsTemplateForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {supplierOrderSmsTemplates.map((supplierOrderSmsTemplate, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                flexDirection="column"
                alignItems="center"
                $borderRadius="15px"
                elevation={3}
                width="255px"
                overflow="hidden"
              >
                <MailIcon color="primary" style={{ fontSize: 100 }} />
                <Text16>{supplierOrderSmsTemplate.name}</Text16>
                <FlexContainer margin="10px">
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      setSupplierOrderSmsTemplatePopupEditActive(
                        supplierOrderSmsTemplate
                      )
                    }
                  >
                    Modifier
                  </ButtonMui>
                  <ButtonMui
                    color="secondary"
                    variant="outlined"
                    margin="0 0 0 10px"
                    onClick={() =>
                      setSupplierOrderSmsTemplatePopupDeleteActive(
                        supplierOrderSmsTemplate
                      )
                    }
                  >
                    Supprimer
                  </ButtonMui>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ mt: "32px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Format des numéros de commandes
        </Text20>
      </BlockContainer>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier le format du numérotage des commandes:
          </Text14>
          <Text12 color="black" opacity="0.7">
            Exemple: si vos numéros de commande sont du format "CM0001",
            "CM0002", etc tapez "CM%increment%" pour le format et 4 dans le
            nombre de chiffres dans l'incrément
          </Text12>
        </Grid>
        {addSupplierOrderNumberFormatFailed !== "" && (
          <Grid item xs={1} sm={1} md={2}>
            <BlockContainer margin="0 0 8px 0">
              <Alert severity="error">
                {addSupplierOrderNumberFormatFailed}
              </Alert>
            </BlockContainer>
          </Grid>
        )}
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="supplierOrderNumberFormat"
            label="Format"
            value={inputSupplierOrderNumberFormat}
            onChange={(e) => {
              setInputSupplierOrderNumberFormat(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="numberOfDigits"
            label="Nombre de chiffres dans l'incrément"
            value={inputNumberOfDigits}
            onChange={(e) => {
              setInputNumberOfDigits(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <FlexContainer>
            <ButtonMui
              color="primary"
              variant="contained"
              size="large"
              disabled={isAddSupplierOrderNumberFormatLoading}
              loading={isAddSupplierOrderNumberFormatLoading}
              onClick={() => {
                addSupplierOrderNumberFormat();
              }}
            >
              Enregistrer
            </ButtonMui>
          </FlexContainer>
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          <Text14>Veuillez spécifier la valeur actuelle de l'incrément:</Text14>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="incrementValue"
            label="Valeur de l'incrément"
            value={inputIncrementValue}
            onChange={(e) => {
              setInputIncrementValue(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              editIncrementValue();
            }}
          >
            Enregistrer
          </ButtonMui>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "32px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Champs personnalisés
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setCustomFieldAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un champ personnalisé
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={customFieldAddActive}>
        {addCustomFieldFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddCustomFieldFailed("");
              }}
            >
              {addCustomFieldFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="custpomFieldTypeLabel">Type</InputLabel>
                <Select
                  labelId="custpomFieldTypeLabel"
                  id="custpomFieldType"
                  required
                  error={inputTypeError}
                  value={inputType}
                  label="Type"
                  onChange={(e) => setInputType(e.target.value)}
                >
                  <MenuItem value="Texte">Texte</MenuItem>
                  <MenuItem value="Liste déroulante">Liste déroulante</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Boutton radio">Boutton radio</MenuItem>
                  <MenuItem value="Checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {["Liste déroulante", "Boutton radio", "Checkbox"].includes(
              inputType
            ) && (
              <Grid item xs={1} sm={1} md={2} alignItems="center">
                <Text16>
                  Veuillez saisir les valeur possibles pour ce champs!
                </Text16>
                <FlexContainer alignItems="center">
                  <TextField
                    fullWidth
                    name="name"
                    label="Valeur"
                    sx={{ margin: "0 16px 0 0" }}
                    error={inputValueError}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    disabled={inputValue === ""}
                    onClick={() => {
                      setInputValues([...inputValues, inputValue]);
                      setInputValue("");
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </FlexContainer>
                <FlexContainer justifyContent="center" margin="16px 0">
                  <FlexContainer
                    flexDirection="column"
                    backgroundColor={CustomTheme.palette.primary.light}
                    elevation={3}
                    $borderRadius="4px"
                  >
                    {inputValues.map((value, index) => (
                      <FlexContainer justifyContent="space-between" key={index}>
                        <Text14 color={CustomTheme.palette.primary.main}>
                          {value}
                        </Text14>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            let newArr = [...inputValues];
                            newArr.splice(index, 1);
                            setInputValues(newArr);
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            )}
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddCustomFieldLoading}
            loading={isAddCustomFieldLoading}
            onClick={() => {
              setIsAddCustomFieldLoading(true);
              if (validateCustomFieldForm()) addCustomField();
              else setIsAddCustomFieldLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setCustomFieldAddActive(false);
              resetCustomFieldForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 5 }}
      >
        {customFields.map((customField, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              elevation={3}
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                position="absolute"
                top="0"
                right="0"
                padding="4px"
                alignItems="flex-end"
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setPopupEditActive(customField)}
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => setPopupDeleteActive(customField)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </FlexContainer>
              <FlexContainer padding="32px 0 16px 0">
                <Text14>{customField.name}</Text14>
              </FlexContainer>
              <FlexContainer
                padding="8px 0"
                width="100%"
                backgroundColor={CustomTheme.palette.primary.light}
                $borderBottomLeftRadius="10px"
                $borderBottomRightRadius="10px"
                flexDirection="column"
              >
                <FlexContainer
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  $cursorHover={
                    ["Liste déroulante", "Boutton radio", "Checkbox"].includes(
                      customField.type
                    )
                      ? "pointer"
                      : "auto"
                  }
                  onClick={() => {
                    if (customFieldNameExpanded === customField.name)
                      setCustomFieldNameExpanded("");
                    else {
                      if (customField.values.length > 0)
                        setCustomFieldNameExpanded(customField.name);
                    }
                  }}
                >
                  <Text14
                    fontWeight="500"
                    margin="4px"
                    color={CustomTheme.palette.primary.main}
                  >
                    {customField.type}
                  </Text14>
                  {customField.values.length > 0 &&
                    customFieldNameExpanded !== customField.name && (
                      <ExpandMoreIcon color="primary" />
                    )}
                  {customField.values.length > 0 &&
                    customFieldNameExpanded === customField.name && (
                      <ExpandLessIcon color="primary" />
                    )}
                </FlexContainer>
                <Collapse
                  orientation="vertical"
                  in={customFieldNameExpanded === customField.name}
                >
                  {customField.values.map((value, index) => (
                    <FlexContainer key={index}>
                      <Text14 color={CustomTheme.palette.primary.main}>
                        {value}
                      </Text14>
                    </FlexContainer>
                  ))}
                </Collapse>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ mt: "32px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Vues personnalisées
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setViewAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une vue personnalisée
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={viewAddActive}>
        {addViewFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddViewFailed("");
              }}
            >
              {addViewFailed}
            </Alert>
          </BlockContainer>
        )}
        <BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputViewNameError}
                value={inputViewName}
                onChange={(e) => setInputViewName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={getAllFields()}
                disableCloseOnSelect
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={
                        option.includes("Custom::")
                          ? getCustomFieldNameByCustomFieldId(
                              option.substring(8)
                            )
                          : option
                      }
                      disabled={option === "N° de commande interne"}
                      key={index}
                    />
                  ));
                }}
                getOptionLabel={(option) => option}
                value={inputSelectedFields}
                getOptionDisabled={(option) =>
                  !inputSelectedFields.includes(option) &&
                  inputSelectedFields.length >= 7
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputSelectedFields.includes(option)}
                    />
                    {option.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(option.substring(8))
                      : option}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputSelectedFields([
                    "N° de commande interne",
                    ...values.filter(
                      (option) => option !== "N° de commande interne"
                    ),
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Champs"
                    helperText={
                      "Champs sélectionnés: " +
                      inputSelectedFields.length +
                      "/7"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <BlockContainer
            padding="16px 0"
            border="1px dashed black"
            margin="16px 0"
            $borderRadius="10px"
          >
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text16>Spécifiez l'ordre des champs</Text16>
            </FlexContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                direction={matches ? "horizontal" : "vertical"}
              >
                {(provided, snapshot) => (
                  <FlexContainer
                    padding="16px 8px 16px 8px"
                    flexDirection={matches ? "row" : "column"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {inputSelectedFields.map((field, index) => (
                      <Draggable
                        key={field + "id"}
                        draggableId={field + "id"}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <FlexContainer
                            height="56px"
                            backgroundColor={CustomTheme.palette.primary.main}
                            elevation={snapshot.isDragging ? 9 : 0}
                            flex="1"
                            alignItems="center"
                            justifyContent="center"
                            padding="16px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Text14
                              textAlign="center"
                              color="white"
                              margin="0"
                              lineHeight="14px"
                            >
                              {field.includes("Custom::")
                                ? getCustomFieldNameByCustomFieldId(
                                    field.substring(8)
                                  )
                                : field}
                            </Text14>
                          </FlexContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FlexContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BlockContainer>
        </BlockContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddViewLoading}
            loading={isAddViewLoading}
            onClick={() => {
              setIsAddViewLoading(true);
              if (validateViewForm()) addView();
              else setIsAddViewLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setViewAddActive(false);
              resetViewForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {views.map((view, index) => (
        <BlockContainer padding="16px 0" key={index}>
          <FlexContainer
            flexDirection="column"
            elevation={3}
            $borderRadius="10px"
            alignItems="center"
            position="relative"
          >
            <FlexContainer
              position="absolute"
              top="0"
              right="0"
              padding="4px"
              alignItems="flex-end"
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() => setViewPopupEditActive(view)}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => setViewPopupDeleteActive(view)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text14>Vue: </Text14>
              <Text14 fontWeight="500" margin="4px 0">
                {view.name}
              </Text14>
            </FlexContainer>
            <FlexContainer
              padding="16px 8px 16px 8px"
              width="100%"
              flexWrap="wrap"
            >
              {view.fields.map((field, index) => (
                <FlexContainer
                  key={index}
                  height="56px"
                  backgroundColor={CustomTheme.palette.primary.main}
                  flex="1"
                  alignItems="center"
                  justifyContent="center"
                  padding="16px"
                >
                  <Text14 color="white" margin="0" lineHeight="14px">
                    {field.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(field.substring(8))
                      : field}
                  </Text14>
                </FlexContainer>
              ))}
            </FlexContainer>
          </FlexContainer>
        </BlockContainer>
      ))}
      {popupEditActive !== null && (
        <EditCustomField
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getCustomFields={getCustomFields}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteCustomField
          popupActive={popupDeleteActive}
          isDeleteCustomFieldLoading={isDeleteCustomFieldLoading}
          setIsDeleteCustomFieldLoading={setIsDeleteCustomFieldLoading}
          setPopupActive={setPopupDeleteActive}
          deleteCustomField={deleteCustomField}
        />
      )}
      {viewPopupEditActive !== null && (
        <EditView
          popupActive={viewPopupEditActive}
          customFields={customFields}
          setPopupActive={setViewPopupEditActive}
          getViews={getViews}
        />
      )}
      {viewPopupDeleteActive !== null && (
        <DeleteView
          popupActive={viewPopupDeleteActive}
          isDeleteViewLoading={isDeleteViewLoading}
          setIsDeleteViewLoading={setIsDeleteViewLoading}
          setPopupActive={setViewPopupDeleteActive}
          deleteView={deleteView}
        />
      )}
      {supplierOrderEmailTemplatePopupEditActive !== null && (
        <EditSupplierOrderEmailTemplate
          popupActive={supplierOrderEmailTemplatePopupEditActive}
          setPopupActive={setSupplierOrderEmailTemplatePopupEditActive}
          getSupplierOrderEmailTemplates={getSupplierOrderEmailTemplates}
        />
      )}
      {supplierOrderEmailTemplatePopupDeleteActive !== null && (
        <DeleteSupplierOrderEmailTemplate
          popupActive={supplierOrderEmailTemplatePopupDeleteActive}
          setPopupActive={setSupplierOrderEmailTemplatePopupDeleteActive}
          getSupplierOrderEmailTemplates={getSupplierOrderEmailTemplates}
        />
      )}
      {supplierOrderSmsTemplatePopupEditActive !== null && (
        <EditSupplierOrderSmsTemplate
          popupActive={supplierOrderSmsTemplatePopupEditActive}
          setPopupActive={setSupplierOrderSmsTemplatePopupEditActive}
          getSupplierOrderSmsTemplates={getSupplierOrderSmsTemplates}
        />
      )}
      {supplierOrderSmsTemplatePopupDeleteActive !== null && (
        <DeleteSupplierOrderSmsTemplate
          popupActive={supplierOrderSmsTemplatePopupDeleteActive}
          setPopupActive={setSupplierOrderSmsTemplatePopupDeleteActive}
          getSupplierOrderSmsTemplates={getSupplierOrderSmsTemplates}
        />
      )}
    </FlexContainer>
  );
};

export const CustomizeSupplierOrders = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_CustomizeSupplierOrders);

export default CustomizeSupplierOrders;
