import { Service } from "../../models/service";

export const GET_SERVICES = "GET_SERVICES" as const;
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS" as const;
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE" as const;

export const getServicesAction = (criteriaList?: string) => ({
  type: GET_SERVICES,
  criteriaList: criteriaList,
});
export type GetServicesAction = ReturnType<typeof getServicesAction>;

export const getServicesActionSuccess = (services: Service[]) => ({
  type: GET_SERVICES_SUCCESS,
  services: services,
});
export type GetServicesActionSuccess = ReturnType<
  typeof getServicesActionSuccess
>;

export const getServicesActionFailure = () => ({
  type: GET_SERVICES_FAILURE,
});
export type GetServicesActionFailure = ReturnType<
  typeof getServicesActionFailure
>;
