import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AppBar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import { Stomp } from "@stomp/stompjs";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CartItem } from "../../../models/cartItem";
import { Employee } from "../../../models/employee";
import { Notification } from "../../../models/notification";
import { AxiosHttpClient } from "../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../store";
import { getCartItemsAction } from "../../../store/Cart/actions";
import { logOutAction } from "../../../store/Signin/actions";
import { getCartItems, isUserSigninSucceed } from "../../../store/selectors";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";
import { ThemeCustom } from "../../../styles/Utils";
import { getItemObject, getRelativeTime, toFixed2 } from "../../Reusable/Utils";
import Cart from "./Cart";

export interface FeatureHeaderProps {
  sideNavShow: boolean;
  isUserSigninSucceed: boolean;
  cartItems: CartItem[];
  logOut: typeof logOutAction;
  getCartItemsAction: typeof getCartItemsAction;
  setSideNavShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const _FeatureHeader: React.FC<FeatureHeaderProps> = ({
  sideNavShow,
  isUserSigninSucceed,
  cartItems,
  logOut,
  getCartItemsAction,
  setSideNavShow,
}) => {
  const getNotificationsPage = (pageNumber?: number) => {
    AxiosHttpClient.get<any>("api/v1/weeventpro/notifications/page", {
      criteriaList: "",
      pageNumber: (pageNumber ? pageNumber : page) - 1,
      pageSize: 20,
      sort: "creationDateTime",
      order: "desc",
    })
      .then((res) => {
        setTotalNumberOfNotifications(Number(res.totalElements));
        (pageNumber ? pageNumber : page) === 1
          ? setNotifications([...res.content])
          : setNotifications([...notifications, ...res.content]);
      })
      .catch((err) => {});
  };

  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  const markAllAsRead = () => {
    AxiosHttpClient.put("api/v1/weeventpro/notifications/read").then((res) => {
      getNotificationsPage(1);
      setPage(1);
      setHasMore(true);
    });
  };

  let fetchMoreData: any = () => {
    if (page > totalNumberOfNotifications / 20) {
      setHasMore(false);
      return;
    }
    setPage(page + 1);
  };

  const [page, setPage] = useState(1);

  const [totalNumberOfNotifications, setTotalNumberOfNotifications] =
    useState(0);

  const [hasMore, setHasMore] = useState(true);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [notification, setNotification] = useState<Notification | null>(null);

  const [user, setUser] = useState<Employee | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState<null | HTMLElement>(null);

  const [open, setOpen] = useState(false);

  const [popupCartActive, setPopupCartActive] = useState<boolean>(false);

  const audioPlayer = useRef<any>(null);

  useEffect(() => {
    if (notification) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (page !== 1) getNotificationsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getNotificationsPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/signin" && !localStorage.getItem("jwtToken"))
      window.location.href = `${window.location.origin}/signin`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getUserInfos();
    getCartItemsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCartItemsAction]);

  useEffect(() => {
    let socket;

    const connect = () => {
      socket = Stomp.over(new WebSocket("ws://localhost:8080/ws"));
      socket.connect({}, () => {
        console.log("Try to connect...");
        socket.subscribe("/topic/" + user?.id + "/notifications", (message) => {
          const notificationReceived: Notification = JSON.parse(message.body);
          setNotification(notificationReceived);
          audioPlayer!.current!.play();
          getNotificationsPage(1);
        });
      });

      socket.onWebSocketClose = () => {
        console.log("Reconnecting...");
        setTimeout(connect, 5000);
      };
    };

    if (user) {
      connect();
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotificationIcon = (notification: Notification | null) => {
    if (notification) {
      switch (notification.actionType) {
        case "TASK_REMINDER":
          return <AssignmentTwoToneIcon color="primary" />;
        case "TASK_ASSIGNED":
          return <AssignmentTwoToneIcon color="primary" />;
        case "INTERACTION_REMINDER":
          return <QuestionAnswerTwoToneIcon color="primary" />;
        case "EMPLOYEE_DOCUMENT_REMINDER":
          return <ArticleIcon color="primary" />;
        case "VACATION_REQUEST":
          return <EventBusyIcon color="primary" />;
        case "VACATION_REQUEST_CC":
          return <EventBusyIcon color="primary" />;
        case "ACCEPT_VACATION_REQUEST":
          return <EventBusyIcon color="primary" />;
        case "REFUSE_VACATION_REQUEST":
          return <EventBusyIcon color="primary" />;

        default:
          return <AssignmentTwoToneIcon color="primary" />;
      }
    }
  };

  return (
    <>
      {!isUserSigninSucceed && <Navigate to="/signin" replace />}
      <AppBar
        sx={{
          backgroundColor: "#fafbfd",
          height: "65px",
          justifyContent: "center",
          width: {
            xs: "100vw",
            md: "calc(100vw - 258px)",
          },
          left: {
            xs: "0px",
            md: "258px",
          },
          paddingRight: "0 !important",
        }}
      >
        <Toolbar>
          <FlexContainer
            alignItems="stretch"
            height="9vh"
            flex="1"
            justifyContent={"space-between"}
          >
            <FlexContainer alignItems="center">
              <IconButton
                size="large"
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                }}
                onClick={() => setSideNavShow(!sideNavShow)}
              >
                <MenuIcon color="action" style={{ fontSize: 25 }} />
              </IconButton>
            </FlexContainer>
            <FlexContainer justifyContent="flex-end" alignItems="center">
              <FlexContainer justifyContent="center" alignItems="center">
                <IconButton
                  style={{ fontSize: 35 }}
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnchorElNotifications(e.currentTarget);
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={
                      notifications.filter((notification) => !notification.read)
                        .length
                    }
                  >
                    <NotificationsIcon
                      color="action"
                      style={{ fontSize: 25 }}
                    />
                  </Badge>
                </IconButton>
                <Menu
                  id="invoice-menu"
                  anchorEl={anchorElNotifications}
                  open={Boolean(anchorElNotifications)}
                  onClose={(e) => {
                    markAllAsRead();
                    setAnchorElNotifications(null);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    id: "notificationsPaperId",
                    elevation: 0,
                    sx: {
                      overflowY: "auto",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      marginX: "20px",
                      maxWidth: "95vw",
                      maxHeight: "600px",
                      width: "450px",
                      padding: "8px",

                      "&::-webkit-scrollbar": {
                        width: "8px",
                        height: "8px",
                      },

                      "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                        borderRadius: "10px",
                      },

                      "&::-webkit-scrollbar-thumb": {
                        background: ThemeCustom.colors.lightOrange,
                        borderRadius: "10px",
                      },

                      "&::-webkit-scrollbar-thumb:hover": {
                        background: ThemeCustom.colors.orange,
                        borderRadius: "10px",
                      },
                    },
                  }}
                >
                  <InfiniteScroll
                    dataLength={notifications.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      <FlexContainer justifyContent="center" margin="0 0 16px">
                        <Text14 fontWeight="600">Chargement...</Text14>
                      </FlexContainer>
                    }
                    scrollableTarget="notificationsPaperId"
                  >
                    <FlexContainer>
                      <Text16 fontWeight="600" margin="12px 16px">
                        Notifications
                      </Text16>
                    </FlexContainer>
                    {notifications.length === 0 && (
                      <FlexContainer
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        flex={"1"}
                        overflow={"hidden"}
                      >
                        <Text14
                          lineHeight="20px"
                          textWrap="wrap"
                          color="rgba(0,0,0,0.7)"
                          margin="0 0 16px 16px"
                        >
                          {"Vous n'avez aucune notification"}
                        </Text14>
                      </FlexContainer>
                    )}
                    {notifications.map((notification) => (
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setAnchorElNotifications(null);
                          navigate(notification!.url);
                        }}
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: notification.read
                            ? "white"
                            : CustomTheme.palette.background.default,
                        }}
                      >
                        <FlexContainer marginTop={"4px"}>
                          <FlexContainer
                            width={"40px"}
                            height={"40px"}
                            backgroundColor={
                              CustomTheme.palette.background.default
                            }
                            justifyContent={"center"}
                            alignItems={"center"}
                            margin={"4px 0 8px 0"}
                          >
                            {getNotificationIcon(notification)}
                          </FlexContainer>
                          <FlexContainer
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                            flex={"1"}
                            overflow={"hidden"}
                          >
                            <Text14
                              lineHeight="20px"
                              textWrap="wrap"
                              fontWeight={notification.read ? "400" : "500"}
                            >
                              {notification?.message}
                            </Text14>
                            <Text14
                              lineHeight="20px"
                              color={CustomTheme.palette.secondary.main}
                              fontWeight={notification.read ? "400" : "500"}
                            >
                              {getRelativeTime(notification?.creationDateTime)}
                            </Text14>
                          </FlexContainer>
                        </FlexContainer>
                      </MenuItem>
                    ))}
                  </InfiniteScroll>
                </Menu>
                <IconButton
                  style={{ fontSize: 35 }}
                  size="large"
                  onClick={() => setPopupCartActive(true)}
                >
                  <Badge
                    badgeContent={
                      cartItems.length > 0
                        ? toFixed2(
                            cartItems.reduce(
                              (totalTTC, item) =>
                                totalTTC +
                                item.quantity *
                                  getItemObject(item).priceHT *
                                  (1 + getItemObject(item).tva / 100),
                              0
                            )
                          ) + "€"
                        : undefined
                    }
                    color="secondary"
                  >
                    <ShoppingCartIcon color="action" style={{ fontSize: 25 }} />
                  </Badge>
                </IconButton>
              </FlexContainer>
              <ButtonMui
                aria-controls="simple-menu"
                aria-haspopup="true"
                height="50px"
                margin="0 0 0 16px"
                onClick={handleClick}
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="textPrimary">
                    {user == null ? "" : user.firstName + " " + user.lastName}
                  </Typography>
                  <AccountCircleIcon color="action" style={{ fontSize: 32 }} />
                </FlexContainer>
              </ButtonMui>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/feature/profile");
                  }}
                >
                  Profil
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/feature/parameters", {
                      state: "general,",
                    });
                  }}
                >
                  Paramètres
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.clear();
                    logOut();
                  }}
                >
                  Se deconnecter
                </MenuItem>
              </Menu>
            </FlexContainer>
          </FlexContainer>
        </Toolbar>
      </AppBar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
      >
        <FlexContainer
          id="client-snackbar"
          elevation={3}
          backgroundColor="white"
          borderRadius={"8px"}
          flexDirection={"column"}
          padding={"16px"}
          maxWidth={"450px"}
          $cursorHover="pointer"
          onClick={(e) => {
            navigate(notification!.url);
          }}
        >
          <FlexContainer justifyContent={"space-between"}>
            <Text14 lineHeight="20px" fontWeight="500">
              Nouvelle notification
            </Text14>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="small" color="secondary" />
            </IconButton>
          </FlexContainer>
          <FlexContainer marginTop={"4px"}>
            <FlexContainer
              width={"40px"}
              height={"40px"}
              backgroundColor={CustomTheme.palette.background.default}
              justifyContent={"center"}
              alignItems={"center"}
              margin={"4px 0 8px 0"}
            >
              {getNotificationIcon(notification)}
            </FlexContainer>
            <FlexContainer flexDirection={"column"} alignItems={"flex-start"}>
              <Text14 lineHeight="20px">{notification?.message}</Text14>
              <Text14
                lineHeight="20px"
                color={CustomTheme.palette.secondary.main}
              >
                {getRelativeTime(notification?.creationDateTime)}
              </Text14>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Snackbar>
      <audio
        ref={audioPlayer}
        src={
          "https://weevent.s3.eu-west-3.amazonaws.com/notification-sound.mp3"
        }
      />

      {popupCartActive && (
        <Cart
          popupActive={popupCartActive}
          setPopupActive={setPopupCartActive}
        />
      )}
    </>
  );
};

export const FeatureHeader = connect(
  (state: RootState) => ({
    isUserSigninSucceed: isUserSigninSucceed(state),
    cartItems: getCartItems(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        logOut: logOutAction,
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_FeatureHeader);

export default _FeatureHeader;
