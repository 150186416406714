import { Search } from "@mui/icons-material";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import { CustomField } from "../../../../../models/customField";
import { Route } from "../../../../../models/route";
import { UserParameter } from "../../../../../models/userParameters";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../styles/Text";
import {
  ROUTE_TABLE_ORDER,
  ROUTE_TABLE_ORDER_BY,
  ROUTE_TABLE_SEARCH_BY,
  ROUTE_TABLE_VIEW,
  getEmployeeDescription,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";
import CloneRoute from "../CloneRoute";
import EditRoute from "../EditRoute";
import ExportRoutes from "../ExportRoutes";
import ImportRoutes from "../ImportRoutes";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface RouteTableProps {}

const RouteTable: React.FC<RouteTableProps> = () => {
  const getRoutesPage = () => {
    setTableLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    AxiosHttpClient.get<any>(
      "api/v1/weeventpro/route/page",
      {
        criteriaList: inputField + "=" + inputSearch,
        pageNumber: page - 1,
        pageSize: limit,
        sort: fieldsCorrespondanceMapForOrders.get(orderBy),
        order: order,
      },
      signal
    )
      .then((res) => {
        setRoutes(res.content);
        setTableLoading(false);
        setTotalElements(res.totalElements);
      })
      .catch((err) => {});
  };

  const getRoute = (id: string) => {
    AxiosHttpClient.get<Route>("api/v1/weeventpro/route/" + id)
      .then((res) => {
        setPopupEditActive(res);
      })
      .catch((err) => {
        setPopupEditActive(null);
      });
  };

  const previousController = useRef<AbortController>(null!);

  const exportRoutes = () => {
    if (haveSameDateForIds(selected)) {
      AxiosHttpClient.postBlob("api/v1/weeventpro/route/export", selected).then(
        (response) => {
          const firstRoute = routes.find((route) => route.id === selected[0]);
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            dayjs(firstRoute?.date)
              .locale("fr")
              .format("dddd D MMMM YYYY")
              .toLocaleUpperCase("fr-FR") + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        }
      );
    } else setNotSameDateErrorOpen(true);
  };

  function haveSameDateForIds(routeIds: string[]): boolean {
    if (routeIds.length === 0) {
      return false; // Aucune route si la liste d'ids est vide
    }

    const firstRoute = routes.find((route) => route.id === routeIds[0]);

    if (!firstRoute) {
      return false; // Pas de correspondance pour le premier id
    }

    const firstDate = firstRoute.date;

    // Vérifiez si toutes les autres dates correspondent à la première date
    return routeIds.every((routeId) => {
      const route = routes.find((r) => r.id === routeId);
      return route && route.date === firstDate;
    });
  }

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/route")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/route"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        ROUTE_TABLE_ORDER +
        "," +
        ROUTE_TABLE_ORDER_BY +
        "," +
        ROUTE_TABLE_SEARCH_BY +
        "," +
        ROUTE_TABLE_VIEW,
    }).then((res) => {
      setOrder(
        getUserParameterValueByName(ROUTE_TABLE_ORDER, res) === "asc"
          ? "asc"
          : "desc"
      );
      setOrderBy(getUserParameterValueByName(ROUTE_TABLE_ORDER_BY, res));
      setInputField(getUserParameterValueByName(ROUTE_TABLE_SEARCH_BY, res));
      setInputView(getUserParameterValueByName(ROUTE_TABLE_VIEW, res));
    });
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [inputSearch, setInputSearch] = useState("");

  const [popupEditActive, setPopupEditActive] = useState<Route | null>(null);

  const [popupCloneActive, setPopupCloneActive] = useState<Route | null>(null);

  const [popupImportActive, setPopupImportActive] = useState(false);

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [inputView, setInputView] = useState("");

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  const [inputField, setInputField] = useState("");

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);

  const [totalElements, setTotalElements] = useState(0);

  const [routes, setRoutes] = useState<Route[]>([]);

  const [notSameDateErrorOpen, setNotSameDateErrorOpen] =
    useState<boolean>(false);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getRoutesPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, page, limit, orderBy, order]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, limit, orderBy, order]);

  useEffect(() => {
    if (popupEditActive !== null) getRoute(popupEditActive.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  const setOrderParameters = (orderValue: string, orderByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: ROUTE_TABLE_ORDER,
        parameterValue: orderValue,
      },
      {
        parameterName: ROUTE_TABLE_ORDER_BY,
        parameterValue: orderByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrderParameters(isAsc ? "desc" : "asc", cellId);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: ["Date", "Nom", "Description", "Livreur", "Véhicule"],
  };

  const getViewById = (id: string) => {
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : defaultView;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  let fieldsCorrespondanceMapForOrders: Map<string, string> = new Map([
    ["Date", "date"],
    ["Nom", "routeNameName"],
    ["Description", "description"],
    ["Livreur", "driverFullName"],
    ["Véhicule", "vehicle"],
  ]);

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["Date", true],
      ["Nom", true],
      ["Description", true],
      ["Livreur", true],
      ["Véhicule", true],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (route: Route, customFieldId: string) => {
    if (route.customFields !== null) {
      for (var customFieldValue of route.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, route: Route) => {
    let headValuesMap: Map<string, string> = new Map([
      ["Date", route.date ? dayjs(route.date).format("DD/MM/YYYY") : ""],
      ["Nom", route.routeName ? route.routeName.name : ""],
      ["Description", route.description],
      ["Livreur", getEmployeeDescription(route.driver)],
      ["Véhicule", route.vehicle],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(
        customField.name,
        getCustomFieldValues(route, customField.id)
      );
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, route: Route) => {
    let headValuesMap: Map<string, React.JSX.Element> = new Map([
      [
        "Actions",
        <FlexContainer justifyContent="center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupCloneActive(route);
            }}
            size="large"
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupEditActive(route);
            }}
            size="large"
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </FlexContainer>,
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getValueCells = (headValue: string, route: Route) => {
    let simpleValue = getSimpleCellValue(headValue, route);
    let complexValue = getComplexCellValue(headValue, route);
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  useEffect(() => {
    getCustomFields();
    getViews();
    getUserParameters();
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setPopupImportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "customization,routes",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                exportRoutes();
              }}
            >
              <FileUploadIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          inputField={inputField}
          setInputField={setInputField}
          setInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui sx={{ position: "relative" }}>
        {tableLoading && (
          <FlexContainer
            sx={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 57.297px)",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              top: "57.297px",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: "2",
              opacity: "0.7",
            }}
          >
            <CircularProgress size={80} />
          </FlexContainer>
        )}
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === routes.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = routes.map((route) => route.id);
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading && routes.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "150px", borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            )}
            {!tableLoading && routes.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "100px", borderBottom: "none" }}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune tournée retrouvée
                  </Text14>
                </TableCell>
              </TableRow>
            )}
            {routes.map((item, index) => (
              <TableRow
                key={index}
                onClick={(e) => {
                  //navigate(`/feature/routes/${item.id}`);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={selected.indexOf(item.id) > -1}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newArr = [...selected];
                      const index = selected.indexOf(item.id);
                      if (index > -1) {
                        newArr.splice(index, 1);
                        setSelected(newArr);
                      } else {
                        setSelected([...selected, item.id]);
                      }
                    }}
                  />
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell key={index}>
                    {getValueCells(headCell.label, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer sx={{ marginTop: "32px" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalElements}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={(dataKey) => {
            setPage(1);
            setLimit(dataKey);
          }}
        />
      </BlockContainer>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        open={notSameDateErrorOpen}
        onClose={(e) => setNotSameDateErrorOpen(false)}
      >
        <FlexContainer elevation={3}>
          <Alert
            onClose={(e) => setNotSameDateErrorOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Les tournées sélectionnées n'appartiennent pas au même jour
          </Alert>
        </FlexContainer>
      </Snackbar>
      {popupEditActive !== null && (
        <EditRoute
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getRoutesPage={getRoutesPage}
        />
      )}
      {popupCloneActive !== null && (
        <CloneRoute
          popupActive={popupCloneActive}
          setPopupActive={setPopupCloneActive}
          getRoutesPage={getRoutesPage}
        />
      )}
      {popupImportActive && (
        <ImportRoutes
          inputSearch={inputSearch}
          popupActive={popupImportActive}
          setPopupActive={setPopupImportActive}
        />
      )}
      {popupExportActive && (
        <ExportRoutes
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
    </Paper>
  );
};

export default RouteTable;
