import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Interaction } from "../../../../../models/interaction";
import EmailDetails from "./EmailDetails";
import OtherDetails from "./OtherDetails";
import PhoneCallDetails from "./PhoneCallDetails";
import PhysicalMeetingDetails from "./PhysicalMeetingDetails";
import SmsDetails from "./SmsDetails";
import VirtualMeetingDetails from "./VirtualMeetingDetails";

export interface InteractionDetailsProps {
  popupActive: Interaction;
  setPopupActive: React.Dispatch<React.SetStateAction<Interaction | null>>;
}

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "700px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const InteractionDetails: React.FC<InteractionDetailsProps> = ({
  popupActive,
  setPopupActive,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      {popupActive.type === "Appel téléphonique" && (
        <PhoneCallDetails popupActive={popupActive} />
      )}
      {popupActive.type === "E-mail" && (
        <EmailDetails popupActive={popupActive} />
      )}
      {popupActive.type === "SMS" && <SmsDetails popupActive={popupActive} />}
      {popupActive.type === "Réunion en personne" && (
        <PhysicalMeetingDetails popupActive={popupActive} />
      )}
      {popupActive.type === "Réunion virtuelle" && (
        <VirtualMeetingDetails popupActive={popupActive} />
      )}
      {popupActive.type === "Autre" && (
        <OtherDetails popupActive={popupActive} />
      )}
    </Dialog>
  );
};

export default InteractionDetails;
