import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CartItem } from "../../../../../models/cartItem";
import { Ingredient } from "../../../../../models/ingredients";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getCartItemsAction } from "../../../../../store/Cart/actions";
import { getCartItems } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import { toFixed2 } from "../../../../Reusable/Utils";

export interface AddToCartProps {
  popupActive: Ingredient | null;
  cartItems: CartItem[];
  setPopupActive: React.Dispatch<React.SetStateAction<Ingredient | null>>;
  getCartItemsAction: typeof getCartItemsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _AddToCart: React.FC<AddToCartProps> = ({
  popupActive,
  cartItems,
  setPopupActive,
  getCartItemsAction,
}) => {
  const addItemToCart = (cartItem: any) => {
    AxiosHttpClient.post("api/v1/weeventpro/cart", cartItem)
      .then(() => {
        getCartItemsAction();
      })
      .catch((err) => {});
  };

  const getPurchasableItemQuantity = (id: string) => {
    // Chercher dans la liste des cartItems la quantité du purchaseOption avec l'ID spécifié
    const cartItem = cartItems.find(
      (item) =>
        (item.purchaseOption?.id || item.materialPurchaseOption?.id) === id
    );
    return cartItem ? cartItem.quantity : 0; // Retourner la quantité ou 0 si non trouvée
  };

  const classes = useStyles();

  useEffect(() => {
    getCartItemsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCartItemsAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter au panier"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui
        id="addSaleDialogContentId"
        sx={{ borderBottom: "none", display: "flex" }}
        dividers
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {popupActive?.purchaseOptions.length === 0 && (
            <FlexContainer alignItems={"center"}>
              <Text14 color="rgba(0,0,0,0.7)">
                Vous n'avez aucune option d'achat pour cet ingrédient!
              </Text14>
            </FlexContainer>
          )}
          {popupActive?.purchaseOptions.map((purchaseOption, index) => (
            <FlexContainer width="100%" alignItems="center" padding={"8px"}>
              <FlexContainer flex={"1"}>
                <BlockContainer
                  width="80px"
                  height="80px"
                  border="rgba(0,0,0,0.1) solid 1px"
                  justifyContent="center"
                  position="relative"
                  textAlign="center"
                  backgroundColor="white"
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      margin: "auto",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                    }}
                    alt=""
                    src={purchaseOption.imageUrl}
                  />
                </BlockContainer>
                <FlexContainer flex={"1"}>
                  <Text14 textAlign="left">{purchaseOption.name}</Text14>
                </FlexContainer>
              </FlexContainer>
              {getPurchasableItemQuantity(purchaseOption.id) === 0 && (
                <FlexContainer marginRight={"30px"}>
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      addItemToCart({
                        purchaseOptionId: purchaseOption.id,
                        materialPurchaseOptionId: null,
                        quantity: 1,
                        ingredientMaterialId: purchaseOption.parentId,
                      });
                    }}
                    sx={{ minWidth: "unset" }}
                  >
                    <AddShoppingCartIcon fontSize="medium" />
                  </ButtonMui>
                </FlexContainer>
              )}
              {getPurchasableItemQuantity(purchaseOption.id) > 0 && (
                <FlexContainer flexDirection={"column"} alignItems={"center"}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    textDecorationLine="underline"
                    cursor="pointer"
                    margin="0"
                    onClick={() => {
                      addItemToCart({
                        purchaseOptionId: purchaseOption.id,
                        materialPurchaseOptionId: null,
                        quantity: 0,
                        ingredientMaterialId: purchaseOption.parentId,
                      });
                    }}
                  >
                    Supprimer
                  </Text14>
                  <FlexContainer alignItems="center">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        addItemToCart({
                          purchaseOptionId: purchaseOption.id,
                          materialPurchaseOptionId: null,
                          quantity:
                            getPurchasableItemQuantity(purchaseOption.id) - 1,
                          ingredientMaterialId: purchaseOption.parentId,
                        });
                      }}
                    >
                      <RemoveIcon fontSize="medium" />
                    </IconButton>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="quantity"
                      label=""
                      value={getPurchasableItemQuantity(purchaseOption.id)}
                      onChange={(e) => {
                        addItemToCart({
                          purchaseOptionId: purchaseOption.id,
                          materialPurchaseOptionId: null,
                          quantity: e.target.value,
                          ingredientMaterialId: purchaseOption.parentId,
                        });
                      }}
                      size="small"
                      sx={{
                        width: "40px",
                        margin: "0 4px",
                      }}
                      inputProps={{
                        sx: {
                          padding: "8.5px 2px",
                          textAlign: "center",
                        },
                      }}
                    />
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        addItemToCart({
                          purchaseOptionId: purchaseOption.id,
                          materialPurchaseOptionId: null,
                          quantity:
                            getPurchasableItemQuantity(purchaseOption.id) + 1,
                          ingredientMaterialId: purchaseOption.parentId,
                        });
                      }}
                    >
                      <AddIcon fontSize="medium" />
                    </IconButton>
                  </FlexContainer>
                  <Text16
                    color={CustomTheme.palette.primary.main}
                    margin="8px 0 0 0"
                    fontWeight="600"
                  >
                    {toFixed2(
                      purchaseOption.priceHT *
                        (1 + purchaseOption.tva * 0.01) *
                        getPurchasableItemQuantity(purchaseOption.id)
                    ) + " €"}
                  </Text16>
                </FlexContainer>
              )}
            </FlexContainer>
          ))}
        </Grid>
      </DialogContentMui>
    </Dialog>
  );
};

export const AddToCart = connect(
  (state: RootState) => ({
    cartItems: getCartItems(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_AddToCart);

export default AddToCart;
