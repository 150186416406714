import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React from "react";
import { Vacation } from "../../../../../models/vacation";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import AvatarWithInitials from "../../../../Reusable/AvatarWithInitials";
import {
  getEmployeeDescription,
  trimLongString,
} from "../../../../Reusable/Utils";

export interface VacationDetailsProps {
  popupActive: Vacation;
  setPopupActive: React.Dispatch<React.SetStateAction<Vacation | null>>;
}

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "700px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const VacationDetails: React.FC<VacationDetailsProps> = ({
  popupActive,
  setPopupActive,
}) => {
  const getVacationDistribution = (vacation: Vacation): string => {
    const distributionArray: string[] = [];
    if (vacation.paidLeave > 0)
      distributionArray.push(`${vacation.paidLeave} j congés payés`);
    if (vacation.rtt > 0) distributionArray.push(`${vacation.rtt} j RTT`);
    if (vacation.sickLeave > 0)
      distributionArray.push(`${vacation.sickLeave} j congé maladie`);
    if (vacation.maternityLeave > 0)
      distributionArray.push(`${vacation.maternityLeave} j congé maternité`);
    if (vacation.unpaidLeave > 0)
      distributionArray.push(`${vacation.unpaidLeave} j congé non payé`);
    if (vacation.publicHoliday > 0)
      distributionArray.push(`${vacation.publicHoliday} j jour férié`);
    if (vacation.recoveryLeave > 0)
      distributionArray.push(
        `${vacation.recoveryLeave} j congé de récupération`
      );

    return distributionArray.join(", ");
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "En attente") return CustomTheme.palette.primary.dark;
    if (status === "Acceptée") return CustomTheme.palette.success.light;
    if (status === "Refusée") return CustomTheme.palette.secondary.main;
  };

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        <FlexContainer textAlign="left" alignItems="center">
          <EventBusyIcon color="primary" sx={{ marginRight: "8px" }} />
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, lineHeight: "20px" }}
          >
            {"Demande de congé N° " + popupActive.vacationNumber}
          </Typography>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui>
        <FlexContainer>
          <Text14 color="rgb(107, 114, 128)" margin="4px 10px 0 10px">
            {"Du: " +
              dayjs(popupActive.startDate).format("DD/MM/YYYY") +
              " au " +
              dayjs(popupActive.endDate).format("DD/MM/YYYY")}
          </Text14>
        </FlexContainer>
        <FlexContainer>
          <Text14 color="rgb(107, 114, 128)" margin="4px 10px 0 10px">
            {"Durée: " +
              popupActive.vacationDuration +
              " j (" +
              getVacationDistribution(popupActive) +
              ")"}
          </Text14>
        </FlexContainer>
        <FlexContainer>
          <Text14 fontWeight="500" color="rgb(107, 114, 128)">
            Employé
          </Text14>
          <FlexContainer flexDirection={"column"} marginLeft={"22px"}>
            <FlexContainer alignItems={"center"} margin={"2px 8px"}>
              <AvatarWithInitials entity={popupActive.supervisor} />
              <FlexContainer flexDirection={"column"}>
                <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                  {getEmployeeDescription(popupActive.employee)}
                </Text16>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Text14 fontWeight="500" color="rgb(107, 114, 128)">
            Superviseur
          </Text14>
          <FlexContainer flexDirection={"column"}>
            <FlexContainer alignItems={"center"} margin={"2px 8px"}>
              <AvatarWithInitials entity={popupActive.supervisor} />
              <FlexContainer flexDirection={"column"}>
                <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                  {getEmployeeDescription(popupActive.supervisor)}
                </Text16>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Text14 fontWeight="500" color="rgb(107, 114, 128)">
            En copie
          </Text14>
          <FlexContainer flexDirection={"column"} marginLeft={"21px"}>
            {popupActive.copiesTo.map((copyTo) => (
              <FlexContainer alignItems={"center"} margin={"2px 8px"}>
                <AvatarWithInitials entity={copyTo} />
                <FlexContainer flexDirection={"column"}>
                  <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                    {getEmployeeDescription(copyTo)}
                  </Text16>
                </FlexContainer>
              </FlexContainer>
            ))}
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        <FlexContainer flexDirection={"column"}>
          <Text16 fontWeight="500">Liste des justificatifs</Text16>

          {popupActive.justificationFiles &&
          popupActive.justificationFiles.length !== 0 ? (
            popupActive.justificationFiles.map((file, index) => (
              <FlexContainer
                elevation={3}
                margin="8px"
                height="48px"
                justifyContent="space-between"
                alignItems="center"
                $borderRadius="16px"
              >
                <FlexContainer margin="0 0 0 8px" alignItems="center">
                  <ArticleIcon fontSize="large" color="primary" />
                  <Text14>{trimLongString(file.name)}</Text14>
                </FlexContainer>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    fetch(file.url)
                      .then((response) => response.blob())
                      .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", file.name);
                        document.body.appendChild(link);
                        link.click();
                      });
                  }}
                  size="medium"
                  style={{ marginRight: "8px", padding: "4px", zIndex: "1" }}
                >
                  <DownloadIcon fontSize="medium" />
                </IconButton>
              </FlexContainer>
            ))
          ) : (
            <Text14>Aucun fichier joint</Text14>
          )}
        </FlexContainer>
        <FlexContainer flexDirection={"column"} marginTop={"16px"}>
          <Text16 fontWeight="500">Commentaire</Text16>
          <Text14>
            {popupActive.comment && popupActive.comment !== ""
              ? popupActive.comment
              : "Pas de commentaire"}
          </Text14>
        </FlexContainer>
        <FlexContainer marginTop={"16px"}>
          <Text16 fontWeight="500">Statut</Text16>
          <FlexContainer justifyContent="center" marginLeft={"32px"}>
            <FlexContainer
              justifyContent="center"
              backgroundColor={getStatusBackgroundColor(popupActive.status)}
              color="white"
              padding="4px 8px"
              $borderRadius="50px"
            >
              {popupActive.status}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </DialogContentMui>
    </Dialog>
  );
};

export default VacationDetails;
