import { RootState } from "./types";

export const isUserSignupFailed = (state: RootState) =>
  state.signUp.signedUpFailure;

export const isUserSignupSucceed = (state: RootState) =>
  state.signUp.signedUpSuccess;

export const isUserSigninFailed = (state: RootState) =>
  state.signIn.signedInFailure;

export const isUserSigninSucceed = (state: RootState) =>
  state.signIn.signedInSuccess;

export const getIngredients = (state: RootState) => state.stock.ingredients;

export const getMaterials = (state: RootState) => state.stock.materials;

export const getStorageAreas = (state: RootState) => state.stock.storageAreas;

export const getSuppliers = (state: RootState) => state.stock.suppliers;

export const getDishes = (state: RootState) => state.dish.dishes;

export const getQuotes = (state: RootState) => state.quote.quotes;

export const getQuoteTemplates = (state: RootState) =>
  state.quoteTemplates.templates;

export const getUpfrontInvoices = (state: RootState) =>
  state.upfrontInvoice.upfrontInvoices;

export const getUpfrontInvoiceTemplates = (state: RootState) =>
  state.upfrontInvoiceTemplates.templates;

export const getInvoices = (state: RootState) => state.invoice.invoices;

export const getInvoiceTemplates = (state: RootState) =>
  state.invoiceTemplates.templates;

export const getCreditNoteTemplates = (state: RootState) =>
  state.creditNoteTemplates.templates;

export const getCustomerOrders = (state: RootState) =>
  state.customerOrder.customerOrders;

export const getCustomerOrdersArchive = (state: RootState) =>
  state.customerOrderArchive.customerOrdersArchive;

export const getSupplierOrders = (state: RootState) =>
  state.supplierOrder.supplierOrders;

export const getSupplierOrdersArchive = (state: RootState) =>
  state.supplierOrderArchive.supplierOrdersArchive;

export const getExpandedMenuItem = (state: RootState) =>
  state.expandedMenuItem.expanded;

export const getCartItems = (state: RootState) => state.cart.cartItems;

export const getBaseMetrics = (state: RootState) => state.metric.baseMetrics;

export const getCustomMetrics = (state: RootState) =>
  state.metric.customMetrics;

export const getClients = (state: RootState) => state.crm.clients;

export const getProspects = (state: RootState) => state.crm.prospects;

export const getContacts = (state: RootState) => state.crm.contacts;

export const getOpportunities = (state: RootState) => state.crm.opportunities;

export const getEmployees = (state: RootState) => state.rh.employees;

export const getVacations = (state: RootState) => state.rh.vacations;

export const getDocuments = (state: RootState) => state.rh.documents;

export const getSales = (state: RootState) => state.sale.sales;

export const getRoutes = (state: RootState) => state.route.routes;

export const getCommercialCycles = (state: RootState) =>
  state.crm.commercialCycles;

export const getComplaints = (state: RootState) => state.crm.complaints;

export const getTasks = (state: RootState) => state.crm.tasks;

export const getInteractions = (state: RootState) =>
  state.interaction.interactions;

export const getNotifications = (state: RootState) =>
  state.notification.notifications;

export const getServices = (state: RootState) => state.service.services;
