import { SignUpState } from "../Signup";
import { RootAction } from "../types";
import {
  REMOVE_SIGN_UP_FAILURE,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
} from "./actions";

const initialState: SignUpState = {
  signedUpSuccess: false,
  signedUpFailure: false,
};

export function signUpReducer(
  state: SignUpState = initialState,
  action: RootAction
): SignUpState {
  switch (action.type) {
    case SIGN_UP_SUCCESS:
      return {
        signedUpSuccess: true,
        signedUpFailure: false,
      };
    case SIGN_UP_FAILURE:
      return {
        signedUpSuccess: false,
        signedUpFailure: true,
      };
    case REMOVE_SIGN_UP_FAILURE:
      return {
        signedUpSuccess: false,
        signedUpFailure: false,
      };

    default:
      return state;
  }
}
