import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { RhService } from "../../services/RhService";
import { RootAction } from "../types";
import {
  GET_DOCUMENTS,
  GET_EMPLOYEES,
  GET_VACATIONS,
  GetDocumentsActionFailure,
  getDocumentsActionFailure,
  GetDocumentsActionSuccess,
  getDocumentsActionSuccess,
  GetEmployeesActionFailure,
  getEmployeesActionFailure,
  GetEmployeesActionSuccess,
  getEmployeesActionSuccess,
  GetVacationsActionFailure,
  getVacationsActionFailure,
  GetVacationsActionSuccess,
  getVacationsActionSuccess,
} from "./actions";

export const employeesListEpic: Epic<
  RootAction,
  GetEmployeesActionSuccess | GetEmployeesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_EMPLOYEES),
    switchMap((action) =>
      from(
        RhService.getEmployees({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getEmployeesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getEmployeesActionFailure()]))
        )
      )
    )
  );

export const vacationsListEpic: Epic<
  RootAction,
  GetVacationsActionSuccess | GetVacationsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_VACATIONS),
    switchMap((action) =>
      from(
        RhService.getVacations({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getVacationsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getVacationsActionFailure()]))
        )
      )
    )
  );

export const documentsEpic: Epic<
  RootAction,
  GetDocumentsActionSuccess | GetDocumentsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_DOCUMENTS),
    switchMap((action) =>
      from(RhService.getDocuments({ input: action.input })).pipe(
        mergeMap((res) => {
          return [getDocumentsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getDocumentsActionFailure()]))
        )
      )
    )
  );

export const RhEpic = combineEpics<any>(
  employeesListEpic,
  vacationsListEpic,
  documentsEpic
);
