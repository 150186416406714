import { Interaction } from "../../models/interaction";

export const GET_INTERACTIONS = "GET_INTERACTIONS" as const;
export const GET_INTERACTIONS_SUCCESS = "GET_INTERACTIONS_SUCCESS" as const;
export const GET_INTERACTIONS_FAILURE = "GET_INTERACTIONS_FAILURE" as const;

export const getInteractionsAction = (criteriaList?: string) => ({
  type: GET_INTERACTIONS,
  criteriaList: criteriaList,
});
export type GetInteractionsAction = ReturnType<typeof getInteractionsAction>;

export const getInteractionsActionSuccess = (interactions: Interaction[]) => ({
  type: GET_INTERACTIONS_SUCCESS,
  interactions: interactions,
});
export type GetInteractionsActionSuccess = ReturnType<
  typeof getInteractionsActionSuccess
>;

export const getInteractionsActionFailure = () => ({
  type: GET_INTERACTIONS_FAILURE,
});
export type GetInteractionsActionFailure = ReturnType<
  typeof getInteractionsActionFailure
>;
