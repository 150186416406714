import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { AppName } from "../../../../../styles/AppName";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { TextFieldMui } from "../../../../../styles/TextFieldCustom";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface ActivateEmployeeProps {}

const ActivateEmployee: React.FC<ActivateEmployeeProps> = (props) => {
  let navigate = useNavigate();
  const [activateFailed, setActivateFailed] = useState(false);

  const [employeeId, setEmployeeId] = useState("");

  const [email, setEmail] = useState("");

  const [inputPassword, SetInputPassword] = useState("");

  const [inputConfirmPassword, SetInputConfirmPassword] = useState("");

  const getPasswordLinkUuidEmployee = () => {
    AxiosHttpClient.getForNonAuth<Employee>(
      "api/v1/weeventpro/employees/passwordLinkEmployee/" + params.uuid
    )
      .then((response) => {
        setEmployeeId(response ? response.id : "NOT_FOUND");
        setEmail(response ? response.email : "");
      })
      .catch(() => {
        setEmployeeId("NOT_FOUND");
      });
  };

  const setEmployeePassword = () => {
    AxiosHttpClient.putForNonAuth(
      "api/v1/weeventpro/employees/setPassword/" + employeeId,
      { password: inputPassword, uuid: params.uuid }
    )
      .then(() => {
        navigate("/activate/success", {
          state: {
            employeeId: employeeId,
            email: email,
            password: inputPassword,
          },
        });
      })
      .catch((err) => {
        setActivateFailed(true);
      });
  };

  useEffect(() => {
    getPasswordLinkUuidEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = useParams();

  return (
    <FlexContainer justifyContent="center" alignItems="center" height="100vh">
      <FlexContainer
        elevation={3}
        padding="40px"
        alignItems="center"
        flexDirection="column"
        backgroundColor="white"
        width="500px"
      >
        <FlexContainer margin="20px" alignSelf="center">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>
        {activateFailed && (
          <Alert severity="error">
            Une erreur est survenue! Veuillez contacter le support
          </Alert>
        )}
        {employeeId === "NOT_FOUND" && (
          <Text16>Le lien n'est plus valide</Text16>
        )}
        {employeeId !== "NOT_FOUND" && (
          <>
            <FlexContainer>
              <Text16 fontWeight="600">
                Votre compte a été activé avec succès!
              </Text16>
            </FlexContainer>
            <FlexContainer>
              <Text14>Veuillez choisir un mot de passe!</Text14>
            </FlexContainer>
            <TextFieldMui
              autoComplete="new-password"
              type="password"
              value={inputPassword}
              onChange={(e) => SetInputPassword(e.target.value)}
              label="Mot de passe"
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
            <TextFieldMui
              error={
                inputConfirmPassword !== "" &&
                inputPassword !== inputConfirmPassword
              }
              autoComplete="new-password"
              type="password"
              value={inputConfirmPassword}
              onChange={(e) => SetInputConfirmPassword(e.target.value)}
              label="Confirmer le mot de passe"
              variant="outlined"
              margin="normal"
              fullWidth
              helperText={
                inputConfirmPassword !== "" &&
                inputPassword !== inputConfirmPassword
                  ? "Les mots de passe sont différents!"
                  : ""
              }
              required
            />
            <FlexContainer margin="24px 0 16px" width="100%">
              <ButtonMui
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setEmployeePassword();
                }}
              >
                CONFIRMER
              </ButtonMui>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ActivateEmployee;
