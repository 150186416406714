import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface AcceptVacationRequestConfirmationProps {
  popupActive: string;
  setPopupActive: React.Dispatch<React.SetStateAction<string>>;
  actionsOnSubmit?: (() => void)[];
  redirect?: boolean;
}

const AcceptVacationRequestConfirmation: React.FC<
  AcceptVacationRequestConfirmationProps
> = (props) => {
  const acceptVacationRequest = () => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/vacations/accept/" + props.popupActive
    )
      .then((response) => {
        props.setPopupActive("");
        props.actionsOnSubmit?.forEach((action) => action());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Dialog
      open={props.popupActive !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive("");
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Accepter la demande de congé?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir accepter cette demande de congé?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            if (props.redirect) navigate("/feature/employees");
            setIsLoading(true);
            acceptVacationRequest();
          }}
          color="primary"
        >
          Confirmer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive("")}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptVacationRequestConfirmation;
