import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import { SaleOption } from "../../../../../../models/dish";
import { Unit } from "../../../../../../models/ingredients";
import { UserParameter } from "../../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  GLOBAL_TVA,
  getHTPriceFromTTC,
  getTTCPriceFromHT,
  getUnitDescription,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface SaleOptionsProps {
  inputSaleOptions: SaleOption[];
  inputSaleUnits: Unit[];
  inputImageUrl: any;
  saleUnitInputRef: React.RefObject<HTMLInputElement | null>;
  setInputSaleOptions: React.Dispatch<React.SetStateAction<SaleOption[]>>;
  setSaleUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSaleUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaleOptions: React.FC<SaleOptionsProps> = (props) => {
  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames: GLOBAL_TVA,
    }).then((res) => {
      setInputGlobalTVA(getUserParameterValueByName(GLOBAL_TVA, res));
    });
  };

  const [saleOptionAddActive, setSaleOptionAddActive] = useState(false);

  const [saleOptionEditActive, setSaleOptionEditActive] = useState("");

  const [inputImageUrl, setInputImageUrl] = useState<any>(props.inputImageUrl);

  const [inputSaleOptionName, setInputSaleOptionName] = useState("");

  const [inputSaleOptionNameError, setInputSaleOptionNameError] =
    useState(false);

  const [inputSaleUnit, setInputSaleUnit] = useState<Unit | null>(null);

  const [inputPriceHT, setInputPriceHT] = useState("");

  const [inputPriceTTC, setInputPriceTTC] = useState("");

  const [inputTVA, setInputTVA] = useState("");

  const [inputGlobalTVA, setInputGlobalTVA] = useState("");

  const [inputSaleUnitError, setInputSaleUnitError] = useState(false);

  const [inputPriceHTError, setInputPriceHTError] = useState(false);

  const [inputPriceTTCError, setInputPriceTTCError] = useState(false);

  const [inputTVAError, setInputTVAError] = useState(false);

  const [productImageHovered, setProductImageHovered] = useState(false);

  const [selectSaleUnitOpen, setSelectSaleUnitOpen] = useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const [addFailed, setAddFailed] = useState("");

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputSaleOptionNameError(false);
    setInputSaleUnitError(false);
    setInputPriceHTError(false);
    setInputPriceTTCError(false);
    setInputTVAError(false);
  };

  const validateForm = () => {
    if (
      props.inputSaleOptions.some(
        (saleOption) => saleOption.name === inputSaleOptionName
      )
    ) {
      setAddFailed(
        "L'option de vente \"" + inputSaleOptionName + '" existe déjà!'
      );
      return false;
    }
    setInputSaleOptionNameError(false);
    setInputSaleUnitError(false);
    setInputPriceHTError(false);
    setInputPriceTTCError(false);
    setInputTVAError(false);
    let result = true;
    if (inputSaleOptionName === "") {
      setInputSaleOptionNameError(true);
      result = false;
    }
    if (inputSaleUnit === null) {
      setInputSaleUnitError(true);
      result = false;
    }
    if (inputPriceHT === "") {
      setInputPriceHTError(true);
      result = false;
    }
    if (inputPriceTTC === "") {
      setInputPriceTTCError(true);
      result = false;
    }
    if (inputTVA === "") {
      setInputTVAError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (saleOption: SaleOption) => {
    if (saleOption.name !== inputSaleOptionName) {
      if (
        props.inputSaleOptions.some(
          (saleOption) => saleOption.name === inputSaleOptionName
        )
      ) {
        setAddFailed(
          "L'option de vente \"" + inputSaleOptionName + '" existe déjà!'
        );
        return false;
      }
    }
    setInputSaleOptionNameError(false);
    setInputSaleUnitError(false);
    setInputPriceHTError(false);
    setInputPriceTTCError(false);
    setInputTVAError(false);
    let result = true;
    if (inputSaleOptionName === "") {
      setInputSaleOptionNameError(true);
      result = false;
    }
    if (inputSaleUnit === null) {
      setInputSaleUnitError(true);
      result = false;
    }
    if (inputPriceHT === "") {
      setInputPriceHTError(true);
      result = false;
    }
    if (inputPriceTTC === "") {
      setInputPriceTTCError(true);
      result = false;
    }
    if (inputTVA === "") {
      setInputTVAError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const getSaleUnitById = (id: string) => {
    for (var saleUnit of props.inputSaleUnits) {
      if (saleUnit.id === id) return saleUnit;
    }
    return null;
  };

  useEffect(() => {
    if (document.getElementById("saleOptionUnit") !== null)
      setSelectWidth(document.getElementById("saleOptionUnit")!.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("saleOptionUnit")]);

  useEffect(() => {
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setSaleOptionAddActive(true);
            setSaleOptionEditActive("");
            setInputSaleOptionName("");
            setInputSaleUnit(null);
            setInputPriceHT("");
            setInputPriceTTC("");
            setInputTVA(inputGlobalTVA);
            setInputImageUrl(props.inputImageUrl);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une option de vente
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={saleOptionAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setProductImageHovered(true)}
                onMouseLeave={() => setProductImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {productImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="productame"
                label="Intitulé"
                error={inputSaleOptionNameError}
                value={inputSaleOptionName}
                onChange={(e) => setInputSaleOptionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="saleUnit">Unité de vente</InputLabel>
                <Select
                  labelId="saleUnit"
                  id="saleOptionUnit"
                  required
                  open={selectSaleUnitOpen}
                  onClose={() => setSelectSaleUnitOpen(false)}
                  onOpen={() => setSelectSaleUnitOpen(true)}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        width: selectWidth,
                      },
                      "& .MuiButtonBase-root": {
                        whiteSpace: "normal",
                      },
                    },
                  }}
                  error={inputSaleUnitError}
                  value={(inputSaleUnit || "") as any}
                  label="Unité de vente"
                  onChange={(e) => setInputSaleUnit(e.target.value)}
                >
                  {props.inputSaleUnits.length === 0 && (
                    <Link
                      to="saleUnitsTopId"
                      spy={true}
                      smooth={true}
                      duration={500}
                      containerId={"addDishDialogContentId"}
                    >
                      {
                        <MenuItem
                          sx={{ color: "red" }}
                          onClick={() => {
                            props.setSaleUnitAddActive(true);
                            setTimeout(function () {
                              setSelectSaleUnitOpen(false);
                              props.saleUnitInputRef.current?.focus({
                                preventScroll: true,
                              });
                            }, 100);
                            setTimeout(function () {
                              props.setSaleUnitOpen(true);
                            }, 500);
                          }}
                        >
                          Aucune unité de vente créée. Cliquez ici pour en créer
                          une!
                        </MenuItem>
                      }
                    </Link>
                  )}
                  {props.inputSaleUnits.map((saleUnit, index) => (
                    <MenuItem value={saleUnit as any} key={index}>
                      {saleUnit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="tva"
                label="TVA"
                error={inputTVAError}
                value={inputTVA}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      !isNaN(Number(e.target.value)) &&
                      !isNaN(Number(inputPriceHT))
                    ) {
                      setInputPriceTTC(
                        toFixed2(
                          getTTCPriceFromHT(
                            Number(inputPriceHT),
                            Number(e.target.value)
                          )
                        )
                      );
                    }
                    setInputTVA(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="priceHT"
                label="Prix HT"
                error={inputPriceHTError}
                value={inputPriceHT}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      !isNaN(Number(e.target.value)) &&
                      !isNaN(Number(inputTVA))
                    ) {
                      setInputPriceTTC(
                        toFixed2(
                          getTTCPriceFromHT(
                            Number(e.target.value),
                            Number(inputTVA)
                          )
                        )
                      );
                    }
                    setInputPriceHT(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="priceTTC"
                label="Prix TTC"
                error={inputPriceTTCError}
                value={inputPriceTTC}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      !isNaN(Number(e.target.value)) &&
                      !isNaN(Number(inputTVA))
                    ) {
                      setInputPriceHT(
                        toFixed2(
                          getHTPriceFromTTC(
                            Number(e.target.value),
                            Number(inputTVA)
                          )
                        )
                      );
                    }
                    setInputPriceTTC(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputSaleOptions([
                  ...props.inputSaleOptions,
                  {
                    id: uuid(),
                    imageUrl: inputImageUrl,
                    name: inputSaleOptionName,
                    saleUnit: inputSaleUnit!,
                    priceHT: Number(inputPriceHT),
                    priceTTC: Number(inputPriceTTC),
                    tva: Number(inputTVA),
                  },
                ]);
                setSaleOptionAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setSaleOptionAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputSaleOptions.map((saleOption, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer>
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={saleOption.imageUrl}
                />
              </BlockContainer>
              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600">{saleOption.name}</Text16>
                <Text14 margin="0px 10px 4px 10px">
                  {"Prix de vente: " +
                    saleOption.priceHT +
                    " €/" +
                    getUnitDescription(saleOption.saleUnit)}
                </Text14>
                <Text14 margin="4px 10px 0 10px">
                  {"TVA: " + saleOption.tva + "%"}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setSaleOptionAddActive(false);
                  setSaleOptionEditActive(saleOption.name);
                  setInputImageUrl(saleOption.imageUrl);
                  setInputSaleOptionName(saleOption.name);
                  setInputSaleUnit(getSaleUnitById(saleOption.saleUnit.id));
                  setInputPriceHT(toFixed2(saleOption.priceHT));
                  setInputPriceTTC(toFixed2(saleOption.priceTTC));
                  setInputTVA(toFixed2(saleOption.tva));
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputSaleOptions];
                  newArr.splice(index, 1);
                  props.setInputSaleOptions(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={saleOptionEditActive === saleOption.name}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  display="flex"
                  justifyContent="center"
                >
                  <BlockContainer
                    width="150px"
                    height="150px"
                    border="rgba(0,0,0,0.1) solid 1px"
                    justifyContent="center"
                    position="relative"
                    textAlign="center"
                    onMouseEnter={() => setProductImageHovered(true)}
                    onMouseLeave={() => setProductImageHovered(false)}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "auto",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                      }}
                      alt=""
                      src={inputImageUrl}
                    />
                    {productImageHovered && (
                      <FlexContainer
                        position="absolute"
                        top="0"
                        left="0"
                        width="150px"
                        height="150px"
                        backgroundColor="rgba(0,0,0,0.5)"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              imagePreviewHandler(e.target.files![0]);
                            }}
                          />
                          <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => {}}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <AddAPhotoIcon
                              sx={{ color: "white", fontSize: "32px" }}
                            />
                          </IconButton>
                        </label>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setInputImageUrl(
                              "https://" +
                                process.env.REACT_APP_BUCKET_NAME! +
                                ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                            )
                          }
                          size="large"
                          style={{ padding: "4px", zIndex: "1" }}
                        >
                          <DeleteIcon
                            sx={{ color: "white", fontSize: "32px" }}
                          />
                        </IconButton>
                      </FlexContainer>
                    )}
                  </BlockContainer>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="productame"
                    label="Intitulé"
                    error={inputSaleOptionNameError}
                    value={inputSaleOptionName}
                    onChange={(e) => setInputSaleOptionName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="saleUnit">Unité de vente</InputLabel>
                    <Select
                      labelId="saleUnit"
                      id="saleUnit"
                      required
                      error={inputSaleUnitError}
                      value={(inputSaleUnit || "") as any}
                      label="Unité d'achat"
                      onChange={(e) => setInputSaleUnit(e.target.value)}
                    >
                      {props.inputSaleUnits.map((saleUnit, index) => (
                        <MenuItem value={saleUnit as any} key={index}>
                          {saleUnit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="tva"
                    label="TVA"
                    error={inputTVAError}
                    value={inputTVA}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          !isNaN(Number(e.target.value)) &&
                          !isNaN(Number(inputPriceHT))
                        ) {
                          setInputPriceTTC(
                            toFixed2(
                              getTTCPriceFromHT(
                                Number(inputPriceHT),
                                Number(e.target.value)
                              )
                            )
                          );
                        }
                        setInputTVA(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="priceHT"
                    label="Prix HT"
                    error={inputPriceHTError}
                    value={inputPriceHT}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          !isNaN(Number(e.target.value)) &&
                          !isNaN(Number(inputTVA))
                        ) {
                          setInputPriceTTC(
                            toFixed2(
                              getTTCPriceFromHT(
                                Number(e.target.value),
                                Number(inputTVA)
                              )
                            )
                          );
                        }
                        setInputPriceHT(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="priceTTC"
                    label="Prix TTC"
                    error={inputPriceTTCError}
                    value={inputPriceTTC}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          !isNaN(Number(e.target.value)) &&
                          !isNaN(Number(inputTVA))
                        ) {
                          setInputPriceHT(
                            toFixed2(
                              getHTPriceFromTTC(
                                Number(e.target.value),
                                Number(inputTVA)
                              )
                            )
                          );
                        }
                        setInputPriceTTC(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setSaleOptionAddActive(false);
                  resetValidation();
                  if (validateEditForm(saleOption)) {
                    let tmpArray = [...props.inputSaleOptions];
                    tmpArray[index] = {
                      id: saleOption.id,
                      imageUrl: inputImageUrl,
                      name: inputSaleOptionName,
                      saleUnit: inputSaleUnit!,
                      priceHT: Number(inputPriceHT),
                      priceTTC: Number(inputPriceTTC),
                      tva: Number(inputTVA),
                    };

                    props.setInputSaleOptions(tmpArray);
                    setSaleOptionEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setSaleOptionEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default SaleOptions;
