import { RootAction } from "../types";
import {
  GET_INGREDIENTS_SUCCESS,
  GET_MATERIALS_SUCCESS,
  GET_STORAGE_AREAS_SUCCESS,
  GET_SUPPLIERS_SUCCESS,
} from "./actions";
import { StockState } from "./types";

const initialState: StockState = {
  ingredients: [],
  materials: [],
  storageAreas: [],
  suppliers: [],
};

export function stockReducer(
  state: StockState = initialState,
  action: RootAction
): StockState {
  switch (action.type) {
    case GET_INGREDIENTS_SUCCESS:
      return {
        ingredients: action.ingredients,
        materials: state.materials,
        storageAreas: state.storageAreas,
        suppliers: state.suppliers,
      };

    case GET_MATERIALS_SUCCESS:
      return {
        ingredients: state.ingredients,
        materials: action.materials,
        storageAreas: state.storageAreas,
        suppliers: state.suppliers,
      };

    case GET_STORAGE_AREAS_SUCCESS:
      return {
        ingredients: state.ingredients,
        materials: state.materials,
        storageAreas: action.storageAreas,
        suppliers: state.suppliers,
      };

    case GET_SUPPLIERS_SUCCESS:
      return {
        ingredients: state.ingredients,
        materials: state.materials,
        storageAreas: state.storageAreas,
        suppliers: action.suppliers,
      };

    default:
      return state;
  }
}
