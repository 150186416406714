import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApexOptions } from "apexcharts";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { CustomMetric } from "../../../../../../models/metric";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16, Text24 } from "../../../../../../styles/Text";
import { commify } from "../../../../../Reusable/Utils";
import "./index.css";

interface MotionDivProps {
  children: ReactNode;
  className: string;
  layoutId: string;
  onClick?: () => void;
}

const MotionDiv: React.FC<MotionDivProps> = ({ children, ...props }) => {
  return <motion.div {...props}>{children}</motion.div>;
};

// Parent Card

export interface CustomMetricItemProps {
  metric: CustomMetric;
  startDate: string;
  endDate: string;
  setPopupEditActive: React.Dispatch<React.SetStateAction<CustomMetric>>;
  setPopupDeleteActive: React.Dispatch<React.SetStateAction<string>>;
}

const CustomMetricItem: React.FC<CustomMetricItemProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AnimatePresence mode="wait">
      {expanded ? (
        <ExpandedCard
          metric={props.metric}
          startDate={props.startDate}
          endDate={props.endDate}
          setExpanded={() => setExpanded(false)}
        />
      ) : (
        <CompactCard
          metric={props.metric}
          setExpanded={() => setExpanded(true)}
          setPopupEditActive={props.setPopupEditActive}
          setPopupDeleteActive={props.setPopupDeleteActive}
        />
      )}
    </AnimatePresence>
  );
};

export default CustomMetricItem;

// Compact Card

export interface CompactCardProps {
  metric: CustomMetric;
  setExpanded: () => void;
  setPopupEditActive: React.Dispatch<React.SetStateAction<CustomMetric>>;
  setPopupDeleteActive: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    background: "white",
    border: "1px solid rgba(0,0,0,0.5)",
  },
}));

const CompactCard: React.FC<CompactCardProps> = (props) => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      placement="top"
      TransitionComponent={Zoom}
      enterDelay={500}
      classes={{ tooltip: classes.tooltip }}
      title={
        <FlexContainer>
          <IconButton
            color="primary"
            size="large"
            onClick={() => props.setPopupEditActive(props.metric)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            size="large"
            onClick={() => props.setPopupDeleteActive(props.metric.name)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>
      }
    >
      <MotionDiv
        className="CompactCard"
        layoutId="expandableCard"
        onClick={props.setExpanded}
      >
        <FlexContainer>
          <Text16 fontWeight="bold">{props.metric.name}</Text16>
          <Text24>
            {commify(props.metric.value)} {props.metric.unit}
          </Text24>
        </FlexContainer>
      </MotionDiv>
    </Tooltip>
  );
};

// Expanded Card

export interface ExpandedCardProps {
  metric: CustomMetric;
  startDate: string;
  endDate: string;
  setExpanded: () => void;
}

const ExpandedCard: React.FC<ExpandedCardProps> = (props) => {
  const [seriesReturned, setSeriesReturned] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    AxiosHttpClient.get<number[]>(
      "api/v1/weeventpro/statistics/metrics/getDetails",
      {
        metricName: props.metric.name,
        startDate: props.startDate,
        endDate: props.endDate,
      }
    )
      .then((response) => setSeriesReturned(response))
      .catch(() => {});

    const categoriesTmp = [] as string[];
    for (
      let d = new Date(props.startDate);
      d <= new Date(props.endDate);
      d.setDate(d.getDate() + 1)
    ) {
      categoriesTmp.push(d.toISOString());
    }
    setCategories(categoriesTmp);
  }, [props.startDate, props.endDate, props.metric.name]);

  const data: ApexOptions = {
    chart: {
      type: "area",
      height: "auto",
    },
    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    stroke: {
      curve: "smooth",
      colors: ["white"],
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
  };

  const series = [
    {
      name: "Sales",
      data: seriesReturned,
    },
  ];

  return (
    <MotionDiv className="ExpandedCard" layoutId="expandableCard">
      <ButtonMui onClick={props.setExpanded}>
        <CloseIcon />
      </ButtonMui>
      <Chart options={data} series={series} type="area" />
    </MotionDiv>
  );
};
