import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React from "react";
import { Employee } from "../../../../../../models/employee";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getEmployeeDescription } from "../../../../../Reusable/Utils";

export interface EmployeeDetailsProps {
  employee: Employee;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    "& thead th": {
      textAlign: "left",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "left",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({ employee }) => {
  const classes = useStyles();

  return (
    <BlockContainer width="100%" padding="16px">
      <Text16 fontWeight="500">Informations générales</Text16>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Identifiant employé</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.employeeNumber && employee.employeeNumber !== ""
              ? employee.employeeNumber
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Nom complet</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>{getEmployeeDescription(employee)}</Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Date de naissance</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.dateOfBirth
              ? dayjs(employee.dateOfBirth).format("DD/MM/YYYY")
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Numéro de téléphone</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.phoneNumber && employee.phoneNumber !== ""
              ? employee.phoneNumber
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Email</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.email && employee.email !== "" ? employee.email : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Langues parlées</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.languages && employee.languages.length !== 0
              ? employee.languages.join(", ")
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Adresse</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.address && employee.address !== ""
              ? employee.address
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <Divider sx={{ margin: "8px" }} />
      <Text16 fontWeight="500">Informations professionnelles</Text16>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Poste</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.position && employee.position !== ""
              ? employee.position
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Superviseur</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {getEmployeeDescription(employee.supervisor) !== ""
              ? getEmployeeDescription(employee.supervisor)
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Date d'embauche</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.hireDate
              ? dayjs(employee.hireDate).format("DD/MM/YYYY")
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Type de contrat</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.contractType && employee.contractType !== ""
              ? employee.contractType
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Date de fin de contrat</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.contratEndDate
              ? dayjs(employee.contratEndDate).format("DD/MM/YYYY")
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Statut</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.workStatus && employee.workStatus !== ""
              ? employee.workStatus
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Pourcentage</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.percentage ? employee.percentage + "%" : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">
            Nombre d'heures hebdomadaires
          </Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.weeklyHours ? employee.weeklyHours + " heures" : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Nombre d'heures effectif</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.effectiveHours
              ? employee.effectiveHours + " heures"
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Salaire de base</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.baseSalary
              ? employee.baseSalary +
                " €" +
                (employee.workSchedule && employee.workSchedule !== ""
                  ? "/" + employee.workSchedule
                  : "-")
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Numéro de sécurité sociale</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.socialSecurityNumber &&
            employee.socialSecurityNumber !== ""
              ? employee.socialSecurityNumber
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">
            Numéro d'identification fiscale
          </Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.taxIdNumber && employee.taxIdNumber !== ""
              ? employee.taxIdNumber
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <Divider sx={{ margin: "8px" }} />
      <Text16 fontWeight="500">Congés</Text16>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Nombre annuel de CP annuel</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.cpAnnualNumber ? employee.cpAnnualNumber + " jours" : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Solde CP</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.cpBalance ? employee.cpBalance + " jours" : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Nombre de RTT annuel</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.rttAnnualNumber
              ? employee.rttAnnualNumber + " jours"
              : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer>
        <FlexContainer flex="1">
          <Text14 color="rgb(107, 114, 128)">Solde RTT</Text14>
        </FlexContainer>
        <FlexContainer flex="2">
          <Text14>
            {employee.rttBalance ? employee.rttBalance + " jours" : "-"}
          </Text14>
        </FlexContainer>
      </FlexContainer>

      {/* Documents de l'employé */}
      <Divider sx={{ margin: "8px" }} />
      <Text16 fontWeight="500">Documents de l'employé</Text16>

      <FlexContainer marginTop={"32px"} maxWidth={"1200px"}>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="documentName">Nom du document</TableCell>
                    <TableCell key="expirationDate">
                      Date d'expiration
                    </TableCell>
                    <TableCell key="files">Fichiers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee.documents.map((employeeDocument, index) => (
                    <TableRow key={index}>
                      <TableCell>{employeeDocument.name}</TableCell>
                      <TableCell>
                        {employeeDocument.expirationDate
                          ? dayjs(employeeDocument.expirationDate).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {employeeDocument.files.map((file, index) => (
                          <FlexContainer alignItems={"center"} margin="0 -4px">
                            <AttachFileRoundedIcon
                              fontSize="small"
                              color="primary"
                            />
                            <Text14
                              cursor="pointer"
                              margin="0"
                              color={CustomTheme.palette.secondary.main}
                              textDecorationLine="underline"
                              onClick={(e) => {
                                fetch(file.url)
                                  .then((response) => response.blob())
                                  .then((blob) => {
                                    const url =
                                      window.URL.createObjectURL(blob);
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.setAttribute("download", file.name);
                                    document.body.appendChild(link);
                                    link.click();
                                  });
                              }}
                            >
                              {file.name}
                            </Text14>
                          </FlexContainer>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FlexContainer>
    </BlockContainer>
  );
};

export default EmployeeDetails;
