import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { EmailTemplate } from "../../../../../../../models/emailTemplate";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteSupplierOrderEmailTemplateProps {
  popupActive: EmailTemplate | null;
  setPopupActive: React.Dispatch<React.SetStateAction<EmailTemplate | null>>;
  getSupplierOrderEmailTemplates: () => void;
}

const DeleteSupplierOrderEmailTemplate: React.FC<
  DeleteSupplierOrderEmailTemplateProps
> = (props) => {
  const deleteSupplierOrderEmailTemplate = (
    supplierOrderEmailTemplateId: string
  ) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/template/supplierOrder/email/" +
        supplierOrderEmailTemplateId
    )
      .then(() => {
        props.getSupplierOrderEmailTemplates();
        props.setPopupActive(null);
        setIsDeleteSupplierOrderEmailTemplateLoading(false);
      })
      .catch((err) => {
        setIsDeleteSupplierOrderEmailTemplateLoading(false);
      });
  };

  const [
    isDeleteSupplierOrderEmailTemplateLoading,
    setIsDeleteSupplierOrderEmailTemplateLoading,
  ] = useState(false);

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isDeleteSupplierOrderEmailTemplateLoading}
          loading={isDeleteSupplierOrderEmailTemplateLoading}
          onClick={() => {
            deleteSupplierOrderEmailTemplate(props.popupActive!.id);
            setIsDeleteSupplierOrderEmailTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSupplierOrderEmailTemplate;
