import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import SubjectTwoToneIcon from "@mui/icons-material/SubjectTwoTone";
import VideoCameraFrontTwoToneIcon from "@mui/icons-material/VideoCameraFrontTwoTone";
import { Box, IconButton, Paper, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Comment } from "../../../../../models/comment";
import { Employee } from "../../../../../models/employee";
import { Interaction } from "../../../../../models/interaction";
import { Task } from "../../../../../models/task";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { getEmployeeDescription } from "../../../../Reusable/Utils";
import DeleteInteraction from "../../Interactions/DeleteInteraction";
import EditInteraction from "../../Interactions/EditInteraction";
import InteractionTable from "../../Interactions/InteractionTable";
import DeleteTask from "../../Tasks/DeleteTask";
import EditTask from "../../Tasks/EditTask";
import TaskTable from "../../Tasks/TaskTable";
import DeleteEmployee from "../DeleteEmployee";
import EditEmployee from "../EditEmployee";
import Informations from "./Informations";
import "./style.css";

export interface EmployeeDetailsProps {}

const EmployeeDetails: React.FC<EmployeeDetailsProps> = () => {
  const [employee, setEmployee] = useState<Employee | null>(null);

  const [tasks, setTasks] = useState<Task[]>([]);

  const [interactions, setInteractions] = useState<Interaction[]>([]);

  const [nextActions, setNextActions] = useState<
    (Interaction | Task | Comment)[]
  >([]);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Employee | null>(null);

  const [popupDeleteInteractionActive, setPopupDeleteInteractionActive] =
    useState<string[]>([]);

  const [popupEditInteractionActive, setPopupEditInteractionActive] =
    useState<Interaction | null>(null);

  const [popupDeleteTaskActive, setPopupDeleteTaskActive] = useState<string[]>(
    []
  );

  const [popupEditTaskActive, setPopupEditTaskActive] = useState<Task | null>(
    null
  );

  const navigate = useNavigate();

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getEmployeeDetails = () => {
    AxiosHttpClient.get<Employee>(
      "api/v1/weeventpro/employees/" + params.employeeId
    ).then((response) => {
      setEmployee(response);
    });
  };

  const getTasks = () => {
    AxiosHttpClient.get<Task[]>("api/v1/weeventpro/tasks/", {
      criteriaList:
        "responsibles_id=" +
        params.employeeId +
        ",startDateTime=" +
        dayjs().tz("Europe/Paris").format("DD/MM/YYYY") +
        "-undefined",
    }).then((response) => {
      setTasks(response);
    });
  };

  const getInteractions = () => {
    AxiosHttpClient.get<Interaction[]>("api/v1/weeventpro/interactions/", {
      criteriaList:
        "employeesIds=" +
        params.employeeId +
        ",startDateTime=" +
        dayjs().tz("Europe/Paris").format("DD/MM/YYYY") +
        "-undefined",
    }).then((response) => {
      setInteractions(response);
    });
  };

  const getActionDateTime = (obj: Task | Interaction | Comment) => {
    if ("startDateTime" in obj) {
      return obj.startDateTime
        ? dayjs(obj.startDateTime).format("DD/MM/YYYY [à] HH:mm")
        : "";
    } else if ("creationDateTime" in obj) {
      return obj.creationDateTime
        ? dayjs(obj.creationDateTime).format("DD/MM/YYYY [à] HH:mm")
        : "";
    }
    return null;
  };

  const fetchEntityById = (
    action: Task | Interaction | Comment,
    editFunction: any
  ) => {
    let endpoint = "";

    if ("taskNumber" in action) {
      endpoint = "api/v1/weeventpro/tasks/" + action.id;
    } else if ("interactionNumber" in action) {
      endpoint = "api/v1/weeventpro/interactions/" + action.id;
    } else {
      editFunction(action);
      return;
    }

    AxiosHttpClient.get(endpoint)
      .then((response) => {
        editFunction(response);
      })
      .catch((error) => {});
  };

  const getActionComponents = (action: Task | Interaction | Comment) => {
    let icon: React.JSX.Element | null = <SubjectTwoToneIcon />;
    let actionType: string = "Commentaire";
    let actionNumber: string = "";
    let editFunction: any = setPopupEditTaskActive;
    let deleteFunction = setPopupDeleteTaskActive;

    if ("interactionNumber" in action) {
      // C'est une Interaction
      if (action.type === "Appel téléphonique") {
        icon = <LocalPhoneTwoToneIcon color="primary" />;
        actionType = "Appel téléphonique -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "SMS") {
        icon = <SmsTwoToneIcon color="primary" />;
        actionType = "SMS -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "E-mail") {
        icon = <EmailTwoToneIcon color="primary" />;
        actionType = "E-mail -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "Réunion en personne") {
        icon = <GroupsTwoToneIcon color="primary" />;
        actionType = "Réunion en personne -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "Réunion virtuelle") {
        icon = <VideoCameraFrontTwoToneIcon color="primary" />;
        actionType = "Réunion virtuelle -";
        actionNumber = action.interactionNumber;
      } else {
        icon = <LiveHelpTwoToneIcon color="primary" />;
        actionType = "Intéraction -";
        actionNumber = action.interactionNumber;
      }
      editFunction = setPopupEditInteractionActive;
      deleteFunction = setPopupDeleteInteractionActive;
    } else if ("taskNumber" in action) {
      // C'est une Task
      icon = <AssignmentTwoToneIcon color="primary" />;
      actionType = "Tâche -";
      actionNumber = action.taskNumber;
      editFunction = setPopupEditTaskActive;
      deleteFunction = setPopupDeleteTaskActive;
    } else {
      // C'est un Comment
      icon = <SubjectTwoToneIcon color="primary" />;
      actionType = "Commentaire";
      editFunction = setPopupEditTaskActive;
      deleteFunction = setPopupDeleteTaskActive;
    }

    return (
      <FlexContainer margin={"16px 0 0 0"}>
        <FlexContainer
          width={"40px"}
          height={"40px"}
          backgroundColor={CustomTheme.palette.background.default}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {icon}
        </FlexContainer>
        <FlexContainer flexDirection={"column"} flex="1">
          {("interactionNumber" in action || "taskNumber" in action) && (
            <>
              <Text16 margin="0 16px" fontWeight="600" lineHeight="20px">
                {action.title}
              </Text16>
              <FlexContainer>
                <Text14
                  color="rgba(0,0,0,0.5)"
                  margin="0 0 0 16px"
                  lineHeight="20px"
                >
                  {actionType}
                </Text14>
                <Text14
                  color={CustomTheme.palette.secondary.main}
                  margin="0 16px 0 4px"
                  lineHeight="20px"
                  textDecorationLine="underline"
                  cursor="pointer"
                  onClick={(e) => {
                    if ("interactionNumber" in action)
                      navigate(`/feature/interactions/${action.id}`);
                    navigate(`/feature/tasks/${action.id}`);
                  }}
                >
                  {actionNumber}
                </Text14>
              </FlexContainer>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="0 16px"
                lineHeight="20px"
                fontWeight="600"
              >
                {getActionDateTime(action)}
              </Text14>
            </>
          )}
          {"commentWriter" in action && (
            <>
              <Text16 margin="0 16px" fontWeight="600" lineHeight="20px">
                {getEmployeeDescription(action.commentWriter)}
              </Text16>
              <FlexContainer>
                <Text14
                  color="rgba(0,0,0,0.5)"
                  margin="0 0 0 16px"
                  lineHeight="20px"
                >
                  {actionType}
                </Text14>
              </FlexContainer>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="0 16px"
                lineHeight="20px"
                fontWeight="600"
              >
                {getActionDateTime(action)}
              </Text14>
            </>
          )}
        </FlexContainer>
        <FlexContainer
          justifyContent="center"
          marginLeft={"auto"}
          padding={"0 8px 0 0"}
        >
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fetchEntityById(action, editFunction);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteFunction([action!.id]);
            }}
            size="small"
            sx={{ height: "30px", width: "30px" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>
      </FlexContainer>
    );
  };

  useEffect(() => {
    if (!popupEditActive && popupDeleteActive.length === 0) {
      getEmployeeDetails();
    }
    if (!popupEditTaskActive && popupDeleteTaskActive.length === 0) {
      getTasks();
    }
    if (
      !popupEditInteractionActive &&
      popupDeleteInteractionActive.length === 0
    ) {
      getInteractions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    popupEditActive,
    popupDeleteActive,
    popupEditTaskActive,
    popupDeleteTaskActive,
    popupEditInteractionActive,
    popupDeleteInteractionActive,
  ]);

  useEffect(() => {
    if (employee) {
      const today = dayjs().startOf("day");
      const combinedListAsc: (Task | Interaction)[] = [
        ...tasks,
        ...interactions,
      ];

      const sortedListAsc = combinedListAsc.sort((a, b) => {
        return a.startDateTime && b.startDateTime
          ? dayjs(a.startDateTime).isBefore(dayjs(b.startDateTime))
            ? -1
            : 1
          : 0;
      });

      const filteredList = sortedListAsc.filter((action) => {
        return (
          (action.startDateTime &&
            dayjs(action.startDateTime).isSame(today, "day")) ||
          dayjs(action.startDateTime).isAfter(today)
        );
      });

      setNextActions(filteredList);
    }
  }, [employee, tasks, interactions]);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/employees">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>Employés</Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={employee?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{getEmployeeDescription(employee)}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {employee?.employeeNumber}
              </Text14>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {employee?.position}
              </Text14>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {employee?.phoneNumber}
              </Text14>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {employee?.email}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(employee);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  if (employee) setPopupDeleteActive([employee.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
            width: "33%",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <FlexContainer flexDirection="column" flex="1">
              <FlexContainer>
                <Text20>Activités à venir</Text20>
              </FlexContainer>
              {nextActions.length === 0 && (
                <FlexContainer
                  maxHeight={"180px"}
                  flexDirection={"column"}
                  overflowY={"auto"}
                  flex={"1"}
                  justifyContent={"center"}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucune activité retrouvée
                  </Text14>
                </FlexContainer>
              )}
              <FlexContainer
                maxHeight={"180px"}
                flexDirection={"column"}
                overflowY={"auto"}
              >
                {nextActions.map((action) => getActionComponents(action))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && employee && (
        <TabsDesktop
          tabs={["Infos", "Tâches", "Intéractions"]}
          components={[
            <Informations employee={employee!} />,
            <TaskTable
              additionalCriterias={"responsibles_id=" + employee.id}
              actionsOnAdd={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnEdit={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnDelete={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnGenerate={[
                getEmployeeDetails,
                getTasks,
                getInteractions,
              ]}
            />,
            <InteractionTable
              additionalCriterias={"employeesIds=" + employee.id}
              actionsOnAdd={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnEdit={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnDelete={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnGenerate={[
                getEmployeeDetails,
                getTasks,
                getInteractions,
              ]}
            />,
          ]}
        />
      )}
      {!matches && employee && (
        <TabsMobile
          tabs={["Infos", "Tâches", "Intéractions"]}
          components={[
            <Informations employee={employee!} />,
            <TaskTable
              additionalCriterias={"responsibles_id=" + employee.id}
              actionsOnAdd={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnEdit={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnDelete={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnGenerate={[
                getEmployeeDetails,
                getTasks,
                getInteractions,
              ]}
            />,
            <InteractionTable
              additionalCriterias={"employeesIds=" + employee.id}
              actionsOnAdd={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnEdit={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnDelete={[getEmployeeDetails, getTasks, getInteractions]}
              actionsOnGenerate={[
                getEmployeeDetails,
                getTasks,
                getInteractions,
              ]}
            />,
          ]}
        />
      )}
      <DeleteEmployee
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
        redirect
      />
      <DeleteInteraction
        popupActive={popupDeleteInteractionActive}
        setPopupActive={setPopupDeleteInteractionActive}
        actionsOnDelete={[getEmployeeDetails]}
      />
      <DeleteTask
        popupActive={popupDeleteTaskActive}
        setPopupActive={setPopupDeleteTaskActive}
        actionsOnDelete={[getEmployeeDetails]}
      />
      {popupEditActive !== null && (
        <EditEmployee
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          actionsOnEdit={[getEmployeeDetails]}
        />
      )}
      {popupEditInteractionActive !== null && (
        <EditInteraction
          popupActive={popupEditInteractionActive}
          setPopupActive={setPopupEditInteractionActive}
          actionsOnEdit={[getEmployeeDetails]}
        />
      )}
      {popupEditTaskActive !== null && (
        <EditTask
          popupActive={popupEditTaskActive}
          setPopupActive={setPopupEditTaskActive}
          actionsOnEdit={[getEmployeeDetails]}
        />
      )}
    </BlockContainer>
  );
};

export default EmployeeDetails;
