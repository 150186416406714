import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import QuoteTable from "./QuoteTable";

export interface QuoteProps {}

const Quote: React.FC<QuoteProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Devis"
        subTitle="Liste des devis"
        icon={<ArticleTwoToneIcon fontSize="large" color="primary" />}
      />
      <QuoteTable />
    </BlockContainer>
  );
};

export default Quote;
