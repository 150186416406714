import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArticleIcon from "@mui/icons-material/Article";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Collapse,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { EmployeeDocument } from "../../../../../../models/employee";
import { File } from "../../../../../../models/file";
import { Dispatch, RootState } from "../../../../../../store";
import { getClientsAction } from "../../../../../../store/Crm/actions";
import { getClients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { trimLongString } from "../../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface EmployeeDocumentProps {
  inputDocuments: EmployeeDocument[];
  setInputDocuments: React.Dispatch<React.SetStateAction<EmployeeDocument[]>>;
}

const _Documents: React.FC<EmployeeDocumentProps> = (props) => {
  const [employeeDocumentAddActive, setEmployeeDocumentAddActive] =
    useState(false);

  const [employeeDocumentEditActive, setEmployeeDocumentEditActive] =
    useState("");

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputExpirationDate, setInputExpirationDate] = useState<Dayjs | null>(
    null
  );

  const [inputFiles, setInputFiles] = useState<File[]>([]);

  const [addFailed, setAddFailed] = useState("");

  const resetValidation = () => {
    setAddFailed("");
    setInputNameError(false);
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputFiles.length === 0) {
      setAddFailed("Veuillez sélectionner un fichier!");
      return false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputFiles.length === 0) {
      setAddFailed("Veuillez sélectionner un fichier!");
      return false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  let removeFile = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setEmployeeDocumentAddActive(true);
            setEmployeeDocumentEditActive("");
            setInputName("");
            setInputExpirationDate(null);
            setInputFiles([]);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un document
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={employeeDocumentAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="category"
                noOptionsText={"Pas de suggestions"}
                fullWidth
                disableClearable
                freeSolo
                options={[
                  "Carte d'identité",
                  "Titre de séjour",
                  "Passeport",
                  "Permis de conduire",
                  "Carte Vitale",
                  "Diplôme",
                  "Contrat de travail",
                ]}
                value={inputName}
                onChange={(event, newValue) => {
                  setInputName(newValue!);
                }}
                inputValue={inputName}
                onInputChange={(event, newInputValue) => {
                  setInputName(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={inputNameError}
                    name="name"
                    label="Nom"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date d'expiration"
                  inputFormat="DD/MM/YYYY"
                  value={inputExpirationDate}
                  onChange={(value) => setInputExpirationDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <FlexContainer
                border="2px dashed rgba(0,0,0,0.1)"
                margin={"0 0 8px 0"}
                alignItems={"center"}
              >
                <Grid container flex={1}>
                  {inputFiles!.map((file, index) => (
                    <Grid>
                      <FlexContainer
                        elevation={3}
                        margin="8px"
                        height="40px"
                        justifyContent="space-between"
                        alignItems="center"
                        $borderRadius="16px"
                      >
                        <FlexContainer margin="0 0 0 8px" alignItems="center">
                          <AttachFileRoundedIcon
                            fontSize="medium"
                            color="primary"
                          />
                          <Text14
                            cursor="pointer"
                            margin="0 4px"
                            color={CustomTheme.palette.secondary.main}
                            textDecorationLine="underline"
                            onClick={(e) => {
                              fetch(file.url)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const link = document.createElement("a");
                                  link.href = url;
                                  link.setAttribute("download", file.name);
                                  document.body.appendChild(link);
                                  link.click();
                                });
                            }}
                          >
                            {trimLongString(file.name)}
                          </Text14>
                        </FlexContainer>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            removeFile(index);
                          }}
                          size="medium"
                          style={{
                            margin: "0 8px",
                            padding: "4px",
                            zIndex: "1",
                          }}
                        >
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </FlexContainer>
                    </Grid>
                  ))}
                  {inputFiles.length === 0 && (
                    <FlexContainer
                      margin="8px"
                      height="40px"
                      alignItems={"center"}
                    >
                      <Text16 color="rgba(0,0,0,0.5)">
                        Aucun fichier sélectionné
                      </Text16>
                    </FlexContainer>
                  )}
                </Grid>
                <FlexContainer marginRight={"8px"}>
                  <label htmlFor="contained-button-file">
                    <Input
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {
                        filesPreviewHandler(e.target.files);
                      }}
                    />
                    <IconButton component="span">
                      <AttachFileRoundedIcon fontSize="medium" />
                    </IconButton>
                  </label>
                </FlexContainer>
              </FlexContainer>
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputDocuments([
                  ...props.inputDocuments,
                  {
                    id: uuid(),
                    name: inputName,
                    expirationDate: inputExpirationDate
                      ? dayjs(inputExpirationDate).tz("Europe/Paris")
                      : null,
                    files: inputFiles,
                  },
                ]);
                setEmployeeDocumentAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setEmployeeDocumentAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputDocuments.map((employeeDocument, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer>
              <FlexContainer
                width={"40px"}
                height={"40px"}
                backgroundColor={CustomTheme.palette.background.default}
                justifyContent={"center"}
                alignItems={"center"}
                margin={"4px 0 8px 0"}
              >
                <ArticleIcon fontSize="large" color="primary" />
              </FlexContainer>

              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600" lineHeight="20px">
                  {employeeDocument.name}
                </Text16>
                <Text14 lineHeight="20px">
                  {employeeDocument.expirationDate
                    ? "Expire le: " +
                      dayjs(employeeDocument.expirationDate).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setEmployeeDocumentAddActive(false);
                  setEmployeeDocumentEditActive(employeeDocument.id);
                  setInputName(employeeDocument.name);
                  setInputExpirationDate(employeeDocument.expirationDate);
                  setInputFiles(employeeDocument.files);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputDocuments];
                  newArr.splice(index, 1);
                  props.setInputDocuments(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={employeeDocumentEditActive === employeeDocument.id}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={1}>
                  <Autocomplete
                    id="category"
                    noOptionsText={"Pas de suggestions"}
                    fullWidth
                    disableClearable
                    freeSolo
                    options={[
                      "Carte d'identité",
                      "Titre de séjour",
                      "Passeport",
                      "Permis de conduire",
                      "Carte Vitale",
                      "Diplôme",
                      "Contrat de travail",
                    ]}
                    value={inputName}
                    onChange={(event, newValue) => {
                      setInputName(newValue!);
                    }}
                    inputValue={inputName}
                    onInputChange={(event, newInputValue) => {
                      setInputName(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={inputNameError}
                        name="name"
                        label="Nom"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date d'expiration"
                      inputFormat="DD/MM/YYYY"
                      value={inputExpirationDate}
                      onChange={(value) => setInputExpirationDate(value!)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <FlexContainer
                    border="2px dashed rgba(0,0,0,0.1)"
                    margin={"0 0 8px 0"}
                    alignItems={"center"}
                  >
                    <Grid container flex={1}>
                      {inputFiles!.map((justification, index) => (
                        <Grid>
                          <FlexContainer
                            elevation={3}
                            margin="8px"
                            height="40px"
                            justifyContent="space-between"
                            alignItems="center"
                            $borderRadius="16px"
                          >
                            <FlexContainer
                              margin="0 0 0 8px"
                              alignItems="center"
                            >
                              <AttachFileRoundedIcon
                                fontSize="medium"
                                color="primary"
                              />
                              <Text14
                                cursor="pointer"
                                margin="0 4px"
                                color={CustomTheme.palette.secondary.main}
                                textDecorationLine="underline"
                                onClick={(e) => {
                                  fetch(justification.url)
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      const url =
                                        window.URL.createObjectURL(blob);
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.setAttribute(
                                        "download",
                                        justification.name
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                    });
                                }}
                              >
                                {trimLongString(justification.name)}
                              </Text14>
                            </FlexContainer>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeFile(index);
                              }}
                              size="medium"
                              style={{
                                margin: "0 8px",
                                padding: "4px",
                                zIndex: "1",
                              }}
                            >
                              <CloseIcon fontSize="medium" />
                            </IconButton>
                          </FlexContainer>
                        </Grid>
                      ))}
                      {inputFiles.length === 0 && (
                        <FlexContainer
                          margin="8px"
                          height="40px"
                          alignItems={"center"}
                        >
                          <Text16 color="rgba(0,0,0,0.5)">
                            Aucun fichier sélectionné
                          </Text16>
                        </FlexContainer>
                      )}
                    </Grid>
                    <FlexContainer marginRight={"8px"}>
                      <label htmlFor="contained-button-file">
                        <Input
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            filesPreviewHandler(e.target.files);
                          }}
                        />
                        <IconButton component="span">
                          <AttachFileRoundedIcon fontSize="medium" />
                        </IconButton>
                      </label>
                    </FlexContainer>
                  </FlexContainer>
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setEmployeeDocumentAddActive(false);
                  resetValidation();
                  if (validateEditForm()) {
                    let tmpArray = [...props.inputDocuments];
                    tmpArray[index] = {
                      id: employeeDocument.id,
                      name: inputName,
                      expirationDate: inputExpirationDate
                        ? dayjs(inputExpirationDate).tz("Europe/Paris")
                        : null,
                      files: inputFiles,
                    };

                    props.setInputDocuments(tmpArray);
                    setEmployeeDocumentEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setEmployeeDocumentEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const Documents = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_Documents);

export default _Documents;
