import { Search } from "@mui/icons-material";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Pagination } from "rsuite";
import { CustomField } from "../../../../../models/customField";
import { UserParameter } from "../../../../../models/userParameters";
import { Vacation } from "../../../../../models/vacation";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import {
  VACATION_TABLE_ORDER,
  VACATION_TABLE_ORDER_BY,
  VACATION_TABLE_SEARCH_BY,
  VACATION_TABLE_VIEW,
  getEmployeeDescription,
  getUserParameterValueByName,
  isBeforeToday,
} from "../../../../Reusable/Utils";
import AcceptVacationRequestConfirmation from "../AcceptVacationRequestConfirmation";
import ExportVacations from "../ExportVacation";
import Justifications from "../Justifications";
import RefuseVacationRequestConfirmation from "../RefuseVacationRequestConfirmation";
import VacationDetails from "../VacationDetails";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface VacationRequestsTableProps {
  additionalCriterias?: string;
  actionsOnAdd?: (() => void)[];
  actionsOnEdit?: (() => void)[];
  actionsOnDelete?: (() => void)[];
  triggerGetTable?: boolean;
}

const VacationRequestsTable: React.FC<VacationRequestsTableProps> = ({
  additionalCriterias,
  actionsOnAdd,
  actionsOnEdit,
  actionsOnDelete,
  triggerGetTable,
}) => {
  const getVacationsPage = () => {
    setTableLoading(true);
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    AxiosHttpClient.get<any>(
      "api/v1/weeventpro/vacations/requests/page",
      {
        criteriaList:
          inputField +
          "=" +
          inputSearch +
          (additionalCriterias ? "," + additionalCriterias : ""),
        pageNumber: page - 1,
        pageSize: limit,
        sort: fieldsCorrespondanceMapForOrders.get(orderBy),
        order: order,
      },
      signal
    )
      .then((res) => {
        setVacations(res.content);
        setTableLoading(false);
        setTotalElements(res.totalElements);
      })
      .catch((err) => {});
  };

  const previousController = useRef<AbortController>(null!);

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/vacation")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/vacation"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        VACATION_TABLE_ORDER +
        "," +
        VACATION_TABLE_ORDER_BY +
        "," +
        VACATION_TABLE_SEARCH_BY +
        "," +
        VACATION_TABLE_VIEW,
    }).then((res) => {
      setOrder(
        getUserParameterValueByName(VACATION_TABLE_ORDER, res) === "asc"
          ? "asc"
          : "desc"
      );
      setOrderBy(getUserParameterValueByName(VACATION_TABLE_ORDER_BY, res));
      setInputField(getUserParameterValueByName(VACATION_TABLE_SEARCH_BY, res));
      setInputView(getUserParameterValueByName(VACATION_TABLE_VIEW, res));
    });
  };

  const classes = useStyles();

  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc" | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [inputSearch, setInputSearch] = useState("");

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [popupDetailsActive, setPopupDetailsActive] = useState<Vacation | null>(
    null
  );

  const [popupJustificationsActive, setPopupJustificationsActive] =
    useState<Vacation | null>(null);

  const [
    popupAcceptVacationRequestActive,
    setPopupAcceptVacationRequestActive,
  ] = useState("");

  const [
    popupRefuseVacationRequestActive,
    setPopupRefuseVacationRequestActive,
  ] = useState("");

  const [inputView, setInputView] = useState("");

  const [views, setViews] = useState<View[]>([]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  const [inputField, setInputField] = useState("");

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);

  const [totalElements, setTotalElements] = useState(0);

  const [vacations, setVacations] = useState<Vacation[]>([]);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getVacationsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, page, limit, orderBy, order, triggerGetTable]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, inputField, limit, orderBy, order]);

  useEffect(() => {
    setInputSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  const setOrderParameters = (orderValue: string, orderByValue: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: VACATION_TABLE_ORDER,
        parameterValue: orderValue,
      },
      {
        parameterName: VACATION_TABLE_ORDER_BY,
        parameterValue: orderByValue,
      },
    ])
      .then(() => {})
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrderParameters(isAsc ? "desc" : "asc", cellId);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "N° du congé",
      "Employé",
      "Date de début",
      "Date de fin",
      "Durée du congé",
      "Répartition des congés",
      "Statut",
    ],
  };

  const getViewById = (id: string) => {
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : defaultView;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  let fieldsCorrespondanceMapForOrders: Map<string, string> = new Map([
    ["N° du congé", "vacationNumber"],
    ["Employé", "employeeFullName"],
    ["Date de début", "startDate"],
    ["Date de fin", "endDate"],
    ["Durée du congé", "vacationDuration"],
    ["Superviseur", "supervisorFullName"],
    ["Commentaire", "comment"],
    ["Statut", "status"],
  ]);

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["N° du congé", true],
      ["Employé", true],
      ["Date de début", true],
      ["Date de fin", true],
      ["Durée du congé", true],
      ["Répartition des congés", false],
      ["Superviseur", true],
      ["Copies à", false],
      ["Fichiers de justification", false],
      ["Commentaire", true],
      ["Statut", true],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (vacation: Vacation, customFieldId: string) => {
    if (vacation.customFields !== null) {
      for (var customFieldValue of vacation.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, vacation: Vacation) => {
    let headValuesMap: Map<string, string> = new Map([
      ["N° du congé", vacation.vacationNumber],
      ["Employé", getEmployeeDescription(vacation.employee)],
      [
        "Date de début",
        vacation.startDate
          ? dayjs(vacation.startDate).format("DD/MM/YYYY")
          : "",
      ],
      [
        "Date de fin",
        vacation.endDate ? dayjs(vacation.endDate).format("DD/MM/YYYY") : "",
      ],
      ["Durée du congé", vacation.vacationDuration.toString()],
      ["Répartition des congés", getVacationDistribution(vacation)],
      ["Superviseur", getEmployeeDescription(vacation.supervisor)],
      [
        "Copies à",
        vacation.copiesTo
          .map((employee) => getEmployeeDescription(employee))
          .join(", "),
      ],
      ["Commentaire", vacation.comment],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(
        customField.name,
        getCustomFieldValues(vacation, customField.id)
      );
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, vacation: Vacation) => {
    let headValuesMap: Map<string, React.JSX.Element> = new Map([
      [
        "Fichiers de justification",
        vacation.justificationFiles.length === 0 ? (
          <>-</>
        ) : (
          <FlexContainer justifyContent="center">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPopupJustificationsActive(vacation);
              }}
              size="large"
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </FlexContainer>
        ),
      ],
      [
        "Statut",
        <FlexContainer justifyContent="center">
          <FlexContainer
            justifyContent="center"
            backgroundColor={getStatusBackgroundColor(vacation.status)}
            color="white"
            padding="4px 8px"
            $borderRadius="50px"
          >
            {vacation.status}
          </FlexContainer>
        </FlexContainer>,
      ],
      [
        "Actions",
        <FlexContainer justifyContent="center">
          <IconButton
            disabled={
              vacation.status !== "En attente" ||
              isBeforeToday(vacation.startDate)
            }
            color="success"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupAcceptVacationRequestActive(vacation.id);
            }}
            size="large"
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            disabled={
              vacation.status !== "En attente" ||
              isBeforeToday(vacation.startDate)
            }
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPopupRefuseVacationRequestActive(vacation.id);
            }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FlexContainer>,
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getValueCells = (headValue: string, vacation: Vacation) => {
    let simpleValue = getSimpleCellValue(headValue, vacation);
    let complexValue = getComplexCellValue(headValue, vacation);
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "En attente") return CustomTheme.palette.primary.dark;
    if (status === "Acceptée") return CustomTheme.palette.success.light;
    if (status === "Refusée") return CustomTheme.palette.secondary.main;
    if (status === "Annulée") return CustomTheme.palette.error.dark;
  };

  const getVacationDistribution = (vacation: Vacation): string => {
    const distributionArray: string[] = [];
    if (vacation.paidLeave > 0)
      distributionArray.push(`${vacation.paidLeave} congés payés`);
    if (vacation.rtt > 0) distributionArray.push(`${vacation.rtt} RTT`);
    if (vacation.sickLeave > 0)
      distributionArray.push(`${vacation.sickLeave} congé maladie`);
    if (vacation.maternityLeave > 0)
      distributionArray.push(`${vacation.maternityLeave} congé maternité`);
    if (vacation.unpaidLeave > 0)
      distributionArray.push(`${vacation.unpaidLeave} congé non payé`);
    if (vacation.publicHoliday > 0)
      distributionArray.push(`${vacation.publicHoliday} jour férié`);
    if (vacation.recoveryLeave > 0)
      distributionArray.push(`${vacation.recoveryLeave} congé de récupération`);

    return distributionArray.join(", ");
  };

  useEffect(() => {
    getCustomFields();
    getViews();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </FlexContainer>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          inputField={inputField}
          setInputField={setInputField}
          setInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui sx={{ position: "relative" }}>
        {tableLoading && (
          <FlexContainer
            sx={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 57.297px)",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              top: "57.297px",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: "2",
              opacity: "0.7",
            }}
          >
            <CircularProgress size={80} />
          </FlexContainer>
        )}
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === vacations.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = vacations.map(
                        (vacation) => vacation.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading && vacations.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "150px", borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            )}
            {!tableLoading && vacations.length === 0 && (
              <TableRow key={"Empty"}>
                <TableCell
                  colSpan={8}
                  sx={{ height: "100px", borderBottom: "none" }}
                >
                  <Text14 color="gray" textAlign="center">
                    Aucun congé retrouvé
                  </Text14>
                </TableCell>
              </TableRow>
            )}
            {vacations.map((item) => (
              <TableRow
                key={item.id}
                onClick={(e) => {
                  setPopupDetailsActive(item);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={selected.indexOf(item.id) > -1}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newArr = [...selected];
                      const index = selected.indexOf(item.id);
                      if (index > -1) {
                        newArr.splice(index, 1);
                        setSelected(newArr);
                      } else {
                        setSelected([...selected, item.id]);
                      }
                    }}
                  />
                </TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell key={index}>
                    {getValueCells(headCell.label, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer sx={{ marginTop: "16px" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalElements}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={(dataKey) => {
            setPage(1);
            setLimit(dataKey);
          }}
        />
      </BlockContainer>
      {popupJustificationsActive !== null && (
        <Justifications
          popupActive={popupJustificationsActive}
          setPopupActive={setPopupJustificationsActive}
        />
      )}
      {popupExportActive && (
        <ExportVacations
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
      {popupAcceptVacationRequestActive !== null && (
        <AcceptVacationRequestConfirmation
          popupActive={popupAcceptVacationRequestActive}
          setPopupActive={setPopupAcceptVacationRequestActive}
          actionsOnSubmit={[...(actionsOnDelete || []), getVacationsPage]}
        />
      )}
      {popupRefuseVacationRequestActive !== null && (
        <RefuseVacationRequestConfirmation
          popupActive={popupRefuseVacationRequestActive}
          setPopupActive={setPopupRefuseVacationRequestActive}
          actionsOnSubmit={[...(actionsOnDelete || []), getVacationsPage]}
        />
      )}
      {popupDetailsActive !== null && (
        <VacationDetails
          popupActive={popupDetailsActive}
          setPopupActive={setPopupDetailsActive}
        />
      )}
      {/*popupUpGenerateUpfrontInvoice !== null && (
        <GenerateUpfrontInvoice
          inputSearch={inputSearch}
          popupActive={popupUpGenerateUpfrontInvoice}
          setPopupActive={setPopupUpGenerateUpfrontInvoice}
        />
      )*/}
      {/*popupUpGenerateInvoice !== null && (
        <GenerateInvoice
          inputSearch={inputSearch}
          popupActive={popupUpGenerateInvoice}
          setPopupActive={setPopupUpGenerateInvoice}
          actionsOnGenerate={[
            ...(actionsOnGenerate || []),
            getVacationsPage,
          ]}
        />
        )*/}
    </Paper>
  );
};

export default VacationRequestsTable;
