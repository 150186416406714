import { Avatar } from "@mui/material";
import React from "react";
import { Contact } from "../../../models/contact";
import { Employee } from "../../../models/employee";
import {
  getParticipantDescription,
  imageUrlValid,
  stringAvatar,
} from "../Utils";

export interface AvatarWithInitialsProps {
  entity: Contact | Employee;
}

const AvatarWithInitials: React.FC<AvatarWithInitialsProps> = ({ entity }) => {
  return (
    <Avatar
      alt={getParticipantDescription(entity)}
      src={imageUrlValid(entity.imageUrl) ? entity.imageUrl : undefined}
      {...stringAvatar(getParticipantDescription(entity))}
    />
  );
};

export default AvatarWithInitials;
