import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { SigninService } from "../../services/SigninService";
import { RootAction } from "../types";
import {
  SIGN_IN,
  SignInActionFailure,
  signInActionFailure,
  SignInActionSuccess,
  signInActionSuccess,
} from "./actions";

export const signinEpic: Epic<
  RootAction,
  SignInActionSuccess | SignInActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap((action) =>
      from(
        SigninService.signIn({
          email: action.email,
          password: action.password,
        })
      ).pipe(
        mergeMap((res) => {
          localStorage.setItem("jwtToken", res.accessToken);
          localStorage.setItem("refreshToken", res.refreshToken);
          return [signInActionSuccess()];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [signInActionFailure()]))
        )
      )
    )
  );
