import { combineReducers } from "redux";
import { cartReducer } from "./Cart";
import { crmReducer } from "./Crm";
import {
  customerOrderArchiveReducer,
  customerOrderReducer,
} from "./CustomerOrder";
import { dishReducer } from "./Dish";
import { expandedMenuItemReducer } from "./ExpandedMenuItem";
import { interactionReducer } from "./Interaction";
import {
  creditNoteTemplateReducer,
  invoiceReducer,
  invoiceTemplateReducer,
} from "./Invoice";
import { metricReducer } from "./Metric";
import { notificationReducer } from "./Notification";
import { quoteReducer, quoteTemplateReducer } from "./Quote";
import { rhReducer } from "./Rh";
import { routeReducer } from "./Route";
import { saleReducer } from "./Sale";
import { serviceReducer } from "./Service";
import { signInReducer } from "./Signin";
import { signUpReducer } from "./Signup";
import { stockReducer } from "./Stock";
import {
  supplierOrderArchiveReducer,
  supplierOrderReducer,
} from "./SupplierOrder";
import { RootState } from "./types";
import {
  upfrontInvoiceReducer,
  upfrontInvoiceTemplateReducer,
} from "./UpfrontInvoice";

export const rootReducer = combineReducers<RootState>({
  signUp: signUpReducer,
  signIn: signInReducer,
  stock: stockReducer,
  dish: dishReducer,
  service: serviceReducer,
  quote: quoteReducer,
  quoteTemplates: quoteTemplateReducer,
  upfrontInvoice: upfrontInvoiceReducer,
  upfrontInvoiceTemplates: upfrontInvoiceTemplateReducer,
  invoice: invoiceReducer,
  invoiceTemplates: invoiceTemplateReducer,
  creditNoteTemplates: creditNoteTemplateReducer,
  expandedMenuItem: expandedMenuItemReducer,
  customerOrder: customerOrderReducer,
  customerOrderArchive: customerOrderArchiveReducer,
  supplierOrder: supplierOrderReducer,
  supplierOrderArchive: supplierOrderArchiveReducer,
  cart: cartReducer,
  metric: metricReducer,
  crm: crmReducer,
  rh: rhReducer,
  sale: saleReducer,
  route: routeReducer,
  interaction: interactionReducer,
  notification: notificationReducer,
});
