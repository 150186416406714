import { DialogContent } from "@mui/material";
import styled from "styled-components";
import { ThemeCustom } from "../Utils";

interface IHeadingStyled {}

export const DialogContentMui = styled(DialogContent)<IHeadingStyled>`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${ThemeCustom.colors.lightOrange};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${ThemeCustom.colors.orange};
    border-radius: 10px;
  }
`;
