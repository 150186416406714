import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import ProspectTable from "./ProspectTable";

export interface ProspectProps {}

const Prospect: React.FC<ProspectProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Prospects"
        subTitle="Liste des prospects"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <ProspectTable />
    </BlockContainer>
  );
};

export default Prospect;
