import { useMediaQuery } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../../../store";
import { getTasksAction } from "../../../../../store/Crm/actions";
import { getInteractionsAction } from "../../../../../store/Interaction/actions";
import { getInteractions, getTasks } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import VacationRequestsTable from "../VacationRequestsTable";
import VacationTable from "../VacationTable";

export interface VacationTablesProps {}

const _VacationTables: React.FC<VacationTablesProps> = () => {
  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      {matches && (
        <TabsDesktop
          tabs={["Mes congés", "Demandes à valider"]}
          components={[<VacationTable />, <VacationRequestsTable />]}
        />
      )}
      {!matches && (
        <TabsMobile
          tabs={["Mes congés", "Demandes à valider"]}
          components={[<VacationTable />, <VacationRequestsTable />]}
        />
      )}
    </BlockContainer>
  );
};

export const VacationTables = connect(
  (state: RootState) => ({
    tasks: getTasks(state),
    interactions: getInteractions(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getTasksAction: getTasksAction,
        getInteractionsAction: getInteractionsAction,
      },
      dispatch
    )
)(_VacationTables);

export default _VacationTables;
