import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Contact } from "../../../../../../models/contact";
import { Ingredient } from "../../../../../../models/ingredients";
import { Dispatch, RootState } from "../../../../../../store";
import { getIngredientsAction } from "../../../../../../store/Stock/actions";
import { getIngredients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getContactDescription,
  getEmployeeDescription,
  toFixed2,
} from "../../../../../Reusable/Utils";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MapFieldsProps {
  clients: Contact[];
  ingredients: Ingredient[];
  fetchedValues: string[][];
  outputErrors: string[];
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
}

const _MapFields: React.FC<MapFieldsProps> = (props) => {
  let fields = [
    "Image",
    "Nom",
    "Catégorie",
    "Quantité",
    "Prix",
    "Allergies",
    "Quantité minimale",
    "Quantité idéale",
    "Suggestion par évènement",
    "À vendre",
    "Durée d'expiration",
    "Unités d'achat",
    "Unités de stockage",
    "Unités de production",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
  ];

  const getValueCells = (headValue: string, client: Contact) => {
    let headValuesMap: Map<string, string> = new Map([
      ["Code client", client.contactCode],
      ["Intitulé", getContactDescription(client)],
      ["Type client", client.type],
      ["Nom entreprise", client.companyName],
      ["Raison sociale", client.businessName],
      ["Forme juridique", client.legalForm],
      ["Régime fiscal", client.fiscalRegime],
      ["Numéro SIRET", client.siretNumber],
      ["Numéro de TVA intracommunautaire", client.intracommunityVATNumber],
      ["Civilité", client.type === "Particulier" ? client.title : ""],
      ["Nom", client.lastName],
      ["Prénom", client.firstName],
      [
        "Date de naissance",
        client.dateOfBirth
          ? dayjs(client.dateOfBirth).format("DD/MM/YYYY")
          : "",
      ],
      ["Situation familiale", client.maritalStatus],
      ["Catégorie socio-professionnelle", client.socioProfessionalCategory],
      ["N° de téléphone", client.phoneNumber],
      ["Adresse e-mail", client.email],
      ["Adresse", client.address],
      ["Instructions de livraison", client.deliveryInstructions],
      [
        "Contacts associés",
        client.associatedContacts
          .map((contact) => `${contact.firstName} ${contact.lastName}`)
          .join(", "),
      ],
      ["Mode de paiement préféré", client.preferredPaymentMethod],
      ["Responsable", getEmployeeDescription(client.responsible)],
      ["Reste à payer HT", toFixed2(client.remainingPriceHT)],
      ["Reste à payer TVA", toFixed2(client.remainingPriceTVA)],
      ["Reste à payer TTC", toFixed2(client.remainingPriceTTC)],
      ["Non facturé HT", toFixed2(client.nonBilledPriceHT)],
      ["Non facturé TVA", toFixed2(client.nonBilledPriceTVA)],
      ["Non facturé TTC", toFixed2(client.nonBilledPriceTTC)],
    ]);
    let headComplexValuesMap = new Map([
      [
        "Image",
        <BlockContainer
          width="100px"
          height="100px"
          border="rgba(0,0,0,0.1) solid 1px"
          justifyContent="center"
          position="relative"
          textAlign="center"
          backgroundColor="white"
          margin="auto"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            alt=""
            src={client?.imageUrl}
          />
        </BlockContainer>,
      ],
    ]);
    return headValuesMap.get(headValue) !== undefined
      ? headValuesMap.get(headValue)
      : headComplexValuesMap.get(headValue);
  };

  const classes = useStyles();

  return (
    <BlockContainer>
      <TableContainerMui $maxHeight="45vh">
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map((header, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clients.map((client, index) => (
              <TableRow key={index}>
                {fields.map((field, index2) => (
                  <TableCell key={index2}>
                    {getValueCells(field, client)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <BlockContainer
        margin="16px"
        border="1px solid rgba(0,0,0,0.2)"
        $borderRadius="4px"
        $maxHeight="15vh"
        overflow="auto"
      >
        {props.outputErrors.map((outputError, index) => (
          <Text14 color={CustomTheme.palette.secondary.main} key={index}>
            {outputError}
          </Text14>
        ))}
        {props.outputErrors.length === 0 && (
          <Text14>Fichier valide! Vous pouvez importer vos données</Text14>
        )}
      </BlockContainer>
    </BlockContainer>
  );
};

export const MapFields = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_MapFields);

export default MapFields;
