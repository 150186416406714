import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { StockService } from "../../services/StockService";
import { RootAction } from "../types";
import {
  GET_INGREDIENTS,
  GET_MATERIALS,
  GET_STORAGE_AREAS,
  GET_SUPPLIERS,
  GetIngredientsActionFailure,
  getIngredientsActionFailure,
  GetIngredientsActionSuccess,
  getIngredientsActionSuccess,
  GetMaterialsActionFailure,
  getMaterialsActionFailure,
  GetMaterialsActionSuccess,
  getMaterialsActionSuccess,
  GetStorageAreasActionFailure,
  getStorageAreasActionFailure,
  getStorageAreasActionSuccess,
  GetStorageAreasActionSuccess,
  GetSuppliersActionFailure,
  getSuppliersActionFailure,
  GetSuppliersActionSuccess,
  getSuppliersActionSuccess,
} from "./actions";

export const ingredientsEpic: Epic<
  RootAction,
  GetIngredientsActionSuccess | GetIngredientsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INGREDIENTS),
    switchMap((action) =>
      from(
        StockService.getIngredients({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getIngredientsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getIngredientsActionFailure()]))
        )
      )
    )
  );

export const materialsEpic: Epic<
  RootAction,
  GetMaterialsActionSuccess | GetMaterialsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_MATERIALS),
    switchMap((action) =>
      from(
        StockService.getMaterials({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getMaterialsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getMaterialsActionFailure()]))
        )
      )
    )
  );

export const storageAreasEpic: Epic<
  RootAction,
  GetStorageAreasActionSuccess | GetStorageAreasActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_STORAGE_AREAS),
    switchMap((action) =>
      from(
        StockService.getStorageAreas({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getStorageAreasActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getStorageAreasActionFailure()]))
        )
      )
    )
  );

export const suppliersEpic: Epic<
  RootAction,
  GetSuppliersActionSuccess | GetSuppliersActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SUPPLIERS),
    switchMap((action) =>
      from(
        StockService.getSuppliers({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getSuppliersActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getSuppliersActionFailure()]))
        )
      )
    )
  );

export const stockEpic = combineEpics<any>(
  ingredientsEpic,
  materialsEpic,
  storageAreasEpic,
  suppliersEpic
);
