import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getSuppliers } from "../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../store/Stock/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface DeleteSupplierProps {
  popupActive: string[];
  setPopupActive: React.Dispatch<React.SetStateAction<string[]>>;
  actionsOnDelete?: (() => void)[];
  redirect?: boolean;
}

const _DeleteSupplier: React.FC<DeleteSupplierProps> = (props) => {
  const deleteSupplier = () => {
    AxiosHttpClient.delete("api/v1/weeventpro/suppliers/", props.popupActive)
      .then(() => {
        props.setPopupActive([]);
        props.actionsOnDelete?.forEach((action) => action());
        if (props.redirect) navigate("/feature/suppliers");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Dialog
      open={props.popupActive.length !== 0}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive([]);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer ingrédient?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          {props.popupActive.length === 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer ce fournisseur ?
            </React.Fragment>
          )}
          {props.popupActive.length > 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer les fournisseurs sélectionnés?
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            if (props.redirect) navigate("/feature/suppliers");
            setIsLoading(true);
            deleteSupplier();
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive([])}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteSupplier = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_DeleteSupplier);

export default DeleteSupplier;
