import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Unit } from "../../../../../../models/ingredients";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  baseUnits,
  getUnitById,
  getUnitDescription,
  isBaseUnit,
  isParentOf,
} from "../../../../../Reusable/Utils";

export interface SaleUnitsProps {
  inputRef: React.RefObject<HTMLInputElement | null>;
  enabledUnitOptions: string[];
  inputProductionUnits: Unit[];
  inputSaleUnits: Unit[];
  saleUnitAddActive: boolean;
  saleUnitOpen: boolean;
  setInputProductionUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputSaleUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setSaleUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSaleUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaleUnits: React.FC<SaleUnitsProps> = (props) => {
  const [inputSaleUnit, setInputSaleUnit] = useState("");

  const [inputSaleUnitError, setInputSaleUnitError] = useState(false);

  const [saleUnitEditActive, setSaleUnitEditActive] = useState("");

  const [inputCustomSaleUnit, setInputCustomSaleUnit] = useState("");

  const [inputCustomSaleUnitError, setInputCustomSaleUnitError] =
    useState(false);

  const [
    inputCustomSaleUnitCorrespondanceValue,
    setInputCustomSaleUnitCorrespondanceValue,
  ] = useState("");

  const [
    inputCustomSaleUnitCorrespondanceValueError,
    setInputCustomSaleUnitCorrespondanceValueError,
  ] = useState(false);

  const [
    inputCustomSaleUnitCorrespondanceUnit,
    setInputCustomSaleUnitCorrespondanceUnit,
  ] = useState("");

  const [
    inputCustomSaleUnitCorrespondanceUnitError,
    setInputCustomSaleUnitCorrespondanceUnitError,
  ] = useState(false);

  const [addToProductionUnits, setAddToProductionUnits] = useState(true);

  const [addFailed, setAddFailed] = useState("");

  const isUnitDisabled = (unit: string) => {
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const isUnitDisabledForEdit = (unit: string) => {
    if (props.inputProductionUnits.length + props.inputSaleUnits.length <= 1)
      return false;
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputSaleUnitError(false);
    setInputCustomSaleUnitError(false);
    setInputCustomSaleUnitCorrespondanceValueError(false);
    setInputCustomSaleUnitCorrespondanceUnitError(false);
  };

  const validateForm = () => {
    if (
      props.inputSaleUnits.some(
        (saleUnit) =>
          (inputSaleUnit === "Personnalisée"
            ? inputCustomSaleUnit
            : inputSaleUnit) === saleUnit.name
      )
    ) {
      setAddFailed(
        "L'unité de vente \"" +
          (inputSaleUnit === "Personnalisée"
            ? inputCustomSaleUnit
            : inputSaleUnit) +
          '" existe déjà!'
      );
      return false;
    }

    if (
      props.inputProductionUnits.some(
        (productionUnit) =>
          (inputSaleUnit === "Personnalisée"
            ? inputCustomSaleUnit
            : inputSaleUnit) === productionUnit.name
      ) &&
      addToProductionUnits
    ) {
      setAddFailed(
        "L'unité de production \"" +
          (inputSaleUnit === "Personnalisée"
            ? inputCustomSaleUnit
            : inputSaleUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de production!"
      );
      return false;
    }
    setInputSaleUnitError(false);
    setInputCustomSaleUnitError(false);
    setInputCustomSaleUnitCorrespondanceValueError(false);
    setInputCustomSaleUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputSaleUnit === "") {
      setInputSaleUnitError(true);
      result = false;
    }
    if (inputSaleUnit === "Personnalisée" && inputCustomSaleUnit === "") {
      setInputCustomSaleUnitError(true);
      result = false;
    }
    if (
      inputSaleUnit === "Personnalisée" &&
      inputCustomSaleUnitCorrespondanceValue === ""
    ) {
      setInputCustomSaleUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputSaleUnit === "Personnalisée" &&
      inputCustomSaleUnitCorrespondanceUnit === ""
    ) {
      setInputCustomSaleUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (saleUnit: Unit) => {
    if (
      props.inputSaleUnits
        .filter((unit) => unit.id !== saleUnit.id)
        .some(
          (unit) =>
            (inputSaleUnit === "Personnalisée"
              ? inputCustomSaleUnit
              : inputSaleUnit) === unit.name
        )
    ) {
      setAddFailed(
        "L'unité de vente\"" +
          (inputSaleUnit === "Personnalisée"
            ? inputCustomSaleUnit
            : inputSaleUnit) +
          '" existe déjà!'
      );
      return false;
    }

    setInputSaleUnitError(false);
    setInputCustomSaleUnitError(false);
    setInputCustomSaleUnitCorrespondanceValueError(false);
    setInputCustomSaleUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputSaleUnit === "") {
      setInputSaleUnitError(true);
      result = false;
    }
    if (inputSaleUnit === "Personnalisée" && inputCustomSaleUnit === "") {
      setInputCustomSaleUnitError(true);
      result = false;
    }
    if (
      inputSaleUnit === "Personnalisée" &&
      inputCustomSaleUnitCorrespondanceValue === ""
    ) {
      setInputCustomSaleUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputSaleUnit === "Personnalisée" &&
      inputCustomSaleUnitCorrespondanceUnit === ""
    ) {
      setInputCustomSaleUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const addUnitWithChildren = (
    unit: Unit,
    targetUnits: Unit[],
    setTargetUnits: (units: Unit[]) => void
  ) => {
    const newUnits = [...targetUnits];

    const addMissingChildUnits = (unit: Unit) => {
      if (unit.correspondanceUnit) {
        const childUnitExists = newUnits.some(
          (existingUnit) => existingUnit.name === unit.correspondanceUnit!.name
        );

        if (!childUnitExists) {
          newUnits.push({
            id: uuid(),
            name: unit.correspondanceUnit.name,
            correspondanceValue: unit.correspondanceUnit.correspondanceValue,
            correspondanceUnit: unit.correspondanceUnit.correspondanceUnit,
            baseUnit: unit.correspondanceUnit.baseUnit,
          });
          addMissingChildUnits(unit.correspondanceUnit);
        }
      }
    };

    addMissingChildUnits(unit);

    newUnits.push(unit);
    setTargetUnits(newUnits);
  };

  useEffect(() => {
    if (inputSaleUnit !== "Personnalisée") {
      setInputCustomSaleUnit("");
      setInputCustomSaleUnitCorrespondanceValue("");
      setInputCustomSaleUnitCorrespondanceUnit("");
    }
  }, [inputSaleUnit]);

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0" id="saleUnitsTopId">
        <Text16 textAlign="left" margin="0">
          Unités de vente
        </Text16>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setSaleUnitEditActive("");
            setInputSaleUnit("");
            setInputCustomSaleUnit("");
            setInputCustomSaleUnitCorrespondanceValue("");
            setInputCustomSaleUnitCorrespondanceUnit("");
            props.setSaleUnitAddActive(true);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une unité de vente
          </Text16>
        </FlexContainer>
      </FlexContainer>
      <Collapse orientation="vertical" in={props.saleUnitAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputSaleUnitError}
              >
                <InputLabel id="unitLabel">Unité</InputLabel>
                <Select
                  labelId="unitLabel"
                  id="unit"
                  required
                  inputRef={props.inputRef}
                  open={props.saleUnitOpen}
                  onClose={() => props.setSaleUnitOpen(false)}
                  onOpen={() => props.setSaleUnitOpen(true)}
                  value={inputSaleUnit}
                  label="Unité"
                  onChange={(e) => setInputSaleUnit(e.target.value)}
                >
                  <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                    Kg
                  </MenuItem>
                  <MenuItem value="g" disabled={isUnitDisabled("g")}>
                    g
                  </MenuItem>
                  <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                    mg
                  </MenuItem>
                  <MenuItem value="L" disabled={isUnitDisabled("L")}>
                    L
                  </MenuItem>
                  <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                    mL
                  </MenuItem>
                  <MenuItem value="Unité" disabled={isUnitDisabled("Unité")}>
                    Unité
                  </MenuItem>
                  <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Nom personnalisé de votre unité"
              >
                <TextField
                  fullWidth
                  disabled={inputSaleUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputSaleUnit !== "Personnalisée" ? "#e8e8e8" : "white",
                  }}
                  error={inputCustomSaleUnitError}
                  variant="outlined"
                  name="label"
                  label="Libellé"
                  value={inputCustomSaleUnit}
                  onChange={(e) => setInputCustomSaleUnit(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Valeur de votre unité personnalisée en unité internationale"
              >
                <TextField
                  fullWidth
                  disabled={inputSaleUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputSaleUnit !== "Personnalisée" ? "#e8e8e8" : "white",
                  }}
                  error={inputCustomSaleUnitCorrespondanceValueError}
                  variant="outlined"
                  name="customUnitSaleCorrespondanceValue"
                  label="Valeur de correspondance"
                  value={inputCustomSaleUnitCorrespondanceValue}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputCustomSaleUnitCorrespondanceValue(e.target.value);
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Unité internationale utilisée pour calculer la valeur de correspondance"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={inputCustomSaleUnitCorrespondanceUnitError}
                >
                  <InputLabel id="customSaleUnitCorrespondanceUnitLabel">
                    Unité de correspondance
                  </InputLabel>
                  <Select
                    disabled={inputSaleUnit !== "Personnalisée"}
                    sx={{
                      backgroundColor:
                        inputSaleUnit !== "Personnalisée" ? "#e8e8e8" : "white",
                    }}
                    labelId="customSaleUnitCorrespondanceUnitLabel"
                    id="customSaleUnitCorrespondanceUnit"
                    required
                    value={inputCustomSaleUnitCorrespondanceUnit}
                    label="Unité de correspondance"
                    onChange={(e) =>
                      setInputCustomSaleUnitCorrespondanceUnit(e.target.value)
                    }
                  >
                    <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                      Kg
                    </MenuItem>
                    <MenuItem value="g" disabled={isUnitDisabled("g")}>
                      g
                    </MenuItem>
                    <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                      mg
                    </MenuItem>
                    <MenuItem value="L" disabled={isUnitDisabled("L")}>
                      L
                    </MenuItem>
                    <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                      mL
                    </MenuItem>
                    <MenuItem value="Unité" disabled={isUnitDisabled("Unité")}>
                      Unité
                    </MenuItem>
                    {props.inputSaleUnits
                      .filter((unit) => !baseUnits.includes(unit.name))
                      .map((unit) => (
                        <MenuItem value={unit.id}>{unit.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <FlexContainer flexDirection="column" padding="8px 0 0 16px">
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToProductionUnits}
                  onChange={(event) => {
                    setAddToProductionUnits(!addToProductionUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités de production
                </Text16>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                const newUnit: Unit = {
                  id: uuid(),
                  name:
                    inputSaleUnit === "Personnalisée"
                      ? inputCustomSaleUnit
                      : inputSaleUnit,
                  correspondanceValue:
                    inputCustomSaleUnitCorrespondanceValue !== ""
                      ? Number(inputCustomSaleUnitCorrespondanceValue)
                      : 1,
                  correspondanceUnit: getUnitById(
                    inputCustomSaleUnitCorrespondanceUnit,
                    props.inputSaleUnits
                  ),
                  baseUnit:
                    getUnitById(
                      inputCustomSaleUnitCorrespondanceUnit,
                      props.inputSaleUnits
                    )?.baseUnit ??
                    (inputCustomSaleUnitCorrespondanceUnit !== ""
                      ? inputCustomSaleUnitCorrespondanceUnit
                      : inputSaleUnit),
                };

                props.setInputSaleUnits([...props.inputSaleUnits, newUnit]);

                if (addToProductionUnits) {
                  addUnitWithChildren(
                    newUnit,
                    props.inputProductionUnits,
                    props.setInputProductionUnits
                  );
                }
                setAddToProductionUnits(true);
                props.setSaleUnitAddActive(false);
                setInputSaleUnit("");
                setInputCustomSaleUnit("");
                setInputCustomSaleUnitCorrespondanceValue("");
                setInputCustomSaleUnitCorrespondanceUnit("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              props.setSaleUnitAddActive(false);
              setInputSaleUnit("");
              setInputCustomSaleUnit("");
              setInputCustomSaleUnitCorrespondanceValue("");
              setInputCustomSaleUnitCorrespondanceUnit("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {props.inputSaleUnits.map((saleUnit, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            border="1px solid rgba(0,0,0,0.1)"
            margin="8px 0"
            elevation={3}
            $borderRadius="10px"
            padding="8px 8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text16>{getUnitDescription(saleUnit)}</Text16>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setSaleUnitAddActive(false);
                  setInputSaleUnit(
                    isBaseUnit(saleUnit) ? saleUnit.name : "Personnalisée"
                  );
                  setInputCustomSaleUnit(
                    isBaseUnit(saleUnit) ? "" : saleUnit.name
                  );
                  setInputCustomSaleUnitCorrespondanceValue(
                    saleUnit.correspondanceValue.toString()
                  );
                  setInputCustomSaleUnitCorrespondanceUnit(
                    isBaseUnit(saleUnit)
                      ? ""
                      : saleUnit.correspondanceUnit?.id ?? saleUnit.baseUnit
                  );
                  setSaleUnitEditActive(saleUnit.id);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                disabled={props.inputSaleUnits.some(
                  (parentUnit) =>
                    parentUnit.correspondanceUnit?.id === saleUnit.id
                )}
                onClick={() => {
                  let newArr = [...props.inputSaleUnits];
                  newArr.splice(index, 1);
                  props.setInputSaleUnits(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          <Collapse
            orientation="vertical"
            in={saleUnit.id === saleUnitEditActive}
          >
            <BlockContainer margin="8px 0">
              {addFailed !== "" && (
                <BlockContainer margin="8px 0 16px 0">
                  <Alert severity="error">{addFailed}</Alert>
                </BlockContainer>
              )}
              <FlexContainer>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={inputSaleUnitError}
                    >
                      <InputLabel id="unitLabel">Unité</InputLabel>
                      <Select
                        labelId="unitLabel"
                        id="unit"
                        required
                        value={inputSaleUnit}
                        label="Unité"
                        onChange={(e) => setInputSaleUnit(e.target.value)}
                      >
                        <MenuItem
                          value="Kg"
                          disabled={isUnitDisabledForEdit("Kg")}
                        >
                          Kg
                        </MenuItem>
                        <MenuItem
                          value="g"
                          disabled={isUnitDisabledForEdit("g")}
                        >
                          g
                        </MenuItem>
                        <MenuItem
                          value="mg"
                          disabled={isUnitDisabledForEdit("mg")}
                        >
                          mg
                        </MenuItem>
                        <MenuItem
                          value="L"
                          disabled={isUnitDisabledForEdit("L")}
                        >
                          L
                        </MenuItem>
                        <MenuItem
                          value="mL"
                          disabled={isUnitDisabledForEdit("mL")}
                        >
                          mL
                        </MenuItem>
                        <MenuItem
                          value="Unité"
                          disabled={isUnitDisabledForEdit("Unité")}
                        >
                          Unité
                        </MenuItem>
                        <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Nom personnalisé de votre unité"
                    >
                      <TextField
                        fullWidth
                        disabled={inputSaleUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputSaleUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomSaleUnitError}
                        variant="outlined"
                        name="label"
                        label="Libellé"
                        value={inputCustomSaleUnit}
                        onChange={(e) => setInputCustomSaleUnit(e.target.value)}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Valeur de votre unité personnalisée en unité internationale"
                    >
                      <TextField
                        fullWidth
                        disabled={inputSaleUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputSaleUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomSaleUnitCorrespondanceValueError}
                        variant="outlined"
                        name="customUnitSaleCorrespondanceValue"
                        label="Valeur de correspondance"
                        value={inputCustomSaleUnitCorrespondanceValue}
                        onChange={(e) => {
                          if (
                            /^\d*\.?\d*$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setInputCustomSaleUnitCorrespondanceValue(
                              e.target.value
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Unité internationale utilisée pour calculer la valeur de correspondance"
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={inputCustomSaleUnitCorrespondanceUnitError}
                      >
                        <InputLabel id="customSaleUnitCorrespondanceUnitLabel">
                          Unité de correspondance
                        </InputLabel>
                        <Select
                          disabled={inputSaleUnit !== "Personnalisée"}
                          sx={{
                            backgroundColor:
                              inputSaleUnit !== "Personnalisée"
                                ? "#e8e8e8"
                                : "white",
                          }}
                          labelId="customSaleUnitCorrespondanceUnitLabel"
                          id="customSaleUnitCorrespondanceUnit"
                          required
                          value={inputCustomSaleUnitCorrespondanceUnit}
                          label="Unité de correspondance"
                          onChange={(e) =>
                            setInputCustomSaleUnitCorrespondanceUnit(
                              e.target.value
                            )
                          }
                        >
                          <MenuItem
                            value="Kg"
                            disabled={isUnitDisabledForEdit("Kg")}
                          >
                            Kg
                          </MenuItem>
                          <MenuItem
                            value="g"
                            disabled={isUnitDisabledForEdit("g")}
                          >
                            g
                          </MenuItem>
                          <MenuItem
                            value="mg"
                            disabled={isUnitDisabledForEdit("mg")}
                          >
                            mg
                          </MenuItem>
                          <MenuItem
                            value="L"
                            disabled={isUnitDisabledForEdit("L")}
                          >
                            L
                          </MenuItem>
                          <MenuItem
                            value="mL"
                            disabled={isUnitDisabledForEdit("mL")}
                          >
                            mL
                          </MenuItem>
                          <MenuItem
                            value="Unité"
                            disabled={isUnitDisabledForEdit("Unité")}
                          >
                            Unité
                          </MenuItem>
                          {props.inputSaleUnits
                            .filter(
                              (unit) =>
                                unit.id !== saleUnitEditActive &&
                                !baseUnits.includes(unit.name)
                            )
                            .map((unit) => (
                              <MenuItem
                                value={unit.id}
                                disabled={isParentOf(
                                  unit,
                                  getUnitById(
                                    saleUnitEditActive,
                                    props.inputSaleUnits
                                  )!
                                )}
                              >
                                {unit.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FlexContainer>
              <FlexContainer justifyContent="center">
                <ButtonMui
                  margin="16px 4px 0 0"
                  onClick={() => {
                    if (validateEditForm(saleUnit)) {
                      let tmpArray = [...props.inputSaleUnits];
                      tmpArray[index] = {
                        id: saleUnit.id,
                        name:
                          inputSaleUnit === "Personnalisée"
                            ? inputCustomSaleUnit
                            : inputSaleUnit,
                        correspondanceValue:
                          inputCustomSaleUnitCorrespondanceValue !== ""
                            ? Number(inputCustomSaleUnitCorrespondanceValue)
                            : 1,
                        correspondanceUnit: getUnitById(
                          inputCustomSaleUnitCorrespondanceUnit,
                          props.inputSaleUnits
                        ),
                        baseUnit:
                          getUnitById(
                            inputCustomSaleUnitCorrespondanceUnit,
                            props.inputSaleUnits
                          )?.baseUnit ??
                          (inputCustomSaleUnitCorrespondanceUnit !== ""
                            ? inputCustomSaleUnitCorrespondanceUnit
                            : inputSaleUnit),
                      };

                      props.setInputSaleUnits(tmpArray);
                      props.setSaleUnitAddActive(false);
                      setSaleUnitEditActive("");
                      setInputSaleUnit("");
                      setInputCustomSaleUnit("");
                      setInputCustomSaleUnitCorrespondanceValue("");
                      setInputCustomSaleUnitCorrespondanceUnit("");
                      resetValidation();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Modifier
                </ButtonMui>
                <ButtonMui
                  margin="16px 0 0 4px"
                  onClick={() => {
                    setSaleUnitEditActive("");
                    setInputSaleUnit("");
                    setInputCustomSaleUnit("");
                    setInputCustomSaleUnitCorrespondanceValue("");
                    setInputCustomSaleUnitCorrespondanceUnit("");
                    resetValidation();
                  }}
                  color="primary"
                  variant="outlined"
                  size="large"
                >
                  Annuler
                </ButtonMui>
              </FlexContainer>
            </BlockContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default SaleUnits;
