import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useState } from "react";

export interface AutocompleteProps {
  name: string;
  label: string;
  inputExpression: string;
  setInputExpression: React.Dispatch<React.SetStateAction<string>>;
  suggestions: string[];
}

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid #999",
    padding: "0.5rem",
    width: "300px",
  },

  noSuggestions: {
    color: "#999",
    padding: "0.5rem",
  },

  suggestions: {
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%),0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    borderTopWidth: 0,
    listStyle: "none",
    marginTop: 0,
    maxHeight: "143px",
    overflowY: "auto",
    paddingLeft: 0,
    margin: "0 0 0 8px",
    width: "calc(300px + 1rem)",
    "& li": {
      padding: "0.5rem",
    },
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
  },
  activeSuggestion: {
    "&, li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
  },
}));

const AutocompleteCustom: React.FC<AutocompleteProps> = ({
  name,
  label,
  inputExpression,
  setInputExpression,
  suggestions,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [autocompleteStartIndex, setAutocompleteStartIndex] = useState(0);
  const [selectionStart, setSelectionStart] = useState(0);
  const [focused, setFocused] = useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const classes = useStyles();

  let onChange = (e) => {
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    let suggested: string[] = [];

    for (let i = 0; i < selectionStart + 1; i++) {
      suggested = suggestions.filter((suggestion) =>
        suggestion
          .toLowerCase()
          .startsWith(userInput.slice(i, selectionStart + 1).toLowerCase())
      );

      if (suggested.length) {
        setAutocompleteStartIndex(i);
        break;
      }
    }

    setActiveSuggestion(0);
    setFilteredSuggestions(suggested);
    setShowSuggestions(true);
    setInputExpression(e.currentTarget.value);
  };

  let onClick = (e) => {
    const innerText: string = e.currentTarget.innerText;
    const innerTextLength = innerText.length;
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setInputExpression(
      inputExpression.slice(0, autocompleteStartIndex) +
        innerText +
        inputExpression.slice(selectionStart)
    );
    setTimeout(function () {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        inputExpression.slice(0, autocompleteStartIndex).length +
          innerTextLength,
        inputExpression.slice(0, autocompleteStartIndex).length +
          innerTextLength
      );
    }, 100);
  };

  let onKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setInputExpression(
        inputExpression.slice(0, autocompleteStartIndex) +
          filteredSuggestions[activeSuggestion] +
          inputExpression.slice(selectionStart)
      );
      setTimeout(function () {
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(
          inputExpression.slice(0, autocompleteStartIndex).length +
            filteredSuggestions[activeSuggestion].length,
          inputExpression.slice(0, autocompleteStartIndex).length +
            filteredSuggestions[activeSuggestion].length
        );
      }, 100);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && inputExpression) {
    if (filteredSuggestions.length && focused) {
      suggestionsListComponent = (
        <ul className={classes.suggestions}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = classes.activeSuggestion;
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = <div className={classes.noSuggestions}></div>;
    }
  }

  return (
    <Fragment>
      <TextField
        variant="outlined"
        name={name}
        label={label}
        inputRef={inputRef}
        onSelect={() => setSelectionStart(inputRef.current!.selectionStart!)}
        onFocus={(e) => setFocused(true)}
        onBlur={(e) => {
          setTimeout(function () {
            setFocused(false);
          }, 100);
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={inputExpression}
        style={{ width: "80%", margin: "8px 8px 0 8px" }}
      />
      {suggestionsListComponent}
    </Fragment>
  );
};

export default AutocompleteCustom;
