import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import ContactSection from "../ContactSection";
import Footer from "../Footer";
import Header from "../Header";

const faqData = [
  {
    question: "Qu'est-ce qu'un ERP et en quoi peut-il aider mon entreprise ?",
    answer:
      "Un ERP (Enterprise Resource Planning) est un logiciel qui centralise la gestion des processus d'une entreprise (ventes, finances, ressources humaines, production, etc.). Il permet d'améliorer la productivité, d'éviter la duplication des données et d'optimiser la prise de décision.",
  },
  {
    question: "Quelle est la différence entre un ERP et un CRM ?",
    answer:
      "Un ERP gère l’ensemble des processus internes d’une entreprise, alors qu’un CRM (Customer Relationship Management) est spécifiquement conçu pour optimiser la gestion de la relation client, améliorer les ventes et le suivi des interactions commerciales.",
  },
  {
    question: "Mon entreprise est-elle trop petite pour utiliser un ERP ?",
    answer:
      "Non, un ERP peut être adapté à toutes les tailles d'entreprise. Il existe des solutions modulaires qui permettent aux petites structures de bénéficier d'une gestion optimisée sans investissements lourds.",
  },
  {
    question: "Quels sont les avantages d'un CRM pour mon équipe commerciale ?",
    answer:
      "Un CRM permet d'automatiser certaines tâches (relances, suivi des prospects), d’avoir une meilleure visibilité sur les opportunités de vente et d’améliorer la personnalisation de la relation client pour augmenter le taux de conversion.",
  },
  {
    question: "Mon ERP CRM est-il accessible en ligne et sur mobile ?",
    answer:
      "Oui, notre solution est disponible en mode SaaS, accessible via un navigateur web et optimisée pour une utilisation sur mobile et tablette, vous permettant de gérer votre activité partout et à tout moment.",
  },
  {
    question: "Comment l’ERP facilite-t-il la gestion des factures et devis ?",
    answer:
      "Notre ERP génère automatiquement des devis et factures à partir des commandes clients. Vous pouvez suivre les paiements, relancer les clients en retard et exporter les données pour la comptabilité.",
  },
  {
    question: "Comment suivre en temps réel l’état des stocks ?",
    answer:
      "Notre ERP vous permet de visualiser l’état des stocks en direct, de gérer les réapprovisionnements et d’éviter les ruptures grâce aux alertes de seuil critique.",
  },
  {
    question: "Combien d’utilisateurs peuvent utiliser l’ERP en même temps ?",
    answer:
      "Il n’y a pas de limite ! Notre ERP est conçu pour supporter un grand nombre d’utilisateurs simultanément avec des performances optimales.",
  },
];

const Faq: React.FC = () => {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <BlockContainer position="relative" overflow="hidden">
      <Header />
      <Box
        sx={{
          padding: { xs: "40px 16px", md: "80px 128px" },
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          marginBottom={4}
        >
          Foire aux questions
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {faqData.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                disableGutters
                sx={{
                  boxShadow: "none",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    fontWeight: "bold",
                    background: expanded === index ? "#fff" : "#F9F3EE",
                  }}
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#fff" }}>
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ContactSection />
      <Footer />
    </BlockContainer>
  );
};

export default Faq;
