import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../../models/employee";
import { Supplier } from "../../../../../../models/suppliers";
import { Dispatch, RootState } from "../../../../../../store";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { getEmployees, getSuppliers } from "../../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { getEmployeeDescription } from "../../../../../Reusable/Utils";

export interface InformationsProps {
  supplier: Supplier;
  suppliers: Supplier[];
  employees: Employee[];
  getSuppliersAction: typeof getSuppliersAction;
  getEmployeesAction: typeof getEmployeesAction;
}

const _Informations: React.FC<InformationsProps> = (props) => {
  useEffect(() => {
    props.getSuppliersAction("");
    props.getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer padding="16px">
      <FlexContainer width="100%">
        <BlockContainer width="100%">
          <Text16 fontWeight="500">Informations générales</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Nom</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.name}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">N° fournisseur</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.supplierNumber}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Catégorie</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.category}</Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Commandes</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Email</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.email}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Commande minimum</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.minimumOrder + " €"}</Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Suivi</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Employés en charge</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.supplier?.inCharge && props.supplier.inCharge.length > 0
                  ? props.supplier.inCharge
                      .map((employee) => getEmployeeDescription(employee))
                      .join(", ")
                  : "-"}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Superviseurs</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.supplier?.responsibles &&
                props.supplier.responsibles.length > 0
                  ? props.supplier.responsibles
                      .map((employee) => getEmployeeDescription(employee))
                      .join(", ")
                  : "-"}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Livraisons</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Lead time</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.leadTime + " Jour(s)"}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Jours de livraison</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.supplier?.openWeekDays.join(", ")}</Text14>
            </FlexContainer>
          </FlexContainer>
        </BlockContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export const Informations = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_Informations);

export default _Informations;
