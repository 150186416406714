import { Ingredient } from "../models/ingredients";
import { Material } from "../models/materials";
import { StorageArea } from "../models/storageArea";
import { Supplier } from "../models/suppliers";
import { AxiosHttpClient } from "./AxiosHttpService";

export class StockService {
  static getIngredients = (input: any) => {
    return AxiosHttpClient.get<Ingredient[]>(
      "api/v1/weeventpro/ingredients",
      input
    );
  };

  static getMaterials = (input: any) => {
    return AxiosHttpClient.get<Material[]>(
      "api/v1/weeventpro/materials",
      input
    );
  };

  static getStorageAreas = (input: any) => {
    return AxiosHttpClient.get<StorageArea[]>(
      "api/v1/weeventpro/storageAreas",
      input
    );
  };

  static getSuppliers = (input: any) => {
    return AxiosHttpClient.get<Supplier[]>(
      "api/v1/weeventpro/suppliers",
      input
    );
  };
}
