import { SupplierOrder } from "../../models/supplierOrder";
import { SupplierOrderArchive } from "../../models/supplierOrderArchive";

export const GET_SUPPLIER_ORDERS = "GET_SUPPLIER_ORDERS" as const;
export const GET_SUPPLIER_ORDERS_SUCCESS =
  "GET_SUPPLIER_ORDERS_SUCCESS" as const;
export const GET_SUPPLIER_ORDERS_FAILURE =
  "GET_SUPPLIER_ORDERS_FAILURE" as const;

export const GET_SUPPLIER_ORDERS_ARCHIVE =
  "GET_SUPPLIER_ORDERS_ARCHIVE" as const;
export const GET_SUPPLIER_ORDERS_ARCHIVE_SUCCESS =
  "GET_SUPPLIER_ORDERS_ARCHIVE_SUCCESS" as const;
export const GET_SUPPLIER_ORDERS_ARCHIVE_FAILURE =
  "GET_SUPPLIER_ORDERS_ARCHIVE_FAILURE" as const;

export const getSupplierOrdersAction = (input: string) => ({
  type: GET_SUPPLIER_ORDERS,
  input: input,
});
export type GetSupplierOrdersAction = ReturnType<
  typeof getSupplierOrdersAction
>;

export const getSupplierOrdersActionSuccess = (
  supplierOrders: SupplierOrder[]
) => ({
  type: GET_SUPPLIER_ORDERS_SUCCESS,
  supplierOrders: supplierOrders,
});
export type GetSupplierOrdersActionSuccess = ReturnType<
  typeof getSupplierOrdersActionSuccess
>;

export const getSupplierOrdersActionFailure = () => ({
  type: GET_SUPPLIER_ORDERS_FAILURE,
});
export type GetSupplierOrdersActionFailure = ReturnType<
  typeof getSupplierOrdersActionFailure
>;

export const getSupplierOrdersArchiveAction = (input: string) => ({
  type: GET_SUPPLIER_ORDERS_ARCHIVE,
  input: input,
});
export type GetSupplierOrdersArchiveAction = ReturnType<
  typeof getSupplierOrdersArchiveAction
>;

export const getSupplierOrdersArchiveActionSuccess = (
  supplierOrdersArchive: SupplierOrderArchive[]
) => ({
  type: GET_SUPPLIER_ORDERS_ARCHIVE_SUCCESS,
  supplierOrdersArchive: supplierOrdersArchive,
});
export type GetSupplierOrdersArchiveActionSuccess = ReturnType<
  typeof getSupplierOrdersArchiveActionSuccess
>;

export const getSupplierOrdersArchiveActionFailure = () => ({
  type: GET_SUPPLIER_ORDERS_ARCHIVE_FAILURE,
});
export type GetSupplierOrdersArchiveActionFailure = ReturnType<
  typeof getSupplierOrdersArchiveActionFailure
>;
