import { createFilterOptions } from "@mui/core";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { File } from "../../../../../models/file";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  VACATION_FORMAT,
  VACATION_INCREMENT,
  VACATION_NUMBER_OF_DIGITS,
  calculateWorkingDays,
  getEmployeeDescription,
  getUserParameterValueByName,
  trimLongString,
} from "../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

const Input = styled("input")({
  display: "none",
});

export interface AddVacationProps {
  popupActive: boolean;
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  actionsOnAdd?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _AddVacation: React.FC<AddVacationProps> = ({
  popupActive,
  employees,
  getEmployeesAction,
  setPopupActive,
  actionsOnAdd,
}) => {
  /* TODO */
  const addVacation = () => {
    AxiosHttpClient.post("api/v1/weeventpro/vacations", {
      id: "",
      vacationNumber: inputVacationNumber,
      startDate: inputStartDate
        ? dayjs(inputStartDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      endDate: inputEndDate
        ? dayjs(inputEndDate).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      vacationDuration: inputVacationDuration,
      paidLeave: isNaN(Number(inputPaidLeave)) ? 0 : Number(inputPaidLeave),
      rtt: isNaN(Number(inputRtt)) ? 0 : Number(inputRtt),
      sickLeave: isNaN(Number(inputSickLeave)) ? 0 : Number(inputSickLeave),
      maternityLeave: isNaN(Number(inputMaternityLeave))
        ? 0
        : Number(inputMaternityLeave),
      unpaidLeave: isNaN(Number(inputUnpaidLeave))
        ? 0
        : Number(inputUnpaidLeave),
      publicHoliday: isNaN(Number(inputPublicHoliday))
        ? 0
        : Number(inputPublicHoliday),
      recoveryLeave: isNaN(Number(inputRecoveryLeave))
        ? 0
        : Number(inputRecoveryLeave),
      supervisorId: inputSupervisor?.id,
      copiesToIds: inputCopiesTo.map((responsible) => responsible.id),
      comment: inputComment,
      status: "En attente",
      justificationFiles: inputJustifications,
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addVacationDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/vacation"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        VACATION_FORMAT +
        "," +
        VACATION_NUMBER_OF_DIGITS +
        "," +
        VACATION_INCREMENT,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(
        VACATION_INCREMENT,
        res
      );
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(VACATION_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        VACATION_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputVacationNumber(invoiceNumber);
    });
  };

  const validateForm = () => {
    setInputStartDateError(false);
    setInputEndDateError(false);
    setInputSupervisorError(false);
    let result = true;
    if (inputStartDate === null) {
      setInputStartDateError(true);
      result = false;
    }
    if (inputEndDate === null) {
      setInputEndDateError(true);
      result = false;
    }
    if (inputSupervisor === null) {
      setInputSupervisorError(true);
      result = false;
    }
    if (!sumVacationRepartitionValid()) {
      setAddFailed(
        "la répartition des congés ne matche pas la durée du congé!"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addVacationDialogContentId",
      });
      return false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addVacationDialogContentId",
      });
    }
    return result;
  };

  const sumVacationRepartitionValid = () => {
    return (
      Number(inputPaidLeave) +
        Number(inputRtt) +
        Number(inputSickLeave) +
        Number(inputMaternityLeave) +
        Number(inputUnpaidLeave) +
        Number(inputPublicHoliday) +
        Number(inputRecoveryLeave) ===
      Number(inputVacationDuration)
    );
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputJustifications([...(inputJustifications ?? []), ...fileObjs]);
      });
    }
  };

  let removeFile = (index: number) => {
    let newArr = [...inputJustifications];
    newArr.splice(index, 1);
    setInputJustifications(newArr);
  };

  const [inputVacationNumber, setInputVacationNumber] = useState("");

  const [inputStartDate, setInputStartDate] = useState<Dayjs | null>(dayjs());

  const [inputStartDateError, setInputStartDateError] = useState(false);

  const [inputEndDate, setInputEndDate] = useState<Dayjs | null>(null);

  const [inputEndDateError, setInputEndDateError] = useState(false);

  const [inputVacationDuration, setInputVacationDuration] = useState("");

  const [inputPaidLeave, setInputPaidLeave] = useState("");

  const [inputRtt, setInputRtt] = useState("");

  const [inputSickLeave, setInputSickLeave] = useState("");

  const [inputMaternityLeave, setInputMaternityLeave] = useState("");

  const [inputUnpaidLeave, setInputUnpaidLeave] = useState("");

  const [inputPublicHoliday, setInputPublicHoliday] = useState("");

  const [inputRecoveryLeave, setInputRecoveryLeave] = useState("");

  const [inputSupervisor, setInputSupervisor] = useState<Employee | null>(null);

  const [inputSupervisorError, setInputSupervisorError] = useState(false);

  const [inputCopiesTo, setInputCopiesTo] = useState<Employee[]>([]);

  const [inputJustifications, setInputJustifications] = useState<File[]>([]);

  const [inputComment, setInputComment] = useState("");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterEmployeesOptions = createFilterOptions<Employee>({
    stringify: (option) => getEmployeeDescription(option),
    limit: 20,
  });

  useEffect(() => {
    if (!inputStartDate || !inputEndDate) setInputVacationDuration("");
    else
      setInputVacationDuration(
        calculateWorkingDays(inputStartDate, inputEndDate).toString()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDate, inputEndDate]);

  useEffect(() => {
    getEmployeesAction("");
    getCustomFields();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter un congé"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addVacationDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Dates du congé
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de début"
                  inputFormat="DD/MM/YYYY"
                  value={inputStartDate}
                  onChange={(value) => setInputStartDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputStartDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de fin"
                  inputFormat="DD/MM/YYYY"
                  value={inputEndDate}
                  onChange={(value) => setInputEndDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputEndDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                name="vacationDuration"
                label="Durée du congé"
                value={inputVacationDuration}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputVacationDuration(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  if (
                    Number(inputVacationDuration) % 1 === 0 &&
                    Number(inputVacationDuration) >= 1
                  )
                    setInputVacationDuration(
                      (Number(inputVacationDuration) - 0.5).toString()
                    );
                }}
              >
                Soustraire une demi-journée
              </ButtonMui>
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Répartition
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="paidLeave"
              label="Congé payé"
              value={inputPaidLeave}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputPaidLeave(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="rtt"
              label="Congé RTT"
              value={inputRtt}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputRtt(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="sickLeave"
              label="Arrêt maladie"
              value={inputSickLeave}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSickLeave(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="maternityLeave"
              label="Congé maternité"
              value={inputMaternityLeave}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputMaternityLeave(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="unpaidLeave"
              label="Congé sans solde"
              value={inputUnpaidLeave}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputUnpaidLeave(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="publicHoliday"
              label="Jours fériés"
              value={inputPublicHoliday}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputPublicHoliday(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="recoveryLeave"
              label="Congé de récupération"
              value={inputRecoveryLeave}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputRecoveryLeave(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Approbation
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="supervisor"
              filterOptions={filterEmployeesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return option.firstName + " " + option.lastName;
              }}
              options={employees}
              value={inputSupervisor}
              onChange={(event, newValue) => {
                setInputSupervisor(newValue!);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer flex="4">
                    <Text14 textAlign="left">
                      {option.firstName + " " + option.lastName}
                    </Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={inputSupervisorError}
                  variant="outlined"
                  name="supervisor"
                  label="Superviseur"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="copiesTo"
              filterOptions={filterEmployeesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return option.firstName + " " + option.lastName;
              }}
              options={employees}
              value={inputCopiesTo}
              onChange={(event, newValue) => {
                setInputCopiesTo(newValue!);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer flex="4">
                    <Text14 textAlign="left">
                      {option.firstName + " " + option.lastName}
                    </Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="copiesTo"
                  label="En copie"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={2}>
            <FlexContainer
              border="2px dashed rgba(0,0,0,0.1)"
              margin={"0 0 8px 0"}
              alignItems={"center"}
            >
              <Grid container flex={1}>
                {inputJustifications!.map((justification, index) => (
                  <Grid>
                    <FlexContainer
                      elevation={3}
                      margin="8px"
                      height="40px"
                      justifyContent="space-between"
                      alignItems="center"
                      $borderRadius="16px"
                    >
                      <FlexContainer margin="0 0 0 8px" alignItems="center">
                        <AttachFileRoundedIcon
                          fontSize="medium"
                          color="primary"
                        />
                        <Text14
                          cursor="pointer"
                          margin="0 4px"
                          color={CustomTheme.palette.secondary.main}
                          textDecorationLine="underline"
                          onClick={(e) => {
                            fetch(justification.url)
                              .then((response) => response.blob())
                              .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                  "download",
                                  justification.name
                                );
                                document.body.appendChild(link);
                                link.click();
                              });
                          }}
                        >
                          {trimLongString(justification.name)}
                        </Text14>
                      </FlexContainer>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          removeFile(index);
                        }}
                        size="medium"
                        style={{
                          margin: "0 8px",
                          padding: "4px",
                          zIndex: "1",
                        }}
                      >
                        <CloseIcon fontSize="medium" />
                      </IconButton>
                    </FlexContainer>
                  </Grid>
                ))}
                {inputJustifications.length === 0 && (
                  <FlexContainer
                    margin="8px"
                    height="40px"
                    alignItems={"center"}
                  >
                    <Text16 color="rgba(0,0,0,0.5)">
                      Aucun fichier sélectionné
                    </Text16>
                  </FlexContainer>
                )}
              </Grid>
              <FlexContainer marginRight={"8px"}>
                <label htmlFor="contained-button-file">
                  <Input
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      filesPreviewHandler(e.target.files);
                    }}
                  />
                  <IconButton component="span">
                    <AttachFileRoundedIcon fontSize="medium" />
                  </IconButton>
                </label>
              </FlexContainer>
            </FlexContainer>
          </Grid>
          <Grid item xs={1} sm={1} md={2}>
            <TextField
              fullWidth
              variant="outlined"
              name="comment"
              label="Commentaire"
              multiline
              rows={4}
              value={inputComment}
              onChange={(e) => {
                setInputComment(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addVacation();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddVacation = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_AddVacation);

export default AddVacation;
