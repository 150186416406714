import AddIcon from "@mui/icons-material/Add";
import { DialogContentText, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../../../../store";
import { getRoutesAction } from "../../../../../../store/Route/actions";
import { getRoutes } from "../../../../../../store/selectors";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";

const Input = styled("input")({
  display: "none",
});

export interface UploadFileProps {
  inputTemplate: any;
  importDataFromDB: boolean;
  setInputTemplate: React.Dispatch<any>;
  setImportDataFromDB: React.Dispatch<React.SetStateAction<boolean>>;
}

const _UploadFile: React.FC<UploadFileProps> = (props) => {
  const [fileName, setFileName] = useState("");

  return (
    <React.Fragment>
      <DialogContentText textAlign="center">
        Vous pouvez télécharger un exemple de fichier d'importation:{" "}
        <Typography
          variant="subtitle1"
          color="secondary"
          display="inline"
          component="span"
          sx={{
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
          }}
        >
          <a
            href={
              "https://" +
              process.env.REACT_APP_BUCKET_NAME! +
              ".s3.eu-west-3.amazonaws.com/Samples/sample.xlsx"
            }
            download
            style={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            {"Excel"}
          </a>
        </Typography>
        {" - "}
        <Typography
          variant="subtitle1"
          color="secondary"
          display="inline"
          component="span"
          sx={{
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
          }}
        >
          <a
            href={
              "https://" +
              process.env.REACT_APP_BUCKET_NAME! +
              ".s3.eu-west-3.amazonaws.com/Samples/sample.csv"
            }
            download
            style={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            {"Csv"}
          </a>
        </Typography>
      </DialogContentText>
      <FlexContainer alignItems="center" flexDirection="column" margin="16px 0">
        <label htmlFor="contained-button-file">
          <Input
            accept=".xls,.xlsx"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e) => {
              props.setInputTemplate(e.target.files![0]);
              setFileName(e.target.files![0].name);
            }}
          />
          <ButtonMui
            color="secondary"
            variant="contained"
            size="large"
            $borderRadius="50px"
            padding="8px 22px 8px 12px"
            component="span"
            margin="0 0 0 15px"
          >
            <AddIcon fontSize="medium" />
            Choisir votre document
          </ButtonMui>
        </label>
        <Text16>{fileName}</Text16>
      </FlexContainer>
      <FlexContainer
        flexDirection="column"
        border={"1px solid rgba(0,0,0,0.2)"}
        padding="16px"
        $borderRadius="4px"
      >
        <Text14>
          - Les noms des champs doivent être identiques à ceux mentionnés dans
          le fichier d'exemple et respecter la casse.
        </Text14>
        <Text14>
          - La catégorie doit faire partie de l'ensemble des catégories définies
          et respecter la casse.
        </Text14>
        <Text14>
          - L'email mentionné doit avoir un format d'email correct.
        </Text14>
        <Text14>
          - Les employés mentionnés (Employés en charge et superviseurs) doivent
          exister préalablement dans votre liste d'employés et respecter la
          casse.
        </Text14>
        <Text14>
          - Les jours de livraisons doivent fair partie de la liste: Lundi,
          Mardi, Mercredi, Jeudi, Vendredi, Samedi, Dimanche et respecter la
          casse.
        </Text14>
      </FlexContainer>
    </React.Fragment>
  );
};

export const UploadFile = connect(
  (state: RootState) => ({
    routes: getRoutes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getRoutesAction: getRoutesAction,
      },
      dispatch
    )
)(_UploadFile);

export default UploadFile;
