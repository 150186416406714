import { RootAction } from "../types";
import {
  GET_UPFRONT_INVOICES_SUCCESS,
  GET_UPFRONT_INVOICE_TEMPLATES_SUCCESS,
} from "./actions";
import { UpfrontInvoiceState, UpfrontInvoiceTemplatesState } from "./types";

const initialUpfrontInvoiceState: UpfrontInvoiceState = {
  upfrontInvoices: [],
};

const initialUpfrontInvoiceTemplatesState: UpfrontInvoiceTemplatesState = {
  templates: [],
};

export function upfrontInvoiceReducer(
  state: UpfrontInvoiceState = initialUpfrontInvoiceState,
  action: RootAction
): UpfrontInvoiceState {
  switch (action.type) {
    case GET_UPFRONT_INVOICES_SUCCESS:
      return {
        upfrontInvoices: action.upfrontInvoices,
      };

    default:
      return state;
  }
}

export function upfrontInvoiceTemplateReducer(
  state: UpfrontInvoiceTemplatesState = initialUpfrontInvoiceTemplatesState,
  action: RootAction
): UpfrontInvoiceTemplatesState {
  switch (action.type) {
    case GET_UPFRONT_INVOICE_TEMPLATES_SUCCESS:
      return {
        templates: action.templates,
      };

    default:
      return state;
  }
}
