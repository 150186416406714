import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Contact } from "../../../../../models/contact";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish, SaleOption } from "../../../../../models/dish";
import { Ingredient } from "../../../../../models/ingredients";
import { AdditionalFee, Invoice } from "../../../../../models/invoice";
import { Material } from "../../../../../models/materials";
import { Sale, SoldItem } from "../../../../../models/sale";
import { Service, ServiceSaleOption } from "../../../../../models/service";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getInvoicesAction } from "../../../../../store/Invoice/actions";
import {
  getClients,
  getDishes,
  getIngredients,
  getMaterials,
  getServices,
} from "../../../../../store/selectors";
import { getServicesAction } from "../../../../../store/Service/actions";
import {
  getIngredientsAction,
  getMaterialsAction,
} from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  CREDIT_NOTE_FORMAT,
  CREDIT_NOTE_NUMBER_OF_DIGITS,
  INVOICE_FORMAT,
  INVOICE_INCREMENT,
  INVOICE_NUMBER_OF_DIGITS,
  UPFRONT_INVOICE_FORMAT,
  UPFRONT_INVOICE_NUMBER_OF_DIGITS,
  getContactDescription,
  getDishBySaleOptionId,
  getHTPriceFromTTC,
  getIngredientBySaleOptionId,
  getItemCode,
  getMaterialBySaleOptionId,
  getServiceBySaleOptionId,
  getSoldItemsFromSales,
  getTTCPriceFromHT,
  getUserParameterValueByName,
  isDateValid,
  toFixed2,
  toFixed2Number,
} from "../../../../Reusable/Utils";

export interface AddInvoiceProps {
  popupActive: boolean;
  operationType?: string;
  type?: string;
  client?: Contact;
  invoice?: Invoice;
  sale?: Sale;
  ingredients: Ingredient[];
  materials: Material[];
  dishes: Dish[];
  services: Service[];
  clients: Contact[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
  getMaterialsAction: typeof getMaterialsAction;
  getDishesAction: typeof getDishesAction;
  getServicesAction: typeof getServicesAction;
  getClientsAction: typeof getClientsAction;
  actionsOnAdd?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _AddInvoice: React.FC<AddInvoiceProps> = ({
  popupActive,
  operationType,
  type,
  client,
  invoice,
  sale,
  ingredients,
  materials,
  dishes,
  services,
  clients,
  setPopupActive,
  getIngredientsAction,
  getMaterialsAction,
  getDishesAction,
  getServicesAction,
  getClientsAction,
  actionsOnAdd,
}) => {
  /* TODO */
  const addInvoice = () => {
    let totalGlobalHt = getGlobalTotalHt();
    let totalGlobalTTC = getGlobalTotalTtc();
    let totalPriceHT = getTotalHt();
    let totalPriceTTC = getTotalTtc();
    AxiosHttpClient.post("api/v1/weeventpro/invoices", {
      id: "",
      invoiceNumber: inputInvoiceNumber,
      description: inputDescription,
      clientId: inputClient?.id,
      soldItems: inputSoldItems,
      emailSent: false,
      date: dayjs(inputInvoiceDate),
      dueDate: dayjs(inputDueDate),
      paymentMethods: inputPaymentMethods,
      associatedSalesIds: sale ? [sale.id] : sales.map((sale) => sale.id),
      additionalFees: inputAdditionalFees,
      comments: inputComments,
      discount: inputDiscount,
      discountCalculation: inputDiscountCalculation,
      documentUrl: "",
      associatedUpfrontInvoicesIds: inputAssociatedUpfrontInvoices.map(
        (upfrontInvoice) => upfrontInvoice.id
      ),
      associatedCreditNotesIds: [],
      associatedInvoiceId:
        invoice && invoice.type === "Facture" ? invoice.id : null,
      type: inputType,
      payments: [],
      totalPriceHT: totalPriceHT,
      totalPriceTVA: toFixed2(
        parseFloat(totalPriceTTC) - parseFloat(totalPriceHT)
      ),
      totalPriceTTC: totalPriceTTC,
      remainingPriceHT: totalPriceHT,
      remainingPriceTVA: toFixed2(
        parseFloat(totalPriceTTC) - parseFloat(totalPriceHT)
      ),
      remainingPriceTTC: totalPriceTTC,
      upfrontTotalHT: totalGlobalHt,
      upfrontTotalTVA: toFixed2(totalGlobalTTC - totalGlobalHt),
      upfrontTotalTTC: totalGlobalTTC,
      globalTVA:
        totalGlobalTTC && totalGlobalHt
          ? toFixed2((totalGlobalTTC / totalGlobalHt - 1) * 100)
          : inputUpfrontCreditNoteTVA,
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addInvoiceDialogContentId",
        });
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        INVOICE_FORMAT +
        "," +
        INVOICE_NUMBER_OF_DIGITS +
        "," +
        UPFRONT_INVOICE_FORMAT +
        "," +
        UPFRONT_INVOICE_NUMBER_OF_DIGITS +
        "," +
        CREDIT_NOTE_FORMAT +
        "," +
        CREDIT_NOTE_NUMBER_OF_DIGITS +
        "," +
        INVOICE_INCREMENT,
    }).then((res) => {
      let format =
        inputType === "Facture"
          ? INVOICE_FORMAT
          : inputType === "Acompte"
          ? UPFRONT_INVOICE_FORMAT
          : CREDIT_NOTE_FORMAT;
      let numberOfDigits =
        inputType === "Facture"
          ? INVOICE_NUMBER_OF_DIGITS
          : inputType === "Acompte"
          ? UPFRONT_INVOICE_NUMBER_OF_DIGITS
          : CREDIT_NOTE_NUMBER_OF_DIGITS;
      let incrementString = getUserParameterValueByName(INVOICE_INCREMENT, res);
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(numberOfDigits, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(format, res)
        .replace(/%increment%/g, incrementPadding)
        .replace(/%year%/g, dayjs().tz("Europe/Paris").format("YYYY"))
        .replace(/%month%/g, dayjs().tz("Europe/Paris").format("MM"));

      setInputInvoiceNumber(invoiceNumber);
    });
  };

  const getClientSales = () => {
    if (client) {
      AxiosHttpClient.get<Sale[]>("api/v1/weeventpro/sales", {
        criteriaList:
          "client_id=" +
          client.id +
          ",calculatedStatus=Validée,deliveryDate=" +
          dayjs(inputStartDate).format("DD/MM/YYYY") +
          "-" +
          dayjs(inputEndDate).format("DD/MM/YYYY"),
      })
        .then((res) => {
          setSales(res);
          setInputSoldItems(getSoldItemsFromSales(res));
        })
        .catch((err) => {});
    }
  };

  const getUpfrontInvoices = () => {
    AxiosHttpClient.get<Invoice[]>("api/v1/weeventpro/invoices", {
      criteriaList: "type=Acompte",
    })
      .then((res) => {
        setUpfrontInvoices(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/invoice"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getInvoicesLastDate = () => {
    AxiosHttpClient.get<string>("api/v1/weeventpro/invoices/lastDate").then(
      (res) => {
        setLastInvoiceDate(dayjs(res));
        if (dayjs(res).isAfter(dayjs())) {
          setInputInvoiceDate(dayjs(res));
        }
      }
    );
  };

  const validateAddSoldItemForm = () => {
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
    setAddSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOption === null) {
      setInputSaleOptionNameError(true);
      result = false;
    }
    if (inputSaleOptionQuantity === "") {
      setInputSaleOptionQuantityError(true);
      result = false;
    }
    if (!result) {
      setAddSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditSoldItemForm = () => {
    setInputSaleOptionQuantityEditError(false);
    setEditSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOptionQuantityEdit === "") {
      setInputSaleOptionQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateAddAdditionalFeeForm = () => {
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeePriceTTCError(false);
    setInputAdditionalFeeTvaError(false);
    setAddAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHT) *
            (1 + Number(inputAdditionalFeeTva) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTC)
    ) {
      setAddAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeeDescription === "") {
      setInputAdditionalFeeDescriptionError(true);
      result = false;
    }
    if (inputAdditionalFeePriceHT === "") {
      setInputAdditionalFeePriceHTError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTC === "") {
      setInputAdditionalFeePriceTTCError(true);
      result = false;
    }
    if (inputAdditionalFeeTva === "") {
      setInputAdditionalFeeTvaError(true);
      result = false;
    }
    if (!result) {
      setAddAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditAdditionalFeeForm = () => {
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setEditAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHTEdit) *
            (1 + Number(inputAdditionalFeeTvaEdit) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTCEdit)
    ) {
      setEditAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeePriceHTEdit === "") {
      setInputAdditionalFeePriceHTEditError(true);
      result = false;
    }
    if (inputAdditionalFeeTvaEdit === "") {
      setInputAdditionalFeeTvaEditError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTCEdit === "") {
      setInputAdditionalFeePriceTTCEditError(true);
      result = false;
    }
    if (!result) {
      setEditAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputDescriptionError(false);
    setInputInvoiceDateError(false);
    setInputClientError(false);
    setInputUpfrontAmountError(false);
    setInputCreditNoteAmountError(false);
    setInputUpfrontTVAError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (inputClient === null) {
      setInputClientError(true);
      result = false;
    }
    if (inputType === "Acompte" && inputUpfrontAmount === "") {
      setInputUpfrontAmountError(true);
      result = false;
    }
    if (
      !isDateValid(inputInvoiceDate) ||
      inputInvoiceDate!.isBefore(lastInvoiceDate, "day")
    ) {
      setAddFailed("Date invalide!");
      setInputInvoiceDateError(true);
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInvoiceDialogContentId",
      });
      return false;
    }
    if (inputSoldItems.length === 0 && inputType === "Facture") {
      setAddFailed("Vous n'avez aucun produit vendu pour cette facture!");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInvoiceDialogContentId",
      });
      return false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInvoiceDialogContentId",
      });
    }
    return result;
  };

  const resetForm = () => {
    setAddSaleOptionItemFailed("");
    setInputSaleOption(null);
    setInputSaleOptionName("");
    setInputSaleOptionQuantity("");
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
  };

  const resetAdditionalFeeForm = () => {
    setAddAdditionalFeeItemFailed("");
    setInputAdditionalFeeDescription("");
    setInputAdditionalFeePriceHT("");
    setInputAdditionalFeeTva("");
    setInputAdditionalFeePriceTTC("");
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeeTvaError(false);
    setInputAdditionalFeePriceTTCError(false);
  };

  const resetEditForm = () => {
    setEditSaleOptionItemFailed("");
    setInputSaleOptionQuantityEdit("");
    setInputSaleOptionQuantityEditError(false);
  };

  const resetAdditionalFeeEditForm = () => {
    setEditAdditionalFeeItemFailed("");
    setInputAdditionalFeePriceHTEdit("");
    setInputAdditionalFeeTvaEdit("");
    setInputAdditionalFeePriceTTCEdit("");
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  const paymentMethodsList = [
    "Espèces",
    "Chèque",
    "Virement bancaire",
    "Carte de crédit",
    "Carte de débit",
    "PayPal",
    "Portefeuille numérique",
    "Prélèvement automatique",
  ];

  const getClient = () => {
    if (invoice) {
      return invoice.client;
    } else if (client) {
      return client;
    } else if (sale) {
      return sale.client;
    }
    return null;
  };

  const getSoldItems = () => {
    if (invoice) {
      return invoice.soldItems.map((soldItem) => ({
        ...soldItem,
        id: uuid(),
        quantity: type === "Avoir" ? -soldItem.quantity : soldItem.quantity,
      }));
    } else if (sale) {
      return sale.soldItems.map((soldItem) => ({
        ...soldItem,
        id: uuid(),
        quantity: soldItem.quantity,
      }));
    }
    return [];
  };

  const getAdditionalFees = () => {
    if (invoice) {
      return invoice.additionalFees.map((additionalFee) => ({
        ...additionalFee,
        id: uuid(),
        priceHT:
          type === "Avoir" ? -additionalFee.priceHT : additionalFee.priceHT,
      }));
    }
    if (sale && sale.associatedUpfrontInvoices[0]) {
      return sale.associatedUpfrontInvoices[0].additionalFees.map(
        (additionalFee) => ({
          ...additionalFee,
          id: uuid(),
          priceHT:
            type === "Avoir" ? -additionalFee.priceHT : additionalFee.priceHT,
        })
      );
    }
    return [];
  };

  const [inputType, setInputType] = useState(type ? type : "Facture");

  const [sales, setSales] = useState<Sale[]>([]);

  const [inputStartDate, setInputStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "month").startOf("month")
  );

  const [inputEndDate, setInputEndDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "month").endOf("month")
  );

  const [inputDescription, setInputDescription] = useState("");

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [inputInvoiceNumber, setInputInvoiceNumber] = useState("");

  const [inputInvoiceDate, setInputInvoiceDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputInvoiceDateError, setInputInvoiceDateError] = useState(false);

  const [inputDueDate, setInputDueDate] = useState<Dayjs | null>(null);

  const [inputPaymentMethods, setInputPaymentMethods] = useState<string[]>([]);

  const [inputComments, setInputComments] = useState("");

  const [inputClient, setInputClient] = useState<Contact | null>(getClient());

  const [inputClientError, setInputClientError] = useState(false);

  const [upfrontInvoices, setUpfrontInvoices] = useState<Invoice[]>([]);

  const [inputAssociatedUpfrontInvoices, setInputAssociatedUpfrontInvoices] =
    useState<Invoice[]>([]);

  const [
    inputAssociatedUpfrontInvoicesText,
    setInputAssociatedUpfrontInvoicesText,
  ] = useState("");

  const [inputClientText, setInputClientText] = useState("");

  const [inputSoldItems, setInputSoldItems] = useState<SoldItem[]>(
    getSoldItems()
  );

  const [inputAdditionalFees, setInputAdditionalFees] = useState<
    AdditionalFee[]
  >(getAdditionalFees());

  const [saleOptionsTmp, setSaleOptionsTmp] = useState<
    (SaleOption | ServiceSaleOption)[]
  >([]);

  const [inputSaleOptionName, setInputSaleOptionName] = useState("");

  const [inputAdditionalFeeDescription, setInputAdditionalFeeDescription] =
    useState("");

  const [inputSaleOption, setInputSaleOption] = React.useState<
    SaleOption | ServiceSaleOption | null
  >(null);

  const [inputSaleOptionQuantity, setInputSaleOptionQuantity] = useState("");

  const [inputAdditionalFeePriceHT, setInputAdditionalFeePriceHT] =
    useState("");

  const [inputAdditionalFeeTva, setInputAdditionalFeeTva] = useState("");

  const [inputAdditionalFeePriceTTC, setInputAdditionalFeePriceTTC] =
    useState("");

  const [inputSaleOptionQuantityEdit, setInputSaleOptionQuantityEdit] =
    useState("");

  const [inputAdditionalFeePriceHTEdit, setInputAdditionalFeePriceHTEdit] =
    useState("");

  const [inputAdditionalFeeTvaEdit, setInputAdditionalFeeTvaEdit] =
    useState("");

  const [inputAdditionalFeePriceTTCEdit, setInputAdditionalFeePriceTTCEdit] =
    useState("");

  const [inputSaleOptionNameError, setInputSaleOptionNameError] =
    useState(false);

  const [
    inputAdditionalFeeDescriptionError,
    setInputAdditionalFeeDescriptionError,
  ] = useState(false);

  const [inputAdditionalFeeTvaError, setInputAdditionalFeeTvaError] =
    useState(false);

  const [inputAdditionalFeePriceTTCError, setInputAdditionalFeePriceTTCError] =
    useState(false);

  const [inputSaleOptionQuantityError, setInputSaleOptionQuantityError] =
    useState(false);

  const [inputAdditionalFeePriceHTError, setInputAdditionalFeePriceHTError] =
    useState(false);

  const [inputDiscount, setInputDiscount] = useState(
    invoice
      ? toFixed2(invoice.discount)
      : sale && sale.associatedUpfrontInvoices[0]
      ? toFixed2(sale.associatedUpfrontInvoices[0].discount)
      : ""
  );

  const [inputDiscountCalculation, setInputDiscountCalculation] = useState(
    invoice ? invoice.discountCalculation : "percentage"
  );

  const [inputUpfrontAmount, setInputUpfrontAmount] = useState("");

  const [inputUpfrontAmountError, setInputUpfrontAmountError] = useState(false);

  const [inputUpfrontAmountCalculation, setInputUpfrontAmountCalculation] =
    useState("percentage");

  const [inputCreditNoteAmount, setInputCreditNoteAmount] = useState(
    invoice ? toFixed2(invoice.totalPriceHT) : ""
  );

  const [inputCreditNoteAmountError, setInputCreditNoteAmountError] =
    useState(false);

  const [
    inputCreditNoteAmountCalculation,
    setInputCreditNoteAmountCalculation,
  ] = useState("valueHT");

  const [addSaleOptionItemFailed, setAddSaleOptionItemFailed] = useState("");

  const [addAdditionalFeeItemFailed, setAddAdditionalFeeItemFailed] =
    useState("");

  const [
    inputSaleOptionQuantityEditError,
    setInputSaleOptionQuantityEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceHTEditError,
    setInputAdditionalFeePriceHTEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceTTCEditError,
    setInputAdditionalFeePriceTTCEditError,
  ] = useState(false);

  const [inputAdditionalFeeTvaEditError, setInputAdditionalFeeTvaEditError] =
    useState(false);

  const [editSaleOptionItemFailed, setEditSaleOptionItemFailed] = useState("");

  const [editAdditionalFeeItemFailed, setEditAdditionalFeeItemFailed] =
    useState("");

  const [inputUpfrontCreditNoteTVA, setInputUpfrontCreditNoteTVA] =
    useState("");

  const [inputUpfrontTVAError, setInputUpfrontTVAError] = useState(false);

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [saleOptionItemEditActive, setSaleOptionItemEditActive] = useState("");

  const [additionalFeeItemEditActive, setAdditionalFeeItemEditActive] =
    useState("");

  const [lastInvoiceDate, setLastInvoiceDate] = useState<Dayjs | null>(null);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  let removeSaleOptionItem = (index: number) => {
    let newArr = [...inputSoldItems];
    newArr.splice(index, 1);
    setInputSoldItems(newArr);
  };

  let removeAdditionalFeeItem = (index: number) => {
    let newArr = [...inputAdditionalFees];
    newArr.splice(index, 1);
    setInputAdditionalFees(newArr);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterOptions = createFilterOptions<Contact>({
    stringify: (option) => getContactDescription(option),
  });

  const filterSaleOptionOptions = (
    options: (SaleOption | ServiceSaleOption)[],
    { inputValue }
  ) => {
    if (inputValue === null || inputValue === undefined) return [];
    const searchTokens = inputValue.toLowerCase().split(" ");
    return options
      .filter((option) =>
        searchTokens.every((token) => option.name.toLowerCase().includes(token))
      )
      .slice(0, 20);
  };

  const filterAssociatedUpfontInvicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
  });

  // Fonction utilitaire pour ajouter des parenthèses autour des valeurs négatives
  const formatWithParentheses = (value) => {
    const number = toFixed2(value);
    return value < 0 ? `(${number})` : number;
  };

  const getTotalHtText = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = (totalHt * parseFloat(inputDiscount)) / 100;
        if (inputType === "Avoir") {
          discountHT = -discountHT;
        }
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return (
      toFixed2(totalHt) +
      (inputDiscount === ""
        ? ""
        : " - " +
          formatWithParentheses(
            inputType === "Avoir" ? -discountHT : discountHT
          ) +
          " = " +
          toFixed2(
            totalHt - (inputType === "Avoir" ? -discountHT : discountHT)
          )) +
      " €"
    );
  };

  const getGlobalTotalHt = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = (totalHt * parseFloat(inputDiscount)) / 100;
        if (inputType === "Avoir") {
          discountHT = -discountHT;
        }
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return totalHt - (inputType === "Avoir" ? -discountHT : discountHT);
  };

  const getTotalHt = () => {
    if (inputType === "Acompte") {
      if (inputUpfrontAmountCalculation === "percentage") {
        return toFixed2(
          (parseFloat(inputUpfrontAmount) / 100) * getGlobalTotalHt()
        );
      } else if (inputUpfrontAmountCalculation === "valueHT") {
        return toFixed2(parseFloat(inputUpfrontAmount));
      } else if (inputUpfrontAmountCalculation === "valueTTC") {
        return toFixed2(
          (100 * parseFloat(inputUpfrontAmount)) /
            (100 + parseFloat(inputUpfrontCreditNoteTVA))
        );
      }
    }
    if (inputType === "Avoir") {
      if (inputCreditNoteAmountCalculation === "percentage") {
        return toFixed2(
          -(parseFloat(inputCreditNoteAmount) / 100) * getGlobalTotalHt()
        );
      } else if (inputCreditNoteAmountCalculation === "valueHT") {
        return toFixed2(-parseFloat(inputCreditNoteAmount));
      } else if (inputCreditNoteAmountCalculation === "valueTTC") {
        return toFixed2(
          -(100 * parseFloat(inputCreditNoteAmount)) /
            (100 + parseFloat(inputUpfrontCreditNoteTVA))
        );
      }
    }

    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        return acc + priceHT;
      },
      0
    );

    return toFixed2(getGlobalTotalHt() - totalUpfrontInvoiceAmounts);
  };

  const getTotalTTCBeforeDiscount = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    const totalTTC = totalSoldItems + totalAdditionalFees;

    return toFixed2(totalTTC);
  };

  const getTotalTTCText = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = (totalTTC * parseFloat(inputDiscount)) / 100;
        if (inputType === "Avoir") {
          discountTTC = -discountTTC;
        }
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return (
      toFixed2(totalTTC) +
      (inputDiscount === ""
        ? ""
        : " - " +
          formatWithParentheses(
            inputType === "Avoir" ? -discountTTC : discountTTC
          ) +
          " = " +
          toFixed2(
            totalTTC - (inputType === "Avoir" ? -discountTTC : discountTTC)
          )) +
      " €"
    );
  };

  const getTotalTtc = () => {
    if (inputType === "Acompte") {
      if (inputUpfrontAmountCalculation === "percentage") {
        let upfrontHt = toFixed2Number(
          (parseFloat(inputUpfrontAmount) / 100) * getGlobalTotalHt()
        );
        return toFixed2(
          upfrontHt + (upfrontHt * parseFloat(inputUpfrontCreditNoteTVA)) / 100
        );
      } else if (inputUpfrontAmountCalculation === "valueTTC") {
        return toFixed2(parseFloat(inputUpfrontAmount));
      } else if (inputUpfrontAmountCalculation === "valueHT") {
        return toFixed2(
          parseFloat(inputUpfrontAmount) +
            (parseFloat(inputUpfrontAmount) *
              parseFloat(inputUpfrontCreditNoteTVA)) /
              100
        );
      }
    }

    if (inputType === "Avoir") {
      if (inputCreditNoteAmountCalculation === "percentage") {
        let creditNoteHt = toFixed2Number(
          (parseFloat(inputCreditNoteAmount) / 100) * getGlobalTotalHt()
        );
        return toFixed2(
          -(
            creditNoteHt +
            (creditNoteHt * parseFloat(inputUpfrontCreditNoteTVA)) / 100
          )
        );
      } else if (inputCreditNoteAmountCalculation === "valueTTC") {
        return toFixed2(-parseFloat(inputCreditNoteAmount));
      } else if (inputCreditNoteAmountCalculation === "valueHT") {
        return toFixed2(
          -(
            parseFloat(inputCreditNoteAmount) +
            (parseFloat(inputCreditNoteAmount) *
              parseFloat(inputUpfrontCreditNoteTVA)) /
              100
          )
        );
      }
    }

    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceTTC = upfrontInvoice.totalPriceTTC;
        return acc + priceTTC;
      },
      0
    );

    return toFixed2(getGlobalTotalTtc() - totalUpfrontInvoiceAmounts);
  };

  const getGlobalTotalTtc = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem!.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = (totalTTC * parseFloat(inputDiscount)) / 100;
        if (inputType === "Avoir") {
          discountTTC = -discountTTC;
        }
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return totalTTC - (inputType === "Avoir" ? -discountTTC : discountTTC);
  };

  const getTotalHtBeforeDiscount = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    const totalHt = totalSoldItems + totalAdditionalFees;
    return toFixed2(totalHt);
  };

  const getRemainingPriceHTText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        return acc + priceHT;
      },
      0
    );
    const totalHtBeforeUpfront = getGlobalTotalHt();

    return (
      toFixed2(totalHtBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalHtBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  const getRemainingPriceTTCText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        const tvaRate = upfrontInvoice.globalTVA / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );
    const totalTTCBeforeUpfront = getGlobalTotalTtc();

    return (
      toFixed2(totalTTCBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalTTCBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  useEffect(() => {
    getIngredientsAction("");
    getMaterialsAction("");
    getDishesAction("");
    getServicesAction("");
    getClientsAction("");
    getInvoicesAction("");
    getUpfrontInvoices();
    getInvoicesLastDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getIngredientsAction,
    getMaterialsAction,
    getDishesAction,
    getServicesAction,
    getClientsAction,
    getInvoicesAction,
  ]);

  useEffect(() => {
    getUserParameters();

    setInputSoldItems(
      inputSoldItems.map((soldItem) => ({
        ...soldItem,
        quantity:
          inputType === "Avoir"
            ? Math.abs(soldItem.quantity) * -1
            : Math.abs(soldItem.quantity),
      }))
    );

    setInputAdditionalFees(
      inputAdditionalFees.map((additionalFee) => ({
        ...additionalFee,
        priceHT:
          inputType === "Avoir"
            ? Math.abs(additionalFee.priceHT) * -1
            : Math.abs(additionalFee.priceHT),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType]);

  useEffect(() => {
    let saleOptions: (SaleOption | ServiceSaleOption)[] = [];

    for (var ingredient of ingredients) {
      ingredient.saleOptions.forEach((saleOption) =>
        saleOptions.push(saleOption)
      );
    }

    for (var material of materials) {
      material.saleOptions.forEach((saleOption) =>
        saleOptions.push(saleOption)
      );
    }

    for (var dish of dishes) {
      dish.saleOptions.forEach((saleOption) => saleOptions.push(saleOption));
    }

    for (var service of services) {
      service.saleOptions.forEach((saleOption) => saleOptions.push(saleOption));
    }

    if (saleOptions[0] !== undefined) {
      setSaleOptionsTmp([...saleOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, materials, dishes, services]);

  useEffect(() => {
    let generatedDescription = "";

    // invoice
    if (invoice) {
      if (
        operationType &&
        [
          "INVOICE_INVOICE",
          "UPFRONT_UPFRONT",
          "CREDITNOTE_CREDITNOTE",
        ].includes(operationType)
      ) {
        setInputDescription(
          "Copie (" + invoice.invoiceNumber + ") - " + invoice.description
        );
      } else if (operationType && ["INVOICE_UPFRONT"].includes(operationType)) {
        generatedDescription +=
          "Facture suite à l'acompte " + invoice.invoiceNumber;
        if (invoice.client !== null)
          generatedDescription += " - " + getContactDescription(invoice.client);
        setInputDescription(generatedDescription);
      } else if (operationType && ["UPFRONT_INVOICE"].includes(operationType)) {
        generatedDescription +=
          "Acompte suite à la facture " + invoice.invoiceNumber;
        if (invoice.client !== null)
          generatedDescription += " - " + getContactDescription(invoice.client);
        setInputDescription(generatedDescription);
      } else if (
        operationType &&
        ["CREDITNOTE_INVOICE", "CREDITNOTE_UPFRONT"].includes(operationType)
      ) {
        generatedDescription +=
          (operationType === "INVOICE_UPFRONT" ? "Acompte" : "Avoir") +
          " sur " +
          invoice.invoiceNumber;
        if (invoice.client !== null)
          generatedDescription += " - " + getContactDescription(invoice.client);
        setInputDescription(generatedDescription);
      }
    }
    // client
    else if (client) {
      generatedDescription +=
        (operationType === "UPFRONT_CLIENT" ? "Acompte" : "Facture") +
        " du " +
        (inputStartDate ? dayjs(inputStartDate).format("DD/MM/YYYY") : "?") +
        " au " +
        (inputEndDate ? dayjs(inputEndDate).format("DD/MM/YYYY") : "?");
      if (client !== null)
        generatedDescription += " - " + getContactDescription(client);
      setInputDescription(generatedDescription);
    }
    // sale
    else if (sale) {
      generatedDescription +=
        (operationType === "UPFRONT_SALE" ? "Acompte" : "Facture") +
        " sur " +
        sale.saleNumber;
      if (sale.client !== null)
        generatedDescription += " - " + getContactDescription(sale.client);
      setInputDescription(generatedDescription);
    }
    // None
    else {
      if (inputClient !== null)
        generatedDescription +=
          (inputType === "Facture"
            ? "Facture - "
            : inputType === "Acompte"
            ? "Acompte - "
            : "Avoir - ") +
          getContactDescription(inputClient) +
          " - ";

      if (inputSoldItems.length > 0)
        generatedDescription += inputSoldItems
          .map((soldItem) => soldItem.quantity + ' "' + soldItem.name + '"')
          .join(", ");

      setInputDescription(generatedDescription);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputClient, inputSoldItems, inputStartDate, inputEndDate]);

  useEffect(() => {
    if (inputAssociatedUpfrontInvoices.length === 0) {
      if (invoice) {
        if (invoice.type === "Acompte") {
          setInputAssociatedUpfrontInvoices([
            invoice,
            ...invoice.associatedUpfrontInvoices,
          ]);
        }
        setInputAssociatedUpfrontInvoices([
          ...invoice.associatedUpfrontInvoices,
        ]);
      } else if (sales.length > 0) {
        setInputAssociatedUpfrontInvoices(
          sales
            .map((sale) => sale.associatedUpfrontInvoices)
            .reduce((accumulator, currentInvoices) => {
              currentInvoices.forEach((invoice) => {
                if (
                  !accumulator.some(
                    (existingInvoice) => existingInvoice.id === invoice.id
                  )
                ) {
                  accumulator.push(invoice);
                }
              });
              return accumulator;
            }, [])
        );
      } else if (sale) {
        setInputAssociatedUpfrontInvoices(sale.associatedUpfrontInvoices);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, sale, sales]);

  useEffect(() => {
    if (invoice) {
      setInputAdditionalFees(
        invoice.additionalFees.map((additionalFee) => ({
          ...additionalFee,
          id: uuid(),
          priceHT:
            inputType === "Avoir"
              ? -additionalFee.priceHT
              : additionalFee.priceHT,
        }))
      );
    } else if (sales.length > 0) {
      setInputAdditionalFees(
        sales[0].associatedUpfrontInvoices[0]?.additionalFees.map(
          (additionalFee) => ({
            ...additionalFee,
            id: uuid(),
            priceHT:
              inputType === "Avoir"
                ? -additionalFee.priceHT
                : additionalFee.priceHT,
          })
        ) || []
      );
    } else if (sale && sale.associatedUpfrontInvoices[0]) {
      setInputAdditionalFees(
        sale.associatedUpfrontInvoices[0].additionalFees.map(
          (additionalFee) => ({
            ...additionalFee,
            id: uuid(),
            priceHT:
              inputType === "Avoir"
                ? -additionalFee.priceHT
                : additionalFee.priceHT,
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, sale, sales]);

  useEffect(() => {
    if (inputStartDate === null || inputEndDate === null) setSales([]);
    else getClientSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDate, inputEndDate]);

  useEffect(() => {
    setInputUpfrontCreditNoteTVA(
      toFixed2((getGlobalTotalTtc() / getGlobalTotalHt() - 1) * 100)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSoldItems, inputAdditionalFees]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une facture"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addInvoiceDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputType}
                  label="Type"
                  onChange={(e) => {
                    setInputType(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Facture">Facture</MenuItem>
                  <MenuItem value="Acompte">Acompte</MenuItem>
                  <MenuItem value="Avoir">Avoir</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {client && (
              <>
                <Grid item xs={1} sm={1} md={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Du"
                      inputFormat="DD/MM/YYYY"
                      value={inputStartDate}
                      onChange={(value) => setInputStartDate(value!)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Au"
                      inputFormat="DD/MM/YYYY"
                      value={inputEndDate}
                      onChange={(value) => setInputEndDate(value!)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete
                id="clients"
                disabled={client ? true : false}
                filterOptions={filterOptions}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                getOptionLabel={(option) => {
                  return getContactDescription(option);
                }}
                options={clients}
                value={inputClient}
                onChange={(event, newValue) => {
                  setInputClient(newValue!);
                }}
                inputValue={inputClientText}
                onInputChange={(event, newInputValue) => {
                  setInputClientText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {getContactDescription(option)}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputClientError}
                    variant="outlined"
                    name="client"
                    label="Client"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="InvoiceNumber"
                label="Numéro de facture"
                disabled
                value={inputInvoiceNumber}
                onChange={(e) => {
                  setInputInvoiceNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                id="paymentMethods"
                disableCloseOnSelect
                options={paymentMethodsList}
                value={inputPaymentMethods}
                getOptionLabel={(option) => (option ? option : "")}
                onChange={(event, newValue) => {
                  setInputPaymentMethods(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Modes de paiement" />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de facturation"
                  inputFormat="DD/MM/YYYY"
                  minDate={lastInvoiceDate}
                  value={inputInvoiceDate}
                  onChange={(value) => setInputInvoiceDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputInvoiceDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date d'échéance"
                  inputFormat="DD/MM/YYYY"
                  value={inputDueDate}
                  onChange={(value) => setInputDueDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="comments"
                label="Notes/Commentaires"
                multiline
                rows={4}
                value={inputComments}
                onChange={(e) => {
                  setInputComments(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
        {inputType !== "Avoir" && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Produits vendus
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={2}>
                <Text14>
                  Sélectionnez les items vendus avec leurs quantités
                  respectives:
                </Text14>
              </Grid>
              {addSaleOptionItemFailed !== "" && (
                <Grid item xs={1} sm={1} md={2}>
                  <BlockContainer margin="0 0 8px 0">
                    <Alert severity="error">{addSaleOptionItemFailed}</Alert>
                  </BlockContainer>
                </Grid>
              )}

              <Grid item xs={1} sm={1} md={1}>
                <Autocomplete
                  id="saleOptions"
                  filterOptions={filterSaleOptionOptions}
                  options={saleOptionsTmp}
                  getOptionLabel={(option) => option.name}
                  value={inputSaleOption}
                  onChange={(event, newValue) => {
                    setInputSaleOption(newValue);
                  }}
                  inputValue={inputSaleOptionName}
                  onInputChange={(event, newInputValue) => {
                    setInputSaleOptionName(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        height: "100px",
                      }}
                      {...props}
                    >
                      <FlexContainer
                        flex="1"
                        justifyContent="center"
                        marginRight="16px"
                        maxWidth="90px"
                      >
                        <img
                          style={{
                            maxWidth: "90px",
                            maxHeight: "90px",
                          }}
                          loading="lazy"
                          src={option.imageUrl}
                          alt=""
                        />
                      </FlexContainer>
                      <FlexContainer>
                        <Text14 textAlign="left">{option.name}</Text14>
                      </FlexContainer>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="saleOption"
                      label="Produit"
                      error={inputSaleOptionNameError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ingredientQuantity"
                  label="Quantité"
                  error={inputSaleOptionQuantityError}
                  value={inputSaleOptionQuantity}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputSaleOptionQuantity(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                display="flex"
                justifyContent="center"
              >
                <FlexContainer>
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    onClick={() => {
                      if (validateAddSoldItemForm()) {
                        resetForm();
                        let item =
                          getDishBySaleOptionId(inputSaleOption!.id, dishes) ||
                          getServiceBySaleOptionId(
                            inputSaleOption!.id,
                            services
                          ) ||
                          getIngredientBySaleOptionId(
                            inputSaleOption!.id,
                            ingredients
                          ) ||
                          getMaterialBySaleOptionId(
                            inputSaleOption!.id,
                            materials
                          );
                        if (item) {
                          setInputSoldItems([
                            ...inputSoldItems,
                            {
                              id: uuid(),
                              name: item.name,
                              priceHT: inputSaleOption!.priceHT,
                              tva: inputSaleOption!.tva,
                              quantity: Number(inputSaleOptionQuantity),
                              internalCode: getItemCode(item),
                              imageUrl: inputSaleOption!.imageUrl,
                              dishServiceId: item.id,
                            },
                          ]);
                        }
                      }
                    }}
                  >
                    Ajouter
                  </ButtonMui>
                </FlexContainer>
              </Grid>

              <Grid item xs={1} sm={1} md={2}>
                <TableContainer sx={{ maxWidth: "100vw" }}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell key="image">Image</TableCell>
                        <TableCell key="product">Produit</TableCell>
                        <TableCell key="code">Code</TableCell>
                        <TableCell key="quantity">Quantité</TableCell>
                        <TableCell key="pu">P.U</TableCell>
                        <TableCell key="pht">Prix H.T</TableCell>
                        <TableCell key="tva">TVA</TableCell>
                        <TableCell key="pttc">Prix TTC</TableCell>
                        <TableCell key="actions">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inputSoldItems.length === 0 && (
                        <TableRow key="empty">
                          <TableCell colSpan={9}>
                            <FlexContainer width="100%" justifyContent="center">
                              <Text14 color="grey" opacity="80%">
                                Veuillez ajouter les produits vendus dans cette
                                vente!
                              </Text14>
                            </FlexContainer>
                          </TableCell>
                        </TableRow>
                      )}
                      {inputSoldItems.map((soldItem, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell>
                              <BlockContainer
                                sx={{
                                  width: {
                                    xs: "60px",
                                    sm: "60px",
                                  },
                                  height: {
                                    xs: "60px",
                                    sm: "60px",
                                  },
                                }}
                                border="rgba(0,0,0,0.1) solid 1px"
                                justifyContent="center"
                                position="relative"
                                textAlign="center"
                                backgroundColor="white"
                                margin="auto"
                              >
                                <img
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    margin: "auto",
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    bottom: "0",
                                    left: "0",
                                  }}
                                  alt=""
                                  src={soldItem.imageUrl}
                                />
                              </BlockContainer>
                            </TableCell>
                            <TableCell>{soldItem.name}</TableCell>
                            <TableCell>{soldItem.internalCode}</TableCell>
                            <TableCell>{soldItem.quantity}</TableCell>
                            <TableCell>{soldItem.priceHT + " €"}</TableCell>
                            <TableCell>
                              {toFixed2(
                                Number(soldItem.quantity) *
                                  Number(soldItem.priceHT)
                              ) + " €"}
                            </TableCell>
                            <TableCell>{soldItem.tva}</TableCell>
                            <TableCell>
                              {toFixed2(
                                (1 + Number(soldItem.tva) * 0.01) *
                                  Number(soldItem.quantity) *
                                  Number(soldItem.priceHT)
                              ) + " €"}
                            </TableCell>
                            <TableCell>
                              <FlexContainer justifyContent="center">
                                <IconButton
                                  color="primary"
                                  onClick={(e) => {
                                    setInputSaleOptionQuantityEdit(
                                      toFixed2(soldItem.quantity)
                                    );
                                    setSaleOptionItemEditActive(soldItem.id);
                                  }}
                                  size="large"
                                >
                                  <EditOutlinedIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    removeSaleOptionItem(index);
                                  }}
                                  size="large"
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </FlexContainer>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={"edit-" + index}
                            sx={{
                              display:
                                saleOptionItemEditActive === soldItem.id
                                  ? "table-row"
                                  : "none",
                            }}
                          >
                            <TableCell colSpan={9}>
                              <Collapse
                                orientation="vertical"
                                in={saleOptionItemEditActive === soldItem.id}
                              >
                                {saleOptionItemEditActive === soldItem.id && (
                                  <Grid
                                    container
                                    spacing={{ xs: 1, sm: 1, md: 2 }}
                                    columns={{ xs: 1, sm: 1, md: 2 }}
                                  >
                                    <Grid item xs={1} sm={1} md={2}>
                                      {editSaleOptionItemFailed !== "" && (
                                        <BlockContainer margin="0 0 8px 0">
                                          <Alert severity="error">
                                            {editSaleOptionItemFailed}
                                          </Alert>
                                        </BlockContainer>
                                      )}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={2}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="saleOptionQuantity"
                                        label="Quantité"
                                        error={inputSaleOptionQuantityEditError}
                                        value={inputSaleOptionQuantityEdit}
                                        onChange={(e) => {
                                          if (
                                            /^\d*\.?\d*$/.test(
                                              e.target.value
                                            ) ||
                                            e.target.value === ""
                                          ) {
                                            setInputSaleOptionQuantityEdit(
                                              e.target.value
                                            );
                                          }
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      sm={1}
                                      md={2}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <FlexContainer>
                                        <ButtonMui
                                          color="primary"
                                          variant="contained"
                                          size="large"
                                          disabled={isLoading}
                                          margin="0 4px 0 0"
                                          onClick={() => {
                                            if (validateEditSoldItemForm()) {
                                              resetEditForm();
                                              let tmpArray = [
                                                ...inputSoldItems,
                                              ];
                                              tmpArray[index] = {
                                                id: soldItem.id,
                                                name: soldItem.name,
                                                priceHT: soldItem.priceHT,
                                                tva: soldItem.tva,
                                                quantity: Number(
                                                  inputSaleOptionQuantityEdit
                                                ),
                                                internalCode:
                                                  soldItem.internalCode,
                                                imageUrl: soldItem.imageUrl,
                                                dishServiceId:
                                                  soldItem.dishServiceId,
                                              };
                                              setInputSoldItems(tmpArray);
                                              setSaleOptionItemEditActive("");
                                            }
                                          }}
                                        >
                                          Modifier
                                        </ButtonMui>
                                        <ButtonMui
                                          margin="0 0 0 4px"
                                          onClick={() => {
                                            resetEditForm();
                                            setSaleOptionItemEditActive("");
                                          }}
                                          color="primary"
                                          variant="outlined"
                                          size="large"
                                        >
                                          Annuler
                                        </ButtonMui>
                                      </FlexContainer>
                                    </Grid>
                                  </Grid>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Frais supplémentaires
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              <Grid item xs={1} sm={1} md={2}>
                <Text14>
                  Veuillez ajouter des frais supplémentaires pour cette facture
                  s'il en existe:
                </Text14>
              </Grid>
              {addAdditionalFeeItemFailed !== "" && (
                <Grid item xs={1} sm={1} md={2}>
                  <BlockContainer margin="0 0 8px 0">
                    <Alert severity="error">{addAdditionalFeeItemFailed}</Alert>
                  </BlockContainer>
                </Grid>
              )}
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="additionalFeeDescription"
                  label="Description"
                  error={inputAdditionalFeeDescriptionError}
                  value={inputAdditionalFeeDescription}
                  onChange={(e) => {
                    setInputAdditionalFeeDescription(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="additionalFeeTva"
                  label="TVA"
                  error={inputAdditionalFeeTvaError}
                  value={inputAdditionalFeeTva}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        !isNaN(Number(e.target.value)) &&
                        !isNaN(Number(inputAdditionalFeePriceHT))
                      ) {
                        setInputAdditionalFeePriceTTC(
                          toFixed2(
                            getTTCPriceFromHT(
                              Number(inputAdditionalFeePriceHT),
                              Number(e.target.value)
                            )
                          )
                        );
                      }
                      setInputAdditionalFeeTva(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="additionalFeePriceHT"
                  label="Prix HT"
                  error={inputAdditionalFeePriceHTError}
                  value={inputAdditionalFeePriceHT}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        !isNaN(Number(e.target.value)) &&
                        !isNaN(Number(inputAdditionalFeeTva))
                      ) {
                        setInputAdditionalFeePriceTTC(
                          toFixed2(
                            getTTCPriceFromHT(
                              Number(e.target.value),
                              Number(inputAdditionalFeeTva)
                            )
                          )
                        );
                      }
                      setInputAdditionalFeePriceHT(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="additionalFeePriceTTC"
                  label="Prix TTC"
                  error={inputAdditionalFeePriceTTCError}
                  value={inputAdditionalFeePriceTTC}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      if (
                        !isNaN(Number(e.target.value)) &&
                        !isNaN(Number(inputAdditionalFeeTva))
                      ) {
                        setInputAdditionalFeePriceHT(
                          toFixed2(
                            getHTPriceFromTTC(
                              Number(e.target.value),
                              Number(inputAdditionalFeeTva)
                            )
                          )
                        );
                      }
                      setInputAdditionalFeePriceTTC(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                display="flex"
                justifyContent="center"
              >
                <FlexContainer>
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    onClick={() => {
                      if (validateAddAdditionalFeeForm()) {
                        resetAdditionalFeeForm();
                        setInputAdditionalFees([
                          ...inputAdditionalFees,
                          {
                            id: uuid(),
                            description: inputAdditionalFeeDescription,
                            priceHT: Number(inputAdditionalFeePriceHT),
                            tva: Number(inputAdditionalFeeTva),
                          },
                        ]);
                      }
                    }}
                  >
                    Ajouter
                  </ButtonMui>
                </FlexContainer>
              </Grid>

              <Grid item xs={1} sm={1} md={2}>
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell key="additionalFeeDescription">
                          Description
                        </TableCell>
                        <TableCell key="inputAdditionalFeePriceHT">
                          Prix HT
                        </TableCell>
                        <TableCell key="inputAdditionalFeeTva">TVA</TableCell>
                        <TableCell key="inputAdditionalFeePriceTTC">
                          Prix TTC
                        </TableCell>
                        <TableCell key="actions">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inputAdditionalFees.length === 0 && (
                        <TableRow key="empty">
                          <TableCell colSpan={5}>
                            <FlexContainer width="100%" justifyContent="center">
                              <Text14 color="grey" opacity="80%">
                                Aucun frais supplémentaire ajouté!
                              </Text14>
                            </FlexContainer>
                          </TableCell>
                        </TableRow>
                      )}
                      {inputAdditionalFees.map((additionalFee, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell>{additionalFee.description}</TableCell>
                            <TableCell>
                              {toFixed2(Number(additionalFee.priceHT)) + " €"}
                            </TableCell>
                            <TableCell>
                              {toFixed2(Number(additionalFee.tva)) + "%"}
                            </TableCell>
                            <TableCell>
                              {toFixed2(
                                Number(additionalFee.priceHT) *
                                  (1 + Number(additionalFee.tva) / 100)
                              ) + " €"}
                            </TableCell>
                            <TableCell>
                              <FlexContainer justifyContent="center">
                                <IconButton
                                  color="primary"
                                  onClick={(e) => {
                                    setInputAdditionalFeePriceHTEdit(
                                      toFixed2(Math.abs(additionalFee.priceHT))
                                    );
                                    setInputAdditionalFeeTvaEdit(
                                      toFixed2(Math.abs(additionalFee.tva))
                                    );
                                    setInputAdditionalFeePriceTTCEdit(
                                      toFixed2(
                                        Math.abs(
                                          Number(additionalFee.priceHT) *
                                            (1 +
                                              Number(additionalFee.tva) / 100)
                                        )
                                      )
                                    );
                                    setAdditionalFeeItemEditActive(
                                      additionalFee.id
                                    );
                                  }}
                                  size="large"
                                >
                                  <EditOutlinedIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    removeAdditionalFeeItem(index);
                                  }}
                                  size="large"
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </FlexContainer>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={"edit-" + index}
                            sx={{
                              display:
                                additionalFeeItemEditActive === additionalFee.id
                                  ? "table-row"
                                  : "none",
                            }}
                          >
                            <TableCell colSpan={5}>
                              <Collapse
                                orientation="vertical"
                                in={
                                  additionalFeeItemEditActive ===
                                  additionalFee.id
                                }
                              >
                                {additionalFeeItemEditActive ===
                                  additionalFee.id && (
                                  <Grid
                                    container
                                    spacing={{ xs: 1, sm: 1, md: 2 }}
                                    columns={{ xs: 1, sm: 1, md: 2 }}
                                  >
                                    <Grid item xs={1} sm={1} md={2}>
                                      {editAdditionalFeeItemFailed !== "" && (
                                        <BlockContainer margin="0 0 8px 0">
                                          <Alert severity="error">
                                            {editAdditionalFeeItemFailed}
                                          </Alert>
                                        </BlockContainer>
                                      )}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="additionalFeeTva"
                                        label="TVA"
                                        error={inputAdditionalFeeTvaEditError}
                                        value={inputAdditionalFeeTvaEdit}
                                        onChange={(e) => {
                                          if (
                                            /^\d*\.?\d*$/.test(
                                              e.target.value
                                            ) ||
                                            e.target.value === ""
                                          ) {
                                            if (
                                              !isNaN(Number(e.target.value)) &&
                                              !isNaN(
                                                Number(
                                                  inputAdditionalFeePriceHTEdit
                                                )
                                              )
                                            ) {
                                              setInputAdditionalFeePriceTTCEdit(
                                                toFixed2(
                                                  getTTCPriceFromHT(
                                                    Number(
                                                      inputAdditionalFeePriceHTEdit
                                                    ),
                                                    Number(e.target.value)
                                                  )
                                                )
                                              );
                                            }
                                            setInputAdditionalFeeTvaEdit(
                                              e.target.value
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="additionalFeePriceHT"
                                        label="Prix HT"
                                        error={
                                          inputAdditionalFeePriceHTEditError
                                        }
                                        value={inputAdditionalFeePriceHTEdit}
                                        onChange={(e) => {
                                          if (
                                            /^\d*\.?\d*$/.test(
                                              e.target.value
                                            ) ||
                                            e.target.value === ""
                                          ) {
                                            if (
                                              !isNaN(Number(e.target.value)) &&
                                              !isNaN(
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            ) {
                                              setInputAdditionalFeePriceTTCEdit(
                                                toFixed2(
                                                  getTTCPriceFromHT(
                                                    Number(e.target.value),
                                                    Number(
                                                      inputAdditionalFeeTvaEdit
                                                    )
                                                  )
                                                )
                                              );
                                            }
                                            setInputAdditionalFeePriceHTEdit(
                                              e.target.value
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              €
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="additionalFeePriceTTC"
                                        label="Prix TTC"
                                        error={
                                          inputAdditionalFeePriceTTCEditError
                                        }
                                        value={inputAdditionalFeePriceTTCEdit}
                                        onChange={(e) => {
                                          if (
                                            /^\d*\.?\d*$/.test(
                                              e.target.value
                                            ) ||
                                            e.target.value === ""
                                          ) {
                                            if (
                                              !isNaN(Number(e.target.value)) &&
                                              !isNaN(
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            ) {
                                              setInputAdditionalFeePriceHTEdit(
                                                toFixed2(
                                                  getHTPriceFromTTC(
                                                    Number(e.target.value),
                                                    Number(
                                                      inputAdditionalFeeTvaEdit
                                                    )
                                                  )
                                                )
                                              );
                                            }
                                            setInputAdditionalFeePriceTTCEdit(
                                              e.target.value
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              €
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      sm={1}
                                      md={2}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <FlexContainer>
                                        <ButtonMui
                                          color="primary"
                                          variant="contained"
                                          size="large"
                                          disabled={isLoading}
                                          margin="0 4px 0 0"
                                          onClick={() => {
                                            if (
                                              validateEditAdditionalFeeForm()
                                            ) {
                                              resetAdditionalFeeEditForm();
                                              let tmpArray = [
                                                ...inputAdditionalFees,
                                              ];
                                              tmpArray[index] = {
                                                id: additionalFee.id,
                                                description:
                                                  additionalFee.description,
                                                priceHT: Number(
                                                  inputAdditionalFeePriceHTEdit
                                                ),
                                                tva: Number(
                                                  inputAdditionalFeeTvaEdit
                                                ),
                                              };
                                              setInputAdditionalFees(tmpArray);
                                              setAdditionalFeeItemEditActive(
                                                ""
                                              );
                                            }
                                          }}
                                        >
                                          Modifier
                                        </ButtonMui>
                                        <ButtonMui
                                          margin="0 0 0 4px"
                                          onClick={() => {
                                            resetAdditionalFeeEditForm();
                                            setAdditionalFeeItemEditActive("");
                                          }}
                                          color="primary"
                                          variant="outlined"
                                          size="large"
                                        >
                                          Annuler
                                        </ButtonMui>
                                      </FlexContainer>
                                    </Grid>
                                  </Grid>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Tarification
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {inputType === "Facture" && (
            <>
              <Grid item xs={1} sm={1} md={2}>
                <Text14 display="inline-block" width="144px">
                  Prix HT:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getTotalHtText()}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="144px">
                  Prix TTC:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getTotalTTCText()}
                </Text16>
              </Grid>
              {inputAssociatedUpfrontInvoices.length > 0 && (
                <>
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={2}
                    sx={{ paddingTop: "0 !important" }}
                  >
                    <Text14 display="inline-block" width="144px">
                      Reste à payer HT:
                    </Text14>
                    <Text16
                      display="inline"
                      color={CustomTheme.palette.primary.main}
                      fontWeight="500"
                    >
                      {getRemainingPriceHTText()}
                    </Text16>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={2}
                    sx={{ paddingTop: "0 !important" }}
                  >
                    <Text14 display="inline-block" width="144px">
                      Reste à payer TTC:
                    </Text14>
                    <Text16
                      display="inline"
                      color={CustomTheme.palette.primary.main}
                      fontWeight="500"
                    >
                      {getRemainingPriceTTCText()}
                    </Text16>
                  </Grid>
                </>
              )}
            </>
          )}
          {(inputType === "Acompte" || inputType === "Avoir") && (
            <>
              <Grid item xs={1} sm={1} md={2}>
                <Text14 display="inline-block" width="144px">
                  Total produits HT:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getTotalHtText()}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="144px">
                  Total produits TTC:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getTotalTTCText()}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="144px">
                  {"Montant " +
                    (inputType === "Acompte" ? "acompte" : "avoir") +
                    " HT:"}
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {(isNaN(parseFloat(getTotalHt())) ? "0.00" : getTotalHt()) +
                    " €"}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="144px">
                  {"Montant " +
                    (inputType === "Acompte" ? "acompte" : "avoir") +
                    " TTC:"}
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {(isNaN(parseFloat(getTotalTtc())) ? "0.00" : getTotalTtc()) +
                    " €"}
                </Text16>
              </Grid>
            </>
          )}
          {inputType !== "Avoir" && (
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="discount"
                label="Remise"
                value={inputDiscount}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputDiscount(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        "& .MuiInputBase-root:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:hover:before": {
                          borderBottom: "none !important",
                        },
                      }}
                    >
                      <Select
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select:focus": {
                            borderBottom: "none",
                            backgroundColor: "white",
                          },
                        }}
                        value={inputDiscountCalculation}
                        onChange={(e) =>
                          setInputDiscountCalculation(e.target.value)
                        }
                      >
                        <MenuItem key="percentage" value="percentage">
                          %
                        </MenuItem>
                        <MenuItem key="valueHT" value="valueHT">
                          Valeur HT
                        </MenuItem>
                        <MenuItem key="valueTTC" value="valueTTC">
                          Valeur TTC
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {inputType === "Acompte" && (
            <>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="upfrontAmount"
                  label="Montant de l'acompte"
                  error={inputUpfrontAmountError}
                  value={inputUpfrontAmount}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputUpfrontAmount(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiInputBase-root:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:after": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:hover:before": {
                            borderBottom: "none !important",
                          },
                        }}
                      >
                        <Select
                          variant="standard"
                          sx={{
                            "& .MuiSelect-select:focus": {
                              borderBottom: "none",
                              backgroundColor: "white",
                            },
                          }}
                          value={inputUpfrontAmountCalculation}
                          onChange={(e) =>
                            setInputUpfrontAmountCalculation(e.target.value)
                          }
                        >
                          <MenuItem key="percentage" value="percentage">
                            %
                          </MenuItem>
                          <MenuItem key="valueHT" value="valueHT">
                            Valeur HT
                          </MenuItem>
                          <MenuItem key="valueTTC" value="valueTTC">
                            Valeur TTC
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="upfrontTVA"
                  label="TVA de l'acompte"
                  disabled={
                    inputSoldItems.length > 0 || inputAdditionalFees.length > 0
                  }
                  error={inputUpfrontTVAError}
                  value={inputUpfrontCreditNoteTVA}
                  onChange={(e) => {
                    setInputUpfrontCreditNoteTVA(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          {inputType === "Avoir" && (
            <>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="creditNoteAmount"
                  label="Montant de l'avoir"
                  error={inputCreditNoteAmountError}
                  value={inputCreditNoteAmount}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputCreditNoteAmount(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiInputBase-root:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:after": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:hover:before": {
                            borderBottom: "none !important",
                          },
                        }}
                      >
                        <Select
                          variant="standard"
                          sx={{
                            "& .MuiSelect-select:focus": {
                              borderBottom: "none",
                              backgroundColor: "white",
                            },
                          }}
                          value={inputCreditNoteAmountCalculation}
                          onChange={(e) =>
                            setInputCreditNoteAmountCalculation(e.target.value)
                          }
                        >
                          <MenuItem key="percentage" value="percentage">
                            %
                          </MenuItem>
                          <MenuItem key="valueHT" value="valueHT">
                            Valeur HT
                          </MenuItem>
                          <MenuItem key="valueTTC" value="valueTTC">
                            Valeur TTC
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="upfrontTVA"
                  label="TVA de l'avoir"
                  disabled={
                    inputSoldItems.length > 0 || inputAdditionalFees.length > 0
                  }
                  error={inputUpfrontTVAError}
                  value={inputUpfrontCreditNoteTVA}
                  onChange={(e) => {
                    setInputUpfrontCreditNoteTVA(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          {inputType === "Facture" && (
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="associatedUpfrontInvoices"
                filterOptions={filterAssociatedUpfontInvicesOptions}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                multiple
                disableCloseOnSelect
                getOptionLabel={(option) => {
                  return (
                    option.invoiceNumber +
                    " - " +
                    getContactDescription(option.client)
                  );
                }}
                options={upfrontInvoices}
                value={inputAssociatedUpfrontInvoices}
                onChange={(event, newValue) => {
                  setInputAssociatedUpfrontInvoices(newValue!);
                }}
                inputValue={inputAssociatedUpfrontInvoicesText}
                onInputChange={(event, newInputValue) => {
                  setInputAssociatedUpfrontInvoicesText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="associatedUpfrontInvoicesTextfield"
                    label="Factures d'acompte"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addInvoice();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddInvoice = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
    materials: getMaterials(state),
    dishes: getDishes(state),
    services: getServices(state),
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getMaterialsAction: getMaterialsAction,
        getDishesAction: getDishesAction,
        getServicesAction: getServicesAction,
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_AddInvoice);

export default AddInvoice;
