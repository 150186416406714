import { Box, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { AxiosHttpClient } from "../../../services/AxiosHttpService";
import { AppName } from "../../../styles/AppName";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text16 } from "../../../styles/Text";
import { ThemeCustom } from "../../../styles/Utils";

export interface ForgotPasswordFormProps {
  setForgotPasswordActive: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailSentAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  setForgotPasswordActive,
  setEmailSentAlert,
}) => {
  const setEmployeePassword = () => {
    AxiosHttpClient.putForNonAuth(
      "api/v1/weeventpro/employees/sendPasswordResetEmail",
      { email: inputEmail }
    )
      .then(() => {
        setForgotPasswordActive(false);
        setEmailSentAlert(true);
      })
      .catch((err) => {
        setForgotPasswordActive(false);
        setEmailSentAlert(true);
      });
  };

  const [inputEmail, SetInputEmail] = useState("");

  return (
    <FlexContainer flex="1" elevation={5} backgroundColor="white">
      <FlexContainer
        flexDirection="column"
        margin="64px 32px"
        textAlign="left"
        width="100%"
      >
        <FlexContainer margin="20px" alignSelf="center">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>

        <Text16>
          Veuillez spécifier votre adresse email. Nous vous enverrons un mail
          pour réinitialiser votre mot de passe.
        </Text16>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <TextField
              value={inputEmail}
              onChange={(e) => SetInputEmail(e.target.value)}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          </Grid>
        </Grid>
        <FlexContainer margin="24px 0 16px" justifyContent={"center"}>
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              setEmployeePassword();
            }}
          >
            CONFIRMER
          </ButtonMui>
          <ButtonMui
            color="primary"
            variant="outlined"
            size="large"
            margin="0 0 0 8px"
            onClick={() => setForgotPasswordActive(false)}
          >
            RETOUR
          </ButtonMui>
        </FlexContainer>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="/">
              WeEvent
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ForgotPasswordForm;
