import { RootAction } from "../types";
import { GET_SERVICES_SUCCESS } from "./actions";
import { ServiceState } from "./types";

const initialState: ServiceState = {
  services: [],
};

export function serviceReducer(
  state: ServiceState = initialState,
  action: RootAction
): ServiceState {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      return {
        services: action.services,
      };

    default:
      return state;
  }
}
