import { Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../styles/InlineBlockContainer";
import { Text14 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  return (
    <FlexContainer
      backgroundColor="#161C28"
      sx={{
        padding: { xs: "24px 32px 16px 32px", sm: "80px 128px 64px 128px" },
      }}
    >
      <Grid container spacing={4}>
        {/* Bloc gauche - Message de l'équipe */}
        <Grid item xs={12} md={6}>
          <BlockContainer marginLeft={"16px"}>
            <img
              src={`https://${process.env.REACT_APP_BUCKET_NAME}.s3.eu-west-3.amazonaws.com/WebsiteImages/Logo.svg`}
              alt="Mon SVG"
              width={82.4}
              height={55}
              style={{ marginBottom: "8px" }}
            />
            <Text14 color="#A6A6A6" margin="8px 0 0 0" lineHeight="24px">
              Commencez dès maintenant,
            </Text14>
            <Text14 color="#A6A6A6" margin="0" lineHeight="24px">
              essayez WeEvent!
            </Text14>
            <Button
              sx={{
                marginTop: "12px",
                marginRight: "16px",
                backgroundColor: CustomTheme.palette.primary.main, // Orange
                color: "white",
                fontFamily: "Roboto", // Utilisation de Roboto
                fontWeight: 500,
                fontSize: 14,
                padding: "12px 20px",
                borderRadius: "40px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: CustomTheme.palette.primary.dark, // Orange plus foncé au survol
                },
              }}
              onClick={() => {
                navigate("signup");
              }}
            >
              Essayer gratuitement
            </Button>
          </BlockContainer>
        </Grid>

        {/* Bloc droit - Formulaire de contact */}
        <Grid item xs={12} md={6}>
          <BlockContainer>
            <Text14 color="#A6A6A6" margin="8px 0 0 0" lineHeight="24px">
              <InlineBlockContainer
                $colorHover="white"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/features");
                }}
              >
                Fonctionnalités
              </InlineBlockContainer>
            </Text14>
            <Text14 color="#A6A6A6" margin="8px 0 0 0" lineHeight="24px">
              <InlineBlockContainer
                $colorHover="white"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/faq");
                }}
              >
                FAQ
              </InlineBlockContainer>
            </Text14>
            <Text14 color="#A6A6A6" margin="8px 0 0 0" lineHeight="24px">
              <InlineBlockContainer
                $colorHover="white"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/blog");
                }}
              >
                Blog
              </InlineBlockContainer>
            </Text14>
            <Text14 color="#A6A6A6" margin="8px 0 0 0" lineHeight="24px">
              <InlineBlockContainer
                $colorHover="white"
                $cursorHover="pointer"
                width={"auto"}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Inscription
              </InlineBlockContainer>
            </Text14>
          </BlockContainer>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default Footer;
