import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import CommentTwoToneIcon from "@mui/icons-material/CommentTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import SubjectTwoToneIcon from "@mui/icons-material/SubjectTwoTone";
import VideoCameraFrontTwoToneIcon from "@mui/icons-material/VideoCameraFrontTwoTone";
import { Alert, Collapse, Grid, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router";
import uuid from "react-uuid";
import { Comment } from "../../../../../../models/comment";
import { Employee } from "../../../../../../models/employee";
import { File } from "../../../../../../models/file";
import { Interaction } from "../../../../../../models/interaction";
import { Opportunity } from "../../../../../../models/opportunity";
import { Task } from "../../../../../../models/task";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getEmployeeDescription,
  trimLongString,
} from "../../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface TimelineProps {
  opportunity: Opportunity | null;
  allActions: (Interaction | Task | Comment)[];
  setPopupEditCommentActive: React.Dispatch<
    React.SetStateAction<Comment | null>
  >;
  setPopupEditInteractionActive: React.Dispatch<
    React.SetStateAction<Interaction | null>
  >;
  setPopupEditTaskActive: React.Dispatch<React.SetStateAction<Task | null>>;
  setPopupDeleteCommentActive: React.Dispatch<React.SetStateAction<string[]>>;
  setPopupDeleteInteractionActive: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  setPopupDeleteTaskActive: React.Dispatch<React.SetStateAction<string[]>>;
  actionsOnAdd?: (() => void)[];
}

const Timeline: React.FC<TimelineProps> = ({
  opportunity,
  allActions,
  setPopupEditCommentActive,
  setPopupEditInteractionActive,
  setPopupEditTaskActive,
  setPopupDeleteCommentActive,
  setPopupDeleteInteractionActive,
  setPopupDeleteTaskActive,
  actionsOnAdd,
}) => {
  const addComment = () => {
    if (opportunity) {
      AxiosHttpClient.put("api/v1/weeventpro/opportunities", {
        id: opportunity.id,
        title: opportunity.title,
        opportunityNumber: opportunity.opportunityNumber,
        startDate: opportunity.startDate
          ? dayjs(opportunity.startDate).tz("Europe/Paris").format("YYYY-MM-DD")
          : null,
        endDate: opportunity.endDate
          ? dayjs(opportunity.endDate).tz("Europe/Paris").format("YYYY-MM-DD")
          : null,
        contactId: opportunity.contact?.id,
        responsiblesIds: opportunity.responsibles.map(
          (responsible) => responsible.id
        ),
        tags: opportunity.tags,
        source: opportunity.source,
        amountHT: opportunity.amountHT,
        amountFrequency: opportunity.amountFrequency,
        probability: opportunity.probability,
        priority: opportunity.priority,
        description: opportunity.description,
        soldItems: opportunity.soldItems,
        commercialCycleId: opportunity.commercialCycle?.id,
        commercialCycleStepId: opportunity.commercialCycleStep?.id,
        status: opportunity.status,
        lossReason: opportunity.lossReason,
        comments: [
          ...opportunity.comments.map((comment) => {
            return {
              id: comment.id,
              content: comment.content,
              commentWriterId: comment.commentWriter?.id,
              files: comment.files,
              creationDateTime: comment.creationDateTime,
            };
          }),
          {
            id: uuid(),
            content: inputComment,
            commentWriterId: user?.id,
            files: inputFiles,
          },
        ],
        customFields: opportunity.customFields,
      })
        .then(() => {
          setCommentAddActive(false);
          actionsOnAdd?.forEach((action) => action());
        })
        .catch((err) => {
          setAddFailed(err.cause);
          setIsLoading(false);
        });
    }
  };

  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  const [user, setUser] = useState<Employee | null>(null);

  const [inputComment, setInputComment] = useState("");

  const [inputFiles, setInputFiles] = useState<File[]>([]);

  const [commentAddActive, setCommentAddActive] = useState(false);

  const [actionHovered, setActionHovered] = useState("");

  const [addFailed, setAddFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  let removeDocument = (index: number) => {
    let newArr = [...inputFiles];
    newArr.splice(index, 1);
    setInputFiles(newArr);
  };

  let filesPreviewHandler = (files: FileList | null) => {
    if (files) {
      const promises: Promise<File>[] = [];

      Array.from(files).forEach((file) => {
        const promise = new Promise<File>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              const fileObj: File = {
                name: file.name,
                url: reader.result as string,
              };
              resolve(fileObj);
            }
          };
          reader.readAsDataURL(file);
        });
        promises.push(promise);
      });

      Promise.all(promises).then((fileObjs) => {
        setInputFiles([...(inputFiles ?? []), ...fileObjs]);
      });
    }
  };

  const getActionDateTime = (obj: Task | Interaction | Comment) => {
    if ("startDateTime" in obj) {
      return obj.startDateTime
        ? dayjs(obj.startDateTime).format("DD/MM/YYYY [à] HH:mm")
        : "";
    } else if ("creationDateTime" in obj) {
      return obj.creationDateTime
        ? dayjs(obj.creationDateTime).format("DD/MM/YYYY [à] HH:mm")
        : "";
    }
    return null;
  };

  const fetchEntityById = (
    action: Task | Interaction | Comment,
    editFunction: any
  ) => {
    let endpoint = "";

    if ("taskNumber" in action) {
      endpoint = "api/v1/weeventpro/tasks/" + action.id;
    } else if ("interactionNumber" in action) {
      endpoint = "api/v1/weeventpro/interactions/" + action.id;
    } else {
      editFunction(action);
      return;
    }

    AxiosHttpClient.get(endpoint)
      .then((response) => {
        editFunction(response);
      })
      .catch((error) => {});
  };

  const getActionComponents = (action: Task | Interaction | Comment) => {
    let icon: React.JSX.Element | null = <SubjectTwoToneIcon />;
    let actionType: string = "Commentaire";
    let actionNumber: string = "";
    let editFunction: any = setPopupEditCommentActive;
    let deleteFunction = setPopupDeleteCommentActive;

    if ("interactionNumber" in action) {
      // C'est une Interaction
      if (action.type === "Appel téléphonique") {
        icon = <LocalPhoneTwoToneIcon color="primary" />;
        actionType = "Appel téléphonique -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "SMS") {
        icon = <SmsTwoToneIcon color="primary" />;
        actionType = "SMS -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "E-mail") {
        icon = <EmailTwoToneIcon color="primary" />;
        actionType = "E-mail -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "Réunion en personne") {
        icon = <GroupsTwoToneIcon color="primary" />;
        actionType = "Réunion en personne -";
        actionNumber = action.interactionNumber;
      } else if (action.type === "Réunion virtuelle") {
        icon = <VideoCameraFrontTwoToneIcon color="primary" />;
        actionType = "Réunion virtuelle -";
        actionNumber = action.interactionNumber;
      } else {
        icon = <LiveHelpTwoToneIcon color="primary" />;
        actionType = "Intéraction -";
        actionNumber = action.interactionNumber;
      }
      editFunction = setPopupEditInteractionActive;
      deleteFunction = setPopupDeleteInteractionActive;
    } else if ("taskNumber" in action) {
      // C'est une Task
      icon = <AssignmentTwoToneIcon color="primary" />;
      actionType = "Tâche -";
      actionNumber = action.taskNumber;
      editFunction = setPopupEditTaskActive;
      deleteFunction = setPopupDeleteTaskActive;
    } else {
      // C'est un Comment
      icon = <CommentTwoToneIcon color="primary" />;
      actionType = "Commentaire";
      editFunction = setPopupEditCommentActive;
      deleteFunction = setPopupDeleteCommentActive;
    }

    return (
      <FlexContainer
        onMouseEnter={() => setActionHovered(action.id)}
        onMouseLeave={() => setActionHovered("")}
        padding={"8px 0 0 0"}
        backgroundColor={actionHovered === action.id ? "#f5f3f0" : "white"}
        position={"relative"}
      >
        <FlexContainer flexDirection={"column"} margin={"0 0 24px 0"}>
          <Text14
            color="rgba(0,0,0,0.7)"
            margin="8px 16px"
            lineHeight="20px"
            fontWeight="600"
          >
            {getActionDateTime(action)}
          </Text14>
        </FlexContainer>
        <FlexContainer flexDirection={"column"} alignItems={"center"}>
          <FlexContainer
            width={"40px"}
            height={"40px"}
            backgroundColor={CustomTheme.palette.background.default}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"0 0 8px 0"}
          >
            {icon}
          </FlexContainer>
          <FlexContainer
            border={"1px solid rgba(0,0,0,0.1)"}
            flex={"1"}
            width={"0"}
          ></FlexContainer>
        </FlexContainer>
        <FlexContainer
          flexDirection={"column"}
          alignItems={"flex-start"}
          margin={"0 0 24px 0"}
          flex={"1"}
        >
          {("interactionNumber" in action || "taskNumber" in action) && (
            <>
              <Text16 margin="0 16px" fontWeight="500" lineHeight="20px">
                {action.title}
              </Text16>
              <FlexContainer>
                <Text14
                  color="rgba(0,0,0,0.5)"
                  margin="0 0 0 16px"
                  lineHeight="20px"
                >
                  {actionType}
                </Text14>
                <Text14
                  color={CustomTheme.palette.secondary.main}
                  margin="0 16px 0 4px"
                  lineHeight="20px"
                  textDecorationLine="underline"
                  cursor="pointer"
                  onClick={(e) => {
                    if ("interactionNumber" in action)
                      navigate(`/feature/interactions/${action.id}`);
                    navigate(`/feature/tasks/${action.id}`);
                  }}
                >
                  {actionNumber}
                </Text14>
              </FlexContainer>
              {action.description && action.description !== "" && (
                <>
                  <Text14
                    color="rgba(0,0,0,0.5)"
                    margin="0 16px"
                    lineHeight="20px"
                  >
                    Description:
                  </Text14>
                  <Text14
                    color="rgba(0,0,0,0.5)"
                    margin="0 16px"
                    lineHeight="20px"
                    fontWeight="600"
                  >
                    {action.description}
                  </Text14>
                </>
              )}
            </>
          )}
          {"commentWriter" in action && (
            <>
              <FlexContainer alignItems={"center"} margin={"0 8px"}>
                <BlockContainer
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  border="rgba(0,0,0,0.1) solid 1px"
                  justifyContent="center"
                  position="relative"
                  textAlign="center"
                  backgroundColor="white"
                  margin="auto"
                  borderRadius={"40px"}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      margin: "auto",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                    }}
                    alt=""
                    src={action?.commentWriter?.imageUrl}
                  />
                </BlockContainer>
                <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                  {getEmployeeDescription(action.commentWriter)}
                </Text16>
              </FlexContainer>
              <Text14
                color="rgba(0,0,0,0.7)"
                margin="8px 16px"
                lineHeight="20px"
              >
                {action.content}
              </Text14>
              {action.files.map((file) => (
                <FlexContainer alignItems={"center"} margin="0 12px">
                  <AttachFileRoundedIcon fontSize="small" color="primary" />
                  <Text14
                    cursor="pointer"
                    margin="0 4px"
                    color={CustomTheme.palette.secondary.main}
                    textDecorationLine="underline"
                    onClick={(e) => {
                      fetch(file.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", file.name);
                          document.body.appendChild(link);
                          link.click();
                        });
                    }}
                  >
                    {file.name}
                  </Text14>
                </FlexContainer>
              ))}
            </>
          )}
        </FlexContainer>
        {actionHovered === action.id && (
          <FlexContainer
            justifyContent="center"
            marginLeft={"auto"}
            padding={"0 8px 0 0"}
            position={"absolute"}
            top="8px"
            right="0"
          >
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                fetchEntityById(action, editFunction);
              }}
              size="small"
              sx={{ height: "30px", width: "30px" }}
            >
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteFunction([action!.id]);
              }}
              size="small"
              sx={{ height: "30px", width: "30px" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </FlexContainer>
        )}
      </FlexContainer>
    );
  };

  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  return (
    <FlexContainer padding="16px" flexDirection={"column"}>
      {addFailed !== "" && (
        <BlockContainer margin="8px">
          <Alert
            severity="error"
            onClose={() => {
              setAddFailed("");
            }}
          >
            {addFailed}
          </Alert>
        </BlockContainer>
      )}
      <FlexContainer
        margin="16px 0 16px 16px"
        alignItems="center"
        $cursorHover="pointer"
        onClick={() => {
          setCommentAddActive(true);
          setInputComment("");
          setInputFiles([]);
        }}
      >
        <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
        <Text16
          color={CustomTheme.palette.secondary.main}
          textAlign="left"
          margin="0"
        >
          Ajouter un commentaire
        </Text16>
      </FlexContainer>
      <Collapse orientation="vertical" in={commentAddActive}>
        <FlexContainer
          flexDirection={"column"}
          maxWidth={"1100px"}
          margin={"0 0 16px 16px"}
        >
          {inputFiles.length > 0 && (
            <Grid
              container
              border="2px dashed rgba(0,0,0,0.1)"
              margin={"0 0 8px 0"}
            >
              {inputFiles!.map((justification, index) => (
                <Grid>
                  <FlexContainer
                    elevation={3}
                    margin="8px"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                    $borderRadius="16px"
                  >
                    <FlexContainer margin="0 0 0 8px" alignItems="center">
                      <AttachFileRoundedIcon
                        fontSize="medium"
                        color="primary"
                      />
                      <Text14
                        cursor="pointer"
                        margin="0 4px"
                        color={CustomTheme.palette.secondary.main}
                        textDecorationLine="underline"
                        onClick={(e) => {
                          fetch(justification.url)
                            .then((response) => response.blob())
                            .then((blob) => {
                              const url = window.URL.createObjectURL(blob);
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", justification.name);
                              document.body.appendChild(link);
                              link.click();
                            });
                        }}
                      >
                        {trimLongString(justification.name)}
                      </Text14>
                    </FlexContainer>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        removeDocument(index);
                      }}
                      size="medium"
                      style={{ margin: "0 8px", padding: "4px", zIndex: "1" }}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </FlexContainer>
                </Grid>
              ))}
            </Grid>
          )}
          <FlexContainer>
            <TextField
              fullWidth
              variant="outlined"
              name="comment"
              label="Commentaire"
              multiline
              minRows={4}
              value={inputComment}
              onChange={(e) => {
                setInputComment(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <label htmlFor="contained-button-file">
                      <Input
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          filesPreviewHandler(e.target.files);
                        }}
                      />
                      <IconButton component="span">
                        <AttachFileRoundedIcon fontSize="medium" />
                      </IconButton>
                    </label>
                  </>
                ),
              }}
            />
          </FlexContainer>
          <FlexContainer justifyContent="center">
            <ButtonMui
              margin="16px 4px 0 0"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                addComment();
              }}
              color="primary"
              variant="contained"
              size="large"
            >
              Ajouter
            </ButtonMui>
            <ButtonMui
              margin="16px 0 0 4px"
              onClick={() => {
                setCommentAddActive(false);
              }}
              color="primary"
              variant="outlined"
              size="large"
            >
              Annuler
            </ButtonMui>
          </FlexContainer>
        </FlexContainer>
      </Collapse>
      {allActions.length === 0 && (
        <FlexContainer
          maxHeight={"180px"}
          flexDirection={"column"}
          overflowY={"auto"}
          flex={"1"}
          justifyContent={"center"}
        >
          <Text14 color="gray" textAlign="center">
            Aucune activité retrouvée
          </Text14>
        </FlexContainer>
      )}
      {allActions.map((action) => getActionComponents(action))}
    </FlexContainer>
  );
};

export default Timeline;
