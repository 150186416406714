import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { DialogTitle, Divider, Link, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Interaction } from "../../../../../../models/interaction";
import { DialogContentMui } from "../../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import AvatarWithInitials from "../../../../../Reusable/AvatarWithInitials";
import { getParticipantDescription } from "../../../../../Reusable/Utils";

export interface EmailDetailsProps {
  popupActive: Interaction;
}

const EmailDetails: React.FC<EmailDetailsProps> = ({ popupActive }) => {
  return (
    <>
      <DialogTitle id="responsive-dialog-title">
        <FlexContainer textAlign="left" alignItems="flex-start">
          <EmailTwoToneIcon color="primary" sx={{ marginRight: "8px" }} />
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, lineHeight: "20px" }}
          >
            {popupActive.interactionNumber +
              " (" +
              popupActive.type +
              ")" +
              (popupActive.title !== "" ? " - " + popupActive.title : "")}
          </Typography>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui>
        {popupActive.startDateTime && (
          <FlexContainer>
            <Text14 color="rgb(107, 114, 128)">
              {popupActive.startDateTime
                ? "Envoyé le " +
                  dayjs(popupActive.startDateTime).format(
                    "DD/MM/YYYY [à] HH:mm"
                  )
                : ""}
            </Text14>
          </FlexContainer>
        )}
        <FlexContainer>
          <Text14
            fontWeight="500"
            color="rgb(107, 114, 128)"
            margin="4px 12px 4px 10px"
          >
            De
          </Text14>
          <FlexContainer alignItems={"center"} margin={"0 8px"}>
            <AvatarWithInitials
              entity={
                popupActive.fromContact
                  ? popupActive.fromContact
                  : popupActive.fromEmployee
              }
            />
            <FlexContainer flexDirection={"column"}>
              <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                {getParticipantDescription(
                  popupActive.fromContact
                    ? popupActive.fromContact
                    : popupActive.fromEmployee
                )}
              </Text16>
              {(popupActive.fromContact
                ? popupActive.fromContact
                : popupActive.fromEmployee) &&
                (popupActive.fromContact
                  ? popupActive.fromContact
                  : popupActive.fromEmployee
                ).email !== "" && (
                  <Text14 lineHeight="10px" color="rgb(107, 114, 128)">
                    {
                      (popupActive.fromContact
                        ? popupActive.fromContact
                        : popupActive.fromEmployee
                      ).email
                    }
                  </Text14>
                )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Text14
            fontWeight="500"
            color="rgb(107, 114, 128)"
            margin="4px 19px 4px 10px"
          >
            À
          </Text14>
          <FlexContainer flexDirection={"column"}>
            {[...popupActive.toContacts, ...popupActive.toEmployees].map(
              (to) => (
                <FlexContainer alignItems={"center"} margin={"2px 8px"}>
                  <AvatarWithInitials entity={to} />
                  <FlexContainer flexDirection={"column"}>
                    <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                      {getParticipantDescription(to)}
                    </Text16>
                    {to.email && to.email !== "" && (
                      <Text14 lineHeight="10px" color="rgb(107, 114, 128)">
                        {to.email}
                      </Text14>
                    )}
                  </FlexContainer>
                </FlexContainer>
              )
            )}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Text14 fontWeight="500" color="rgb(107, 114, 128)">
            CC
          </Text14>
          <FlexContainer flexDirection={"column"}>
            {[...popupActive.ccContacts, ...popupActive.ccEmployees].map(
              (cc) => (
                <FlexContainer alignItems={"center"} margin={"2px 8px"}>
                  <AvatarWithInitials entity={cc} />
                  <FlexContainer flexDirection={"column"}>
                    <Text16 margin="0 8px" fontWeight="500" lineHeight="20px">
                      {getParticipantDescription(cc)}
                    </Text16>
                    {cc.email && cc.email !== "" && (
                      <Text14 lineHeight="10px" color="rgb(107, 114, 128)">
                        {cc.email}
                      </Text14>
                    )}
                  </FlexContainer>
                </FlexContainer>
              )
            )}
          </FlexContainer>
        </FlexContainer>
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        <FlexContainer flexDirection={"column"}>
          <Text16 fontWeight="500">Objet</Text16>
          <Text14>{popupActive.object}</Text14>
        </FlexContainer>
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        <FlexContainer flexDirection={"column"}>
          <Text16 fontWeight="500">Contenu</Text16>
          <Text14>{popupActive.content}</Text14>
          {popupActive.files.map((file) => (
            <FlexContainer alignItems={"center"} margin="0 12px">
              <AttachFileRoundedIcon fontSize="small" color="primary" />
              <Text14
                cursor="pointer"
                margin="0 4px"
                color={CustomTheme.palette.secondary.main}
                textDecorationLine="underline"
                onClick={(e) => {
                  fetch(file.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", file.name);
                      document.body.appendChild(link);
                      link.click();
                    });
                }}
              >
                {file.name}
              </Text14>
            </FlexContainer>
          ))}
        </FlexContainer>
        <Divider sx={{ margin: "16px 200px", minWidth: "200px" }} />
        {popupActive.associatedOpportunities &&
          popupActive.associatedOpportunities.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Opportunités associées</Text14>
              {popupActive.associatedOpportunities.map((opportunity) => (
                <Link href={"/feature/opportunities/" + opportunity.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {opportunity.opportunityNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedInvoices &&
          popupActive.associatedInvoices.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Factures associées</Text14>
              {popupActive.associatedInvoices.map((invoice) => (
                <Link href={"/feature/invoices/" + invoice.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {invoice.invoiceNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedSales &&
          popupActive.associatedSales.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Ventes associées</Text14>
              {popupActive.associatedSales.map((sale) => (
                <Link href={"/feature/sales/" + sale.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {sale.saleNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedInvoices &&
          popupActive.associatedInvoices.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Factures associées</Text14>
              {popupActive.associatedInvoices.map((invoice) => (
                <Link href={"/feature/invoices/" + invoice.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {invoice.invoiceNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedComplaints &&
          popupActive.associatedComplaints.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Réclamations associées</Text14>
              {popupActive.associatedComplaints.map((complaint) => (
                <Link href={"/feature/complaints/" + complaint.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {complaint.complaintNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
        {popupActive.associatedTasks &&
          popupActive.associatedTasks.length !== 0 && (
            <FlexContainer>
              <Text14 color="rgb(107, 114, 128)">Tâches associées</Text14>
              {popupActive.associatedTasks.map((task) => (
                <Link href={"/feature/tasks/" + task.id}>
                  <Text14
                    color={CustomTheme.palette.secondary.main}
                    cursor="pointer"
                    textDecorationLine="underline"
                  >
                    {task.taskNumber}
                  </Text14>
                </Link>
              ))}
            </FlexContainer>
          )}
      </DialogContentMui>
    </>
  );
};

export default EmailDetails;
