import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { ServiceService } from "../../services/ServiceService";
import { RootAction } from "../types";
import {
  GET_SERVICES,
  GetServicesActionFailure,
  getServicesActionFailure,
  GetServicesActionSuccess,
  getServicesActionSuccess,
} from "./actions";

export const servicesEpic: Epic<
  RootAction,
  GetServicesActionSuccess | GetServicesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SERVICES),
    switchMap((action) =>
      from(
        ServiceService.getServices({
          criteriaList: action.criteriaList ? action.criteriaList : null,
        })
      ).pipe(
        mergeMap((res) => {
          return [getServicesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getServicesActionFailure()]))
        )
      )
    )
  );

export const serviceEpic = combineEpics(servicesEpic);
