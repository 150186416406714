import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Complaint } from "../../../../../models/complaint";
import { Contact } from "../../../../../models/contact";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { File } from "../../../../../models/file";
import { Invoice } from "../../../../../models/invoice";
import { Opportunity } from "../../../../../models/opportunity";
import { Quote } from "../../../../../models/quote";
import { Sale } from "../../../../../models/sale";
import { Task } from "../../../../../models/task";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getComplaintsAction,
  getContactsAction,
  getOpportunitiesAction,
  getTasksAction,
} from "../../../../../store/Crm/actions";
import { getInvoicesAction } from "../../../../../store/Invoice/actions";
import { getQuotesAction } from "../../../../../store/Quote/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getSalesAction } from "../../../../../store/Sale/actions";
import {
  getComplaints,
  getContacts,
  getEmployees,
  getInvoices,
  getOpportunities,
  getQuotes,
  getSales,
  getTasks,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  INTERACTION_FORMAT,
  INTERACTION_INCREMENT,
  INTERACTION_NUMBER_OF_DIGITS,
  getUserParameterValueByName,
  isContactType,
  mergeDateTime,
} from "../../../../Reusable/Utils";
import EmailInteraction from "./EmailInteraction";
import OtherInteraction from "./OtherInteraction";
import PhoneCallInteraction from "./PhoneCallInteraction";
import PhysicalMeetingInteraction from "./PhysicalMeetingInteraction";
import SmsInteraction from "./SmsInteraction";
import VirtualMeetingInteraction from "./VirtualMeetingInteraction";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface AddInteractionProps {
  popupActive: boolean;
  opportunities: Opportunity[];
  sales: Sale[];
  quotes: Quote[];
  invoices: Invoice[];
  complaints: Complaint[];
  tasks: Task[];
  employees: Employee[];
  contacts: Contact[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getOpportunitiesAction: typeof getOpportunitiesAction;
  getSalesAction: typeof getSalesAction;
  getQuotesAction: typeof getQuotesAction;
  getInvoicesAction: typeof getInvoicesAction;
  getComplaintsAction: typeof getComplaintsAction;
  getTasksAction: typeof getTasksAction;
  getEmployeesAction: typeof getEmployeesAction;
  getContactsAction: typeof getContactsAction;
  actionsOnAdd?: (() => void)[];
  associatedOpportunity?: Opportunity;
  associatedComplaint?: Complaint;
  associatedTask?: Task;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _AddInteraction: React.FC<AddInteractionProps> = ({
  popupActive,
  opportunities,
  sales,
  quotes,
  invoices,
  complaints,
  tasks,
  employees,
  contacts,
  setPopupActive,
  getOpportunitiesAction,
  getQuotesAction,
  getInvoicesAction,
  getSalesAction,
  getComplaintsAction,
  getTasksAction,
  getEmployeesAction,
  getContactsAction,
  actionsOnAdd,
  associatedOpportunity,
  associatedComplaint,
  associatedTask,
}) => {
  /* TODO */
  const addInteraction = () => {
    AxiosHttpClient.post("api/v1/weeventpro/interactions", {
      id: "",
      interactionNumber: inputInteractionNumber,
      type: inputType,
      title: inputTitle,
      startDateTime: mergeDateTime(inputStartDate, inputStartTime),
      endDateTime: mergeDateTime(inputEndDate, inputEndTime),
      contactsParticipantsIds: inputParticipants
        .filter((participant) => isContactType(participant))
        .map((contact) => contact.id),
      employeesParticipantsIds: inputParticipants
        .filter((participant) => !isContactType(participant))
        .map((employee) => employee.id),
      location: inputLocation,
      description: inputDescription,
      associatedOpportunitiesIds: inputAssociatedOpportunities.map(
        (opportunity) => opportunity.id
      ),
      associatedQuotesIds: inputAssociatedQuotes.map((quote) => quote.id),
      associatedInvoicesIds: inputAssociatedInvoices.map(
        (invoice) => invoice.id
      ),
      associatedSalesIds: inputAssociatedSales.map((sale) => sale.id),
      associatedComplaintsIds: inputAssociatedComplaints.map(
        (complaint) => complaint.id
      ),
      associatedTasksIds: inputAssociatedTasks.map((task) => task.id),
      reminderModes: inputReminderModes,
      reminderTime: inputReminderTime,
      reminderTimeUnit: inputReminderTimeUnit,
      report: inputReport,
      fromContactId: isContactType(inputFrom) ? inputFrom?.id : null,
      fromEmployeeId: !isContactType(inputFrom) ? inputFrom?.id : null,
      toContactsIds: inputTo
        .filter((to) => isContactType(to))
        .map((to) => to.id),
      toEmployeesIds: inputTo
        .filter((to) => !isContactType(to))
        .map((to) => to.id),
      ccContactsIds: inputCC
        .filter((cc) => isContactType(cc))
        .map((cc) => cc.id),
      ccEmployeesIds: inputCC
        .filter((cc) => !isContactType(cc))
        .map((cc) => cc.id),
      object: inputObject,
      content: inputContent,
      files: inputFiles,
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addInteractionDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/interaction"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        INTERACTION_FORMAT +
        "," +
        INTERACTION_NUMBER_OF_DIGITS +
        "," +
        INTERACTION_INCREMENT,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(
        INTERACTION_INCREMENT,
        res
      );
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(INTERACTION_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let interactionNumber = getUserParameterValueByName(
        INTERACTION_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputInteractionNumber(interactionNumber);
    });
  };

  const validateForm = () => {
    setInputTitleError(false);
    setInputStartDateError(false);
    setInputFromError(false);
    setInputToError(false);
    setInputParticipantsError(false);
    setInputContentError(false);
    let result = true;
    if (inputTitle === "") {
      setInputTitleError(true);
      result = false;
    }
    if (inputStartDate === null) {
      setInputStartDateError(true);
      result = false;
    }
    if (
      ["SMS", "E-mail", "Appel téléphonique"].includes(inputType) &&
      inputFrom === null
    ) {
      setInputFromError(true);
      result = false;
    }
    if (
      ["SMS", "E-mail", "Appel téléphonique"].includes(inputType) &&
      inputTo.length < 1
    ) {
      setInputToError(true);
      result = false;
    }
    if (
      !["SMS", "E-mail", "Appel téléphonique"].includes(inputType) &&
      inputParticipants.length < 2
    ) {
      setAddFailed("Une interaction doit contenir au moins 2 participants!");
      setInputParticipantsError(true);
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInteractionDialogContentId",
      });
      return false;
    }
    if (["SMS", "E-mail"].includes(inputType) && inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInteractionDialogContentId",
      });
    }
    return result;
  };

  const [inputInteractionNumber, setInputInteractionNumber] = useState("");

  const [inputType, setInputType] = useState("Appel téléphonique");

  const [inputTitle, setInputTitle] = useState("");

  const [inputTitleError, setInputTitleError] = useState(false);

  const [inputStartDate, setInputStartDate] = useState<Dayjs | null>(null);

  const [inputStartDateError, setInputStartDateError] = useState(false);

  const [inputStartTime, setInputStartTime] = useState<Dayjs | null>(null);

  const [inputEndDate, setInputEndDate] = useState<Dayjs | null>(null);

  const [inputEndTime, setInputEndTime] = useState<Dayjs | null>(null);

  const [inputParticipants, setInputParticipants] = useState<
    (Employee | Contact)[]
  >([]);

  const [inputParticipantsError, setInputParticipantsError] = useState(false);

  const [inputParticipantsText, setInputParticipantsText] = useState("");

  const [inputLocation, setInputLocation] = useState("");

  const [inputDescription, setInputDescription] = useState("");

  const [inputAssociatedOpportunities, setInputAssociatedOpportunities] =
    useState<Opportunity[]>(
      associatedOpportunity ? [associatedOpportunity] : []
    );

  const [
    inputAssociatedOpportunitiesText,
    setInputAssociatedOpportunitiesText,
  ] = useState("");

  const [inputAssociatedQuotes, setInputAssociatedQuotes] = useState<Quote[]>(
    []
  );

  const [inputAssociatedQuotesText, setInputAssociatedQuotesText] =
    useState("");

  const [inputAssociatedInvoices, setInputAssociatedInvoices] = useState<
    Invoice[]
  >([]);

  const [inputAssociatedInvoicesText, setInputAssociatedInvoicesText] =
    useState("");

  const [inputAssociatedSales, setInputAssociatedSales] = useState<Sale[]>([]);

  const [inputAssociatedSalesText, setInputAssociatedSalesText] = useState("");

  const [inputAssociatedComplaints, setInputAssociatedComplaints] = useState<
    Complaint[]
  >(associatedComplaint ? [associatedComplaint] : []);

  const [inputAssociatedComplaintsText, setInputAssociatedComplaintsText] =
    useState("");

  const [inputAssociatedTasks, setInputAssociatedTasks] = useState<Task[]>(
    associatedTask ? [associatedTask] : []
  );

  const [inputAssociatedTasksText, setInputAssociatedTasksText] = useState("");

  const [inputReminderModes, setInputReminderModes] = useState<string[]>([
    "Notification",
  ]);

  const [inputReminderTime, setInputReminderTime] = useState("15");

  const [inputReminderTimeUnit, setInputReminderTimeUnit] = useState("minutes");

  const [inputReport, setInputReport] = useState("");

  const [inputFrom, setInputFrom] = useState<Employee | Contact | null>(null);

  const [inputFromError, setInputFromError] = useState(false);

  const [inputTo, setInputTo] = useState<(Employee | Contact)[]>([]);

  const [inputToError, setInputToError] = useState(false);

  const [inputCC, setInputCC] = useState<(Employee | Contact)[]>([]);

  const [inputObject, setInputObject] = useState("");

  const [inputContent, setInputContent] = useState("");

  const [inputContentError, setInputContentError] = useState(false);

  const [inputFiles, setInputFiles] = useState<File[]>([]);

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    getOpportunitiesAction("");
    getSalesAction("");
    getQuotesAction("");
    getInvoicesAction("");
    getComplaintsAction("");
    getTasksAction("");
    getEmployeesAction("");
    getContactsAction("");
    getCustomFields();
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContactsAction]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une interaction"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addInteractionDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        {inputType === "Appel téléphonique" && (
          <>
            <PhoneCallInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputEndDate={inputEndDate}
              inputEndTime={inputEndTime}
              inputFrom={inputFrom}
              inputFromError={inputFromError}
              inputTo={inputTo}
              inputToError={inputToError}
              inputDescription={inputDescription}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              inputReminderModes={inputReminderModes}
              inputReminderTime={inputReminderTime}
              inputReminderTimeUnit={inputReminderTimeUnit}
              inputReport={inputReport}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputEndDate={setInputEndDate}
              setInputEndTime={setInputEndTime}
              setInputFrom={setInputFrom}
              setInputTo={setInputTo}
              setInputDescription={setInputDescription}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
              setInputReminderModes={setInputReminderModes}
              setInputReminderTime={setInputReminderTime}
              setInputReminderTimeUnit={setInputReminderTimeUnit}
              setInputReport={setInputReport}
            />
          </>
        )}
        {inputType === "SMS" && (
          <>
            <SmsInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputFrom={inputFrom}
              inputFromError={inputFromError}
              inputTo={inputTo}
              inputToError={inputToError}
              inputContent={inputContent}
              inputContentError={inputContentError}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputFrom={setInputFrom}
              setInputTo={setInputTo}
              setInputContent={setInputContent}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
            />
          </>
        )}
        {inputType === "E-mail" && (
          <>
            <EmailInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputFrom={inputFrom}
              inputFromError={inputFromError}
              inputTo={inputTo}
              inputToError={inputToError}
              inputCC={inputCC}
              inputObject={inputObject}
              inputContent={inputContent}
              inputContentError={inputContentError}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              inputFiles={inputFiles}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputFrom={setInputFrom}
              setInputTo={setInputTo}
              setInputCC={setInputCC}
              setInputObject={setInputObject}
              setInputContent={setInputContent}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
              setInputFiles={setInputFiles}
            />
          </>
        )}
        {inputType === "Réunion en personne" && (
          <>
            <PhysicalMeetingInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputEndDate={inputEndDate}
              inputEndTime={inputEndTime}
              inputParticipants={inputParticipants}
              inputParticipantsError={inputParticipantsError}
              inputParticipantsText={inputParticipantsText}
              inputLocation={inputLocation}
              inputDescription={inputDescription}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              inputReminderModes={inputReminderModes}
              inputReminderTime={inputReminderTime}
              inputReminderTimeUnit={inputReminderTimeUnit}
              inputReport={inputReport}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputEndDate={setInputEndDate}
              setInputEndTime={setInputEndTime}
              setInputParticipants={setInputParticipants}
              setInputParticipantsText={setInputParticipantsText}
              setInputLocation={setInputLocation}
              setInputDescription={setInputDescription}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
              setInputReminderModes={setInputReminderModes}
              setInputReminderTime={setInputReminderTime}
              setInputReminderTimeUnit={setInputReminderTimeUnit}
              setInputReport={setInputReport}
            />
          </>
        )}
        {inputType === "Réunion virtuelle" && (
          <>
            <VirtualMeetingInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputEndDate={inputEndDate}
              inputEndTime={inputEndTime}
              inputParticipants={inputParticipants}
              inputParticipantsError={inputParticipantsError}
              inputParticipantsText={inputParticipantsText}
              inputLocation={inputLocation}
              inputDescription={inputDescription}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              inputReminderModes={inputReminderModes}
              inputReminderTime={inputReminderTime}
              inputReminderTimeUnit={inputReminderTimeUnit}
              inputReport={inputReport}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputEndDate={setInputEndDate}
              setInputEndTime={setInputEndTime}
              setInputParticipants={setInputParticipants}
              setInputParticipantsText={setInputParticipantsText}
              setInputLocation={setInputLocation}
              setInputDescription={setInputDescription}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
              setInputReminderModes={setInputReminderModes}
              setInputReminderTime={setInputReminderTime}
              setInputReminderTimeUnit={setInputReminderTimeUnit}
              setInputReport={setInputReport}
            />
          </>
        )}
        {inputType === "Autre" && (
          <>
            <OtherInteraction
              inputType={inputType}
              inputTitle={inputTitle}
              inputTitleError={inputTitleError}
              inputStartDate={inputStartDate}
              inputStartDateError={inputStartDateError}
              inputStartTime={inputStartTime}
              inputEndDate={inputEndDate}
              inputEndTime={inputEndTime}
              inputParticipants={inputParticipants}
              inputParticipantsError={inputParticipantsError}
              inputParticipantsText={inputParticipantsText}
              inputLocation={inputLocation}
              inputDescription={inputDescription}
              inputAssociatedOpportunities={inputAssociatedOpportunities}
              inputAssociatedOpportunitiesText={
                inputAssociatedOpportunitiesText
              }
              inputAssociatedQuotes={inputAssociatedQuotes}
              inputAssociatedQuotesText={inputAssociatedQuotesText}
              inputAssociatedInvoices={inputAssociatedInvoices}
              inputAssociatedInvoicesText={inputAssociatedInvoicesText}
              inputAssociatedSales={inputAssociatedSales}
              inputAssociatedSalesText={inputAssociatedSalesText}
              inputAssociatedComplaints={inputAssociatedComplaints}
              inputAssociatedComplaintsText={inputAssociatedComplaintsText}
              inputAssociatedTasks={inputAssociatedTasks}
              inputAssociatedTasksText={inputAssociatedTasksText}
              inputReminderModes={inputReminderModes}
              inputReminderTime={inputReminderTime}
              inputReminderTimeUnit={inputReminderTimeUnit}
              inputReport={inputReport}
              opportunities={opportunities}
              quotes={quotes}
              invoices={invoices}
              sales={sales}
              complaints={complaints}
              tasks={tasks}
              employees={employees}
              contacts={contacts}
              setInputType={setInputType}
              setInputTitle={setInputTitle}
              setInputStartDate={setInputStartDate}
              setInputStartTime={setInputStartTime}
              setInputEndDate={setInputEndDate}
              setInputEndTime={setInputEndTime}
              setInputParticipants={setInputParticipants}
              setInputParticipantsText={setInputParticipantsText}
              setInputLocation={setInputLocation}
              setInputDescription={setInputDescription}
              setInputAssociatedOpportunities={setInputAssociatedOpportunities}
              setInputAssociatedOpportunitiesText={
                setInputAssociatedOpportunitiesText
              }
              setInputAssociatedQuotes={setInputAssociatedQuotes}
              setInputAssociatedQuotesText={setInputAssociatedQuotesText}
              setInputAssociatedInvoices={setInputAssociatedInvoices}
              setInputAssociatedInvoicesText={setInputAssociatedInvoicesText}
              setInputAssociatedSales={setInputAssociatedSales}
              setInputAssociatedSalesText={setInputAssociatedSalesText}
              setInputAssociatedComplaints={setInputAssociatedComplaints}
              setInputAssociatedComplaintsText={
                setInputAssociatedComplaintsText
              }
              setInputAssociatedTasks={setInputAssociatedTasks}
              setInputAssociatedTasksText={setInputAssociatedTasksText}
              setInputReminderModes={setInputReminderModes}
              setInputReminderTime={setInputReminderTime}
              setInputReminderTimeUnit={setInputReminderTimeUnit}
              setInputReport={setInputReport}
            />
          </>
        )}

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addInteraction();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddInteraction = connect(
  (state: RootState) => ({
    contacts: getContacts(state),
    employees: getEmployees(state),
    opportunities: getOpportunities(state),
    sales: getSales(state),
    quotes: getQuotes(state),
    invoices: getInvoices(state),
    complaints: getComplaints(state),
    tasks: getTasks(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getContactsAction: getContactsAction,
        getEmployeesAction: getEmployeesAction,
        getOpportunitiesAction: getOpportunitiesAction,
        getSalesAction: getSalesAction,
        getQuotesAction: getQuotesAction,
        getInvoicesAction: getInvoicesAction,
        getComplaintsAction: getComplaintsAction,
        getTasksAction: getTasksAction,
      },
      dispatch
    )
)(_AddInteraction);

export default AddInteraction;
