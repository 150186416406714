import React, { useState } from "react";
import { FlexContainer } from "../../styles/FlexContainer";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginImageSection from "./LoginImageSection";
import { SignInForm } from "./SignInForm";

export interface SignInProps {}

const SignIn: React.FC<SignInProps> = () => {
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  const [emailSentAlert, setEmailSentAlert] = useState(false);

  return (
    <FlexContainer height="100vh">
      <LoginImageSection />
      {!forgotPasswordActive && (
        <SignInForm
          emailSentAlert={emailSentAlert}
          setEmailSentAlert={setEmailSentAlert}
          setForgotPasswordActive={setForgotPasswordActive}
        />
      )}
      {forgotPasswordActive && (
        <ForgotPasswordForm
          setForgotPasswordActive={setForgotPasswordActive}
          setEmailSentAlert={setEmailSentAlert}
        />
      )}
    </FlexContainer>
  );
};

export default SignIn;
