import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { SaleOption } from "../../../../../models/dish";
import {
  PurchaseOption,
  StorageRecord,
  Unit,
} from "../../../../../models/ingredients";
import { Material } from "../../../../../models/materials";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import TextfieldTip from "../../../../Reusable/TextfieldTip";
import { convertUnitToUnit } from "../../../../Reusable/Utils";
import SaleOptions from "../../Catalog/AddDish/SaleOptions";
import ProductionUnits from "../../Ingredients/AddIngredient/ProductionUnits";
import PurchaseUnits from "../../Ingredients/AddIngredient/PurchaseUnits";
import SaleUnits from "../../Ingredients/AddIngredient/SaleUnits";
import StorageRecords from "../../Ingredients/AddIngredient/StorageRecords";
import StorageUnits from "../../Ingredients/AddIngredient/StorageUnits";
import { PurchaseOptions } from "../AddMaterial/PurchaseOptions";

const Input = styled("input")({
  display: "none",
});

export interface EditMaterialProps {
  popupActive: Material | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Material | null>>;
  actionsOnEdit: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const EditMaterial: React.FC<EditMaterialProps> = ({
  popupActive,
  setPopupActive,
  actionsOnEdit,
}) => {
  const editMaterial = () => {
    AxiosHttpClient.put("api/v1/weeventpro/materials", {
      id: popupActive?.id,
      name: inputName,
      imageUrl: inputImageUrl,
      minimalQuantity: Number(inputMinimalQuantity),
      idealQuantity: Number(inputIdealQuantity),
      category: inputCategory,
      materialCode: inputMaterialNumber,
      suggestionPerEvent: /*inputSuggestionPerEvent === "Yes" ? true : */ false,
      materialForSale: inputMaterialForSale,
      handleExpiry: inputHandleExpiry === "Yes" ? true : false,
      averageExpiryDelay: inputAverageExpiryDelay,
      averageExpiryDelayUnit: inputAverageExpiryDelayUnit,
      globalStorageUnitId: inputGlobalStorageUnit?.id,
      purchaseUnits: inputPurchaseUnits,
      storageUnits: inputStorageUnits,
      productionUnits: inputProductionUnits,
      saleUnits: inputSaleUnits,
      purchaseOptions: inputPurchaseOptions.map((purchaseOption) => {
        return {
          id: purchaseOption.id,
          imageUrl: purchaseOption.imageUrl,
          name: purchaseOption.name,
          purchaseUnitId: purchaseOption.purchaseUnit?.id,
          productCode: purchaseOption.productCode,
          supplierId: purchaseOption.supplier?.id,
          priceHT: purchaseOption.priceHT,
          priceTTC: purchaseOption.priceTTC,
          tva: purchaseOption.tva,
        };
      }),
      saleOptions: inputMaterialForSale ? inputSaleOptions : [],
      storageRecords: inputStorageRecords.map((storageRecord) => {
        return {
          id: storageRecord.id,
          storageAreaId: storageRecord.storageArea.id,
          productId: storageRecord.product.id,
          quantity: storageRecord.quantity,
          storageUnitId: storageRecord.storageUnit?.id,
          expirationDate: storageRecord.expirationDate,
        };
      }),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnEdit?.forEach((action) => action());
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editMaterialDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/material"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateForm = () => {
    setInputNameError(false);
    if (
      inputIdealQuantity !== "" &&
      inputMinimalQuantity !== "" &&
      Number(inputIdealQuantity) < Number(inputMinimalQuantity)
    ) {
      setEditFailed(
        "La valeur de la quantité minimale ne doit pas être inférieure ou égale à la quantité idéale"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addMaterialDialogContentId",
      });
      return false;
    }
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addMaterialDialogContentId",
      });
    }
    return result;
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputName, setInputName] = useState(popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputCategory, setInputCategory] = useState(popupActive!.category);

  const [inputMinimalQuantity, setInputMinimalQuantity] = useState(
    popupActive!.minimalQuantity.toString()
  );

  const [inputIdealQuantity, setInputIdealQuantity] = useState(
    popupActive!.idealQuantity.toString()
  );

  const [inputPurchaseOptions, setInputPurchaseOptions] = useState<
    PurchaseOption[]
  >(popupActive!.purchaseOptions);

  const [purchaseOptionAddActive, setPurchaseOptionAddActive] = useState(false);

  /*const [inputSuggestionPerEvent, setInputSuggestionPerEvent] = useState(
    popupActive!.suggestionPerEvent ? "Yes" : "No"
  );*/
  //const inputSuggestionPerEvent = "No";

  const [inputMaterialForSale, setInputMaterialForSale] = useState(
    popupActive!.materialForSale
  );

  const [inputAverageExpiryDelay, setInputAverageExpiryDelay] = useState(
    popupActive!.averageExpiryDelay?.toString() || ""
  );

  const [inputPurchaseUnits, setInputPurchaseUnits] = useState<Unit[]>(
    popupActive!.purchaseUnits
  );

  const [inputStorageUnits, setInputStorageUnits] = useState<Unit[]>(
    popupActive!.storageUnits
  );

  const [inputGlobalStorageUnit, setInputGlobalStorageUnit] =
    useState<Unit | null>(
      popupActive!.storageUnits.find(
        (storageUnit) => storageUnit.id === popupActive!.globalStorageUnit?.id
      ) || null
    );

  const [inputProductionUnits, setInputProductionUnits] = useState<Unit[]>(
    popupActive!.productionUnits
  );

  const [inputSaleUnits, setInputSaleUnits] = useState<Unit[]>(
    popupActive!.saleUnits
  );

  const [inputSaleOptions, setInputSaleOptions] = useState<SaleOption[]>(
    popupActive!.saleOptions
  );

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    popupActive!.imageUrl
  );

  const [materialImageHovered, setMaterialImageHovered] = useState(false);

  const [inputStorageRecords, setInputStorageRecords] = useState<
    StorageRecord[]
  >(popupActive!.storageRecords);

  const [inputStorageRecordsTmp, setInputStorageRecordsTmp] = useState<
    StorageRecord[]
  >(popupActive!.storageRecords);

  const [enabledUnitOptions, setEnabledUnitOptions] = useState<string[]>([
    "Kg",
    "g",
    "mg",
    "L",
    "mL",
    "Unité",
  ]);

  const [inputAverageExpiryDelayUnit, setInputAverageExpiryDelayUnit] =
    useState(popupActive!.averageExpiryDelayUnit);

  const [selectGlobalStorageUnitOpen, setSelectGlobalStorageUnitOpen] =
    useState(false);

  const [storageUnitOpen, setStorageUnitOpen] = useState(false);

  const [storageUnitAddActive, setStorageUnitAddActive] = useState(false);

  const [purchaseUnitOpen, setPurchaseUnitOpen] = useState(false);

  const [saleUnitOpen, setSaleUnitOpen] = useState(false);

  const [purchaseUnitAddActive, setPurchaseUnitAddActive] = useState(false);

  const [saleUnitAddActive, setSaleUnitAddActive] = useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const [editFailed, setEditFailed] = useState("");

  const [openedTooltip, setOpenedTooltip] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [inputHandleExpiry, setInputHandleExpiry] = useState(
    popupActive!.handleExpiry ? "Yes" : "No"
  );

  const [inputMaterialNumber, setInputMaterialNumber] = useState(
    popupActive!.materialCode
  );

  const classes = useStyles();

  const StorageUnitRef = useRef<HTMLInputElement>(null);

  const StorageProductRef = useRef<HTMLInputElement>(null);

  const purchaseUnitInputRef = useRef<HTMLInputElement>(null);

  const saleUnitRef = useRef<HTMLInputElement>(null);

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    let gUnits = ["Kg", "g", "mg"];
    let lUnits = ["L", "mL"];
    if (inputPurchaseUnits.length > 0) {
      if (gUnits.indexOf(inputPurchaseUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(inputPurchaseUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Unité"]);
    } else if (inputStorageUnits.length > 0) {
      if (gUnits.indexOf(inputStorageUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(inputStorageUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Unité"]);
    } else if (inputProductionUnits.length > 0) {
      if (gUnits.indexOf(inputProductionUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(inputProductionUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Unité"]);
    } else if (inputSaleUnits.length > 0) {
      if (gUnits.indexOf(inputSaleUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(inputSaleUnits[0].baseUnit) > -1)
        setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Unité"]);
    } else setEnabledUnitOptions(["Kg", "g", "mg", "L", "mL", "Unité"]);
  }, [
    inputPurchaseUnits,
    inputStorageUnits,
    inputProductionUnits,
    inputSaleUnits,
  ]);

  useEffect(() => {
    if (document.getElementById("globalStorageUnit") !== null)
      setSelectWidth(document.getElementById("globalStorageUnit")!.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("globalStorageUnit")]);

  useEffect(() => {
    let hasChanged = false;
    if (inputHandleExpiry === "No") {
      let arr: StorageRecord[] = [];

      var helper: Map<string, StorageRecord> = new Map();
      console.log(inputStorageRecords);
      console.log(popupActive);
      inputStorageRecords.forEach((o) => {
        var key =
          o.product.name +
          "-" +
          o.product.productCode +
          "-" +
          o.storageArea.name;

        if (!helper.has(key)) {
          helper.set(key, o);
        } else {
          let tmp = helper.get(key)!;

          helper.set(key, {
            ...tmp,
            quantity:
              Number(tmp!.quantity) +
              Number(o.quantity) *
                convertUnitToUnit(o.storageUnit!, tmp.storageUnit!),
          });
          hasChanged = true;
        }
      });

      const iterator = helper.values();

      while (1) {
        let iteration = iterator.next();
        if (iteration.done) break;
        arr.push(iteration.value);
      }

      setInputStorageRecords(arr);

      if (hasChanged) setInputStorageRecordsTmp(inputStorageRecords);
    } else {
      if (inputStorageRecordsTmp.length !== 0) {
        setInputStorageRecords(inputStorageRecordsTmp);
        setInputStorageRecordsTmp([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHandleExpiry]);

  useEffect(() => {
    if (
      !inputStorageUnits.find(
        (storageUnit) => storageUnit.id === inputGlobalStorageUnit?.id
      )
    )
      setInputGlobalStorageUnit(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStorageUnits]);

  useEffect(() => {
    if (inputStorageUnits.length === 1)
      setInputGlobalStorageUnit(inputStorageUnits[0]);
    else if (
      !inputStorageUnits.find(
        (storageUnit) => storageUnit.id === inputGlobalStorageUnit?.id
      )
    ) {
      setInputGlobalStorageUnit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStorageUnits]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un matériel"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editMaterialDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setMaterialImageHovered(true)}
                onMouseLeave={() => setMaterialImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {materialImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-material.jpg"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
          </Grid>

          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => {
                  setInputName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                <Select
                  required
                  fullWidth
                  value={inputCategory}
                  label="Catégorie"
                  onChange={(e) => {
                    setInputCategory(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Informatique et Électronique">
                    Informatique et Électronique
                  </MenuItem>
                  <MenuItem value="Bureautique">Bureautique</MenuItem>
                  <MenuItem value="Outillage et Équipements">
                    Outillage et Équipements
                  </MenuItem>
                  <MenuItem value="Transport et Logistique">
                    Transport et Logistique
                  </MenuItem>
                  <MenuItem value="Maintenance et Entretien">
                    Maintenance et Entretien
                  </MenuItem>
                  <MenuItem value="Sécurité et Protection">
                    Sécurité et Protection
                  </MenuItem>
                  <MenuItem value="Audiovisuel">Audiovisuel</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="reference"
                label="Référence"
                value={inputMaterialNumber}
                onChange={(e) => {
                  setInputMaterialNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Gérer les dates de péremption?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputHandleExpiry}
                  onChange={(e) => setInputHandleExpiry(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              {inputHandleExpiry === "Yes" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  name="averageExpiryDelay"
                  label="Durée d'expiration moyenne"
                  value={inputAverageExpiryDelay}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputAverageExpiryDelay(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiInputBase-root:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:after": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:hover:before": {
                            borderBottom: "none !important",
                          },
                        }}
                      >
                        <Select
                          variant="standard"
                          sx={{
                            "& .MuiSelect-select:focus": {
                              borderBottom: "none",
                              backgroundColor: "white",
                            },
                          }}
                          value={inputAverageExpiryDelayUnit}
                          onChange={(e) =>
                            setInputAverageExpiryDelayUnit(e.target.value)
                          }
                        >
                          <MenuItem key="Jours" value="Jours">
                            Jours
                          </MenuItem>
                          <MenuItem key="Mois" value="Mois">
                            Mois
                          </MenuItem>
                          <MenuItem key="Années" value="Années">
                            Années
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            {/*<Grid item xs={1} sm={1} md={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Suggestion de commande par évènement
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputSuggestionPerEvent}
                  onChange={(e) => setInputSuggestionPerEvent(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>*/}
            <Grid item xs={1} sm={1} md={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Vous vendez ce produit?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputMaterialForSale ? "Yes" : "No"}
                  onChange={(e) =>
                    setInputMaterialForSale(
                      e.target.value === "Yes" ? true : false
                    )
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Unités
          </Text20>
        </BlockContainer>
        <PurchaseUnits
          inputRef={purchaseUnitInputRef}
          enabledUnitOptions={enabledUnitOptions}
          inputPurchaseUnits={inputPurchaseUnits}
          inputStorageUnits={inputStorageUnits}
          inputProductionUnits={inputProductionUnits}
          inputSaleUnits={inputSaleUnits}
          purchaseUnitAddActive={purchaseUnitAddActive}
          purchaseUnitOpen={purchaseUnitOpen}
          inputForSale={inputMaterialForSale}
          setInputPurchaseUnits={setInputPurchaseUnits}
          setInputStorageUnits={setInputStorageUnits}
          setInputProductionUnits={setInputProductionUnits}
          setInputSaleUnits={setInputSaleUnits}
          setPurchaseUnitOpen={setPurchaseUnitOpen}
          setPurchaseUnitAddActive={setPurchaseUnitAddActive}
        />
        <StorageUnits
          inputRef={StorageUnitRef}
          storageUnitOpen={storageUnitOpen}
          enabledUnitOptions={enabledUnitOptions}
          inputPurchaseUnits={inputPurchaseUnits}
          inputStorageUnits={inputStorageUnits}
          inputProductionUnits={inputProductionUnits}
          inputSaleUnits={inputSaleUnits}
          storageUnitAddActive={storageUnitAddActive}
          inputForSale={inputMaterialForSale}
          setInputPurchaseUnits={setInputPurchaseUnits}
          setInputStorageUnits={setInputStorageUnits}
          setInputProductionUnits={setInputProductionUnits}
          setInputSaleUnits={setInputSaleUnits}
          setStorageUnitOpen={setStorageUnitOpen}
          setStorageUnitAddActive={setStorageUnitAddActive}
        />
        <ProductionUnits
          enabledUnitOptions={enabledUnitOptions}
          inputPurchaseUnits={inputPurchaseUnits}
          inputStorageUnits={inputStorageUnits}
          inputProductionUnits={inputProductionUnits}
          inputSaleUnits={inputSaleUnits}
          inputForSale={inputMaterialForSale}
          setInputPurchaseUnits={setInputPurchaseUnits}
          setInputStorageUnits={setInputStorageUnits}
          setInputProductionUnits={setInputProductionUnits}
          setInputSaleUnits={setInputSaleUnits}
        />
        {inputMaterialForSale && (
          <SaleUnits
            inputRef={saleUnitRef}
            enabledUnitOptions={enabledUnitOptions}
            inputSaleUnits={inputSaleUnits}
            inputPurchaseUnits={inputPurchaseUnits}
            inputStorageUnits={inputStorageUnits}
            inputProductionUnits={inputProductionUnits}
            saleUnitAddActive={saleUnitAddActive}
            saleUnitOpen={saleUnitOpen}
            setInputSaleUnits={setInputSaleUnits}
            setInputPurchaseUnits={setInputPurchaseUnits}
            setInputStorageUnits={setInputStorageUnits}
            setInputProductionUnits={setInputProductionUnits}
            setSaleUnitOpen={setSaleUnitOpen}
            setSaleUnitAddActive={setSaleUnitAddActive}
          />
        )}
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Options d'achat
          </Text20>
        </BlockContainer>
        <PurchaseOptions
          purchaseUnitInputRef={purchaseUnitInputRef}
          purchaseOptionAddActive={purchaseOptionAddActive}
          inputPurchaseOptions={inputPurchaseOptions}
          inputPurchaseUnits={inputPurchaseUnits}
          imageUrl={inputImageUrl}
          materialName={inputName}
          setInputPurchaseUnits={setInputPurchaseUnits}
          setInputPurchaseOptions={setInputPurchaseOptions}
          setPurchaseUnitAddActive={setPurchaseUnitAddActive}
          setPurchaseUnitOpen={setPurchaseUnitOpen}
          setPurchaseOptionAddActive={setPurchaseOptionAddActive}
        />
        {inputMaterialForSale && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0" id="offSearch">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Options de vente
              </Text20>
            </BlockContainer>
            <SaleOptions
              inputSaleOptions={inputSaleOptions}
              inputSaleUnits={inputSaleUnits}
              inputImageUrl={inputImageUrl}
              saleUnitInputRef={saleUnitRef}
              setInputSaleOptions={setInputSaleOptions}
              setSaleUnitAddActive={setSaleUnitAddActive}
              setSaleUnitOpen={setSaleUnitOpen}
            />
          </>
        )}
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Lots de stockage
          </Text20>
        </BlockContainer>

        <StorageRecords
          inputRefStorageProduct={StorageProductRef}
          inputRefStorageUnit={StorageUnitRef}
          inputPurchaseOptions={inputPurchaseOptions}
          inputStorageRecords={inputStorageRecords}
          inputStorageUnits={inputStorageUnits}
          handleExpiry={inputHandleExpiry}
          inputImageUrl={inputImageUrl}
          inputName={inputName}
          inputAverageExpiryDelay={inputAverageExpiryDelay}
          inputAverageExpiryDelayUnit={inputAverageExpiryDelayUnit}
          setInputStorageRecords={setInputStorageRecords}
          setStorageUnitAddActive={setStorageUnitAddActive}
          setStorageUnitOpen={setStorageUnitOpen}
          setPurchaseOptionAddActive={setPurchaseOptionAddActive}
        />
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Alertes de stockage
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Tooltip
              color="primary"
              arrow
              open={openedTooltip === "globalStorageUnit"}
              disableFocusListener
              disableTouchListener
              placement="top"
              title="Unité utilisée pour le calcul des quantités minimales et idéales"
            >
              <FormControl fullWidth variant="outlined">
                <InputLabel id="globalStorageUnitLabel">
                  Unité de stockage globale
                </InputLabel>
                <Select
                  labelId="globalStorageUnitLabel"
                  id="globalStorageUnit"
                  open={selectGlobalStorageUnitOpen}
                  onMouseEnter={() => {
                    setOpenedTooltip("globalStorageUnit");
                  }}
                  onMouseLeave={() => {
                    setOpenedTooltip("");
                  }}
                  onBlur={() => {
                    setOpenedTooltip("");
                  }}
                  onClose={() => {
                    setSelectGlobalStorageUnitOpen(false);
                    setOpenedTooltip("");
                  }}
                  onOpen={() => setSelectGlobalStorageUnitOpen(true)}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        width: selectWidth,
                      },
                      "& .MuiButtonBase-root": {
                        whiteSpace: "normal",
                      },
                    },
                  }}
                  value={(inputGlobalStorageUnit || "") as any}
                  label="Unité de stockage globale"
                  onChange={(e) => setInputGlobalStorageUnit(e.target.value)}
                >
                  {inputStorageUnits.length === 0 && (
                    <Link
                      to="storageUnitsTopId"
                      spy={true}
                      smooth={true}
                      duration={500}
                      containerId={"editMaterialDialogContentId"}
                    >
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => {
                          setStorageUnitAddActive(true);
                          setTimeout(function () {
                            setSelectGlobalStorageUnitOpen(false);
                            StorageUnitRef.current?.focus({
                              preventScroll: true,
                            });
                          }, 100);
                          setTimeout(function () {
                            setStorageUnitOpen(true);
                          }, 500);
                        }}
                      >
                        Aucune unité de stockage créée. Cliquez ici pour en
                        créer une!
                      </MenuItem>
                    </Link>
                  )}
                  {inputStorageUnits.map((storageUnit, index) => (
                    <MenuItem value={storageUnit as any} key={index}>
                      {storageUnit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextfieldTip
              tipColor="primary"
              tipPlacement="top"
              tipTitle="Quantité en dessous de laquelle vous recevez une alerte"
              name="minimalQuantity"
              label="Quantité minimale"
              value={inputMinimalQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputMinimalQuantity(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {inputGlobalStorageUnit?.name || ""}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextfieldTip
              tipColor="primary"
              tipPlacement="top"
              tipTitle="Quantité désirée après remise à niveau du stock"
              name="idealQuantity"
              label="Quantité idéale"
              value={inputIdealQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputIdealQuantity(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {inputGlobalStorageUnit?.name || ""}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editMaterial();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditMaterial;
